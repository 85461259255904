import React, { useState , useRef} from 'react';
import styled from 'styled-components';
import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY } from '../constants';
import {
  Button,
  useMediaQuery,
  Menu as MenuContainer,
  MenuItem,
  Typography,
  Hidden,
  Divider,
} from '@material-ui/core';
import VideoTrack from '../VideoTrack';
import useDevices from '../hooks/useDevices';
import useMediaStreamTrack from '../hooks/useMediaStreamTrack';
import useVideoCallContext from '../hooks/useVideoCallContext';
import VirtualBackgroundDialog from '../VirtualBackgroundDialog';
import { isSupported } from '@twilio/video-processors';
import { useTranslation } from 'react-i18next';

const VideoDeviceSelectButton = ({className, disabled}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef(null);
  const { videoInputDevices } = useDevices();
  const { localTracks } = useVideoCallContext();
  const { t } = useTranslation();

  const localVideoTrack = localTracks.find(track => track.kind === 'video');
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
  );
  const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;
  const [virtualBackgroundSettingOpen, setVirtualBackgroundSettingOpen] = useState(false);

  const handleReplaceTrack = (newDeviceId) => {
    setStoredLocalVideoDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    localVideoTrack?.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS),
      deviceId: { exact: newDeviceId },
    });
  }
  const handleOpenVirtualBackgroundSetting = () => {
    setVirtualBackgroundSettingOpen(true);
    setMenuOpen(false); 
  }

  return (
    <>
    <Button
    className={className}
    onClick={() => setMenuOpen(isOpen => !isOpen)}
    // disabled={!hasAudioTrack}
    ref={anchorRef}
    data-cy-video-setting-toggle
    style={{ 'minWidth': '10px' , 'padding': '0'}}
    >
       <img src={RailsAssetPath("video_call/icon/expand_less.png")} className="button_icon_small" /> 
    </Button>

    <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen(isOpen => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: videoInputDevices ? (videoInputDevices.length) * 10 + 100 : 100, 
          horizontal: 0,
        }}
      >
        <Typography variant="subtitle2">{t("VideocallTextCamera",{})}</Typography>
        {videoInputDevices && videoInputDevices.length >= 1 ? videoInputDevices.map(device => (
          <MenuItem onClick={() => handleReplaceTrack(device.deviceId )}
           value={device.deviceId} 
           key={device.deviceId}
          >
            {localVideoInputDeviceId == device.deviceId ? (
              <IconContainer>
                <img src={RailsAssetPath("video_call/icon/checkmark.png")} className="button_icon_small" /> 
              </IconContainer>
            ):<IconContainer />
            }
            {device.deviceId == "default" ? t("VideocallTextSameSystem",{}) : device.label}
          </MenuItem>
          )): "" }
          {isSupported && (
            <>
            <Divider orientation="horizontal" variant="middle" style={{"background": "#707070", "margin": "10px"}} />
            <MenuItem onClick={handleOpenVirtualBackgroundSetting}> 
              <IconContainer />
              {t("VideocallTextVirtualBackground",{})}
            </MenuItem>

            </>
          )}
      </MenuContainer>
      <VirtualBackgroundDialog
        open={virtualBackgroundSettingOpen}
        onClose={() => {
          setVirtualBackgroundSettingOpen(false);
        }}
      />
    </>
  )
}

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 1em;
  margin-right: 0.3em;
`

export default VideoDeviceSelectButton;