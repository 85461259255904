import { useCallback, useState, useEffect } from 'react';
import { UserAvatar } from 'types';
import { useVoice } from 'javascript/components/voice/hooks/useVoice';
import { directCallChannel } from 'javascript/channels/direct_call_channel';
import useVoiceContext from 'javascript/components/voice/hooks/useVoiceContext';
import { useTranslation } from 'react-i18next';

export const useDirectCall = () => {
  const { handleVoiceConnect, handleVoiceDisconnect, roomName, setRoomName } =
    useVoice();
  const { room } = useVoiceContext();
  const [leaveRoom, setLeaveRoom] = useState(false);
  const { t } = useTranslation();
  // @ts-ignore
  // const sound = new Audio(RailsAssetPath('receive_call.mp3'));

  const { subscribeDirectCallChannel } = directCallChannel();
  const cable = subscribeDirectCallChannel();

  const handleCreateDirectCall = useCallback(
    async (
      avatar_id: number,
      currentAvatarId: number | undefined,
      inviting: boolean,
      setInviting
    ) => {
      if (!currentAvatarId) return;
      if (inviting) return;
      setInviting(true);
      let room_name = roomName;
      if (!room && !roomName) {
        let data = await handleVoiceConnect({ is_direct_call: true });
        room_name = data.room_name;
        setInviting(false);
      } else {
        room_name = roomName || room.name;
        setInviting(false);
      }
      cable.add_member_for_direct_call({
        room_name: room_name,
        avatar_id: avatar_id,
      });
    },
    [room, roomName, handleVoiceConnect]
  );

  const handleJoinDirectCall = useCallback(
    async (avatar_id, twilio_room_id) => {
      let data = await handleVoiceConnect({
        twilio_room_id: twilio_room_id,
        is_direct_call: true,
      });
      cable.join_for_direct_call({
        room_name: data?.room_name,
        avatar_id: avatar_id,
        twilio_room_id: twilio_room_id,
      });
    },
    [handleVoiceConnect]
  );

  const handleLeaveDirectCall = useCallback(async () => {
    if (!room) return;
    let room_name = room.name;
    await handleVoiceDisconnect();
    cable.leave_for_direct_call({
      room_name: room_name,
    });
  }, [room, handleVoiceDisconnect]);

  const handleDirectCallChannelRecieved = useCallback(
    (currentAvatar: UserAvatar, data) => {
      setLeaveRoom(false);
      switch (data.type) {
        case 'invite_user':
          if (data.to_avatar_id == currentAvatar?.id) {
            handleJoinDirectCall(data.to_avatar_id, data.twilio_room_id);
            return;
            ``;
          }
          break;
        case 'cancel_invite':
          if (data.from_avatar_id == currentAvatar?.id) {
            alert(t('DirectcallAlertNoresponse', {}));
            if (data.end_call) {
              setLeaveRoom(true);
            }
            return;
          }
          break;
        case 'join_user':
          // 誰かが入室したら音などを鳴らす場合はここの処理を使用する,現状み未指定
          return;
          // if (data.avatar_ids.includes(currentAvatar?.id)) {
          //   sound.play();
          //   return;
          // }
          break;
        case 'leave_user':
          //  誰かが切断し、最後の一人になっているようであれば強制的に終了
          if (data.end_call && data.avatar_ids.includes(currentAvatar?.id)) {
            setLeaveRoom(true);
            setRoomName('');
          }
          break;
      }
    },
    []
  );

  useEffect(() => {
    if (room && leaveRoom) {
      handleLeaveDirectCall();
    }
  }, [room, leaveRoom]);

  return {
    handleCreateDirectCall,
    handleJoinDirectCall,
    handleLeaveDirectCall,
    handleDirectCallChannelRecieved,
    setLeaveRoom,
    leaveRoom,
  };
};
