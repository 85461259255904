import { useCallback } from 'react';
import { OwnedProductInMybox, OwnedSkillProductInMybox } from 'types';

export const useIsTypeProductItem = () => {
  const isTypeProductItem = useCallback(
    (
      productItem: OwnedProductInMybox | OwnedSkillProductInMybox
    ): productItem is OwnedProductInMybox => {
      return (productItem as OwnedProductInMybox)?.item_count !== undefined;
    },
    []
  );

  return { isTypeProductItem };
};
