import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  MQ_DESKTOP,
  MQ_MOBILE,
} from 'javascript/components/landingPage/lib/MQ';
import { pcPx, spp } from 'javascript/components/landingPage/lib/cssUtil';
import {
  BLACK,
  fadeInStyle,
  jpStyle,
} from 'javascript/components/landingPage/local/commonCss';
import {
  onScrollPromise,
  removeOnscrollEvent,
} from 'javascript/components/landingPage/local/scrollPromise';
import {
  LANGUAGE,
  useLanguageStoreValue,
} from 'javascript/components/landingPage/store/language';

// @ts-ignore
const ASSETS_VOICE = RailsAssetPath('lp/room/voice.png');
// @ts-ignore
const ASSETS_VIDEO = RailsAssetPath('lp/room/video.png');
// @ts-ignore
const ASSETS_TIME = RailsAssetPath('lp/room/time.png');
// @ts-ignore
const ASSETS_VOICE_PC = RailsAssetPath('lp/room/voice_pc.png');
// @ts-ignore
const ASSETS_VIDEO_PC = RailsAssetPath('lp/room/video_pc.png');
// @ts-ignore
const ASSETS_TIME_PC = RailsAssetPath('lp/room/time_pc.png');

type Props = {
  className?: string;
  content: string;
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  ${MQ_DESKTOP} {
    display: flex;
    width: ${pcPx(1800 - 95)};
    margin: auto;
    padding-top: ${pcPx(100)};
    padding-left: ${pcPx(95)};
    &:before {
      content: '';
      background-color: rgba(255, 255, 255, 0.39);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: ${pcPx(1800)};
      height: 100%;
      top: 0;
    }
  }
`;

const Title = styled.h3`
  ${jpStyle}
  ${fadeInStyle}
  color: ${BLACK};
  font-weight: bold;
  position: relative;
  overflow: visible;
  display: block;
  margin: auto;
  ${MQ_MOBILE} {
    width: ${spp(714)};
    text-align: center;
    font-size: ${spp(36)};
    line-height: ${spp(69)};
    margin-top: ${spp(70)};
    margin-bottom: ${spp(23)};
    padding-bottom: ${spp(15)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(816)};
    font-size: ${pcPx(50)};
    line-height: ${pcPx(80)};
    margin-top: ${pcPx(0)};
    margin-bottom: ${pcPx(45)};
    padding-bottom: ${pcPx(50)};
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    height: 1px;
    background-color: #707070;
    ${MQ_MOBILE} {
      width: ${spp(0)};
      bottom: ${spp(4)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(0)};
      bottom: ${pcPx(4)};
    }
  }
  &[data-visible='true'] {
    &:after {
      transition: width 0.5s cubic-bezier(0.4, 0.59, 0.41, 0.97) 0s;
      transition-delay: 0.1s;
      ${MQ_MOBILE} {
        width: ${spp(714)};
      }
      ${MQ_DESKTOP} {
        width: ${pcPx(816)};
      }
    }
  }
`;

const Text = styled.p`
  ${jpStyle}
  ${fadeInStyle}
  color: ${BLACK};
  position: relative;
  overflow: visible;
  transition-delay: 0.2s;
  display: block;
  margin: auto;
  ${MQ_MOBILE} {
    width: ${spp(714)};
    text-align: center;
    font-size: ${spp(18)};
    line-height: ${spp(31)};
    margin-top: ${spp(10)};
    margin-bottom: ${spp(40)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(816)};
    font-size: ${pcPx(24)};
    line-height: ${pcPx(41)};
    margin-top: ${pcPx(5)};
    margin-bottom: ${pcPx(0)};
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  margin: auto;
  position: relative;
  ${MQ_MOBILE} {
    width: ${spp(550)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(700)};
  }
`;

const imgStyle = css`
  ${jpStyle}
  ${fadeInStyle}
  background-position: bottom;
  display: flex;
  margin: auto;
  justify-content: center;
  ${MQ_MOBILE} {
    width: ${spp(72)};
    height: ${spp(144)};
    margin-top: ${spp(20)};
    margin-bottom: ${spp(0)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(80)};
    height: ${pcPx(178)};
    margin-bottom: ${pcPx(30)};
  }
  &:before {
    display: block;
    text-align: center;
    font-weight: bold;
    ${MQ_MOBILE} {
      font-size: ${spp(21)};
      line-height: ${spp(18)};
    }
    ${MQ_DESKTOP} {
      font-size: ${pcPx(24)};
      line-height: ${pcPx(41)};
    }
  }
`;

const Img = styled.pre<Pick<Props, 'content'>>`
  ${imgStyle}
  transition-delay: 0.1s;
  ${MQ_MOBILE} {
    background: url(${ASSETS_VIDEO}) center no-repeat;
    background-size: contain;
  }
  ${MQ_DESKTOP} {
    background: url(${ASSETS_VIDEO_PC}) center no-repeat;
    background-size: contain;
  }
  &:before {
    content: '${({ content }) => content}';
  }
`;

const Img02 = styled.pre<Pick<Props, 'content'>>`
  ${imgStyle}
  transition-delay: 0.2s;
  ${MQ_MOBILE} {
    background: url(${ASSETS_VOICE}) center no-repeat;
    background-size: ${pcPx(53)};
  }
  ${MQ_DESKTOP} {
    background: url(${ASSETS_VOICE_PC}) center no-repeat;
    background-size: ${pcPx(64)};
  }
  &:before {
    content: '${({ content }) => content}';
  }
`;

const Img03 = styled.pre<Pick<Props, 'content'>>`
  ${imgStyle}
  transition-delay: 0.3s;
  ${MQ_MOBILE} {
    background: url(${ASSETS_TIME}) center no-repeat;
    background-size: contain;
  }
  ${MQ_DESKTOP} {
    background: url(${ASSETS_TIME_PC}) center no-repeat;
    background-size: contain;
  }
  &:before {
    content: '${({ content }) => content}';
  }
`;

const Room = () => {
  const fvRef = useRef<HTMLDivElement | null>(null);
  const imgRef = useRef<HTMLDivElement | null>(null);
  const [isFvRefScrolled, setIsFvRefScrolled] = useState(false);
  const [isImgRefScrolled, setIsImgRefScrolled] = useState(false);
  const languageStoreValue = useLanguageStoreValue();
  const { t } = useTranslation();

  useEffect(() => {
    onScrollPromise(fvRef.current)().then(() => setIsFvRefScrolled(true));
    onScrollPromise(imgRef.current)().then(() => setIsImgRefScrolled(true));
    return () => {
      removeOnscrollEvent(fvRef.current);
      removeOnscrollEvent(imgRef.current);
    };
  }, []);

  return (
    <Wrapper>
      <div>
        <Title
          ref={fvRef}
          data-visible={isFvRefScrolled}
          data-lang-ru={languageStoreValue === LANGUAGE.ru}
        >
          {t('room.title')}
        </Title>
        <Text
          data-visible={isFvRefScrolled}
          data-lang-ru={languageStoreValue === LANGUAGE.ru}
        >
          {t('room.text')}
        </Text>
      </div>
      <ImageWrapper ref={imgRef}>
        <Img content={t('room.video')} data-visible={isImgRefScrolled} />
        <Img02 content={t('room.voice')} data-visible={isImgRefScrolled} />
        <Img03 content={t('room.time')} data-visible={isImgRefScrolled} />
      </ImageWrapper>
    </Wrapper>
  );
};

export default Room;
