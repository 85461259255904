import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { device } from 'javascript/utils/mediaQuery';

const StyledModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(62, 62, 62, 0.75);
`;

const StyledModalForceToPay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  padding: 40px;
  background: #fff;
  a {
    display: block;
    margin-top: 32px;
    padding: 8px 24px;
    background: #00a0e9;
    color: #fff;
    font-size: 14px;
    border-radius: 8px;
  }
  @media ${device.sp} {
    width: 90vw;
  }
`;

export const ModalForceToPay = () => {
  const { t } = useTranslation();

  return (
    <StyledModalBackground>
      <StyledModalForceToPay>
        <p>{t('PaymentAlertModaltoforce', {})}</p>
        <a href='/payment'>{t('PaymentAlertLink', {})}</a>
      </StyledModalForceToPay>
    </StyledModalBackground>
  );
};
