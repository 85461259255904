import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { isPermissionDenied } from '../utils';
import useIsTrackEnabled from './useIsTrackEnabled';
import useVideoCallContext from './useVideoCallContext';

const useLocalAudioToggle = () => {
  const { t } = useTranslation();
  const { localTracks, getLocalAudioTrack } = useVideoCallContext();
  const audioTrack = localTracks.find((track) => track.kind === 'audio');
  const isEnabled = useIsTrackEnabled(audioTrack);

  const toggleAudioEnabled = useCallback(async () => {
    const isMicrophonePermissionDenied = await isPermissionDenied('microphone');
    if (isMicrophonePermissionDenied) {
      alert(t('VideocallPermissiondeniedMic', {}));
      return;
    }
    if (audioTrack) {
      audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
    } else {
    }
  }, [audioTrack]);

  const toggleAudioDisabled = useCallback(() => {
    if (audioTrack && audioTrack.isEnabled) {
      audioTrack.disable();
    }
  }, [audioTrack]);

  return [isEnabled, toggleAudioEnabled, toggleAudioDisabled];
};

export default useLocalAudioToggle;
