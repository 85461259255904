import axios from 'axios';
import { useState } from 'react';
import useSWR from 'swr';
import { OrderedHistory } from 'types';

export type AvatarOrderedHistorySort = "created_at" | "handled" | "handling" | null;
export const useOrderedHistory = () => {
  const fetcher = (url: string) =>
    axios.get<OrderedHistory>(url).then(({ data }) => data);
  const [avatarOrderedHistorySort, setAvatarOrderedHistorySort] = useState<AvatarOrderedHistorySort>(null);
  const { data } = useSWR(`/api/v1/avatar/product/order?avatar_owned_skill_products_sort=${avatarOrderedHistorySort}`, fetcher);

  return { orderedHistory: data?.owned_skill_products[1], avatarOrderedHistorySort, setAvatarOrderedHistorySort };
};
