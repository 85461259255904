const userAgent = window.navigator.userAgent.toLowerCase();

const isIOS =
  userAgent.includes('iphone') ||
  userAgent.includes('ipod') ||
  userAgent.includes('ipad');

export const isChrome = isIOS
  ? userAgent.indexOf('crios') !== -1
  : userAgent.indexOf('chrome') !== -1 &&
    userAgent.indexOf('edg') === -1 &&
    userAgent.indexOf('edge') === -1 &&
    userAgent.indexOf('opr') === -1 &&
    userAgent.indexOf('opera') === -1;
