import { useState, useEffect } from "react";
import useParticipantStatus from "../direct/hooks/useParticipantStatus";
import useVoiceContext from "./useVoiceContext";

const useParticipants = () => {
  const [participants, setParticipants] = useState([]);
  const { setParticipantState } = useParticipantStatus();

  const { room, invitedUsers, setInvitedUsers } = useVoiceContext();

  const participantConnected = participant => {
    if (invitedUsers.length > 0) {
      setParticipantState(Number(participant.identity), participant.state); 
    }
    setParticipants(prevParticipants => [...prevParticipants, participant]);
  }

  const participantDisconnected = participant => {
    if (invitedUsers.length > 0) {
      setParticipantState(Number(participant.identity), participant.state); 
    }
    setInvitedUsers(prevInvitedUsers => prevInvitedUsers.filter(p => p.id !== Number(participant.identity)));
    setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
  }

  useEffect(() => {
    if (room) {
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      room.participants.forEach(participantConnected);
    }
  }, [room]);

  return participants;
}


export default useParticipants;