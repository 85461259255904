import React from "react";
import styled from "styled-components";

const LoadingAnimation = () => {
  return (
    <AnimationContainer>
      <div />
      <div />
      <div />
    </AnimationContainer>
  );
}

const AnimationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //margin: 20% 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  opacity: 0.6;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  > div {
    width: 1rem;
    height: 1rem;
    margin: 1rem .2rem;
    background: #00a0e9;
    border-radius: 50%;
    animation: bound .6s infinite alternate;
  }
  > div:nth-of-type(2) {
    animation-delay: .2s;
  }
  > div:nth-of-type(3) {
    animation-delay: .4s;
  }
  @keyframes bound {
    from {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(-1rem);
      opacity: .1;
    }
  }
`

export default LoadingAnimation;