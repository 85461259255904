import axios from 'axios';
import useSWR from 'swr';
import { AvatarPaidProductType, OwnedProductListInMybox } from 'types';

export type AvatarOwnedSkillProductSort = 'created_at' | 'used' | 'avatar' | null;

export const useMybox = (selectedShopProductType: AvatarPaidProductType, avatarOwnedSkillProductSort?: AvatarOwnedSkillProductSort) => {
  const fetcher = (url: string, queryParams = '') => {
    return axios.get<OwnedProductListInMybox>(`${url}${queryParams}`).then(({ data }) => data);
  }

  const queryParams = `?avatar_owned_skill_products_sort=${avatarOwnedSkillProductSort}`;
  const { data } = useSWR(`/api/v1/avatar/owned_products${queryParams}`, fetcher);

  const fixShownProductList = (
    productType: AvatarPaidProductType,
    list: OwnedProductListInMybox | undefined
  ) => {
    if (!list) return;
    switch (productType) {
      case 'hat':
        return list.owned_products[1];
      case 'hair':
        return list.owned_products[2];
      case 'eye':
        return list.owned_products[3];
      case 'mouth':
        return list.owned_products[5];
      case 'clothes':
        return list.owned_products[7];
      case 'flower':
        return list.owned_products[9];
      case 'skill':
        return list.owned_skill_products[1];
      default:
        return null;
    }
  };

  const fixedShownProductListInMybox = fixShownProductList(
    selectedShopProductType,
    data
  );

  return { fixedShownProductListInMybox };
};
