import { useCallback, useState } from 'react';

export const useSortType = () => {
  const [sortType, setSort] = useState(1);

  const setSortType = useCallback((sortNum: number) => {
    setSort(sortNum);
  }, []);

  return { sortType, setSortType };
};
