import { useCallback } from 'react';
import { OwnedProductInMybox, OwnedSkillProductInMybox } from 'types';

export const useIsTypeProductList = () => {
  const isTypeProductList = useCallback(
    (
      productList: OwnedProductInMybox[] | OwnedSkillProductInMybox[]
    ): productList is OwnedProductInMybox[] => {
      return (
        (productList as OwnedProductInMybox[])[0]?.item_count !== undefined
      );
    },
    []
  );

  return { isTypeProductList };
};
