import React, { useCallback, useEffect, useRef, useState } from 'react';
import useSWR from 'swr';

// userIdが引数として渡ってくるのはStatus内のアイコンにホバーした場合。
export const useTooltip = <T extends HTMLElement = HTMLDivElement>(
  userId?: number | null
) => {
  const targetRef = useRef<T>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  // Status内のアイコンにホバー時、フリースペースのアバターの吹き出しの色を変更するため、
  // グローバルにステートを保持するため、useSWRを使用する。
  const { mutate: setIsHoveredInStatus } = useSWR(
    `isHoveredInStatus/${userId}`,
    null,
    { fallbackData: false }
  );

  useEffect(() => {
    const domPosition = targetRef.current?.getBoundingClientRect();
    if (!domPosition) return;
    setPosition({ top: domPosition.top, left: domPosition.left });
  }, [targetRef]);

  const handleOnMouseEnter = useCallback(() => {
    setIsHovered(true);
    (userId !== undefined || userId !== null) && setIsHoveredInStatus(true);
  }, [userId]);

  const handleOnMouseLeave = useCallback(() => {
    setIsHovered(false);
    (userId !== undefined || userId !== null) && setIsHoveredInStatus(false);
  }, [userId]);

  const handleOnMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setPosition({ top: e.pageY, left: e.pageX });
    },
    []
  );

  return {
    targetRef,
    position,
    isHovered,
    handleOnMouseEnter,
    handleOnMouseLeave,
    handleOnMouseMove,
  };
};
