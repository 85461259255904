import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  DragOverlay,
  MouseSensor,
} from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { SortableVirtualWorldItem } from 'javascript/features/virtualWorld/components/SortableVirtualWorldItem';
import { VirtualWorldItem } from 'javascript/features/virtualWorld/components/VirtualWorldItem';
import { UseDnDVirtual } from 'javascript/features/virtualWorld/hooks/useDnDVirtual';
import { useToggleSortMode } from 'javascript/features/virtualWorld/hooks/useToggleSortMode';
import { device } from 'javascript/utils/mediaQuery';
import { VirtualWorld } from 'types';

// heightは、col-2のheightから、タイトルエリアの16pxを引いた値
const VirtualWorldListWrapper = styled.div<{
  isHiddenSubRoom?: boolean;
  index?: number;
}>`
  flex: 1 0 auto;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  justify-items: center;
  gap: 8px;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: auto;
  width: 100%;
  padding-bottom: 16px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 48px;
  &::-webkit-scrollbar {
    display: none;
  }
  @media ${device.sp} {
    grid-template-columns: repeat(3, 1fr);
    justify-content: flex-start;
    margin: 0 0 10px;
    padding: ${({ isHiddenSubRoom }) =>
      isHiddenSubRoom ? '8px 0 40px 4px' : '8px 0 150px 4px'};
    height: 90vh;
    ${({ index }) => index === 0 && 'overflow: hidden'};
  }
`;

interface Props {
  virtualWorldList: VirtualWorld[];
  image?: string;
  isHiddenSubRoom?: boolean;
  isVideoOnline?: boolean;
  index?: number;
  isCast?: boolean;
}

export const VirtualWorldList: React.FC<Props> = ({
  virtualWorldList,
  image,
  isHiddenSubRoom,
  isVideoOnline,
  index,
  isCast
}) => {

  const isMobile = useMediaQuery({ maxWidth: 430 });

  const { isSortMode } = useToggleSortMode();

  const { activeId, items, handleDragStart, handleDragEnd } =
    UseDnDVirtual(virtualWorldList);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 0,
      },
    })
  );

  const draggingRoom = useMemo(
    () =>
      activeId &&
      virtualWorldList.find((room) => activeId === room.id.toString()),
    [activeId]
  );

  const convertImage = useCallback(
    (fetchedImageType: number, worldTheme: string | undefined) => {
      return `worldTheme/${worldTheme}-white-room-${fetchedImageType}.png`;
    },
    []
  );

  return !isMobile && isSortMode ? (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={items.map((item) => ({ ...item, id: item.id.toString() }))}
        strategy={rectSortingStrategy}
      >
        <VirtualWorldListWrapper
          isHiddenSubRoom={isHiddenSubRoom}
          index={index}
        >
          {items.map((item) => (
            <SortableVirtualWorldItem
              key={item.id}
              virtualWorld={item}
              localImage={convertImage(item.room_image_type, image)}
              isDraggingItem={activeId === item.id.toString()}
            />
          ))}
        </VirtualWorldListWrapper>
      </SortableContext>
      <DragOverlay>
        {activeId && draggingRoom ? (
          <SortableVirtualWorldItem
            virtualWorld={draggingRoom}
            localImage={convertImage(draggingRoom.room_image_type, image)}
            isOverlayed
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  ) : (
    <VirtualWorldListWrapper isHiddenSubRoom={isHiddenSubRoom} index={index}>
      {items.map((item) => (
        (!isCast || isCast && item.is_open_guest) ? (
        <VirtualWorldItem
          key={item.id}
          virtualWorld={item}
          isVideoOnline={isVideoOnline}
          localImage={convertImage(item.room_image_type, image)}
        />
        ) : <></>
      ))}
    </VirtualWorldListWrapper>
  );
};
