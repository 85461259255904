import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { VirtualWorld } from 'types';

export const UseDnDVirtual = (virtualList: VirtualWorld[]) => {
  const [items, setItems] = useState(virtualList);
  const [activeId, setActiveId] = useState<string | null>(null);

  const roomsLength = virtualList.length;
  // useEffectの第二引数でプリミティブ値（string）として各ルームの状態を比較し、再レンダリングの条件とする
  const stringifiedNeededPropertyForRerendering = virtualList
    .map((room) =>
      JSON.stringify({
        userLength: room.users.length,
        name: room.name,
        guestLength: room.guest_num,
        type: room.room_type,
        image: room.room_image_type,
      })
    )
    .join();

  useEffect(() => {
    setItems(virtualList);
  }, [stringifiedNeededPropertyForRerendering, roomsLength]);

  const handleDragEnd = useCallback(
    (e: DragEndEvent) => {
      const { active, over } = e;

      if (active.id !== over?.id) {
        setItems((items) => {
          const oldIndex = items.findIndex(
            (item) => item.id.toString() === active.id
          );
          const newIndex = items.findIndex(
            (item) => item.id.toString() === over?.id
          );

          const newArray = arrayMove(items, oldIndex, newIndex);

          const roomIds = newArray.map((item) => item.id);

          axios.post('/api/v1/office/rooms/sort', { room_id_sorts: roomIds });

          return newArray;
        });
      }
      setActiveId(null);
    },
    [items]
  );

  const handleDragStart = useCallback((e: DragStartEvent) => {
    setActiveId(e.active.id);
  }, []);

  return { activeId, items, handleDragStart, handleDragEnd };
};
