import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { useGlobal } from 'reactn';
import useVoiceContext from '../voice/hooks/useVoiceContext';
import axios from 'axios';
import styled from 'styled-components';
import { Typography, Grid, Button } from '@material-ui/core';
import { useStyles } from './hooks/useStyles';
import usePaymentContext from './hooks/usePaymentContext';
import { useTranslation } from 'react-i18next';
import { ExchangeToVikeDialog } from './ExchangeToVikeDialog';
import { useSelectAvatar } from 'javascript/features/mybox/hooks/useSelectAvatar';
import { useAvatarsToPresent } from 'javascript/features/mybox/hooks/useAvatarsToPresent';

const NowPoint = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { remainingPoint, setRemainingPoint } = useVoiceContext();
  const { paymentConfig, fetchConfig } = usePaymentContext();

  const { avatars } = useAvatarsToPresent();
  const { targetAvatar, handleSelectAvatar, setAvatarId } =
    useSelectAvatar(avatars);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    fetchConfig();
    setDialogOpen(false);
    setAvatarId('');
  }, [fetchConfig]);

  useEffect(() => {
    if (paymentConfig) {
      setRemainingPoint(paymentConfig.twilio_remain_point);
    }
  }, [paymentConfig]);

  return (
    <NowPointWrapper>
      <Typography variant='subtitle1'>
        {t('PaymentTextRemainPoint', {})}
      </Typography>
      <StyledPointWrapper>
        <Grid container item xs={12} justify='flex-start'>
          <Grid container item xs={6} justify='flex-start' alignItems='center'>
            <PointArea>
              <Typography variant='h5' display='inline'>
                {remainingPoint}
              </Typography>
              pt
            </PointArea>
          </Grid>
          {paymentConfig && paymentConfig.available_plan && (
            <Grid container item xs={6} justify='flex-end'>
              <Button className={classes.baseButton} href='/buy/charge'>
                {t('PaymentTextPurchasePoint', {})}
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid container item xs={12} justify='flex-start'>
          <Grid container item xs={6} justify='flex-start' alignItems='center'>
            <StyledRateTitle>{t('PaymentTextCurrentrate')}</StyledRateTitle>
            <StyledRateValueWrapper>
              <p>
                <span>{paymentConfig?.rate_point}</span>pt
              </p>
              <StyledArrow></StyledArrow>
              <p>
                <span>{paymentConfig?.rate_viket}</span>vike
              </p>
            </StyledRateValueWrapper>
          </Grid>
          {paymentConfig?.rate_point &&
            paymentConfig?.rate_viket &&
            remainingPoint && (
              <Grid container item xs={6} justify='flex-end'>
                <Button
                  className={classes.baseButton}
                  style={{ textTransform: 'none' }}
                  onClick={handleDialogOpen}
                >
                  {t('PaymentTextPointtovike', {})}
                </Button>
                <ExchangeToVikeDialog
                  open={dialogOpen}
                  onClose={handleClose}
                  avatars={avatars}
                  targetAvatar={targetAvatar}
                  handleSelectAvatar={handleSelectAvatar}
                  remainingPoint={remainingPoint}
                  rate={{
                    point: paymentConfig.rate_point,
                    viket: paymentConfig.rate_viket,
                  }}
                />
              </Grid>
            )}
        </Grid>
      </StyledPointWrapper>
    </NowPointWrapper>
  );
};

const NowPointWrapper = styled.div`
  margin: 20px 0;
`;
const StyledPointWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;
const PointArea = styled.div`
  margin-left: 10px;
`;
const StyledRateTitle = styled.div`
  margin-left: 10px;
  margin-right: 16px;
  font-weight: bold;
  span {
    font-weight: bold;
  }
`;
const StyledRateValueWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  span {
    font-weight: bold;
  }
`;
const StyledArrow = styled.div`
  position: relative;
  padding-left: 16px;
  &::before,
  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    content: '';
  }
  &::before {
    left: 0;
    width: 10px;
    height: 2px;
    background: #00a0e9;
  }
  &::after {
    left: 10px;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 8px solid #00a0e9;
  }
`;
const RemainPoint = styled.span`
  font-weight: bold;
  font-size: 15px;
`;

export default NowPoint;
