import React from 'react';
import {
  Typography,
  Grid,
  Hidden,
  Button,
  ButtonGroup,
  Divider,
} from '@material-ui/core';
import styled from 'styled-components';
import { sp } from '../../utils/mediaQuery';
import useVideoCallContext from './hooks/useVideoCallContext';
import useRoomState from './hooks/useRoomState';
import { useMediaQuery } from 'react-responsive';
import ToggleAudioButton from './Buttons/ToggleAudioButton';
import AudioDeviceSelectButton from './Buttons/AudioDeviceSelectButton';
import VideoDeviceSelectButton from './Buttons/VideoDeviceSelectButton';
import ToggleVideoButton from './Buttons/ToggleVideoButton';
import ToggleScreenShareButton from './Buttons/ToggleScreenShareButton';
import DisconnectButton from './Buttons/DisconnectButton';
import NumberParticipantButton from './Buttons/NumberParticipantButton';
import { isChrome } from 'javascript/utils/isChrome';
import { useTranslation } from 'react-i18next';

const MenuBar = () => {
  const { room, leave, isSharingScreen, toggleScreenShare } =
    useVideoCallContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const isMobile =
    useMediaQuery({ maxWidth: 430 }) ||
    (/android|ipod|ipad|iphone|macintosh/.test(
      navigator.userAgent.toLowerCase()
    ) &&
      'ontouchend' in document);
  const { t } = useTranslation();

  const handleDisconnect = () => {
    leave(room);
  };
  return (
    <Conatainer>
      {isSharingScreen && (
        <ScreenShareDisconnect>
          <Grid container justify='center' alignItems='center'>
            <Typography variant='subtitle2' style={{ fontSize: '10px' }}>
              {t('VideocallStatusShareScreen', {})}
            </Typography>
            <Button
              style={{ fontSize: '10px', padding: '5px', marginLeft: '10px' }}
              variant='contained'
              color='secondary'
              onClick={() => toggleScreenShare()}
            >
              {t('VideocallButtonStopShareScreen', {})}
            </Button>
          </Grid>
        </ScreenShareDisconnect>
      )}
      <Grid
        container
        spacing={0}
        direction='row'
        alignItems='center'
        justify='center'
      >
        <Grid
          container
          item
          xs={12}
          justify='center'
          style={{ paddingRight: '0%' }}
        >
          <NumberParticipantButton />
          <Divider
            orientation='vertical'
            variant='middle'
            flexItem
            style={{ background: '#707070' }}
          />
          <ButtonGroup
            variant='contained'
            color='primary'
            aria-label='split button'
          >
            <ToggleAudioButton
              style={{ minWidth: '10px', paddingRight: '5px' }}
              disabled={isReconnecting}
            />
            {!isMobile && <AudioDeviceSelectButton />}
          </ButtonGroup>
          <ButtonGroup variant='contained' aria-label='split button'>
            <ToggleVideoButton
              style={{ minWidth: '10px', paddingRight: '5px' }}
              disabled={isReconnecting}
            />
            {!isMobile && <VideoDeviceSelectButton />}
          </ButtonGroup>

          {!isSharingScreen && !isMobile && (
            <ToggleScreenShareButton disabled={isReconnecting} />
          )}
          <Divider
            orientation='vertical'
            variant='middle'
            flexItem
            style={{ background: '#707070' }}
          />
          <DisconnectButton onClick={handleDisconnect} />
          {!isChrome && (
            <StyledNotChromeAlert>
              {t('GenelalAlertOnlyChrome', {})}
            </StyledNotChromeAlert>
          )}
        </Grid>
      </Grid>
    </Conatainer>
  );
};
const Conatainer = styled.footer`
  background-color: #000;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  position: fixed;
  display: flex;
  padding: 0 1.43em;
  z-index: 10;
  ${sp`
    height: 50px;
    padding: 0;
    bottom: 50px;
  `}
`;

const ScreenShareDisconnect = styled.div`
  position: fixed;
  z-index: 8;
  bottom: 100px;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  & h6 {
    color: white;
  }
  padding: 5px;
  width: 200px;
  margin: 0 auto;
  border-radius: 10px;
`;

const StyledNotChromeAlert = styled.p`
  margin-top: 4px;
  font-weight: bold;
  color: #f00;
  ${sp`
    margin-top: 16px;
  `}
`;

export default MenuBar;
