import React from 'react';
import styled from 'styled-components';
import { RealWorldItem } from 'javascript/features/realWorld/components/RealWorldItem';
import { device } from 'javascript/utils/mediaQuery';
import { RealWorld } from 'types';

const RealWorldListWrapper = styled.div<{
  image: string;
  isHiddenSubRoom?: boolean;
}>`
  overflow: auto;
  height: 65%;
  // OfficeWrapperのpaddingを打ち消し、左端一杯まで背景画像を表示させる
  margin: 0 -4px 0 -16px;
  padding: 4px 4px 0 16px;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media ${device.sp} {
    margin: 0 5px;
    padding: ${({ isHiddenSubRoom }) =>
      isHiddenSubRoom ? '4px 0 40px 0' : '4px 0 150px 0'};
    height: 90vh;
  }
`;

interface Props {
  realWorldList: RealWorld[];
  videoConnectStatus: () => boolean;
  isHiddenSubRoom?: boolean;
}

export const RealWorldList: React.FC<Props> = ({
  realWorldList,
  isHiddenSubRoom,
  videoConnectStatus,
}) => (
  <RealWorldListWrapper
    isHiddenSubRoom={isHiddenSubRoom}
    // @ts-ignore
    image={RailsAssetPath('real-world_background.png')}
  >
    {realWorldList.map((realWorld) => (
      <RealWorldItem
        realWorld={realWorld}
        videoConnectStatus={videoConnectStatus}
        key={realWorld.id}
      />
    ))}
  </RealWorldListWrapper>
);
