import { FixedWidthAndPosition, FixedHeightAndPosition } from 'types';

/*
myboxページの
プレゼントモーダルに表示されるアバターパーツの画像の大きさと位置を管理する定数
*/

// 目
export const eyeAppearanceInPresentModal: FixedWidthAndPosition[] = [
  { width: 25, top: 39, left: 44 },
  { width: 26, top: 39, left: 44 },
  { width: 25, top: 39, left: 44 },
  { width: 25, top: 38, left: 44 },
  { width: 25, top: 39, left: 44 },
  { width: 26, top: 39, left: 44 },
  { width: 23, top: 39, left: 44 },
  { width: 27, top: 39, left: 43 },
  { width: 26, top: 39, left: 45 },
  { width: 27, top: 39, left: 44 },

  { width: 25, top: 40, left: 44 },
  { width: 25, top: 39, left: 44 },
  { width: 25, top: 39, left: 44 },
  { width: 25, top: 38, left: 44 },
  { width: 25, top: 39, left: 44 },
  { width: 25, top: 38, left: 44 },
  { width: 24, top: 39, left: 44 },
  { width: 24, top: 39, left: 44 },
  { width: 23, top: 38, left: 44 },
  { width: 23, top: 37, left: 44 },

  { width: 25, top: 39, left: 44 },
  { width: 25, top: 39, left: 44 },
  { width: 22, top: 39, left: 44 },
  { width: 23, top: 38, left: 44 },
  { width: 25, top: 40, left: 44 },
  { width: 25, top: 39, left: 44 },
  { width: 25, top: 39, left: 44 },
  { width: 25, top: 39, left: 44 },
  { width: 25, top: 41, left: 44 },
  { width: 25, top: 40, left: 44 },

  { width: 25, top: 39, left: 44 },
  { width: 25, top: 41, left: 44 },
  // 以下有料プロダクト
  { width: 22, top: 38, left: 44 },
  { width: 40, top: 34, left: 41 },
  { width: 39, top: 36, left: 42 },
  { width: 19, top: 41, left: 46 },
  { width: 20, top: 42, left: 45 },
  { width: 16, top: 41, left: 46 },
  { width: 24, top: 39, left: 44 },
  { width: 31, top: 35, left: 41 },

  { width: 35, top: 34, left: 42 },
  { width: 38, top: 35, left: 42 },
  { width: 25, top: 39, left: 44 },
  { width: 25, top: 39, left: 44 },
  { width: 26, top: 40, left: 44 },
  { width: 23, top: 39, left: 44 },
  { width: 20, top: 35, left: 45 },
  { width: 22, top: 39, left: 44 },
  { width: 24, top: 40, left: 44 },
  { width: 22, top: 41, left: 44 },

  { width: 19, top: 38, left: 44 },
  { width: 22, top: 41, left: 44 },
  { width: 22, top: 40, left: 44 },
  { width: 22, top: 41, left: 44 },
  { width: 35, top: 39, left: 42 },
  { width: 21, top: 35, left: 44 },
  { width: 18, top: 34, left: 45 },
  { width: 18, top: 41, left: 45 },
  { width: 20, top: 39, left: 44 },
  { width: 22, top: 42, left: 44 },

  { width: 24, top: 40, left: 43 },
  { width: 24, top: 41, left: 44 },
  { width: 23, top: 40, left: 44 },
  { width: 24, top: 39, left: 44 },
  { width: 26, top: 38, left: 41 },
  { width: 24, top: 40, left: 44 },
  { width: 17, top: 40, left: 45 },
  { width: 23, top: 41, left: 44 },
  { width: 23, top: 39, left: 44 },
  { width: 23, top: 41, left: 44 },

  { width: 23, top: 43, left: 44 },
  { width: 24, top: 39, left: 43 },
  { width: 23, top: 41, left: 44 },
  { width: 22, top: 41, left: 44 },
  { width: 23, top: 38, left: 44 },
  { width: 21, top: 39, left: 44 },
  { width: 21, top: 40, left: 44 },
  { width: 24, top: 40, left: 45 },
  { width: 23, top: 41, left: 44 },
  { width: 21, top: 39, left: 44 },
  { width: 21, top: 39, left: 44 },
  { width: 21, top: 39, left: 44 },
  { width: 21, top: 39, left: 44 },
  { width: 19, top: 37, left: 46 },
  { width: 21, top: 36, left: 45 },
  { width: 25, top: 37, left: 45 },
  { width: 21, top: 36, left: 46 },
  { width: 23, top: 36, left: 45 },
  { width: 21, top: 38, left: 46 },
  { width: 25, top: 36, left: 45 },

  { width: 25, top: 36, left: 45 },
  { width: 25, top: 36, left: 45 },
  { width: 25, top: 36, left: 45 },
  { width: 27, top: 33, left: 44 },
  { width: 25, top: 36, left: 45 },

  { width: 25, top: 36, left: 45 },
  { width: 25, top: 36, left: 45 },
  { width: 25, top: 36, left: 45 },
  { width: 25, top: 36, left: 45 },
  { width: 25, top: 36, left: 45 },

  { width: 25, top: 36, left: 45 },
  { width: 25, top: 36, left: 45 },
  { width: 25, top: 36, left: 45 },
  { width: 25, top: 36, left: 45 },
  { width: 25, top: 36, left: 45 },

  { width: 25, top: 36, left: 45 },
  { width: 25, top: 36, left: 45 },
  { width: 25, top: 36, left: 45 },
  { width: 25, top: 36, left: 45 },
  { width: 25, top: 36, left: 45 },

  { width: 25, top: 36, left: 45 }, // 111
  { width: 25, top: 36, left: 45 }, 
  { width: 25, top: 36, left: 45 }, 
  { width: 25, top: 36, left: 45 }, 
  { width: 25, top: 36, left: 45 }, 

  { width: 25, top: 36, left: 45 }, // 116
  { width: 25, top: 36, left: 45 }, 
  { width: 25, top: 36, left: 45 }, 
  { width: 25, top: 36, left: 45 }, 
  { width: 25, top: 36, left: 45 }, 

  { width: 25, top: 36, left: 45 }, // 121
  { width: 25, top: 36, left: 45 }, 
  { width: 25, top: 36, left: 45 }, 
  { width: 25, top: 36, left: 45 }, 
  { width: 25, top: 36, left: 45 }, 

  { width: 25, top: 36, left: 45 }, // 126
  { width: 25, top: 36, left: 45 }, 
  { width: 25, top: 36, left: 45 }, 
  { width: 25, top: 36, left: 45 }, 
  { width: 25, top: 36, left: 45 }, 

  { width: 96, top: 83, left: 188 }, // 目 131
  { width: 63, top: 106, left: 204 },
  { width: 65, top: 107, left: 205 },
  { width: 53, top: 113, left: 210 },
  { width: 64, top: 98, left: 205 },

  { width: 96, top: 83, left: 188 }, // 目 136
  { width: 63, top: 106, left: 204 },
  { width: 65, top: 107, left: 205 },
  { width: 53, top: 113, left: 210 },
  { width: 64, top: 98, left: 205 },

  { width: 96, top: 83, left: 188 }, // 目 141
  { width: 63, top: 106, left: 204 },
  { width: 65, top: 107, left: 205 },
  { width: 53, top: 113, left: 210 },
  { width: 64, top: 98, left: 205 },

  { width: 96, top: 83, left: 188 }, // 目 146
  { width: 63, top: 106, left: 204 },
  { width: 65, top: 107, left: 205 },
  { width: 53, top: 113, left: 210 },
  { width: 64, top: 98, left: 205 },

  { width: 96, top: 83, left: 188 }, // 目 151
  { width: 63, top: 106, left: 204 },
  { width: 65, top: 107, left: 205 },
  { width: 53, top: 113, left: 210 },
  { width: 64, top: 98, left: 205 },

  { width: 96, top: 83, left: 188 }, // 目 156
  { width: 63, top: 106, left: 204 },
  { width: 65, top: 107, left: 205 },
  { width: 53, top: 113, left: 210 },
  { width: 64, top: 98, left: 205 },

  { width: 96, top: 83, left: 188 }, // 目 161
  { width: 63, top: 106, left: 204 },
  { width: 65, top: 107, left: 205 },
  { width: 53, top: 113, left: 210 },
  { width: 64, top: 98, left: 205 },

  { width: 96, top: 83, left: 188 }, // 目 166
  { width: 63, top: 106, left: 204 },
  { width: 65, top: 107, left: 205 },
  { width: 53, top: 113, left: 210 },
  { width: 64, top: 98, left: 205 },

  { width: 96, top: 83, left: 188 }, // 目 171
  { width: 63, top: 106, left: 204 },
  { width: 65, top: 107, left: 205 },
  { width: 53, top: 113, left: 210 },
  { width: 64, top: 98, left: 205 },

  { width: 96, top: 83, left: 188 }, // 目 176
  { width: 63, top: 106, left: 204 },
  { width: 65, top: 107, left: 205 },
  { width: 53, top: 113, left: 210 },
  { width: 64, top: 98, left: 205 },

  { width: 96, top: 83, left: 188 }, // 目 181
  { width: 63, top: 106, left: 204 },
  { width: 65, top: 107, left: 205 },
  { width: 53, top: 113, left: 210 },
  { width: 64, top: 98, left: 205 },

  { width: 96, top: 83, left: 188 }, // 目 186
  { width: 63, top: 106, left: 204 },
  { width: 65, top: 107, left: 205 },
  { width: 53, top: 113, left: 210 },
  { width: 64, top: 98, left: 205 },

  { width: 96, top: 83, left: 188 }, // 目 191
  { width: 63, top: 106, left: 204 },
  { width: 65, top: 107, left: 205 },
  { width: 53, top: 113, left: 210 },
  { width: 64, top: 98, left: 205 },
];

// 口
export const mouthAppearanceInPresentModal: FixedWidthAndPosition[] = [
  { width: 11, top: 51, left: 49 },
  { width: 11, top: 51, left: 50 },
  { width: 8, top: 51, left: 51 },
  { width: 10, top: 50, left: 50 },
  { width: 10, top: 50, left: 50 },
  { width: 4, top: 50, left: 52 },
  { width: 7, top: 49, left: 52 },
  { width: 8, top: 51, left: 51 },
  { width: 4, top: 50, left: 53 },
  { width: 10, top: 51, left: 51 },

  // 以下有料プロダクト
  { width: 11, top: 51, left: 49 },
  { width: 11, top: 47, left: 49 },
  { width: 37, top: 42, left: 41 },
  { width: 7, top: 51, left: 51 },
  { width: 18, top: 52, left: 40 },
  { width: 38, top: 42, left: 42 },
  { width: 38, top: 42, left: 42 },
  { width: 10, top: 51, left: 49 },
  { width: 18, top: 46, left: 47 },
  { width: 40, top: 42, left: 41 },

  { width: 22, top: 43, left: 46 },
  { width: 17, top: 49, left: 47 },
  { width: 14, top: 49, left: 49 },
  { width: 10, top: 49, left: 49 },
  { width: 10, top: 50, left: 50 },
  { width: 10, top: 50, left: 50 },
  { width: 9, top: 49, left: 49 },
  { width: 7, top: 48, left: 52 },
  { width: 7, top: 48, left: 52 },
  { width: 11, top: 50, left: 50 },

  { width: 11, top: 49, left: 50 },
  { width: 12, top: 50, left: 49 },
  { width: 13, top: 49, left: 49 },
  { width: 15, top: 49, left: 48 },
  { width: 12, top: 51, left: 49 },
  { width: 12, top: 50, left: 49 },
  { width: 3, top: 49, left: 52 },
  { width: 8, top: 48, left: 50 },
  { width: 8, top: 50, left: 50 },
  { width: 10, top: 50, left: 50 },

  { width: 10, top: 51, left: 50 },
  { width: 8, top: 48, left: 50 },
  { width: 10, top: 50, left: 50 },
  { width: 15, top: 51, left: 49 },
  { width: 8, top: 49, left: 50 },
  { width: 11, top: 51, left: 50 },
  { width: 12, top: 49, left: 50 },
  { width: 10, top: 50, left: 50 },
  { width: 12, top: 52, left: 50 },
  { width: 16, top: 49, left: 50 },

  { width: 16, top: 49, left: 45 },
  { width: 11, top: 51, left: 51 },
  { width: 7, top: 51, left: 51 },
  { width: 5, top: 51, left: 51 },
  { width: 9, top: 46, left: 50 },
  { width: 38, top: 44, left: 41 },
  { width: 12, top: 48, left: 46 },
  { width: 8, top: 51, left: 51 },
  { width: 10, top: 51, left: 50 },
  { width: 10, top: 51, left: 50 },
  { width: 10, top: 51, left: 50 },
  { width: 5, top: 52, left: 49 },
  { width: 21, top: 51, left: 46 },
  { width: 10, top: 51, left: 50 },
  { width: 17, top: 38, left: 49 },
  { width: 10, top: 49, left: 51 },
  { width: 10, top: 46, left: 49 },
  { width: 10, top: 51, left: 51 },
  { width: 11, top: 45, left: 51 },

  { width: 11, top: 47, left: 51 },
  { width: 11, top: 48, left: 52 },
  { width: 11, top: 48, left: 55 },
  { width: 11, top: 45, left: 51 },
  { width: 8, top: 45, left: 52 },

  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },

  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },

  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },

  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },

  { width: 11, top: 47, left: 51 }, // 口 96
  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },

  { width: 11, top: 47, left: 51 }, // 口 101
  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },

  { width: 11, top: 47, left: 51 }, // 口 106
  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },
  { width: 11, top: 47, left: 51 },

  { width: 95, top: 137, left: 144 }, // 口 110
  { width: 60, top: 130, left: 186 },
  { width: 24, top: 134, left: 222 },
  { width: 23, top: 131, left: 224 },
  { width: 89, top: 114, left: 199 },

  { width: 95, top: 137, left: 144 }, // 口 115
  { width: 60, top: 130, left: 186 },
  { width: 24, top: 134, left: 222 },
  { width: 23, top: 131, left: 224 },
  { width: 89, top: 114, left: 199 },

  { width: 95, top: 137, left: 144 }, // 口 120
  { width: 60, top: 130, left: 186 },
  { width: 24, top: 134, left: 222 },
  { width: 23, top: 131, left: 224 },
  { width: 89, top: 114, left: 199 },

  { width: 95, top: 137, left: 144 }, // 口 125
  { width: 60, top: 130, left: 186 },
  { width: 24, top: 134, left: 222 },
  { width: 23, top: 131, left: 224 },
  { width: 89, top: 114, left: 199 },

  { width: 95, top: 137, left: 144 }, // 口 130
  { width: 60, top: 130, left: 186 },
  { width: 24, top: 134, left: 222 },
  { width: 23, top: 131, left: 224 },
  { width: 89, top: 114, left: 199 },

  { width: 95, top: 137, left: 144 }, // 口 135
  { width: 60, top: 130, left: 186 },
  { width: 24, top: 134, left: 222 },
  { width: 23, top: 131, left: 224 },
  { width: 89, top: 114, left: 199 },

  { width: 95, top: 137, left: 144 }, // 口 140
  { width: 60, top: 130, left: 186 },
  { width: 24, top: 134, left: 222 },
  { width: 23, top: 131, left: 224 },
  { width: 89, top: 114, left: 199 },

  { width: 95, top: 137, left: 144 }, // 口 145
  { width: 60, top: 130, left: 186 },
  { width: 24, top: 134, left: 222 },
  { width: 23, top: 131, left: 224 },
  { width: 89, top: 114, left: 199 },

  { width: 95, top: 137, left: 144 }, // 口 150
  { width: 60, top: 130, left: 186 },
  { width: 24, top: 134, left: 222 },
  { width: 23, top: 131, left: 224 },
  { width: 89, top: 114, left: 199 },

  { width: 95, top: 137, left: 144 }, // 口 155
  { width: 60, top: 130, left: 186 },
  { width: 24, top: 134, left: 222 },
  { width: 23, top: 131, left: 224 },
  { width: 89, top: 114, left: 199 },

  { width: 95, top: 137, left: 144 }, // 口 160
  { width: 60, top: 130, left: 186 },
  { width: 24, top: 134, left: 222 },
  { width: 23, top: 131, left: 224 },
  { width: 89, top: 114, left: 199 },

  { width: 95, top: 137, left: 144 }, // 口 165
  { width: 60, top: 130, left: 186 },
  { width: 24, top: 134, left: 222 },
  { width: 23, top: 131, left: 224 },
  { width: 89, top: 114, left: 199 },

  { width: 95, top: 137, left: 144 }, // 口 170
  { width: 60, top: 130, left: 186 },
  { width: 24, top: 134, left: 222 },
  { width: 23, top: 131, left: 224 },
  { width: 89, top: 114, left: 199 },
];

// 服
export const clothAppearanceInPresentModal: FixedHeightAndPosition[] = [
  { height: 56, top: 58, left: 42 },
  { height: 56, top: 58, left: 42 },
  { height: 56, top: 58, left: 42 },
  { height: 55, top: 57, left: 38 },
  { height: 55, top: 57, left: 38 },
  { height: 55, top: 57, left: 38 },
  { height: 56, top: 57, left: 37 },
  { height: 56, top: 58, left: 42 },
  { height: 56, top: 58, left: 42 },
  { height: 56, top: 58, left: 42 },

  // 以下有料プロダクト
  { height: 55, top: 57, left: 38 },
  { height: 55, top: 57, left: 38 },
  { height: 55, top: 57, left: 38 },
  { height: 56, top: 57, left: 37 },
  { height: 53, top: 57, left: 42 },
  { height: 56, top: 58, left: 37 },
  { height: 56, top: 58, left: 37 },
  { height: 56, top: 58, left: 37 },
  { height: 54, top: 57, left: 38 },
  { height: 54, top: 57, left: 38 },

  { height: 52, top: 58, left: 39 },
  { height: 52, top: 58, left: 39 },
  { height: 52, top: 58, left: 39 },
  { height: 52, top: 58, left: 39 },
  { height: 58, top: 56, left: 35 },
  { height: 55, top: 58, left: 43 },
  { height: 57, top: 57, left: 37 },
  { height: 55, top: 57, left: 36 },
  { height: 55, top: 57, left: 36 },
  { height: 54, top: 57, left: 39 },

  { height: 54, top: 57, left: 39 },
  { height: 54, top: 57, left: 34 },
  { height: 54, top: 57, left: 34 },
  { height: 54, top: 57, left: 38 },
  { height: 54, top: 57, left: 35 },
  { height: 55, top: 57, left: 37 },
  { height: 53, top: 58, left: 34 },
  { height: 53, top: 58, left: 34 },
  { height: 56, top: 56, left: 38 },
  { height: 55, top: 57, left: 33 },

  { height: 47, top: 53, left: 35 },
  { height: 55, top: 55, left: 25 },
  { height: 56, top: 56, left: 46 },
  { height: 61, top: 49, left: 37 },
  { height: 52, top: 59, left: 41 },
  { height: 54, top: 56, left: 39 },
  { height: 54, top: 58, left: 41 },
  { height: 54, top: 58, left: 42 },
  { height: 53, top: 58, left: 40 },
  { height: 53, top: 57, left: 40 },

  { height: 62, top: 51, left: 35 },
  { height: 53, top: 57, left: 38 },
  { height: 52, top: 58, left: 39 },
  { height: 53, top: 57, left: 39 },
  { height: 54, top: 58, left: 39 },
  { height: 55, top: 57, left: 42 },
  { height: 53, top: 58, left: 43 },
  { height: 53, top: 58, left: 43 },
  { height: 53, top: 58, left: 32 },
  { height: 54, top: 57, left: 37 },

  { height: 55, top: 57, left: 33 },
  { height: 55, top: 57, left: 35 },
  { height: 60, top: 58, left: 34 },
  { height: 55, top: 56, left: 29 },
  { height: 54, top: 58, left: 34 },
  { height: 55, top: 57, left: 31 },
  { height: 55, top: 57, left: 33 },
  { height: 55, top: 57, left: 39 },
  { height: 55, top: 57, left: 39 },
  { height: 54, top: 58, left: 38 },

  { height: 55, top: 57, left: 38 },
  { height: 55, top: 57, left: 31 },
  { height: 54, top: 57, left: 35 },
  { height: 54, top: 58, left: 35 },
  { height: 54, top: 56, left: 39 },
  { height: 54, top: 57, left: 38 },
  { height: 55, top: 57, left: 30 },
  { height: 55, top: 57, left: 32 },
  { height: 55, top: 57, left: 36 },
  { height: 55, top: 57, left: 37 },

  { height: 55, top: 57, left: 39 },
  { height: 56, top: 57, left: 38 },
  { height: 59, top: 51, left: 28 },
  { height: 55, top: 55, left: 34 },
  { height: 55, top: 57, left: 35 },
  { height: 54, top: 57, left: 35 },
  { height: 55, top: 57, left: 38 },
  { height: 55, top: 56, left: 37 },
  { height: 56, top: 55, left: 35 },
  { height: 54, top: 57, left: 19 },
  { height: 54, top: 57, left: 30 },
  { height: 54, top: 56, left: 39 },
  { height: 54, top: 57, left: 39 },
  { height: 78, top: 27, left: 15 },
  { height: 53, top: 57, left: 43 },
  { height: 53, top: 57, left: 43 },
  { height: 54, top: 57, left: 39 },
  { height: 55, top: 56, left: 36 },
  { height: 55, top: 56, left: 39 },
  { height: 68, top: 45, left: 26 },

  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 41 },
  { height: 55, top: 56, left: 39 },
  { height: 53, top: 57, left: 38 },
  { height: 58, top: 52, left: 34 },

  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },

  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },

  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },

  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },

  { height: 53, top: 57, left: 38 },  // 服 136
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },

  { height: 53, top: 57, left: 38 },  // 服 141
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },

  { height: 53, top: 57, left: 38 },  // 服 146
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },
  { height: 53, top: 57, left: 38 },

  { height: 126, top: 154, left: 180 }, // 服 151
  { height: 145, top: 142, left: 161 },
  { height: 128, top: 146, left: 181 },
  { height: 187, top: 97, left: 125 },
  { height: 130, top: 153,  left: 190 },

  { height: 126, top: 154, left: 180 }, // 服 156
  { height: 145, top: 142, left: 161 },
  { height: 128, top: 146, left: 181 },
  { height: 187, top: 97, left: 125 },
  { height: 130, top: 153,  left: 190 },

  { height: 126, top: 154, left: 180 }, // 服 161
  { height: 145, top: 142, left: 161 },
  { height: 128, top: 146, left: 181 },
  { height: 187, top: 97, left: 125 },
  { height: 130, top: 153,  left: 190 },

  { height: 126, top: 154, left: 180 }, // 服 166
  { height: 145, top: 142, left: 161 },
  { height: 128, top: 146, left: 181 },
  { height: 187, top: 97, left: 125 },
  { height: 130, top: 153,  left: 190 },

  { height: 126, top: 154, left: 180 }, // 服 171
  { height: 145, top: 142, left: 161 },
  { height: 128, top: 146, left: 181 },
  { height: 187, top: 97, left: 125 },
  { height: 130, top: 153,  left: 190 },

  { height: 126, top: 154, left: 180 }, // 服 176
  { height: 145, top: 142, left: 161 },
  { height: 128, top: 146, left: 181 },
  { height: 187, top: 97, left: 125 },
  { height: 130, top: 153,  left: 190 },

  { height: 126, top: 154, left: 180 }, // 服 181
  { height: 145, top: 142, left: 161 },
  { height: 128, top: 146, left: 181 },
  { height: 187, top: 97, left: 125 },
  { height: 130, top: 153,  left: 190 },

  { height: 126, top: 154, left: 180 }, // 服 186
  { height: 145, top: 142, left: 161 },
  { height: 128, top: 146, left: 181 },
  { height: 187, top: 97, left: 125 },
  { height: 130, top: 153,  left: 190 },

  { height: 126, top: 154, left: 180 }, // 服 191
  { height: 145, top: 142, left: 161 },
  { height: 128, top: 146, left: 181 },
  { height: 187, top: 97, left: 125 },
  { height: 130, top: 153,  left: 190 },

  { height: 126, top: 154, left: 180 }, // 服 196
  { height: 145, top: 142, left: 161 },
  { height: 128, top: 146, left: 181 },
  { height: 187, top: 97, left: 125 },
  { height: 130, top: 153,  left: 190 },

  { height: 126, top: 154, left: 180 }, // 服 201
  { height: 145, top: 142, left: 161 },
  { height: 128, top: 146, left: 181 },
  { height: 187, top: 97, left: 125 },
  { height: 130, top: 153,  left: 190 },

  { height: 126, top: 154, left: 180 }, // 服 206
  { height: 145, top: 142, left: 161 },
  { height: 128, top: 146, left: 181 },
  { height: 187, top: 97, left: 125 },
  { height: 130, top: 153,  left: 190 },

  { height: 126, top: 154, left: 180 }, // 服 211
  { height: 145, top: 142, left: 161 },
  { height: 128, top: 146, left: 181 },
  { height: 187, top: 97, left: 125 },
  { height: 130, top: 153,  left: 190 },
];

// 髪
export const hairAppearanceInPresentModal: FixedWidthAndPosition[] = [
  { width: 50, top: 7, left: 38 },
  { width: 50, top: 7, left: 38 },
  { width: 49, top: 8, left: 39 },
  { width: 75, top: 8, left: 28 },
  { width: 50, top: 7, left: 37 },
  { width: 51, top: 7, left: 38 },
  { width: 50, top: 8, left: 38 },
  { width: 46, top: 9, left: 40 },
  { width: 47, top: 9, left: 39 },
  { width: 49, top: 9, left: 39 },

  { width: 48, top: 8, left: 38 },
  { width: 55, top: 9, left: 35 },
  { width: 55, top: 8, left: 37 },
  { width: 58, top: 9, left: 38 },
  { width: 49, top: 8, left: 38 },
  { width: 52, top: 6, left: 37 },
  { width: 51, top: 9, left: 35 },
  { width: 50, top: 7, left: 37 },
  { width: 47, top: 7, left: 40 },
  { width: 58, top: 7, left: 31 },

  // 以下有料プロダクト
  { width: 54, top: 10, left: 36 },
  { width: 46, top: 9, left: 40 },
  { width: 45, top: 9, left: 39 },
  { width: 53, top: 7, left: 36 },
  { width: 46, top: 9, left: 40 },
  { width: 46, top: 9, left: 40 },
  { width: 50, top: 9, left: 36 },
  { width: 46, top: 1, left: 39 },
  { width: 50, top: 9, left: 41 },
  { width: 47, top: 9, left: 40 },

  { width: 53, top: 9, left: 38 },
  { width: 50, top: 3, left: 38 },
  { width: 44, top: 8, left: 41 },
  { width: 49, top: 8, left: 36 },
  { width: 42, top: 1, left: 41 },
  { width: 54, top: 9, left: 35 },
  { width: 55, top: 8, left: 36 },
  { width: 47, top: 8, left: 39 },
  { width: 46, top: 8, left: 40 },
  { width: 54, top: 7, left: 37 },

  { width: 50, top: 5, left: 38 },
  { width: 58, top: 9, left: 35 },
  { width: 54, top: 2, left: 41 },
  { width: 51, top: 9, left: 39 },
  { width: 52, top: 7, left: 37 },
  { width: 45, top: 7, left: 39 },
  { width: 46, top: 9, left: 40 },
  { width: 44, top: 7, left: 41 },
  { width: 50, top: 7, left: 35 },
  { width: 38, top: 6, left: 53 },

  { width: 66, top: 10, left: 33 },
  { width: 43, top: 8, left: 41 },
  { width: 45, top: 8, left: 43 },
  { width: 52, top: 9, left: 38 },
  { width: 48, top: 8, left: 38 },
  { width: 54, top: 0, left: 36 },
  { width: 52, top: 3, left: 37 },
  { width: 50, top: 7, left: 36 },
  { width: 52, top: 7, left: 37 },
  { width: 46, top: 8, left: 39 },

  { width: 46, top: 8, left: 39 },
  { width: 56, top: 7, left: 33 },
  { width: 50, top: 9, left: 37 },
  { width: 48, top: 5, left: 38 },
  { width: 65, top: 8, left: 31 },
  { width: 44, top: 7, left: 40 },
  { width: 51, top: 8, left: 38 },
  { width: 48, top: 8, left: 38 },
  { width: 56, top: 8, left: 33 },
  { width: 53, top: 9, left: 38 },
  { width: 47, top: 9, left: 38 }, //ok
  { width: 53, top: 9, left: 36 }, //ok
  { width: 53, top: 9, left: 34 },
  { width: 51, top: 6, left: 37 },
  { width: 65, top: 4, left: 40 },

  { width: 60, top: 7, left: 33 },
  { width: 57, top: 6, left: 34 },
  { width: 60, top: 4, left: 33 },
  { width: 52, top: 4, left: 32 },
  { width: 57, top: 4, left: 35 },

  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },

  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },

  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },

  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },

  { width: 60, top: 7, left: 33 }, // 髪 101
  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },

  { width: 60, top: 7, left: 33 }, // 髪 106
  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },

  { width: 60, top: 7, left: 33 }, // 髪 111
  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },
  { width: 60, top: 7, left: 33 },

  { width: 138, top: 36, left: 191 }, // 髪116
  { width: 157, top: 13, left: 170 },
  { width: 133, top: 39, left: 178 },
  { width: 122, top: 16, left: 190 },
  { width: 209, top: 23, left: 142 },

  { width: 138, top: 36, left: 191 }, // 髪121
  { width: 157, top: 13, left: 170 },
  { width: 133, top: 39, left: 178 },
  { width: 122, top: 16, left: 190 },
  { width: 209, top: 23, left: 142 },

  { width: 138, top: 36, left: 191 }, // 髪126
  { width: 157, top: 13, left: 170 },
  { width: 133, top: 39, left: 178 },
  { width: 122, top: 16, left: 190 },
  { width: 209, top: 23, left: 142 },

  { width: 138, top: 36, left: 191 }, // 髪131
  { width: 157, top: 13, left: 170 },
  { width: 133, top: 39, left: 178 },
  { width: 122, top: 16, left: 190 },
  { width: 209, top: 23, left: 142 },

  { width: 138, top: 36, left: 191 }, // 髪136
  { width: 157, top: 13, left: 170 },
  { width: 133, top: 39, left: 178 },
  { width: 122, top: 16, left: 190 },
  { width: 209, top: 23, left: 142 },

  { width: 138, top: 36, left: 191 }, // 髪141
  { width: 157, top: 13, left: 170 },
  { width: 133, top: 39, left: 178 },
  { width: 122, top: 16, left: 190 },
  { width: 209, top: 23, left: 142 },

  { width: 138, top: 36, left: 191 }, // 髪146
  { width: 157, top: 13, left: 170 },
  { width: 133, top: 39, left: 178 },
  { width: 122, top: 16, left: 190 },
  { width: 209, top: 23, left: 142 },

  { width: 138, top: 36, left: 191 }, // 髪151
  { width: 157, top: 13, left: 170 },
  { width: 133, top: 39, left: 178 },
  { width: 122, top: 16, left: 190 },
  { width: 209, top: 23, left: 142 },

  { width: 138, top: 36, left: 191 }, // 髪156
  { width: 157, top: 13, left: 170 },
  { width: 133, top: 39, left: 178 },
  { width: 122, top: 16, left: 190 },
  { width: 209, top: 23, left: 142 },

  { width: 138, top: 36, left: 191 }, // 髪161
  { width: 157, top: 13, left: 170 },
  { width: 133, top: 39, left: 178 },
  { width: 122, top: 16, left: 190 },
  { width: 209, top: 23, left: 142 },

  { width: 138, top: 36, left: 191 }, // 髪166
  { width: 157, top: 13, left: 170 },
  { width: 133, top: 39, left: 178 },
  { width: 122, top: 16, left: 190 },
  { width: 209, top: 23, left: 142 },

  { width: 138, top: 36, left: 191 }, // 髪171
  { width: 157, top: 13, left: 170 },
  { width: 133, top: 39, left: 178 },
  { width: 122, top: 16, left: 190 },
  { width: 209, top: 23, left: 142 },

  { width: 138, top: 36, left: 191 }, // 髪176
  { width: 157, top: 13, left: 170 },
  { width: 133, top: 39, left: 178 },
  { width: 122, top: 16, left: 190 },
  { width: 209, top: 23, left: 142 },
];

// 帽子
export const hatAppearanceInPresentModal: FixedWidthAndPosition[] = [
  { width: 50, top: 4, left: 37 },
  { width: 50, top: 4, left: 37 },
  { width: 51, top: 2, left: 37 },
  { width: 51, top: 2, left: 37 },
  { width: 56, top: 2, left: 30 },
  { width: 56, top: 2, left: 30 },
  // 以下有料プロダクト
  { width: 56, top: 0, left: 35 },
  { width: 55, top: 2, left: 36 },
  { width: 62, top: -1, left: 33 },
  { width: 45, top: 13, left: 40 },

  { width: 60, top: 3, left: 30 },
  { width: 46, top: 14, left: 41 },
  { width: 51, top: 13, left: 37 },
  { width: 52, top: 11, left: 36 },
  { width: 52, top: 11, left: 36 },
  { width: 45, top: 14, left: 38 },
  { width: 45, top: 14, left: 38 },
  { width: 58, top: -5, left: 36 },
  { width: 58, top: -5, left: 36 },
  { width: 48, top: 10, left: 35 },

  { width: 48, top: 10, left: 35 },
  { width: 34, top: 14, left: 44 },
  { width: 69, top: -4, left: 36 },
  { width: 48, top: 3, left: 40 },
  { width: 82, top: 2, left: 21 },
  { width: 55, top: 4, left: 36 },
  { width: 52, top: -4, left: 40 },
  { width: 55, top: 2, left: 37 },
  { width: 52, top: -7, left: 36 },
  { width: 46, top: -3, left: 39 },

  { width: 53, top: 17, left: 35 },
  { width: 70, top: -7, left: 32 },
  { width: 69, top: 16, left: 26 },
  { width: 62, top: -3, left: 37 },
  { width: 49, top: 2, left: 36 },
  { width: 30, top: -4, left: 36 },
  { width: 50, top: 1, left: 37 },
  { width: 24, top: 8, left: 63 },
  { width: 28, top: 8, left: 62 },
  { width: 52, top: 20, left: 40 },

  { width: 52, top: 21, left: 35 },
  { width: 42, top: 20, left: 41 },
  { width: 18, top: 20, left: 63 },
  { width: 60, top: 2, left: 33 },
  { width: 63, top: -2, left: 32 },
  { width: 54, top: -3, left: 37 },
  { width: 50, top: -4, left: 34 },
  { width: 64, top: -7, left: 32 },
  { width: 33, top: 2, left: 48 },
  { width: 44, top: 13, left: 38 },

  { width: 60, top: -6, left: 35 },
  { width: 46, top: 4, left: 39 },
  { width: 50, top: -7, left: 38 },
  { width: 48, top: 4, left: 38 },
  { width: 52, top: 6, left: 38 },
  { width: 52, top: 4, left: 37 },
  { width: 62, top: -2, left: 39 },
  { width: 80, top: 2, left: 23 },
  { width: 38, top: -2, left: 55 },
  { width: 58, top: -5, left: 35 },

  { width: 56, top: -1, left: 35 },
  { width: 60, top: -4, left: 35 },
  { width: 64, top: 0, left: 31 },
  { width: 60, top: -8, left: 31 },
  { width: 72, top: 0, left: 29 },
  { width: 60, top: 1, left: 28 },
  { width: 72, top: -9, left: 27 },
  { width: 64, top: -10, left: 31 },
  { width: 83, top: -2, left: 23 },
  { width: 48, top: 3, left: 42 },

  { width: 52, top: -2, left: 35 },
  { width: 40, top: -2, left: 43 },
  { width: 48, top: -4, left: 38 },
  { width: 49, top: 10, left: 39 },
  { width: 68, top: -2, left: 30 },
  { width: 48, top: -4, left: 37 },
  { width: 35, top: 10, left: 42 },
  { width: 30, top: -6, left: 49 },
  { width: 33, top: -11, left: 43 },
  { width: 60, top: -6, left: 35 },
  { width: 62, top: -4, left: 27 },
  { width: 57, top: -8, left: 31 },
  { width: 51, top: 0, left: 35 },
  { width: 75, top: -8, left: 27 },

  { width: 72, top: -2, left: 27 },
  { width: 45, top: 7, left: 37 },
  { width: 53, top: 4, left: 35 },
  { width: 57, top: 2, left: 28 },
  { width: 50, top: 1, left: 39 },

  { width: 72, top: -2, left: 27 },
  { width: 72, top: -2, left: 27 },
  { width: 72, top: -2, left: 27 },
  { width: 72, top: -2, left: 27 },
  { width: 72, top: -2, left: 27  },

  { width: 72, top: -2, left: 27 },
  { width: 72, top: -2, left: 27 },
  { width: 72, top: -2, left: 27 },
  { width: 72, top: -2, left: 27 },
  { width: 72, top: -2, left: 27  },

  { width: 72, top: -2, left: 27 },
  { width: 72, top: -2, left: 27 },
  { width: 72, top: -2, left: 27 },
  { width: 72, top: -2, left: 27 },
  { width: 72, top: -2, left: 27  },

  { width: 72, top: -2, left: 27  },
  { width: 72, top: -2, left: 27  },
  { width: 72, top: -2, left: 27  },
  { width: 72, top: -2, left: 27  },
  { width: 72, top: -2, left: 27  },
  { width: 72, top: -2, left: 27  },
  { width: 72, top: -2, left: 27  },

  { width: 72, top: -2, left: 27 }, // 帽子 112
  { width: 72, top: -2, left: 27 },
  { width: 72, top: -2, left: 27 },
  { width: 72, top: -2, left: 27 },
  { width: 72, top: -2, left: 27 },

  { width: 72, top: -2, left: 27 }, // 帽子 117
  { width: 72, top: -2, left: 27 },
  { width: 72, top: -2, left: 27 },
  { width: 72, top: -2, left: 27 },
  { width: 72, top: -2, left: 27 },

  { width: 72, top: -2, left: 27 }, // 帽子 122
  { width: 72, top: -2, left: 27 },
  { width: 72, top: -2, left: 27 },
  { width: 72, top: -2, left: 27 },
  { width: 72, top: -2, left: 27 },

  { width: 153, top: 13, left: 175 }, //帽子 127
  { width: 112, top: -14, left: 191 },
  { width: 124, top: 16, left: 191 },
  { width: 110, top: 37, left: 185 },
  { width: 143, top: 15, left: 174 },

  { width: 153, top: 13, left: 175 }, //帽子 132
  { width: 112, top: -14, left: 191 },
  { width: 124, top: 16, left: 191 },
  { width: 110, top: 37, left: 185 },
  { width: 143, top: 15, left: 174 },

  { width: 153, top: 13, left: 175 }, //帽子 137
  { width: 112, top: -14, left: 191 },
  { width: 124, top: 16, left: 191 },
  { width: 110, top: 37, left: 185 },
  { width: 143, top: 15, left: 174 },

  { width: 153, top: 13, left: 175 }, //帽子 142
  { width: 112, top: -14, left: 191 },
  { width: 124, top: 16, left: 191 },
  { width: 110, top: 37, left: 185 },
  { width: 143, top: 15, left: 174 },

  { width: 153, top: 13, left: 175 }, //帽子 147
  { width: 112, top: -14, left: 191 },
  { width: 124, top: 16, left: 191 },
  { width: 110, top: 37, left: 185 },
  { width: 143, top: 15, left: 174 },

  { width: 153, top: 13, left: 175 }, //帽子 152
  { width: 112, top: -14, left: 191 },
  { width: 124, top: 16, left: 191 },
  { width: 110, top: 37, left: 185 },
  { width: 143, top: 15, left: 174 },

  { width: 153, top: 13, left: 175 }, //帽子 157
  { width: 112, top: -14, left: 191 },
  { width: 124, top: 16, left: 191 },
  { width: 110, top: 37, left: 185 },
  { width: 143, top: 15, left: 174 },

  { width: 153, top: 13, left: 175 }, //帽子 162
  { width: 112, top: -14, left: 191 },
  { width: 124, top: 16, left: 191 },
  { width: 110, top: 37, left: 185 },
  { width: 143, top: 15, left: 174 },

  { width: 153, top: 13, left: 175 }, //帽子 167
  { width: 112, top: -14, left: 191 },
  { width: 124, top: 16, left: 191 },
  { width: 110, top: 37, left: 185 },
  { width: 143, top: 15, left: 174 },

  { width: 153, top: 13, left: 175 }, //帽子 172
  { width: 112, top: -14, left: 191 },
  { width: 124, top: 16, left: 191 },
  { width: 110, top: 37, left: 185 },
  { width: 143, top: 15, left: 174 },

  { width: 153, top: 13, left: 175 }, //帽子 177
  { width: 112, top: -14, left: 191 },
  { width: 124, top: 16, left: 191 },
  { width: 110, top: 37, left: 185 },
  { width: 143, top: 15, left: 174 },

  { width: 153, top: 13, left: 175 }, //帽子 182
  { width: 112, top: -14, left: 191 },
  { width: 124, top: 16, left: 191 },
  { width: 110, top: 37, left: 185 },
  { width: 143, top: 15, left: 174 },

  { width: 153, top: 13, left: 175 }, //帽子 187
  { width: 112, top: -14, left: 191 },
  { width: 124, top: 16, left: 191 },
  { width: 110, top: 37, left: 185 },
  { width: 143, top: 15, left: 174 },
];
