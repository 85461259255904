import React from 'react';
import styled from 'styled-components';

// @ts-ignore
const ASSETS_BG = RailsAssetPath('lp/common/bg.png');

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  background: url(${ASSETS_BG}) no-repeat;
  background-size: cover;
  background-position: center;
`;

const Background = () => {
  return <Wrapper />;
};

export default Background;
