export function getQueryString() {
  const qs = location.search;
  if (!qs) {
    return null;
  }

  const params = {};
  const arr = qs.substring(1).split('&');

  for (let i = 0; i < arr.length; i++) {
    const index = arr[i].search(/[^\w\s]/g);
    if (index < 0) return false;
    const name = arr[i].slice(0, index);
    params[name] = arr[i].substring(index + 1);
  }

  return params;
}

export function convertMinutesToTime(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const time = `${hours}:${String(remainingMinutes).padStart(2, '0')}`;
  return time;
}

// constants
// TODO: 多くなったらファイルを分ける
export const USER_ROLE = {
  MASTER: "master",
  MEMBER: "member",
}

export const SHOW_FEED_TYPE = {
  TODO: 0,
  REPORT: 1,
  FORUM: 2,
  SURVEY: 3
};

// JSONデータ内の<span>タグを解釈し、HTMLとして表示
export const renderHTML = (htmlString) => {
  const div = document.createElement('div');
  div.innerHTML = htmlString;
  return { __html: div.innerHTML };
};