import React, {
  useState,
  useEffect,
  useCallback,
  useGlobal,
  useMemo,
} from 'reactn';
import axios from 'axios';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import {
  Typography,
  Grid,
  Button,
  Checkbox,
  Link,
  FormControlLabel,
  TextField,
  Box,
  Divider,
} from '@material-ui/core';
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
  Elements,
} from '@stripe/react-stripe-js';
import usePaymentContext from '../payment/hooks/usePaymentContext';
import { FORM_TYPES } from './constants';
import InputNumber from './input/InputNumber';
import InputCardNumber from './input/InputCardNumber';
import BuyComplete from './BuyComplete';
import ChangePaymentMethodButton from './buttons/ChangePaymentMethodButton';
import StripeInput from './input/StripeInput';
import ProgressBackdrop from './ProgressBackdrop';
import { useStyles } from './hooks/useStyles';
import { Trans, useTranslation } from 'react-i18next';

const BuyForm = ({ formType, setFormType }) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const {
    paymentConfig,
    fetchConfig,
    cardInputComplete,
    setCardInputComplete,
  } = usePaymentContext();
  const [paymentChargeQuantity, setPaymentChargeQuantity] = useGlobal(
    'paymentChargeQuantity'
  );
  const [paymentCarnumberInputDialogOpen, setPaymentCarnumberInputDialogOpen] =
    useGlobal('paymentCarnumberInputDialogOpen');
  const [subscriptionPlanId, setSubscriptionPlanId] = useState(null);
  const [isCardSave, setIsCardSave] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [inProgres, setInProgress] = useState(false);
  const [paymentRequire, setPaymentRequire] = useState(true);
  const [formCompleteType, setFormCompleteType] = useState(
    FORM_TYPES.SUBSCRIPTION_BUY
  );

  useEffect(() => {
    if (paymentConfig) {
      if (
        (formType == FORM_TYPES.SUBSCRIPTION_BUY ||
          formType == FORM_TYPES.SUBSCRIPTION_UPDATE) &&
        paymentConfig.available_plan
      ) {
        setFormType(FORM_TYPES.SUBSCRIPTION_UPDATE);
      }
    }
  }, [paymentConfig]);

  const handleSubmit = async (event) => {
    setInProgress(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await createClientSecret(formType)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        const message = error.response.data.message;
        alert(message);
        setInProgress(false);
      });

    if (result) {
      let paymentMethod;
      if (paymentConfig.is_card_save) {
        paymentMethod = {
          payment_method: paymentConfig.default_payment_method.id,
          payment_method_options: {
            card: {
              cvc: elements.getElement(CardCvcElement),
            },
          },
        };
        if (formType == FORM_TYPES.SUBSCRIPTION_UPDATE) {
          // setUpIntentの場合はCVCチェックができない
          delete paymentMethod['payment_method_options'];
        }
      } else {
        paymentMethod = {
          payment_method: {
            type: 'card',
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: cardInputComplete.name,
              address: {
                country: cardInputComplete.country,
              },
            },
          },
        };
      }

      let confirmCard = await cardNumberSend(
        result.clientSecret,
        paymentMethod,
        formType,
        result.confirm_type
      );
      if (confirmCard.error) {
        alert(confirmCard.error.message);
        setInProgress(false);
        return;
      }
      let intent = confirmCard.paymentIntent || confirmCard.setupIntent;
      if (intent.status == 'succeeded') {
        let params = {
          payment_method: intent.payment_method,
          subscription_id: result.subscriptionId || null,
          is_save: isCardSave,
        };
        completeSend(params, formType).then((res) => {
          fetchConfig();
          setFormCompleteType(formType);
          setIsComplete(true);
          setInProgress(false);
        });
      }
      if (confirmCard.error) {
        alert(confirmCard.error.message);
        setInProgress(false);
      }
    }
  };

  const createClientSecret = async (formType) => {
    let params = {
      quantity: paymentChargeQuantity,
    };
    switch (formType) {
      case FORM_TYPES.CHARGE:
        return await axios.post('/api/v1/billing/charges', params);
      case FORM_TYPES.SUBSCRIPTION_BUY:
        return await axios.post('/api/v1/billing/subscriptions', params);
      case FORM_TYPES.SUBSCRIPTION_UPDATE:
        return await axios.put(
          '/api/v1/billing/subscriptions/' + paymentConfig.available_plan.id,
          params
        );
    }
  };

  const cardNumberSend = async (
    clientSecret,
    paymentMethod,
    formType,
    confirm_type
  ) => {
    switch (formType) {
      case FORM_TYPES.SUBSCRIPTION_UPDATE:
        if (confirm_type == 'payment_require') {
          return await stripe.confirmCardPayment(clientSecret, paymentMethod);
        } else {
          return await stripe.confirmCardSetup(clientSecret, paymentMethod);
        }
      default:
        return await stripe.confirmCardPayment(clientSecret, paymentMethod);
    }
  };

  const completeSend = async (params, formType) => {
    switch (formType) {
      case FORM_TYPES.CHARGE:
        return await axios.post('/api/v1/billing/charges/complete', params);
      default:
        return await axios.post(
          '/api/v1/billing/subscriptions/complete',
          params
        );
    }
  };

  const handleCardSaveChange = (event) => {
    setIsCardSave(event.target.checked);
  };

  const isDisabled = useCallback(() => {
    if (paymentConfig) {
      if (paymentConfig.is_card_save && formType !== FORM_TYPES.CHARGE) {
        return false;
      }
      if (cardInputComplete) {
        let val = Object.values(cardInputComplete).filter(
          (element) => element == false
        );
        return val.length != 0 || !stripe;
      }
      return false;
    }
  }, [paymentConfig, cardInputComplete]);

  if (isComplete) {
    return (
      <BuyComplete
        formType={formCompleteType}
        quantity={paymentChargeQuantity}
        basePoint={paymentConfig.charge_base_point}
        baseAmount={paymentConfig.charge_amount}
      />
    );
  }

  return (
    <>
      <Typography variant='h5'>
        {formType == FORM_TYPES.CHARGE
          ? t('PaymentButtonPurchaseMorePoint', {})
          : formType == FORM_TYPES.SUBSCRIPTION_UPDATE
          ? t('PaymentButtonChangeSubscription', {})
          : t('PaymentButtonPurchaseSubscription', {})}
      </Typography>

      <InputNumber formType={formType} />

      <>
        <Subtitle>{t('PaymentTextPaymentMethod', {})}</Subtitle>
        <form onSubmit={handleSubmit}>
          {paymentConfig && paymentConfig.is_card_save ? (
            <Box m={2}>
              <Grid container direction='column' spacing={3}>
                <Grid container item xs={12} justify='space-between'>
                  <Grid item>
                    {paymentConfig.default_payment_method.card.brand}・・・
                    {paymentConfig.default_payment_method.card.last4}
                  </Grid>

                  <Grid item xs={3}>
                    <ChangePaymentMethodButton />
                  </Grid>
                </Grid>
                {formType == FORM_TYPES.CHARGE &&
                  !paymentCarnumberInputDialogOpen && (
                    <>
                      <Grid item>
                        <Typography variant='caption'>
                          {t('PaymentMessageEnterSecuretyCodee', {})}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={8}
                        sm={4}
                        justify='space-between'
                      >
                        <CardCvcText
                          label={t('PaymentTextCvc', {})}
                          name='cvc'
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          required
                          onChange={(event) =>
                            setCardInputComplete({ cvc: event.complete })
                          }
                          InputProps={{
                            inputComponent: StripeInput,
                            inputProps: {
                              component: CardCvcElement,
                            },
                          }}
                        />
                      </Grid>
                    </>
                  )}
              </Grid>
            </Box>
          ) : (
            <InputCardNumber />
          )}

          <ButtonWrapper>
            <Grid
              container
              direction='column'
              justify='flex-end'
              alignItems='center'
              spacing={2}
            >
              {paymentConfig && !paymentConfig.is_card_save && (
                <Grid item>
                  <FormControlLabel
                    value=''
                    control={
                      <Checkbox
                        type='checkbox'
                        checked={isCardSave}
                        color='primary'
                        onChange={handleCardSaveChange}
                      />
                    }
                    labelPlacement='end'
                    label={t('PaymentTextSkip', {})}
                  />
                </Grid>
              )}
              <Grid item>
                <Typography variant='body1'>
                  <Trans i18nKey='PaymentMessageAgree'>
                    続行すると、これらの<Link href='/privacy'>利用規約</Link>
                    に同意したものと見なされます。
                  </Trans>
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  className={`${classes.baseButton} ${classes.submitButton} ${classes.roundedButton}`}
                  type='submit'
                  disabled={isDisabled()}
                >
                  {formType == FORM_TYPES.SUBSCRIPTION_UPDATE
                    ? t('PaymentBuyTypeChange', {})
                    : t('PaymentBuyTypePurchase', {})}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  href='/payment'
                  className={`${classes.baseButton} ${classes.roundedButton}`}
                >
                  {t('PaymentTextBackPlan', {})}
                </Button>
              </Grid>
            </Grid>
          </ButtonWrapper>
        </form>

        <ProgressBackdrop open={inProgres}></ProgressBackdrop>
      </>
    </>
  );
};

const Subtitle = styled.div`
  margin: 10px 0;
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

const CardCvcText = styled(TextField)`
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: 0px;
    background-image: url(${RailsAssetPath('payment/icon/cvc.png')});
    background-size: contain;
    background-repeat: no-repeat;
    width: 13%;
    height: 100%;
  }
`;

export default BuyForm;
