import React from 'react';
import useAudioToggle from '../hooks/useAudioToggle';
import * as style from '../../style/VoiceButtons';

const VoiceChatConfigButton = () => {
  const { microphone, toggleMicrophone, speaker, toggleSpeaker } =
    useAudioToggle();

  const microphoneImage = RailsAssetPath(
    `voice/icon_mic_${microphone ? 'on' : 'off'}.png`
  );

  const speakerImage = RailsAssetPath(
    `voice/volume_${speaker ? 'on' : 'off'}.png`
  );

  return (
    <>
      <style.__VoiceChatButton onClick={toggleMicrophone}>
        <style.__VoiceChatButtonMic microphone={microphone}>
          <img src={microphoneImage} />
        </style.__VoiceChatButtonMic>
      </style.__VoiceChatButton>
      <style.__VoiceChatButton onClick={toggleSpeaker}>
        <style.__VoiceChatButtonMic microphone={speaker}>
          <img src={speakerImage} />
        </style.__VoiceChatButtonMic>
      </style.__VoiceChatButton>
    </>
  );
};

export default VoiceChatConfigButton;
