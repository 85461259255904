import React from 'react';
import styled from 'styled-components';
import { MyboxTemplate } from 'javascript/features/mybox/components';
import { device } from 'javascript/utils/mediaQuery';

const StyledMyboxPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
  padding: 40px 36px 0;
  height: calc(100vh - 111px);
  @media ${device.sp} {
    display: block;
    height: calc(100vh - 44px);
    padding: 12px 24px 8px;
    overflow: auto;
  }
`;

interface Props {
  userId: number | undefined;
}

const Mybox: React.FC<Props> = ({ userId }) => (
  <StyledMyboxPageContainer>
    <MyboxTemplate userId={userId} />
  </StyledMyboxPageContainer>
);

export default Mybox;
