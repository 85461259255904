import React, { useEffect } from 'react';
import { useGlobal } from 'reactn';
import styled from 'styled-components';
import UserProfileTemplate from 'javascript/features/userProfile/components';
import { device } from 'javascript/utils/mediaQuery';

const MemberContainer = styled.div<{
  isQueryTargetAvatar: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isQueryTargetAvatar }) =>
    isQueryTargetAvatar ? '#f6f6f6' : '#fff'};
  padding: 40px 36px 0;
  height: calc(100vh - 111px);
  @media ${device.sp} {
    display: block;
    height: calc(100vh - 44px);
    padding: 40px 24px;
    overflow: auto;
  }
`;

interface Props {
  MypageId: number;
}

const Mypage: React.FC<Props> = ({ MypageId }) => {
  // TODO 型付け
  const [_, setUserId] = useGlobal<{ userId: number }, 'userId'>('userId');

  const isQueryTargetAvatar = location.search.includes('target');

  useEffect(() => {
    setUserId(MypageId);
  }, [MypageId]);

  return (
    <>
      <MemberContainer isQueryTargetAvatar={isQueryTargetAvatar}>
        <UserProfileTemplate isQueryTargetAvatar={isQueryTargetAvatar} />
      </MemberContainer>
    </>
  );
};

export default Mypage;
