import { useCallback, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from "react-responsive";
import Video, { createLocalTracks } from "twilio-video";
import axios from "axios";
import { useSelector } from "react-redux";
import { useIsVideoConnect } from "./useIsVideoConnect";

const useRoom = (localTracks, onError, options) => {
  const [room, setRoom] = useState(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const optionsRef = useRef(options);
  const isMobile = useMediaQuery({ maxWidth: 430 });
  const isGuest = useSelector((state) => state.videoCall.isGuest);
  const roomId = useSelector((state) => state.videoCall.roomId);
  const token = useSelector((state) => state.videoCall.token);
  const videoCall = useSelector((state) => state.videoCall);


  const {connected} = useIsVideoConnect();
  useEffect(() => {
    optionsRef.current = options;
  }, [options]);

  const connect = useCallback(
    token => {
      setIsConnecting(true);
      return Video.connect(token, { ...optionsRef.current, tracks: localTracks }).then(
        newRoom => {
          connectApi(newRoom);
          connected();
          setRoom(newRoom);
          const disconnect = () => newRoom.disconnect();
          newRoom.once('disconnected', () => {
            setTimeout(() => setRoom(null));
            window.removeEventListener('beforeunload', disconnect);

            if (isMobile) {
              window.removeEventListener('pagehide', disconnect);
            }
          });

          window.twilioRoom = newRoom;

          newRoom.localParticipant.videoTracks.forEach(publication =>
            publication.setPriority('low')
          );

          setIsConnecting(false);
          
          window.addEventListener('beforeunload', disconnect);

          if (isMobile) {
            window.addEventListener('pagehide', disconnect);
          }
        },
        error => {
          onError(error);
          setIsConnecting(false);
        }
      );
    },
    [localTracks, onError]
  );

  const connectApi = (newRoom) => {
    const params = {
      remorks_room_id: roomId,
      twilio_room_id: null,
      invite_user_id: null,
      user_identity: newRoom.localParticipant.identity,
      is_guest: isGuest,
      token: token
    }
    return axios.post('/api/v1/twilio/videocalls/connect',params); 
  }


  return { room, connect, isConnecting };
}

export default useRoom;