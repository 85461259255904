import { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { ViketHistory as TypeViketHistory } from 'types';

export const useViketHistory = (avatarId: number) => {
  const [viketHistory, setViketHistory] = useState<TypeViketHistory[]>([]);

  const [page, setPage] = useState(0);

  const target = useRef(null);

  const handleonIntersection = useCallback(() => {
    setPage((prev) => prev + 1);
  }, [setPage]);

  const handleGetCommentsData = useCallback(
    (avatarId: number) => {
      axios
        .get<{ viket_histories: TypeViketHistory[] }>(
          `/api/v1/avatars/viket_history/${avatarId}`
        )
        .then((res) => {
          setViketHistory(res.data.viket_histories);
          setPage(1);
        });
    },
    [setViketHistory, setPage]
  );

  const getNextPageData = useCallback(() => {
    const url = `/api/v1/avatars/viket_history/${avatarId}?page=${page}`;
    axios.get<{ viket_histories: TypeViketHistory[] }>(url).then((res) => {
      const viketHistory = res.data.viket_histories;
      setViketHistory((prev) =>
        prev !== viketHistory ? [...prev, ...viketHistory] : prev
      );
    });
  }, [avatarId, page, setViketHistory]);

  useEffect(() => {
    handleGetCommentsData(avatarId);
  }, [avatarId]);

  useEffect(() => {
    if (page > 1) {
      getNextPageData();
    }
  }, [page]);

  return { viketHistory, target, handleonIntersection };
};
