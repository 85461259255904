/* eslint-disable @typescript-eslint/no-explicit-any */
const chars = '!<>-_\\/[]{}—=+*^?#________';

export const setText = (ref: HTMLDivElement | null, text: string) => {
  if (!ref) return;

  const { length } = text;
  let frameRequest = 0;
  let frame = 0;
  const queue: string | any[] = [];

  const randomChar = () => {
    return chars[Math.floor(Math.random() * chars.length)];
  };

  const update = () => {
    let output = '';
    let complete = 0;
    for (let i = 0, n = queue.length; i < n; i += 1) {
      const { from, to, start, end } = queue[i];
      let { char } = queue[i];
      if (frame >= end) {
        complete += 1;
        output += to;
      } else if (frame >= start) {
        if (!char || Math.random() < 0.28) {
          char = randomChar();
          queue[i].char = char;
        }
        output += `<span>${char}</span>`;
      } else {
        output += from;
      }
    }
    ref.innerHTML = output;
    if (complete !== queue.length) {
      frameRequest = requestAnimationFrame(update);
      frame += 1;
    }
  };

  for (let i = 0; i < length; i += 1) {
    const from = '';
    const to = text[i] || '';
    const start = Math.floor(Math.random() * 40);
    const end = start + Math.floor(Math.random() * 40);
    queue.push({ from, to, start, end });
  }

  cancelAnimationFrame(frameRequest);
  frame = 0;
  update();
};
