import { useCallback, useState, useEffect,useGlobal } from 'reactn';
import axios from 'axios';
import { useGetOfficeData } from 'javascript/features/realWorld/hooks/useGetOfficeData';
import { useTranslation  } from 'react-i18next';

export const useFilterMenuModal = () => {
  const [isOpenFilterMenuModal, setIsOpenFilterMenuModal] = useState(false);
  const [modalPosition, setModalPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [roomFilterId, setRoomFilterId] = useGlobal<{ roomFilterId: number }>('roomFilterId');
  const { getOfficeData, officeData } = useGetOfficeData();
  const { t } = useTranslation();

  const openMenuModal = useCallback(
    ( e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      const position = e.currentTarget.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const fixedXPosition =
        windowWidth - position.x < 250 ? position.x - 250 : position.x + 64;
      setModalPosition({ x: fixedXPosition, y: position.y - 40 });
      setIsOpenFilterMenuModal(true);
    },
    []
  );

  const closeMenuModal = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsOpenFilterMenuModal(false);
  }, []);

  const closeModalAfterSetFilter = useCallback(
    (_filterId: number) => {
      setFilter(_filterId);
    },
    [roomFilterId]
  );

  const setFilter = useCallback(async (_filterId: number) => {
    await axios.post('/api/v1/users/set_room_filter', {
      filter_id: _filterId
    }).then(() => {
      setRoomFilterId(_filterId);
      getOfficeData();
    }).catch(() => {
      alert(t("AttendanceAlertServerError",{}))
    })
  },[roomFilterId]);

  useEffect(() => {
    if(roomFilterId == undefined && officeData ) {
      setRoomFilterId(officeData?.room_filter_id);
    }
  },[roomFilterId, officeData])
 

  return {
    modalPosition,
    isOpenFilterMenuModal,
    openMenuModal,
    closeMenuModal,
    closeModalAfterSetFilter,
    roomFilterId,
  };
};
