import axios from 'axios';
import useSWR from 'swr';
import { ThanksComments, UnauthorizedError } from 'types';

export const useThanksComments = (officeName: string, page: number) => {
  const fetcher = (url: string) =>
    axios.get<ThanksComments | UnauthorizedError>(url).then(({ data }) => data);
  const { data: thanksComments } = useSWR(
    `/api/v1/thanks/${officeName}?page=${page}`,
    fetcher
  );

  return { thanksComments };
};
