import React from 'react';
import Modal from 'react-modal';
import { useGlobal } from 'reactn';

import FormEnterRoom from '../form/FormEnterRoom';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: '0px',
    background: 'rgba(0,0,0,0)',
  },
  overlay: {
    background: 'rgba(62, 62, 62, 0.75)',
    zIndex: '100',
  },
};

Modal.setAppElement('body'); //react-modal初期設定

const ModalEnterOffice = () => {
  const [isShowEnterOfficeModal, setIsShowEnterOfficeModal] = useGlobal(
    'isShowEnterOfficeModal'
  );

  function openModal() {
    setIsShowEnterOfficeModal(true);
  }
  function closeModal() {
    setIsShowEnterOfficeModal(false);
  }
  return (
    <Modal
      isOpen={isShowEnterOfficeModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Example Modal'
    >
      <FormEnterRoom />
    </Modal>
  );
};

export default ModalEnterOffice;
