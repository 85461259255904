import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import axios from 'axios';
import { GlobalContainer, GlobalComponentWrapper } from './style/Layout';
import Header from './common/Header';
import Office from './page/Office';
import Mypage from './page/Mypage';
import Employees from './page/Employees';
import Attendance from './page/Attendance';
import Diary from './page/Diary';
import Shop from './page/Shop';
import Mybox from './page/Mybox';
import World from './page/World';
// import Payment from './page/Payment';
// import Buy from './page/Buy';
import Loader from './Loader';
import Footer from './common/Footer';
import { connect } from 'react-redux';
import store from '../lib/redux/store';
import { fetchUserData } from '../lib/redux/actions/user';
import VoiceChatProvider from './voice/VoiceChatProvider';
import VoiceChat from './voice/VoiceChat';
import { useIsDuplicatedWindow } from '../shared/hooks/useIsDuplicatedWindow';
import { DisplayAlertMessage } from './DisplayAlertMessage';
import { useIsVideoConnect } from './video_call/hooks/useIsVideoConnect';
import { useTranslation } from 'react-i18next';
import Thanks from './page/Thanks';
import { ModalForceToPay } from './modal/ModalForceToPay';
import styled from 'styled-components';
import { StylesProvider } from '@material-ui/styles';

// import時に内部で使用しているStripeのAPIがアプリの別タブで開いてしまうようなので、遅延ロードに変更して様子を見る
// https://ja.reactjs.org/docs/code-splitting.html#reactlazy
const Payment = lazy(() => import('./page/Payment'));
const Buy = lazy(() => import('./page/Buy'));

const App = ({ currentUser }) => {
  const { isDuplicatedWindow } = useIsDuplicatedWindow();
  const isDuplicated = isDuplicatedWindow();
  const isOfficePage = location.pathname.includes('office');
  const isEmployeesPage = location.pathname.includes('employees');
  const isDiaryPage = location.pathname.includes('diary');
  const isMyPage = location.pathname.includes('mypage');
  const isShopPage = location.pathname.includes('shop');
  const isMyboxPage = location.pathname.includes('mybox');
  const isPaymentPage = location.pathname.includes('payment');
  const isBuyPage = location.pathname.includes('buy');
  const isThanksPage = location.pathname.includes('thanks');
  const isWorld = location.pathname.includes('world');
  const isattendance = location.pathname.includes('attendance');

  const { videoConnectStatus, isVideoConnect, isConnected } =
    useIsVideoConnect();
  const { t, i18n } = useTranslation();

  const isCast = currentUser.role === 'cast';
  const isUnauthorizedPageForCast =
    !isOfficePage && !isMyPage && !isShopPage && !isMyboxPage && !isDiaryPage && !isattendance;

  const isMaster = currentUser.role === 'master';
  const isAuthorizedPageOnlyForMaster = isPaymentPage || isBuyPage;
  const isNonActiveUser = currentUser.role === 'non_active';

  useEffect(() => {
    getCurrentUser();
    isVideoConnect();
    axios.post('/api/v1/twilio/voices/room_leave', {
      end_call: true,
    });
  }, []);

  useEffect(() => {
    getCurrentUser();
  }, [isConnected]);

  useEffect(() => {
    if (currentUser.current_language) {
      i18n.changeLanguage(currentUser.current_language);
    }
  }, [currentUser]);

  const getCurrentUser = () => {
    store.dispatch(fetchUserData());
  };

  if (!currentUser.id && isThanksPage) {
    return <Thanks />;
  }

  if (!currentUser.id) {
    return null;
  }

  if (
    (isCast && isUnauthorizedPageForCast) ||
    (!isMaster && isAuthorizedPageOnlyForMaster) ||
    (isNonActiveUser && !isEmployeesPage)
  ) {
    return (
      <DisplayAlertMessage>
        {t('GenelalAlertNotauthorized', {})}
      </DisplayAlertMessage>
    );
  }

  if (currentUser.charge_control && !isPaymentPage && !isBuyPage) {
    return <ModalForceToPay />;
  }

  if (isDuplicated) {
    return (
      <DisplayAlertMessage>
        {t('GenelalAlertOnlyonetaballowed', {})}
      </DisplayAlertMessage>
    );
  }

  return (
    <>
      <StylesProvider injectFirst>
        <VoiceChatProvider>
          <Router>
            <GlobalContainer>
              {!currentUser.charge_control ? (
                <Header videoConnectStatus={videoConnectStatus} />
              ) : (
                <StyledNoHeader />
              )}
              <GlobalComponentWrapper>
                <Switch>
                  <Route path='/office'>
                    <Office
                      currentUser={currentUser}
                      videoConnectStatus={videoConnectStatus}
                    />
                  </Route>
                  <Route path='/employees'>
                    <Employees />
                  </Route>
                  <Route path='/attendance'>
                    <Attendance />
                  </Route>
                  <Route path='/diary'>
                    <Diary currentUser={currentUser} />
                  </Route>
                  <Route path='/mypage/:id' component={setMypage}></Route>
                  <Route path='/shop'>
                    <Shop userId={currentUser.id} />
                  </Route>
                  <Route path='/mybox'>
                    <Mybox userId={currentUser.id} />
                  </Route>
                  <Route path='/thanks/:id'>
                    <Thanks isLoggedInUser />
                  </Route>
                  <Route path='/payment'>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Payment />
                    </Suspense>
                  </Route>
                  <Route path='/buy/:type' component={setBuyType} />
                  <Route path='/world'>
                    <World
                      avatar={currentUser.avatar}
                      isWorld={isWorld}
                      currentUser={currentUser}
                    />
                  </Route>
                </Switch>
              </GlobalComponentWrapper>
              <Footer />
            </GlobalContainer>
          </Router>
          <Loader i18n={i18n} isWorld={isWorld} />
          <VoiceChat />
        </VoiceChatProvider>
      </StylesProvider>
    </>
  );
};

const StyledNoHeader = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: #fff;
`;

const setMypage = ({ match }) => {
  let id = match.params.id;
  return <Mypage MypageId={id} />;
};

const setBuyType = ({ match }) => {
  let type = match.params.type;
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Buy BuyType={type} />
    </Suspense>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.setUser.currentUser,
  };
};

export default connect(mapStateToProps)(App);
