import React from "react";
import Button from '@material-ui/core/Button';
import useLocalAudioToggle from '../hooks/useLocalAudioToggle';
import useVideoCallContext from '../hooks/useVideoCallContext';

const ToggleAudioButton = ({className, style, disabled}) => {

  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoCallContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');
  return (
    <>
    <Button
    className={className}
    onClick={toggleAudioEnabled}
    // disabled={!hasAudioTrack || disabled}
    data-cy-audio-toggle
    style={style}
    disabled={disabled}
    >
      {isAudioEnabled ? <img src={RailsAssetPath("video_call/icon/mic_on.png")} className="button_icon" /> : <img src={RailsAssetPath("video_call/icon/mic_off.png")} className="button_icon" /> }
      {/* {!hasAudioTrack ? 'No Audio' : isAudioEnabled ? 'Mute' : 'Unmute'} */}
    </Button>
    </>
  )
}

export default ToggleAudioButton;