import React from 'react';
import styled, { keyframes } from 'styled-components';

interface StyledProps {
  isHovered: boolean;
  position: { top: number; left: number };
}

const showTooltip = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

// attrsメソッド記法を使い、mousemoveイベント毎のクラス再生成を抑止する
const StyledTooltip = styled.div.attrs<StyledProps>(({ position }) => ({
  style: {
    // マウスの気持ち上にtooltipがくるようにtopを調整
    top: `${position.top - 24}px`,
    // マウスの位置とtooltipの中心が合うようにleftを調整
    left: `${position.left - 32}px`,
  },
}))<StyledProps>`
  display: ${({ isHovered }) => (isHovered ? 'block' : 'none')};
  position: fixed;
  width: 64px;
  background-color: #666;
  border-radius: 8px;
  color: #fff;
  font-size: 10px;
  text-align: center;
  z-index: 2;
  animation: ${showTooltip} 0.5s ease;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform: scale(0.8);
  }
`;

interface Props extends StyledProps {
  text: string;
}

export const Tooltip: React.FC<Props> = ({ isHovered, position, text }) => (
  <StyledTooltip isHovered={isHovered} position={position}>
    <p>{text}</p>
  </StyledTooltip>
);
