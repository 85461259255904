import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  type: 'nonfunctional' | 'voice' | 'video' | null;
  handleSelectType: (type: 'nonfunctional' | 'voice' | 'video') => void;
}

export const VirtualWorldTypeInput: React.FC<Props> = ({
  type,
  handleSelectType,
}) => {
  const { t } = useTranslation();

  return (
    <StyledVirtualWorldTypeInput>
      <StyledVirtualWorldTypeTitle>
        <Trans i18nKey='RoomTypeType' />
      </StyledVirtualWorldTypeTitle>
      <StyledTypeSelectWrapper>
        <StyledTypeItem
          isSelected={type === null || type === 'nonfunctional'}
          onClick={() => handleSelectType('nonfunctional')}
        >
          <StyledRoomType
            colorType='#5387f4'
            isSelected={type === null || type === 'nonfunctional'}
          >
            {t('RoomTypeNormal', {})}
          </StyledRoomType>
          <StyledTypeImageWrapper
            isSelected={type === null || type === 'nonfunctional'}
          >
            <img
              // @ts-ignore
              src={RailsAssetPath('enter_modal/icon_time.png')}
            />
          </StyledTypeImageWrapper>
        </StyledTypeItem>
        <StyledTypeItem
          isSelected={type === 'voice'}
          onClick={() => handleSelectType('voice')}
        >
          <StyledRoomType colorType='#4fd24b' isSelected={type === 'voice'}>
            {t('RoomTypeVoice', {})}
          </StyledRoomType>
          <StyledTypeImageWrapper isSelected={type === 'voice'}>
            <img
              // @ts-ignore
              src={RailsAssetPath('enter_modal/icon_voice.png')}
            />
          </StyledTypeImageWrapper>
        </StyledTypeItem>
        <StyledTypeItem
          isSelected={type === 'video'}
          onClick={() => handleSelectType('video')}
        >
          <StyledRoomType colorType='#ff8086' isSelected={type === 'video'}>
            {t('RoomTypeVideo', {})}
          </StyledRoomType>
          <StyledTypeImageWrapper isSelected={type === 'video'}>
            <img
              // @ts-ignore
              src={RailsAssetPath('enter_modal/icon_video.png')}
            />
          </StyledTypeImageWrapper>
        </StyledTypeItem>
      </StyledTypeSelectWrapper>
      <StyledTypeTextWrapper>
        <p>
          {type === 'voice'
            ? t('RoomVoiceText', {})
            : type === 'video'
            ? t('RoomVideoText', {})
            : t('RoomTimeText', {})}
        </p>
      </StyledTypeTextWrapper>
    </StyledVirtualWorldTypeInput>
  );
};

const StyledVirtualWorldTypeInput = styled.div`
  margin-top: 24px;
`;

const StyledVirtualWorldTypeTitle = styled.p`
  font-size: 16px;
`;

const StyledTypeSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const StyledTypeItem = styled.div<{ isSelected: boolean }>`
  width: 80px;
  cursor: pointer;
  opacity: ${({ isSelected }) => !isSelected && '0.4'};
`;

const scale = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const animation = () =>
  css`
    animation: ${scale} 0.5s;
  `;

const StyledTypeImageWrapper = styled.div<{ isSelected: boolean }>`
  img {
    height: 40px;
    ${({ isSelected }) => isSelected && animation}
  }
`;

const PseudoElm = css`
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 4px;
  clip-path: polygon(0 0, 100% 0%, 50% 100%);
`;

const StyledRoomType = styled.p<{ colorType: string; isSelected: boolean }>`
  position: relative;
  width: 100%;
  padding: 2px 0;
  margin-bottom: 8px;
  border: 2px solid ${({ colorType }) => colorType};
  border-radius: 12px;
  font-size: 10px;
  color: ${({ isSelected }) => isSelected && '#fff'};
  background-color: ${({ isSelected, colorType }) =>
    isSelected ? colorType : '#fff'};
  &::before {
    ${PseudoElm}
    top: 100%;
    background-color: ${({ isSelected, colorType }) =>
      isSelected ? colorType : '#fff'};
  }
  &::after {
    ${PseudoElm}
    top: 110%;
    width: 8px;
    height: 5px;
    background-color: ${({ colorType }) => colorType};
    z-index: -1;
  }
`;

const StyledTypeTextWrapper = styled.div`
  margin-top: 16px;
  color: #6e6e6e;
  font-size: 12px;
`;
