import React from 'react';
import styled from 'styled-components';
import {
  Button,
  IconButton,
  Grid,
} from '@material-ui/core';
import useVideoCallContext from './hooks/useVideoCallContext';
import { useTranslation } from 'react-i18next';

const BackgroundThumbnail = ({ thumbnail, imagePath, index, url,  handleDelete}) => {
  const { backgroundSettings, setBackgroundSettings } = useVideoCallContext();
  const isImage = thumbnail === 'image' || thumbnail === 'custom';
  const thumbnailSelected = isImage
    ? backgroundSettings.index === index && ( backgroundSettings.type === 'image' || backgroundSettings.type === 'custom') 
    : backgroundSettings.type === thumbnail;
  const icons = {
    none: '',
    image: null,
  };
  const ThumbnailIcon = icons[thumbnail];
  const { t } = useTranslation();

  return (
    <ThumbnailWrapper

      thumbnailSelected={thumbnailSelected}
    >
    { thumbnail == 'custom'  ? (

      <DeleteIcon onClick={handleDelete}>
        <img src={RailsAssetPath('close-img-gray.png')} />
      </DeleteIcon>
    ) : '' }
    <Thumbnail
      onClick={() =>
        setBackgroundSettings({
          type: thumbnail,
          index: index,
          url: url
    })}>
      {thumbnail == 'none' ? <ThumbNone >{t("GenelalTextNone",{})}</ThumbNone>
        : thumbnail == 'custom' ? 
          <ThumbImage src={imagePath} alt='custom'/>
          : <ThumbImage src={RailsAssetPath('video_call/virtual_background/' + imagePath + '_thumbnail.jpg')} />
      }
      </Thumbnail>
 
    </ThumbnailWrapper>
  )
}

const ThumbnailWrapper = styled.div`
  width:240px;
  display:flex;
  justify-content:center;
  align-items:center;
  border-radius:10px;
  padding: 3px;
  color: white;
  ${props => props.thumbnailSelected ? `background-color:#169ce5;` : ``}
  margin:2px;  
  position:relative;
`
const Thumbnail = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
`
const ThumbNone = styled.div`
  width:100%;
  height:100%;
  background-color:#272727;
  border-radius:8px;
  color:white;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
`
const ThumbImage = styled.img`
  width:100%;
   position:relative;
  object-fit: cover;
  border-radius:8px;
  height: 140px;

`

const DeleteIcon = styled.div`
  position: absolute;
  right: -4px;
  top: -2px;
  z-index: 1;
  width: 18px;
  height: 18px;
  background: #fff;
  padding: 2px;
  border-radius:10px;
  box-shadow: 1px 1px 5px gray;
  img {
    padding: 2px;
  }
`
export default BackgroundThumbnail;
