import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CommentModal } from 'javascript/features/thanks/components/CommentModal';
import { useToggleModal } from 'javascript/features/thanks/hooks/useToggleModal';
import { ThanksComment as TypeThanksComment } from 'types';

const StyledThanksComment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 198px;
  padding: 12px 24px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 8px #ddd;
`;

const StyledStatusIconWrapper = styled.div`
  img {
    width: 24px;
  }
`;

const StyledStatusText = styled.p`
  margin-top: 8px;
  font-size: 14px;
`;

const StyledContent = styled.p`
  height: 56px;
  margin-top: 12px;
  font-size: 12px;
  overflow: hidden;
  word-break: break-word;
`;

const StyledMoreWrapper = styled.div`
  width: 100%;
`;

const StyledMore = styled.p`
  color: #ccc;
  font-size: 10px;
  cursor: pointer;
`;

const StyledPersonRelation = styled.div`
  width: 100%;
  height: 15px;
  margin-top: 8px;
  font-size: 10px;
  color: #aaa;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StyledDate = styled.div`
  width: 100%;
  font-size: 10px;
  color: #aaa;
`;

interface Props {
  thanksComment: TypeThanksComment;
}

export const ThanksComment: React.FC<Props> = ({ thanksComment }) => {
  const { t } = useTranslation();
  const { isOpen, toggleModal } = useToggleModal();

  const { feeling_status, content, created_at, from_user_name, to_user_name } =
    thanksComment;

  const icon =
    feeling_status === 1
      ? 'heart'
      : feeling_status === 2
      ? 'star'
      : 'party_cracker';

  const text =
    feeling_status === 1
      ? t('ReportStatusThankyou', {})
      : feeling_status === 2
      ? t('ReportStatusAmazing', {})
      : t('ReportStatusCongrat', {});

  return (
    <>
      <StyledThanksComment>
        <StyledStatusIconWrapper>
          <img
            // @ts-ignore
            src={RailsAssetPath(`diary/${icon}.png`)}
          />
        </StyledStatusIconWrapper>
        <StyledStatusText>{text}</StyledStatusText>
        <StyledContent dangerouslySetInnerHTML={{ __html: content }} />
        <StyledMoreWrapper>
          <StyledMore onClick={toggleModal}>
            ...{t('ThanksPageMore', {})}
          </StyledMore>
        </StyledMoreWrapper>
        <StyledPersonRelation>
          <p>{`${from_user_name} ▶ ${to_user_name}`}</p>
        </StyledPersonRelation>
        <StyledDate>
          <p>{created_at}</p>
        </StyledDate>
      </StyledThanksComment>
      {isOpen && (
        <CommentModal
          icon={icon}
          text={text}
          thanksComment={thanksComment}
          toggleModal={toggleModal}
        />
      )}
    </>
  );
};
