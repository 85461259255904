import axios from 'axios';
import useSWR from 'swr';
import { Scrum } from 'types';

export const useScrum = (userId: number | undefined) => {
  const fetcher = (url: string) =>
    axios.get<Scrum>(url).then(({ data }) => data);

  const { data } = useSWR(`/api/v1/users/scrum?user_id=${userId}`, fetcher);

  return { scrum: data };
};
