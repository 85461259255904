import React from 'react';
import styled from 'styled-components';
import ProfileThumbnail from 'javascript/components/items/ProfileThumbnail';

const StyledVirtualWorldMember = styled.div`
  position: relative;
  cursor: pointer;
  height: 30px;
`;

interface Props {
  userImage: string;
}

export const SortableVirtualWorldMember: React.FC<Props> = React.memo(
  ({ userImage }) => {
    return (
      <StyledVirtualWorldMember>
        <ProfileThumbnail image={userImage} isRoom size='23px' isClickable />
      </StyledVirtualWorldMember>
    );
  }
);
