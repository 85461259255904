import { useContext } from 'react';
import { VoiceContext } from '../VoiceChatProvider';

const useVoiceContext = () => {
  const context = useContext(VoiceContext);
  if (!context) {
    throw new Error('useVoiceContext must be used within a VoiceChatProvider');
  }
  return context;
}

export default useVoiceContext;