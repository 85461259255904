import React, { useState } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import ProfileThumbnail from 'javascript/components/items/ProfileThumbnail';
import { device } from 'javascript/utils/mediaQuery';
import { UserMenu } from 'javascript/components/common/Header/components/UserMenu';

const UserProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 200px;
  border-left: 2px solid #f6f6f6;
  @media ${device.tab} {
    min-width: 144px;
  }
  @media ${device.sp} {
    min-width: auto;
    margin-right: -4px;
    padding: 0;
    border: none;
  }
`;

const HeaderUserProfile = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const HeaderUserName = styled.p`
  display: -webkit-box;
  width: 136px;
  margin-right: 16px;
  padding-left: 16px;
  font-size: 12px;
  letter-spacing: 1px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  @media ${device.tab} {
    width: 88px;
    margin-right: 8px;
    padding-left: 8px;
    font-size: 10px;
  }
`;

interface Props {
  // TODO: typesにて型付け
  currentUser: { id: number; role: string; name: string; image: string };
}

export const UserProfile: React.FC<Props> = ({ currentUser }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 430 });

  const toggleUserMenu = () => setIsOpen(!isOpen);

  const isNonActiveUser = currentUser.role === 'non_active';

  return (
    <UserProfileWrapper>
      <HeaderUserProfile
        onClick={() => {
          toggleUserMenu();
        }}
      >
        {!isMobile && <HeaderUserName>{currentUser.name}</HeaderUserName>}
        <ProfileThumbnail
          image={currentUser.image}
          size={isMobile && '24px'}
          isClickable
        />
      </HeaderUserProfile>
      <UserMenu
        userId={currentUser.id}
        isOpen={isOpen}
        isNonActiveUser={isNonActiveUser}
        toggleUserMenu={toggleUserMenu}
      />
    </UserProfileWrapper>
  );
};
