import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { device } from 'javascript/utils/mediaQuery';
import { useEnterOrLeaveRoom } from 'javascript/features/virtualWorld/hooks/useEnterOrLeaveRoom';
import { useToggleFavorite } from 'javascript/features/virtualWorld/hooks/useToggleFavorite';
import { VirtualWorldMemberBox } from 'javascript/features/virtualWorld/components/VirtualWorldMemberBox';
import { VirtualWorldTitle } from 'javascript/features/virtualWorld/components/VirtualWorldTitle';
import { VirtualWorld } from 'types';

interface Props {
  virtualWorld: VirtualWorld;
  localImage: string;
  isVideoOnline?: boolean;
}

export const VirtualWorldItem: React.FC<Props> = ({
  virtualWorld,
  localImage,
  isVideoOnline,
}) => {
  const { isStarredLocal } = useToggleFavorite(
    virtualWorld.id,
    virtualWorld.favorite
  );
  // TODO stateの型付け
  // @ts-ignore
  const currentUser = useSelector((state) => state.setUser.currentUser);
  const hasCurrentUser = useMemo(
    () =>
      virtualWorld.users.findIndex(
        (user) => user.avatar_id === currentUser?.avatar?.id
      ) !== -1,
    [virtualWorld.users, currentUser.id]
  );
  const directCallNow = useMemo(
    () => currentUser.avatar.direct_call_now,
    [currentUser]
  );

  const { enterOrLeaveRoom } = useEnterOrLeaveRoom(
    virtualWorld,
    isVideoOnline,
    hasCurrentUser,
    directCallNow
  );

  return (
    <VirtualWorldItemWrapper
      onClick={enterOrLeaveRoom}
      hasCurrentUser={hasCurrentUser}
      isFavorite={isStarredLocal}
    >
      <VirtualWorldTitle
        virtualWorld={virtualWorld}
        hasCurrentUser={hasCurrentUser}
      />
      <VirtualWorldMemberWrapper
        // @ts-ignore
        image={RailsAssetPath(localImage)}
        hasCurrentUser={hasCurrentUser}
      >
        <VirtualWorldMemberBox
          users={virtualWorld.users}
          guestNum={virtualWorld.guest_num}
          isCast={currentUser.role == "cast"}
        />
      </VirtualWorldMemberWrapper>
    </VirtualWorldItemWrapper>
  );
};

const VirtualWorldItemWrapper = styled.div<{
  hasCurrentUser: boolean;
  isFavorite: boolean | undefined;
}>`
  width: 120px;
  height: 121px;
  padding: 4px 2px 0 0;
  border-radius: 8px;
  cursor: pointer;
  background: ${({ isFavorite }) => isFavorite && 'rgba(255, 206, 0, 0.2)'};
  ${({ hasCurrentUser }) =>
    !hasCurrentUser &&
    `
    &:hover {
      box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 5%), 4px 4px 4px 0px rgb(0 0 0 / 5%);
    }
  `}
  ${({ hasCurrentUser }) =>
    hasCurrentUser &&
    `
    box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 5%), 4px 4px 4px 0px rgb(0 0 0 / 5%);
  `}
  // virtualWorldTitle内で使用されている、三点リーダーのvisibilityをhoverにより変更する
  &:hover {
    .usually-hidden {
      visibility: visible;
    }
  }
  &:focus-visible {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  @media ${device.sp} {
    width: 28vw;
    height: 114px;
    padding: 8px 4px 4px 0;
    &:hover {
      transform: none;
      box-shadow: none;
    }
  }
`;

const VirtualWorldMemberWrapper = styled.div<{
  image: string;
  hasCurrentUser: boolean;
}>`
  width: 80%;
  height: 90px;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  padding: 8px 3px;
  margin-left: 12px;
  @media ${device.sp} {
    width: 100%;
    height: 80px;
    background-size: contain;
    margin-left: 0;
  }
`;
