// TODO　要リファクタ。（本来ならアバター着せ替えのタイミングでリファクタしたかったが、アバター着せ替え実装時はモーダルのリファクタの優先度が高くなかったため、一旦保留とした）
import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import DayjsUtils from '@date-io/dayjs';
import { createMuiTheme } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import ProfileThumbnail from 'javascript/components/items/ProfileThumbnail';
import { DefaultSelect } from 'javascript/features/userProfile/components/DefaultSelect';
import { useUserProfile } from 'javascript/features/userProfile/hooks/useUserProfile';
import { device } from 'javascript/utils/mediaQuery';
import { UserProfile } from 'types';
import { Trans, useTranslation } from 'react-i18next';
//datepicker
const materialTheme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        width: '220px',
      },
      input: {
        background: '#eeeeee',
        textAlign: 'center',
        padding: '5px',
        height: '30px',
        borderRadius: '8px',
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '5px',
      },
      notchedOutline: {
        border: 'none',
      },
    },
    MuiIconButton: {
      root: {
        padding: '10px',
        width: '40px',
      },
    },
    // @ts-ignore
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#00a0e9',
        '&:hover': {
          backgroundColor: '#00a0e9',
        },
      },
    },
  },
});

interface Props {
  userData: UserProfile;
  isMaster: boolean;
  uploadImage: any;
  setUploadImageByUser: (data: any) => void;
  setIsEmployeeEditing: (isOpened: false) => void;
  isCurrentUser: boolean;
}

export const UserProfileModal: React.FC<Props> = ({
  userData,
  isMaster,
  uploadImage,
  setUploadImageByUser,
  setIsEmployeeEditing,
  isCurrentUser,
}) => {
  const { handleGetUserData, setUserProfileData } = useUserProfile(
    userData.user.user_id
  );
  const [isOpenBirth, setIsOpenBirth] = useState(false);
  const [isOpenDate, setIsOpenDate] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [deleteImage] = useState(false);
  const { t, i18n } = useTranslation();

  const onChangeUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    setUploadImageByUser(e.target.files[0]);
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      setUploaded(true);
    }
    reader.onloadend = () => {
      setUserProfileData({
        ...userData,
        user: {
          ...userData.user,
          user_image: typeof reader.result === 'string' ? reader.result : '',
        },
      });
    };
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData();
    if (uploaded) {
      formData.append('user_image', uploadImage);
    } else if (deleteImage) {
      params.user_image_delete_flg = true;
    }
    if (userData.user.email === '') {
      alert(t('ProfileModalCategoryAlertenteremail'));
      return;
    }
    submit(formData)
      .then((res) => {
        if (isCurrentUser) {
          i18n.changeLanguage(res.data.current_language);
        }
        setIsEmployeeEditing(false);
        handleGetUserData(userData.user.user_id);
        alert(t('GenelalAlertSaveComplete'));
      })
      .catch((e) => {
        e.response.data.message
          ? alert(e.response.data.message)
          : alert(t('GenelalAlertSaveFailed'));
      });
  };

  const params = {
    user_name: userData?.user.user_name,
    role_id: userData?.user.current_role_id,
    join_date: userData?.user.join_date,
    birth_date: userData?.user.birth_date,
    team_id: userData?.user.current_team_id,
    position_id: userData?.user.current_position_id,
    timezone: userData?.user.current_timezone,
    language: userData?.user.current_language,
    user_image_delete_flg: false,
    work_send_mail: userData?.user.work_send_mail,
    email: userData?.user.email,
    user_real_name: userData?.user.user_real_name,
    employee_status: userData?.user.employee_status,
    working_time_id: userData?.user.current_working_time_id,
  };

  const submit = (data: FormData) => {
    const url = `/api/v1/users/${userData?.user.user_id}`;
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      params: params,
    };
    return axios.patch(url, data, config);
  };

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserProfileData({
      ...userData,
      user: { ...userData.user, user_name: e.currentTarget.value },
    });
  };

  const onChangeId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserProfileData({
      ...userData,
      user: { ...userData.user, employee_status: e.currentTarget.value },
    });
  };

  const onChangeRealName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserProfileData({
      ...userData,
      user: { ...userData.user, user_real_name: e.currentTarget.value },
    });
  };

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserProfileData({
      ...userData,
      user: { ...userData.user, email: e.currentTarget.value },
    });
  };

  const onChangeChecked = () => {
    setUserProfileData({
      ...userData,
      user: {
        ...userData.user,
        work_send_mail: !userData.user.work_send_mail,
      },
    });
  };

  const handleDeleteModal = () => {
    handleGetUserData(userData.user.user_id);
    setIsEmployeeEditing(false);
  };

  const onChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    let key = name !== 'timezone' ? `current_${name}_id` : `current_timezone`;
    if (name == 'language') {
      key = 'current_language';
    }
    setUserProfileData({
      ...userData,
      user: { ...userData.user, [key]: value },
    });
  };

  return (
    <>
      <ModalSettingMypage>
        <form onSubmit={handleSubmit} className='employee-edit-form'>
          <div className='form-wrapper'>
            <label htmlFor='fileUpload'>
              <div className='relative'>
                {/** @ts-ignore */}
                <ProfileThumbnail
                  image={userData.user.user_image || uploadImage}
                  size='90px'
                />
                <ProfileThumnailEditButtonWrapper>
                  <ProfileThumnailEditButton>
                    <img
                      className='profile-edit-icon'
                      // @ts-ignore
                      src={RailsAssetPath('camera-white.png')}
                    />
                  </ProfileThumnailEditButton>
                </ProfileThumnailEditButtonWrapper>
              </div>
            </label>
            <input
              type='file'
              id='fileUpload'
              className=''
              accept='image/*'
              style={{ display: 'none' }}
              onChange={onChangeUpload}
            />
          </div>
          <ModalSettingMypageContent>
            <div className='content-edit'>
              <div className='flex relative'>
                <EmployeeDetailProfileEditTitle>
                  <Trans i18nKey='ProfileModalCategoryName' />
                </EmployeeDetailProfileEditTitle>
                <input
                  type='text'
                  className='profile-edit-input'
                  defaultValue={userData.user.user_real_name ?? ''}
                  onChange={onChangeRealName}
                />
                <EmployeeDetailProfileContentIconEditWrapper>
                  <EmployeeDetailProfileContentIconEdit
                    style={{ margin: '10px 8px' }}
                    // @ts-ignore
                    src={RailsAssetPath('pencil.png')}
                  />
                </EmployeeDetailProfileContentIconEditWrapper>
              </div>
              <div className='flex relative'>
                <EmployeeDetailProfileEditTitle>
                  <Trans i18nKey='ProfileModalCategoryNickname' />
                </EmployeeDetailProfileEditTitle>
                <input
                  type='text'
                  className='profile-edit-input'
                  defaultValue={userData.user.user_name}
                  onChange={onChangeName}
                />
                <EmployeeDetailProfileContentIconEditWrapper>
                  <EmployeeDetailProfileContentIconEdit
                    style={{ margin: '10px 8px' }}
                    // @ts-ignore
                    src={RailsAssetPath('pencil.png')}
                  />
                </EmployeeDetailProfileContentIconEditWrapper>
              </div>
              <div className='flex relative'>
                <EmployeeDetailProfileEditTitle>
                  <Trans i18nKey='AttendanceTextId' />
                </EmployeeDetailProfileEditTitle>
                <input
                  type='text'
                  className='profile-edit-input'
                  defaultValue={userData.user.employee_status ?? ''}
                  onChange={onChangeId}
                />
                <EmployeeDetailProfileContentIconEditWrapper>
                  <EmployeeDetailProfileContentIconEdit
                    style={{ margin: '10px 8px' }}
                    // @ts-ignore
                    src={RailsAssetPath('pencil.png')}
                  />
                </EmployeeDetailProfileContentIconEditWrapper>
              </div>
              <div className='flex relative'>
                <EmployeeDetailProfileEditTitle>
                  <Trans i18nKey='ProfileModalCategoryEmail' />
                </EmployeeDetailProfileEditTitle>
                <input
                  type='email'
                  className='profile-edit-input'
                  defaultValue={userData.user.email}
                  onChange={onChangeEmail}
                />
                <EmployeeDetailProfileContentIconEditWrapper>
                  <EmployeeDetailProfileContentIconEdit
                    style={{ margin: '10px 8px' }}
                    // @ts-ignore
                    src={RailsAssetPath('pencil.png')}
                  />
                </EmployeeDetailProfileContentIconEditWrapper>
              </div>
              <div className='flex relative'>
                <EmployeeDetailProfileEditTitle>
                  <Trans i18nKey='ProfileModalCategoryTimezone' />
                </EmployeeDetailProfileEditTitle>
                {userData.timezones && (
                  <DefaultSelect
                    style={{
                      textOverflow: 'ellipsis',
                      backgroundColor: `${
                        userData.user.work_status === 'working'
                          ? '#ddd'
                          : 'inherit'
                      }`,
                    }}
                    defaultValue={userData.user.current_timezone}
                    data={userData.timezones}
                    onChange={onChangeSelect}
                    name='timezone'
                    disabled={userData.user.work_status === 'working'}
                  />
                )}
                {userData.user.work_status === 'working' && (
                  <AlertForWorking>
                    <Trans i18nKey='ProfileModalNoticeOnlyChangeWorking' />
                  </AlertForWorking>
                )}
              </div>
            </div>
            <div className='content-edit'>
              <div className='flex'>
                <EmployeeDetailProfileEditTitleRight>
                  <Trans i18nKey='ProfileModalCategoryEmployment' />
                </EmployeeDetailProfileEditTitleRight>
                <div className='profile-edit__date'>
                  <MuiPickersUtilsProvider utils={DayjsUtils}>
                    <ThemeProvider theme={materialTheme}>
                      <KeyboardDatePicker
                        autoOk
                        value={userData.user.join_date}
                        placeholder='2020/11/02'
                        onChange={(date) =>
                          setUserProfileData({
                            ...userData,
                            user: {
                              ...userData.user,
                              join_date: date?.format('YYYYMMDD') || '',
                            },
                          })
                        }
                        format='YYYY/MM/DD'
                        inputVariant='outlined'
                        variant='inline'
                        open={isOpenDate}
                        onOpen={() => setIsOpenDate(true)}
                        onClose={() => setIsOpenDate(false)}
                        keyboardIcon={<CustomIcon />}
                        disableToolbar
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className='flex'>
                <EmployeeDetailProfileEditTitleRight>
                  <Trans i18nKey='ProfileModalCategoryBirthday' />
                </EmployeeDetailProfileEditTitleRight>
                <div className='profile-edit__date'>
                  <MuiPickersUtilsProvider utils={DayjsUtils}>
                    <ThemeProvider theme={materialTheme}>
                      <KeyboardDatePicker
                        autoOk
                        value={userData.user.birth_date}
                        placeholder='2020/11/02'
                        onChange={(date) =>
                          setUserProfileData({
                            ...userData,
                            user: {
                              ...userData.user,
                              birth_date: date?.format('YYYYMMDD') || '',
                            },
                          })
                        }
                        format='YYYY/MM/DD'
                        inputVariant='outlined'
                        variant='inline'
                        open={isOpenBirth}
                        onOpen={() => setIsOpenBirth(true)}
                        onClose={() => setIsOpenBirth(false)}
                        keyboardIcon={<CustomIcon />}
                        disableToolbar
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className='flex relative'>
                <EmployeeDetailProfileEditTitleRight>
                  <Trans i18nKey='ProfileModalCategoryTeam' />
                </EmployeeDetailProfileEditTitleRight>
                {userData.teams && (
                  <DefaultSelect
                    style={{ textOverflow: 'ellipsis' }}
                    defaultValue={userData.user.current_team_id}
                    data={userData.teams}
                    onChange={onChangeSelect}
                    name='team'
                    label={t('ProfileModalCategoryTeamName')}
                  />
                )}
              </div>
              <div className='flex relative'>
                <EmployeeDetailProfileEditTitleRight>
                  <Trans i18nKey='ProfileModalCategoryPosition' />
                </EmployeeDetailProfileEditTitleRight>
                {userData.positions && (
                  <DefaultSelect
                    style={{ textOverflow: 'ellipsis' }}
                    defaultValue={userData.user.current_position_id}
                    data={userData.positions}
                    onChange={onChangeSelect}
                    name='position'
                    label={t('ProfileModalLabelPositionJa')}
                  />
                )}
              </div>
              <div className='flex'>
                <EmployeeDetailProfileEditTitleRight>
                  <Trans i18nKey='ProfileModalCategoryLanguage' />
                </EmployeeDetailProfileEditTitleRight>
                {userData.languages && (
                  <DefaultSelect
                    style={{ textOverflow: 'ellipsis' }}
                    defaultValue={userData.user.current_language}
                    data={userData.languages}
                    onChange={onChangeSelect}
                    name='language'
                  />
                )}
              </div>
              {isMaster && (
                <div className='flex'>
                  <EmployeeDetailProfileEditTitleRight>
                    <Trans i18nKey='ProfileModalCategoryAuthority' />
                  </EmployeeDetailProfileEditTitleRight>
                  {userData.roles && (
                    <DefaultSelect
                      style={{ textOverflow: 'ellipsis' }}
                      defaultValue={userData.user.current_role_id}
                      data={userData.roles}
                      onChange={onChangeSelect}
                      name='role'
                    />
                  )}
                </div>
              )}

              <div className='flex'>
                <EmployeeDetailProfileEditTitleRight>
                  <Trans i18nKey='ProfileModalCategoryWorkingTime' />
                </EmployeeDetailProfileEditTitleRight>
                {userData.working_times && (
                  <DefaultSelect
                    style={{ textOverflow: 'ellipsis' }}
                    defaultValue={userData.user.current_working_time_id || 0}
                    data={userData.working_times}
                    onChange={onChangeSelect}
                    name='working_time'
                  />
                )}
              </div>
            </div>
          </ModalSettingMypageContent>
          <div className='mail-notify'>
            <div className='mail-btn-text'>
              <Trans i18nKey='ProfileModalCategoryNotificationEmail' />
            </div>
            <StyledToggleBtnArea id='makeImg'>
              <input
                type='checkbox'
                id='sample2check'
                onChange={onChangeChecked}
                checked={userData.user.work_send_mail}
              />
              <label htmlFor='sample2check'>
                <p className='sample2check-text sample2check-text-on'>
                  <Trans i18nKey='GenelalButtonOn' />
                </p>
                <p className='sample2check-text sample2check-text-off'>
                  <Trans i18nKey='GenelalButtonOff' />
                </p>
                <div id='sample2box'></div>
              </label>
            </StyledToggleBtnArea>
          </div>
          <ModalSubmitButton>
            <EmployeeDetailProfileEditSaveButton type='submit'>
              <Trans i18nKey='GenelalButtonOk' />
            </EmployeeDetailProfileEditSaveButton>
            <div
              className='delete-modal'
              style={{ color: '#adadad', cursor: 'pointer' }}
              onClick={() => {
                handleDeleteModal();
              }}
            >
              <Trans i18nKey='GenelalButtonCancel' />
            </div>
          </ModalSubmitButton>
        </form>
      </ModalSettingMypage>
    </>
  );
};

const CustomIcon = () => {
  // @ts-ignore
  return <img src={RailsAssetPath('icon_calendar.png')} />;
};

const ModalSubmitButton = styled.div`
  justify-content: space-around;
  width: 180px;
  margin: auto;
  font-size: 13px;
  display: flex;
  .delete-modal {
    color: rgb(173, 173, 173);
    cursor: pointer;
    border-radius: 20px;
    padding: 5px 5px;
    background: white;
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
  }
`;

const ModalSettingMypage = styled.div`
  background: rgba(62, 62, 62, 0.75);
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  right: 0;
  z-index: 100;
  form {
    width: 569px;
    background: white;
    border-radius: 10px;
    padding: 20px 30px 40px;
    margin: 13vh auto;
    font-size: 10px;
    input,
    select {
      font-size: 13px;
    }
    @media ${device.sp} {
      margin: 10vh auto;
      width: 95%;
      overflow-y: scroll;
      max-height: 90vh;
    }
  }
  .form-wrapper {
    margin: auto;
    width: 100px;
  }
  .mail-notify {
    display: flex;
    font-size: 13px;
    @media ${device.sp} {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .mail-btn-text {
    margin: 16px 30px 0 50px;
    @media ${device.sp} {
      margin: 16px 0 0;
    }
  }
`;

const ModalSettingMypageContent = styled.div`
  display: flex;
  @media ${device.sp} {
    flex-wrap: wrap;
    padding-left: 5px;
  }
`;

const EmployeeDetailProfileContentIconEditWrapper = styled.div`
  position: absolute;
  right: 0;
`;

const ProfileThumnailEditButton = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 50px;
  margin: 5px;
  background-color: #0000004f;
`;

const ProfileThumnailEditButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  cursor: pointer;
`;

const EmployeeDetailProfileEditTitle = styled.div`
  width: 72px;
  padding: 10px 0px 0px 0px;
  text-align: left;
  @media ${device.sp} {
    width: 80px;
  }
`;

const EmployeeDetailProfileEditTitleRight = styled.div`
  width: 72px;
  padding: 10px 0px 0px 0px;
  text-align: left;
  @media ${device.sp} {
    width: 80px;
  }
`;

const EmployeeDetailProfileEditSaveButton = styled.button`
  border-radius: 20px;
  padding: 5px 5px;
  color: white;
  background: #11a5e9;
  cursor: pointer;
  width: 72px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
`;

const AlertForWorking = styled.p`
  position: absolute;
  top: 35px;
  left: 20px;
  color: #fa6964;
  font-size: 10px;
  width: 280px;
  @media ${device.sp} {
    top: 33px;
    left: 40px;
  }
`;

const EmployeeDetailProfileContentIconEdit = styled.img`
  display: inline;
  width: 15px;
  margin-right: 5px;
`;

const StyledToggleBtnArea = styled.div`
  margin: 10px 28px 20px;
  width: 60px;
  transform: rotate(180deg);
  border-radius: 50px;
  input[type='checkbox'] {
    display: none;
  }
  .sample2check-text {
    transform: rotate(180deg);
    position: absolute;
    top: 6px;
    font-size: 12px;
    color: #bfbfbf;
  }

  .sample2check-text-on {
    left: 70px;
  }

  .sample2check-text-off {
    right: 70px;
  }

  label {
    display: block;
    box-sizing: border-box;
    text-align: left;
    border: 4px solid #bfbfbf;
    border-radius: 3px;
    line-height: 1;
    height: 28px;
    background: #bfbfbf;
    border-radius: 50px;
  }

  #sample2box {
    display: inline-block;
    height: 20px;
    width: 20px;
    background: white;
    transition: 0.3s;
    border-radius: 50px;
  }

  #sample2check:checked + label #sample2box {
    transform: translateX(160%);
    background: white;
  }

  #sample2check:checked + label {
    transition: 0.5s all;
    border: 4px solid #03a9f4;
    background: #03a9f4;
  }

  #sample2check:checked + label .sample2check-text-on {
    color: #03a9f4;
  }

  #sample2check:checked + label .sample2check-text-off {
    color: #bfbfbf;
  }
`;
