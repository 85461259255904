import React, { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ProfileThumbnail from 'javascript/components/items/ProfileThumbnail';
import { Tooltip } from 'javascript/features/realWorld/components/Tooltip';
import { useTooltip } from 'javascript/features/realWorld/hooks/useTooltip';
import { device } from 'javascript/utils/mediaQuery';

const StyledVirtualWorldMember = styled.div`
  position: relative;
  cursor: pointer;
  height: 30px;
  @media ${device.sp} {
    height: 24px;
  }
`;

interface Props {
  userId: number;
  userName: string;
  userImage: string;
  isCast: boolean;
}

export const VirtualWorldMember: React.FC<Props> = React.memo(
  ({ userId, userName, userImage, isCast }) => {
    const isMobile = useMediaQuery({ maxWidth: 430 });
    const history = useHistory();

    const {
      targetRef,
      isHovered,
      position,
      handleOnMouseEnter,
      handleOnMouseLeave,
      handleOnMouseMove,
    } = useTooltip();

    const handleOnClick = useCallback((memberId, e, isCast) => {
      if (!isCast) {
        e.stopPropagation();
        history.push(`mypage/${memberId}`);
      }
    }, []);

    return (
      <StyledVirtualWorldMember
        ref={targetRef}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        onMouseMove={handleOnMouseMove}
        onClick={(e) => handleOnClick(userId, e, isCast)}
      >
        <ProfileThumbnail
          image={userImage}
          isRoom
          size={isMobile ? '18px' : '23px'}
          isClickable
        />
        <Tooltip isHovered={isHovered} position={position} text={userName} />
      </StyledVirtualWorldMember>
    );
  }
);
