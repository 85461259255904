import React from 'react';
import styled from 'styled-components';
import { BarOfMonth } from 'javascript/features/stayHistory/components/BarOfMonth';
import { StayHistoryHeader } from 'javascript/features/stayHistory/components/StayHistoryHeader';
import { useChagenHistoryYear } from 'javascript/features/stayHistory/hooks/useChangeHistoryYear';
import { useShowEachMonth } from 'javascript/features/stayHistory/hooks/useShowEachMonth';
import { useStayHistory } from 'javascript/features/stayHistory/hooks/useStayHistory';
import { device } from 'javascript/utils/mediaQuery';

const StyledStayHistory = styled.div`
  width: 100%;
  margin-top: 40px;
  padding-left: 40px;
  @media ${device.sp} {
    padding-left: 0;
  }
`;

const StyledBarsContainer = styled.div`
  width: 100%;
  margin-top: 48px;
  padding-right: 14px;
`;

const StyledWhiteSpaceForLoading = styled.div`
  width: 100%;
  height: 1504px;
`;

interface Props {
  userId: number;
}

export const StayHistoryTemplate: React.FC<Props> = ({ userId }) => {
  const { historyYear, chagenHistoryYear, isLatestYear, isEarliestYear } =
    useChagenHistoryYear();
  const { stayHistory, maxScale } = useStayHistory(userId, historyYear);
  const { selectMonth, selectedMonthHistory, monthMaxScale } =
    useShowEachMonth(stayHistory);

  return (
    <StyledStayHistory>
      <StayHistoryHeader
        userId={userId}
        historyYear={historyYear}
        isLatestYear={isLatestYear}
        isEarliestYear={isEarliestYear}
        changeHistoryYear={chagenHistoryYear}
        selectMonth={selectMonth}
      />
      {stayHistory !== undefined && maxScale !== undefined ? (
        <StyledBarsContainer>
          {selectedMonthHistory ? (
            <BarOfMonth
              historyPerMonth={selectedMonthHistory}
              isLast
              isFirst
              maxScale={monthMaxScale}
            />
          ) : (
            stayHistory.map((hist, i) => (
              <BarOfMonth
                key={i}
                historyPerMonth={hist}
                isLast={i === 11}
                isFirst={i === 0}
                maxScale={maxScale}
              />
            ))
          )}
        </StyledBarsContainer>
      ) : (
        <StyledWhiteSpaceForLoading />
      )}
    </StyledStayHistory>
  );
};
