import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, keyframes } from 'styled-components';
import {
  MQ_DESKTOP,
  MQ_MOBILE,
} from 'javascript/components/landingPage/lib/MQ';
import { pcPx, px, spp } from 'javascript/components/landingPage/lib/cssUtil';
import {
  BLACK,
  jpStyle,
  onlySP,
  GRAY,
  onlyPC,
  fadeInStyle,
} from 'javascript/components/landingPage/local/commonCss';
import {
  onScrollPromise,
  removeOnscrollEvent,
} from 'javascript/components/landingPage/local/scrollPromise';
import { useScrollYStoreValue } from 'javascript/components/landingPage/store/scrollYStore';
import {
  LANGUAGE,
  useLanguageStoreValue,
} from 'javascript/components/landingPage/store/language';

// @ts-ignore
const ASSETS_IMG = RailsAssetPath('lp/avatar/img.png');
// @ts-ignore
const ASSETS_IMG_PC = RailsAssetPath('lp/avatar/img_pc.jpg');

const path = keyframes({
  from: { strokeDashoffset: spp(-1500) },
  to: { strokeDashoffset: spp(1500) },
});

const pathPc = keyframes({
  from: { strokeDashoffset: pcPx(-1550) },
  to: { strokeDashoffset: pcPx(1550) },
});

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  ${MQ_DESKTOP} {
    &:before {
      content: '';
      background-color: rgba(255, 255, 255, 0.39);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: ${pcPx(1800)};
      height: 100%;
      top: 0;
    }
  }
`;

const Title = styled.h3`
  ${jpStyle}
  ${fadeInStyle}
  color: ${BLACK};
  font-weight: bold;
  position: relative;
  text-align: center;
  overflow: visible;
  ${MQ_MOBILE} {
    font-size: ${spp(26)};
    line-height: ${spp(45)};
    margin-top: ${spp(80)};
    margin-bottom: ${spp(35)};
    padding-bottom: ${spp(35)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(36)};
    line-height: ${pcPx(80)};
    margin-top: ${pcPx(100)};
    margin-bottom: ${pcPx(35)};
    padding-bottom: ${pcPx(30)};
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    background-color: ${BLACK};
    ${MQ_MOBILE} {
      width: ${spp(103)};
      height: ${spp(4)};
      bottom: ${spp(4)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(176)};
      height: ${pcPx(4)};
      bottom: ${pcPx(4)};
    }
  }
`;

const Text = styled.p`
  ${jpStyle}
  ${fadeInStyle}
  white-space: pre-wrap;
  color: ${BLACK};
  position: relative;
  text-align: center;
  overflow: visible;
  transition-delay: 0.1s;
  ${MQ_MOBILE} {
    font-size: ${spp(18)};
    line-height: ${spp(31)};
    margin-top: ${spp(10)};
    margin-bottom: ${spp(40)};
    padding: 0 ${spp(32.5)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(24)};
    line-height: ${pcPx(41)};
    margin-top: ${pcPx(5)};
    padding-bottom: ${pcPx(40)};
    margin-bottom: ${pcPx(0)};
  }
`;

const Img = styled.div`
  ${fadeInStyle}
  display: block;
  margin: auto;
  position: relative;
  ${MQ_MOBILE} {
    width: ${spp(372)};
    height: ${spp(805)};
    background: url(${ASSETS_IMG}) no-repeat;
    background-size: contain;
    margin-bottom: ${spp(30)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(1095)};
    height: ${pcPx(586)};
    background: url(${ASSETS_IMG_PC}) no-repeat;
    background-size: contain;
    margin-bottom: ${pcPx(10)};
  }
`;

const svgStyle = css`
  position: absolute;
  margin: auto;
  svg {
    width: 100%;
    height: 100%;
    g {
      stroke: ${GRAY};
    }
  }
`;

const BoxStyle = css`
  ${svgStyle}
  ${onlySP}
  left: 0;
  ${MQ_MOBILE} {
    width: ${spp(273)};
    height: ${spp(179)};
    top: ${spp(110)};
    right: ${spp(-920)};
  }
`;

const Box = styled.div`
  ${BoxStyle}
`;

const BoxAnim = styled.div`
  ${BoxStyle}
  svg {
    g {
      stroke: ${BLACK};
      stroke-width: 2;
      stroke-dashoffset: ${spp(1500)};
      stroke-dasharray: ${spp(1500)};
      animation: ${path} 3s linear forwards infinite;
    }
  }
`;

const BoxPcStyle = css`
  ${svgStyle}
  ${onlyPC}
  left: 0;
  ${MQ_DESKTOP} {
    width: ${pcPx(464)};
    height: ${pcPx(231)};
    top: ${pcPx(30)};
    right: ${pcPx(-1900)};
  }
`;

const BoxPc = styled.div`
  ${BoxPcStyle}
`;

const BoxPcAnim = styled.div`
  ${BoxPcStyle}
  svg {
    g {
      stroke: ${BLACK};
      stroke-width: 2;
      stroke-dashoffset: ${pcPx(1500)};
      stroke-dasharray: ${pcPx(1500)};
      animation: ${pathPc} 3s linear forwards infinite;
    }
  }
`;

const LineStyle = css`
  ${svgStyle}
  ${onlySP}
  right: 0;
  ${MQ_MOBILE} {
    width: ${spp(399)};
    height: ${spp(330)};
    top: ${spp(310)};
    left: ${spp(-950)};
  }
`;

const Line = styled.div`
  ${LineStyle}
`;

const LineAnim = styled.div`
  ${LineStyle}
  svg {
    line {
      stroke: ${BLACK};
      stroke-width: 2;
      stroke-dashoffset: ${spp(1500)};
      stroke-dasharray: ${spp(1500)};
      animation: ${path} 3s linear forwards infinite;
    }
  }
`;

const LinePcStyle = css`
  ${svgStyle}
  ${onlyPC}
  right: 0;
  ${MQ_DESKTOP} {
    width: ${pcPx(681)};
    height: ${pcPx(426)};
    top: ${pcPx(350)};
    left: ${pcPx(-2500)};
  }
`;

const LinePc = styled.div`
  ${LinePcStyle}
`;

const LinePcAnim = styled.div`
  ${LinePcStyle}
  svg {
    line {
      stroke: ${BLACK};
      stroke-width: 2;
      stroke-dashoffset: ${pcPx(1500)};
      stroke-dasharray: ${pcPx(1500)};
      animation: ${pathPc} 3s linear forwards infinite;
    }
  }
`;

const Avatar = () => {
  const scrollYStoreValue = useScrollYStoreValue();
  const boxRef = useRef<HTMLDivElement | null>(null);
  const lineRef = useRef<HTMLDivElement | null>(null);
  const boxPcRef = useRef<HTMLDivElement | null>(null);
  const linePcRef = useRef<HTMLDivElement | null>(null);
  const fvRef = useRef<HTMLDivElement | null>(null);
  const imgRef = useRef<HTMLDivElement | null>(null);
  const [isFvRefScrolled, setIsFvRefScrolled] = useState(false);
  const [isImgRefScrolled, setIsImgRefScrolled] = useState(false);
  const languageStoreValue = useLanguageStoreValue();
  const { t } = useTranslation();

  const getY = (
    ref: MutableRefObject<HTMLDivElement | null>,
    scrollY: number
  ) => {
    if (!ref || !ref.current) return 0;
    const y =
      (scrollY -
        (ref.current.getBoundingClientRect().top + window.pageYOffset)) *
      0.1;
    return y;
  };

  useEffect(() => {
    onScrollPromise(fvRef.current)().then(() => setIsFvRefScrolled(true));
    onScrollPromise(imgRef.current)().then(() => setIsImgRefScrolled(true));
    return () => {
      removeOnscrollEvent(fvRef.current);
      removeOnscrollEvent(imgRef.current);
    };
  }, []);

  return (
    <Wrapper>
      <Title
        ref={fvRef}
        data-visible={isFvRefScrolled}
        data-lang-ru={languageStoreValue === LANGUAGE.ru}
      >
        {t('avatar.title')}
      </Title>
      <Text
        data-visible={isFvRefScrolled}
        data-lang-ru={languageStoreValue === LANGUAGE.ru}
      >
        {t('avatar.text')}
      </Text>
      <Img ref={imgRef} data-visible={isImgRefScrolled} />
      <Box
        ref={boxRef}
        style={{
          transform: `translate3d(0, ${px(
            getY(boxRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='273'
          height='179'
          viewBox='0 0 273 179'
        >
          <g fill='none' stroke='#707070'>
            <rect width='273' height='179' stroke='none' />
            <rect x='0.5' y='0.5' width='272' height='178' fill='none' />
          </g>
        </svg>
      </Box>
      <BoxAnim
        style={{
          transform: `translate3d(0, ${px(
            getY(boxRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='273'
          height='179'
          viewBox='0 0 273 179'
        >
          <g fill='none' stroke='#707070'>
            <rect width='273' height='179' stroke='none' />
            <rect x='0.5' y='0.5' width='272' height='178' fill='none' />
          </g>
        </svg>
      </BoxAnim>
      <BoxPc
        ref={boxPcRef}
        style={{
          transform: `translate3d(0, ${px(
            getY(boxPcRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='464'
          height='231'
          viewBox='0 0 464 231'
        >
          <g fill='none' stroke='#707070'>
            <rect width='464' height='231' stroke='none' />
            <rect x='0.5' y='0.5' width='463' height='230' fill='none' />
          </g>
        </svg>
      </BoxPc>
      <BoxPcAnim
        style={{
          transform: `translate3d(0, ${px(
            getY(boxPcRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='464'
          height='231'
          viewBox='0 0 464 231'
        >
          <g fill='none' stroke='#707070'>
            <rect width='464' height='231' stroke='none' />
            <rect x='0.5' y='0.5' width='463' height='230' fill='none' />
          </g>
        </svg>
      </BoxPcAnim>
      <Line
        ref={lineRef}
        style={{
          transform: `translate3d(0, ${px(
            getY(lineRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='400.275'
          height='331.541'
          viewBox='0 0 400.275 331.541'
        >
          <line
            x1='399'
            y2='330'
            transform='translate(0.637 0.771)'
            fill='none'
            stroke='#000'
            opacity='0.322'
          />
        </svg>
      </Line>
      <LineAnim
        style={{
          transform: `translate3d(0, ${px(
            getY(lineRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='400.275'
          height='331.541'
          viewBox='0 0 400.275 331.541'
        >
          <line
            x1='399'
            y2='330'
            transform='translate(0.637 0.771)'
            fill='none'
            stroke='#000'
            opacity='0.322'
          />
        </svg>
      </LineAnim>
      <LinePc
        ref={linePcRef}
        style={{
          transform: `translate3d(0, ${px(
            getY(linePcRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='682.061'
          height='427.695'
          viewBox='0 0 682.061 427.695'
        >
          <line
            x1='681'
            y2='426'
            transform='translate(0.53 0.848)'
            fill='none'
            stroke='#000'
            opacity='0.322'
          />
        </svg>
      </LinePc>
      <LinePcAnim
        style={{
          transform: `translate3d(0, ${px(
            getY(linePcRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='682.061'
          height='427.695'
          viewBox='0 0 682.061 427.695'
        >
          <line
            x1='681'
            y2='426'
            transform='translate(0.53 0.848)'
            fill='none'
            stroke='#000'
            opacity='0.322'
          />
        </svg>
      </LinePcAnim>
    </Wrapper>
  );
};

export default Avatar;
