import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { RecoilRoot } from 'recoil';
import { FC } from 'react';
import { MQ_DESKTOP } from 'javascript/components/landingPage/lib/MQ';
import { px } from 'javascript/components/landingPage/lib/cssUtil';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import 'swiper/modules/scrollbar/scrollbar.scss';

export const GlobalStyle = createGlobalStyle`
  body{
    font-family: 'Hiragino Sans', sans-serif;
    font-size: 16;
    line-height: 1.5;
    text-size-adjust: 100%;
    background-color: white;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    ${MQ_DESKTOP} {
      min-width: ${px(900)};
    }
  }
  button {
    font-size: 16;
    line-height: 1.5;
    font-weight: 300;
    letter-spacing: 0.8;
    text-size-adjust: 100%;
    cursor: pointer;
    opacity: 1;
    outline: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    ${MQ_DESKTOP} {
      transition: opacity ease-out .3s;
      &:hover {
        opacity: 0.6;
      }
      &:focus {
        outline: none;
      }
    }
  }
  a {
    cursor: pointer;
    opacity: 1;
    outline: none;
    text-decoration: none;
    transition: opacity ease-out .3s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    ${MQ_DESKTOP} {
      &:hover {
        opacity: 0.6;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .swiper-slide {
    user-select: none;
    div {
      user-select: none;
    }
    h3 {
      user-select: none;
    }
    p {
      user-select: none;
    }
  }
`;

const LayoutRoot: FC = ({ children }) => {
  return (
    <RecoilRoot>
      <GlobalStyle />
      {children}
    </RecoilRoot>
  );
};

export default LayoutRoot;
