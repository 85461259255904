import axios from 'axios';
import useSWR from 'swr';
import { AvatarsToPresent } from 'types';

export const useAvatarsToPresent = () => {
  const fetcher = (url: string) =>
    axios.get<AvatarsToPresent>(url).then(({ data }) => data);
  const { data } = useSWR(`/api/v1/avatars`, fetcher);

  return { avatars: data?.avatars };
};
