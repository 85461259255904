import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { useTranslation } from 'react-i18next';
import SwiperCore, { Autoplay, Controller, EffectCoverflow } from 'swiper';
import styled, { keyframes } from 'styled-components';
import {
  MQ_DESKTOP,
  MQ_MOBILE,
} from 'javascript/components/landingPage/lib/MQ';
import {
  layerZIndex,
  pcPx,
  spp,
} from 'javascript/components/landingPage/lib/cssUtil';
import {
  buttonReset,
  fadeInStyle,
  notojpStyle,
} from 'javascript/components/landingPage/local/commonCss';
import {
  onScrollPromise,
  removeOnscrollEvent,
} from 'javascript/components/landingPage/local/scrollPromise';
import { useIsSpStoreValue } from 'javascript/components/landingPage/store/isSpStore';
import {
  LANGUAGE,
  useLanguageStoreValue,
} from 'javascript/components/landingPage/store/language';

// @ts-ignore
const ASSETS_BG = RailsAssetPath('lp/world/bg.png');
// @ts-ignore
const ASSETS_BG_PC = RailsAssetPath('lp/world/bg_pc.png');
// @ts-ignore
const ASSETS_01 = RailsAssetPath('lp/world/01.png');
// @ts-ignore
const ASSETS_02 = RailsAssetPath('lp/world/02.png');
// @ts-ignore
const ASSETS_03 = RailsAssetPath('lp/world/03.png');
// @ts-ignore
const ASSETS_04 = RailsAssetPath('lp/world/04.png');
// @ts-ignore
const ASSETS_05 = RailsAssetPath('lp/world/05.png');
// @ts-ignore
const ASSETS_06 = RailsAssetPath('lp/world/06.png');
// @ts-ignore
const ASSETS_07 = RailsAssetPath('lp/world/07.png');
// @ts-ignore
const ASSETS_08 = RailsAssetPath('lp/world/08.png');
// @ts-ignore
const ASSETS_09 = RailsAssetPath('lp/world/09.png');
// @ts-ignore
const ASSETS_10 = RailsAssetPath('lp/world/10.png');
// @ts-ignore
const ASSETS_11 = RailsAssetPath('lp/world/11.png');
// @ts-ignore
const ASSETS_12 = RailsAssetPath('lp/world/12.png');
// @ts-ignore
const ASSETS_13 = RailsAssetPath('lp/world/13.png');
// @ts-ignore
const ASSETS_14 = RailsAssetPath('lp/world/14.png');
// @ts-ignore
const ASSETS_15 = RailsAssetPath('lp/world/15.png');
// @ts-ignore
const ASSETS_16 = RailsAssetPath('lp/world/16.png');
// @ts-ignore
const ASSETS_17 = RailsAssetPath('lp/world/17.png');
// @ts-ignore
const ASSETS_18 = RailsAssetPath('lp/world/18.png');
// @ts-ignore
const ASSETS_19 = RailsAssetPath('lp/world/19.png');
// @ts-ignore
const ASSETS_20 = RailsAssetPath('lp/world/20.png');

const circle = keyframes({
  from: { strokeDasharray: '0 366' },
  to: { strokeDasharray: '366 366' },
});

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  ${MQ_MOBILE} {
    height: ${spp(518)};
    background: url(${ASSETS_BG}) no-repeat;
    background-size: cover;
  }
  ${MQ_DESKTOP} {
    height: ${pcPx(880)};
    background: url(${ASSETS_BG_PC}) no-repeat;
    background-size: cover;
    display: flex;
  }
  .swiper {
    overflow: visible;
    position: relative;
    ${MQ_MOBILE} {
      width: ${spp(291)};
      height: ${spp(163)};
      perspective-origin: -510% -1610%;
    }
    ${MQ_DESKTOP} {
      perspective-origin: 0% -820%;
      width: ${pcPx(1920 * 0.35)};
      height: ${pcPx(1080 * 0.35)};
      margin: auto;
      padding-top: ${pcPx(0)};
      padding-bottom: ${pcPx(0)};
      padding-left: ${pcPx(0)};
      margin-left: ${pcPx(250)};
    }
  }
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    display: flex;
  }
  .swiper-slide {
    width: 100%;
    height: 100%;
    opacity: 0.42;
    transform-origin: left top;
    transition: 0.3s opacity ease, 1.3s transform ease;
    ${MQ_MOBILE} {
      transform: translate3d(0, 0, 0);
    }
    ${MQ_DESKTOP} {
      transform: translate3d(${pcPx(0)}, ${pcPx(0)}, ${pcPx(0)});
    }
    &[data-prev='true'] {
      opacity: 0;
      ${MQ_MOBILE} {
        transform: translate3d(${spp(100)}, ${spp(10)}, ${spp(200)}) !important;
      }
      ${MQ_DESKTOP} {
        transform: translate3d(
          ${pcPx(100)},
          ${pcPx(100)},
          ${pcPx(200)}
        ) !important;
      }
    }
    div {
      width: 100%;
      height: 100%;
      background-size: contain;
    }
  }
  .swiper-slide-next {
    ${MQ_MOBILE} {
      transform: translate3d(0, 0, 0);
    }
    ${MQ_DESKTOP} {
      transform: translate3d(${pcPx(0)}, ${pcPx(0)}, ${pcPx(0)});
    }
  }
  .swiper-slide-prev {
    opacity: 0;
    ${MQ_MOBILE} {
      transform: translate3d(${spp(0)}, ${spp(5)}, ${spp(100)}) !important;
    }
    ${MQ_DESKTOP} {
      transform: translate3d(${pcPx(0)}, ${pcPx(50)}, ${pcPx(100)}) !important;
    }
  }
  .swiper-slide-active {
    opacity: 1;
    ${MQ_MOBILE} {
      transform: translate3d(0, 0, 0);
    }
    ${MQ_DESKTOP} {
      transform: translate3d(0, 0, 0);
    }
  }
`;

const PcWrapper = styled.div`
  ${MQ_DESKTOP} {
    position: relative;
    display: flex;
    width: ${pcPx(1920)};
    height: 100%;
    margin: auto;
    margin-top: 0;
    &:before {
      content: '';
      position: absolute;
      width: 1px;
      height: ${pcPx(0)};
      background-color: white;
      bottom: 0;
      left: ${pcPx(80)};
      transition: height 0.5s cubic-bezier(0.4, 0.59, 0.41, 0.97) 0s;
    }
    &:after {
      content: '';
      position: absolute;
      width: ${pcPx(0)};
      height: ${pcPx(4)};
      background-color: white;
      top: ${pcPx(180)};
      left: ${pcPx(160)};
      transition: width 0.5s cubic-bezier(0.4, 0.59, 0.41, 0.97) 0s;
    }
    &[data-visible='true'] {
      &:before {
        height: ${pcPx(570)};
      }
      &:after {
        width: ${pcPx(193)};
      }
    }
  }
`;

const Text = styled.p`
  ${notojpStyle}
  ${fadeInStyle}
  color: white;
  font-weight: bold;
  white-space: pre-wrap;
  ${MQ_MOBILE} {
    width: ${spp(330)};
    font-size: ${spp(24)};
    line-height: ${spp(35)};
    margin-top: ${spp(100)};
    margin-left: ${spp(80)};
    margin-bottom: ${spp(50)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(40)};
    line-height: ${pcPx(58)};
    margin-top: ${pcPx(325)};
    margin-left: ${pcPx(157)};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: relative;
  z-index: ${layerZIndex('button')};
  ${MQ_MOBILE} {
    width: ${spp(300)};
    margin-top: ${spp(50)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(430)};
    position: absolute;
    top: ${pcPx(560)};
    left: ${pcPx(130)};
  }
`;

const Button = styled.button`
  ${buttonReset}
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transform: rotate(-90deg);
  ${MQ_DESKTOP} {
    width: ${pcPx(80)};
    height: ${pcPx(80)};
    margin-right: ${pcPx(2)};
  }
  ${MQ_MOBILE} {
    width: ${spp(45)};
    height: ${spp(45)};
    margin-right: ${spp(-3)};
  }
  &:after {
    content: '';
    background-color: rgba(255, 255, 255, 0.45);
    border-radius: 50%;
    position: absolute;
    ${MQ_MOBILE} {
      width: ${spp(8)};
      height: ${spp(8)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(12)};
      height: ${pcPx(12)};
    }
  }
  svg {
    width: 100%;
    height: 100%;
  }
  circle {
    fill: transparent;
    stroke: transparent;
    stroke-width: 1.5;
  }
  &[data-active='true'] {
    &:after {
      background-color: rgba(255, 255, 255, 1);
      border-radius: 50%;
      position: absolute;
      ${MQ_MOBILE} {
        width: ${spp(13)};
        height: ${spp(13)};
      }
      ${MQ_DESKTOP} {
        width: ${pcPx(18)};
        height: ${pcPx(18)};
      }
    }
    circle {
      stroke: white;
      animation: ${circle} 27s linear;
    }
  }
`;

const World = () => {
  SwiperCore.use([Autoplay, Controller, EffectCoverflow]);
  const IMAGES = [
    ASSETS_01,
    ASSETS_02,
    ASSETS_03,
    ASSETS_04,
    ASSETS_05,
    ASSETS_06,
    ASSETS_07,
    ASSETS_08,
    ASSETS_09,
    ASSETS_10,
    ASSETS_11,
    ASSETS_12,
    ASSETS_13,
    ASSETS_14,
    ASSETS_15,
    ASSETS_16,
    ASSETS_17,
    ASSETS_18,
    ASSETS_19,
    ASSETS_20,
  ];
  const [id, setId] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);
  const [activeId, setActiveId] = useState(0);
  const [swiper, setSwiper] = useState<any>(null);
  const isSlideMove = useRef(false);
  const slideX = useRef(0);
  const isSpStoreValue = useIsSpStoreValue();
  const LENGTH = IMAGES.length;
  const fvRef = useRef<HTMLDivElement | null>(null);
  const [isFvRefScrolled, setIsFvRefScrolled] = useState(false);
  const languageStoreValue = useLanguageStoreValue();
  const { t } = useTranslation();

  const onClickButton = (swiper: any, i: number) => {
    swiper.slideToLoop(i);
  };

  const onTouchStart = (swiper: any) => {
    isSlideMove.current = true;
    slideX.current = swiper.touches.startX;
  };

  const onTouchEnd = (swiper: any) => {
    if (!isSlideMove.current) return;
    isSlideMove.current = false;
    if (slideX.current < swiper.touches.startX) {
      swiper.slidePrev();
    } else {
      swiper.slideNext();
    }
  };

  useEffect(() => {
    const onResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', onResize);

    onResize();
    return () => {
      window.addEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    const LOOP_LENGTH = LENGTH * 3;
    if (!swiper) return;
    const children = swiper.$wrapperEl[0].children;

    for (let i = 0; i < LOOP_LENGTH; i++) {
      children[i].style.opacity = 0;
      children[i].setAttribute('data-prev', false);
    }
    // swiper-prev 以前のスライド
    for (let i = 0; i < id - 1; i++) {
      children[i].setAttribute('data-prev', true);
    }
    children[id + 1].style.opacity = 0.8;
    children[id + 2].style.opacity = 0.6;
    children[id].style.opacity = 1;
  }, [id]);

  useEffect(() => {
    onScrollPromise(fvRef.current)().then(() => setIsFvRefScrolled(true));
    return () => {
      removeOnscrollEvent(fvRef.current);
    };
  }, []);

  return (
    <Wrapper>
      <PcWrapper data-visible={isFvRefScrolled}>
        <Text
          ref={fvRef}
          data-visible={isFvRefScrolled}
          data-lang-ru={languageStoreValue === LANGUAGE.ru}
        >
          {t('world.copy')}
        </Text>
        <Swiper
          spaceBetween={isSpStoreValue ? windowWidth * -0.1 : -100}
          slidesPerView={'auto'}
          allowTouchMove={false}
          loop
          speed={1200}
          effect='coverflow'
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 500,
            modifier: 0.1,
            slideShadows: false,
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loopedSlides={LENGTH}
          onSwiper={setSwiper}
          onLoopFix={(swiper) => {
            setId(swiper.activeIndex);
          }}
          onSlideChange={(swiper) => {
            setId(swiper.activeIndex);
            setActiveId(swiper.realIndex);
          }}
          onTouchStart={(swiper) => onTouchStart(swiper)}
          onTouchEnd={(swiper) => onTouchEnd(swiper)}
        >
          {IMAGES.map((_, i) => (
            <SwiperSlide key={i}>
              <div style={{ backgroundImage: `url(${IMAGES[i]})` }} />
            </SwiperSlide>
          ))}
        </Swiper>
        <ButtonWrapper>
          {Array(LENGTH)
            .fill(0)
            .map((_, i) => (
              <Button
                data-active={i === activeId}
                key={i}
                onClick={() => onClickButton(swiper, i)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='30'
                  height='30'
                  viewBox='0 0 30 30'
                >
                  <circle cx='15' cy='15' r='13' />
                </svg>
              </Button>
            ))}
        </ButtonWrapper>
      </PcWrapper>
    </Wrapper>
  );
};

export default World;
