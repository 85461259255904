import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { device } from 'javascript/utils/mediaQuery';

const UserMenuWrapper = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 1;
  position: absolute;
  width: 100%;
  top: 0;
  height: 100vh;
  right: 0;
`;

const UserMenuBox = styled.div`
  position: absolute;
  min-width: 140px;
  padding: 8px 0;
  font-size: 10px;
  text-align: left;
  border-radius: 5px;
  backdrop-filter: blur(12px);
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 3px 0px grey;
  color: #303030;
  top: 70px;
  right: 70px;
  z-index: 1;
  @media ${device.sp} {
    width: auto;
    right: 5px;
    top: 80px;
  }
`;

const mixinUserMenuItem = css`
  padding: 8px 16px 8px 8px;
  display: block;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`;

const UserMenuItemText = styled.p`
  ${mixinUserMenuItem}
  display: flex;
  align-items: center;
`;

const UserMenuItemLink = styled.a`
  ${mixinUserMenuItem}
`;

const UserMenuItemIcon = styled.i<{ icon: string }>`
  display: inline-block;
  padding: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
  vertical-align: middle;
  background-image: ${({ icon }) => `url(${icon})`};
`;

interface Props {
  userId: number;
  isOpen: boolean;
  isNonActiveUser: boolean;
  toggleUserMenu: () => void;
}

export const UserMenu: React.FC<Props> = ({
  userId,
  isOpen,
  toggleUserMenu,
  isNonActiveUser,
}) => {
  const { t } = useTranslation();
  const isThanksPage = location.pathname.includes('/thanks/');

  return (
    <UserMenuWrapper
      isOpen={isOpen}
      onClick={() => {
        toggleUserMenu();
      }}
    >
      <UserMenuBox>
        {!isNonActiveUser && (
          <>
            {isThanksPage ? (
              <>
                <a href={`/mypage/${userId}`}>
                  <UserMenuItemText>
                    <UserMenuItemIcon
                      // @ts-ignore
                      icon={RailsAssetPath('pencil-blue.png')}
                    />
                    {t('MenuUserMypage', {})}
                  </UserMenuItemText>
                </a>
                <a href={`/mypage/${userId}?target=avatar`}>
                  <UserMenuItemText>
                    <UserMenuItemIcon
                      // @ts-ignore
                      icon={RailsAssetPath('mypage/dressup_icon.png')}
                    />
                    {t('MenuUserAvatar', {})}
                  </UserMenuItemText>
                </a>
                <a href='/shop'>
                  <UserMenuItemText>
                    {/* @ts-ignore */}
                    <UserMenuItemIcon icon={RailsAssetPath('shop_blue.png')} />
                    {t('MenuUserShop', {})}
                  </UserMenuItemText>
                </a>
                <a href='/mybox'>
                  <UserMenuItemText>
                    {/* @ts-ignore */}
                    <UserMenuItemIcon icon={RailsAssetPath('mybox_blue.png')} />
                    {t('MenuUserMybox', {})}
                  </UserMenuItemText>
                </a>
              </>
            ) : (
              <>
                <Link to={`/mypage/${userId}`}>
                  <UserMenuItemText>
                    <UserMenuItemIcon
                      // @ts-ignore
                      icon={RailsAssetPath('pencil-blue.png')}
                    />
                    {t('MenuUserMypage', {})}
                  </UserMenuItemText>
                </Link>
                <Link to={`/mypage/${userId}?target=avatar`}>
                  <UserMenuItemText>
                    <UserMenuItemIcon
                      // @ts-ignore
                      icon={RailsAssetPath('mypage/dressup_icon.png')}
                    />
                    {t('MenuUserAvatar', {})}
                  </UserMenuItemText>
                </Link>
                <Link to='/shop'>
                  <UserMenuItemText>
                    {/* @ts-ignore */}
                    <UserMenuItemIcon icon={RailsAssetPath('shop_blue.png')} />
                    {t('MenuUserShop', {})}
                  </UserMenuItemText>
                </Link>
                <Link to='/mybox'>
                  <UserMenuItemText>
                    {/* @ts-ignore */}
                    <UserMenuItemIcon icon={RailsAssetPath('mybox_blue.png')} />
                    {t('MenuUserMybox', {})}
                  </UserMenuItemText>
                </Link>
              </>
            )}
          </>
        )}
        <UserMenuItemLink
          data-confirm={t('MenuUserConfirmLogout', {})}
          data-method='delete'
          href='/sign_out'
          rel='nofollow'
        >
          {/* @ts-ignore */}
          <UserMenuItemIcon icon={RailsAssetPath('door.png')} />
          {t('MenuUserLogout', {})}
        </UserMenuItemLink>
      </UserMenuBox>
    </UserMenuWrapper>
  );
};
