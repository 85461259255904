import React from 'react';
import styled, { css } from 'styled-components';
import { device } from 'javascript/utils/mediaQuery';
import { UserProfile as TypeUserProfile } from 'types';
import { useTranslation } from 'react-i18next';

interface Props {
  userData: TypeUserProfile;
  isEditable: boolean;
  handleEditUser: (userData: TypeUserProfile) => void;
}

export const UserProfile: React.FC<Props> = ({
  userData,
  isEditable,
  handleEditUser,
}) => {
  const { t } = useTranslation();

  return (
    <StyledUserProfile>
      {isEditable && (
        <StyledSettingButtonWrapper>
          <StyledSettingButton
            onClick={() => {
              handleEditUser(userData);
            }}
          >
            <StyledSettingIconWrapper
              // @ts-ignore
              src={RailsAssetPath('pencil.png')}
            />
            {t('ProfileCategorySetting', {})}
          </StyledSettingButton>
        </StyledSettingButtonWrapper>
      )}
      <StyledUserName>{userData.user.user_name}</StyledUserName>
      <StyledTeamName>
        {userData.user.team_name || t('GenelalTextNoSet', {})}
      </StyledTeamName>
      <StyledPositionName>
        {userData.user.position_name || t('GenelalTextNoSet', {})}
      </StyledPositionName>
      <StyledDateBadge>
        {t('ProfileCategoryDateEmployment', {})}
      </StyledDateBadge>
      <StyledDate>
        {userData.user.join_date || t('GenelalTextNoSet', {})}
      </StyledDate>
      <StyledDateBadge>{t('ProfileCategoryBirthday', {})}</StyledDateBadge>
      <StyledDate>
        {userData.user.birth_date || t('GenelalTextNoSet', {})}
      </StyledDate>
    </StyledUserProfile>
  );
};

const StyledUserProfile = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 8px 16px;
  background-color: #fafafa;
  border-radius: 8px;
`;

const StyledUserName = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  width: 100%;
  font-weight: bold;
`;

const Truncate = css`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledTeamName = styled.p`
  ${Truncate}
  margin-top: 16px;
`;

const StyledPositionName = styled.p`
  ${Truncate}
  margin-top: 4px;
`;

const StyledDateBadge = styled.div`
  width: 80px;
  margin-top: 16px;
  padding: 2px 0;
  color: #fff;
  background-color: #00a0e9;
  border-radius: 10px;
  font-size: 10px;
`;

const StyledDate = styled.p`
  margin-top: 2px;
  font-size: 14px;
`;

const StyledSettingButton = styled.div`
  width: 84px;
  padding: 5px 5px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #bfbfc3;
  color: #999;
  text-align: center;
  cursor: pointer;
  @media ${device.sp} {
    width: 72px;
  }
`;

const StyledSettingButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  @media ${device.sp} {
    top: 12px;
    right: 12px;
  }
`;

const StyledSettingIconWrapper = styled.img`
  display: inline;
  width: 15px;
  margin-right: 5px;
`;
