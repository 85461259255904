import React, { useState, useEffect, useCallback, useGlobal } from "reactn";
import {
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { PAYMENT_METHOD_STEPS  } from "../constants";
const DialogCloseButton = ({onClose}) => {
  return (
    <DialogTitle>
      <IconButton size="small" aria-label="close" onClick={onClose}>
        <img src={RailsAssetPath('close-img-gray.png')} />
      </IconButton>
    </DialogTitle>
  )
}

export default DialogCloseButton;