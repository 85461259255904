import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useGlobal } from 'reactn';
import { connect } from 'react-redux';
import axios from 'axios';

import FormWorkStartForm from '../form/FormWorkStart';
import FormWorkFinishForm from '../form/FormWorkFinish';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: '10px',
    // width                 : '600px',
    height: 'auto',
    background: 'white',
    overflow: 'hidden',
    boxShadow: '0 0 5px #0000008c',
    // minHeight             : '670px'
  },
  overlay: {
    background: 'rgb(62 62 62 / 41%)',
    zIndex: '100',
  },
};

Modal.setAppElement('body'); //react-modal初期設定

const ModalWorkRegister = ({ currentUser }) => {
  const [realList, setRealList] = useState([]);

  const fetchRealList = () => {
    axios.get('/api/v1/users/me/reals_list').then((res) => {
      setRealList(res.data.reals_list);
    });
  };

  useEffect(() => {
    fetchRealList();
  }, []);

  const [isShowModal, setIsShowModal] = useGlobal('isShowModal');

  return (
    <Modal
      isOpen={isShowModal}
      style={customStyles}
      contentLabel='Example Modal'
    >
      <span
        className='close_btn'
        onClick={() => {
          setIsShowModal(false);
        }}
      >
        <i>close</i>
      </span>
      <FormWorkRegister user={currentUser} realList={realList} />
    </Modal>
  );
};

const FormWorkRegister = ({ user, realList}) => {
  const userStatus = user.work_status;
  const [todayDetailList, setTodayDetailList] = useState([]);
  const fetchTodayDetailList = () => {
    axios.get('/api/v1/attendances/today_detail').then((res) => {
      setTodayDetailList(res.data);
    });
  };

  useEffect(() => {
    fetchTodayDetailList();
  }, []);

  return (
    <>
      {userStatus == 'not_working' || userStatus == 'finished' ? (
        <FormWorkStartForm user={user} realList={realList} />
      ) : (
        <FormWorkFinishForm user={user} todayDetailList={todayDetailList}/>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.setUser.currentUser,
  };
};

export default connect(mapStateToProps)(ModalWorkRegister);
