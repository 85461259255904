import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import ProfileThumbnail from '../items/ProfileThumbnail';
import consumer from '../../channels/consumer';
import DailyReport from '../../lib/model/DailyReport';
import { sp } from '../../utils/mediaQuery';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useDeleteNews } from 'javascript/features/news/hooks/useDeleteNews';
import { Tooltip } from 'javascript/features/news/components/Tooltip';
import { useTooltip } from 'javascript/features/news/hooks/useTooltip';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SHOW_FEED_TYPE } from '../../utils/util';
import { useGlobal } from 'reactn';

const MAX_LENGTH = 2;
const MAX_RATE = 5;
const INITIAL_INDEX = 0;
const REPORT_TEXT = {
  DEFAULT: 'ReportTextGiveStars',
  //JUST_SUBMITTED: '評価しました！',
  SUBMITTED: 'ReportAlertAlreadyGivenStar',
};

// 評価機能
const ReportEvaluation = ({
  report,
  currentUser,
  feedType,
  showReport,
  localDeletedNewsIds,
  saveLocalDeletedNewsIds,
}) => {
  const { t } = useTranslation();
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [isShowStar, setIsShowStar] = useState(false);
  const [isStarred, setIsStarred] = useState(report.evaluated_flg);
  const [comments, setComments] = useState(report.comments);
  const [commentCount, setCommentCount] = useState(report.comment_count);
  //ログインユーザーが評価ポイント数
  const [evaluatedPoint, setEvaluatedPoint] = useState(
    report.evaluated_daily_report_point
  );
  //評価ポイント数の総合計
  const [totalEvaluatedPoint, setTotalEvaluatedPoint] = useState(
    report.total_daily_report_point
  );
  // ビケをプレゼントしたメンバーのリスト
  const [memberGivingViketList, setMemberGivingViketList] = useState(
    report.daily_report_user_list
  );
  const [reportPointText, setReportPointText] = useState(
    t(REPORT_TEXT.DEFAULT)
  );
  const [starPos, setStarPos] = useState({ top: 0, out: false });

  const { deleteNews } = useDeleteNews(report.id, saveLocalDeletedNewsIds);

  const [feedShowType, setFeedShowType] = useGlobal('feedShowType');

  useEffect(() => {
    subscribe();
    update();
  }, [report]);

  const isSelf = useMemo(
    () => currentUser.id === report.user.id,
    [currentUser.id, report.user.id]
  );
  const isMaster = useMemo(
    () => currentUser.role === 'master',
    [currentUser.role]
  );

  const update = () => {
    setIsCommentOpen(false);
    setIsShowStar(false);
    setIsStarred(report.evaluated_flg);
    setComments(report.comments);
    setCommentCount(report.comment_count);
    setTotalEvaluatedPoint(report.total_daily_report_point);
    setEvaluatedPoint(report.evaluated_daily_report_point);
  };

  const isReports = useMemo(() => feedType === 'Reports', [feedType]);

  //subscribe to DailyReportChannel
  const subscribe = () =>
    consumer.subscriptions.create(
      feedShowType === SHOW_FEED_TYPE.REPORT ? {
        channel: 'DailyReportChannel',
        daily_report_id: report.id,
      } : feedShowType === SHOW_FEED_TYPE.FORUM ? {
        channel: 'NoticeBoardChannel',
        notice_board_id: report.id,
      } : feedShowType === SHOW_FEED_TYPE.SURVEY? {
        channel: 'QuestionChannel',
        question_id: report.id,
      } : NULL,
      {
        received(data) {
          if (data.type == 'comment') {
            updateReportData(data.comments);
          }

          if (data.type == 'evaluate') {
            updateEvaluateData(data.total_daily_report_point);
            setMemberGivingViketList(data.daily_report_user_list);
          }
        },
        comment(content) {
          return this.perform('comment', {
            content: content.comment,
            feeling_status: content.feeling_status,
          });
        },
        evaluate(val) {
          return this.perform('evaluate', { value: val });
        },
      }
    );

  const cable = subscribe();

  const updateEvaluateData = (value) => {
    setTotalEvaluatedPoint(value);
  };

  const handleToggleComment = () => {
    //setIsCommentOpen(prevState => !prevState);
    setIsCommentOpen(true);
  };

  const handleToggleStar = (e) => {
    const elm = e.target;
    const offset = elm.getBoundingClientRect();
    const wh = window.innerHeight;
    let isOut = false;
    if (wh - offset.top < 105) {
      isOut = true;
    }
    setStarPos({ top: offset.top, out: isOut });
    setIsShowStar(true);
  };

  const updateReportData = (data) => {
    setCommentCount(data.length);
    setComments(data);
  };

  const closeRating = () => {
    setIsShowStar(false);
  };

  const initialStateStars = [];
  for (let i = 0; i < MAX_RATE; i++) {
    initialStateStars.push({ state: false });
  }

  const submitPoint = (point) => {
    setIsStarred(true);
    setReportPointText(t('ReportMessageYouGiveStar', { star: point }));
    setEvaluatedPoint(point);
    cable.evaluate(point);
    setTimeout(() => {
      closeRating();
    }, 1200);
  };

  return (
    <>
      <EvaluateIconRow>
        {!isReports && (isSelf || isMaster) && (
          <EvaluateIcon
            onClick={deleteNews}
            icon={RailsAssetPath('delete_red.png')}
          />
        )}
        <EvaluateIcon
          onClick={handleToggleComment}
          icon={RailsAssetPath('diary/icon_fukidashi_outline.png')}
        />
        {commentCount}
        {isReports && (
          <EvaluateIcon
            viket
            onClick={handleToggleStar}
            icon={RailsAssetPath('mypage/coin.png')}
            isGiven={isStarred}
          />
        )}
        {isReports && totalEvaluatedPoint}
      </EvaluateIconRow>
      <ReportComment
        isOpen={isCommentOpen}
        setIsShowComment={handleToggleComment}
        comments={comments}
        cable={cable}
        currentUser={currentUser}
        showReport={showReport}
      />
      {isShowStar && (
        <ReportEvaluate
          onClickClose={closeRating}
          initialStateStars={initialStateStars}
          submitPoint={submitPoint}
          isStarred={isStarred}
          reportPointText={reportPointText}
          points={evaluatedPoint}
          pos={starPos}
          isSelf={isSelf}
          evaluateUsers={memberGivingViketList}
        />
      )}
    </>
  );
};

const ReportEvaluate = ({
  onClickClose,
  initialStateStars,
  submitPoint,
  isStarred,
  reportPointText,
  points,
  pos,
  isSelf,
  evaluateUsers,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 430 });
  const [starList, setStarList] = useState(initialStateStars);
  const { t } = useTranslation();

  const currentUser = useSelector((state) => state.setUser.currentUser);

  useEffect(() => {
    init();
  }, []);

  ReportEvaluate.propTypes = {
    onClickClose: propTypes.func.isRequired,
    initialStateStars: propTypes.array.isRequired,
    submitPoint: propTypes.func.isRequired,
    isStarred: propTypes.bool.isRequired,
    reportPointText: propTypes.string.isRequired,
    points: propTypes.number.isRequired,
    pos: propTypes.object.isRequired,
  };

  const init = () => {
    if (isStarred && points !== 0) {
      setInitialStars(points);
    }
  };

  const setInitialStars = (index) => {
    const list = [...starList];
    for (let i = 0; i < index; i++) {
      list[i].state = true;
      setStarList(list);
    }
  };

  const sound = new Audio('/assets/coin.mp3');
  sound.volume = 0.3;
  const evaluate = (index) => {
    if (isSelf) {
      alert(t('ReportAlertCantYourDiaryReport', {}));
      return;
    }
    if (isStarred) {
      alert(t('ReportAlertAlreadyGiveStars', {}));
      return;
    }
    setInitialStars(index + 1);
    submitPoint(index + 1);
    !isMobile && currentUser.speaker && sound.play();
  };

  const stars = [];
  for (let i = 0; i < MAX_RATE; i++) {
    const image = RailsAssetPath('mypage/coin.png');
    stars.push(
      <EvaluateStar
        key={i}
        isGiven={starList[i].state}
        src={image}
        onClick={() => evaluate(i)}
      />
    );
  }

  return (
    <EvaluateStarBox pos={pos}>
      <EmotionCloseButton onClick={onClickClose} />
      <EvaluateStarHeader>
        {isSelf
          ? t('ReportAlertCantYourDiaryReport', {})
          : isStarred
          ? t(REPORT_TEXT.SUBMITTED)
          : reportPointText}
      </EvaluateStarHeader>
      <EvaluateStarRow>{stars}</EvaluateStarRow>
      {evaluateUsers && evaluateUsers.length !== 0 && (
        <>
          <StyledNameListTitle>
            {t('ReportTextMembergivingviket', {})}
          </StyledNameListTitle>
          <StyledNameList>
            {evaluateUsers.map((user, i) => (
              <p key={i}>{user}</p>
            ))}
          </StyledNameList>
        </>
      )}
    </EvaluateStarBox>
  );
};

const EvaluateStarBox = styled.div`
  width: 280px;
  background: #fff;
  position: absolute;
  right: 135px;
  z-index: 10;
  ${(props) =>
    props.pos.out
      ? `
    top: ${props.pos.top - 30}px;
  `
      : `
    top: ${props.pos.top + 55}px;
  `}
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 7px 0px rgba(0, 0, 0, 0.3);
  ${sp`
    right: 10px;
  `}
`;

const EvaluateStarHeader = styled.div`
  text-align: center;
  font-size: 11px;
`;

const EvaluateStarRow = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0 5px;
  padding-bottom: 8px;
  border-bottom: 1px solid #ccc;
`;

const StyledNameListTitle = styled.p`
  font-size: 12px;
`;

const StyledNameList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  max-height: 80px;
  margin-top: 8px;
  padding-left: 12px;
  font-size: 11px;
  overflow: scroll;
`;

const EvaluateStar = styled.img`
  height: 100%;
  width: 23px;
  margin: 6px;
  opacity: ${({ isGiven }) => (isGiven ? '1' : '0.2')};
`;

const EvaluateIcon = styled.div`
  margin: 5px 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  background: #fff;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    display: inline-block;
    width: 18px;
    height: 18px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: center / contain url(${(props) => props.icon}) no-repeat;
    opacity: ${({ viket, isGiven }) => (viket && isGiven ? '1' : '0.5')};
  }
`;

const EvaluateIconRow = styled.div`
  margin-top: 20px;
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #00a0e9;
`;

const ReportComment = ({
  isOpen,
  setIsShowComment,
  comments,
  cable,
  currentUser,
  showReport,
}) => {
  ReportComment.propTypes = {
    isOpen: propTypes.bool.isRequired,
    // comments: propTypes.array.isRequired 一時的に
  };

  //なくてもいいかもだけど念のためpromise
  const onSubmit = (data) => {
    return new Promise((resolve, reject) => {
      cable.comment(data);
      setTimeout(() => {
        resolve();
      }, 200);
    });
  };

  return (
    <CommentWrapper>
      <ReportCommentInput
        onSubmit={(data) => onSubmit(data)}
        currentUser={currentUser}
      />
      <ReportCommentList
        comments={comments}
        isOpen={isOpen}
        setIsShowComment={setIsShowComment}
        showReport={showReport}
        isCast={currentUser.role == "cast"}
      />
    </CommentWrapper>
  );
};

const CommentWrapper = styled.div`
  position: relative;
`;

const ReportCommentInput = ({ onSubmit, currentUser }) => {
  const isMobile = useMediaQuery({ maxWidth: 430 });
  ReportCommentInput.propTypes = {
    onSubmit: propTypes.func.isRequired,
  };

  const [textValue, setTextValue] = useState('');
  const [emotionId, setEmotionId] = useState(null);
  const [isShowSelectEmotion, setIsShowSelectEmotion] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const openEmotionBox = () => {
    setIsShowSelectEmotion((prevState) => !prevState);
  };

  const sound = new Audio('/assets/coin.mp3');
  sound.volume = 0.3;
  const handleSubmit = () => {
    // 改行のみでの送信を防止
    const textIndex = textValue.search(/[^<div><br></div>]+/g);
    if (textValue.length <= 0 || textIndex < 0) {
      return false;
    }
    const params = {
      comment: textValue,
      feeling_status: emotionId,
    };
    onSubmit(params).then((res) => {
      handleDelete();
      !isMobile && currentUser.speaker && sound.play();
    });
  };

  const handleDelete = () => {
    setTextValue('');
    setEmotionId(null);
    setIsSubmitted(true);
  };

  const resetState = () => {
    setIsSubmitted(false);
  };

  const onSelectEmotion = (id) => {
    setEmotionId(id);
    setIsShowSelectEmotion(false);
  };

  return (
    <SingleComment>
      <ProfileThumbnail size='35px' image={currentUser.image} />
      <SingleCommentInputBox>
        <SingleCommentInput mode='input'>
          <ContentEditable
            onChange={(obj) => setTextValue(obj.value)}
            submitted={isSubmitted}
            resetState={resetState}
          />
          <TextInputButtonWrapper>
            <TextInputButton
              onClick={openEmotionBox}
              icon={
                emotionId === 1
                  ? RailsAssetPath('icon-heart-blue.png')
                  : emotionId === 2
                  ? RailsAssetPath('diary/star.png')
                  : emotionId === 3
                  ? RailsAssetPath('diary/party_cracker.png')
                  : emotionId === 4
                  ? RailsAssetPath('diary/cheer_horn.png')
                  : RailsAssetPath('diary/icon_smile.png')
              }
            ></TextInputButton>
            <TextInputButton
              onClick={handleSubmit}
              icon={RailsAssetPath('diary/icon_send_plane.png')}
            />
          </TextInputButtonWrapper>
        </SingleCommentInput>
      </SingleCommentInputBox>
      {isShowSelectEmotion && (
        <ReportSelectEmotion
          onClickClose={openEmotionBox}
          onSelectEmotion={(id) => onSelectEmotion(id)}
          selectedId={emotionId}
        />
      )}
    </SingleComment>
  );
};

const ContentEditable = ({ onChange, submitted, resetState }) => {
  const { t } = useTranslation();

  useEffect(() => {
    updateInput();
  }, [submitted]);

  const value = useRef();

  const handleInput = (e) => {
    if (onChange) {
      onChange({ value: e.target.innerHTML });
    }
  };

  const updateInput = () => {
    if (submitted) {
      value.current.innerHTML = '';
      resetState();
    }
  };

  return (
    <TextInput
      contentEditable
      onInput={handleInput}
      ref={value}
      placeholder={t('ReportTextEnterComment', {})}
    />
  );
};

const ReportSelectEmotion = ({ onClickClose, onSelectEmotion, selectedId }) => {
  const config = DailyReport.config.feelingStatus;
  const { t } = useTranslation();

  const itemArray = [];
  Object.keys(config).forEach((key) => {
    itemArray.push(config[key]);
  });

  return (
    <EmotionBox>
      <EmotionCloseButton onClick={onClickClose} />
      <EmotionHeader>{t('ReportTextCommunicateFeelings', {})}</EmotionHeader>
      <EmotionList>
        {itemArray.map((item, key) => (
          <EmotionItem
            key={key}
            onClick={() => onSelectEmotion(key + 1)}
            current={key + 1 === selectedId ? true : false}
          >
            <EmotionIcon icon={RailsAssetPath(item.icon)} />
            {t(item.text)}
          </EmotionItem>
        ))}
      </EmotionList>
    </EmotionBox>
  );
};

const EmotionBox = styled.div`
  width: 190px;
  padding: 33px 0 5px;
  border-radius: 8px;
  background: #fff;
  position: absolute;
  top: 50px;
  right: -55px;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.2);
  z-index: 10;
  font-size: 13px;
  ${sp`
    right: 0;
  `}
`;

const EmotionCloseButton = styled.button`
  position: absolute;
  left: 15px;
  top: 12px;
  width: 15px;
  height: 15px;
  &::before,
  &::after {
    content: '';
    display: inline-block;
    background: #0e0e0e;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(135deg);
  }
`;

const EmotionHeader = styled.div`
  text-align: center;
  color: #808080;
`;

const EmotionList = styled.div`
  width: 100%;
  margin: 10px 0 20px;
`;

const EmotionItem = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 20px;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  ${(props) => props.current && `background: rgba(0,0,0,0.1);`}
`;

const EmotionIcon = styled.i`
  display: inline-block;
  background: center / cover url(${(props) => props.icon}) no-repeat;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  margin-right: 20px;
`;

const ReportCommentList = ({
  comments,
  setIsShowComment,
  isOpen,
  showReport,
  isCast,
}) => {
  const [isShowReadMore, setIsShowReadMore] = useState(false);
  const [hasOpened, setHasOpened] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const { t } = useTranslation();

  if (!comments) {
    return null;
  }

  useEffect(() => {
    init();
    if (isOpen) {
      onReadMore();
    }
  }, [comments, isOpen]);

  const init = () => {
    if (hasOpened) {
      setCommentList(comments);
      return;
    }
    if (comments.length > MAX_LENGTH) {
      const arr = comments.slice(INITIAL_INDEX, MAX_LENGTH);
      setCommentList(arr);
      setIsShowReadMore(true);
    } else {
      setCommentList(comments);
      setIsShowReadMore(false);
    }
  };

  const onReadMore = () => {
    setIsShowComment();
    setCommentList(comments);
    setIsShowReadMore(false);
    setHasOpened(true);
  };

  const count = comments.length - MAX_LENGTH;

  return (
    <>
      {commentList.length > 0
        ? commentList.map((comment, key) => (
            <CommentItem comment={comment} key={key} showReport={showReport} isCast={isCast} />
          ))
        : null}
      {isShowReadMore && (
        <ReadMoreButton>
          <span onClick={onReadMore}>
            {t('ReportTextShowOtherComment', { count: count })}
          </span>
        </ReadMoreButton>
      )}
    </>
  );
};

const CommentItem = ({ comment, showReport, isCast }) => {
  const { t } = useTranslation();
  const config = DailyReport.config.feelingStatus;
  const history = useHistory();

  const {
    targetRef,
    isHovered,
    handleOnMouseEnter,
    handleOnMouseLeave,
    handleOnMouseMove,
  } = useTooltip(comment.user.avatar_id);

  const handleOnClick = useCallback((memberId, e) => {
    if (!isCast) {
      e.stopPropagation();
      history.push(`mypage/${memberId}`);
    }
  }, []);

  return (
    <SingleComment>
      <StyledRealWorldMember
        ref={targetRef}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        onMouseMove={handleOnMouseMove}
        onClick={(e) => handleOnClick(comment.user.id, e)}
      >
        <ProfileThumbnail
          size='35px'
          image={comment.user.image}
          showReport={showReport}
          user={comment.user}
        />
        <Tooltip isHovered={isHovered} text={comment.user.name} />
      </StyledRealWorldMember>
      <SingleCommentDisplayBox>
        <SingleCommentInput
          dangerouslySetInnerHTML={{ __html: comment.content }}
          mode='display'
        />
      </SingleCommentDisplayBox>
      <SingleCommentDate>{comment.created_at}</SingleCommentDate>
      {comment.feeling_status ? (     
        <SingleCommentEmotion>
          <SingleCommentEmotionIcon
            icon={RailsAssetPath(config[comment.feeling_status].icon)}
          />
          {comment.feeling_status === 1
            ? t('ReportStatusThankyou', {})
            : comment.feeling_status === 2
            ? t('ReportStatusAmazing', {})
            : comment.feeling_status === 3
            ? t('ReportStatusCongrat', {})
            : t('ReportStatusFight', {})}
        </SingleCommentEmotion>
      ) : null}
    </SingleComment>
  );
};

const StyledRealWorldMember = styled.div`
  cursor: pointer;
  position: relative;
`;

const ReadMoreButton = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
  span {
    color: #00a0e9;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
`;

const SingleComment = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px auto;
  padding: 10px 35px 10px 10px;
  color: #303030;
  flex-wrap: wrap;
  position: relative;
  ${sp`
    padding: 10px 10px 10px 0px;
  `}
`;

const SingleCommentDate = styled.div`
  margin-left: 60px;
  font-size: 12px;
  color: #b6b6b6;
  ${sp`
    margin-left: 18%;
  `}
`;

const SingleCommentEmotionIcon = styled.i`
  display: inline-block;
  width: 12px;
  height: 12px;
  background: center / cover url(${(props) => props.icon}) no-repeat;
  margin-right: 10px;
`;

const SingleCommentEmotion = styled.div`
  background: #fff;
  color: #00a0e9;
  border: 1px solid #00a0e9;
  border-radius: 3px;
  display: flex;
  position: absolute;
  top: 0;
  left: 70px;
  padding: 3px 14px;
  font-size: 10px;
  justify-content: flex-start;
  align-items: center;
  line-height: 12px;
  ${sp`
    left: 18%;
  `}
`;

const SingleCommentInputBox = styled.div`
  width: 555px;
  border: 1px solid #00a0e9;
  border-radius: 5px;
  overflow: hidden;
  padding: 10px 20px;
  position: relative;
  ${sp`
    width: 80%;
    padding: 10px;
  `}
`;

const SingleCommentDisplayBox = styled.div`
  width: 555px;
  border-radius: 5px;
  overflow: hidden;
  padding: 15px 0 10px;
  position: relative;
  ${sp`
    width: 82%;
  `}
`;

const SingleCommentInput = styled.div`
  height: 100%;
  font-size: 13px;
  word-break: break-all;
  a {
    border-bottom: 1px solid #00a0e9;
    color: #00a0e9;
  }
  ${(props) =>
    props.mode == 'input' &&
    `
    display: flex;
    align-items: flex-end;
  `}
`;

const TextInput = styled.div`
  width: 100%;
  height: 100%;
  outline: none;
  color: #303030;
  max-width: 435px;
  word-break: break-all;
  &[contenteditable='true']:empty:before {
    content: '${(props) => props.placeholder}';
    opacity: 0.5;
    display: block;
    pointer-events: none;
  }
  ${sp`
    max-width: 39vw;
  `}
`;

const TextInputButtonWrapper = styled.div`
  display: flex;
  margin-left: 10px;
`;

const TextInputButton = styled.div`
  display: inline-block;
  border-radius: 50%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: relative;
  margin-left: 10px;
  &:first-child {
    background: center / 20px url(${(props) => props.icon}) no-repeat;
  }
  &:last-child {
    background: center / 24px url(${(props) => props.icon}) no-repeat;
  }
  &:before {
    content: '';
    opacity: 0;
    position: absolute;
    width: 32px;
    height: 32px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
  }
  &:hover::before {
    opacity: 1;
  }
`;

export default ReportEvaluation;
