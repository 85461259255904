/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, {
  Pagination,
  Autoplay,
  Controller,
  EffectCoverflow,
} from 'swiper';
import styled, { css, keyframes } from 'styled-components';
import {
  MQ_DESKTOP,
  MQ_MOBILE,
} from 'javascript/components/landingPage/lib/MQ';
import { pcPx, px, spp } from 'javascript/components/landingPage/lib/cssUtil';
import {
  BLACK,
  fadeInStyle,
  GRAY,
  jpStyle,
  onlyPC,
  onlySP,
} from 'javascript/components/landingPage/local/commonCss';
import {
  onScrollPromise,
  removeOnscrollEvent,
} from 'javascript/components/landingPage/local/scrollPromise';
import { useIsSpStoreValue } from 'javascript/components/landingPage/store/isSpStore';
import {
  LANGUAGE,
  useLanguageStoreValue,
} from 'javascript/components/landingPage/store/language';
import { useScrollYStoreValue } from 'javascript/components/landingPage/store/scrollYStore';

// @ts-ignore
const ASSETS_OPEN = RailsAssetPath('lp/openchat/open.png');
// @ts-ignore
const ASSETS_OPEN_PC = RailsAssetPath('lp/openchat/open_pc.png');
// @ts-ignore
const ASSETS_GROUP = RailsAssetPath('lp/openchat/group.png');
// @ts-ignore
const ASSETS_GROUP_PC = RailsAssetPath('lp/openchat/group_pc.png');
// @ts-ignore
const ASSETS_MYU = RailsAssetPath('lp/openchat/myu.png');
// @ts-ignore
const ASSETS_MYU_PC = RailsAssetPath('lp/openchat/myu_pc.png');
// @ts-ignore
const ASSETS_MENU = RailsAssetPath('lp/openchat/avatar-menu.png');
// @ts-ignore
const ASSETS_MENU_PC = RailsAssetPath('lp/openchat/avatar-menu_pc.png');
// @ts-ignore
const ASSETS_PRESENT = RailsAssetPath('lp/openchat/present.png');
// @ts-ignore
const ASSETS_PRESENT_PC = RailsAssetPath('lp/openchat/present_pc.png');
// @ts-ignore
const ASSETS_TALK = RailsAssetPath('lp/openchat/talk.png');
// @ts-ignore
const ASSETS_TALK_PC = RailsAssetPath('lp/openchat/talk_pc.png');

const path = keyframes({
  from: { strokeDashoffset: spp(-1500) },
  to: { strokeDashoffset: spp(1500) },
});

const pathPc = keyframes({
  from: { strokeDashoffset: pcPx(-1550) },
  to: { strokeDashoffset: pcPx(1550) },
});

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  ${MQ_MOBILE} {
    padding-top: ${spp(80)};
  }
  ${MQ_DESKTOP} {
    &:before {
      content: '';
      background-color: rgba(255, 255, 255, 0.39);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: ${pcPx(1800)};
      height: 100%;
      top: 0;
    }
  }
  .swiper {
    overflow: visible;
    position: relative;
    perspective-origin: -10% 0%;
    ${MQ_MOBILE} {
      padding-bottom: ${spp(60)};
    }
    ${MQ_DESKTOP} {
      width: 750px;
      margin: auto;
      padding-top: ${pcPx(30)};
      padding-bottom: ${pcPx(80)};
      padding-right: ${pcPx(400)};
    }
  }
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    display: flex;
  }
  .swiper-slide {
    width: 100%;
    height: 100%;
    opacity: 0.42;
    transform-origin: left top;
    transition: 0.3s opacity ease, 1.3s transform ease;
    ${MQ_MOBILE} {
      transform: translate3d(0, 0, 0);
    }
    ${MQ_DESKTOP} {
      transform: translate3d(${pcPx(0)}, ${pcPx(0)}, ${pcPx(0)});
    }
    &[data-prev='true'] {
      opacity: 0;
      ${MQ_MOBILE} {
        transform: translate3d(
          ${spp(100)},
          ${spp(500)},
          ${spp(200)}
        ) !important;
      }
      ${MQ_DESKTOP} {
        transform: translate3d(
          ${pcPx(100)},
          ${pcPx(500)},
          ${pcPx(200)}
        ) !important;
      }
    }
  }
  .swiper-slide-next {
    ${MQ_MOBILE} {
      transform: translate3d(0, 0, 0);
    }
    ${MQ_DESKTOP} {
      transform: translate3d(${pcPx(0)}, ${pcPx(0)}, ${pcPx(0)});
    }
  }
  .swiper-slide-prev {
    opacity: 0;
    ${MQ_MOBILE} {
      transform: translate3d(${spp(0)}, ${spp(300)}, ${spp(100)}) !important;
    }
    ${MQ_DESKTOP} {
      transform: translate3d(${pcPx(0)}, ${pcPx(300)}, ${pcPx(100)}) !important;
    }
  }
  .swiper-slide-active {
    opacity: 1;
    ${MQ_MOBILE} {
      transform: translate3d(0, 0, 0);
    }
    ${MQ_DESKTOP} {
      transform: translate3d(0, 0, 0);
    }
  }
  .swiper-pagination {
    display: flex;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    justify-content: space-around;
    align-items: center;
    ${MQ_MOBILE} {
      width: ${spp(200)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(200)};
    }
  }
  .swiper-pagination-bullet {
    opacity: 1;
    background-color: rgba(49, 49, 49, 0.45);
    ${MQ_MOBILE} {
      width: ${spp(13)};
      height: ${spp(13)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(14)};
      height: ${pcPx(14)};
    }
  }
  .swiper-pagination-bullet-active {
    background-color: ${BLACK};
    ${MQ_MOBILE} {
      width: ${spp(16)};
      height: ${spp(16)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(20)};
      height: ${pcPx(20)};
    }
  }
`;

const Title = styled.h3`
  ${fadeInStyle}
  ${jpStyle}
  color: ${BLACK};
  font-weight: bold;
  position: relative;
  text-align: center;
  overflow: visible;
  ${MQ_MOBILE} {
    font-size: ${spp(26)};
    line-height: ${spp(45)};
    margin-top: ${spp(70)};
    margin-bottom: ${spp(35)};
    padding-bottom: ${spp(35)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(36)};
    line-height: ${pcPx(80)};
    margin-top: ${pcPx(100)};
    margin-bottom: ${pcPx(35)};
    padding-bottom: ${pcPx(30)};
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    background-color: ${BLACK};
    ${MQ_MOBILE} {
      width: ${spp(146)};
      height: ${spp(4)};
      bottom: ${spp(4)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(176)};
      height: ${pcPx(4)};
      bottom: ${pcPx(4)};
    }
  }
`;

const SlideTitle = styled.h3`
  ${jpStyle}
  color: ${BLACK};
  font-weight: bold;
  position: relative;
  text-align: center;
  overflow: visible;
  ${MQ_MOBILE} {
    font-size: ${spp(19)};
    line-height: ${spp(42)};
    margin-top: ${spp(60)};
    margin-bottom: ${spp(15)};
    padding-bottom: ${spp(25)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(36)};
    line-height: ${pcPx(80)};
    margin-top: ${pcPx(100)};
    margin-bottom: ${pcPx(35)};
    padding-bottom: ${pcPx(30)};
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    background-color: ${BLACK};
    ${MQ_MOBILE} {
      width: ${spp(103)};
      height: ${spp(4)};
      bottom: ${spp(4)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(176)};
      height: ${pcPx(4)};
      bottom: ${pcPx(4)};
    }
  }
`;

const Text = styled.p`
  ${jpStyle}
  ${fadeInStyle}
  color: ${BLACK};
  position: relative;
  text-align: center;
  overflow: visible;
  transition-delay: 0.1s;
  ${MQ_MOBILE} {
    font-size: ${spp(18)};
    line-height: ${spp(31)};
    margin-top: ${spp(10)};
    margin-bottom: ${spp(40)};
    padding: 0 ${spp(32.5)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(24)};
    line-height: ${pcPx(41)};
    margin-top: ${pcPx(5)};
    padding-bottom: ${pcPx(40)};
    margin-bottom: ${pcPx(0)};
  }
`;

const SwiperWrapper = styled.div`
  ${fadeInStyle}
`;

const ImgStyle = css`
  display: block;
  margin: auto;
  ${MQ_MOBILE} {
    width: ${spp(369)};
    height: ${spp(752)};
    background-size: contain;
    margin-top: ${spp(40)};
    margin-bottom: ${spp(40)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(1078)};
    height: ${pcPx(605)};
    background-size: contain;
    margin-bottom: ${pcPx(30)};
    filter: drop-shadow(0 ${pcPx(3)} ${pcPx(6)} rgba(0, 0, 0, 0.15));
  }
`;

const Img = styled.div`
  ${ImgStyle}
  ${MQ_MOBILE} {
    background-image: url(${ASSETS_OPEN});
    background-repeat: no-repeat;
  }
  ${MQ_DESKTOP} {
    background-image: url(${ASSETS_OPEN_PC});
    background-repeat: no-repeat;
  }
`;

const Img02 = styled.div`
  ${ImgStyle}
  ${MQ_MOBILE} {
    background-image: url(${ASSETS_GROUP});
    background-repeat: no-repeat;
  }
  ${MQ_DESKTOP} {
    background-image: url(${ASSETS_GROUP_PC});
    background-repeat: no-repeat;
  }
`;

const Img03 = styled.div`
  ${ImgStyle}
  ${MQ_MOBILE} {
    background-image: url(${ASSETS_MYU});
    background-repeat: no-repeat;
  }
  ${MQ_DESKTOP} {
    background-image: url(${ASSETS_MYU_PC});
    background-repeat: no-repeat;
  }
`;

const Img04 = styled.div`
  ${ImgStyle}
  ${MQ_MOBILE} {
    background-image: url(${ASSETS_MENU});
    background-repeat: no-repeat;
  }
  ${MQ_DESKTOP} {
    background-image: url(${ASSETS_MENU_PC});
    background-repeat: no-repeat;
  }
`;

const Img05 = styled.div`
  ${ImgStyle}
  ${MQ_MOBILE} {
    background-image: url(${ASSETS_PRESENT});
    background-repeat: no-repeat;
  }
  ${MQ_DESKTOP} {
    background-image: url(${ASSETS_PRESENT_PC});
    background-repeat: no-repeat;
  }
`;

const Img06 = styled.div`
  ${ImgStyle}
  ${MQ_MOBILE} {
    background-image: url(${ASSETS_TALK});
    background-repeat: no-repeat;
  }
  ${MQ_DESKTOP} {
    background-image: url(${ASSETS_TALK_PC});
    background-repeat: no-repeat;
  }
`;

const svgStyle = css`
  position: absolute;
  margin: auto;
  backface-visibility: hidden;
  overflow: hidden;
  display: inline-block;
  svg {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    overflow: hidden;
    transform: translateZ(0);
    line {
      stroke: ${GRAY};
      backface-visibility: hidden;
      overflow: hidden;
    }
  }
`;

const LineL01Style = css`
  ${svgStyle}
  ${onlySP}
  right: 0;
  ${MQ_MOBILE} {
    width: ${spp(271)};
    height: ${spp(238)};
    top: ${spp(90)};
    left: ${spp(600)};
  }
`;

const LineL01 = styled.div`
  ${LineL01Style}
`;

const LineL01Anim = styled.div`
  ${LineL01Style}
  svg {
    line {
      stroke: ${BLACK};
      stroke-width: 2;
      stroke-dashoffset: ${spp(1500)};
      stroke-dasharray: ${spp(1500)};
      animation: ${path} 2s linear forwards infinite;
    }
  }
`;

const LineLPc01Style = css`
  ${svgStyle}
  ${onlyPC}
  right: 0;
  ${MQ_DESKTOP} {
    width: ${pcPx(715)};
    height: ${pcPx(247)};
    top: ${pcPx(30)};
    left: ${pcPx(1750)};
  }
`;

const LineLPc01 = styled.div`
  ${LineLPc01Style}
`;

const LineLPc01Anim = styled.div`
  ${LineLPc01Style}
  svg {
    line {
      stroke: ${BLACK};
      stroke-width: 2;
      stroke-dashoffset: ${pcPx(1550)};
      stroke-dasharray: ${pcPx(1550)};
      animation: ${pathPc} 2s linear forwards infinite;
    }
  }
`;

const OpenChat = () => {
  SwiperCore.use([Pagination, Autoplay, Controller, EffectCoverflow]);
  const [windowWidth, setWindowWidth] = useState(0);
  const [id, setId] = useState(0);
  const [swiper, setSwiper] = useState<any>(null);
  const isSlideMove = useRef(false);
  const slideX = useRef(0);
  const isSpStoreValue = useIsSpStoreValue();
  const LENGTH = 6;
  const fvRef = useRef<HTMLDivElement | null>(null);
  const imgRef = useRef<HTMLDivElement | null>(null);
  const [isFvRefScrolled, setIsFvRefScrolled] = useState(false);
  const [isImgRefScrolled, setIsImgRefScrolled] = useState(false);
  const lineL01Ref = useRef<HTMLDivElement | null>(null);
  const lineL01PcRef = useRef<HTMLDivElement | null>(null);
  const scrollYStoreValue = useScrollYStoreValue();
  const languageStoreValue = useLanguageStoreValue();
  const { t } = useTranslation();

  const onTouchStart = (swiper: any) => {
    isSlideMove.current = true;
    slideX.current = swiper.touches.startX;
  };

  const onTouchEnd = (swiper: any) => {
    if (!isSlideMove.current) return;
    isSlideMove.current = false;
    if (slideX.current < swiper.touches.startX) {
      swiper.slidePrev();
    } else {
      swiper.slideNext();
    }
  };

  const getY = (
    ref: MutableRefObject<HTMLDivElement | null>,
    scrollY: number
  ) => {
    if (!ref || !ref.current) return 0;
    const y =
      (scrollY -
        (ref.current.getBoundingClientRect().top + window.pageYOffset)) *
      0.1;
    return y;
  };

  useEffect(() => {
    const onResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', onResize);

    onResize();
    return () => {
      window.addEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    const LOOP_LENGTH = LENGTH * 3;
    if (!swiper) return;
    const children = swiper.$wrapperEl[0].children;

    for (let i = 0; i < LOOP_LENGTH; i++) {
      children[i].style.opacity = 0;
      children[i].setAttribute('data-prev', false);
    }
    // swiper-prev 以前のスライド
    for (let i = 0; i < id - 1; i++) {
      children[i].setAttribute('data-prev', true);
    }
    children[id + 1].style.opacity = 0.42;
    children[id + 2].style.opacity = 0.42;
    children[id].style.opacity = 1;
  }, [id]);

  useEffect(() => {
    onScrollPromise(fvRef.current)().then(() => setIsFvRefScrolled(true));
    onScrollPromise(imgRef.current)().then(() => setIsImgRefScrolled(true));
    return () => {
      removeOnscrollEvent(fvRef.current);
      removeOnscrollEvent(imgRef.current);
    };
  }, []);

  return (
    <Wrapper>
      <Title
        ref={fvRef}
        data-visible={isFvRefScrolled}
        data-lang-ru={languageStoreValue === LANGUAGE.ru}
      >
        {t('openchat.title')}
      </Title>
      <Text
        data-visible={isFvRefScrolled}
        data-lang-ru={languageStoreValue === LANGUAGE.ru}
      >
        {t('openchat.text')}
      </Text>
      <SwiperWrapper ref={imgRef} data-visible={isImgRefScrolled}>
        <Swiper
          spaceBetween={isSpStoreValue ? windowWidth * -0.1 : 0}
          slidesPerView='auto'
          centeredSlides
          speed={1200}
          loop
          effect='coverflow'
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 500,
            modifier: 0.7,
            slideShadows: false,
          }}
          allowTouchMove={false}
          pagination={{ clickable: true }}
          onSwiper={setSwiper}
          onLoopFix={(swiper) => {
            setId(swiper.activeIndex);
          }}
          onSlideChange={(swiper) => {
            setId(swiper.activeIndex);
          }}
          onTouchStart={(swiper) => onTouchStart(swiper)}
          onTouchEnd={(swiper) => onTouchEnd(swiper)}
        >
          <SwiperSlide>
            <SlideTitle data-lang-ru={languageStoreValue === LANGUAGE.ru}>
              {t('openchat.slide01Title')}
            </SlideTitle>
            <Img />
            <Text data-lang-ru={languageStoreValue === LANGUAGE.ru}>
              {t('openchat.slide01Txet')}
            </Text>
          </SwiperSlide>
          <SwiperSlide>
            <SlideTitle data-lang-ru={languageStoreValue === LANGUAGE.ru}>
              {t('openchat.slide02Title')}
            </SlideTitle>
            <Img02 />
            <Text data-lang-ru={languageStoreValue === LANGUAGE.ru}>
              {t('openchat.slide02Text')}
            </Text>
          </SwiperSlide>
          <SwiperSlide>
            <SlideTitle data-lang-ru={languageStoreValue === LANGUAGE.ru}>
              {t('openchat.slide04Title')}
            </SlideTitle>
            <Img04 />
            <Text data-lang-ru={languageStoreValue === LANGUAGE.ru}>
              {t('openchat.slide04Text')}
            </Text>
          </SwiperSlide>
          <SwiperSlide>
            <SlideTitle data-lang-ru={languageStoreValue === LANGUAGE.ru}>
              {t('openchat.slide05Title')}
            </SlideTitle>
            <Img05 />
            <Text data-lang-ru={languageStoreValue === LANGUAGE.ru}>
              {t('openchat.slide05Text')}
            </Text>
          </SwiperSlide>
          <SwiperSlide>
            <SlideTitle data-lang-ru={languageStoreValue === LANGUAGE.ru}>
              {t('openchat.slide06Title')}
            </SlideTitle>
            <Img06 />
            <Text data-lang-ru={languageStoreValue === LANGUAGE.ru}>
              {t('openchat.slide06Text')}
            </Text>
          </SwiperSlide>
          <SwiperSlide>
            <SlideTitle data-lang-ru={languageStoreValue === LANGUAGE.ru}>
              {t('openchat.slide03Title')}
            </SlideTitle>
            <Img03 />
            <Text data-lang-ru={languageStoreValue === LANGUAGE.ru}>
              {t('openchat.slide03Text')}
            </Text>
          </SwiperSlide>
        </Swiper>
      </SwiperWrapper>
      <LineL01
        ref={lineL01Ref}
        style={{
          transform: `translate3d(0, ${px(
            getY(lineL01Ref, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='273.07'
          height='240.07'
          viewBox='0 0 273.07 240.07'
        >
          <line
            x2='271.751'
            y2='238.567'
            transform='translate(0.66 0.751)'
            fill='none'
            stroke='#000'
            strokeWidth='2'
            opacity='0.322'
          />
        </svg>
      </LineL01>
      <LineL01Anim
        style={{
          transform: `translate3d(0, ${px(
            getY(lineL01Ref, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='273.07'
          height='240.07'
          viewBox='0 0 273.07 240.07'
        >
          <line
            x2='271.751'
            y2='238.567'
            transform='translate(0.66 0.751)'
            fill='none'
            stroke='#000'
            strokeWidth='2'
            opacity='0.322'
          />
        </svg>
      </LineL01Anim>
      <LineLPc01
        ref={lineL01PcRef}
        style={{
          transform: `translate3d(0, ${px(
            getY(lineL01PcRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='607.319'
          height='533.503'
          viewBox='0 0 607.319 533.503'
        >
          <line
            x2='606'
            y2='532'
            transform='translate(0.66 0.751)'
            fill='none'
            stroke='#000'
            strokeWidth='2'
            opacity='0.322'
          />
        </svg>
      </LineLPc01>
      <LineLPc01Anim
        ref={lineL01PcRef}
        style={{
          transform: `translate3d(0, ${px(
            getY(lineL01PcRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='607.319'
          height='533.503'
          viewBox='0 0 607.319 533.503'
        >
          <line
            x2='606'
            y2='532'
            transform='translate(0.66 0.751)'
            fill='none'
            stroke='#000'
            strokeWidth='2'
          />
        </svg>
      </LineLPc01Anim>
    </Wrapper>
  );
};

export default OpenChat;
