import React from 'react';
import { useGlobal } from 'reactn';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { sp } from '../../../utils/mediaQuery';
import { useEditVirtualWorld } from '../hooks/useEditVirtualWorld';
import { VirtualWorldTypeInput } from './VirtualWorldTypeInput';
import { VirtualWorldImageInput } from './VirtualWorldImageInput';
import { useTranslation } from 'react-i18next';

const FormEditVirtualWorld = ({ roomId }) => {
  const [_, setIsShowEditVirtualWorldModal] = useGlobal(
    'isShowEditVirtualWorldModal'
  );
  const {
    roomDetail,
    handleChangeValue,
    handleSubmit,
    handleSelectType,
    handleSelectImage,
    type,
    isSubmitting,
    virtualWorldImageList,
    imageNum,
  } = useEditVirtualWorld(roomId);

  const isDisabled = !roomDetail.name || !imageNum || isSubmitting;
  const { t } = useTranslation();

  return (
    <FormEditVirtualWorldWrapper>
      <ModalCloseIcon
        onClick={() => {
          setIsShowEditVirtualWorldModal(false);
        }}
        src={RailsAssetPath('close-img-gray.png')}
      />
      <VirtualWorldSettingTitleArea>
        <p>{t('RoomTextVirtualSetting', {})}</p>
      </VirtualWorldSettingTitleArea>
      <form onSubmit={(e) => handleSubmit(e)}>
        <VirtualWorldTypeInput
          type={type}
          handleSelectType={handleSelectType}
        />
        <VirtualWorldImageInput
          imageNum={imageNum}
          imageList={virtualWorldImageList}
          handleSelectImage={handleSelectImage}
        />
        <VirtualWorldNameInput
          defaultValue={roomDetail.name !== 'null' ? roomDetail.name : ''}
          type='text'
          name='name'
          placeholder={t('RoomTextVirtualName', {})}
          onChange={handleChangeValue}
        />
        <VirtualWorldDescriptionInput
          rows={3}
          defaultValue={roomDetail.note !== 'null' ? roomDetail.note : ''}
          type='text'
          placeholder={t('GenelalTextDescription', {})}
          name='note'
          onChange={handleChangeValue}
        />
        <VirtualWorldIsOpenGuestLabel htmlFor='is_open_guest'>
          <VirtualWorldIsOpenGuestInput
            id='is_open_guest'
            checked={roomDetail.is_open_guest === true ? true: false}
            defaultChecked={false}
            type='checkbox'
            name='is_open_guest'
            onChange={handleChangeValue}
          />
          <p>castに公開</p>
        </VirtualWorldIsOpenGuestLabel>
        <StyledSubmitButtonWrapper>
          <FormSubmitButton
            type='submit'
            disabled={isDisabled}
            colorType={type}
          >
            {t('GenelalButtonSave', {})}
          </FormSubmitButton>
        </StyledSubmitButtonWrapper>
      </form>
    </FormEditVirtualWorldWrapper>
  );
};

const FormEditVirtualWorldWrapper = styled.div`
  margin: auto;
  text-align: center;
  padding: 30px;
  font-size: 11px;
  color: #303030;
  height: 75vh;
  ${sp`
    position: relative;
    overflow: auto;
    padding: 30px 30px;
  `}
`;

const ModalCloseIcon = styled.img`
  position: absolute;
  top: 27px;
  font-size: 25px;
  cursor: pointer;
  width: 19px;
  ${sp`
    top: 15px;
    left: 19px;
    width: 15px;
  `}
`;

const VirtualWorldSettingTitleArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  p {
    font-size: 16px;
  }
`;

const mixinTextInput = css`
  height: 48px;
  width: 100%;
  background: #eaeaea;
  font-size: 16px;
  border-radius: 8px;
  line-height: 37px;
  padding: 0 20px;
`;

const VirtualWorldNameInput = styled.input`
  ${mixinTextInput}
  margin-top: 24px;
`;

const VirtualWorldDescriptionInput = styled.textarea`
  font-size: 16px;
  height: 120px;
  margin-top: 24px;
  padding: 16px 20px;
  width: 100%;
  background: #eaeaea;
  border-radius: 8px;
  resize: none;
  ${sp`
    font-size: 14px;
    height: 110px;
  `}
`;

const VirtualWorldIsOpenGuestLabel = styled.label`
  display: flex;
  align-items: center;
  margin-top: 16px!important;
  font-size: 12px!important;
  padding-left: 16px;
  margin-top: -8px;
  cursor: pointer;
  input {
    width: auto;
    margin-right: 8px;
    cursor: pointer;
  }
`;

const VirtualWorldIsOpenGuestInput = styled.input`
  width: 100%;
  padding: 10px 16px;
  border: 1px solid #707070;
  border-radius: 8px;
  outline: none;
  &:focus-visible {
    border: 1px solid #00a0e9';
    box-shadow: 0 0 4px #00a0e9';
  }
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px #fff inset;
  }
`;

const FormSubmitButton = styled.button`
  background: ${({ disabled, colorType }) =>
    disabled
      ? '#b6b6b6'
      : colorType === 'video'
      ? '#ff8086'
      : colorType === 'voice'
      ? '#4fd24b'
      : '#5387f4'};
  color: white;
  font-size: 12px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  padding: 8px 24px;
  width: 160px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  ${({ disabled }) =>
    !disabled &&
    `
    &:hover {
      transform: translate(-1px, -1px);
      box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 5%), 4px 4px 4px 0px rgb(0 0 0 / 5%);
    }
  `}
  ${sp`
    margin-bottom: 0;
  `}
`;

const StyledSubmitButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 24px 0;
  background-color: #fff;
`;

const mapStateToProps = (state) => {
  return {
    roomId: state.setRooms.id,
  };
};

export default connect(mapStateToProps)(FormEditVirtualWorld);
