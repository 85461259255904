import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useToggleOpenStatus } from 'javascript/features/thanks/hooks/useToggleOpenStatus';
import { device } from 'javascript/utils/mediaQuery';

const StyledToggleButtonWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 0;
  display: flex;
  p {
    margin-top: 4px;
    margin-right: 16px;
    font-size: 12px;
    &:nth-child(2) {
      margin-right: 4px;
    }
    &:nth-child(3) {
      margin-right: 0;
      margin-left: 4px;
    }
  }
  @media ${device.sp} {
    position: static;
    margin-top: 16px;
  }
`;

const StyledToggleButton = styled.div`
  position: relative;
  width: 52px;
  height: 40px;
  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    opacity: 0;
    cursor: pointer;
    &:checked + label {
      background-color: #ccc;
    }
    &:checked + label::after {
      left: 24px;
    }
  }
  label {
    width: 48px;
    height: 24px;
    background: #00a0e9;
    position: relative;
    display: inline-block;
    border-radius: 46px;
    transition: 0.4s;
    box-sizing: border-box;
    &::after {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      left: 0;
      top: 0;
      z-index: 2;
      background: #fff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      transition: 0.4s;
    }
  }
`;

interface Props {
  officeName: string;
  pageNumber: number;
  isOpen?: boolean;
}

export const ToggleButton: React.FC<Props> = ({
  officeName,
  pageNumber,
  isOpen,
}) => {
  const [isOpenLocal, setIsOpenLocal] = useState(isOpen);
  const { t } = useTranslation();
  const { toggleOpenStatus } = useToggleOpenStatus(
    officeName,
    pageNumber,
    isOpen
  );

  return (
    <StyledToggleButtonWrapper>
      <p>{t('ThanksPageToggleopenbutton', {})}</p>
      <p>{t('GenelalButtonOn', {})}</p>
      <StyledToggleButton>
        <input
          type='checkbox'
          id='toggle'
          checked={!isOpenLocal}
          onChange={() => {
            setIsOpenLocal(!isOpenLocal);
            toggleOpenStatus();
          }}
        />
        <label htmlFor='toggle' />
      </StyledToggleButton>
      <p>{t('GenelalButtonOff', {})}</p>
    </StyledToggleButtonWrapper>
  );
};
