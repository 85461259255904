import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useGlobal } from 'reactn';
import { sp } from 'javascript/utils/mediaQuery';
import { DisplayAvatarArea } from 'javascript/features/freeSpace/components/DisplayAvatarArea';
import { useInteractWorldAvatars } from 'javascript/features/freeSpace/hooks/useInteractWorldAvatars';
import { useInteractWorld } from 'javascript/features/freeSpace/hooks/useInteractWorld';
import { UserAvatar } from 'types';
import { OpenChatInputArea } from 'javascript/features/freeSpace/components/OpenChatInputArea';

interface Props {
  avatar?: UserAvatar;
  currentUser: any;
}

const World: React.FC<Props> = ({ avatar , currentUser}) => {
  const [ interactWorldId, setInteractWorldId ] = useGlobal<{ interactWorldId: number | undefined }>('interactWorldId');
  const { avatars, handleSetLocation } = useInteractWorldAvatars(avatar?.id);
  const isCurrentAvatarInFreeSpace = avatars.some(
    (a) => a.avatar_id === avatar?.id
  );
  const currentAvatar = currentUser.avatar;
  const userImage = currentUser.image;
  const { handleJoinChannel , handleLeaveChannel} = useInteractWorld();

  useEffect(() => {
    if(!interactWorldId) {
      handleJoinChannel();
    }
    window.addEventListener('beforeunload', () => {
      setInteractWorldId(undefined);
      handleLeaveChannel(interactWorldId);
    });
  },[interactWorldId])

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      handleLeaveChannel(interactWorldId);
    });
    return () => {
      setInteractWorldId(undefined);
      handleLeaveChannel(interactWorldId);
    };
  }, [])

  return (
    <WorldWrapper>
      {/* @ts-ignore */}
      <StyledVirtualWorldWrapper 
          // @ts-ignore
          worldTheme={RailsAssetPath('interact_world_background.png')}
        >
        <DisplayAvatarArea
          avatars={avatars}
          currentAvatar={avatar}
          handleSetLocation={handleSetLocation}
          isCurrentAvatarInFreeSpace={isCurrentAvatarInFreeSpace}
          isWorld={true}
          interactWorldId={interactWorldId}
          isCast={false}
        />
        <SubRoomsWrapper>
          <OpenChatInputArea
            isCurrentAvatarInFreeSpace={isCurrentAvatarInFreeSpace}
            avatar={currentAvatar}
            userImage={userImage}
          />
        </SubRoomsWrapper>
      </StyledVirtualWorldWrapper>
    </WorldWrapper>
  );

};

const WorldWrapper = styled.div`
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  height: calc(100vh - 111px);
  overflow: scroll;
  ${sp`
    width: auto;
    height: calc(100vh - 44px);
    margin-top: 33px;
    padding: 0;
    overflow: auto;
  `}}
  
`;
const StyledVirtualWorldWrapper = styled.div<{ worldTheme: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 6px 12px 0 8px;
  height: calc(100% - 16px);
  color: #000;
  background-image: url(${({ worldTheme }) => worldTheme});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.2);
  background-blend-mode: lighten;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  height: 100vh;
  ${sp`
    padding:0;
  `}
`;

const SubRoomsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  transform: translateY(-70%);
  ${sp`
    transform: translateY(0%);
  `}
`;

export default World;
