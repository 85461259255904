import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { device } from 'javascript/utils/mediaQuery';
import { OwnedSkillProductInMybox } from 'types';
import { useSkillProductApplyUse } from '../hooks/useSkillProductApplyUse';

const StyledModalTitle = styled.p`
  margin-top: 48px;
  font-size: 18px;
  @media ${device.sp} {
    margin-top: 24px;
    font-size: 14px;
  }
`;

const StyledDefaultModalImageWrapper = styled.div`
  margin-top: 32px;
  img {
    max-width: 104px;
    max-height: 104px;
  }
`;

const StyleModalProductDescriptionWrapper = styled.div`
  width: 80%;
  overflow-wrap: break-word;
  margin: 30px 0 0 0;
`;

const StyleModalProductProductName = styled.div`
  margin-bottom: 15px;
  text-align: center;
`;

const StyleModalProductDescription = styled.div`
  margin-bottom: 15px;
`;

const StyleModalProductDescriptionOwnerName = styled.div`
  text-align: center;
`;

const StyledButtonAreaWrapper = styled.div<{ isAbleToPresent: boolean }>`
  display: flex;
  justify-content: ${({ isAbleToPresent }) =>
    isAbleToPresent ? 'space-between' : 'center'};
  width: 100%;
  margin-top: 28px;
  @media ${device.sp} {
    flex-direction: column;
    align-items: center;
    height: 104px;
    margin-top: 40px;
  }
`;

const StyledButton = styled.button<{ danger?: boolean }>`
  width: 156px;
  padding: 8px 16px;
  color: ${({ danger }) => (danger ? '#ec6a40' : '#03a9f4')};
  border: 2px solid ${({ danger }) => (danger ? '#ec6a40' : '#03a9f4')};
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translate(-1px, -1px);
    box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 5%), 4px 4px 4px 0px rgb(0 0 0 / 5%);
  }
`;
const StyledUsedButton = styled.button`
  width: 156px;
  padding: 8px 16px;
  color: #dfdfdf;
  border: 2px solid #dfdfdf;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
`;

const StyledDescription = styled.p`
  white-space: pre-wrap;
`;

interface Props {
  product: OwnedSkillProductInMybox;
  handleSelectPresent: () => void;
  toggleModal: () => void;
  avatarOwnedSkillProductSort: string | null;
}

export const SkillUseModalBody: React.FC<Props> = ({
  product,
  handleSelectPresent,
  toggleModal,
  avatarOwnedSkillProductSort
}) => {
  const { t } = useTranslation();
  const isAbleToPresent = !product.present_flg && !(product.usage_status !== 1);
  const { handleApplyUseSkillProduct } = useSkillProductApplyUse(product, toggleModal, avatarOwnedSkillProductSort);

  return (
    <>
      <StyledModalTitle>{t('MyboxUseskillAskuse', {})}</StyledModalTitle>
      <StyledDefaultModalImageWrapper>
        <img
          src={
            product.image_url !== ''
              ? product.image_url
              : // @ts-ignore
              RailsAssetPath(`avatar_shop/skill_default.png`)
          }
        />
      </StyledDefaultModalImageWrapper>
      <StyleModalProductDescriptionWrapper>
        <StyleModalProductProductName>
          {product.product_name}
        </StyleModalProductProductName>

        <StyleModalProductDescription>
          {product.description && (
            <StyledDescription>{product.description}</StyledDescription>
          )}
        </StyleModalProductDescription>
        <StyleModalProductDescriptionOwnerName>
          {product.username}
        </StyleModalProductDescriptionOwnerName>
      </StyleModalProductDescriptionWrapper>
      <StyledButtonAreaWrapper isAbleToPresent={isAbleToPresent}>
        {/* プレゼントされたものは招待できないようにする */}
        {(isAbleToPresent && !(product.usage_status !== 1)) && (
          <StyledButton onClick={handleSelectPresent}>
            {t('MyboxDefaultmodalPresent', {})}
          </StyledButton>
        )}
        {product.usage_status !== 1 ? (
          <StyledUsedButton>
            {t('ModalUseSkillUseRequestText', {})}
          </StyledUsedButton>
        ) : (
          <StyledButton danger onClick={handleApplyUseSkillProduct}>
            {t('MyboxDefaultmodalUse', {})}
          </StyledButton>
        )}
      </StyledButtonAreaWrapper>
    </>
  );
};
