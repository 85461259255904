import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { BarItem } from 'javascript/features/stayHistory/components/BarItem';
import { ScaleValues } from 'javascript/features/stayHistory/components/ScaleValues';
import { useEachStayHistory } from 'javascript/features/stayHistory/hooks/useEachStayHistory';
import { device } from 'javascript/utils/mediaQuery';
import { StayHistoryOfMonth } from 'types';

const StyledBarOfMonth = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;

const StyledMonthDataWrapper = styled.div`
  display: flex;
`;

const StyledLabel = css`
  width: 104px;
  padding-right: 16px;
  border-right: 1px solid #dedede;
  text-align: right;
  @media ${device.sp} {
    width: 40px;
    padding-right: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StyledMonthLabel = styled.div`
  padding-top: 24px;
  ${StyledLabel}
`;

const StyledSleepLabel = styled.div`
  padding-top: 24px;
  ${StyledLabel}
`;

const StyledOvertimeLabel = styled.div<{ isLast: boolean }>`
  padding-top: 16px;
  padding-bottom: ${({ isLast }) => isLast && '16px'};
  ${StyledLabel}
`;

const StyledBarWrapper = styled.div<{ overtime?: boolean; isLast?: boolean }>`
  display: flex;
  position: relative;
  height: 24px;
  width: calc(100% - 104px);
  padding-top: ${({ overtime }) => (overtime ? '12px' : '20px')};
  padding-bottom: ${({ overtime, isLast }) => overtime && isLast && '16px'};
  box-sizing: content-box;
  @media ${device.sp} {
    width: calc(100% - 40px);
  }
`;

const StyledScaleLabel = styled.p<{ percent: number }>`
  position: absolute;
  top: -24px;
  left: ${({ percent }) => `${percent}%`};
  transform: translateX(-50%);
`;

interface Props {
  historyPerMonth: [string, StayHistoryOfMonth];
  isLast: boolean;
  isFirst: boolean;
  maxScale: number | undefined;
}

export const BarOfMonth: React.FC<Props> = ({
  historyPerMonth,
  isLast,
  isFirst,
  maxScale = 0,
}) => {
  const { t } = useTranslation();
  const {
    month,
    freeSpaceMinutes,
    sleepMinutes,
    overWorkMinutes,
    normalRoom,
    voiceRoom,
    videoRoom,
    directCalls,
  } = useEachStayHistory(historyPerMonth);

  // 1hあたりの目盛り最大値中の割合を算出。
  // 例：労働1時間は、maxScale(目盛り最大値)が50hのとき、100 / 50で全体の2%になる。
  const PERCENT_PER_HOUR = 100 / maxScale;
  const ONE_HOUR = 60;
  // BarItemのPropsのitemWidthは、渡された時間がグラフの何％に相当するかを計算し、その割合（％）を渡す
  console.log("maxScale="+maxScale);
  console.log("maxScale="+PERCENT_PER_HOUR);
  
  return (
    <StyledBarOfMonth>
      <StyledMonthDataWrapper>
        <StyledMonthLabel>{month}</StyledMonthLabel>
        <StyledBarWrapper>
          <BarItem
            barColor='#d0f25f'
            itemWidth={(freeSpaceMinutes / ONE_HOUR) * PERCENT_PER_HOUR}
            roomName={t('ProfileTextFreespace', {})}
            stayMinutes={freeSpaceMinutes}
          />
          {normalRoom.map((room) => (
            <BarItem
              key={room.room_id}
              barColor='#5387f4'
              itemWidth={(room.stay_minutes / ONE_HOUR) * PERCENT_PER_HOUR}
              roomName={room.name}
              stayMinutes={room.stay_minutes}
            />
          ))}
          {voiceRoom.map((room) => (
            <BarItem
              key={room.room_id}
              barColor='#4fd24b'
              itemWidth={(room.stay_minutes / ONE_HOUR) * PERCENT_PER_HOUR}
              roomName={room.name}
              stayMinutes={room.stay_minutes}
            />
          ))}
          {videoRoom.map((room) => (
            <BarItem
              key={room.room_id}
              barColor='#ff8086'
              itemWidth={(room.stay_minutes / ONE_HOUR) * PERCENT_PER_HOUR}
              roomName={room.name}
              stayMinutes={room.stay_minutes}
            />
          ))}
          {/* 目盛りとなるborderの位置は本来、先祖コンポーネントのStyledBarsContainer内部で配置したかったが、
          均等な幅とならない（原因不明）ため、やむをえずバーごとに設置。残業も同様 */}
          <ScaleValues />
          {isFirst && (
            <StyledScaleLabel percent={20}>{maxScale * 0.2}h</StyledScaleLabel>
          )}
          {isFirst && (
            <StyledScaleLabel percent={40}>{maxScale * 0.4}h</StyledScaleLabel>
          )}
          {isFirst && (
            <StyledScaleLabel percent={60}>{maxScale * 0.6}h</StyledScaleLabel>
          )}
          {isFirst && (
            <StyledScaleLabel percent={80}>{maxScale * 0.8}h</StyledScaleLabel>
          )}
          {isFirst && (
            <StyledScaleLabel percent={100}>{maxScale}h</StyledScaleLabel>
          )}
        </StyledBarWrapper>
      </StyledMonthDataWrapper>
      <StyledMonthDataWrapper>
        <StyledSleepLabel>{t('ProfileTextDirectcall', {})}</StyledSleepLabel>
        <StyledBarWrapper>
          {directCalls.map((room) => (
            <BarItem
              key={room.room_id}
              barColor='#9b95c9'
              itemWidth={
                // 個別通話の分数が少ないと、グラフに表示されなくなる場合があるため、0でない限りは0.2%は表示させるようにする
                (room.stay_minutes / ONE_HOUR) * PERCENT_PER_HOUR < 0.2
                  ? 0.2
                  : (room.stay_minutes / ONE_HOUR) * PERCENT_PER_HOUR
              }
              roomName={room.name}
              stayMinutes={room.stay_minutes}
            />
          ))}
          <ScaleValues />
        </StyledBarWrapper>
      </StyledMonthDataWrapper>
      <StyledMonthDataWrapper>
        <StyledSleepLabel>{t('OfficeStatusSleep', {})}</StyledSleepLabel>
        <StyledBarWrapper>
          <BarItem
            barColor='#8ce3f8'
            itemWidth={(sleepMinutes / ONE_HOUR) * PERCENT_PER_HOUR}
            roomName={t('OfficeStatusSleep', {})}
            stayMinutes={sleepMinutes}
          />
          <ScaleValues />
        </StyledBarWrapper>
      </StyledMonthDataWrapper>
      <StyledMonthDataWrapper>
        <StyledOvertimeLabel isLast={isLast}>
          {t('ProfileTextOvertime', {})}
        </StyledOvertimeLabel>
        <StyledBarWrapper overtime isLast={isLast}>
          <BarItem
            barColor='#ff4343'
            itemWidth={(overWorkMinutes / ONE_HOUR) * PERCENT_PER_HOUR}
            roomName={t('ProfileTextOvertime', {})}
            stayMinutes={overWorkMinutes}
          />
          <ScaleValues />
        </StyledBarWrapper>
      </StyledMonthDataWrapper>
    </StyledBarOfMonth>
  );
};
