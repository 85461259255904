import useSWR from 'swr';
import axios from 'axios';
import { StayHistory, StayHistoryOfMonth } from 'types';

type StayHistoryWithTotalMinutes = StayHistoryOfMonth & {
  totalMinutes: {
    free: number;
    overtime_work: number;
    sleep: number;
    virtuals: number;
    directCalls: number;
    monthly_stay_minutes: number;
  };
};

export const useStayHistory = (
  userId: number,
  historyYear: number | undefined
) => {
  const fetcher = (url: string) =>
    axios.get<StayHistory>(url).then(({ data }) => data);

  const { data } = useSWR(
    `/api/v1/users/stay_time/${userId}?year=${historyYear}`,
    fetcher
  );

  if (!data) return { stayHistory: undefined };

  const stayHistoryWithUserId = Object.entries(data);

  const [, ...stayHistory] = stayHistoryWithUserId;

  // 1年分の滞在履歴グラフ表示時の目盛りのMAX値を決定するため、
  // 月毎のそれぞれの部屋タイプの総滞在時間を算出
  const stayHistoryWithTotalMinutes: [string, StayHistoryWithTotalMinutes][] =
    stayHistory.map((hist) => {
      const totalVirtualMinutes = hist[1].virtuals.reduce(
        (prev, current) => prev + current.stay_minutes,
        0
      );
      const totalDirectCallMinutes = hist[1].direct_calls.reduce(
        (prev, current) => prev + current.stay_minutes,
        0
      );
      const { free, overtime_work, sleep, monthly_stay_minutes } = hist[1];
      return [
        hist[0],
        {
          ...hist[1],
          totalMinutes: {
            free: free.stay_minutes,
            overtime_work: overtime_work.stay_minutes,
            sleep: sleep.stay_minutes,
            virtuals: totalVirtualMinutes,
            directCalls: totalDirectCallMinutes,
            monthly_stay_minutes: monthly_stay_minutes,
          },
        },
      ];
    });

  // 1年分の滞在履歴グラフ表示時の目盛りのMAX値を決定するため、
  // 年間（12ヶ月全体）での最大値を算出。分単位で算出されるので、60で割り時間単位に変換
  const maxHoursInAYear =
  Math.ceil(  
    Math.max(
      ...stayHistoryWithTotalMinutes.map((hist) =>
        Math.max(
          hist[1].totalMinutes.free,
          hist[1].totalMinutes.overtime_work,
          hist[1].totalMinutes.sleep,
          hist[1].totalMinutes.virtuals,
          hist[1].totalMinutes.directCalls,
          hist[1].totalMinutes.monthly_stay_minutes
        )
      )
    ) / 60);

  // maxHoursInAYearから最も近い50の倍数(MAX値は50h単位になるようにする)を算出
//  const maxScale = Math.ceil((maxHoursInAYear + 50) / 50) * 50;
  const maxScale = (Math.ceil((maxHoursInAYear) / 50) * 50);

  return { stayHistory, maxScale };
};
