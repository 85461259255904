import React from 'react';
import { useGlobal } from 'reactn';
import styled from 'styled-components';
import { SHOW_FEED_TYPE } from '../../utils/util';
import { sp } from '../../utils/mediaQuery';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useGetOfficeData } from 'javascript/features/realWorld/hooks/useGetOfficeData';

const DiaryButtonsWrapper = styled.div`
  position: absolute;
  top: 130px;
  left: 60px;
  ${sp`
    position: static;
    display: flex;
    width: 100%;
    margin: 15px 20px 10px;
    justify-content: space-evenly;
  `}
`;

const DiaryButtonWrapper = styled.div`
  margin-bottom: 30px;
  cursor: pointer;
  display: flex;
  position: relative;
  ${sp`
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  `}
`;

const DiaryButton = styled.img`
  width: 40px;
  height: 40px;
  margin: 0;
  ${sp`
    width: 25px;
    height: 25px;
  `}
`;

const DiaryButtonText = styled.div`
  padding: 5px 15px;
  ${sp`
    font-size: 10px;
  `}
`;

const SurveyNewBadge = styled.div`
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  left: -14px;
  position: absolute;
  top: -5px;
  ${sp`
    left: 4px;
    top: -5px;
  `}
`;

const ReportButton = () => {
  const [feedShowType, setFeedShowType] = useGlobal('feedShowType');
  const { t } = useTranslation();

  return (
    <>
      <DiaryButtonWrapper
        onClick={() => {
          setFeedShowType(SHOW_FEED_TYPE.REPORT);
        }}
      >
        <DiaryButton
          src={
            feedShowType === SHOW_FEED_TYPE.REPORT
              ? RailsAssetPath('icon-diary-blue.png')
              : RailsAssetPath('icon-diary.png')
          }
          alt='icon-diary'
        />
        <DiaryButtonText
          style={{
            color:
              feedShowType === SHOW_FEED_TYPE.REPORT ? '#00a1e9' : '#676767',
          }}
        >
          {t('DiaryCategoryReport', {})}
        </DiaryButtonText>
      </DiaryButtonWrapper>
    </>
  );
};

const TodoButton = () => {
  const [feedShowType, setFeedShowType] = useGlobal('feedShowType');
  const { t } = useTranslation();

  return (
    <>
      <DiaryButtonWrapper
        onClick={() => {
          setFeedShowType(SHOW_FEED_TYPE.TODO);
        }}
      >
        <DiaryButton
          src={
            feedShowType === SHOW_FEED_TYPE.TODO
              ? RailsAssetPath('icon-todo-blue.png')
              : RailsAssetPath('icon-todo.png')
          }
          alt='icon-todo'
        />
        <DiaryButtonText
          style={{
            color: feedShowType === SHOW_FEED_TYPE.TODO ? '#00a1e9' : '#676767',
          }}
        >
          {t('DiaryCategoryTodo', {})}
        </DiaryButtonText>
      </DiaryButtonWrapper>
    </>
  );
};

const ForumButton = () => {
  const [feedShowType, setFeedShowType] = useGlobal('feedShowType');
  const { t } = useTranslation();

  return (
    <>
      <DiaryButtonWrapper
        onClick={() => {
          setFeedShowType(SHOW_FEED_TYPE.FORUM);
        }}
      >
        <DiaryButton
          src={
            feedShowType === SHOW_FEED_TYPE.FORUM
              ? RailsAssetPath('icon-forum-blue.png')
              : RailsAssetPath('icon-forum.png')
          }
          alt='icon-forum'
        />
        <DiaryButtonText
          style={{
            color:
              feedShowType === SHOW_FEED_TYPE.FORUM ? '#00a1e9' : '#676767',
          }}
        >
          {t('DiaryCategoryForum', {})}
        </DiaryButtonText>
      </DiaryButtonWrapper>
    </>
  );
};

const SurveyButton = () => {
  const [feedShowType, setFeedShowType] = useGlobal('feedShowType');
  const { t } = useTranslation();
  const { officeData } = useGetOfficeData();

  return (
    <>
      <DiaryButtonWrapper
        onClick={() => {
          setFeedShowType(SHOW_FEED_TYPE.SURVEY);
        }}
      >
        <DiaryButton
          src={
            feedShowType === SHOW_FEED_TYPE.SURVEY
              ? RailsAssetPath('icon-survey-blue.png')
              : RailsAssetPath('icon-survey.png')
          }
          alt='icon-survey'
        />
        {typeof officeData !== 'undefined' ? 
          ( officeData.is_survey ? <SurveyNewBadge></SurveyNewBadge> : null )
          :
          null
        }
        <DiaryButtonText
          style={{
            color:
              feedShowType === SHOW_FEED_TYPE.SURVEY ? '#00a1e9' : '#676767',
          }}
        >
          {t('DiaryCategorySurvey', {})}
        </DiaryButtonText>
      </DiaryButtonWrapper>
    </>
  );
};

const ThanksLink = ({ officeName }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <a href={`/thanks/${officeName}`}>
      <DiaryButtonWrapper>
        <DiaryButton src={RailsAssetPath('icon-thanks.png')} alt='icon-forum' />
        <DiaryButtonText>{t('DiaryCategoryThanks', {})}</DiaryButtonText>
      </DiaryButtonWrapper>
    </a>
  );
};

const DiaryButtons = ({ officeName, isUnauthorizedUser }) => {
  return (
    <>
      <DiaryButtonsWrapper>
        <ReportButton />
        <TodoButton />
        <ForumButton />
        <ThanksLink officeName={officeName} />
        <SurveyButton />
      </DiaryButtonsWrapper>
    </>
  );
};

export default DiaryButtons;
