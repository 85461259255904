import React, { createContext } from 'react';
import useRoom from './hooks/useRoom';
import { useEffect } from 'reactn';
import { useSelector } from 'react-redux';
import useInvitedUser from './direct/hooks/useInvitedUser';
import useRemainingPoint from "./hooks/useRemainingPoint";
import useLeaveCall from './direct/hooks/useLeavelCall';
import { useVoice } from 'javascript/components/voice/hooks/useVoice';
import useInvitedDirectCallInfo from './hooks/useInvitedDirectCallInfo';

export const VoiceContext = createContext();

const VoiceChatProvider = ({ children }) => {
  const { room, isConnecting, connect } = useRoom();
  const { invitedUsers, setInvitedUsers } = useInvitedUser();
  const { leave, isDisconnecting, setIsDisconnecting } = useLeaveCall();
  const [remainingPoint, setRemainingPoint] = useRemainingPoint();
  const { inviteInfo, setInviteInfo } = useInvitedDirectCallInfo();

  useEffect(() => {
    if (room) {
      room.disconnect();
    }
  }, []);

  return (
    <VoiceContext.Provider
      value={{
        room,
        isConnecting,
        connect,
        invitedUsers,
        setInvitedUsers,
        leave,
        isDisconnecting,
        setIsDisconnecting,
        remainingPoint,
        setRemainingPoint,
        inviteInfo,
        setInviteInfo
      }}
    >
      {children}
    </VoiceContext.Provider>
  );
};

export default VoiceChatProvider;
