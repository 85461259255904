import React from 'react';
import styled from 'styled-components';
import {
  MQ_DESKTOP,
  MQ_MOBILE,
} from 'javascript/components/landingPage/lib/MQ';
import {
  pcPx,
  percent,
  spp,
} from 'javascript/components/landingPage/lib/cssUtil';
import {
  BLACK,
  jpStyle,
} from 'javascript/components/landingPage/local/commonCss';
import {
  LANGUAGE,
  useLanguageStoreValue,
} from 'javascript/components/landingPage/store/language';
import { t } from 'i18next';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
`;

const PcWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  ${MQ_MOBILE} {
    margin-bottom: ${spp(70)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(1800 - 80 * 2)};
    margin: auto;
    margin-bottom: ${pcPx(30)};
  }
  p {
    ${MQ_MOBILE} {
      &:nth-of-type(1) {
        width: ${spp(700)};
      }
    }
  }
`;

const Text = styled.p`
  ${jpStyle}
  color: ${BLACK};
  position: relative;
  text-align: center;
  overflow: visible;
  font-weight: bold;
  ${MQ_MOBILE} {
    width: ${spp(500)};
    font-size: ${spp(15 * 2)};
    line-height: ${spp(26 * 2)};
    transform: scale(0.5);
    transform-origin: bottom;
    margin-left: ${percent(-23)};
    margin-right: ${percent(-23)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(24)};
    line-height: ${pcPx(41)};
    margin-top: ${pcPx(5)};
    padding-bottom: ${pcPx(40)};
    margin-bottom: ${pcPx(0)};
    margin-left: ${pcPx(100)};
    margin-right: ${pcPx(100)};
  }
`;

const Footer = () => {
  const languageStoreValue = useLanguageStoreValue();

  return (
    <Wrapper>
      <PcWrapper>
        <Text data-lang-ru={languageStoreValue === LANGUAGE.ru}>
          <a href='/privacy' target='_blank'>
            {t('footer.privacy')}
          </a>
        </Text>
        <Text data-lang-ru={languageStoreValue === LANGUAGE.ru}>
          <a
            href={
              languageStoreValue === 'ja'
                ? 'https://docs.google.com/forms/d/1FfyHPuo1VzzgqIS-DvX_17DXf5W_e4JvtJSI1ZOFdGg/edit?usp=sharing'
                : 'https://bit.ly/3hwF1D9'
            }
            target='_blank'
          >
            {t('footer.contact')}
          </a>
        </Text>
        <Text data-lang-ru={languageStoreValue === LANGUAGE.ru}>
          <a href='https://bit.ly/3GKLBSw' target='_blank'>
            {t('header.download')}
          </a>
        </Text>
        <Text data-lang-ru={languageStoreValue === LANGUAGE.ru}>
          <a href='https://pla-cole.co/' target='_blank'>
            {t('footer.company')}
          </a>
        </Text>
      </PcWrapper>
    </Wrapper>
  );
};

export default Footer;
