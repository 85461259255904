import React, { useCallback, useState } from 'react';
import { LocalVideoTrack } from 'twilio-video';
import useIsTrackEnabled from './useIsTrackEnabled';
import useVideoCallContext from './useVideoCallContext'

const useLocalVideoToggle = () => {

  const { room, localTracks, getLocalVideoTrack, removeLocalVideoTrack } = useVideoCallContext();
  const localParticipant = room?.localParticipant;
  const videoTrack = localTracks.find(
    track => !track.name.includes('screen') && track.kind === 'video'
  ) 
  const [isPublishing, setIspublishing] = useState();

  const toggleVideoEnabled = useCallback(() => {
    if (!isPublishing) {
      if (videoTrack) {
        const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
        localParticipant?.emit('trackUnpublished', localTrackPublication);
        removeLocalVideoTrack();
      } else {
        setIspublishing(true);
        getLocalVideoTrack()
          .then((track) => localParticipant?.publishTrack(track, { priority: 'low' }))
          .finally(() => {
            setIspublishing(false);
          });
      }
    }
  }, [videoTrack, localParticipant, getLocalVideoTrack, isPublishing, removeLocalVideoTrack]);
  const toggleVideoDisabled = useCallback(() => {
    if (videoTrack) {
      const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
      localParticipant?.emit('trackUnpublished', localTrackPublication);
      removeLocalVideoTrack();
    }
  }, [videoTrack, localParticipant, getLocalVideoTrack, isPublishing, removeLocalVideoTrack]);

  return [!!videoTrack, toggleVideoEnabled, toggleVideoDisabled];
}

export default useLocalVideoToggle;