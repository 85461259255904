import React, { useState } from 'react';
import axios from 'axios';
import { useGlobal } from 'reactn';
import store from '../../lib/redux/store';
import { fetchUserData } from '../../lib/redux/actions/user';
import Clock from '../items/Clock';
import styled from 'styled-components';
import { sp } from '../../utils/mediaQuery';
import { useTranslation } from 'react-i18next';

const FormWorkStart = ({ user, realList }) => {
  const [isShowModal, setIsShowModal] = useGlobal('isShowModal');
  const [remainingTime, setRemainingTime] = useGlobal('remainingTime');
  const [todoImage, setTodoImage] = useState([]);
  const [previewUrlList, setPreviewUrlList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ interactWorldId ] = useGlobal('interactWorldId');
  const { t } = useTranslation();

  const defaultLocation = {
    id: 0,
    name: t("WorkStartTextSelect",{}),
  };
  const realListWidhDefault = [defaultLocation, ...realList];

  const [a, setA] = useState(user.previous_attendance.finish_hours || 0);
  const [b, setB] = useState(user.previous_attendance.finish_minutes || 0);
  const [c, setC] = useState(user.previous_attendance.break_hours || 0);
  const [d, setD] = useState(user.previous_attendance.break_minutes || 0);
  const [breakTime, setBreakTime] = useState(
    user.previous_attendance.break_time || 60
  );
  const [h, setH] = useState('');
  const [location, setLocation] = useState(defaultLocation);
  const [j, setJ] = useState('');
  const [commentValidationMessage, setCommentValidationMessage] = useState('');

  const defaultFinishHours = user.previous_attendance.finish_hours || 12;
  const defaultFinishMinutes = user.previous_attendance.finish_minutes || 0;
  const defaultBreakHours = user.previous_attendance.break_hours || 12;
  const defaultBreakMinutes = user.previous_attendance.break_minutes || 0;
  const defaultDetail = '';
  const defaultImage = [];
  const defaultComment = '';

  let hours = [];
  let workMinutes = [0, 15, 30, 45];
  let breakMinutes = [0, 15, 30, 45, 60];

  for (var i = 12; i <= 23; i++) {
    hours.push(i);
  }

  const hoursOptions = hours.map((h, key) => (
    <option value={h} key={key}>
      {h}
    </option>
  ));

  const workMinutesOptions = workMinutes.map((m, key) => (
    <option value={m} key={key}>
      {m}
    </option>
  ));

  const breakMinutesOptions = breakMinutes.map((m, key) => (
    <option value={m} key={key}>
      {m}
    </option>
  ));

  const locationOptions = realListWidhDefault.map(({ id, name }) => (
    <option value={name} key={id}>
      {name}
    </option>
  ));

  const updateUserInfo = () => {
    store.dispatch(fetchUserData());
  };

  const onChangeUpload = (e) => {
    if (e.target.files[0]) {
      defaultImage.push([e.target.files[0]]);
      todoImage.push(e.target.files[0]); //これを送信している

      var reader = new FileReader();

      reader.onload = function (e) {
        setPreviewUrlList([...previewUrlList, { image: e.target.result }]);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleChangeA = (e) => {
    setA(e.target.value);
  };

  const handleChangeB = (e) => {
    setB(e.target.value);
  };

  const handleChangeC = (e) => {
    setC(e.target.value);
  };

  const handleChangeD = (e) => {
    setD(e.target.value);
  };

  const handleChangeF = (e) => {
    setBreakTime(e.target.value);
  };

  const handleChangeH = (e) => {
    setH(e.target.value);
  };

  const handleChangeJ = (e) => {
    setJ(e.target.value);

    if (e.target.value.length <= 20) {
      setCommentValidationMessage('');
    } else {
      setCommentValidationMessage(t("WorkStartCommentValidation",{}));
    }
  };

  const handleChangeLocation = (e) =>
    setLocation(
      realListWidhDefault.find((real) => real.name === e.target.value)
    );

  const deleteImage = (index) => {
    let newUrl = [];
    let num = index;
    let arr = previewUrlList;
    arr.splice(num, 1);
    todoImage.splice(num, 1);
    for (let i = 0; i < arr.length; i++) {
      newUrl.push({ image: arr[i].image });
    }
    setPreviewUrlList(newUrl);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (location.id === 0) {
      alert(t("WorkStartAlertTodayLocation",{}));
      return;
    }
    setLoading(false);
    const url = '/api/v1/users/me/attendances/begin';
    const params = {
      finish_hours: a || defaultFinishHours,
      finish_minutes: b || defaultFinishMinutes,
      break_hours: c || defaultBreakHours,
      break_minutes: d || defaultBreakMinutes,
      break_time: breakTime,
      title: 'title',
      detail: h || defaultDetail,
      images: todoImage,
      comment: j || defaultComment,
      room_id: location.id,
      interact_world_id: interactWorldId,
    };

    const formData = new FormData();
    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v, i) => {
          formData.append(key + '[]', v); // arrayデータを分割して入れ直す
        });
      } else {
        formData.append(key, value);
      }
    });
    axios
      .post(url, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((res) => {
        updateUserInfo();
        setRemainingTime(res.data.remaining_times);
        setIsShowModal(false);
      })
      .catch((error) => {
        setLoading(true);
        const msg = error.response.data.message;
        if (msg) {
          alert(msg);
        } else {
          alert(t("WorkStartAlertFaildStartWork",{}));
        }
      });
  };

  return (
    <div className='modal__background'>
      <ModalWrapper>
        <p id='DateTimeDisp' className='modal__hour'>
          <Clock />
        </p>
        <form onSubmit={handleSubmit}>
          <div className='label-wrapper'>
            <label
              htmlFor='lesttime'
              className='finish-rest-time__text'
              onClick={deleteImage}
            >
              <span className='start-rest-time__icon--cup' />
              {t("WorkScheduleTextBreakTime",{})}
            </label>
          </div>
          <div className='inputbox__time'>
            <div className='select-box-time'>
              <select
                className='inputbox'
                name='break_hours'
                value={c}
                onChange={handleChangeC}
              >
                {hoursOptions}
              </select>
              <span></span>
              <span className='option-btn'>
                <img src='/assets/triangle.png' alt='' />
              </span>
            </div>
            <span className='text-wave'>：</span>
            <div className='select-box-time'>
              <select
                className='inputbox'
                name='break_minutes'
                value={d}
                onChange={handleChangeD}
              >
                {workMinutesOptions}
              </select>
              <span></span>
              <span className='option-btn'>
                <img src='/assets/triangle.png' alt='' />
              </span>
            </div>
            <span className='text-wave'>〜</span>
            <div className='select-box-time'>
              <select
                className='inputbox'
                name='break_time'
                value={breakTime}
                onChange={handleChangeF}
              >
                {breakMinutesOptions}
              </select>
              <span className='text-blue'>m</span>
              <span className='option-btn'>
                <img src='/assets/triangle.png' alt='' />
              </span>
            </div>
          </div>
          <div className='label-wrapper'>
            <label htmlFor='finishtime' className='finish-rest-time__text'>
              <span className='start-rest-time__icon--door' />
              {t("WorkScheduleTextLeaveTime",{})}
            </label>
          </div>
          <div className='inputbox__time'>
            <div className='select-box-time'>
              <select
                className='inputbox'
                value={a}
                name='finish_hours'
                onChange={handleChangeA}
              >
                {hoursOptions}
              </select>
              <span></span>
              <span className='option-btn'>
                <img src='/assets/triangle.png' alt='' />
              </span>
            </div>
            <span className='text-wave'>：</span>
            <div className='select-box-time'>
              <select
                className='inputbox'
                name='finish_minutes'
                value={b}
                onChange={handleChangeB}
              >
                {workMinutesOptions}
              </select>
              <span></span>
              <span className='option-btn'>
                <img src='/assets/triangle.png' alt='' />
              </span>
            </div>
          </div>
          <div className='label-wrapper'>
            <label className='finish-rest-time__text'>
              <span className='start-location__icon--location' />
              {t("WorkStartTextTodayLocation",{})}
            </label>
          </div>
          <div className='inputbox__location'>
            {location ? (
              <div className='select-box-time'>
                <select
                  className='inputbox--location'
                  value={location.name}
                  name='room_id'
                  onChange={handleChangeLocation}
                >
                  {locationOptions}
                </select>
                <span></span>
                <span className='option-btn'>
                  <img src='/assets/triangle.png' alt='' />
                </span>
              </div>
            ) : (
              <p className='location--alert'>
                {t("WorkStartAlertNoRealWorld",{})}
              </p>
            )}
          </div>
          <div>
            <input 
              className='textarea padding'
              style={{ width: '100%', color: 'black', marginBottom: '14px' }}
              type='text' 
              name='comment' 
              onChange={handleChangeJ}
              value={j}
              placeholder={t("WorkStartCommentPlaceholder",{})}
              maxLength='20'
            />
            {commentValidationMessage && (
              <div style={{ color: 'red', textAlign: 'start', letterSpacing: '1px' }}>{commentValidationMessage}</div>
            )}
          </div>
          <div>
            <textarea
              className='textarea padding'
              style={{ width: '100%', color: 'black', height: '120px' }}
              name='detail'
              onChange={handleChangeH}
              rows='10'
              value={h}
              placeholder='Comment'
            ></textarea>
            <div className='' id='preview'>
              {previewUrlList.map((i, key) => (
                <div key={key} className='preview-wrapper'>
                  <div className='relative'>
                    <div
                      className='preview-button'
                      onClick={() => deleteImage(key)}
                    >
                      ×
                    </div>
                    <img className='preview-img' src={i.image} alt='preview' />
                  </div>
                </div>
              ))}
            </div>
            <div style={{ width: '50px' }}>
              <label className='input-file-label' htmlFor='todofileUpload'>
                <input
                  id='todofileUpload'
                  accept='image/*'
                  className='inputImage'
                  name='images'
                  type='file'
                  onChange={onChangeUpload}
                />
              </label>
            </div>
          </div>
          {loading && location && (
            <div style={{marginTop: "12px"}}>
              <button
                className='startwork btn'
                type='submit'
                onClick={handleSubmit}
              ></button>
            </div>
          )}
        </form>
      </ModalWrapper>
    </div>
  );
};

const ModalWrapper = styled.div`
  overflow-y: scroll;
  height: 570px;
  margin: auto;
  text-align: center;
  color: #535353;
  letter-spacing: 0.2em;
  font-size: 12px;
  ${sp`
    height: auto;
    overflow: visible;
  `}
`;

export default FormWorkStart;
