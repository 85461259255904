import React, { useState, useEffect, useCallback, useGlobal } from 'reactn';
import styled from 'styled-components';
import axios from 'axios';
import {
  Typography,
  Divider,
  Dialog,
  DialogContent,
  Button,
  IconButton,
  Grid,
  Link,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@material-ui/core';
import { useStyles } from './hooks/useStyles';
import { useMediaQuery } from 'react-responsive';
import usePaymentContext from './hooks/usePaymentContext';
import DialogCloseButton from './buttons/DialogCloseButton';
import ProgressBackdrop from './ProgressBackdrop';
import { SUBSCRIPTION_CANCEL_OTHER_REASON_ID } from './constants';
import { useTranslation } from 'react-i18next';

const SubscriptionCancelDialog = ({ open, onClose }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 430 });
  const classes = useStyles();

  const { paymentConfig, fetchConfig } = usePaymentContext();
  const [cancelComplete, setCancelComplete] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [inProgres, setInProgress] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [cancelReasonText, setCancelReasonText] = useState('');
  const [reasonError, setReasonError] = useState(false);
  const [planPt, setPlanPt] = useState(0);
  const [basePrice, setBasePrice] = useState(0);

  useEffect(() => {
    if (paymentConfig?.available_plan) {
      setSubscriptionId(paymentConfig.available_plan.id);
      setPlanPt(paymentConfig.plan_pt);
    }
    setBasePrice(paymentConfig?.charge_amount);
    setQuestions(paymentConfig?.plan_cancel_reasons);
  }, [paymentConfig]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      checkedItems[SUBSCRIPTION_CANCEL_OTHER_REASON_ID] == true &&
      cancelReasonText.length == 0
    ) {
      setReasonError(true);
      return;
    }
    setInProgress(true);
    const data = Object.entries(checkedItems).reduce((pre, [key, value]) => {
      value && pre.push(key);
      return pre;
    }, []);

    let params = {
      data: {
        answers: data,
        reason_text: cancelReasonText,
      },
    };
    let { error } = await axios
      .delete(`/api/v1/billing/subscriptions/${subscriptionId}`, params)
      .then((res) => {
        return res.data;
      });
    if (error) {
      alert(error.messsge);
    } else {
      setCancelComplete(true);
    }
    setInProgress(false);
  };
  const handleChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.value]: event.target.checked,
    });
  };
  const handleChangeCancelReasontext = (event) => {
    setCancelReasonText(event.target.value);
    if (event.target.value.length > 0) {
      setReasonError(false);
    }
  };
  return (
    <>
      <Dialog
        maxWidth={isMobile ? 'sm' : 'xs'}
        fullWidth={true}
        open={open}
        onClose={onClose}
      >
        <DialogCloseButton onClose={onClose} />
        <DialogContent>
          <ProgressBackdrop open={inProgres}></ProgressBackdrop>
          <ContentWrapper>
            {cancelComplete ? (
              <Grid
                container
                direction='column'
                spacing={4}
                alignItems='center'
              >
                <Grid item>
                  <Typography variant='subtitle1'>
                    {t('PaymentPlanCancelled', {})}
                  </Typography>
                </Grid>
                <Grid container item justify='center' spacing={2}>
                  <Typography variant='body1'>
                    {t('PaymentPlanThanks', {})}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    className={`${classes.baseButton} ${classes.submitButton}`}
                    onClick={onClose}
                  >
                    {t('PaymentPlanClose', {})}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                direction='column'
                spacing={4}
                alignItems='center'
              >
                <Grid item>
                  <Typography variant='subtitle1'>
                    {t('PaymentPlanCancelconfirm', {})}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  direction='row'
                  alignItems='center'
                  justify='center'
                >
                  <Grid item>
                    <Typography variant='h5'>{planPt}</Typography>
                  </Grid>
                  <Divider orientation='vertical' variant='middle' flexItem />
                  <Grid item>
                    ¥{basePrice}/{t('PaymentTextMonth', {})}
                  </Grid>
                </Grid>

                <Grid item>
                  <Typography variant='subtitle2'>
                    {t('PaymentPlanCancelreason', {})}
                  </Typography>
                </Grid>

                <Grid container item justify='center'>
                  <Grid
                    container
                    item
                    direction='column'
                    justify='flex-start'
                    sm={10}
                    xs={12}
                  >
                    {questions?.map((item, index) => (
                      <FormControlLabel
                        key={index}
                        value=''
                        control={
                          <Checkbox
                            type='checkbox'
                            color='primary'
                            name='question'
                            value={item[0]}
                            checked={checkedItems[item[0]] || false}
                            onChange={handleChange}
                          />
                        }
                        label={item[1]}
                      />
                    ))}
                    {checkedItems[SUBSCRIPTION_CANCEL_OTHER_REASON_ID] ==
                      true && (
                      <TextField
                        required
                        error={reasonError}
                        multiline
                        rows={4}
                        label={t('PaymentPlanLabel', {})}
                        value={cancelReasonText}
                        onChange={handleChangeCancelReasontext}
                        variant='outlined'
                      />
                    )}
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction='column'
                  justify='center'
                  alignItems='center'
                  spacing={4}
                >
                  <Grid item>
                    <Button
                      className={`${classes.baseButton} ${classes.submitButton}`}
                      onClick={handleSubmit}
                    >
                      {t('PaymentPlanContinuecancel', {})}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </ContentWrapper>
        </DialogContent>
      </Dialog>
    </>
  );
};

const ContentWrapper = styled.div`
  margin: 20px;
`;
export default SubscriptionCancelDialog;
