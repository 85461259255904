import { useCallback } from 'react';
import Push from 'push.js';
import { SecretChatMessage } from 'types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const useCreateNotification = (
  currentAvatarId: number,
  messageList: SecretChatMessage[]
) => {
  // @ts-ignore
  const currentUser = useSelector((state) => state.setUser.currentUser);

  const history = useHistory();
  const sound = new Audio('/assets/freeSpace/receive_secret_chat.mp3');
  const requestNotification = () => {
    if (!Push.Permission.has()) {
      Push.Permission.request();
    }
  };
  const latestMessage = messageList[messageList.length - 1];
  const { t } = useTranslation();

  const createNotification = useCallback(() => {
    if (
      messageList.length !== 0 &&
      latestMessage.avatar_id !== currentAvatarId
    ) {
      requestNotification();
      Push.create(
        t('OpenChatMessageGotAMessage', { user: latestMessage.avatar_name }),
        {
          timeout: 1000 * 60,
          icon: '/favicon.ico',
          onClick: () => {
            window.focus();
            !location.pathname.includes('office') && history.push('/office');
          },
        }
      );
      currentUser.speaker && sound.play();
    }
    return;
  }, [currentAvatarId, messageList.length, currentUser.speaker]);

  return { createNotification };
};
