import React from 'react';
import styled from 'styled-components';
import { device } from 'javascript/utils/mediaQuery';
import { Team, Position, Role, Timezone, WorkingTime } from 'types';

interface Props {
  data: Team[] | Position[] | Role[] | Timezone[] | WorkingTime[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  defaultValue: string | number | readonly string[] | undefined;
  name?: string;
  label?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export const DefaultSelect: React.FC<Props> = ({
  defaultValue,
  data,
  onChange,
  name,
  label,
  disabled,
  style,
}) => {
  const optionNode = data.map((o, key) => (
    <option value={o.id || undefined} key={key}>
      {o.name}
    </option>
  ));

  return (
    <StyledDefaultSelect>
      <select
        defaultValue={defaultValue}
        onChange={onChange}
        name={name}
        disabled={disabled}
        style={style}
      >
        {!defaultValue && label && <option value=''>{label}</option>}
        {optionNode}
      </select>
    </StyledDefaultSelect>
  );
};

const StyledDefaultSelect = styled.div`
  position: relative;

  select {
    text-align-last: center;
    background: #ffffff00;
    padding: 0px 16px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  &::before {
    content: '';
    width: 12px;
    height: 12px;
    border: 6px solid transparent;
    border-top: 8px solid #6f6f6f;
    position: absolute;
    top: 15px;
    right: 8px;
  }

  @media ${device.sp} {
    select {
      text-align-last: left;
    }
  }
`;
