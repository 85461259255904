import { useCallback } from 'react';
import { useGlobal } from 'reactn';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import { useSWRConfig } from 'swr';
import { OwnedProductInMybox, OwnedSkillProductInMybox } from 'types';
import { useSelector } from 'react-redux';
import { useIsTypeProductItem } from 'javascript/features/mybox/util/useIsTypeProductItem';

interface Params {
  product: OwnedProductInMybox | OwnedSkillProductInMybox;
  targetAvatarId: number | undefined;
  avatarName?: string | undefined;
  currentAvatarId?: number;
  toggleModal?: () => void;
}

export const usePresentProduct = ({
  product,
  targetAvatarId,
  avatarName,
  currentAvatarId,
  toggleModal,
}: Params) => {
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();
  const isMobile = useMediaQuery({ maxWidth: 430 });
  const [interactWorldId] =
    useGlobal<{ interactWorldId: number | undefined }>('interactWorldId');

  const { isTypeProductItem } = useIsTypeProductItem();

  // @ts-ignore
  const currentUser = useSelector((state) => state.setUser.currentUser);

  const sound = new Audio('/assets/coin.mp3');
  sound.volume = 0.3;

  const params = {
    avatar_owned_product_id: isTypeProductItem(product)
      ? product.avatar_owned_product_id[0]
      : product.avatar_owned_product_id,
    avatar_original_product_id: null,
    to_avatar_id: targetAvatarId,
    interact_world_id: interactWorldId,
  };

  const handlePresentProduct = useCallback(async () => {
    if (!targetAvatarId) {
      alert(t('MyboxPresentmodalSelecttarget', {}));
      return;
    }

    if (targetAvatarId === currentAvatarId) {
      alert(t('MyboxPresentmodalNottoown', {}));
      return;
    }

    const isConfirmed =
      product.viket >= 500
        ? confirm(
            avatarName
              ? t('MyboxPresentmodalConfirmwhotobegivenpresent', {
                  name: avatarName,
                })
              : t('MyboxPresentmodalConfirmtoremovefromdressroom', {})
          )
        : confirm(
            avatarName
              ? t('MyboxPresentmodalConfirmtopresenttoperson', {
                  name: avatarName,
                })
              : t('MyboxPresentmodalConfirmtopresent', {})
          );

    if (isConfirmed) {
      const { data } = await axios.post<{ message: string; status: number }>(
        '/api/v1/avatar/product/give',
        params
      );
      !isMobile && currentUser.speaker && sound.play();
      mutate('/api/v1/avatar/owned_products');
      alert(data.message);
      toggleModal && toggleModal();
    }
  }, [
    params,
    product.viket,
    targetAvatarId,
    currentAvatarId,
    avatarName,
    currentUser.speaker,
  ]);

  return { handlePresentProduct };
};
