import { useCallback, useState } from 'react';
import { AvatarPaidProductType } from 'types';

export const useSelectedShopProductType = (
  setFilterType?: (filterNum: number) => void,
  setSortType?: (sortNum: number) => void,
  sortNum?: number
) => {
  const [selectedShopProductType, setselectedShopProductType] =
    useState<AvatarPaidProductType>('flower');

  const selectShopProductType = useCallback(
    (partsType: AvatarPaidProductType) => {
      setselectedShopProductType(partsType);
      setFilterType && setFilterType(1);
      sortNum === 5 && setSortType && setSortType(1);
    },
    [setselectedShopProductType, sortNum, setSortType]
  );

  return {
    selectedShopProductType,
    selectShopProductType,
  };
};
