import React from 'react';
import InviteCallModalPortal from './InviteCallModalPortal';
import { SecretChatTemplate } from 'javascript/features/secretChat/components';

const InviteCallModal = ({isWorld}) => {
  return (
    <InviteCallModalPortal>
      <SecretChatTemplate isWorld={isWorld}/>
    </InviteCallModalPortal>
  );
};

export default InviteCallModal;
