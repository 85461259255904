import React from 'react';
import styled from 'styled-components';
import Observer from 'javascript/components/common/Observer';
import { useViketHistory } from 'javascript/features/userProfile/hooks/useViketHistory';
import { device } from 'javascript/utils/mediaQuery';
import { useTranslation } from 'react-i18next';

interface Props {
  avatarId: number;
}

export const ViketHistory: React.FC<Props> = React.memo(({ avatarId }) => {
  const { viketHistory, target, handleonIntersection } =
    useViketHistory(avatarId);
  const { t } = useTranslation();

  return (
    <>
      <StyledViketHistory id='historyTarget'>
        <StyledLabel>{t("ViketTextHistory",{})}</StyledLabel>
        <StyledViketHistoryList>
          {viketHistory.length !== 0 ? (
            viketHistory.map((item, i) => (
              <StyledViketHistoryItem key={`${i}_${item.created_at}`}>
                <StyledDate>{item.created_at}</StyledDate>
                <StyledViketIconWrapper>
                  {/* @ts-ignore */}
                  <img src={RailsAssetPath('mypage/coin.png')} />
                </StyledViketIconWrapper>
                <StyledViketCount>
                  {item.viket > 0 ? `+${item.viket}` : `${item.viket}`}
                </StyledViketCount>
              </StyledViketHistoryItem>
            ))
          ) : (
            <p>{t("ViketTextNoHistory",{})}</p>
          )}
        </StyledViketHistoryList>
        <div ref={target}></div>
      </StyledViketHistory>
      <Observer
        root={document.getElementById('historyTarget')}
        el={target.current}
        onIntersection={handleonIntersection}
      />
    </>
  );
});

const StyledViketHistory = styled.div`
  margin-top: 30px;
`;

const StyledLabel = styled.div`
  padding: 0 0 16px 24px;
  border-bottom: 2px solid #ddd;
  font-weight: bold;
  @media ${device.sp} {
    padding-left: 16px;
  }
`;

const StyledViketHistoryList = styled.div`
  max-height: 100px;
  margin-top: 32px;
  padding: 0 24px;
  overflow: scroll;
`;

const StyledViketHistoryItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const StyledDate = styled.p`
  font-weight: 300;
`;

const StyledViketIconWrapper = styled.div`
  margin-left: 120px;
  img {
    width: 16px;
  }
`;

const StyledViketCount = styled.p`
  margin-left: 16px;
  font-size: 14px;
`;
