import { useCallback, useState } from 'react';
import moment from 'moment';

export const useChagenHistoryYear = () => {
  const currentYear = moment().year();
  const selectableYears: number[] = [];

  // 選択できる年で2021年以降のものを追加
  for (let i = currentYear; i >= 2021; i--) {
    selectableYears.unshift(i);
  }

  const [historyYear, setHistoryYear] = useState(selectableYears.at(-1));

  const currentIndex = selectableYears.findIndex(
    (year) => year === historyYear
  );

  const chagenHistoryYear = useCallback(
    (backOrForward: number) => {
      setHistoryYear(selectableYears[currentIndex + backOrForward]);
    },
    [historyYear, currentIndex]
  );

  const isLatestYear = currentIndex === selectableYears.length - 1;
  const isEarliestYear = currentIndex === 0;

  return { historyYear, chagenHistoryYear, isLatestYear, isEarliestYear };
};
