import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  MQ_DESKTOP,
  MQ_MOBILE,
} from 'javascript/components/landingPage/lib/MQ';
import { pcPx, px, spp } from 'javascript/components/landingPage/lib/cssUtil';
import {
  BLACK,
  buttonAnim,
  fadeInStyle,
  GRAY,
  jpStyle,
  onlyPC,
  onlySP,
} from 'javascript/components/landingPage/local/commonCss';
import {
  onScrollPromise,
  removeOnscrollEvent,
} from 'javascript/components/landingPage/local/scrollPromise';
import { useScrollYStoreValue } from 'javascript/components/landingPage/store/scrollYStore';
import {
  LANGUAGE,
  useLanguageStoreValue,
} from 'javascript/components/landingPage/store/language';
import { renderHTML } from 'javascript/utils/util';
// @ts-ignore
const ASSETS_APP_DL = RailsAssetPath('lp/intro/app_dl.svg');
// @ts-ignore
const ASSETS_TEXT_JA = RailsAssetPath('lp/intro/text_ja.svg');
// @ts-ignore
const ASSETS_TEXT_EN = RailsAssetPath('lp/intro/text_en.svg');
// @ts-ignore
const ASSETS_TEXT_DE = RailsAssetPath('lp/intro/text_de.svg');
// @ts-ignore
const ASSETS_TEXT_ES = RailsAssetPath('lp/intro/text_es.svg');
// @ts-ignore
const ASSETS_TEXT_KO = RailsAssetPath('lp/intro/text_ko.svg');
// @ts-ignore
const ASSETS_TEXT_FR = RailsAssetPath('lp/intro/text_fr.svg');
// @ts-ignore
const ASSETS_TEXT_IT = RailsAssetPath('lp/intro/text_it.svg');
// @ts-ignore
const ASSETS_TEXT_PO = RailsAssetPath('lp/intro/text_po.svg');
// @ts-ignore
const ASSETS_TEXT_RU = RailsAssetPath('lp/intro/text_ru.svg');
// @ts-ignore
const ASSETS_TEXT_CH_CN = RailsAssetPath('lp/intro/text_ch_cn.svg');
// @ts-ignore
const ASSETS_TEXT_CH_TW = RailsAssetPath('lp/intro/text_ch_tw.svg');
// @ts-ignore
const ASSETS_BG = RailsAssetPath('lp/intro/bg.png');
// @ts-ignore
const ASSETS_BG_PC = RailsAssetPath('lp/intro/bg_pc.png');
// @ts-ignore
const ASSETS_IMG = RailsAssetPath('lp/intro/img.png');
// @ts-ignore
const ASSETS_MOVIE = RailsAssetPath('lp/movie/viket_movie.mp4');
// @ts-ignore
const ASSETS_AWARD = RailsAssetPath('lp/intro/award_logo.png');
// @ts-ignore
const ASSETS_AWARD_IT = RailsAssetPath('lp/intro/award_it.png');

const path = keyframes({
  from: { strokeDashoffset: spp(-1500) },
  to: { strokeDashoffset: spp(1500) },
});

const pathPc = keyframes({
  from: { strokeDashoffset: pcPx(-1550) },
  to: { strokeDashoffset: pcPx(1550) },
});

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
  overflow: hidden;
  background-color: white;
  backface-visibility: hidden;
  ${MQ_MOBILE} {
  }
  ${MQ_DESKTOP} {
  }
`;

const BGWrapper = styled.div`
  position: absolute;
  background: url(${ASSETS_IMG}) no-repeat;
  background-size: contain;
  left: 0;
  margin: auto;
  ${MQ_MOBILE} {
    width: ${spp(878)};
    height: ${spp(830)};
    top: ${spp(-60)};
    right: ${spp(-900)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(878)};
    height: ${pcPx(830)};
    top: ${pcPx(30)};
    right: ${pcPx(-2000)};
  }
`;

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  ${MQ_DESKTOP} {
    flex-direction: row;
    width: ${pcPx(1636)};
    margin: auto;
    margin-top: ${pcPx(115)};
  }
`;

const TextWrapper = styled.div`
  ${MQ_MOBILE} {
    margin-top: 5vw;
  }
  ${[MQ_DESKTOP]} {
    text-align: left;
    margin-left: ${pcPx(100)};
  }
`;

const Video = styled.video`
  ${fadeInStyle}
  ${MQ_MOBILE} {
    display: none;
  }
  ${MQ_DESKTOP} {
    display: block;
    width: ${pcPx(1024)};
    height: ${pcPx(606)};
  }
`;

const Text = styled.p`
  ${jpStyle}
  ${fadeInStyle}
  color: ${BLACK};
  transition-delay: 0.1s;
  ${MQ_MOBILE} {
    text-align: center;
    font-size: ${spp(24)};
    line-height: ${spp(41)};
    margin-bottom: ${spp(5)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(24)};
    line-height: ${pcPx(41)};
    margin-bottom: ${pcPx(5)};
  }
`;

const Bold = styled.p`
  ${jpStyle}
  ${fadeInStyle}
  color: ${BLACK};
  font-weight: 700;
  transition-delay: 0.2s;
  ${MQ_MOBILE} {
    text-align: center;
    font-size: ${spp(40)};
    line-height: ${spp(68)};
    margin-top: ${spp(5)};
    margin-bottom: 6vw;
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(40)};
    line-height: ${pcPx(68)};
    margin-top: ${pcPx(5)};
    margin-bottom: ${pcPx(45)};
  }
`;

const LinkWrapper = styled.div`
  ${fadeInStyle}
  transition-delay: 0.3s;
`;

const Link = styled.a`
  ${jpStyle}
  ${buttonAnim}
  color: ${BLACK};
  border: solid 1px ${BLACK};
  font-weight: 600;
  text-align: center;
  background-color: transparent;
  ${MQ_MOBILE} {
    font-size: ${spp(25)};
    line-height: ${spp(43)};
    border-radius: ${spp(10)};
    padding: ${spp(21)} ${spp(95)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(20)};
    line-height: ${pcPx(34)};
    border-radius: ${pcPx(10)};
    padding: ${pcPx(20)} ${pcPx(97)};
  }
`;

const AwardWrapper = styled.div`
  ${fadeInStyle}
  display: flex;
  align-items: center;
  column-gap: ${px(8)};
  margin-top: ${px(32)};
  transition-delay: 0.4s;
  img {
    width: ${px(100)};
  }
  &div:nth-child(2) {
    img {
      width: ${px(80)};
    }
  }
  ${MQ_MOBILE} {
    justify-content: center;
  }
  ${MQ_DESKTOP} {
    justify-content: flex-start;
    margin-left: ${px(-12)};
  }
`;

const TextDL = styled.p`
  ${fadeInStyle}
  ${jpStyle}
  color: ${BLACK};
  font-weight: 300;
  transition-delay: 0.4s;
  ${MQ_MOBILE} {
    text-align: center;
    font-size: ${spp(20)};
    line-height: ${spp(34)};
    margin-top: ${spp(30)};
    margin-bottom: 2vw;
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(20)};
    line-height: ${pcPx(34)};
    margin-top: ${pcPx(40)};
    margin-bottom: ${pcPx(20)};
  }
`;

const AppDl = styled.img`
  ${fadeInStyle}
  margin: auto;
  transition-delay: 0.5s;
  ${MQ_MOBILE} {
    height: ${spp(61)};
    margin-top: ${spp(5)};
    margin-bottom: ${spp(20)};
  }
  ${MQ_DESKTOP} {
    height: ${pcPx(61)};
    float: left;
  }
`;

const TextContentsWrapper = styled.div`
  ${fadeInStyle}
  width: 100%;
  ${MQ_MOBILE} {
    background: url(${ASSETS_BG}) no-repeat;
    background-size: contain;
    padding-top: ${spp(30)};
    margin-top: ${spp(20)};
  }
  ${MQ_DESKTOP} {
    background: url(${ASSETS_BG_PC}) no-repeat;
    background-size: cover;
    background-position: 0 ${pcPx(330)};
  }
`;

const IntroText = styled.p`
  ${jpStyle}
  color: ${BLACK};
  font-weight: 300;
  white-space: pre-wrap;
  ${MQ_MOBILE} {
    text-align: center;
    font-size: ${spp(20)};
    line-height: ${spp(30)};
    margin-top: ${spp(30)};
    margin-bottom: ${spp(130)};
    padding: 0 ${spp(32.5)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(30)};
    line-height: ${pcPx(50)};
    padding-top: ${pcPx(220)};
  }
  span:first-child {
    font-weight: bold;
    color: #000;
  }
  span {
    color: #006EFA;
    font-weight: bold;
    ${MQ_MOBILE} {
      font-size: 12px;
    }
    ${MQ_DESKTOP} {
      font-size: 17px;
    }
  }
`;

const ConceptText = styled.img`
  ${fadeInStyle}
  margin: auto;
  display: block;
  ${MQ_MOBILE} {
    width: ${spp(658)};
    margin-top: ${spp(5)};
    padding-bottom: ${spp(110)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(1185.89)};
    margin-top: ${pcPx(120)};
    margin-bottom: ${pcPx(20)};
    padding-bottom: ${pcPx(130)};
  }
`;

const svgStyle = css`
  position: absolute;
  margin: auto;
  backface-visibility: hidden;
  overflow: hidden;
  display: inline-block;
  svg {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    overflow: hidden;
    transform: translateZ(0);
    line {
      stroke: ${GRAY};
      backface-visibility: hidden;
      overflow: hidden;
    }
  }
`;

const LineL01Style = css`
  ${svgStyle}
  ${onlySP}
  right: 0;
  ${MQ_MOBILE} {
    width: ${spp(715)};
    height: ${spp(247)};
    top: ${spp(40)};
    left: ${spp(-1100)};
  }
`;

const LineL01 = styled.div`
  ${LineL01Style}
`;

const LineL01Anim = styled.div`
  ${LineL01Style}
  svg {
    line {
      stroke: ${BLACK};
      stroke-width: 2;
      stroke-dashoffset: ${spp(1500)};
      stroke-dasharray: ${spp(1500)};
      animation: ${path} 2s linear forwards infinite;
    }
  }
`;

const LineLPc01Style = css`
  ${svgStyle}
  ${onlyPC}
  right: 0;
  ${MQ_DESKTOP} {
    width: ${pcPx(715)};
    height: ${pcPx(247)};
    top: ${pcPx(-30)};
    left: ${pcPx(-1700)};
  }
`;

const LineLPc01 = styled.div`
  ${LineLPc01Style}
`;

const LineLPc01Anim = styled.div`
  ${LineLPc01Style}
  svg {
    line {
      stroke: ${BLACK};
      stroke-width: 2;
      stroke-dashoffset: ${pcPx(1550)};
      stroke-dasharray: ${pcPx(1550)};
      animation: ${pathPc} 2s linear forwards infinite;
    }
  }
`;

const LineRStyle = css`
  ${svgStyle}
  ${onlySP}
  left: 0;
  ${MQ_MOBILE} {
    width: ${spp(729)};
    height: ${spp(460)};
    top: ${spp(620)};
    right: ${spp(-1380)};
  }
`;

const LineR = styled.div`
  ${LineRStyle}
`;

const LineRAnim = styled.div`
  ${LineRStyle}
  svg {
    line {
      stroke: ${BLACK};
      stroke-width: 2;
      stroke-dashoffset: ${spp(1500)};
      stroke-dasharray: ${spp(1500)};
      animation: ${path} 2s linear forwards infinite;
    }
  }
`;

const LineRPcStyle = css`
  ${svgStyle}
  ${onlyPC}
  left: 0;
  ${MQ_DESKTOP} {
    width: ${pcPx(729)};
    height: ${pcPx(460)};
    top: ${pcPx(520)};
    right: ${pcPx(-1600)};
  }
`;

const LineRPc = styled.div`
  ${LineRPcStyle}
`;

const LineRPcAnim = styled.div`
  ${LineRPcStyle}
  svg {
    line {
      stroke: ${BLACK};
      stroke-width: 2;
      stroke-dashoffset: ${pcPx(1550)};
      stroke-dasharray: ${pcPx(1550)};
      animation: ${pathPc} 2s linear forwards infinite;
    }
  }
`;

const LineL02Style = css`
  ${svgStyle}
  ${onlySP}
  right: 0;
  ${MQ_MOBILE} {
    width: ${spp(348)};
    height: ${spp(217)};
    top: ${spp(1590)};
    left: ${spp(-800)};
  }
`;

const LineL02 = styled.div`
  ${LineL02Style}
`;

const LineL02Anim = styled.div`
  ${LineL02Style}
  svg {
    line {
      stroke: ${BLACK};
      stroke-width: 2;
      stroke-dashoffset: ${spp(1500)};
      stroke-dasharray: ${spp(1500)};
      animation: ${path} 2s linear forwards infinite;
    }
  }
`;

const LineLPc02Style = css`
  ${svgStyle}
  ${onlyPC}
  right: 0;
  ${MQ_DESKTOP} {
    width: ${pcPx(681)};
    height: ${pcPx(426)};
    top: ${pcPx(1700)};
    left: ${pcPx(-1730)};
  }
`;

const LineLPc02 = styled.div`
  ${LineLPc02Style}
`;

const LineLPc02Anim = styled.div`
  ${LineLPc02Style}
  svg {
    line {
      stroke: ${BLACK};
      stroke-width: 2;
      stroke-dashoffset: ${pcPx(1550)};
      stroke-dasharray: ${pcPx(1550)};
      animation: ${pathPc} 2s linear forwards infinite;
    }
  }
`;

const Intro = () => {
  const VIDEO = ASSETS_MOVIE;
  const scrollYStoreValue = useScrollYStoreValue();
  const lineL01Ref = useRef<HTMLDivElement | null>(null);
  const lineL02Ref = useRef<HTMLDivElement | null>(null);
  const lineRRef = useRef<HTMLDivElement | null>(null);
  const lineL01PcRef = useRef<HTMLDivElement | null>(null);
  const lineL02PcRef = useRef<HTMLDivElement | null>(null);
  const lineRPcRef = useRef<HTMLDivElement | null>(null);
  const fvRef = useRef<HTMLDivElement | null>(null);
  const text01Ref = useRef<HTMLDivElement | null>(null);
  const text02Ref = useRef<HTMLImageElement | null>(null);
  const [isFvRefScrolled, setIsFvRefScrolled] = useState(false);
  const [isText01RefScrolled, setIsText01RefScrolled] = useState(false);
  const [isText02RefScrolled, setIsText02RefScrolled] = useState(false);
  const languageStoreValue = useLanguageStoreValue();
  const { t } = useTranslation();

  const getY = (
    ref: MutableRefObject<HTMLDivElement | null>,
    scrollY: number
  ) => {
    if (!ref || !ref.current) return 0;
    const y =
      (scrollY -
        (ref.current.getBoundingClientRect().top + window.pageYOffset)) *
      0.1;
    return y;
  };

  const getTextImg = (lang: string) => {
    let img = ASSETS_TEXT_JA;
    if (lang === LANGUAGE.en) img = ASSETS_TEXT_EN;
    if (lang === LANGUAGE.fr) img = ASSETS_TEXT_FR;
    if (lang === LANGUAGE.de) img = ASSETS_TEXT_DE;
    if (lang === LANGUAGE.it) img = ASSETS_TEXT_IT;
    if (lang === LANGUAGE.ko) img = ASSETS_TEXT_KO;
    if (lang === LANGUAGE.ru) img = ASSETS_TEXT_RU;
    if (lang === LANGUAGE.es) img = ASSETS_TEXT_ES;
    if (lang === LANGUAGE.pt) img = ASSETS_TEXT_PO;
    if (lang === LANGUAGE.zhCN) img = ASSETS_TEXT_CH_CN;
    if (lang === LANGUAGE.zhTW) img = ASSETS_TEXT_CH_TW;
    return img;
  };

  useEffect(() => {
    onScrollPromise(fvRef.current)().then(() => setIsFvRefScrolled(true));
    onScrollPromise(text01Ref.current)().then(() =>
      setIsText01RefScrolled(true)
    );
    onScrollPromise(text02Ref.current)().then(() =>
      setIsText02RefScrolled(true)
    );
    return () => {
      removeOnscrollEvent(fvRef.current);
      removeOnscrollEvent(text01Ref.current);
      removeOnscrollEvent(text02Ref.current);
    };
  }, []);

  return (
    <Wrapper>
      <BGWrapper />
      <ContentsWrapper ref={fvRef} data-visible={isFvRefScrolled}>
        <Video
          src={VIDEO}
          muted
          autoPlay
          loop
          playsInline
          data-visible={isFvRefScrolled}
        ></Video>
        <TextWrapper>
          <Text
            data-visible={isFvRefScrolled}
            data-lang-ru={languageStoreValue === LANGUAGE.ru}
          >
            {t('intro.copy')}
          </Text>
          <Bold data-visible={isFvRefScrolled}>{t('intro.title')}</Bold>
          <LinkWrapper data-visible={isFvRefScrolled}>
            <Link
              href={`/sign_up?lang=${languageStoreValue}`}
              target='_blank'
              rel='noopener noreferrer'
              data-lang-ru={languageStoreValue === LANGUAGE.ru}
            >
              <span>{t('intro.signup')}</span>
            </Link>
          </LinkWrapper>
          <AwardWrapper data-visible={isFvRefScrolled}>
            <div>
              <a
                href='https://boxil.jp/service/7360/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={ASSETS_AWARD} />
              </a>
            </div>
            <div>
              <img src={ASSETS_AWARD_IT} />
            </div>
          </AwardWrapper>
          <TextDL
            data-visible={isFvRefScrolled}
            data-lang-ru={languageStoreValue === LANGUAGE.ru}
          >
            {t('intro.dlText')}
          </TextDL>
          <a
            href='https://apps.apple.com/jp/app/viket-town-%E5%83%8D%E3%81%8F%E3%82%92%E3%82%A8%E3%83%B3%E3%82%BF%E3%83%A1%E3%81%AB-%E3%83%93%E3%82%B1%E3%83%83%E3%83%88%E3%82%BF%E3%82%A6%E3%83%B3/id1552796432'
            target='_blank'
            rel='noopener noreferrer'
          >
            <AppDl
              src={ASSETS_APP_DL}
              alt={t('intro.dl')}
              data-visible={isFvRefScrolled}
            />
          </a>
        </TextWrapper>
      </ContentsWrapper>
      {/* <TextContentsWrapper style={{ backgroundPosition: `0 ${pcPx(scrollYStoreValue * 0.3)}`}}> */}
      <TextContentsWrapper ref={text01Ref} data-visible={isText01RefScrolled}>
        <IntroText data-lang-ru={languageStoreValue === LANGUAGE.ru} dangerouslySetInnerHTML={renderHTML(t('intro.text'))} />
        <ConceptText
          src={getTextImg(languageStoreValue)}
          alt={t('intro.alt')}
          ref={text02Ref}
          data-visible={isText02RefScrolled}
        />
      </TextContentsWrapper>
      <LineL01
        ref={lineL01Ref}
        style={{
          transform: `translate3d(0, ${px(
            getY(lineL01Ref, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='715.653'
          height='248.89'
          viewBox='0 0 715.653 248.89'
        >
          <line
            x1='715'
            y2='247'
            transform='translate(0.327 0.945)'
            fill='none'
            stroke='#290434'
            opacity='0.322'
          />
        </svg>
      </LineL01>
      <LineL01Anim
        style={{
          transform: `translate3d(0, ${px(
            getY(lineL01Ref, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='715.653'
          height='248.89'
          viewBox='0 0 715.653 248.89'
        >
          <line
            x1='715'
            y2='247'
            transform='translate(0.327 0.945)'
            fill='none'
            stroke='#290434'
            opacity='0.322'
          />
        </svg>
      </LineL01Anim>
      <LineLPc01
        ref={lineL01PcRef}
        style={{
          transform: `translate3d(0, ${px(
            getY(lineL01PcRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='715.653'
          height='248.89'
          viewBox='0 0 715.653 248.89'
        >
          <line
            x1='715'
            y2='247'
            transform='translate(0.327 0.945)'
            fill='none'
            stroke='#290434'
            opacity='0.322'
          />
        </svg>
      </LineLPc01>
      <LineLPc01Anim
        style={{
          transform: `translate3d(0, ${px(
            getY(lineL01PcRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='715.653'
          height='248.89'
          viewBox='0 0 715.653 248.89'
        >
          <line
            x1='715'
            y2='247'
            transform='translate(0.327 0.945)'
            fill='none'
            stroke='#290434'
            opacity='0.322'
          />
        </svg>
      </LineLPc01Anim>
      <LineR
        ref={lineRRef}
        style={{
          transform: `translate3d(0, ${px(
            getY(lineRRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='730.067'
          height='461.691'
          viewBox='0 0 730.067 461.691'
        >
          <line
            x1='729'
            y2='460'
            transform='translate(0.534 0.846)'
            fill='none'
            stroke='#000'
            opacity='0.322'
          />
        </svg>
      </LineR>
      <LineRAnim
        style={{
          transform: `translate3d(0, ${px(
            getY(lineRRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='730.067'
          height='461.691'
          viewBox='0 0 730.067 461.691'
        >
          <line
            x1='729'
            y2='460'
            transform='translate(0.534 0.846)'
            fill='none'
            stroke='#000'
            opacity='0.322'
          />
        </svg>
      </LineRAnim>
      <LineRPc
        ref={lineRPcRef}
        style={{
          transform: `translate3d(0, ${px(
            getY(lineRPcRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='730.067'
          height='461.691'
          viewBox='0 0 730.067 461.691'
        >
          <line
            x1='729'
            y2='460'
            transform='translate(0.534 0.846)'
            fill='none'
            stroke='#000'
            opacity='0.322'
          />
        </svg>
      </LineRPc>
      <LineRPcAnim
        style={{
          transform: `translate3d(0, ${px(
            getY(lineRPcRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='730.067'
          height='461.691'
          viewBox='0 0 730.067 461.691'
        >
          <line
            x1='729'
            y2='460'
            transform='translate(0.534 0.846)'
            fill='none'
            stroke='#000'
            opacity='0.322'
          />
        </svg>
      </LineRPcAnim>
      <LineL02
        ref={lineL02Ref}
        style={{
          transform: `translate3d(0, ${px(
            getY(lineL02Ref, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='349.558'
          height='218.892'
          viewBox='0 0 349.558 218.892'
        >
          <line
            x1='348.5'
            y2='217.194'
            transform='translate(0.529 0.849)'
            fill='none'
            stroke='#000'
            opacity='0.322'
          />
        </svg>
      </LineL02>
      <LineL02Anim
        style={{
          transform: `translate3d(0, ${px(
            getY(lineL02Ref, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='349.558'
          height='218.892'
          viewBox='0 0 349.558 218.892'
        >
          <line
            x1='348.5'
            y2='217.194'
            transform='translate(0.529 0.849)'
            fill='none'
            stroke='#000'
            opacity='0.322'
          />
        </svg>
      </LineL02Anim>
      <LineLPc02
        ref={lineL02PcRef}
        style={{
          transform: `translate3d(0, ${px(
            getY(lineL02PcRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='682.061'
          height='427.696'
          viewBox='0 0 682.061 427.696'
        >
          <line
            x1='681'
            y2='426'
            transform='translate(0.53 0.848)'
            fill='none'
            stroke='#000'
            opacity='0.322'
          />
        </svg>
      </LineLPc02>
      <LineLPc02Anim
        style={{
          transform: `translate3d(0, ${px(
            getY(lineL02PcRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='682.061'
          height='427.696'
          viewBox='0 0 682.061 427.696'
        >
          <line
            x1='681'
            y2='426'
            transform='translate(0.53 0.848)'
            fill='none'
            stroke='#000'
            opacity='0.322'
          />
        </svg>
      </LineLPc02Anim>
    </Wrapper>
  );
};

export default Intro;
