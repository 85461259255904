import React, { useEffect,useGlobal } from "reactn";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { sp } from "../../utils/mediaQuery";
import { useTranslation } from 'react-i18next';
import { useSelector, connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
const USER_ROLE = {
  MASTER: "master",
  MEMBER: "member",
};

const PlanLimitDialog = () => {
  
  const link = 'https://forms.gle/Xvz2pHDKRwSNNpXU8';

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.setUser.currentUser);
  const [isShowEnterOfficeModal, setIsShowEnterOfficeModal] = useGlobal("isShowEnterOfficeModal");

  let isMaster;
  if (currentUser.role == USER_ROLE.MASTER) {
    isMaster = true;
  }
  const isMobile = useMediaQuery({ maxWidth: 430 }) || (/android|ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) && 'ontouchend' in document)

  useEffect(() => {
    const content = $('#content');
    $('body').on('click', function(e) {
      if (!$(e.target).closest(content).length && !$(e.target).is(content)) {
        dispatch({ type: 'SET_TYPE_CALL_LIMIT', isShow: false });
      }
    })
   }, []);

  const handleClick = () => {
    dispatch({ type: 'SET_TYPE_CALL_LIMIT', isShow: false });
    setIsShowEnterOfficeModal(false);
  }

  return (
    <BoxFixed id="content">
      <LimitDialogWrapper>
        <p>{t("PlanLimitTextLimit1",{})}</p>
        <p>{t("PlanLimitTextLimit2",{})}</p>
        <p>{t("PlanLimitTextLimit3",{})}</p>
        {isMaster && !isMobile && (
          <ButtonPaymentWrapper>
            <a href="/payment" onClick={handleClick}>
              <ButtonPaymentButton>
                購入はこちら
              </ButtonPaymentButton>
            </a>
          </ButtonPaymentWrapper>
        )}

      </LimitDialogWrapper>
    </BoxFixed>
  );
}

const BoxFixed = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`

const LimitDialogWrapper = styled.div`
  background: #fff;
  width: 600px;
  height: auto;
  padding: 50px 20px;
  box-shadow: 0 2px 8px 0px rgb(0 0 0 / 40%);
  color: #000;
  ${sp`
    width: 95vw;
  `}
  p {
    font-size: 13px;
    text-align: center;
    line-height: 1.3rem;
  }
  a {
    color: #fff;
  }
`

const ButtonPaymentWrapper = styled.div`
  margin: 18px 10px;
  display: flex;
  justify-content: center;
  font-size:12px;
  ${sp`
    display:none;
  `}
`

const ButtonPaymentButton = styled.div`
  width: 240px;
  padding: 17px 10px;
  background-color: #03A9F4;
  text-align:center
`
export default PlanLimitDialog;
