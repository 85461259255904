import React from 'react';
import styled, { keyframes } from 'styled-components';

interface StyledProps {
  isHovered: boolean;
  position: { top: number; left: number };
}

const showTooltip = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

// attrsメソッド記法を使い、mousemoveイベント毎のクラス再生成を抑止する
const StyledTooltip = styled.div.attrs<StyledProps>(({ position }) => ({
  style: {
    // マウスの気持ち上にtooltipがくるようにtopを調整
    top: `${position.top - 72}px`,
    // マウスの位置とtooltipの中心が合うようにleftを調整
    left: `${position.left - 48}px`,
  },
}))<StyledProps>`
  display: ${({ isHovered }) => (isHovered ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  min-width: 104px;
  max-width: 240px;
  padding: 8px 0;
  backdrop-filter: blur(5px);
  background-color: rgba(238, 238, 238, 0.6);
  border-radius: 8px;
  font-size: 10px;
  text-align: center;
  z-index: 2;
  animation: ${showTooltip} 0.5s ease;
  p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    transform: scale(0.8);
    color: #000;
    &:nth-child(2) {
      font-weight: bold;
      font-size: 12px;
    }
  }
`;

interface Props extends StyledProps {
  roomName: string;
  stayMinutes: string;
}

export const Tooltip: React.FC<Props> = ({
  isHovered,
  position,
  roomName,
  stayMinutes,
}) => {
  return (
    <StyledTooltip isHovered={isHovered} position={position}>
      <p>{roomName}</p>
      <p>{stayMinutes}</p>
    </StyledTooltip>
  );
};
