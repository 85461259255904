import React, { useEffect } from "react";

const Clock = () => { 
  const [date, setDate] = React.useState(new Date());

  useEffect(() => {
    var timerID = setInterval( () => tick(), 1000 );
    return function cleanup() {
      clearInterval(timerID);
    };
  });

  function tick() {
    setDate(new Date());
  }

  return (
    <span>{date.toLocaleDateString()} {date.toLocaleTimeString()}</span>
  );
}

export default Clock;
