import { useCallback } from 'react';
import { useGlobal } from 'reactn';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useSWRConfig } from 'swr';

export const useAddSkillProduct = (
  toggleModal?: () => void
) => {
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();
  const [_, setIsShowEditVirtualWorldModal] = useGlobal<{ isShowSkillEditModal: boolean }>(
    'isShowSkillEditModal'
  );
  const handleAddSkillProduct = useCallback(async (form) => {
    const isConfirmed = (form.published) ? confirm(t('ModalAddSkillConfirm', {})) : true;
    const formData = new FormData();
    if (isConfirmed) {
      formData.append('skill_image', form.file[0]);
      formData.append('product_name', form.product_name);
      formData.append('description', form.description);
      formData.append('for_world', form.for_world);
      formData.append('quantity', form.quantity);
      formData.append('viket', form.viket);
      formData.append('avatar_skill_product_id', form.avatar_skill_product_id)
      formData.append('published', form.published);
      formData.append('expired_at', form.expired_at);
      const { data } = await axios.post<{ message: string; status: number }>(
        '/api/v1/avatar/product/add_skill',
        formData
      );
      mutate(`/api/v1/avatar/products`);
      alert(data.message);
      toggleModal && toggleModal();
      setIsShowEditVirtualWorldModal(false);
    }
  }, []);

  const handleDeleteSkillProduct = useCallback(async (avatar_skill_product_id: number | null) => {
    const isConfirmed = confirm(t('ModalDeleteSkillConfirm', {}))
    if (isConfirmed) {
      await axios.delete(
        '/api/v1/avatar/product/delete_skill',
        { data: { avatar_skill_product_id: avatar_skill_product_id } }
      ).then((data: any) => {
        alert(data.data.message);
      });
      mutate(`/api/v1/avatar/products`);
      toggleModal && toggleModal();
    }
  }, []);

  return { handleAddSkillProduct, handleDeleteSkillProduct };
};
