import { useCallback } from 'react';
import { DayModifiers } from 'react-day-picker';

export const useSelectDate = (setSelectedDate: (date: Date) => void) => {
  const selectDate = useCallback(
    (day: Date, option: DayModifiers) => {
      if (option.disabled) return;
      setSelectedDate(day);
    },
    [setSelectedDate]
  );

  return { selectDate };
};
