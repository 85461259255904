import { useCallback, useState } from 'react';

export const useFilterType = () => {
  const [filterType, setFilter] = useState(1);

  const setFilterType = useCallback((filterNum: number) => {
    setFilter(filterNum);
  }, []);

  return { filterType, setFilterType };
};
