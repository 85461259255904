// TODO refactoring. now in progress
import React, { useState } from 'react';
import { useGlobal } from 'reactn';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { UserProfileDisplay } from 'javascript/features/userProfile/components/UserProfileDisplay';
import { UserProfileModal } from 'javascript/features/userProfile/components/UserProfileModal';
import { UserProfileRecentComments } from 'javascript/features/userProfile/components/UserProfileRecentComments';
import { UserProfileViketInfo } from 'javascript/features/userProfile/components/UserProfileViketInfo';
import { StayHistoryTemplate } from 'javascript/features/stayHistory/components';
import { useDeleteUser } from 'javascript/features/userProfile/hooks/useDeleteUser';
import { useUserProfile } from 'javascript/features/userProfile/hooks/useUserProfile';
import { useOwnedProductList } from 'javascript/features/userProfile/hooks/useOwnedProductList';
import { device } from 'javascript/utils/mediaQuery';
import { UserProfile } from 'types';
import { AvatarDressUpTemplate } from 'javascript/features/avatarDressUp/components';
import { useTranslation } from 'react-i18next';

interface Props {
  isQueryTargetAvatar: boolean;
}

const UserProfileTemplate: React.FC<Props> = ({ isQueryTargetAvatar }) => {
  const [isEmployeeEdit, setIsEmployeeEdit] = useState(false);
  const [uploadImage, setUploadImage] = useState('');
  // @ts-ignore
  const currentUser = useSelector((state) => state.setUser.currentUser);
  // @ts-ignore
  const isMaster = useSelector((state) => state.setUser.isMaster);

  const isMobile = useMediaQuery({ maxWidth: 430 });

  const [userIdString] = useGlobal<{ userId: string | undefined }>('userId');
  const userId = Number(userIdString);

  const { userData, handleGetUserData } = useUserProfile(userId);
  const { userData: currentUserData } = useUserProfile(currentUser.id);

  const { handleDeleteUser } = useDeleteUser(userId);

  const { ownedProductList } = useOwnedProductList();

  const setIsEmployeeEditing = (isOpend: false) => {
    setIsEmployeeEdit(isOpend);
  };

  const setUploadImageByUser = (uploadedImage: any) => {
    setUploadImage(uploadedImage);
  };

  const isCurrentUser = currentUser.id === userId;
  const { t } = useTranslation();

  let isEditable = false;
  if (isMaster || userId == currentUser.id) {
    isEditable = true;
  }

  const handleEditUser = (userData: UserProfile) => {
    handleGetUserData(userData.user.user_id);
    setIsEmployeeEdit(true);
  };

  if (!userData || !ownedProductList?.owned_products || !currentUserData) {
    return null;
  }

  return (
    <>
      {isQueryTargetAvatar ? (
        <AvatarDressUpTemplate
          currentUserId={currentUser.id}
          avatarPartsInfo={currentUserData.avatar}
          ownedProducts={ownedProductList.owned_products}
        />
      ) : (
        <>
          <StyledUserProfileTemplate>
            <EmployeeDetailWrapper>
              <EmployeeDetailWrapperItems>
                <UserProfileDisplay
                  userData={userData}
                  isEditable={isEditable}
                  isMaster={isMaster}
                  isCurrentUser={isCurrentUser}
                  handleEditUser={handleEditUser}
                  isCast={currentUser.role == "cast"}
                />
              </EmployeeDetailWrapperItems>
              <EmployeeDetailWrapperItems>
                <UserProfileViketInfo
                  avatarId={userData.avatar.avatar_id}
                  viketCount={userData.avatar.viket}
                  level={userData.avatar.level}
                />
              </EmployeeDetailWrapperItems>
              <EmployeeDetailWrapperItems>
                <UserProfileRecentComments
                  currentPageUserId={userData.user.user_id}
                  userIdsSortByRespected={userData.user_ids}
                  isCast={currentUser.role == "cast"}
                />
                {isMaster && !isMobile && (
                  <EmployeeDeleteButton onClick={handleDeleteUser}>
                    {t('EmployeeAlertDeleteMember', {})}
                  </EmployeeDeleteButton>
                )}
              </EmployeeDetailWrapperItems>
            </EmployeeDetailWrapper>
            <StayHistoryTemplate userId={userData.user.user_id} />
          </StyledUserProfileTemplate>
          {isEmployeeEdit && userData && (
            <UserProfileModal
              userData={userData}
              isMaster={isMaster}
              uploadImage={uploadImage}
              setUploadImageByUser={setUploadImageByUser}
              setIsEmployeeEditing={setIsEmployeeEditing}
              isCurrentUser={currentUser.id == userId}
            />
          )}
        </>
      )}
    </>
  );
};

const StyledUserProfileTemplate = styled.div`
  position: relative;
  height: 100%;
  overflow: scroll;
  @media ${device.sp} {
    height: auto;
    overflow: inherit;
  }
`;

const EmployeeDeleteButton = styled.div`
  margin: auto;
  text-align: right;
  color: red;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 20px;
`;

const EmployeeDetailWrapper = styled.div`
  font-size: 13px;
  position: relative;
  display: flex;
  width: 1200px;
  justify-content: center;
  @media ${device.sp} {
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
`;

const EmployeeDetailWrapperItems = styled.div`
  position: relative;
  width: calc(100% / 3);
  &:nth-child(2) {
    border-left: 1px solid #dedede;
    border-right: 1px solid #dedede;
  }
  @media ${device.sp} {
    width: 100%;
    margin: 0 0 20px;
    &:nth-child(2) {
      border: none;
    }
  }
`;

export default UserProfileTemplate;
