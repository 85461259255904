import React from "react";
import styled from "styled-components";

interface Props {
    borderColor?: string;
    fontSize?: string;
    disabled?: boolean;
    onClick?: () => void;
    children: string;
}
export default function SimpleButton({ borderColor = "#C0C0C0", fontSize = "12px", disabled = false, onClick, children }: Props) {
    const StyledButton = styled.button`
        background-color: #fff;
        color: #00A0E9;
        border: 1px solid ${borderColor};
        border-radius: 4px;
        padding: 4px 12px;
        font-size: ${fontSize};
        font-weight: 600;
        cursor: pointer;
        &:focus {
            outline: none;
        }
        ${disabled && "opacity: 0.4; cursor: default;"}
    `;

    return (
        <StyledButton onClick={disabled ? () => { } : onClick}>
            {children}
        </StyledButton>
    )
}
