import { useState, useEffect } from "react";
import useVideoCallContext from "./useVideoCallContext";

const useRoomState = () => {

  const { room } = useVideoCallContext();
  const [state, setState] = useState('notconnected');

  useEffect(() => {
    const setRoomState = () => setState(room.state);
    if (room) {
      setRoomState();
      room
        .on('disconnected', setRoomState)
        .on('reconnected', setRoomState)
        .on('reconnecting', setRoomState);
      return () => {
        room
          .on('disconnected', setRoomState)
          .on('reconnected', setRoomState)
          .on('reconnecting', setRoomState); 
      };
    }
  }, [room]);

  return state;
}

export default useRoomState;