import useParticipants from './useParticipants';
import useVideoCallContext from './useVideoCallContext';
import useScreenShareParticipant from './useScreenShareParticipant';
import {useSelectedParticipant} from './useSelectedParticipant';

const useMainParticipant = () => {
  const screenShareParticipant = useScreenShareParticipant();
  const { room } = useVideoCallContext();
  const [selectedParticipant] = useSelectedParticipant();
  const participants = useParticipants();
  const localParticipant = room?.localParticipant;
  const remoteScreenShareParticipant = screenShareParticipant !== localParticipant ? screenShareParticipant : null;
  
  // return selectedParticipant || remoteScreenShareParticipant || participants[0] || localParticipant;
  return remoteScreenShareParticipant || screenShareParticipant;
}
export default useMainParticipant;