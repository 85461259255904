import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { useGetOfficeData } from '../../realWorld/hooks/useGetOfficeData';

export const useCreateVirtualWorld = () => {
  const initialValues = {
    type: 'ROOM',
    name: '',
    url: '',
    note: '',
    is_open_guest: false,
    room_type: 'nonfunctional',
    lounge_auto_move: true,
  };

  const [roomDetail, setRoomDetail] = useState(initialValues);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [type, setType] = useState(initialValues.room_type);
  const [_, setIsShowCreateVirtualWorldModal] = useGlobal(
    'isShowCreateVirtualWorldModal'
  );
  const { getOfficeData } = useGetOfficeData();

  const submit = async (headers, data) => {
    const url = '/api/v1/rooms';
    const config = { headers };
    return await axios.post(url, data, config);
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      const headers = {
        'content-type': 'application/json',
      };

      const params = {
        type: 'ROOM',
        name: roomDetail.name.trim() + (roomDetail.is_open_guest? " " : ""),
        url: roomDetail.url,
        note: roomDetail.note,
        is_open_guest: roomDetail.is_open_guest,
        room_type: type === 'voice' ? 2 : type === 'video' ? 1 : 3,
        delete_flg: false,
        lounge_auto_move: type !== 'nonfunctional',
      };

      submit(headers, params)
        .then(() => {
          setIsShowCreateVirtualWorldModal(false);
          getOfficeData();
        })
        .catch((err) => {
          alert(err);
        });
    },
    [submit]
  );

  const handleChangeValue = useCallback(
    (e) => {
      const { name, value } = e.target;
      if (name == "is_open_guest") {
        roomDetail[name] =  e.target.checked;  
      } else {
        roomDetail[name] = value;
      }
      setRoomDetail({ ...roomDetail, roomDetail });
    },
    [roomDetail, setRoomDetail]
  );

  const handleSelectType = (roomType) => {
    setType(roomType);
  };

  return {
    roomDetail,
    handleChangeValue,
    handleSubmit,
    type,
    handleSelectType,
    isSubmitting,
  };
};
