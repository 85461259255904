import React, { useRef } from 'react';
import styled from 'styled-components';
import { StatusPopup } from 'javascript/features/freeSpace/components/StatusPopup';
import { STATUS_ARR } from 'javascript/features/freeSpace/constants';
import { useIsShowPopup } from 'javascript/features/freeSpace/hooks/useIsShowPopup';
import { UserAvatar, StatusType } from 'types';
import { device } from 'javascript/utils/mediaQuery';
import { useTranslation } from 'react-i18next';
interface Props {
  avatar?: Omit<UserAvatar, 'id'>;
  handleSelectType: (type: StatusType, num: number) => void;
}

export const StatusItemSelectArea: React.FC<Props> = ({
  avatar,
  handleSelectType,
}) => {
  const summonRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLDivElement>(null);
  const defenseRef = useRef<HTMLDivElement>(null);
  const { isOpen: isSummonOpen, setIsOpen: setIsSummonOpen } = useIsShowPopup();
  const { isOpen: isItemOpen, setIsOpen: setIsItemOpen } = useIsShowPopup();
  const { isOpen: isDefenseOpen, setIsOpen: setIsDefenseOpen } =
    useIsShowPopup();

  const handleClickToggleSummon = () => setIsSummonOpen(true);
  const handleClickToggleItem = () => setIsItemOpen(true);
  const handleClickToggleDefense = () => setIsDefenseOpen(true);
  const { t } = useTranslation();

  return (
    <StyledStatusItemSelectArea>
      {STATUS_ARR.map(({ type, str, items }, i) => (
        <StyledStatusItemType
          key={i}
          onClick={
            type === 'summon'
              ? handleClickToggleSummon
              : type === 'item'
              ? handleClickToggleItem
              : handleClickToggleDefense
          }
        >
          <StatusPopup
            isOpen={
              type === 'summon'
                ? isSummonOpen
                : type === 'item'
                ? isItemOpen
                : isDefenseOpen
            }
            popupRef={
              type === 'summon'
                ? summonRef
                : type === 'item'
                ? itemRef
                : defenseRef
            }
            type={type}
            items={items}
            avatar={avatar}
            handleSelectType={handleSelectType}
          />
          <StyledStatusTypeFrame>
            {/* @ts-ignore */}
            <img src={RailsAssetPath(`freeSpace/frame_${type}.png`)} />
            <StyledStatusTypeImage>
              <img
                // @ts-ignore
                src={RailsAssetPath(
                  `freeSpace/status/${type}/${
                    type === 'summon'
                      ? avatar?.summon
                        ? avatar.summon
                        : 'default'
                      : type === 'item'
                      ? avatar?.item
                        ? avatar.item
                        : 'default'
                      : avatar?.defense
                      ? avatar.defense
                      : 'default'
                  }.png`
                )}
              />
            </StyledStatusTypeImage>
            <StyledStatusTypeIcon>
              {/* @ts-ignore */}
              <img src={RailsAssetPath(`freeSpace/status/${type}/icon.png`)} />
            </StyledStatusTypeIcon>
          </StyledStatusTypeFrame>
          <StyledStatusName
            // @ts-ignore
            img={RailsAssetPath('freeSpace/bg_status_type_name.png')}
          >
            <p>{t(str)}</p>
          </StyledStatusName>
        </StyledStatusItemType>
      ))}
    </StyledStatusItemSelectArea>
  );
};

const StyledStatusItemSelectArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: 160px;
  height: 100%;
  @media ${device.sp} {
    width: 130px;
    margin: 0 8px 0 6px;
  }
`;

const StyledStatusItemType = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  cursor: pointer;
`;

const StyledStatusTypeFrame = styled.div`
  position: relative;
  margin-top: 5px;
  @media ${device.sp} {
    margin-top: 10px;
  }
  img {
    width: 40px;
    height: 40px;
    @media ${device.sp} {
      width: 35px;
      height: 35px;
    }
  }
`;

const StyledStatusTypeImage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  img {
    width: 20px;
    height: 20px;
  }
`;

const StyledStatusTypeIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(45%, -40%);
  img {
    width: 14px;
    height: 14px;
    @media ${device.sp} {
      width: 11px;
      height: 11px;
    }
  }
`;

const StyledStatusName = styled.div<{ img: string }>`
  width: 100%;
  text-align: center;
  background: center / 80% no-repeat url(${({ img }) => img});
  p {
    color: #fff;
    font-size: 10px;
    transform: scale(0.7);
    @media ${device.sp} {
      white-space: nowrap;
      transform: scale(0.6);
    }
  }
`;
