import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { device } from 'javascript/utils/mediaQuery';

interface Props {
  roomType: 'lunge' | 'sleep';
  memberNum: number;
  handleOnClick?: (e: React.MouseEvent) => void;
  isHiddenSubRoom?: boolean;
}

export const SubRoomTitle: React.FC<Props> = ({
  roomType,
  memberNum,
  handleOnClick,
  isHiddenSubRoom,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 430 });

  return (
    <SubRoomTitleWrapper>
      <div>
        <img
          // @ts-ignore
          src={RailsAssetPath(
            `${roomType === 'lunge' ? 'sleep.png' : 'dayoff.png'}`
          )}
        />
      </div>
      <SubRoomTextArea>
        <p>
          {roomType === 'lunge'
            ? t('OfficeStatusSleep', {})
            : t('OfficeStatusDayoff', {})}
        </p>
        <p>{memberNum}</p>
      </SubRoomTextArea>
      {isMobile && roomType === 'lunge' && (
        <TriangleWrapper
          onClick={handleOnClick ? (e) => handleOnClick(e) : undefined}
        >
          <Triangle isHiddenSubRoom={isHiddenSubRoom} />
        </TriangleWrapper>
      )}
    </SubRoomTitleWrapper>
  );
};

const SubRoomTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #999;
  font-size: 12px;
  letter-spacing: 1px;
  position: relative;
  @media ${device.sp} {
    font-size: 10px;
    height: 20px;
  }
  img {
    width: 14px;
    height: 14px;
    @media ${device.sp} {
      width: 12px;
      height: 12px;
    }
  }
`;

const SubRoomTextArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: 72px;
  margin-left: 8px;
  @media ${device.sp} {
    width: 60px;
  }
`;

const TriangleWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: 20px;
  width: 32px;
  height: 32px;
`;

const Triangle = styled.div<{ isHiddenSubRoom?: boolean }>`
  width: 10px;
  height: 10px;
  border: 1px solid;
  border-color: ${({ isHiddenSubRoom }) =>
    isHiddenSubRoom
      ? '#707070 #707070 transparent transparent'
      : 'transparent transparent #707070 #707070'};
  transform: rotate(-45deg);
`;
