import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { CsvButton } from 'javascript/features/stayHistory/components/CsvButton';
import { useYearMonthOption } from 'javascript/features/stayHistory/hooks/useYearMonthOption';
import { device } from 'javascript/utils/mediaQuery';

const StyledStayHistoryHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  @media ${device.sp} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledTitle = styled.p`
  margin-right: 40px;
  @media ${device.sp} {
    order: 1;
    margin-right: 0;
    padding-left: 16px;
    font-weight: bold;
  }
`;

const StyledCategoryWrapper = styled.div`
  display: flex;
  @media ${device.sp} {
    order: 4;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
    font-size: 10px;
  }
`;

const StyledCategory = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  &:not(:last-child) {
    margin-right: 24px;
  }
  @media ${device.sp} {
    column-gap: 4px;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

const StyledBox = styled.div<{ categoryColor: string }>`
  width: 16px;
  height: 16px;
  background-color: ${({ categoryColor }) => categoryColor};
  @media ${device.sp} {
    width: 12px;
    height: 12px;
  }
`;

const StyledSwitchHistoryYearWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 40px;
  margin-left: 40px;
  margin-right: 32px;
  @media ${device.sp} {
    order: 2;
    margin: 16px auto 0;
    column-gap: 64px;
  }
`;

const StyledSwitchIconWrapper = styled.div<{
  isBack?: boolean;
  isForward?: boolean;
  isEarliestYear?: boolean;
  isLatestYear?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 32px;
  border: 1px solid #aaa;
  border-radius: 8px;
  opacity: ${({ isBack, isForward, isEarliestYear, isLatestYear }) =>
    ((isBack && isEarliestYear) || (isForward && isLatestYear)) && '0.5'};
  cursor: ${({ isBack, isForward, isEarliestYear, isLatestYear }) =>
    (isBack && isEarliestYear) || (isForward && isLatestYear)
      ? 'default'
      : 'pointer'};
`;

const StyledBackIcon = styled.div`
  width: 8px;
  height: 8px;
  border-top: 1px solid #aaa;
  border-left: 1px solid #aaa;
  transform: translateX(25%) rotate(-45deg);
`;

const StyledForwardIcon = styled.div`
  width: 8px;
  height: 8px;
  border-top: 1px solid #aaa;
  border-right: 1px solid #aaa;
  transform: translateX(-25%) rotate(45deg);
`;

const StyledCsvButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-left: auto;
`;

const StyledSelectWrapper = styled.div`
  position: relative;
  width: 88px;
  select {
    width: 100%;
    height: 100%;
    padding: 4px 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    appearance: none;
  }
  @media ${device.sp} {
    order: 3;
    margin: 16px auto 0;
  }
`;

const StyledTriangle = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  width: 8px;
  height: 6px;
  background-color: #888;
  clip-path: polygon(0 0, 100% 0%, 50% 100%);
`;

interface Props {
  userId: number;
  historyYear: number | undefined;
  isLatestYear: boolean;
  isEarliestYear: boolean;
  changeHistoryYear: (backOrForward: number) => void;
  selectMonth: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const StayHistoryHeader: React.FC<Props> = ({
  userId,
  historyYear,
  isLatestYear,
  isEarliestYear,
  changeHistoryYear,
  selectMonth,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 430 });
  const { t } = useTranslation();
  const { yearMonthOption } = useYearMonthOption();
  const [yearMonth, setYearMonth] = useState(yearMonthOption[0]);

  return (
    <StyledStayHistoryHeader>
      <StyledTitle>{t('ProfileTextStayhistory', {})}</StyledTitle>
      <StyledCategoryWrapper>
        <StyledCategory>
          <StyledBox categoryColor='#d0f25f' />
          <p>{t('ProfileTextFreespace', {})}</p>
        </StyledCategory>
        <StyledCategory>
          <StyledBox categoryColor='#5387f4' />
          <p>{t('RoomTypeNormal', {})}</p>
        </StyledCategory>
        <StyledCategory>
          <StyledBox categoryColor='#4fd24b' />
          <p>{t('RoomTypeVoice', {})}</p>
        </StyledCategory>
        <StyledCategory>
          <StyledBox categoryColor='#ff8086' />
          <p>{t('RoomTypeVideo', {})}</p>
        </StyledCategory>
      </StyledCategoryWrapper>
      <StyledSwitchHistoryYearWrapper>
        <StyledSwitchIconWrapper
          isBack
          isEarliestYear={isEarliestYear}
          onClick={!isEarliestYear ? () => changeHistoryYear(-1) : undefined}
        >
          <StyledBackIcon />
        </StyledSwitchIconWrapper>
        <p>{historyYear}</p>
        <StyledSwitchIconWrapper
          isForward
          isLatestYear={isLatestYear}
          onClick={!isLatestYear ? () => changeHistoryYear(1) : undefined}
        >
          <StyledForwardIcon />
        </StyledSwitchIconWrapper>
      </StyledSwitchHistoryYearWrapper>
      <StyledSelectWrapper>
        <select onChange={(e) => selectMonth(e)}>
          <option value={undefined}>{t('ProfileTextWholeyear', {})}</option>
          <option value='01'>{t('ProfileTextJanuary', {})}</option>
          <option value='02'>{t('ProfileTextFebruary', {})}</option>
          <option value='03'>{t('ProfileTextMarch', {})}</option>
          <option value='04'>{t('ProfileTextApril', {})}</option>
          <option value='05'>{t('ProfileTextMay', {})}</option>
          <option value='06'>{t('ProfileTextJune', {})}</option>
          <option value='07'>{t('ProfileTextJuly', {})}</option>
          <option value='08'>{t('ProfileTextAugust', {})}</option>
          <option value='09'>{t('ProfileTextSeptember', {})}</option>
          <option value='10'>{t('ProfileTextOctober', {})}</option>
          <option value='11'>{t('ProfileTextNovember', {})}</option>
          <option value='12'>{t('ProfileTextDecember', {})}</option>
        </select>
        <StyledTriangle />
      </StyledSelectWrapper>
      {!isMobile && (
        <StyledCsvButtonWrapper>
          <StyledSelectWrapper>
            <select
              onChange={(e) => {
                setYearMonth(e.currentTarget.value);
              }}
            >
              {yearMonthOption.map((yearMonth) => (
                <option key={yearMonth} value={yearMonth}>
                  {`${yearMonth.slice(0, 4)}/${yearMonth.slice(4)}`}
                </option>
              ))}
            </select>
            <StyledTriangle />
          </StyledSelectWrapper>
          <CsvButton
            url={`/api/v1/users/stay_time/csv/${userId}?year_month=${yearMonth}`}
          />
        </StyledCsvButtonWrapper>
      )}
    </StyledStayHistoryHeader>
  );
};
