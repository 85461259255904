import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Linkify from 'react-linkify';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { NAME_BACKGROUND_COLOR } from 'javascript/features/freeSpace/constants';
import { useCreateNotification } from 'javascript/features/secretChat/hooks/useCreateNotification';
import { useMoveElementRef } from 'javascript/features/secretChat/hooks/useMoveElementRef';
import { SecretChatMessage } from 'types';

interface Props {
  messageList: SecretChatMessage[];
  currentAvatarId: number;
}

export const SecretChatMessageList: React.FC<Props> = ({
  messageList,
  currentAvatarId,
}) => {
  const { t } = useTranslation();
  const [isInitialRender, setIsInitialRender] = useState(true);
  const { moveRef } = useMoveElementRef();
  const { createNotification } = useCreateNotification(
    currentAvatarId,
    messageList
  );
  const bottomRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery({ maxWidth: 430 });

  useEffect(() => {
    bottomRef.current?.scrollIntoView();
    if (!isInitialRender) {
      createNotification();
    }
    setIsInitialRender(false);
  }, [messageList.length]);

  return (
    // モーダルにrefをしないのは、インプットフォームなどのクリックも反応し、モーダルが移動してしまう。
    // そのため、クリック要素が存在しないメッセージリストにrefをする。SPの場合はモーダルを移動させたくないので、nullをrefにセットする
    <StyledSecretChatMessageList ref={isMobile ? null : moveRef}>
      {messageList.map((message, i) => (
        <StyledSecretChatMessage key={`${message.avatar_id}_${i}`}>
          <StyledAvatarImage
            // @ts-ignore
            src={message.avatar_image || RailsAssetPath('noimage_icon.png')}
            isBot={!message.user_id}
          />
          <Linkify
            componentDecorator={(href, text, key) => (
              <a target='_blank' href={href} key={key}>
                {text}
              </a>
            )}
          >
            <StyledChatContent>
              {/* Bot（user_idがnull）の場合はtranslateするが、
              通常のユーザーの場合もtranslate（t(message.content)）してしまうと、
              httpリンクがあるcontentの場合に表示バグが発生してしまうので、通常のユーザーの場合はtranslateしない */}
              {!message.user_id ? t(message.content) : message.content}
            </StyledChatContent>
          </Linkify>
          <StyledAvatarName nameColor={message.chat_color_status}>
            {message.avatar_name}
          </StyledAvatarName>
        </StyledSecretChatMessage>
      ))}
      <div ref={bottomRef} />
    </StyledSecretChatMessageList>
  );
};

const StyledSecretChatMessageList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 290px;
  padding: 8px 16px 0;
  margin: 8px 0;
  overflow: scroll;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  a {
    border-bottom: 1px solid #00a0e9;
    color: #00a0e9;
  }
`;

const StyledSecretChatMessage = styled.div`
  display: flex;
  position: relative;
`;

const StyledChatContent = styled.p`
  width: 85%;
  padding: 10px 8px 8px;
  background-color: #fff;
  border-radius: 8px;
  font-size: 10px;
  white-space: pre-wrap;
  word-break: break-word;
`;

const StyledAvatarName = styled.p<{ nameColor: number }>`
  position: absolute;
  top: -6px;
  left: 40px;
  max-width: 82px;
  padding: 0 6px;
  font-size: 10px;
  color: #fff;
  background-color: ${({ nameColor }) => NAME_BACKGROUND_COLOR[nameColor]};
  border-radius: 10px;
  transform: scale(0.8);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledAvatarImage = styled.img<{ isBot: boolean }>`
  width: 24px;
  height: ${({ isBot }) => !isBot && '24px'};
  margin-top: 0;
  border-radius: ${({ isBot }) => !isBot && '50%'};
  object-fit: cover;
`;
