import React from 'react';
import styled from 'styled-components';
import { useSecretChat } from 'javascript/features/secretChat/hooks/useSecretChat';
import { SecretChat, SecretChatMember } from 'types';
import { useTranslation } from 'react-i18next';

interface Props {
  currentAvatar: SecretChatMember;
  ownSecretChat: SecretChat;
}

export const SecretChatInputForm: React.FC<Props> = ({
  currentAvatar,
  ownSecretChat,
}) => {
  const { val, handleOnChangeVal, handleOnSubmit, handleOnKeyDown } =
    useSecretChat();
  const { t } = useTranslation();

  // モーダル表示時はsecretChatを取得できていない可能性があるため、同じ高さを持つdivを返す
  if (!ownSecretChat) return <div style={{ height: '48px' }}></div>;

  if (!currentAvatar) return null;

  return (
    <StyledSecretChatInputForm>
      <textarea
        value={val}
        onChange={handleOnChangeVal}
        onKeyDown={(e) =>
          handleOnKeyDown(
            currentAvatar.avatar_id,
            ownSecretChat.avatar_chat_secret_room_id,
            e,
            ownSecretChat.interact_world_id
          )
        }
        placeholder={t("OpenChatTextSentMessage",{})}
      ></textarea>
      <button
        onClick={() =>
          handleOnSubmit(
            currentAvatar.avatar_id,
            ownSecretChat.avatar_chat_secret_room_id,
            ownSecretChat.interact_world_id
          )
        }
      >
        {/* @ts-ignore */}
        <img src={RailsAssetPath('freeSpace/send.png')} />
      </button>
    </StyledSecretChatInputForm>
  );
};

const StyledSecretChatInputForm = styled.div`
  position: relative;
  width: 100%;
  height: 32px;
  margin-bottom: 16px;
  padding: 0 16px;
  textarea {
    width: 100%;
    height: 100%;
    padding: 8px 40px 8px 16px;
    font-size: 10px;
    border-radius: 8px;
    resize: none;
  }
  button {
    position: absolute;
    top: 20%;
    right: 24px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    &:focus {
      border: 1px solid #00a0e9;
      box-shadow: 0 0 4px #00a0e9;
    }
    img {
      width: 12px;
      height: 12px;
    }
  }
`;
