import React from 'react';
import styled from 'styled-components';
import { hairColorArray } from 'javascript/features/avatarParts/constants/hairColor';
import { device } from 'javascript/utils/mediaQuery';
import { AvatarPartsType, UserProfileAvatar } from 'types';

const StyledPartsItem = styled.div<{
  isSelected: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 80px;
  height: 80px;
  padding: 8px;
  background-color: #fff;
  border: ${({ isSelected }) =>
    isSelected ? '3px solid #00a0e9' : '3px solid #f6f6f6'};
  border-radius: 12px;
  cursor: pointer;
  img {
    margin: 0;
    max-width: 48px;
    max-height: 48px;
  }

  @media ${device.sp} {
    width: 65px;
    height: 65px;
    img {
      max-width: 40px;
      max-height: 40px;
    }
  }
`;

const StyledViketIcon = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  img {
    width: 16px;
  }
  @media ${device.sp} {
    top: 2px;
    right: 2px;
    img {
      width: 12px;
    }
  }
`;

type Props = {
  defaultItemsCount: number;
  ownedProducts: number[];
  avatarParts: UserProfileAvatar;
  selectedAvatarPartsType: AvatarPartsType;
  selectAvatarParts: (selectedAvatarParts: UserProfileAvatar) => void;
  localHairColor?: string;
  isAbleToTakeOff?: boolean;
};

export const EachAvatarPartsTypeList: React.FC<Props> = ({
  defaultItemsCount,
  ownedProducts,
  avatarParts,
  selectedAvatarPartsType,
  selectAvatarParts,
  localHairColor,
  isAbleToTakeOff = false,
}) => {
  return (
    <>
      {isAbleToTakeOff && (
        <StyledPartsItem
          isSelected={!avatarParts[selectedAvatarPartsType]}
          onClick={() =>
            selectAvatarParts({
              ...avatarParts,
              [selectedAvatarPartsType]: null,
            })
          }
        >
          {/* @ts-ignore */}
          <img width={12} src={RailsAssetPath('avatar_parts/default.png')} />
        </StyledPartsItem>
      )}
      {[...Array(defaultItemsCount)]
        .map((_, i) => i + 1)
        .concat(ownedProducts.sort((current, next) => current - next))
        .map((itemIndex) => (
          <StyledPartsItem
            key={itemIndex}
            isSelected={
              avatarParts[selectedAvatarPartsType] === itemIndex &&
              (selectedAvatarPartsType !== 'hair' ||
                avatarParts.hair_color ===
                  hairColorArray.findIndex((c) => c === localHairColor) + 1)
            }
            onClick={() =>
              selectAvatarParts({
                ...avatarParts,
                [selectedAvatarPartsType]: itemIndex,
                hair_color:
                  selectedAvatarPartsType !== 'hair'
                    ? avatarParts.hair_color
                    : hairColorArray.findIndex((c) => c === localHairColor) + 1,
              })
            }
          >
            <img
              // @ts-ignore
              src={RailsAssetPath(
                selectedAvatarPartsType !== 'hair'
                  ? `avatar_parts/${selectedAvatarPartsType}/${itemIndex}.png`
                  : `avatar_parts/${selectedAvatarPartsType}/${localHairColor}/${itemIndex}.png`
              )}
            />
            {itemIndex > defaultItemsCount && (
              <StyledViketIcon>
                {/* @ts-ignore */}
                <img src={RailsAssetPath('mypage/coin.png')} />
              </StyledViketIcon>
            )}
          </StyledPartsItem>
        ))}
    </>
  );
};
