import { useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
// import { SHOW_FEED_TYPE } from '../../utils/util';
import { SHOW_FEED_TYPE } from '../../../utils/util';
import { useGlobal } from 'reactn';

export const useDeleteNews = (
  newsId: number,
  saveLocalDeletedNewsIds: (newsId: number) => void
) => {
  const [feedShowType] = useGlobal<any>('feedShowType');
  const { t } = useTranslation();

  const deleteNews = useCallback(async () => {
    const isConfirmed = confirm(t('GenelalConfirmDelete'));
    if (!isConfirmed) return;

    let params;

    if (feedShowType === SHOW_FEED_TYPE.FORUM) {
      params = {
        notice_board_id: newsId,
      };
      await axios.post('/api/v1/notice_boards/delete', params);
    } else if (feedShowType === SHOW_FEED_TYPE.SURVEY) {
      params = {
        id: newsId,
      };
      await axios.post('/api/v1/question/delete', params);
    }

    // await axios.post('/api/v1/notice_boards/delete', params);
    saveLocalDeletedNewsIds(newsId);
  }, [newsId, saveLocalDeletedNewsIds]);

  return { deleteNews };
};
