import React from 'react';
import Modal from 'react-modal';
import { useGlobal } from 'reactn';
import { connect } from 'react-redux';
import FormInviteMember from '../form/FormInviteMember';
import FormEditTeam from '../form/FormEditTeam';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    background: 'white',
    borderRadius: '10px',
    overflow: 'visible',
    padding: '0',
  },
  overlay: {
    background: 'rgba(62, 62, 62, 0.75)',
    zIndex: '100',
  },
};

Modal.setAppElement('body'); //react-modal初期設定

const ModalRegistrationMember = ({ config }) => {
  const [isShowRegistrationMemberModal] = useGlobal(
    'isShowRegistrationMemberModal'
  );

  //switch modal content by type
  function displayModal(config) {
    const modalType = {
      addMember: <FormInviteMember />,
      addTeam: <FormEditTeam form={config} />,
      addPosition: <FormEditTeam form={config} />,
    };
    return modalType[config.type];
  }

  const editForm = displayModal(config);

  return (
    <Modal
      isOpen={isShowRegistrationMemberModal}
      style={customStyles}
      contentLabel='Invite Member Modal'
    >
      {editForm}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    config: state.setMemberModalType.config,
  };
};

export default connect(mapStateToProps)(ModalRegistrationMember);
