import { useState, useCallback } from 'react';

export const useSetPage = () => {
  const [pageNumber, setPageNumber] = useState(1);

  const goNext = useCallback(() => {
    setPageNumber(pageNumber + 1);
  }, [pageNumber]);

  const goBack = useCallback(() => {
    setPageNumber(pageNumber - 1);
  }, [pageNumber]);

  return { pageNumber, goNext, goBack };
};
