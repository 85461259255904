import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ThanksComment } from 'javascript/features/thanks/components/ThanksComment';
import { ToggleButton } from 'javascript/features/thanks/components/ToggleButton';
import { useSetPage } from 'javascript/features/thanks/hooks/useSetPage';
import { useThanksComments } from 'javascript/features/thanks/hooks/useThanksComments';
import { DisplayAlertMessage } from 'javascript/components/DisplayAlertMessage';
import { device } from 'javascript/utils/mediaQuery';

const StyledHeader = styled.header`
  display: flex;
  justify-content: flex-start;
  padding: 20px 24px;
  img {
    width: 150px;
  }
`;

const StyledFooter = styled.footer`
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  background: #fff;
  width: 100%;
  padding: 24px 16px 8px 0;
  img {
    width: 100px;
  }
  @media ${device.sp} {
    display: none;
  }
`;

const StyledThanksBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 111px);
  padding: 32px 16vw;
  background-color: #f6f6f6;
  overflow: scroll;
  @media ${device.sp} {
    height: calc(100vh - 44px);
    padding: 40px 16px;
  }
`;

const StyledThanksTitleWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media ${device.sp} {
    position: static;
    flex-direction: column;
  }
`;

const StyledThanksListWrapper = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(4, 226px);
  column-gap: 24px;
  row-gap: 24px;
  width: 100%;
  margin-top: 24px;
  @media ${device.sp} {
    grid-template-columns: repeat(2, 43vw);
    gap: 12px;
  }
`;

const StyledPaginateWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
  margin-top: 32px;
  font-size: 12px;
  p {
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      color: #000;
      font-weight: bold;
    }
  }
  @media ${device.sp} {
    p {
      transition: none;
      &:hover {
        color: #707070;
        font-weight: normal;
      }
    }
  }
`;

interface Props {
  officeName: string;
  isLoggedInUser?: boolean;
}

export const ThanksTemplate: React.FC<Props> = ({
  officeName,
  isLoggedInUser = false,
}) => {
  const { t } = useTranslation();
  // @ts-ignore
  const currentUser = useSelector((state) => state.setUser.currentUser);
  const isMaster = currentUser.role === 'master';

  const { pageNumber, goNext, goBack } = useSetPage();
  const { thanksComments } = useThanksComments(officeName, pageNumber);

  if (thanksComments && thanksComments.type === 'error') {
    return (
      <DisplayAlertMessage>
        {t('GenelalAlertNotauthorized', {})}
      </DisplayAlertMessage>
    );
  }

  return (
    <>
      {!isLoggedInUser && (
        <StyledHeader>
          <div>
            <a href='https://viket-town.com' target='_blank'>
              {/* @ts-ignore */}
              <img src={RailsAssetPath('viket-town-header-logo.png')} />
            </a>
          </div>
        </StyledHeader>
      )}
      <StyledThanksBodyWrapper>
        <StyledThanksTitleWrapper>
          <p>{t('ThanksPageTitle', {})}</p>
          {isMaster && thanksComments?.thanks_open !== undefined && (
            <ToggleButton
              officeName={officeName}
              pageNumber={pageNumber}
              isOpen={thanksComments.thanks_open}
            />
          )}
        </StyledThanksTitleWrapper>
        <StyledThanksListWrapper>
          {thanksComments &&
            thanksComments.comments?.length !== 0 &&
            thanksComments.comments.map((comment) => (
              <ThanksComment key={comment.id} thanksComment={comment} />
            ))}
        </StyledThanksListWrapper>
        <StyledPaginateWrapper>
          {thanksComments && pageNumber !== 1 && (
            <p onClick={goBack}>{t('ThanksPageBack', {})}</p>
          )}
          {thanksComments && pageNumber !== thanksComments.total_pages && (
            <p onClick={goNext}>{t('ThanksPageNext', {})}</p>
          )}
        </StyledPaginateWrapper>
      </StyledThanksBodyWrapper>
      {!isLoggedInUser && (
        <StyledFooter>
          <div>
            <img
              // @ts-ignore
              src={RailsAssetPath('viket-town-footer-logo.png')}
              width={100}
            />
          </div>
        </StyledFooter>
      )}
    </>
  );
};
