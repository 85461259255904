import React,{useEffect, useState, useCallback} from 'react';
import axios from 'axios';
import {
    DialogContent,
    Typography,
    Divider,
    Dialog,
    DialogActions,
    Button,
    Theme,
    DialogTitle,
    IconButton,
    Grid,
  } from '@material-ui/core';
  import { backgroundConfig } from './hooks/useBackgroundSettings';
  import useVideoCallContext from './hooks/useVideoCallContext';
  import BackgroundThumbnail from './BackgroundThumbnail';
  import { useMediaQuery } from 'react-responsive';
  import { CUSTOM_BACKGROUND_KEY } from './constants';
  import { useTranslation } from 'react-i18next';

  const virtualbackgroundDialog = ({ open, onClose }) => {
    const { isBackgroundSelectionOpen, setIsBackgroundSelectionOpen , backgroundSettings, setBackgroundSettings} = useVideoCallContext();
    const images = backgroundConfig.images;
    const isMobile = useMediaQuery({ maxWidth: 430 });
    const [customBackgrounds, setCustomBackgrounds] = useState(window.localStorage.getItem(CUSTOM_BACKGROUND_KEY) ? JSON.parse(window.localStorage.getItem(CUSTOM_BACKGROUND_KEY)) : []);
    const { t } = useTranslation();

    const uploadBackgroundImage = e => {
      const file = e.target.files[0];
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      reader.onloadend = () => {
        fileUpload(file)
      } 
    }
    const fileUpload = async (image) => {
      const formData = new FormData();
      formData.append('image', image);
      const url = '/api/v1/twilio/videocalls/virtualbackground_upload';
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        },
      }
      await axios.post(url, formData, config).then((res)=>{
        setCustomBackgrounds([...customBackgrounds, res.data]);
        window.localStorage.setItem(CUSTOM_BACKGROUND_KEY,JSON.stringify([...customBackgrounds, res.data]));
      })
    }
   
    const handleDelete = async (id) => {
      const url = '/api/v1/twilio/videocalls/virtualbackground_delete';
      await axios.delete(url, {id: id}).then((res)=>{
        let newItems = [...customBackgrounds];
        newItems.splice(newItems.findIndex(e => e.id === id),1);
        setCustomBackgrounds(newItems);
        if (backgroundSettings.index == id) {
          setBackgroundSettings({type: 'none'})
        } 
        window.localStorage.setItem(CUSTOM_BACKGROUND_KEY,JSON.stringify(newItems));
      })
    }

    return (
      <Dialog maxWidth={isMobile ? 'sm' : 'md'} fullWidth={false} open={open} onClose={onClose}>
        <DialogTitle>
          <Grid container>
            <Grid container item justify='flex-start' xs={1}>
              <IconButton size="small" aria-label="close" onClick={onClose}>
                <img src={RailsAssetPath('close-img-gray.png')} />
              </IconButton>
            </Grid>
            <Grid container item justify='center' style={{'fontSize': '16px'}}>
              {t("VideocallTextSelectVirtualBackground",{})}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
        <Grid container justify={'center'}>
          <BackgroundThumbnail
              thumbnail={'none'}
              imagePath={images[0]}
            />
          {images.map((image, index) => (
            <BackgroundThumbnail
              thumbnail={'image'}
              index={index}
              imagePath={image}
              key={image}
            />
          ))}
          {customBackgrounds.map((item, index) => {
          return (
            <BackgroundThumbnail
              thumbnail={'custom'}
              index={item.id}
              imagePath={item.thumbnail}
              key={item.url}
              url={item.url}
              handleDelete={()=>handleDelete(item.id)}
            />
            )          
          }
          )} 
          <div style={{'width':'240px'}} ></div>
          <div style={{'width':'240px'}} ></div>
        </Grid>


        </DialogContent>
        <DialogActions>
        <Grid container justify={'flex-end'} alignItems={'center'} style={{'marginRight':'30px','cursor': 'pointer'}}>
            <Grid item style={{'marginRight': '10px'}}>
              <label htmlFor="fileUpload" style={{'cursor': 'pointer'}}>
                <img src={RailsAssetPath("video_call/icon/plus.png")} style={{'width':'20px'}}/> 
              </label>
            </Grid>
            <Grid item>
              <label htmlFor="fileUpload" style={{'cursor': 'pointer'}}>
                {t("VideocallTextAddImage",{})}
              </label>
            </Grid>
            <input type="file" accept="image/*" id="fileUpload" className="" style={{ 'display': 'none' }} onChange={uploadBackgroundImage} />
         </Grid>
        </DialogActions>

       </Dialog>
    )
  }
  export default virtualbackgroundDialog;