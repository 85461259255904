import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { device } from 'javascript/utils/mediaQuery';
import { SecretChatModalHeader } from 'javascript/features/secretChat/components/SecretChatModalHeader';
import { SecretChatInputForm } from 'javascript/features/secretChat/components/SecretChatInputForm';
import { SecretChatMessageList } from 'javascript/features/secretChat/components/SecretChatMessageList';
import { useSecretChat } from 'javascript/features/secretChat/hooks/useSecretChat';
import { SecretChat, SecretChatMember } from 'types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface Props {
  ownSecretChat: SecretChat;
  users: SecretChatMember[];
  currentAvatar: SecretChatMember;
  index: number;
  chatType?: string;
  interactWorldId: number | undefined;
}

export const SecretChatModal: React.FC<Props> = ({
  ownSecretChat,
  users,
  currentAvatar,
  index,
  interactWorldId,
  // chatType,
}) => {
  const isWorld = interactWorldId ? true : false;
  const { handleDeleteChatMember } = useSecretChat(isWorld, interactWorldId);
  const isHost = ownSecretChat.host_avatar_id === currentAvatar.avatar_id;
  const { t } = useTranslation();

  // @ts-ignore
  const currentUser = useSelector((state) => state.setUser.currentUser);

  const handleLeaveRoom = useCallback(() => {
    const confirm = window.confirm(t('GenelalConfirmWantLeave', {}));
    if (confirm) {
      handleDeleteChatMember(
        currentAvatar.avatar_id,
        ownSecretChat.avatar_chat_secret_room_id
      );
    }
  }, [handleDeleteChatMember, isHost]);

  useEffect(() => {
    const sound = new Audio('/assets/freeSpace/created_secret_chat.mp3');
    const isSoundAlreadyRung = currentAvatar.sound_completed_flg;
    !isSoundAlreadyRung && currentUser.speaker && sound.play();
  }, [currentAvatar.sound_completed_flg, currentUser.speaker]);

  return (
    // focus擬似要素を効かせるため、tabIndexを設定
    <DirectCallWindow index={index} tabIndex={0}>
      <DirectCallWindowClose onClick={handleLeaveRoom}>
        {/* @ts-ignore */}
        <img src={RailsAssetPath('close-img-white.png')} />
      </DirectCallWindowClose>
      <SecretChatModalHeader
        avatars={users}
        currentAvatarId={currentAvatar.avatar_id}
        ownSecretChatId={ownSecretChat.avatar_chat_secret_room_id}
        isHost={isHost}
        handleDeleteChatMember={handleDeleteChatMember}
      />
      <SecretChatMessageList
        messageList={ownSecretChat.avatar_chat_secret_items}
        currentAvatarId={currentAvatar.avatar_id}
      />
      <SecretChatInputForm
        currentAvatar={currentAvatar}
        ownSecretChat={ownSecretChat}
      />
    </DirectCallWindow>
  );
};

const DirectCallWindow = styled.div<{ index: number }>`
  // 16個目以降のモーダルは表示しない
  display: ${({ index }) => (index < 15 ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 330px;
  height: 420px;
  right: ${({ index }) =>
    index < 5 ? '35px' : index < 10 ? '380px' : '725px'};
  bottom: ${({ index }) => `${45 + (index % 5) * 32}px`};
  position: fixed;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 160, 233, 0.02);
  border-radius: 8px;
  box-shadow: 0 2px 10px 1px rgb(0 0 0 / 20%);
  z-index: ${({ index }) => `${100 - index}`};
  &:focus-within {
    z-index: 101;
  }
  &:focus-visible {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  @media ${device.sp} {
    // 4つ目以降のモーダルは表示しない
    display: ${({ index }) => (index < 3 ? 'flex' : 'none')};
    left: 50%;
    right: 0;
    bottom: ${({ index }) => `${28 + index * 5}vh`};
    transform: translate(-50%, 0);
  }
`;

const DirectCallWindowClose = styled.button`
  position: absolute;
  left: -8px;
  top: -8px;
  width: 20px;
  height: 20px;
  background-color: #a9aeaf;
  border-radius: 50%;
  box-shadow: 0 0 4px #a9aeaf;
  img {
    width: 8px;
  }
`;
