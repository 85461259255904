import { useContext } from 'react';
import { VideoCallContext } from '../VideoCallProvider';

const useVideoCallContext = () => {
  const context = useContext(VideoCallContext);
  if (!context) {
    throw new Error('VideoCallContext must be used within a VideoCallProvdier');
  }
  return context;
}

export default useVideoCallContext;