

import React , { useCallback } from 'react';
import { UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';
import {
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';
const MAX_QUANTITY = 99;

const StyledQuantity = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  position: relative;
  left: 18px;
  justify-content: flex-start;
`;

const StyledQuantityButton = styled(IconButton)`
  height: 30px;
  transform: scale(.8);
  span {
    color: #000;
  }
`;

const StyledQuantityTextField = styled(TextField)`
  width: 140px;
  input {
    text-align:center;
    padding: 7px;
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      -moz-appearance:textfield;
    } 
  
  }
`

interface Props {
  quantity: number;
  updateQuantity: (quantity: number) => void; 
  register: UseFormRegister<any>;
  disabled: boolean; 
}

export const InputQuantity: React.FC<Props> = ({
  quantity,
  updateQuantity,
  register,
  disabled,
}) => {
  // const [ quantity, setQuantity ] = useState<number>(1);

  const increaseQuantity = useCallback(() => {
    if (quantity + 1 > MAX_QUANTITY || disabled) return;
    updateQuantity(quantity + 1);
  },[quantity])

  const decreaseQuantity = useCallback(() => {
    if(disabled) return;
    if (quantity > 1) {
      updateQuantity(quantity - 1);
    }
  },[quantity])

  return (
    <StyledQuantity>
    <Grid
        container
        item
        xs={4}
        direction='row'
        alignItems='center'
        justifyContent='center'
      >

        <Grid container item xs={12} justifyContent='center'>
          <StyledQuantityButton onClick={increaseQuantity}>▲</StyledQuantityButton>
        </Grid>
        <Grid container item xs={10} justifyContent='center'>
          <StyledQuantityTextField
            id='outlined-basic'
            size='small'
            // name='quantity'
            inputProps={{ min: 1, max: MAX_QUANTITY }}
            color='primary'
            variant='outlined'
            type='number'
            value={quantity}
            required
            disabled={disabled}
            {...register("quantity", {
              required: true,
              valueAsNumber: true,
              onChange: (e) => {
                if (parseInt(e.target.value) > MAX_QUANTITY) {
                  updateQuantity(MAX_QUANTITY)
                } else {
                  updateQuantity(parseInt(e.target.value))
                }
              },
            })}
          />
        </Grid>
        <Grid container item xs={12} justifyContent='center'>
          <StyledQuantityButton onClick={decreaseQuantity}>▼</StyledQuantityButton>
        </Grid>
      </Grid>
    </StyledQuantity>
  )

};