import React, { useState, useEffect, useCallback, useGlobal} from "reactn";

const useCardInput = () => {
  const [cardInputComplete, setCardInputComplete] = useGlobal('paymentCardInputComplete'
  )
  useEffect(() => {
    setCardInputComplete({...cardInputComplete, 
      number: false,
      cvc: false,
      expire: false,
      name: "",
      country:"JP",

    } ) 
  },[])
  
  return [cardInputComplete, setCardInputComplete ]
}


export default useCardInput;