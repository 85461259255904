import React from 'react';
import { connect } from 'react-redux';
import { UserProfile } from './components/UserProfile';
import styled from 'styled-components';
import { device } from '../../../utils/mediaQuery';
import { WorkButtonArea } from './components/WorkButtonArea';
import { HeaderNavMenu } from './components/HeaderNavMenu';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  padding: 16px 28px 16px 16px;
  position: fixed;
  top: 0;
  z-index: 3;
  background: #fff;
  @media ${device.tab} {
    padding: 12px;
  }
  @media ${device.sp} {
    height: 44px;
    padding: 12px;
  }
`;

const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
  max-width: 688px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media ${device.sp} {
    max-width: none;
    overflow-x: hidden;
  }
`;

const HeaderOfficeName = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  padding: 4px 16px 4px 0;
  width: 150px;
  border-right: 2px solid #f6f6f6;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  @media ${device.tab} {
    width: 100px;
  }
  @media ${device.sp} {
    width: 60px;
    padding: 0 8px 0 0;
    border: none;
    font-size: 12px;
  }
`;
const RemorksLogo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 20px;
`;

const Header = ({ currentUser, videoConnectStatus }) => {
  const isNoHeader = location.pathname.includes('buy');
  return (
    <HeaderWrapper>
      {!isNoHeader ? (
        <>
          <HeaderMenu>
            <HeaderOfficeName>{currentUser?.office?.name}</HeaderOfficeName>
            {currentUser.role !== 'non_active' && (
              <WorkButtonArea
                videoConnectStatus={videoConnectStatus}
                userStatus={currentUser.work_status}
              />
            )}
          </HeaderMenu>
          <HeaderMenu>
            {currentUser.role && (
              <HeaderNavMenu
                isCast={currentUser.role === 'cast'}
                isNonActiveUser={currentUser.role === 'non_active'}
                isMaster={currentUser.role === 'master'}
              />
            )}
            <UserProfile currentUser={currentUser} />
          </HeaderMenu>
        </>
      ) : (
        <RemorksLogo>
          <img
            src={RailsAssetPath('viket-town-header-logo.png')}
            alt=''
            className='header-title__logo'
          />
        </RemorksLogo>
      )}
    </HeaderWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.setUser.currentUser,
  };
};

export default connect(mapStateToProps)(Header);
