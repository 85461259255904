import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RealWorldMemberBox } from 'javascript/features/realWorld/components/RealWorldMemberBox';
import { RealWorldTitle } from 'javascript/features/realWorld/components/RealWorldTitle';
import { useEnterRealWorld } from 'javascript/features/realWorld/hooks/useEnterRealWorld';
import { device } from 'javascript/utils/mediaQuery';
import { RealWorld } from 'types';

interface Props {
  realWorld: RealWorld;
  videoConnectStatus: () => boolean;
}

export const RealWorldItem: React.FC<Props> = ({
  realWorld,
  videoConnectStatus,
}) => {
  const { enterRealWorld } = useEnterRealWorld(realWorld, videoConnectStatus);

  // TODO 型付け
  // @ts-ignore
  const currentUser = useSelector((state) => state.setUser.currentUser);

  const hasCurrentUser = useMemo(
    () =>
      realWorld.users.findIndex((user) => user.id === currentUser.id) !== -1,
    [realWorld.users, currentUser.id]
  );

  return (
    <RealWorldItemWrapper
      hasCurrentUser={hasCurrentUser}
      onClick={enterRealWorld}
    >
      <RealWorldTitle realWorld={realWorld} />
      <RealWorldMemberWrapper>
        <RealWorldMemberBox realWorld={realWorld} isCast={currentUser.role == "cast"}/>
      </RealWorldMemberWrapper>
    </RealWorldItemWrapper>
  );
};

const RealWorldItemWrapper = styled.div<{ hasCurrentUser: boolean }>`
  width: 100%;
  padding: 8px 12px;
  background-color: #f2f2f2;
  border-radius: 8px;
  margin-bottom: 4px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  ${({ hasCurrentUser }) =>
    hasCurrentUser
      ? `
    box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 5%), 4px 4px 4px 0px rgb(0 0 0 / 5%);
  `
      : `
    &:hover {
      box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 5%), 4px 4px 4px 0px rgb(0 0 0 / 5%);
    }
  `}
  @media ${device.sp} {
    padding: 5px 10px;
    &:hover {
      transform: none;
      box-shadow: none;
    }
  }
`;

const RealWorldMemberWrapper = styled.div`
  margin-top: 0;
`;
