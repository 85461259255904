import React from 'react';
import styled from 'styled-components';
import { SubRoomMemberBox } from 'javascript/features/subRooms/components/SubRoomMemberBox';
import { SubRoomTitle } from 'javascript/features/subRooms/components/SubRoomTitle';
import { useMoveToSleep } from 'javascript/features/subRooms/hooks/useMoveToSleep';
import { device } from 'javascript/utils/mediaQuery';
import { DayOff, Sleep } from 'types';

interface Props {
  room: Sleep | DayOff;
  videoConnectStatus: () => boolean;
  handleOnClick?: (e: React.MouseEvent) => void;
  isHiddenSubRoom?: boolean;
  isCast: boolean;
}

/** おやすみ　ラウンジ **/
export const OfficeSubRoom: React.FC<Props> = ({
  room,
  videoConnectStatus,
  handleOnClick,
  isHiddenSubRoom,
  isCast
}) => {
  const { moveToSleep } = useMoveToSleep(videoConnectStatus);

  const isSleep = room.type === 'lunge';
  const memberNum = room.users.length;

  return (
    <SubRoomContainer
      isSleep={isSleep}
      onClick={isSleep && !room.enter_flg ? moveToSleep : undefined}
    >
      <SubRoomTitle
        roomType={room.type}
        memberNum={memberNum}
        handleOnClick={handleOnClick}
        isHiddenSubRoom={isHiddenSubRoom}
      />
      <SubRoomMemberBox users={room.users} isCast={isCast} />
    </SubRoomContainer>
  );
};

const SubRoomContainer = styled.div<{ isSleep: boolean }>`
  position: relative;
  ${({ isSleep }) =>
    isSleep &&
    `
    cursor: pointer;
    padding-bottom: 8px;
  `}
  &:not(:first-child) {
    border-top: 2px solid #f2f2f2;
    padding-top: 12px;
  }
  @media ${device.sp} {
    height: 56px;
    &:not(:first-child) {
      border-top: 2px solid #f2f2f2;
      margin-bottom: 16px;
      padding: 8px 0;
    }
  }
`;
