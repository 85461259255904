import { useState, useCallback } from "react";
import axios from "axios";
import useVideoCallContext from "../hooks/useVideoCallContext";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

const useLeaveCall = () => {

  const [state, setState] = useState(false);
  const currentUser = useSelector((state) => state.setUser.currentUser);
  const isGuest = useSelector((state) => state.videoCall.isGuest);
  const roomId = useSelector((state) => state.videoCall.roomId);
  const { t } = useTranslation();

  const setIsDisconnecting = useCallback(() => {
    setState(true);
  });

  const leave = useCallback(async room => {
    const confirm = window.confirm(t("GenelalConfirmWantLeave",{}));
    if (confirm) {
      setIsDisconnecting();
      await room.disconnect();
      await leaveApi().then(res => {
        // location.href = '/office';
      });
    } else {
      return null;
    }
  });

  const leaveApi = () => {
    let params = {
      is_guest: isGuest,
      room_id: roomId
    }
    return axios.post('/api/v1/twilio/videocalls/room_leave',params); 
  }

  const isDisconnecting = state;

  return { leave, leaveApi, isDisconnecting, setIsDisconnecting };
}

export default useLeaveCall;