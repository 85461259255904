export const SORT_TYPE = [
  { type: 1, text: 'ShopSortNew' },
  { type: 2, text: 'ShopSortPopular' },
  { type: 3, text: 'ShopSortExpensive' },
  { type: 4, text: 'ShopSortCheap' },
  { type: 5, text: 'ShopSortOwn' },
];

export const FILTER_TYPE = [
  { type: 1, text: '購入一覧' }, // TODO: i18n
  { type: 2, text: '受注一覧' }, // TODO: i18n
];
