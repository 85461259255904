import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { device } from 'javascript/utils/mediaQuery';
import { StatusType, UserAvatar } from 'types';

interface Props {
  isOpen: boolean;
  type: StatusType;
  items: { description: string; position: number }[];
  popupRef: React.RefObject<HTMLDivElement>;
  handleSelectType: (type: StatusType, num: number) => void;
  avatar?: Omit<UserAvatar, 'id'>;
}

export const StatusPopup: React.FC<Props> = ({
  isOpen,
  type,
  items,
  popupRef,
  handleSelectType,
  avatar,
}) => {
  const { t } = useTranslation();
  // @ts-ignore
  const currentUser = useSelector((state) => state.setUser.currentUser);
  const currentLanguage = currentUser.current_language;

  return (
    <StyledStatusPopup isOpen={isOpen} ref={popupRef}>
      <StyledWordWrapper>
        <p>{t('EquipmentTextSelect', {})}</p>
      </StyledWordWrapper>
      <StatusItemWrapper>
        {items.map((item, i) => (
          <StatusItem
            key={`${type}_${i}`}
            isJapaneseUsed={currentLanguage === 'ja'}
            position={item.position}
            onClick={() => handleSelectType(type, i)}
            isSelectedStatus={
              type === 'summon'
                ? avatar?.summon === i
                : type === 'item'
                ? avatar?.item === i
                : avatar?.defense === i
            }
          >
            <button>
              {/* @ts-ignore */}
              <img src={RailsAssetPath(`freeSpace/status/${type}/${i}.png`)} />
            </button>
            <div>
              <p>{t(item.description)}</p>
            </div>
          </StatusItem>
        ))}
      </StatusItemWrapper>
    </StyledStatusPopup>
  );
};

const StyledStatusPopup = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: -62px;
  left: 68px;
  z-index: 2;
  height: 56px;
  background-color: rgba(112, 112, 112, 0.5);
  border-radius: 8px;
  border: 1px solid #d96161;
  @media ${device.sp} {
    left: 14px;
  }
`;

const StyledWordWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 10px;
  background-color: #d96161;
  border-radius: 8px;
  transform: translateY(-50%);
  p {
    font-size: 10px;
    color: #fff;
    white-space: nowrap;
    transform: scale(0.6);
  }
`;

const StatusItemWrapper = styled.div`
  display: flex;
  max-width: 392px;
  height: 100%;
  margin-top: -12px;
  padding: 10px 8px 6px;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media ${device.sp} {
    max-width: 90vw;
    padding: 10px 10px 6px 4px;
  }
`;

const StatusItem = styled.div<{
  position: number;
  isSelectedStatus: boolean;
  isJapaneseUsed: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 52px;
  cursor: pointer;
  @media ${device.sp} {
    width: 48px;
  }
  button {
    width: 52px;
    @media ${device.sp} {
      width: 48px;
    }
    img {
      opacity: ${({ isSelectedStatus }) => (isSelectedStatus ? '1' : '0.4')};
      width: 30px;
      height: 30px;
    }
  }
  p {
    margin-left: ${({ position }) => `${position}px`};
    font-size: 10px;
    color: ${({ isSelectedStatus }) => (isSelectedStatus ? '#fff' : '#cfcfcf')};
    overflow: ${({ isJapaneseUsed }) => !isJapaneseUsed && 'hidden'};
    text-overflow: ${({ isJapaneseUsed }) => !isJapaneseUsed && 'ellipsis'};
    white-space: nowrap;
    transform: scale(0.7);
  }
  &:first-child {
    button {
      margin: 16px 0 9px;
      img {
        width: 4px;
        height: 4px;
      }
    }
  }
`;
