import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { sp } from '../../utils/mediaQuery';
import { isChrome } from 'javascript/utils/isChrome';

const FooterWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  background: #fff;
  width: 100%;
  padding: 24px 16px 8px 0;
  ${sp`
    display: none;
  `}
`;

const StyledNotChromeAlert = styled.p`
  margin-right: 32px;
  font-size: 10px;
  font-weight: bold;
  color: #f00;
`;

const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterWrapper>
      {!isChrome && (
        <StyledNotChromeAlert>
          {t('GenelalAlertOnlyChrome', {})}
        </StyledNotChromeAlert>
      )}
      <div>
        {/* @ts-ignore */}
        <img src={RailsAssetPath('viket-town-footer-logo.png')} width={100} />
      </div>
    </FooterWrapper>
  );
};

export default Footer;
