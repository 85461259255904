import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useGlobal,
} from 'reactn';
import styled from 'styled-components';
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
  Elements,
} from '@stripe/react-stripe-js';
import {
  Typography,
  Container,
  Grid,
  Button,
  Theme,
  Hidden,
  InputLabel,
  TextField,
} from '@material-ui/core';
import usePaymentContext from '../hooks/usePaymentContext';
import { useTranslation } from 'react-i18next';

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '16px',
          iconColor: '#c4f0ff',
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#9E9E9E',
          },
          border: '1px solid #000',
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    []
  );
  return options;
};

const InputCardNumber = () => {
  const { t } = useTranslation();
  const options = useOptions();
  const {
    paymentConfig,
    fetchConfig,
    cardInputComplete,
    setCardInputComplete,
  } = usePaymentContext();

  const [name, setName] = useState(null);

  const countries = useMemo(() => {
    if (paymentConfig) {
      return paymentConfig.countries;
    }
    return [];
  }, [paymentConfig]);

  return (
    <Container maxWidth='sm'>
      <Grid container direction='column' justify='center' spacing={2}>
        <Grid container item>
          <Grid item xs={12}>
            <Typography variant='h6'>
              {t('PaymentTextCardInfo', {})}
            </Typography>
          </Grid>
          <InputWrapper item xs={12}>
            <CardNumber item xs={12}>
              <CardNumberElement
                options={options}
                onChange={(event) => {
                  setCardInputComplete({
                    ...cardInputComplete,
                    number: event.complete,
                  });
                }}
              />
            </CardNumber>
            <Grid
              container
              item
              spacing={0}
              xs={12}
              justify='center'
              alignItems='center'
            >
              <CardExpiry item xs={6}>
                <CardExpiryElement
                  options={options}
                  onChange={(event) => {
                    setCardInputComplete({
                      ...cardInputComplete,
                      expire: event.complete,
                    });
                  }}
                />
              </CardExpiry>
              <CardCvcNumber item xs={6}>
                <CardCvcElement
                  options={options}
                  onChange={(event) => {
                    setCardInputComplete({
                      ...cardInputComplete,
                      cvc: event.complete,
                    });
                  }}
                />
              </CardCvcNumber>
            </Grid>
          </InputWrapper>
        </Grid>
        <Grid container item>
          <Grid item xs={12}>
            <Typography variant='h6'>
              {t('PaymentTextCardHolderName', {})}
            </Typography>
          </Grid>
          <InputWrapper item xs={12}>
            <Input
              name='name'
              type='text'
              required
              onChange={(event) => {
                setCardInputComplete({
                  ...cardInputComplete,
                  name: event.target.value,
                });
              }}
            />
          </InputWrapper>
        </Grid>

        <Grid container item>
          <Grid item xs={12}>
            <Typography variant='h6'>
              {t('PaymentTextCardCountryRegion', {})}
            </Typography>
          </Grid>
          <InputWrapper item xs={12}>
            <Select
              name='country'
              type='text'
              required
              onChange={(event) => {
                setCardInputComplete({
                  ...cardInputComplete,
                  country: event.target.value,
                });
              }}
            >
              {countries.map((item) => (
                <option key={item[0]} value={item[0]}>
                  {item[1]}
                </option>
              ))}
            </Select>
          </InputWrapper>
        </Grid>
      </Grid>
    </Container>
  );
};

const FormFieldGroup = styled.div`
  margin: 4px 0 0;
  border: none;
  padding: 0;
`;

const FormFieldGroupContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`;
const FormFieldGroupChild = styled.div`
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  min-width: 0;
  max-width: 100%;
  -webkit-transform-origin: '0%';
  -ms-transform-origin: '0%';
  transform-origin: '0%';
  min-width: 100px;
`;

const InputWrapper = styled(Grid)`
  box-shadow: 0px 0px 2px 0px #9e9e9e;
  border-radius: 5px;
`;
const Input = styled.input`
  border: solid 1px #9e9e9e;
  border-radius: 5px;
  padding: 8px;
  min-width: 100%;
`;

const CardNumber = styled(Grid)`
  border: 1px solid #9e9e9e;
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -7px;
    background-image: url(${RailsAssetPath('payment/icon/card_5brand.png')});
    background-size: contain;
    background-repeat: no-repeat;
    width: 30%;
    height: 100%;
  }
`;
const CardExpiry = styled(Grid)`
  border: 1px solid #9e9e9e;
  borderbottomleftradius: 5px;
  padding: 10px;
`;
const CardCvcNumber = styled(Grid)`
  border: 1px solid #9e9e9e;
  borderleft: none;
  borderbottomrightradius: 5px;
  padding: 10px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -7px;
    background-image: url(${RailsAssetPath('payment/icon/cvc.png')});
    background-size: contain;
    background-repeat: no-repeat;
    width: 10%;
    height: 100%;
  }
`;
const Select = styled.select`
  border: solid 1px #9e9e9e;
  border-radius: 5px;
  padding: 8px;
  min-width: 100%;
`;
export default InputCardNumber;
