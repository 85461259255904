const onScrollList: { key: HTMLDivElement | null; listener: () => void }[] = [];

function getTargetY(ref: HTMLDivElement | null) {
  if (!ref) {
    return 0;
  }
  const elementOffsetTop = ref.getBoundingClientRect().top + window.pageYOffset;
  return elementOffsetTop;
}

function isScrolled(
  windowHeightCofficient: number,
  targetY: number,
  scrollTop: number = 0
) {
  return scrollTop + window.innerHeight * windowHeightCofficient > targetY;
}

export function onScrollPromise(
  ref: HTMLDivElement | null,
  windowHeightCofficient = 0.85
) {
  return () => {
    return new Promise<void>((resolve) => {
      const targetY = getTargetY(ref);

      function onScroll() {
        const scrollTop = window.scrollY;
        if (isScrolled(windowHeightCofficient, targetY, scrollTop)) {
          window.removeEventListener('scroll', onScroll);
          resolve();
        }
      }

      // スクロールする前にすでに表示領域に入っているものはイベント登録せずにresolveを返す
      if (isScrolled(windowHeightCofficient, targetY)) {
        resolve();
        return;
      }

      // 登録されたイベントを配列に追加
      window.addEventListener('scroll', onScroll);
      onScrollList.push({
        key: ref,
        listener: onScroll,
      });
    });
  };
}

export function removeOnscrollEvent(ref: HTMLDivElement | null) {
  const target = onScrollList.find((list) => list.key === ref);
  if (target) {
    window.removeEventListener('scroll', target.listener);
    onScrollList.splice(onScrollList.indexOf(target), 1);
  }
}
