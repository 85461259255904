import { useCallback } from 'react';
import { useGlobal } from 'reactn';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import useVoiceContext from 'javascript/components/voice/hooks/useVoiceContext';
import { useGetOfficeData } from 'javascript/features/realWorld/hooks/useGetOfficeData';

export const useEnterOrLeaveRoom = (
  virtualWorld,
  isVideoOnline,
  hasCurrentUser,
  directCallNow
) => {
  const { room, leave } = useVoiceContext();
  const [_, setSelectedRoom] = useGlobal('selectedRoom');
  const [isShowEnterOfficeModal, setIsShowEnterOfficeModal] = useGlobal(
    'isShowEnterOfficeModal'
  );
  const { getOfficeData } = useGetOfficeData();
  const { t } = useTranslation();
  const enterOrLeaveRoom = useCallback(() => {
    if (virtualWorld.room_type === 'nonfunctional' && hasCurrentUser) {
      const confirm = window.confirm(t('GenelalConfirmWantLeave', {}));
      if (confirm) {
        axios
          .post('/api/v1/office/rooms/normal_room_out')
          .then(() => getOfficeData());
        return;
      } else {
        return;
      }
    }
    if (hasCurrentUser && room) {
      const confirm = window.confirm(t('GenelalConfirmWantLeave', {}));
      if (confirm) {
        leave(room, true).then(() => (location.href = '/office'));
      }
      return;
    }
    if (!virtualWorld.enter_flg) {
      if (room || (isVideoOnline && virtualWorld.room_type != 'video')) {
        if (virtualWorld.room_type == 'nonfunctional' && directCallNow ) {
          // 個別通話時はタイム部屋のみ入室可能
          setSelectedRoom(virtualWorld.id);
          setIsShowEnterOfficeModal(true);
        } else {
          alert(t('GenelalAlertCallNow', {}));
          return;
        }

      } else {
        setSelectedRoom(virtualWorld.id);
        setIsShowEnterOfficeModal(true);
      }
    } else if (virtualWorld.room_type == 'video') {
      setSelectedRoom(virtualWorld.id);
      setIsShowEnterOfficeModal(true);
    }
  }, [
    virtualWorld.room_type,
    virtualWorld.enter_flg,
    room,
    isVideoOnline,
    hasCurrentUser,
    directCallNow,
  ]);

  return { enterOrLeaveRoom };
};
