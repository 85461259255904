import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useGlobal } from 'reactn';

import FormAddPaidHoliday from '../form/FormAddPaidHoliday';
Modal.setAppElement('body');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: '0px',
    width: '500px',
    background: '#fff',
  },
  overlay: {
    backgroundColor: 'transparent',
    zIndex: '100',
  },
};

const ModalAddPaidHoliday = () => {
  const [isShowAddPaidHolidayFormShow, setIsShowAddPaidHolidayFormShow] =
    useGlobal('isShowAddPaidHolidayFormShow');
  function openModal() {
    setIsShowAddPaidHolidayFormShow(true);
  }
  function closeModal() {
    setIsShowAddPaidHolidayFormShow(false);
  }

  return (
    <Modal
      isOpen={isShowAddPaidHolidayFormShow}
      //onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='add paid holiday modal'
    >
      <FormAddPaidHoliday />
    </Modal>
  );
};

export default ModalAddPaidHoliday;
