import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import IntroContainer from './IntroContainer';
import { sp } from '../../utils/mediaQuery';
import { useTranslation } from 'react-i18next';

const RoomNotFound = () => {
  const { t } = useTranslation();

  return (
    <IntroContainer>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={4} justify='center' alignItems='center'>
            <Logo>
              <img
                src={RailsAssetPath('viket-town-header-logo.png')}
                alt=''
                className='header-title__logo'
              />
            </Logo>
            <Grid container item xs={12} direction={'column'} spacing={2}>
              <EndRoom>{t('VideocallText404NotFound', {})}</EndRoom>
            </Grid>
            <Grid container item xs={12} direction={'column'} spacing={2}>
              <EndRoom>{t('VideocallText404NotFound', {})}</EndRoom>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <GlobalMenu>
            <Grid
              container
              direction={'row'}
              spacing={2}
              justify='center'
              alignItems='center'
            >
              <Grid container item xs={8} sm={2}>
                <a href='/office' target='_blank' rel='noopener'>
                  {t('GenelalSettingsSiteName', {})}
                </a>
              </Grid>
              <Grid container item xs={8} sm={2}>
                <a href='https://bit.ly/3hwF1D9' target='_blank' rel='noopener'>
                  {t('VideocallTextContactUs', {})}
                </a>
              </Grid>
              <Grid container item xs={8} sm={2}>
                <a href='https://pla-cole.co/' target='_blank' rel='noopener'>
                  {t('VideocallTextCompany', {})}
                </a>
              </Grid>
            </Grid>
          </GlobalMenu>
        </Grid>
      </Grid>
    </IntroContainer>
  );
};

const Logo = styled.div`
  margin-bottom: 30px;
`;
const EndRoom = styled.div`
  font-size: 18px;
  text-align: center;
  font-weight: bold;
`;
const Notice = styled.div`
  display: inline-block;
  font-size: 10px;
`;

const GlobalMenu = styled.div`
  position: relative;
  display: flex;
  padding: 0 1.43em;
  margin-top: 80px;
  ${sp`
    height: 20px;
    padding: 0;
  `}
  a {
    color: #0fa5e9;
  }
`;

export default RoomNotFound;
