
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const SET_VIDEOCALL_CONNECT_SESSION_KEY = "sessionIsVideoCallConnect";

export const useIsVideoConnect = () => {

  const [isConnected, setIsConnected] = useState(false);
  // officeとvideo_callをまたぐため状態をstorageに保存
  const videoConnectStatus = () => {
   return localStorage.getItem(SET_VIDEOCALL_CONNECT_SESSION_KEY) == "true";
  }
  const checkConnect = () => {
    return axios.get('/api/v1/twilio/videocalls/is_connect').then(res => {
      setIsConnected(res.data.connected);
      localStorage.setItem(SET_VIDEOCALL_CONNECT_SESSION_KEY, res.data.connected)
    }); 
  }

  const connected = () => {
    setIsConnected(true);
    localStorage.setItem(SET_VIDEOCALL_CONNECT_SESSION_KEY, true);
  }

  const disconnected = () => {
    setIsConnected(false);
    localStorage.setItem(SET_VIDEOCALL_CONNECT_SESSION_KEY, false);
  }

  const isVideoConnect = useCallback(() => {
    checkConnect();
    var timerID = setInterval( () => checkConnect(), 10000 );
    return function cleanup() {
      clearInterval(timerID);
    };
  },[])

  useEffect(() => {
    checkConnect();
  }, [])

  const isOtherConnected = videoConnectStatus();
  return  { isOtherConnected, checkConnect, videoConnectStatus, connected, disconnected, isVideoConnect, isConnected };
}