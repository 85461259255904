import React from "react";
import ParticipantList from "./ParticipantList";

const VoiceChat = () => {

  return (
    <ParticipantList />
  );
}

export default VoiceChat;