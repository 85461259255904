import { useCallback } from 'react';
import { useGlobal } from 'reactn';
import useVoiceContext from '../../../voice/hooks/useVoiceContext';
import { useTranslation } from 'react-i18next';

export const useClickWorkButton = (userStatus, videoConnectStatus) => {
  const [_, setIsShowModal] = useGlobal('isShowModal');

  const { room } = useVoiceContext();
  const { t } = useTranslation();

  const clickStartWorkButton = useCallback(() => {
    if (room || videoConnectStatus()) {
      alert(t('GenelalAlertCallNow', {}));
      return;
    }

    switch (userStatus) {
      case 'not_working': 
        setIsShowModal(true);
        break;
      case 'working':
        alert(t('WorkAlelrtAlreadyWork', {}));
        break;
      case 'finished':
        alert(t('WorkAlertLeftWork', {}));
        break;
      default:
        console.log(userStatus);
    }
  }, [room, userStatus]);

  const clickFinishWorkButton = useCallback(() => {
    if (videoConnectStatus()) {
      alert(t('GenelalAlertCallNow', {}));
      return;
    }

    switch (userStatus) {
      case 'not_working':
        alert(t('WorkAlertNoWork', {}));
        break;
      case 'working':
        setIsShowModal(true);
        break;
      case 'finished':
        alert(t('WorkAlertLeftWork', {}));
        break;
      default:
        console.log(userStatus);
    }
  }, [room, userStatus]);

  return { clickStartWorkButton, clickFinishWorkButton };
};
