import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

interface Props {
  imageList: string[];
  imageNum: number;
  handleSelectImage: (imageIndex: number) => void;
}

export const VirtualWorldImageInput: React.FC<Props> = ({
  imageList,
  imageNum,
  handleSelectImage,
}) => (
  <VirtualWorldImageInputWrapper>
    <p>
      <Trans i18nKey='RoomImageImage' />
    </p>
    <ImageSelectArea>
      {imageList.map((image, i) => (
        <ImageItem
          key={i}
          isSelected={imageNum === i + 1}
          onClick={() => handleSelectImage(i + 1)}
        >
          {/* @ts-ignore */}
          <img src={RailsAssetPath(image)} width={80} height={80} />
        </ImageItem>
      ))}
    </ImageSelectArea>
  </VirtualWorldImageInputWrapper>
);

const VirtualWorldImageInputWrapper = styled.div`
  height: 184px;
  margin-top: 24px;
  p {
    font-size: 16px;
  }
`;

const ImageSelectArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 8px;
  margin-top: 8px;
`;

const ImageItem = styled.div<{ isSelected: boolean }>`
  width: 80px;
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected &&
    `
    border: 2px solid #03A9F4;
    border-radius: 8px;z
  `}
`;
