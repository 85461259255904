import  { useCallback } from 'react';
import { useGlobal } from 'reactn';
import axios from 'axios';
// import { avatarChannel } from 'javascript/channels/avatar_channel';
// import { interactWorldAvatarChannel } from 'javascript/channels/interact_world_avatar_channel';
// import { secretChatChannel } from 'javascript/channels/avatar_chat_secret_channel';
// import { interactWorldsecretChatChannel } from 'javascript/channels/interact_world_chat_secret_channel';

export const useInteractWorld = (
  // currentAvatarId?: number,
  // isWorld?: boolean
) => {
  const [ _, setInteractWorldId ] = useGlobal<{ interactWorldId: number | undefined }>('interactWorldId');
  // const { subscribeInteractWorldAvatarChannel } = interactWorldAvatarChannel();
  // const worldAvatarCable = subscribeInteractWorldAvatarChannel(currentAvatarId, 1)

  const handleJoinChannel = useCallback(
    async (interactWorldId?: number) => {
      return axios
        .post<{ interact_world_id: number }>('/api/v1/interact_world/join', {params: {interact_world_id: interactWorldId}})
        .then((data) => {
          setInteractWorldId(data.data.interact_world_id);
          // avatarCable.destroy();
          // secretChatCable.destroy();
        })
    },[]
  );

  const handleLeaveChannel = useCallback(
    async (interactWorldId?: number) => {
      return axios
        .post('/api/v1/interact_world/leave', {params: {interact_world_id: interactWorldId}})
        .then(() => {
          setInteractWorldId(undefined);
          // worldAvatarCable.destroy();
          // worldSecretChatCable.destroy();
        })
    },[]
  )
  return{
    handleJoinChannel,
    handleLeaveChannel
  };
}