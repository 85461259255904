import React, { useState } from 'react';
import { useGlobal } from 'reactn';
import axios from 'axios';
import styled from 'styled-components';
import CommonAddButton from '../common/CommonAddButton';
import { useTranslation } from 'react-i18next';
import { device } from 'javascript/utils/mediaQuery';

const StyledInviteButton = styled.div`
  padding: 10px 30px;
  border-radius: 5px;
  text-align: center;
  width: 240px;
  margin: auto;
  background: #03a9f4;
  color: white;
  cursor: ${({ isSending }) => (isSending ? 'default' : 'pointer')};
  opacity: ${({ isSending }) => isSending && '0.5'};
  @media ${device.sp} {
    width: 170px;
    padding: 8px 30px;
    font-size: 14px;
  }
`;

const FormInviteMemberForm = () => {
  const [isShowRegistrationMemberModal, setIsShowRegistrationMemberModal] =
    useGlobal('isShowRegistrationMemberModal');
  const [inputList, setInputList] = useState([{ email: '', name: '' }]);
  const { t } = useTranslation();
  const [sending, setSending] = useState(false);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleAddInput = () => {
    setInputList([...inputList, { email: '', name: '' }]);
  };

  const handleSubmit = () => {
    if (sending) return;
    setSending(true);
    const url = '/api/v1/users/invitations';
    axios
      .post(url, { users: inputList })
      .then((res) => {
        alert(t('InviteMessageSentInvite', {}));
        setSending(false);
        setIsShowRegistrationMemberModal(false);
      })
      .catch((error) => {
        const msg = error.response.data.message;
        alert(msg);
        setSending(false);
      });
  };

  return (
    <div className='invite-member'>
      <p className='close__btn--invite btn'>
        <img
          className=''
          src={RailsAssetPath('close-img-gray.png')}
          onClick={() => {
            setIsShowRegistrationMemberModal(false);
          }}
          alt=''
        />
      </p>
      <div className='invite-member__title'>{t('GenelalTextInvite', {})}</div>
      {inputList.map((i, key) => (
        <div className='invite-member__address-wrapper' key={key}>
          <div className='invite-member__input-box input-address'>
            <input
              onChange={(e) => handleInputChange(e, key)}
              className='invite-member__input'
              name='email'
              placeholder={t('GenelalTextEmail', {})}
            />
            <span className='input-reset'></span>
          </div>
          <div className='invite-member__input-box input-name'>
            <input
              onChange={(e) => handleInputChange(e, key)}
              className='invite-member__input'
              name='name'
              placeholder={t('GenelalTextName', {})}
            />
            <span className='input-reset'></span>
          </div>
        </div>
      ))}
      <div onClick={handleAddInput} className='invite-add-row'>
        <CommonAddButton />
        {t('GenelalButtonAdd', {})}
      </div>
      <StyledInviteButton isSending={sending} onClick={handleSubmit}>
        {sending
          ? t('EmployeeCategoryInviting', {})
          : t('GenelalTextInvite', {})}
      </StyledInviteButton>
    </div>
  );
};

export default FormInviteMemberForm;
