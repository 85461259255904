import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledUsedText = styled.p<{ isCompleted: boolean | undefined }>`
  color: #fff;
  background-color: ${({ isCompleted }) => (isCompleted ? '#aaa' : '#ec6a40')};
  min-height: 10px;
  font-size: 10px;
  border-radius: 4px;
  padding: 2px 4px;
  cursor: ${({ isCompleted }) => (isCompleted ? 'default' : 'pointer')};
  &:hover {
    ${({ isCompleted }) =>
    !isCompleted &&
    `
      opacity: 0.7;
      transition: opacity .3s;
    `}
  }
`;

const StyledUsedTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  justify-content: space-around;
`;

interface Props {
  used: boolean;
  handled: boolean;
  usedHandler?: () => void;
  handledHandler?: () => void;
}
export default function MyBoxSkillActionBtns({ used, handled, usedHandler, handledHandler }: Props) {
  const { t } = useTranslation();

  return (
    <StyledUsedTextWrapper>
      <StyledUsedText
        isCompleted={used}
        onClick={!used ? usedHandler : undefined}
      >
        {used
          ? t('ModalUseSkillUsedText', {})
          : t('ModalUseSkillUsingText', {})}
      </StyledUsedText>

      <StyledUsedText
        isCompleted={handled}
        onClick={!handled ? handledHandler : undefined}
      >
        {handled
          ? t('ShopOrderedstatusUsed', {})
          : t('ShopOrderedstatusInuse', {})}
      </StyledUsedText>
    </StyledUsedTextWrapper>
  )
}
