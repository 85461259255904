import React from 'react';
import styled from 'styled-components';
import { AvatarImageWrapper } from 'javascript/features/freeSpace/components/AvatarImageWrapper';
import { StatusItemSelectArea } from 'javascript/features/freeSpace/components/StatusItemSelectArea';
import { OpenChatInputForm } from 'javascript/features/freeSpace/components/OpenChatInputForm';
import { useOpenChat } from '../hooks/useOpenChat';
import { UserAvatar } from 'types';
import { device } from 'javascript/utils/mediaQuery';
import { FreeSpaceLedScrollingText } from 'javascript/features/freeSpace/components/FreeSpaceLedScrollingText';

interface Props {
  userImage: string;
  isCurrentAvatarInFreeSpace: boolean;
  avatar?: UserAvatar;
}

export const OpenChatInputArea: React.FC<Props> = ({
  isCurrentAvatarInFreeSpace,
  avatar,
  userImage,
}) => {
  const {
    val,
    avatarInfo,
    handleOnChangeVal,
    handleOnClick,
    handleOnSubmit,
    electricHandleOnSubmit,
    handleOnKeyDown,
  } = useOpenChat(avatar);

  return (
    <FlexWrapper>
      <StyledOpenChatInputAreaWrapper isDisabled={!isCurrentAvatarInFreeSpace}>
        <StyledInputWrapper>
          <AvatarImageWrapper avatar={avatarInfo} userImage={userImage} />
          <StatusItemSelectArea
            avatar={avatarInfo}
            handleSelectType={handleOnClick}
          />
          <OpenChatInputForm
            isDisabled={!isCurrentAvatarInFreeSpace}
            value={val}
            handleOnChangeVal={handleOnChangeVal}
            handleOnSubmit={handleOnSubmit}
            electricHandleOnSubmit={electricHandleOnSubmit}
            handleOnKeyDown={handleOnKeyDown}
          />
        </StyledInputWrapper>
      </StyledOpenChatInputAreaWrapper>
      <FreeSpaceLedScrollingText/>
    </FlexWrapper>
  );
};

const StyledOpenChatInputAreaWrapper = styled.div<{ isDisabled: boolean }>`
  display: flex;
  /* flex-direction: column;
  justify-content: flex-end; */
  flex: 1 0 auto;
  position: sticky;
  bottom: 0;
  left: -8px;
  width: 464px;
  margin-left: -8px;
  opacity: ${({ isDisabled }) => isDisabled && '0.5'};
  pointer-events: ${({ isDisabled }) => isDisabled && 'none'};
  @media ${device.sp} {
    width: 100%;
    height: auto;
    margin: 0;
  }
`;

const StyledInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // StatusItemSelectAreaのポップアップの起点とするため、relativeを指定
  position: relative;
  height: 72px;
  padding: 6px 8px;
  background-color: rgba(112, 112, 112, 0.5);
  border-top-right-radius: 8px;
  @media ${device.sp} {
    padding: 6px 6px 6px 2px;
    border-top-right-radius: 0;
    max-width: 100vw;
  }
`;

const FlexWrapper = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  bottom: 0;
  @media ${device.sp} {
    display: block;
  }
`;
