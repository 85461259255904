import {
  css,
  CSSObject,
  FlattenSimpleInterpolation,
  SimpleInterpolation,
} from 'styled-components';

const breakpoints = {
  sp: '430px',
  tablet: '960px',
} as const;

export const sp = (
  first: CSSObject | TemplateStringsArray,
  ...interpolations: SimpleInterpolation[]
): FlattenSimpleInterpolation => {
  return css`
    @media only screen and (max-width: ${breakpoints.sp}) {
      ${css(first, ...interpolations)};
    }
  `;
};

export const device = {
  sp: `only screen and (max-width: ${breakpoints.sp})`,
  tab: `only screen and (max-width: ${breakpoints.tablet})`,
} as const;
