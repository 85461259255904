import React from 'react';
import styled from 'styled-components';
import { useIsVideoConnect } from '../../../components/video_call/hooks/useIsVideoConnect';
import { isChrome } from 'javascript/utils/isChrome';
import { useTranslation } from 'react-i18next';

const StyledButtonWrapper = styled.div`
  margin: 0 10px;
  cursor: pointer;
  font-weight: bold;
`;

const StyledButtonImage = styled.img<{ isVideoConnected: boolean }>`
  width: 80px;
  margin-bottom: 11px;
  filter: ${({ isVideoConnected }) => isVideoConnected && 'grayscale(100%)'};
  opacity: ${({ isVideoConnected }) => isVideoConnected && '0.5'};
`;

const StyledNotChromeAlert = styled.p`
  margin: 16px 0 -24px;
  color: #f00;
`;

interface Props {
  onClick: (zoom?: boolean) => void;
}

export const StartVideoCallButton: React.FC<Props> = ({
  onClick: handleSubmit,
}) => {
  const { videoConnectStatus } = useIsVideoConnect();
  const { t } = useTranslation();

  return (
    <StyledButtonWrapper
      onClick={() => {
        if (!videoConnectStatus()) {
          handleSubmit();
        } else {
          alert(t('GenelalAlertCallNow', {}));
        }
      }}
    >
      <StyledButtonImage
        // @ts-ignore
        src={RailsAssetPath('enter_modal/button_video.png')}
        isVideoConnected={videoConnectStatus()}
        alt='icon-enter-zoom'
      />
      <div style={{ color: '#ff8086' }}>{t('RoomButtonVideoRoom', {})}</div>
      {!isChrome && (
        <StyledNotChromeAlert>
          {t('GenelalAlertOnlyChrome', {})}
        </StyledNotChromeAlert>
      )}
    </StyledButtonWrapper>
  );
};
