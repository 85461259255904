import React from 'react';
import Modal from 'react-modal';
import { useGlobal } from 'reactn';
import FormCreateRealWorld from '../../features/realWorld/components/FormCreateRealWorld';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: '0px',
    width: '330px',
    background: 'white',
  },
  overlay: {
    background: 'rgba(62, 62, 62, 0.75)',
    zIndex: '100',
  },
};

Modal.setAppElement('body');

const ModalEditRealWorld = () => {
  const [isShowEditRealWorldModal, setIsShowEditRealWorldModal] = useGlobal(
    'isShowEditRealWorldModal'
  );
  const closeModal = () => {
    setIsShowEditRealWorldModal(false);
  };

  return (
    <Modal
      isOpen={isShowEditRealWorldModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Example Modal'
    >
      <FormCreateRealWorld />
    </Modal>
  );
};

export default ModalEditRealWorld;
