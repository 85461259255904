import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { UserProfileAvatar } from 'types';
import { useSWRConfig } from 'swr';

export const useUpdateAvatarParts = (
  avatarParts: UserProfileAvatar,
  userId: number
) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { mutate } = useSWRConfig();

  const updateAvatarParts = useCallback(() => {
    const {
      body,
      hair,
      hair_color,
      eye,
      mouth,
      hat,
      clothes,
      glasses,
      avatar_id,
    } = avatarParts;
    axios
      .post(`/api/v1/avatars/update/${avatar_id}`, {
        body,
        hair,
        hair_color,
        eye,
        mouth,
        hat,
        clothes,
        glasses,
      })
      .then(() => {
        mutate(`/api/v1/users/${userId}`);
        alert(t('AvatarMessageSaveAvatar', {}));
        history.push(`/mypage/${userId}`);
      });
  }, [avatarParts]);

  return { updateAvatarParts };
};
