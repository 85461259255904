import { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import axios from "axios";

const useRemainingPoint = () => {

  const [remainingPoint, setRemainingPoint] = useState(null);

  return [remainingPoint, setRemainingPoint];
}

export default useRemainingPoint;