import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useSWRConfig } from 'swr';
import { OwnedProductInMybox } from 'types';
import { AvatarOwnedSkillProductSort } from './useMybox';

export const useSellProduct = (
  product: OwnedProductInMybox,
  itemQuantity: number,
  avatarOwnedSkillProductSort: AvatarOwnedSkillProductSort,
  toggleModal: () => void
) => {
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();

  const params = {
    avatar_owned_product_id: product.avatar_owned_product_id.slice(
      0,
      itemQuantity
    ),
    avatar_original_product_id: null,
  };

  const queryParams = `?avatar_owned_skill_products_sort=${avatarOwnedSkillProductSort}`;

  const handleSellProduct = useCallback(async () => {
    const isConfirmed =
      product.viket >= 500
        ? confirm(t('MyboxPresentmodalConfirmtoremovefromdressroom', {}))
        : confirm(t('MyboxSellmodalConfirmtosell', {}));
    if (isConfirmed) {
      const { data } = await axios.post<{ message: string; status: number }>(
        '/api/v1/avatar/product/sell',
        params
      );
      mutate(`/api/v1/avatar/owned_products${queryParams}`);
      alert(data.message);
      toggleModal();
    }
  }, [product.viket, params]);

  return { handleSellProduct };
};
