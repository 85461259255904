import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import {
  MQ_DESKTOP,
  MQ_MOBILE,
} from 'javascript/components/landingPage/lib/MQ';
import { pcPx, spp } from 'javascript/components/landingPage/lib/cssUtil';
import { addScrollStop } from 'javascript/components/landingPage/lib/modalScrollStop';
import { GRAY } from 'javascript/components/landingPage/local/commonCss';
import { useIsLoadedStoreValue } from 'javascript/components/landingPage/store/isLoaded';

const load = keyframes({
  '0%': {
    boxShadow:
      '0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0',
  },
  '12.5%': {
    boxShadow:
      '0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em',
  },
  '25%': {
    boxShadow:
      '0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em',
  },
  '37.5%': {
    boxShadow:
      '0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em',
  },
  '50%': {
    boxShadow:
      '0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em',
  },
  '62.5%': {
    boxShadow:
      '0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em',
  },
  '75%': {
    boxShadow:
      '0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0',
  },
  '87.5%': {
    boxShadow:
      '0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em',
  },
  '100%': {
    boxShadow:
      '0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0',
  },
});

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s cubic-bezier(0.4, 0.59, 0.41, 0.97) 0s,
    visibility 0.3s cubic-bezier(0.4, 0.59, 0.41, 0.97) 0s;
  &[data-loaded='true'] {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
`;

const Load = styled.h3`
  color: ${GRAY};
  margin: 100px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: ${load} 1.3s infinite linear;
  transform: translateZ(0);
  ${MQ_MOBILE} {
    font-size: ${spp(20)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(20)};
  }
`;

const Loading = () => {
  const isLoadedStoreValue = useIsLoadedStoreValue();

  useEffect(() => {
    addScrollStop();
  }, []);

  return (
    <Wrapper data-loaded={isLoadedStoreValue}>
      <Load>loading</Load>
    </Wrapper>
  );
};

export default Loading;
