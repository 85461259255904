import React, {
  useEffect, 
} from "react";


const Observer = ({
  root,
  el,
  onIntersection
}) => {
  useEffect(() => {
    contentLoader();
  }, [el]);

  const contentLoader = () => {
    const elm = el;
    let observer, options;
    options = {
      root: root,
      rootMargin: '0% 0% 80%',
      threshold: 0.1
    }

    observer = new IntersectionObserver(loader, options);
    if (elm) {
      observer.observe(elm);
    } 
  }

  const loader = (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting && onIntersection) {
        onIntersection();
      }
      return false;
    })
  }

  return null;
}

export default Observer;