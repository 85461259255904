import React from 'react';
import { makeStyles, Theme, Typography, Grid } from '@material-ui/core';
import styled from 'styled-components';

const IntroContainer = (props) => {
  return (
    <Container>
      <Logo>
        <img
          src={RailsAssetPath('viket-town-header-logo.png')}
          alt=''
          className='header-title__logo'
        />
      </Logo>
      <InnerContainer>
        <Content>{props.children}</Content>
      </InnerContainer>
    </Container>
  );
};

const Logo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 20px;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const InnerContainer = styled.div`
  display: flex;
  width: 800px;
  height: 600px;
  borderradius: 8px;
  boxshadow: 0px 2px 4px 0px rgba(40, 42, 43, 0.3);
  position: relative;
  margin: auto;
`;
const Content = styled.div`
  width: 90%;
  padding: 0 1em;
  align-items: center;
  display: flex;
  flex: 1;
`;
export default IntroContainer;
