import React, { useState, useEffect, useRef } from 'react';
import Observer from '../common/Observer';
import propTypes from 'prop-types';
import nl2br from 'nl2br';
import Attendance from '../../lib/model/Attendance';
import { setAttendanceApplicationDetail } from '../../lib/redux/actions/attendance';
import styled from 'styled-components';
import { useGlobal } from 'reactn';
import store from '../../lib/redux/store';
import { connect } from 'react-redux';
import { useTranslation , Trans} from 'react-i18next';
import { Tooltip } from 'javascript/features/attendances/components/Tooltip';
import { useTooltip } from 'javascript/features/attendances/hooks/useTooltip';

const AttendanceDetail = ({
  userList,
  dates,
  currentUser,
  isMaster,
  paginate,
  submittedUserData,
}) => {
  AttendanceDetail.propTypes = {
    userList: propTypes.array,
    dates: propTypes.array,
  };

  const [mounted, setMounted] = useState(false);
  const [users, setUsers] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setMounted(true);
    setUsers(userList);
  }, [userList]);

  // reduxでupdateを試みる
  useEffect(() => {
    if (submittedUserData) {
      updateUserInfo();
    }
  }, [submittedUserData]);

  const updateUserInfo = () => {
    const subject = users.find(
      (user) => user.user_id == submittedUserData.user_id
    );
    const index = users.indexOf(subject);
    users[index] = submittedUserData; // TODO:サーバーレスポンスで書き換えるようにする
    setUsers([...users]);
  };

  const colNames = [
    t('AttendanceTextTeamName', {}),
    t('AttendanceTextEmploymentDate', {}),
    t('AttendanceTextWorkingMonth', {}),
    t('AttendanceTextRemainPaidHoliday', {}),
    t('AttendanceTextNumberPaidHoliday', {}),
    t('AttendanceTextWorkingDays', {}),
    t('AttendanceTextHolidays', {}),
    t('AttendanceTextAbsences', {}),
    t('AttendanceTextWorkingTime', {}),
    t('AttendanceTextThisMonthWorkingHour', {}),
    t('AttendanceTextThisMonthOvertime', {}),
  ];

  const tableColumnNames = colNames.map((col, key) => <th key={key}>{col}</th>);

  const root = useRef();
  const target = useRef();

  const onIntersection = () => {
    paginate();
  };

  return (
    <>
      <AttendanceTableWrapper ref={root}>
        <AttendanceTableContent table='left'>
          <thead>
            <tr>
              <th>{t('AttendanceTextId', {})}</th>
              <th>{t('AttendanceTextName', {})}</th>
            </tr>
          </thead>
          <tbody key={users}>
            {users.map((user, key) => (
              <AttendanceTableBody
                userData={user}
                table='left'
                key={key}
                currentUser={currentUser}
              />
            ))}
            <tr ref={target} />
          </tbody>
        </AttendanceTableContent>
        <div className='table-wrapper'>
          <AttendanceTableContent table='right'>
            <thead>
              <tr>
                {tableColumnNames}
                {dates.map((d, key) => (
                  <th key={key}>
                    {d.date}
                    <br />
                    {d.day}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {users.map((user, key) => (
                <AttendanceTableBody
                  userData={user}
                  table='right'
                  key={key}
                  currentUser={currentUser}
                  isMaster={isMaster}
                />
              ))}
              <tr ref={target} />
            </tbody>
          </AttendanceTableContent>
        </div>
      </AttendanceTableWrapper>
      {mounted && (
        <Observer
          root={root.current}
          el={target.current}
          onIntersection={onIntersection}
        />
      )}
    </>
  );
};

const AttendanceTableBody = ({ userData, table, currentUser, isMaster }) => {
  const [isShowEditAttendanceType, setIsShowEditAttendanceType] = useGlobal(
    'isShowEditAttendanceType'
  );

  AttendanceTableBody.propTypes = {
    userData: propTypes.object,
  };

  const [user, setUser] = useState(userData);
  const { t } = useTranslation();

  useEffect(() => {
    setUser(userData);
  }, [userData]);

  // whether
  let isSelf = false;
  if (currentUser.id == user.user_id) {
    isSelf = true;
  }

  const handleWorkTypeChange = (data, futureFlg, applyFlg, fixFlg, planFlg, otherFlg, fixholidayFlg, ApprovedAttendanceFlg) => {
    // if ((!isSelf && futureFlg) || (!isMaster && !futureFlg)) {
    // if ((!isSelf && futureFlg)) {
    if((!isMaster && !isSelf)) {
      return false;
    } 
    else if(isMaster && !isSelf && futureFlg) {
      return false;
    }
    // else if(isMaster && !isSelf && !futureFlg) {
    else if(isMaster && !isSelf && !futureFlg) {
      // if(![0,5,6].includes(data.apply_status) || ![0,5,6].includes(data.attendance_type)) {
      if(([1,4].includes(data.apply_status)) || ([0,1,2,4].includes(data.attendance_type))) {
        if(data.apply_flg) {
        } else {
          return false
        }
      }
    } 
    else if(!isMaster && isSelf && !futureFlg) {
      if(([1,2,3,4,5,6,7].includes(data.apply_status))) {
        return false
      }
    }
    const args = {
      user: user,
      applyDate: data.date,
      applyType: data.apply_status,
      isFuture: futureFlg,
      applyFlg: applyFlg,
      fixFlg: fixFlg,
      planFlg: planFlg,
      otherFlg: otherFlg,
      fixholidayFlg: fixholidayFlg,
      ApprovedAttendanceFlg: ApprovedAttendanceFlg,
    };
    store.dispatch(setAttendanceApplicationDetail(args));
    setIsShowEditAttendanceType(true);
  };

  const attendanceByDate = user.attendance_days.map((data, key) => {
    let result, workType;
    workType = Attendance.WEEKDAY_WORK;
    if (data.attendance_type) {
      workType = data.attendance_type;
    }

    const {
      targetRef,
      isHovered,
      position,
      handleOnMouseEnter,
      handleOnMouseLeave,
      handleOnMouseMove,
    } = useTooltip(data.attendance_id);

    // console.log(data)
    const config = Attendance.config.attendanceType;
    const futureFlg = data.future_flg ? data.future_flg : false;
    const applyFlg = data.apply_status == 3 ? true : false;
    const fixFlg = data.apply_status == 5 ? true : false;
    const planFlg = data.apply_status == 6 || (data.apply_status == 7 &&  data.attendance_type == null) ? true : false;
    const otherFlg = data.apply_status == 1 || data.apply_status == 2 || data.apply_status == 4 || data.apply_status == 7 ? true : false;
    const fixholidayFlg =  data.apply_flg ? data.apply_flg : false; //休日か代休か欠勤の判定
    const ApprovedAttendanceFlg = workType == Attendance.APPLYING_WORK // 出勤予定が承認されている
    let styleByType = applyFlg || otherFlg
      ? { backgroundColor: '#00a0e9', color: '#fff', fontSize: '10px' }
      : config[workType].style;

    if (data.attendance_time && workType == Attendance.WEEKDAY_WORK) {
      result = `${data.attendance_time}<br><span style="font-size: 12px; color: #00a0e9;">${data.working_hours}/${data.break_time}</span>`;
    } else if (!data.attendance_time && workType == Attendance.WEEKDAY_WORK) {
      result = t('AttendanceTextAtWork', {});
    }
    if (workType !== Attendance.WEEKDAY_WORK) {
      result = t(config[workType].name);
    }



    if (futureFlg && workType == Attendance.WEEKDAY_WORK) {
      result = '';
      styleByType = { border: '1px solid #bfbfbf' };
    }

    // if (data.apply_flg || data.apply_status == 3) {
    if (applyFlg) {
      result = t('AttendanceTextApplyingPaidHoliday', {});
    }

    if(fixFlg) {
      styleByType = { backgroundColor: "#fc8484",fontSize: '10px',flexWrap: 'wrap' };
    }

    if(planFlg) {
      styleByType = { backgroundColor: "#00a0e9",fontSize: '10px',flexWrap: 'wrap',color: 'white' };
      result = `${data.attendance_time}<br><span style="font-size: 12px; color: '#fff';">${data.working_hours}/${data.break_time}</span>`;
    }

    // 出勤予定が承認されている場合
    if (ApprovedAttendanceFlg){
      styleByType = { backgroundColor: "#b5b5b5",fontSize: '10px',flexWrap: 'wrap' };
      result = `${data.attendance_time}<br><span style="font-size: 12px;">${data.working_hours}/${data.break_time}</span>`;
    }

    if(otherFlg) {
      if (fixholidayFlg) {
        if(data.apply_status == 1) {
          result = t('AttendanceTextApplyingHolidays', {});
        } else if(data.apply_status == 2) {
          result = t('AttendanceTextApplyingAbsence', {});
        } else if(data.apply_status == 4) {
          result = t('AttendanceTextApplyingSustituteHoliday', {});
        } 
      } else {
        console.log(config[workType].name);
        result = t(config[workType].name);
        styleByType = config[workType].style
      }


    }

    return (
      <React.Fragment>
        <StyledRealWorldMember
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          onMouseMove={handleOnMouseMove}
          onClick={() => handleWorkTypeChange(data, futureFlg, applyFlg, fixFlg, planFlg, otherFlg, fixholidayFlg, ApprovedAttendanceFlg)}
          key={key}
        >
          <span
            className={isSelf && futureFlg ? 'hover' : null}
            style={styleByType}
            dangerouslySetInnerHTML={{ __html: nl2br(result) }}
          />
          {data.attendance_id ? (     
          <Tooltip isHovered={isHovered} position={position} text={data.detail_work} />
          ) : null}
        </StyledRealWorldMember>
        <style jsx>{`
          .hover:hover {
            background: #b6b6b6;
          }
        `}</style>
      </React.Fragment>
    );
  });

  return (
    <tr>
      {table == 'left' ? (
        <>
          <td>
            {user.user_id ? user.displayed_user_id : t('GenelalTextNoSet', {})}
          </td>
          <td>{user.user_name ? user.user_name : t('GenelalTextNoSet', {})}</td>
        </>
      ) : (
        <>
          <td>{user.team_name ? user.team_name : t('GenelalTextNoSet', {})}</td>
          <td>{user.join_date ? user.join_date : t('GenelalTextNoSet', {})}</td>
          <td>
            {user.work_continue_month_count
              ? user.work_continue_month_count
              : t('GenelalTextNoSet', {})}
          </td>
          <td>{user.paid_holiday_count}</td>
          <td>{user.used_paid_holiday_count}</td>
          <td>{user.attendance_count}</td>
          <td>{user.day_off_count}</td>
          <td>{user.absence_count}</td>
          <td>{user.regular_working_time}<br />({user.regular_break_time})</td>
          <td>{user.this_month_working_hour} {user.this_month_overtime_real_min >= 0 ? (
              <p style={{color: "#00a0e9", display:"inline"}}>+{user.this_month_overtime_real}</p>
            )
            : (
              <p style={{color: "#ff5f5f", display:"inline"}}> {user.this_month_overtime_real}</p>
            )
          }<br />({user.this_month_break_time})</td>
          <td>{user.this_month_overtime}</td>
          {attendanceByDate}
        </>
      )}
    </tr>
  );
};

const StyledRealWorldMember = styled.td`
  cursor: pointer;
`;

const AttendanceTableWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid #bfbfbf;
  overflow: auto;
  background: white;
  margin-top: 30px;
  position: relative;
  max-height: 500px;
  display: flex;
  align-items: start;
`;

const AttendanceTableContent = styled.table`
  ${(props) =>
    props.table == 'left' &&
    `
    position: sticky;
    left: 0;
    z-index: 1;
    background: #fff;
  `}
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  word-break: keep-all;
  white-space: nowrap;
  thead th {
    white-space: pre-wrap;
    padding: 25px 10px;
    font-weight: normal;
    background: #dcdcdc;
    text-align: center;
    position: sticky;
    top: 0;
    ${(props) =>
      props.table == 'left' &&
      `
      left: 0;
      height: 92px;
    `}
    height: 134px;
  }
  tbody tr {
    border-top: 1px solid #bfbfbf;
  }
  tbody tr:last-of-type {
    border: none;
  }
  tr:nth-child(even) {
    background: #ecebeb;
  }
  td {
    text-align: center;
    padding: 5px 10px;
    max-width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    ${(props) =>
      props.table == 'left' &&
      `
      height: 53px;`}
  }
  ${(props) =>
    props.table != 'left' &&
    `
    td: nth - child(1) {
  min - width: 125px;
} `}
  td > span {
    padding: 5px;
    border-radius: 7px;
    width: 60px;
    height: 40px;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    background-color: #fff;
    cursor: pointer;
    align-items: center;
  }
`;

const mapStateToProps = (state) => {
  return {
    submittedUserData: state.setDataAttendanceForm.submittedUserData,
  };
};

export default connect(mapStateToProps)(AttendanceDetail);
