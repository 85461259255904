/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, { Pagination } from 'swiper';
import { useScrollYStoreValue } from 'javascript/components/landingPage/store/scrollYStore';
import styled, { css, keyframes } from 'styled-components';
import {
  MQ_DESKTOP,
  MQ_MOBILE,
} from 'javascript/components/landingPage/lib/MQ';
import { pcPx, px, spp } from 'javascript/components/landingPage/lib/cssUtil';
import {
  BLACK,
  fadeInStyle,
  GRAY,
  jpStyle,
  onlySP,
} from 'javascript/components/landingPage/local/commonCss';
import {
  onScrollPromise,
  removeOnscrollEvent,
} from 'javascript/components/landingPage/local/scrollPromise';
import {
  LANGUAGE,
  useLanguageStoreValue,
} from 'javascript/components/landingPage/store/language';
import { useIsSpStoreValue } from 'javascript/components/landingPage/store/isSpStore';

// @ts-ignore
const ASSETS_IMG01 = RailsAssetPath('lp/other/other_1.png');
// @ts-ignore
const ASSETS_IMG02 = RailsAssetPath('lp/other/other_2.png');
// @ts-ignore
const ASSETS_IMG03 = RailsAssetPath('lp/other/other_3.png');
// @ts-ignore
const ASSETS_IMG04 = RailsAssetPath('lp/other/other_4.png');
// @ts-ignore
const ASSETS_IMG05 = RailsAssetPath('lp/other/other_5.png');
// @ts-ignore
const ASSETS_IMG06 = RailsAssetPath('lp/other/other_6.png');
// @ts-ignore
const ASSETS_IMG07 = RailsAssetPath('lp/other/other_7.png');
// @ts-ignore
const ASSETS_IMG08 = RailsAssetPath('lp/other/other_8.png');
// @ts-ignore
const ASSETS_IMG09 = RailsAssetPath('lp/other/other_9.png');
// @ts-ignore
const ASSETS_IMG10 = RailsAssetPath('lp/other/other_10.png');
// @ts-ignore
const ASSETS_IMG11 = RailsAssetPath('lp/other/other_11.png');
// @ts-ignore
const ASSETS_IMG12 = RailsAssetPath('lp/other/other_12.png');
// @ts-ignore
const ASSETS_IMG13 = RailsAssetPath('lp/other/other_13.png');
// @ts-ignore
const ASSETS_IMG14 = RailsAssetPath('lp/other/other_14.png');


const path = keyframes({
  from: { strokeDashoffset: spp(-1500) },
  to: { strokeDashoffset: spp(1500) },
});

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  ${MQ_MOBILE} {
  }
  ${MQ_DESKTOP} {
    padding-top: ${pcPx(0)};
    padding-bottom: ${pcPx(100)};
    &:before {
      content: '';
      background-color: rgba(255, 255, 255, 0.39);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: ${pcPx(1800)};
      height: 100%;
      top: 0;
    }
  }
  .swiper {
    overflow: visible;
    position: relative;
    perspective-origin: -10% 0%;
    ${MQ_MOBILE} {
      width: ${spp(645)};
      padding-bottom: ${spp(60)};
      padding-right: ${spp(320)};
    }
    ${MQ_DESKTOP} {
      width: 240px;
      margin: auto;
      padding-top: ${pcPx(30)};
      padding-bottom: ${pcPx(100)};
      padding-right: ${pcPx(40)};
    }
  }
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    display: flex;
  }
  .swiper-slide {
    width: 100%;
    height: 100%;
    transform-origin: left top;
    transition: 0.3s opacity ease, 1.3s transform ease;
    ${MQ_MOBILE} {
      transform: translate3d(0, 0, 0);
    }
    ${MQ_DESKTOP} {
      transform: translate3d(${pcPx(0)}, ${pcPx(0)}, ${pcPx(0)});
    }
    &[data-prev='true'] {
      opacity: 0;
      ${MQ_MOBILE} {
        transform: translate3d(
          ${spp(100)},
          ${spp(500)},
          ${spp(200)}
        ) !important;
      }
      ${MQ_DESKTOP} {
        transform: translate3d(
          ${pcPx(100)},
          ${pcPx(500)},
          ${pcPx(200)}
        ) !important;
      }
    }
  }
  .swiper-pagination {
    display: flex;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    justify-content: space-around;
    align-items: center;
    ${MQ_MOBILE} {
      width: ${spp(400)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(450)};
    }
  }
  .swiper-pagination-bullet {
    opacity: 1;
    background-color: rgba(49, 49, 49, 0.45);
    ${MQ_MOBILE} {
      width: ${spp(13)};
      height: ${spp(13)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(14)};
      height: ${pcPx(14)};
    }
  }
  .swiper-pagination-bullet-active {
    background-color: ${BLACK};
    ${MQ_MOBILE} {
      width: ${spp(19)};
      height: ${spp(19)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(20)};
      height: ${pcPx(20)};
    }
  }
`;

const Title = styled.h3`
  ${jpStyle}
  ${fadeInStyle}
  color: ${BLACK};
  font-weight: bold;
  position: relative;
  text-align: center;
  overflow: visible;
  ${MQ_MOBILE} {
    font-size: ${spp(26)};
    line-height: ${spp(45)};
    margin-top: ${spp(70)};
    margin-bottom: ${spp(35)};
    padding: 0 ${spp(32.5)};
    padding-bottom: ${spp(35)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(30)};
    line-height: ${pcPx(80)};
    margin-top: ${pcPx(0)};
    margin-bottom: ${pcPx(35)};
    padding-bottom: ${pcPx(30)};
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    background-color: ${BLACK};
    ${MQ_MOBILE} {
      width: ${spp(103)};
      height: ${spp(4)};
      bottom: ${spp(4)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(176)};
      height: ${pcPx(4)};
      bottom: ${pcPx(4)};
    }
  }
`;

const SlideTitle = styled.h3`
  ${jpStyle}
  color: ${BLACK};
  font-weight: bold;
  position: relative;
  text-align: center;
  overflow: visible;
  ${MQ_MOBILE} {
    font-size: ${spp(21)};
    line-height: ${spp(30)};
    margin-top: ${spp(20)};
    margin-bottom: ${spp(20)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(24)};
    line-height: ${pcPx(70)};
    margin-top: ${pcPx(10)};
    margin-bottom: ${pcPx(0)};
  }
`;

const Text = styled.p`
  ${jpStyle}
  ${fadeInStyle}
  color: ${BLACK};
  position: relative;
  text-align: center;
  overflow: visible;
  transition-delay: 0.1s;
  ${MQ_MOBILE} {
    font-size: ${spp(20)};
    line-height: ${spp(34)};
    margin-top: ${spp(10)};
    margin-bottom: ${spp(40)};
    padding: 0 ${spp(32.5)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(24)};
    line-height: ${pcPx(41)};
    margin-top: ${pcPx(5)};
    padding: 0 ${pcPx(50)};
    padding-bottom: ${pcPx(40)};
    margin-bottom: ${pcPx(0)};
  }
`;

const SwiperWrapper = styled.div`
  ${fadeInStyle}
`;

const SlideText = styled.p`
  ${jpStyle}
  color: ${BLACK};
  position: relative;
  text-align: center;
  overflow: visible;
  text-align: justify;
  margin: auto;
  ${MQ_MOBILE} {
    white-space: normal;
    width: ${spp(310)};
    font-size: ${spp(17)};
    line-height: ${spp(29)};
    margin-top: ${spp(0)};
    margin-bottom: ${spp(40)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(300)};
    font-size: ${pcPx(20)};
    line-height: ${pcPx(34)};
    margin-top: ${pcPx(5)};
    padding-bottom: ${pcPx(40)};
    margin-bottom: ${pcPx(0)};
  }
`;

const imgStyle = css`
  display: block;
  margin: auto;
  ${MQ_MOBILE} {
    width: ${spp(300.26)};
    height: ${spp(249.99)};
    margin-top: ${spp(60)};
    margin-bottom: ${spp(0)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(430)};
    height: ${pcPx(358)};
    margin-bottom: ${pcPx(30)};
  }
`;

const Img = styled.pre`
  ${imgStyle}
  ${MQ_MOBILE} {
    background: url(${ASSETS_IMG01}) no-repeat;
    background-size: contain;
  }
  ${MQ_DESKTOP} {
    background: url(${ASSETS_IMG01}) no-repeat;
    background-size: contain;
  }
`;

const svgStyle = css`
  position: absolute;
  margin: auto;
  svg {
    width: 100%;
    height: 100%;
    g {
      stroke: ${GRAY};
    }
  }
`;

const BoxStyle = css`
  ${svgStyle}
  ${onlySP}
  ${MQ_MOBILE} {
    width: ${spp(384)};
    height: ${spp(590)};
    top: ${spp(110)};
    left: ${spp(-270)};
  }
`;

const Box = styled.div`
  ${BoxStyle}
`;

const BoxAnim = styled.div`
  ${BoxStyle}
  svg {
    path {
      stroke: ${BLACK};
      stroke-width: 2;
      stroke-dashoffset: ${spp(1500)};
      stroke-dasharray: ${spp(1500)};
      animation: ${path} 3s linear forwards infinite;
    }
  }
`;

const Other = () => {
  SwiperCore.use([Pagination]);
  const LIST = [
    ASSETS_IMG01,
    ASSETS_IMG02,
    ASSETS_IMG03,
    ASSETS_IMG04,
    ASSETS_IMG05,
    ASSETS_IMG06,
    ASSETS_IMG07,
    ASSETS_IMG08,
    ASSETS_IMG09,
    ASSETS_IMG10,
    ASSETS_IMG11,
    ASSETS_IMG12,
    ASSETS_IMG13,
    ASSETS_IMG14,
  ];
  const [_, setWindowWidth] = useState(0);
  const isSlideMove = useRef(false);
  const slideX = useRef(0);
  const isSpStoreValue = useIsSpStoreValue();
  const fvRef = useRef<HTMLDivElement | null>(null);
  const imgRef = useRef<HTMLDivElement | null>(null);
  const [isFvRefScrolled, setIsFvRefScrolled] = useState(false);
  const [isImgRefScrolled, setIsImgRefScrolled] = useState(false);
  const boxRef = useRef<HTMLDivElement | null>(null);
  const scrollYStoreValue = useScrollYStoreValue();
  const languageStoreValue = useLanguageStoreValue();
  const { t } = useTranslation();

  const getY = (
    ref: MutableRefObject<HTMLDivElement | null>,
    scrollY: number
  ) => {
    if (!ref || !ref.current) return 0;
    const y =
      (scrollY -
        (ref.current.getBoundingClientRect().top + window.pageYOffset)) *
      0.1;
    return y;
  };

  const onTouchStart = (swiper: any) => {
    isSlideMove.current = true;
    slideX.current = swiper.touches.startX;
  };

  const onTouchEnd = (swiper: any) => {
    if (!isSlideMove.current) return;
    isSlideMove.current = false;
    if (slideX.current < swiper.touches.startX) {
      swiper.slidePrev();
    } else {
      swiper.slideNext();
    }
  };

  useEffect(() => {
    const onResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', onResize);

    onResize();
    return () => {
      window.addEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    onScrollPromise(fvRef.current)().then(() => setIsFvRefScrolled(true));
    onScrollPromise(imgRef.current)().then(() => setIsImgRefScrolled(true));
    return () => {
      removeOnscrollEvent(fvRef.current);
      removeOnscrollEvent(imgRef.current);
    };
  }, []);

  return (
    <Wrapper>
      <Title
        ref={fvRef}
        data-visible={isFvRefScrolled}
        data-lang-ru={languageStoreValue === LANGUAGE.ru}
      >
        {t('other.title')}
      </Title>
      <Text
        data-visible={isFvRefScrolled}
        data-lang-ru={languageStoreValue === LANGUAGE.ru}
      >
        {t('other.text')}
      </Text>
      <SwiperWrapper ref={imgRef} data-visible={isImgRefScrolled}>
        <Swiper
          spaceBetween={isSpStoreValue ? 10 : 20}
          slidesPerView='auto'
          pagination={{ clickable: true }}
          allowTouchMove={false}
          onTouchStart={(swiper) => onTouchStart(swiper)}
          onTouchEnd={(swiper) => onTouchEnd(swiper)}
        >
          {LIST.map((img, i) => (
            <SwiperSlide key={i}>
              <Img style={{ backgroundImage: `url(${img})` }} />
              <SlideTitle data-lang-ru={languageStoreValue === LANGUAGE.ru}>
                {t(`other.slide${i + 1}Title`)}
              </SlideTitle>
              <SlideText data-lang-ru={languageStoreValue === LANGUAGE.ru}>
                {t(`other.slide${i + 1}Text`)}
              </SlideText>
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperWrapper>
      <Box
        ref={boxRef}
        style={{
          transform: `translate3d(0, ${px(
            getY(boxRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='532.162'
          height='673.309'
          viewBox='0 0 532.162 673.309'
        >
          <g transform='translate(0 105.945) rotate(-16)' fill='none'>
            <path
              d='M22.159,15.945,384.363,0,362.2,574.281,0,590.227Z'
              stroke='none'
            />
            <path
              d='M 383.3215942382812 1.04669189453125 L 23.12319946289062 16.9039306640625 L 1.041015625 589.1801147460938 L 361.2393493652344 573.3228149414062 L 383.3215942382812 1.04669189453125 M 384.3627014160156 -6.103515625e-05 L 362.2030639648438 574.2813110351562 L -9.1552734375e-05 590.2268676757812 L 22.15945434570312 15.94537353515625 L 384.3627014160156 -6.103515625e-05 Z'
              stroke='none'
              fill='#707070'
            />
          </g>
        </svg>
      </Box>
      <BoxAnim
        style={{
          transform: `translate3d(0, ${px(
            getY(boxRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='532.162'
          height='673.309'
          viewBox='0 0 532.162 673.309'
        >
          <g transform='translate(0 105.945) rotate(-16)' fill='none'>
            <path
              d='M 383.3215942382812 1.04669189453125 L 23.12319946289062 16.9039306640625 L 1.041015625 589.1801147460938 L 361.2393493652344 573.3228149414062 L 383.3215942382812 1.04669189453125 M 384.3627014160156 -6.103515625e-05 L 362.2030639648438 574.2813110351562 L -9.1552734375e-05 590.2268676757812 L 22.15945434570312 15.94537353515625 L 384.3627014160156 -6.103515625e-05 Z'
              stroke='none'
              fill='#707070'
            />
          </g>
        </svg>
      </BoxAnim>
    </Wrapper>
  );
};

export default Other;
