import React, { useState, useEffect, useCallback, useGlobal } from "reactn";
import axios from "axios";
import { PAYMENT_METHOD_STEPS } from "./constants";
import {
  Typography,
  Button,
  Grid,
  Link,
  Backdrop,
  CircularProgress 
} from '@material-ui/core';
import { useStyles } from "./hooks/useStyles";

const ProgressBackdrop = ({open}) => {
  const classes = useStyles();

  return(
    <Backdrop  className={classes.backdrop}  open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )

}


export default ProgressBackdrop;