import React, { useState, useEffect, useCallback, useGlobal } from 'reactn';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { Typography, Grid, Button } from '@material-ui/core';
import { useStyles } from './hooks/useStyles';
import usePaymentConfig from './hooks/usePaymentConfig';
import usePaymentContext from './hooks/usePaymentContext';
import ChangePaymentMethodButton from './buttons/ChangePaymentMethodButton';
import SubscriptionCancelButton from './buttons/SubscriptionCancelButton';
import { useTranslation } from 'react-i18next';

const SubscriptionPlan = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { paymentConfig, fetchConfig } = usePaymentContext();
  const [nowPlan, setNowPlan] = useState(null);
  const [nowAmount, setNowAmount] = useState(0);

  useEffect(() => {
    setNowPlan(null);
    if (paymentConfig) {
      if (paymentConfig.has_plan) {
        setNowPlan(paymentConfig.plan_pt);
        setNowAmount(paymentConfig.plan_pt);
      } else {
      }
    }
  }, [paymentConfig]);
  return (
    <>
      <Typography variant='subtitle1'>
        {t('PaymentTextCurrentPlan', {})}
      </Typography>
      {/* {remainingPoint}pt */}
      <PlanWrapper>
        {!nowPlan || !paymentConfig.available_plan ? (
          <Grid container spacing={4}>
            <Grid container item xs={12} alignItems='center'>
              <Typography variant='body1'>
                {t('PaymentTextNoCurrentPlan', {})}
              </Typography>
            </Grid>
            <Grid container item xs={12} alignItems='center'>
              <Button className={classes.baseButton} href='/buy/subscription'>
                {t('PaymentButtonPurchaseSubscription', {})}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={4}>
            <Grid container item xs={12} alignItems='center'>
              <Grid item xs={6}>
                <Typography variant='h6' display='inline'>
                  {nowPlan}
                </Typography>
                pt
              </Grid>
              <Grid item xs={6}>
                ¥{paymentConfig.plan_amount.toLocaleString()}/
                {t('PaymentTextMonth', {})}
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              {t('PaymentTextNextPurchaseDate', {})}：{paymentConfig.period_end}
            </Grid>
            <Grid container item xs={12} justify='space-between'>
              <Grid item>
                {t('PaymentTextPaymentMethod', {})}：
                {paymentConfig.default_payment_method.card.brand}
                ・・・{paymentConfig.default_payment_method.card.last4}
              </Grid>
              <Grid item xs={4} sm={3}>
                {paymentConfig.payment_methods.length > 0 && (
                  <ChangePaymentMethodButton />
                )}
              </Grid>
            </Grid>

            <Grid container item xs={12} justify='space-around'>
              <Button className={classes.baseButton} href='/buy/subscription'>
                {t('PaymentTextChangePlan', {})}
              </Button>

              <SubscriptionCancelButton />
            </Grid>
          </Grid>
        )}
      </PlanWrapper>
    </>
  );
};

const PlanWrapper = styled.div`
  border: solid 1px #00a0e9;
  padding: 28px 44px;
`;

export default SubscriptionPlan;
