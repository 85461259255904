import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

const setUser = ( 
	state = {
		isMaster: false,
		currentUser: {}
	}, action ) => {
	switch(action.type) {
		case 'SET_USER_WORK_STATUS': 
			return {
				...	state,
				isMaster: action.isMaster,
				currentUser: action.currentUser
			}   
		default: 
			return state;
	}
}

const setDataAttendanceForm = ( 
	state = { 
		applyDate: '', 
		applyType: null,
		user: {},
		isFuture: false,
		applyFlg: false,
		fixFlg: false,
		otherFlg: false,
		planFlg: false,
		fixholidayFlg: false,
		ApprovedAttendanceFlg: false,
		userList: [],
		params: {},
		submittedUserData: {},
		refresh: false
	}, action ) => {
	switch(action.type) {
		case 'SET_UPDATED_USER_ATTENDANCE':
			return {
				...state,
				submittedUserData: action.submittedUserData,
				refresh: action.refresh
			}
		case 'SET_ATTENDANCE_DATA':
			return {
				...state,
				userList: action.userList,
				params: action.params
			}
		case 'SET_ATTENDANCE_APPLICATION_DETAIL':
			return {
				...state,
				user: {
					id: action.user.user_id,
					name: action.user.user_name,
					image: action.user.user_image						
				},
				applyDate: action.applyDate,
				applyType: action.applyType,
				isFuture: action.isFuture,
				applyFlg: action.applyFlg,
				fixFlg: action.fixFlg,
				planFlg: action.planFlg,
				otherFlg: action.otherFlg,
				fixholidayFlg: action.fixholidayFlg,
				ApprovedAttendanceFlg: action.ApprovedAttendanceFlg,
			}
		default: 
			return state;
	}
}

const setMemberProfile = (
	state = {
		submitted: false,
		user: {},
		roles: [],
		teams: [],
		positions: [],
		userList: []
	}, action ) => {
	switch(action.type) {
		case 'SET_MEMBER_DATA':
			return {
				...state,
				userList: action.userList,
				params: action.params	
			}
		case 'SET_MEMBER_PROFILE_DETAIL':
			return {
				...state,
				user: action.user,
				roles: action.roles,
				submitted: action.submitted
			}
		default:
			return state;
	}
}

const setMemberModalType = ( 
	state = {
		config: {}
	}, action ) => {
	switch(action.type) {
		case 'SET_MEMBER_SETTING_MODAL':
			return {
				...state,
				config: action.config
			}
		default:
			return state;
	}
}

const setRooms = (
	state = {
		id: null,
		rooms: []
	}, action ) => {
	switch(action.type) {
		case 'SET_ROOM_CREATE_OR_EDIT':
			return {
				...state,
				rooms: action.rooms
			}	
		case 'SET_EDIT_ROOM_ID':
			return {
				...state,
				id: action.id
			}
		default:
			return state;
	}
}

const report = (
	state = {
		user: {},
		isUserSelected: false
	}, action ) => {
		switch(action.type) {
			case 'SET_SELECTED_USER':
				return {
					...state,
					user: action.user
				}
			case 'SET_IF_USER_SEARCHED':
				return {
					...state,
					isUserSelected: action.isUserSelected
				}
			default: 
				return state;
		}
	}

const voiceChat = (
	state = {
		created: false,
		roomId: null,
		twilioRoomId: null,
	}, action ) => {
		switch(action.type) {
			case 'SET_ROOM_CREATED':
				return {
					...state,
					created: action.created,
					roomId: action.roomId,
					twilioRoomId: action.twilioRoomId	
				}
			default: 
				return state;
		}
	}

const videoCall = (
	state = {
		created: false,
		roomId: null,
		twilioRoomId: null,
		userName: null,
		isGuest: true,
		token: null,
	}, action ) => {
		switch(action.type) {
			case 'SET_VIDEOCALL_ROOM_CREATED':
				return {
					...state,
					created: action.created,
					roomId: action.roomId,
					twilioRoomId: action.twilioRoomId	,
					isGuest: action.isGuest,
					token: action.token,
				}
			default: 
				return state;
		}
	}

const officePlan = (
	state = {
		plan: null
	}, action ) => {
		switch(action.type) {
			case 'SET_OFFICE_PLAN':
				return {
					...state,
					plan: action.plan
				}
			default:
				return state;	
		}
	}

	
const setModalType = (
	state = {
		modalType: '',
		isShow: false
	}, action ) => {
		switch(action.type) {
			case 'SET_TYPE_CALL_LIMIT':
				return {
					...state,
					modalType: 'isLimit',
					isShow: action.isShow
				}
			default:
				return state;
		}
	}

const reducer = combineReducers({
	setUser,
	setDataAttendanceForm,
	setMemberProfile,
	setMemberModalType,
	setRooms,
	report,
	voiceChat,
	videoCall,
	officePlan,
	setModalType
});

const enhancer = () => {
	return compose(
			applyMiddleware(thunk),
			//window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() //redux devtoolをブラウザでインストールした後にコメントアウトを外して下さい
		);
}

export default createStore(
		reducer, 
		enhancer()
);
