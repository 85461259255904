import React from 'react';
import styled from 'styled-components';
import Collapse from '@kunukn/react-collapse';
import { OfficeSubRoom } from 'javascript/features/subRooms/components/OfficeSubRoom';
import { device } from 'javascript/utils/mediaQuery';
import { DayOff, Sleep } from 'types';

// heightは、左カラム全体の高さから、タイトルエリアの42pxとRealWorldの55％の高さを引いた値
const OfficeBlockSub = styled.div`
  height: calc(100% - 55% - 42px);
  padding-top: 12px;
  border-top: 2px solid #f2f2f2;
  @media ${device.sp} {
    padding: 8px 0 0 10px;
    margin: 0;
    background: #fff;
  }
`;

interface Props {
  sleepRoom: Sleep;
  loungeRoom: DayOff;
  handleOnClick: (e: React.MouseEvent) => void;
  videoConnectStatus: () => boolean;
  isHiddenSubRoom?: boolean;
  role: 'member' | 'master' | 'cast' | undefined;
}

export const SubRooms: React.FC<Props> = ({
  sleepRoom,
  loungeRoom,
  handleOnClick,
  videoConnectStatus,
  isHiddenSubRoom,
  role,
}) => (
  <OfficeBlockSub>
    <Collapse
      isOpen={!isHiddenSubRoom}
      transition={`height 500ms cubic-bezier(.4, 0, .2, 1)`}
      collapseHeight='3vh'
    >
      <OfficeSubRoom
        room={loungeRoom}
        handleOnClick={handleOnClick}
        videoConnectStatus={videoConnectStatus}
        isHiddenSubRoom={isHiddenSubRoom}
        isCast={role == "cast"}
      />
      <OfficeSubRoom room={sleepRoom} videoConnectStatus={videoConnectStatus} isCast={role == "cast"}/>
    </Collapse>
  </OfficeBlockSub>
);
