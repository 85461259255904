import React from "react";
import { useSelector } from "react-redux";
import PlanLimitDialog from "./PlanLimitDialog";

const DefaultModalContent = () => {

  const modalType = useSelector((state) => state.setModalType.modalType);
  const isShow = useSelector((state) => state.setModalType.isShow);

  function Component() {
    switch (modalType) {
      case 'isLimit':
        return <PlanLimitDialog />;
      default:
        return null;
    }
  }

  return (
    <>
      {isShow ? 
        <Component /> : ''
      }
    </>
  );
}

export default DefaultModalContent;