import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../utils/mediaQuery';

export const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align:center; 
  width: 70px;
  cursor: pointer;
  @media ${device.tab} {
    width: 64px;
  }
  @media ${device.sp} {
    width: 24px;
  }
`;

export const NavIcon = styled.img`
  width: 24px;
  height: 24px;
  margin: 4px 0;
  filter: grayscale(1);
  opacity: 0.6;
  @media ${device.sp} {
    width: 20px;
    height: 20px;
  }
`;

interface Props {
  img: string;
  text: string;
}

export const HeaderNavItem: React.FC<Props> = ({ img, text }) => (
  <NavItem>
    {/* @ts-ignore */}
    <NavIcon src={RailsAssetPath(img)} />
    <p>{text}</p>
  </NavItem>
);
