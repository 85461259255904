import React, { useState, useEffect, useMemo, useGlobal } from 'reactn';
import styled from 'styled-components';
import {
  Typography,
  Divider,
  Container,
  Grid,
  Button,
} from '@material-ui/core';
import { useStyles } from './hooks/useStyles';
import { FORM_TYPES } from './constants';
import { useTranslation } from 'react-i18next';

const BuyComplete = ({ formType, quantity, basePoint, baseAmount }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const buyMessage = useMemo(() => {
    switch (formType) {
      case FORM_TYPES.SUBSCRIPTION_BUY:
        return t('PaymentMessageBuySubscription', {});
      case FORM_TYPES.SUBSCRIPTION_UPDATE:
        return t('PaymentMessageChangeSubscription', {});
      case FORM_TYPES.CHARGE:
        return t('PaymentMessageBuyPoint', {});
    }
  }, [formType]);
  return (
    <Container maxWidth='sm'>
      <Grid container justify='center' spacing={6}>
        <Grid item xs={12}>
          <Grid container spacing={0} justify='center' alignItems='center'>
            <Grid
              container
              item
              xs={12}
              md={8}
              direction='column'
              spacing={0}
              justify='center'
              alignItems='center'
            >
              <RemorksLogo>
                <img
                  src={RailsAssetPath('viket_town_icon.png')}
                  alt=''
                  className='header-title__logo'
                />
              </RemorksLogo>
            </Grid>
            <Grid
              container
              direction='column'
              item
              justify='center'
              alignItems='center'
              spacing={2}
            >
              <Typography variant='body1'>{buyMessage}</Typography>
              <Typography variant='body1'>
                {t('PaymentMessageSendMailDatail1', {
                  buyType:
                    formType == FORM_TYPES.SUBSCRIPTION_UPDATE
                      ? t('PaymentBuyTypeChange', {})
                      : t('PaymentBuyTypePurchase', {}),
                })}
              </Typography>
              <Typography variant='body1'>
                {t('PaymentMessageSendMailDatail2', {})}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <PointContainer
          container
          item
          xs={12}
          md={7}
          justify='space-around'
          alignItems='center'
        >
          <Grid item>
            <Typography variant='subtitle1'>
              {(basePoint * quantity).toLocaleString()}pt
            </Typography>
          </Grid>
          <Divider orientation='vertical' variant='middle' flexItem />
          <Grid item>
            <Typography variant='h6' display='inline'>
              ¥{(baseAmount * quantity).toLocaleString()}
            </Typography>
            {formType != FORM_TYPES.CHARGE && `/${t('PaymentTextMonth', {})}`}
          </Grid>
        </PointContainer>
        <Grid container item justify='center'>
          <Button
            href='/payment'
            className={`${classes.baseButton} ${classes.roundedButton}`}
          >
            {t('PaymentTextBackPlan', {})}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

const RemorksLogo = styled.div`
  margin-bottom: 30px;
  width: 30%;
`;
const PointContainer = styled(Grid)`
  min-height: 60px;
`;

export default BuyComplete;
