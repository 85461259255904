import React, { useState, useEffect, useCallback, useGlobal } from 'reactn';
import styled from 'styled-components';
import { Link } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const ChangePaymentMethodButton = () => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [paymentCarnumberInputDialogOpen, setPaymentCarnumberInputDialogOpen] =
    useGlobal('paymentCarnumberInputDialogOpen');

  const handleDialogOpen = () => {
    setPaymentCarnumberInputDialogOpen(true);
  };
  useEffect(() => {
    setPaymentCarnumberInputDialogOpen(false);
  }, []);
  return (
    <>
      <CardEditLink onClick={handleDialogOpen}>
        <Typography>{t('PaymentButtonChange', {})}</Typography>
      </CardEditLink>
    </>
  );
};
const CardEditLink = styled(Link)``;

export default ChangePaymentMethodButton;
