import React, { useState, useEffect, useCallback } from "react";
import { useGlobal } from "reactn";
import propTypes from "prop-types";
import styled from "styled-components";
//import ReactPaginate from 'react-paginate';
import { useSelector, connect } from "react-redux";
import { fetchAttendanceApi } from "../../lib/redux/actions/attendance";
import CsvButton from "../items/CsvButton";
import { getQueryString, convertMinutesToTime } from "../../utils/util";
import AttendanceDetail from "../attendance/AttendanceDetail";
import LoadingAnimation from "../common/LoadingAnimation";
import { useTranslation } from 'react-i18next';


const AttendanceManagement = ({ refresh }) => {

  const currentUser = useSelector((state) => state.setUser.currentUser);
  const isMaster = useSelector((state) => state.setUser.isMaster);

  const [
    isShowAddPaidHolidayFormShow,
    setIsShowAddPaidHolidayFormShow,
  ] = useGlobal("isShowAddPaidHolidayFormShow");

  const [
    isShowWorkingHourFormShow,
    setIsShowWorkingHourFormShow,
  ] = useGlobal("isShowWorkingHourFormShow");
  
  const d = new Date();
  const currentMonth = d.getMonth() + 1;
  const currentYear = d.getFullYear();
  const currentMonthWithZero = ('0' + currentMonth).slice(-2);
  const yearMonth = currentYear + '/' + currentMonthWithZero;

  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState(currentMonth);
  const [order, setOrder] = useState("asc");
  const [page, setPage] = useState(1);
  const [teamId, setTeamId] = useState(null);
  const [sortId, setSortId] = useState(null);
  const [data, setData] = useState({});
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPaginatable, setIsPaginatable] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    if (page > 1) {
      getNextPageData();
    }
  }, [page]);

  useEffect(() => {
    getAttedanceData();
  }, [year, month, teamId, sortId]);

  useEffect(() => {
    if (refresh) {
      initializeData();
    }
  }, [refresh]);

  useEffect(() => {
    setParams();
  }, []);

  // useEffect(() => {
  //   if (page > 1) {
  //     getUserData();
  //   }
  // }, [page, year, month, teamId]);

  // reduxでupdateを試みる
  // useEffect(() => {
  //   if (submittedUserData) {
  //     updateUserInfo();
  //   }
  // }, [submittedUserData]);

  // const updateUserInfo = () => {
  //   const subject = users.find(user => user.user_id == submittedUserData.user_id);
  //   const index = users.indexOf(subject);
  //   users[index] = submittedUserData; // TODO:サーバーレスポンスで書き換えるようにする
  //   setUsers(users);
  //   store.dispatch({ type: 'SET_UPDATED_USER_DATA', users: users });
  // }

  const params = {
    year: year,
    month: month,
    order: order,
    page: page,
    select_id: teamId,
    sort_id: sortId
  };

  const initializeData = () => {
    params.page = 1;
    setLoading(true);
    setTimeout(() => {
      fetchAttendanceApi(params).then(res => {
        setLoading(false);
        setUsers(res.data.users);
      })
    }, 1000);
  }

  const getAttedanceData = () => {
    setLoading(true);
    fetchAttendanceApi(params).then(res => {
      const data = res.data;
      const users = data.users;
      setData(data);
      setLoading(false);
      setUsers(prev => prev != users ? [...prev, ...users] : prev);
    });
    //store.dispatch(fetchAttendanceData(params));
  };

  const setParams = useCallback(() => {
    const params = getQueryString();
    if (!params) return false;
    setMonth(prev => prev === params.month ? prev : params.month);
    setYear(prev => prev === params.year ? prev : params.year);
    setOrder(prev => prev === params.order ? prev : params.order);
    //setPage(Number(params.page));
  }, []);

  const dates = [];
  const week = [
    t("GenelalWeekSunday",{}),
    t("GenelalWeekMonday",{}),
    t("GenelalWeekTuesday",{}),
    t("GenelalWeekWednesday",{}),
    t("GenelalWeekThursday",{}),
    t("GenelalWeekFriday",{}),
    t("GenelalWeekSaturday",{})]; //[day];
  const totalDays = new Date(year, month, 0).getDate();
  for (let i = 1; i <= totalDays; i++) {
    const d = new Date(year, month - 1, i);
    const dateSet = {
      date: i,
      day: week[d.getDay()],
    };
    dates.push(dateSet);
  }

  // const onChangePageNumber = (e) => {
  //   const nextPageNum = e.selected + 1;
  //   setPage(nextPageNum);
  // };

  const onChangeDateSelect = (e) => {
    //const s = e.target;
    const monthValue = e.target.value;
    const value = monthValue.split("/");
    const year = value[0];
    const month = value[1];
    setUsers([]);
    setYear(year);
    setMonth(month);
    setPage(1);
  };

  const onChangeTeamSelect = (e) => {
    const t = e.target.value;
    setUsers([]);
    setPage(1);
    setTeamId(t);
  };

  const onChangeSortSelect = (e) => {
    const s = e.target.value;
    setUsers([]);
    setPage(1);
    setSortId(s);
  };

  const timeRange = data.year_months;
  const teamOptions = data.select_list;
  const sortOptions = data.sort_list;

  const handleOpenModal = e => {
    e.preventDefault();
    setIsShowAddPaidHolidayFormShow(true);
  };

  const handleWorkingHourOpenModal = e => {
    e.preventDefault();
    setIsShowWorkingHourFormShow(true);
  };

  const getNextPageData = () => {
    setIsPaginatable(false);
    fetchAttendanceApi(params).then(res => {
      const data = res.data;
      const users = data.users;
      setData(data);
      setUsers(prev => prev != users ? [...prev, ...users] : prev);
      setIsPaginatable(true);
    });
  }

  const onIntersection = () => {
    if (isPaginatable) {
      setPage(prev => prev + 1);
    }
  }

  return (
    <>
      <AttendanceContainer>
        <AttendanceHeader isMaster={isMaster}>
          {isMaster && (
            <AddButtonWrapper>
              <AddButton onClick={handleOpenModal}>{t("AttendanceTextPaidHoriday",{})}</AddButton>
              <AddButton onClick={handleWorkingHourOpenModal}>{t("AttendanceTextAddWorkHour",{})}</AddButton>
            </AddButtonWrapper>
          )}
          <div>
            {timeRange &&
              <PageSelectWrapper>
                <select onChange={onChangeDateSelect} defaultValue={yearMonth}>
                  {timeRange.map((date, key) => (
                    <option value={date.name} key={key}>{date.name}</option>
                  ))}
                </select>
              </PageSelectWrapper>
            }
            {teamOptions &&
              <PageSelectWrapper>
                <select onChange={onChangeTeamSelect}>
                  {teamOptions.map((team, key) => (
                    <option value={team.id} key={key}>{team.name}</option>
                  ))}
                </select>
              </PageSelectWrapper>
            }
            {sortOptions &&
              <PageSelectWrapper>
                <select onChange={onChangeSortSelect}>
                  {sortOptions.map((sort, key) => (
                    <option value={sort.id} key={key}>{sort.name}</option>
                  ))}
                </select>
              </PageSelectWrapper>
            }
          </div>
        </AttendanceHeader>
        {loading ?
          <LoadingAnimation /> :
          users.length ? (
            <AttendanceDetail
              userList={users}
              dates={dates}
              currentUser={currentUser}
              isMaster={isMaster}
              paginate={onIntersection}
            />
          ) : (
              <MessageOnEmpty>
                {t("AttendanceAlertNoAttendanceData",{})}
              </MessageOnEmpty>
            )
        }
        {/* {userList.users && userList.users.length ? (
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={userList.pagination ? userList.pagination.pages : 1}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={onChangePageNumber}
            containerClassName={"pagination"}
            subContainerClassName={"pagination"}
            activeClassName={"active"}
          />
        ) : null} */}
      </AttendanceContainer>
      {currentUser.role !== 'cast' && (
        <CsvButton year={year} month={month} order={order} />
      )}
    </>
  );
};

const AttendanceContainer = styled.div`
  background-color: #f6f6f6;
  padding: 40px 80px;
  min-height: 100vh;
`

const AttendanceHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  ${(props) => props.isMaster ? `
    justify-content: space-between;
  `: `
    justify-content: flex-end;
  `}
`

const MessageOnEmpty = styled.div`
  margin-top: 100px;
  text-align: center;
  font-size: 14px;
  color: #0b0b0b;
  span {
    display: block;
    color: #888888;
  }
`;

const PageSelectWrapper = styled.div`
  display: inline-block;
  position: relative;
  margin-left: 10px;
  &::before {
    content: "";
    width: 12px;
    height: 12px;
    border: 6px solid transparent;
    border-top: 8px solid #6f6f6f;
    position: absolute;
    top: 15px;
    right: 15px;
  }
  select {
    appearance: none;
    height: 35px;
    border-radius: 8px;
    border: 1px solid #bfbfbf;
    color: #7b7b7b;
    width: 150px;
    font-size: 14px;
    text-align-last: center;
    padding: 0 35px 0 20px;
  }
`;

const AddButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  justify-content: space-between;
  gap: 15px;
`; 
const AddButton = styled.button`
  font-size: 14px;
  color: #0b0b0b;
`;

const mapStateToProps = state => {
  return {
    refresh: state.setDataAttendanceForm.refresh,
  };
};

export default connect(mapStateToProps)(AttendanceManagement);
