import React from 'react';
import styled, { css } from 'styled-components';
import VoiceChatSettingButton from '../../../components/voice/Buttons/VoiceChatSettingButton';
import { sp } from '../../../utils/mediaQuery';
import { useShowRealWorldSettingModal } from '../hooks/useShowRealWorldSettingModal';
import { useShowCreateVirtualWorldModal } from '../../virtualWorld/hooks/useShowCreateVirtualWorldModal';
import { useMediaQuery } from 'react-responsive';
import { SelectThemeArea } from '../../virtualWorld/components/SelectThemeArea';
import { FilterMenuModal } from '../../virtualWorld/components/FilterMenuModal';
import { useFilterMenuModal } from '../../virtualWorld/hooks/useFilterMenuModal';
interface Props {
  img: string;
  plan: number;
  children: string;
  role: 'member' | 'master' | 'cast' | undefined;
  theme?: string;
  isVirtual?: boolean;
  index?: number;
}

export const OfficeTitle: React.FC<Props> = ({
  img,
  children,
  plan,
  theme,
  role,
  isVirtual,
  index,
}) => {
  const { showRealWorldSettingModal } = useShowRealWorldSettingModal();
  const { showCreateVirtualWorldModal } = useShowCreateVirtualWorldModal();
  const isMobile = useMediaQuery({ maxWidth: 430 });

  const {
    modalPosition,
    isOpenFilterMenuModal,
    openMenuModal,
    closeMenuModal,
    closeModalAfterSetFilter,
  } = useFilterMenuModal();

  return (
    <OfficeTitleWrapper isVirtual={isVirtual}>
      <OfficeTitleArea>
        <OfficeTitleLabel>
          <OfficeTitleImage isVirtual={isVirtual}>
            {/* @ts-ignore */}
            <img src={RailsAssetPath(img)} />
          </OfficeTitleImage>
          <OfficeTitleName>{children}</OfficeTitleName>
        </OfficeTitleLabel>
        {role !== 'cast' &&
          (!isMobile || !isVirtual || (index && index > 1.7)) && (
            <CreateButton
              isVirtual={isVirtual}
              onClick={
                isVirtual
                  ? () => showCreateVirtualWorldModal()
                  : () => showRealWorldSettingModal()
              }
            >
              {/* @ts-ignore */}
              <img src={RailsAssetPath('plus.png')} />
            </CreateButton>
          )}
          {isVirtual && (
            <RoomSortButton
              isVirtual={isVirtual}
              onClick={openMenuModal}
            >
            {/* @ts-ignore */}
              <img src={RailsAssetPath('sort.png')} />
            </RoomSortButton>
          )}

          { isOpenFilterMenuModal && (
            <FilterMenuModal
              modalPosition={modalPosition}
              closeMenuModal={closeMenuModal}
              closeModalAfterSetFilter={closeModalAfterSetFilter}
            /> 
          )}

        {!isMobile && isVirtual && <SelectThemeArea theme={theme} />}
      </OfficeTitleArea>
      {(isVirtual || isMobile) && (
        <VoiceChatArea>
          <VoiceChatSettingButton plan={plan} isMaster={role === 'master'} />
        </VoiceChatArea>
      )}
    </OfficeTitleWrapper>
  );
};
const mixinFlexCenter = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OfficeTitleWrapper = styled.div<{ isVirtual?: boolean }>`
  ${mixinFlexCenter}
  max-width: 100%;
  margin: ${({ isVirtual }) => (isVirtual ? '5px 0 7px' : '7px 0 12px')};
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 430px) {
    position: relative;
    margin: ${({ isVirtual }) => (isVirtual ? '8px 0 6px 0' : '8px 0')};
  }
`;

const OfficeTitleArea = styled.div`
  ${mixinFlexCenter}
`;

const VoiceChatArea = styled.div`
  margin-right: 68px;
  ${sp`
    margin-right: 0px;
  `}
`;

const OfficeTitleLabel = styled.div`
  ${mixinFlexCenter}
`;

const OfficeTitleImage = styled.div<{ isVirtual?: boolean }>`
  margin-right: ${({ isVirtual }) => (isVirtual ? '8px' : '6px')};
  height: 18px;
  width: 18px;
  img {
    width: 18px;
    height: 18px;
  }

  ${sp`
    margin-right: 8px;
    height: 16px;
    img {
      width: 14px;
      height: 14px;
    }
  `}
`;

const OfficeTitleName = styled.p`
  color: #999;
  font-size: 14px;
  letter-spacing: 1.2px;
  ${sp`
    font-size: 12px;
  `}
`;

const CreateButton = styled.div<{ isVirtual?: boolean }>`
  margin-left: 8px;
  cursor: pointer;
  width: 18px;
  height: 18px;
  img {
    width: 18px;
    height: 18px;
  }

  ${sp`
    img {
      width: 15px;
      height: 15px;
      margin-top: 1px;
    }
  `}
`;

const RoomSortButton = styled(CreateButton)`
  img {
    width: 20px;
    height: 18px;
  }

`