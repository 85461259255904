import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { VirtualWorldSettingMenu } from 'javascript/features/virtualWorld/components/VirtualWorldSettingMenu';
import { device } from 'javascript/utils/mediaQuery';
import { VirtualWorld } from 'types';

interface Props {
  virtualWorld: VirtualWorld;
  hasCurrentUser: boolean;
}

export const VirtualWorldTitle: React.FC<Props> = ({
  virtualWorld,
  hasCurrentUser,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 430 });

  const toggleSetting = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const hasUser = virtualWorld.users.length !== 0;
  const type = virtualWorld.room_type;

  const icon =
    type === 'voice'
      ? hasCurrentUser
        ? 'voice/call_off.png'
        : 'enter_modal/icon_voice.png'
      : type === 'video'
      ? 'enter_modal/icon_video.png'
      : hasCurrentUser // type === 'nonfunctional'
      ? 'voice/call_off.png'
      : 'enter_modal/icon_time.png';

  return (
    <VirtualWorldTitleWrapper>
      <VirtualWorldNameArea>
        <VirtualWorldTypeImage
          isCalling={type === 'voice' && hasCurrentUser}
          isVideo={type === 'video'}
        >
          {/* @ts-ignore */}
          <img src={RailsAssetPath(icon)} />
        </VirtualWorldTypeImage>
        <VirtualWorldName>{virtualWorld.name}</VirtualWorldName>
      </VirtualWorldNameArea>
      <VirtualWorldSettingArea>
        <p>
          {virtualWorld.users.length !== 0
            ? virtualWorld.users.length + virtualWorld.guest_num
            : ''}
        </p>
        {/* 親要素のItemにhoverした時にvisibilityを変更するため、要素を特定するためにclassNameを指定している */}
        {!isMobile && (
          <SettingIconWrapper
            onClick={(e) => toggleSetting(e)}
            className='usually-hidden'
          >
            {/* @ts-ignore */}
            <img src={RailsAssetPath('dot.png')} />
            <VirtualWorldSettingMenu
              isOpen={isOpen}
              isStarred={virtualWorld.favorite}
              toggleSetting={toggleSetting}
              id={virtualWorld.id}
              hasUser={hasUser}
              creatorId={virtualWorld.user_id}
            />
          </SettingIconWrapper>
        )}
      </VirtualWorldSettingArea>
    </VirtualWorldTitleWrapper>
  );
};

const VirtualWorldTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 26px;
  padding-left: 4px;
  font-size: 11px;
  line-height: 1.2;
  @media ${device.sp} {
    height: 24px;
  }
`;
const VirtualWorldNameArea = styled.div`
  display: flex;
  align-items: center;
`;

const VirtualWorldSettingArea = styled.div`
  display: flex;
  align-items: center;
`;

const VirtualWorldTypeImage = styled.div<{
  isCalling: boolean;
  isVideo: boolean;
}>`
  margin-right: 4px;
  img {
    height: ${({ isVideo }) => !isVideo && '16px'};
    width: ${({ isVideo }) => isVideo && '16px'};
  }
  @media ${device.sp} {
    width: 12px;
    height: 12px;
    img {
      height: ${({ isVideo }) => !isVideo && '100%'};
      width: ${({ isVideo }) => isVideo && '100%'};
    }
  }
  ${({ isCalling }) =>
    isCalling &&
    `
    img {
      max-width: 103%;
    }
  `}
`;

const VirtualWorldName = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  max-width: 66px;
  text-overflow: ellipsis;
`;

const SettingIconWrapper = styled.div`
  position: relative;
  width: 14px;
  height: 16px;
  cursor: pointer;
  visibility: hidden;
  img {
    height: 12px;
  }
`;
