import { useCallback, useState } from 'react';
import { UserProfileAvatar } from 'types';

export const useAvatarParts = (avatarPartsInfo: UserProfileAvatar) => {
  const [avatarParts, setAvatarParts] = useState(avatarPartsInfo);

  const selectAvatarParts = useCallback(
    (selectedAvatarParts: UserProfileAvatar) => {
      setAvatarParts(selectedAvatarParts);
    },
    [avatarParts, setAvatarParts]
  );

  return { avatarParts, selectAvatarParts };
};
