import { useCallback, useState, useEffect } from 'react';
import useVoiceContext from './useVoiceContext';

const useRoomState = () => {
  const { room } = useVoiceContext();
  const [state, setState] = useState('disconnected');
  const [errorCode, setErrorCode] = useState();

  useEffect(() => {
    const setRoomState = () => setState(room.state);
    const setRoomStateWithError = (error) => {
      setState(room.state);
      setErrorCode(error.code);
    };
    if (room) {
      setRoomState();
      room
        .on('disconnected', setRoomState)
        .on('reconnected', setRoomState)
        .on('reconnecting', setRoomStateWithError);
      return () => {
        room
          .on('disconnected', setRoomState)
          .on('reconnected', setRoomState)
          .on('reconnecting', setRoomStateWithError);
      };
    }
  }, [room]);

  return { state, errorCode };
};

export default useRoomState;
