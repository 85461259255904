import React, { useCallback, useState } from 'react';
import { StayHistoryOfMonth } from 'types';

type StayHistory = [string, StayHistoryOfMonth][] | undefined;

export const useShowEachMonth = (stayHistory: StayHistory) => {
  const [selectedMonth, setSelectedMonth] = useState<string | undefined>();

  const selectMonth = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMonth(e.target.value);
  }, []);

  const selectedMonthHistory =
    stayHistory &&
    stayHistory.find((hist) => hist[0].substring(5) === selectedMonth);

  const selectedMonthTotalVirtualMinutes =
    selectedMonthHistory &&
    selectedMonthHistory[1].virtuals.reduce(
      (prev, current) => prev + current.stay_minutes,
      0
    );
  const selectedMonthTotalDirectCalls =
    selectedMonthHistory &&
    selectedMonthHistory[1].direct_calls.reduce(
      (prev, current) => prev + current.stay_minutes,
      0
    );
  const maxHoursInAMonth =
    selectedMonthHistory &&
    typeof selectedMonthTotalVirtualMinutes === 'number' &&
    typeof selectedMonthTotalDirectCalls === 'number' &&
    Math.max(
      selectedMonthHistory[1].free.stay_minutes,
      selectedMonthHistory[1].overtime_work.stay_minutes,
      selectedMonthHistory[1].sleep.stay_minutes,
      selectedMonthHistory[1].monthly_stay_minutes,
      selectedMonthTotalDirectCalls,
      selectedMonthTotalVirtualMinutes
    ) / 60;

  const monthMaxScale =
    typeof maxHoursInAMonth === 'number'
      ? (Math.ceil(maxHoursInAMonth / 50) * 50)
      : undefined;

  console.log("maxHoursInAMonth="+maxHoursInAMonth);
  console.log("monthMaxScale="+monthMaxScale);

  return {
    selectMonth,
    selectedMonthHistory,
    monthMaxScale,
  };
};
