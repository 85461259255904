import { StayHistoryOfMonth } from 'types';
import { useFormatMonth } from './useFormatMonth';

export const useEachStayHistory = (
  historyPerMonth: [string, StayHistoryOfMonth]
) => {
  const unformattedMonth = historyPerMonth[0];
  const month = useFormatMonth(unformattedMonth);

  const historyData = historyPerMonth[1];

  const freeSpaceMinutes = historyData.free.stay_minutes;
  const sleepMinutes = historyData.sleep.stay_minutes;

  const overWorkMinutes = historyData.overtime_work.stay_minutes;

  const normalRoom = historyData.virtuals.filter(
    (room) => room.room_type === 'nonfunctional' && room.stay_minutes > 0
  );
  const voiceRoom = historyData.virtuals.filter(
    (room) => room.room_type === 'voice' && room.stay_minutes > 0
  );
  const videoRoom = historyData.virtuals.filter(
    (room) => room.room_type === 'video' && room.stay_minutes > 0
  );
  const directCalls = historyData.direct_calls.filter(
    (room) => room.stay_minutes > 0
  );

  return {
    month,
    freeSpaceMinutes,
    sleepMinutes,
    overWorkMinutes,
    normalRoom,
    voiceRoom,
    videoRoom,
    directCalls,
  };
};
