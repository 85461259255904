import React from 'react';
import styled from 'styled-components';
import { MonthlyViKeRanking } from 'javascript/features/scrum/components/MonthlyViKeRanking';
import { WeeklyScrumResult } from 'javascript/features/scrum/components/WeeklyScrumResult';
import { device } from 'javascript/utils/mediaQuery';

const StyledScrum = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 24px auto 0;
  @media ${device.tab} {
    flex-direction: column;
    row-gap: 24px;
  }
`;

const StyledScrumComponentWrapper = styled.div`
  width: 48%;
  @media ${device.tab} {
    width: 100%;
  }
`;

type Props = {
  userId: number | undefined;
};

export const ScrumTemplate: React.FC<Props> = React.memo(({ userId }) => (
  <StyledScrum>
    <StyledScrumComponentWrapper>
      <MonthlyViKeRanking userId={userId} />
    </StyledScrumComponentWrapper>
    <StyledScrumComponentWrapper>
      <WeeklyScrumResult userId={userId} />
    </StyledScrumComponentWrapper>
  </StyledScrum>
));
