import React from 'react';
import { HeaderNormalNavMenu } from 'javascript/components/common/Header/components/HeaderNormalNavMenu';
import { HeaderCastNavMenu } from 'javascript/components/common/Header/components/HeaderCastNavMenu';
import { HeaderNonActiveNavMenu } from 'javascript/components/common/Header/components/HeaderNonActiveNavMenu';

interface Props {
  isCast: boolean;
  isMaster: boolean;
  isNonActiveUser: boolean;
}

export const HeaderNavMenu: React.FC<Props> = ({
  isCast,
  isNonActiveUser,
  isMaster,
}) => {
  if (isCast) return <HeaderCastNavMenu />;
  if (isNonActiveUser) return <HeaderNonActiveNavMenu />;

  return <HeaderNormalNavMenu isMaster={isMaster} />;
};
