import React from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../../../utils/mediaQuery';
import { useClickWorkButton } from '../hooks/useClickWorkButton';

const StyledWorkButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 186px;
  margin-left: 14px;
  @media ${device.tab} {
    width: 160px;
    margin-left: 8px;
  }
  @media ${device.sp} {
    width: auto;
    margin: 0;
    padding: 0;
  }
`;

const mixinButton = css`
  display: inline-block;
  width: 88px;
  height: 46px;
  img {
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: translate(-1px, -1px);
      box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 5%),
        4px 4px 4px 0px rgb(0 0 0 / 5%);
    }
  }
  @media ${device.tab} {
    width: 76px;
  }
  @media ${device.sp} {
    width: 64px;
    height: 24px;
    img {
      border-radius: 4px;
      width: 90%;
      height: 90%;
    }
  }
`;

const mixinDisabled = css`
  cursor: default;
  filter: grayscale(1);
  opacity: 0.6;
  img:hover {
    transform: none;
    box-shadow: none;
  }
`;

const StyledStartWorkButton = styled.button<{
  userStatus: 'not_working' | 'working' | 'finished';
}>`
  ${mixinButton}
  ${({ userStatus }) =>
    userStatus !== 'not_working' &&
    `
      ${mixinDisabled}
      @media only screen and (max-width: 430px) {
        display: none;
      }
  `}
`;

const StyledFinishWorkButton = styled.button<{
  userStatus: 'not_working' | 'working' | 'finished';
}>`
  ${mixinButton}
  ${({ userStatus }) =>
    userStatus !== 'working' &&
    `
    ${mixinDisabled}
  `}
  ${({ userStatus }) =>
    userStatus === 'not_working' &&
    `
      @media only screen and (max-width: 430px) {
        display: none;
      }
  `}
`;

interface Props {
  videoConnectStatus: () => boolean;
  userStatus: 'not_working' | 'working' | 'finished';
}

export const WorkButtonArea: React.FC<Props> = ({
  userStatus,
  videoConnectStatus,
}) => {
  const { clickStartWorkButton, clickFinishWorkButton } = useClickWorkButton(
    userStatus,
    videoConnectStatus
  );

  return (
    <StyledWorkButtonArea>
      <StyledStartWorkButton
        onClick={clickStartWorkButton}
        userStatus={userStatus}
      >
        {/* @ts-ignore */}
        <img src={RailsAssetPath('sp_in.png')} alt='start work button' />
      </StyledStartWorkButton>
      <StyledFinishWorkButton
        onClick={clickFinishWorkButton}
        userStatus={userStatus}
      >
        {/* @ts-ignore */}
        <img src={RailsAssetPath('sp_out.png')} alt='leave work button' />
      </StyledFinishWorkButton>
    </StyledWorkButtonArea>
  );
};
