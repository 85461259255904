import React from 'react';
import { useCallback, useGlobal } from 'reactn';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { ProductTypeSelect } from 'javascript/features/avatarShop/components/ProductTypeSelect';
import { useSelectedShopProductType } from 'javascript/features/avatarShop/hooks/useSelectedShopProductType';
import { device } from 'javascript/utils/mediaQuery';
import { ShopProduct } from 'javascript/features/avatarShop/components/ShopProduct';
import { useShopProductList } from 'javascript/features/avatarShop/hooks/useShopProductList';
import { useSortType } from 'javascript/features/avatarShop/hooks/useSortType';
import { useFilterType } from 'javascript/features/avatarShop/hooks/useFilterType';
import { ShopAddSkillProductModal } from 'javascript/features/avatarShop/components/ShopAddSkillProductModal';
import {
  SORT_TYPE,
  // FILTER_TYPE,
} from 'javascript/features/avatarShop/constants/sortAndFilterType';

const CssFlexAlignCenter = css`
  display: flex;
  align-items: center;
`;

const StyledAvatarShop = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  height: 100%;
  margin-left: -56px;
  @media ${device.sp} {
    width: 100%;
    margin-left: 0;
    padding-top: 8px;
  }
`;

const StyledAvatarShopTitle = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  @media ${device.sp} {
    font-size: 16px;
  }
`;

const StyledLinkToAnotherPage = css`
  display: flex;
  position: absolute;
  top: 4px;
  padding-bottom: 2px;
  border-bottom: 1px solid #707070;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  cursor: pointer;
  img {
    width: 20px;
    margin-right: 4px;
  }
  @media ${device.sp} {
    top: 0;
    font-size: 12px;
    img {
      width: 14px;
    }
  }
`;

const StyledLinkToMybox = styled.div`
  ${StyledLinkToAnotherPage}
  right: 0;
`;

const StyledLinkToDressRoom = styled.div`
  ${StyledLinkToAnotherPage}
  left: 0;
  img {
    filter: grayscale(100%);
  }
`;

const StyledIconWrapper = styled.div`
  margin-right: 8px;
  margin-left: 112px;
  img {
    width: 32px;
  }
  @media ${device.sp} {
    margin-left: 0;
    img {
      width: 22px;
    }
  }
`;

const StyledAvatarShopWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  overflow: scroll;
  @media ${device.sp} {
    margin-top: 16px;
  }
`;

const StyledShopProductList = styled.div<{
  isSkill: boolean;
  isOrderedSkillShown: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(6, 100px);
  grid-auto-rows: 150px;
  column-gap: 10px;
  row-gap: ${({ isSkill, isOrderedSkillShown }) =>
    isSkill || isOrderedSkillShown ? '66px' : '16px'};
  height: 100%;
  margin-left: 48px;
  padding-bottom: 8px;
  padding-right: 16px;
  overflow: scroll;
  @media ${device.sp} {
    margin-left: 0;
    grid-template-columns: repeat(2, 100px);
  }
`;

const StyledSortFilterWrapper = styled.div`
  ${CssFlexAlignCenter}
  justify-content: space-between;
  margin-left: 144px;
  margin-bottom: 8px;
  @media ${device.sp} {
    flex-direction: column;
    margin: 0 auto;
  }
`;

const StyledSortWrapper = styled.div<{ isOrderedSkillShown?: boolean }>`
  ${CssFlexAlignCenter}
  &:first-child {
    visibility: ${({ isOrderedSkillShown }) => isOrderedSkillShown && 'hidden'};
  }
  @media ${device.sp} {
    &:first-child {
      margin-bottom: 8px;
    }
  }
`;

const StyledSortItem = styled.p<{ isSelected: boolean }>`
  padding: 0 8px;
  color: #f00;
  font-size: 14px;
  font-weight: ${({ isSelected }) => isSelected && 'bold'};
  opacity: ${({ isSelected }) => !isSelected && '0.8'};
  cursor: pointer;
  &:not(:first-child) {
    border-left: 1px solid #f00;
  }
`;

const StyledAddProductIconWrapper = styled.div`
  ${CssFlexAlignCenter}
  position: relative;
  flex-direction: column;
  height: 150px;
`;

const StyledAddProductIcon = styled.div`
  ${CssFlexAlignCenter}
  justify-content: center;
  margin: 60px 5px 0;
  width: 160px;
  height: 0px;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    padding: 24px;
    background-color: #fff;
    cursor: pointer;
    &::before,
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 3px;
      background: #00a0e9;
    }
    &::before {
      transform: translateY(50%);
    }
    &::after {
      transform: rotate(90deg);
    }
  }
  @media ${device.sp} {
    width: calc(100% / 2 - -50px);
    height: 185px;
    margin: 0 5px;
  }
`;

interface Props {
  userId: number | undefined;
}

export const AvatarShopTemplate: React.FC<Props> = ({ userId }) => {
  const { t } = useTranslation();

  const { sortType, setSortType } = useSortType();
  const { filterType, setFilterType } = useFilterType();

  const { selectShopProductType, selectedShopProductType } =
    useSelectedShopProductType(setFilterType, setSortType, sortType);
  const { fixedShownProductList } = useShopProductList({
    selectedShopProductType,
    sortType,
  });

  // @ts-ignore
  const currentUser = useSelector((state) => state.setUser.currentUser);
  const avatarId = currentUser?.avatar?.id as number | undefined;
  const [_, setEditSkillProduct] =
    useGlobal<{ editSkillProduct: any }>('editSkillProduct');
  const [isShowSkillEditModal, setIsShowEditVirtualWorldModal] = useGlobal<{
    isShowSkillEditModal: boolean;
  }>('isShowSkillEditModal');
  const toggleModal = useCallback(() => {
    setIsShowEditVirtualWorldModal(!isShowSkillEditModal);
  }, []);
  return (
    <StyledAvatarShop>
      <StyledAvatarShopTitle>
        <StyledIconWrapper>
          {/* @ts-ignore */}
          <img src={RailsAssetPath('avatar_shop/shop_icon.png')} />
        </StyledIconWrapper>
        {t('ShopPageTitle', {})}
        <Link to={`mypage/${userId}?target=avatar`}>
          <StyledLinkToDressRoom>
            {/* @ts-ignore */}
            <img src={RailsAssetPath('mypage/dressup_icon.png')} />
            {t('MenuUserAvatar', {})}
          </StyledLinkToDressRoom>
        </Link>
        <Link to='/mybox'>
          <StyledLinkToMybox>
            {/* @ts-ignore */}
            <img src={RailsAssetPath('mybox/mybox_icon.png')} />
            {t('MyboxPageTitle', {})}
          </StyledLinkToMybox>
        </Link>
      </StyledAvatarShopTitle>
      <StyledSortFilterWrapper>
        <StyledSortWrapper isOrderedSkillShown={filterType === 2}>
          {SORT_TYPE.map((item) => {
            if (selectedShopProductType !== 'skill' && item.type === 5) return;
            return (
              <StyledSortItem
                key={item.type}
                isSelected={sortType === item.type}
                onClick={() => setSortType(item.type)}
              >
                {t(item.text)}
              </StyledSortItem>
            );
          })}
        </StyledSortWrapper>
      </StyledSortFilterWrapper>
      <StyledAvatarShopWrapper>
        <ProductTypeSelect
          selectedShopProductType={selectedShopProductType}
          selectShopProductType={selectShopProductType}
        />
        <StyledShopProductList
          isSkill={selectedShopProductType == 'skill'}
          isOrderedSkillShown={filterType === 2}
        >
          {filterType === 1 &&
            fixedShownProductList &&
            fixedShownProductList.length !== 0 && (
              <>
                {fixedShownProductList.map((product) => (
                  <ShopProduct
                    product={product}
                    selectedShopProductType={selectedShopProductType}
                    key={`${product.avatar_product_id
                      ? product.avatar_product_id
                      : product.avatar_skill_product_id
                      }_${product.product_number}`}
                    avatarId={avatarId}
                  />
                ))}
              </>
            )}
          {selectedShopProductType == 'skill' && filterType !== 2 && (
            <StyledAddProductIconWrapper>
              <StyledAddProductIcon
                onClick={() => {
                  setEditSkillProduct(undefined);
                  toggleModal();
                }}
              >
                <div></div>
              </StyledAddProductIcon>
            </StyledAddProductIconWrapper>
          )}
        </StyledShopProductList>
        {isShowSkillEditModal && <ShopAddSkillProductModal />}
      </StyledAvatarShopWrapper>
    </StyledAvatarShop>
  );
};
