import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useToggleSortMode } from 'javascript/features/virtualWorld/hooks/useToggleSortMode';

interface Props {
  isOpen: boolean;
  toggleSetting: (e: React.MouseEvent) => void;
}

export const SortableVirtualWorldSettingMenu: React.FC<Props> = React.memo(
  ({ isOpen, toggleSetting }) => {
    const { finishSortMode } = useToggleSortMode();

    const { t } = useTranslation();

    return (
      <VirtualWorldMenuBox isOpen={isOpen}>
        <CloseIconWrapper onClick={(e) => toggleSetting(e)}>
          <img
            // @ts-ignore
            src={RailsAssetPath('close-img-gray.png')}
            width={12}
            height={12}
          />
        </CloseIconWrapper>
        <VirtualWorldMenuItem onClick={finishSortMode}>
          <VirtualWorldMenuItemIcon
            // @ts-ignore
            icon={RailsAssetPath('icon_pin.png')}
          />
          {t('GenelalButtonFix', {})}
        </VirtualWorldMenuItem>
      </VirtualWorldMenuBox>
    );
  }
);

const VirtualWorldMenuBox = styled.div<{
  isOpen: boolean;
}>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  min-width: 104px;
  color: #303030;
  font-size: 10px;
  text-align: left;
  border-radius: 5px;
  backdrop-filter: blur(12px);
  background-color: rgba(0, 160, 233, 0.02);
  box-shadow: 0px 0px 3px 0px grey;
  position: absolute;
  top: 10px;
  right: 10px;
  white-space: nowrap;
  z-index: 1;
`;

const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 24px;
  height: 24px;
  background: #f2f2f2;
  border: 2px solid #fff;
  border-radius: 50%;
`;

const VirtualWorldMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`;

const VirtualWorldMenuItemIcon = styled.i<{ icon: string }>`
  display: inline-block;
  padding: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
  vertical-align: middle;
  background-image: ${({ icon }) => `url(${icon})`};
`;
