import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  MQ_DESKTOP,
  MQ_MOBILE,
} from 'javascript/components/landingPage/lib/MQ';
import { pcPx, spp } from 'javascript/components/landingPage/lib/cssUtil';
import {
  BLACK,
  fadeInStyle,
  jpStyle,
} from 'javascript/components/landingPage/local/commonCss';
import {
  onScrollPromise,
  removeOnscrollEvent,
} from 'javascript/components/landingPage/local/scrollPromise';
import {
  LANGUAGE,
  useLanguageStoreValue,
} from 'javascript/components/landingPage/store/language';

// @ts-ignore
const ASSETS_IMG_JA = RailsAssetPath('lp/viketStructure/viket_graph_ja.png');
// @ts-ignore
const ASSETS_IMG_EN = RailsAssetPath('lp/viketStructure/viket_graph_en.png');
// @ts-ignore
const ASSETS_IMG_DE = RailsAssetPath('lp/viketStructure/viket_graph_de.png');
// @ts-ignore
const ASSETS_IMG_ES = RailsAssetPath('lp/viketStructure/viket_graph_es.png');
// @ts-ignore
const ASSETS_IMG_KO = RailsAssetPath('lp/viketStructure/viket_graph_ko.png');
// @ts-ignore
const ASSETS_IMG_FR = RailsAssetPath('lp/viketStructure/viket_graph_fr.png');
// @ts-ignore
const ASSETS_IMG_IT = RailsAssetPath('lp/viketStructure/viket_graph_it.png');
// @ts-ignore
const ASSETS_IMG_PO = RailsAssetPath('lp/viketStructure/viket_graph_po.png');
// @ts-ignore
const ASSETS_IMG_RU = RailsAssetPath('lp/viketStructure/viket_graph_ru.png');
// @ts-ignore
const ASSETS_IMG_CH_CN = RailsAssetPath(
  'lp/viketStructure/viket_graph_ch_cn.png'
);
// @ts-ignore
const ASSETS_IMG_CH_TW = RailsAssetPath(
  'lp/viketStructure/viket_graph_ch_tw.png'
);

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  ${MQ_DESKTOP} {
    &:before {
      content: '';
      background-color: rgba(255, 255, 255, 0.39);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: ${pcPx(1800)};
      height: 100%;
      top: 0;
    }
  }
`;

const Title = styled.h3`
  ${jpStyle}
  ${fadeInStyle}
  color: ${BLACK};
  font-weight: bold;
  position: relative;
  text-align: center;
  overflow: visible;
  ${MQ_MOBILE} {
    font-size: ${spp(26)};
    line-height: ${spp(45)};
    margin-top: ${spp(70)};
    margin-bottom: ${spp(35)};
    padding: 0 ${spp(20)};
    padding-bottom: ${spp(35)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(36)};
    line-height: ${pcPx(80)};
    margin-bottom: ${pcPx(35)};
    padding-bottom: ${pcPx(30)};
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    background-color: ${BLACK};
    ${MQ_MOBILE} {
      width: ${spp(103)};
      height: ${spp(4)};
      bottom: ${spp(4)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(176)};
      height: ${pcPx(4)};
      bottom: ${pcPx(4)};
    }
  }
`;

const Img = styled.div<{ image: string }>`
  ${fadeInStyle}
  display: block;
  margin: auto;
  position: relative;
  ${MQ_MOBILE} {
    width: ${spp(714)};
    height: ${spp(714)};
    background: url(${({ image }) => image}) no-repeat;
    background-size: contain;
    margin-top: ${spp(60)};
    margin-bottom: ${spp(0)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(1290)};
    height: ${pcPx(1290)};
    background: url(${({ image }) => image}) no-repeat;
    background-size: contain;
    margin-bottom: ${pcPx(190)};
  }
`;

const ViketStructure = () => {
  const fvRef = useRef<HTMLDivElement | null>(null);
  const imgRef = useRef<HTMLDivElement | null>(null);
  const [isFvRefScrolled, setIsFvRefScrolled] = useState(false);
  const [isImgRefScrolled, setIsImgRefScrolled] = useState(false);
  const languageStoreValue = useLanguageStoreValue();
  const { t } = useTranslation();

  const getTextImg = (lang: string): string => {
    let img = ASSETS_IMG_JA;
    if (lang === LANGUAGE.en) img = ASSETS_IMG_EN;
    if (lang === LANGUAGE.fr) img = ASSETS_IMG_FR;
    if (lang === LANGUAGE.de) img = ASSETS_IMG_DE;
    if (lang === LANGUAGE.it) img = ASSETS_IMG_IT;
    if (lang === LANGUAGE.ko) img = ASSETS_IMG_KO;
    if (lang === LANGUAGE.ru) img = ASSETS_IMG_RU;
    if (lang === LANGUAGE.es) img = ASSETS_IMG_ES;
    if (lang === LANGUAGE.pt) img = ASSETS_IMG_PO;
    if (lang === LANGUAGE.zhCN) img = ASSETS_IMG_CH_CN;
    if (lang === LANGUAGE.zhTW) img = ASSETS_IMG_CH_TW;
    return img;
  };

  useEffect(() => {
    onScrollPromise(fvRef.current)().then(() => setIsFvRefScrolled(true));
    onScrollPromise(imgRef.current)().then(() => setIsImgRefScrolled(true));
    return () => {
      removeOnscrollEvent(fvRef.current);
      removeOnscrollEvent(imgRef.current);
    };
  }, []);

  return (
    <Wrapper>
      <Title
        ref={fvRef}
        data-visible={isFvRefScrolled}
        data-lang-ru={languageStoreValue === LANGUAGE.ru}
      >
        {t('viketStructure.title')}
      </Title>
      <Img
        ref={imgRef}
        data-visible={isImgRefScrolled}
        image={getTextImg(languageStoreValue)}
      />
    </Wrapper>
  );
};

export default ViketStructure;
