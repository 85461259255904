import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { SortableVirtualWorldSettingMenu } from 'javascript/features/virtualWorld/components/SortableVirtualWorldSettingMenu';

interface Props {
  roomType: 'voice' | 'video' | 'nonfunctional' | undefined;
  roomName: string;
  userNum: number;
}

export const SortableVirtualWorldTitle: React.FC<Props> = React.memo(
  ({ roomType, roomName, userNum }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSetting = useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
      },
      [isOpen]
    );

    const icon =
      roomType === 'voice'
        ? 'enter_modal/icon_voice.png'
        : roomType === 'video'
        ? 'enter_modal/icon_video.png'
        : 'enter_modal/icon_time.png';

    return (
      <VirtualWorldTitleWrapper>
        <VirtualWorldNameArea>
          <VirtualWorldTypeImage isVideo={roomType === 'video'}>
            {/* @ts-ignore */}
            <img src={RailsAssetPath(icon)} />
          </VirtualWorldTypeImage>
          <VirtualWorldName>{roomName}</VirtualWorldName>
        </VirtualWorldNameArea>
        <VirtualWorldSettingArea>
          <p>{userNum || ''}</p>
          {/* 親要素のItemにhoverした時にvisibilityを変更するため、要素を特定するためにclassNameを指定している */}
          <SettingIconWrapper onClick={(e) => toggleSetting(e)}>
            {/* @ts-ignore */}
            <img src={RailsAssetPath('dot.png')} />
            <SortableVirtualWorldSettingMenu
              isOpen={isOpen}
              toggleSetting={toggleSetting}
            />
          </SettingIconWrapper>
        </VirtualWorldSettingArea>
      </VirtualWorldTitleWrapper>
    );
  }
);

const VirtualWorldTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 4px;
  height: 26px;
  font-size: 11px;
  line-height: 1.2;
`;
const VirtualWorldNameArea = styled.div`
  display: flex;
  align-items: center;
`;

const VirtualWorldSettingArea = styled.div`
  display: flex;
  align-items: center;
`;

const VirtualWorldTypeImage = styled.div<{
  isVideo: boolean;
}>`
  margin-right: 4px;
  img {
    height: ${({ isVideo }) => !isVideo && '16px'};
    width: ${({ isVideo }) => isVideo && '16px'};
  }
`;

const VirtualWorldName = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  max-width: 70px;
  text-overflow: ellipsis;
`;

const SettingIconWrapper = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  cursor: pointer;
  img {
    height: 12px;
  }
`;
