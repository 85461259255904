import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// const resources = require('./resources.json');

const resources = {
  "ja": {
    "translation": require("./resources/ja.json")
  },
  "en": {
    "translation": require("./resources/en.json")
  },
  "es": {
    "translation": require("./resources/es.json")
  },
  "fr": {
    "translation": require("./resources/fr.json")
  },
  "it": {
    "translation": require("./resources/it.json")
  },
  "ko": {
    "translation": require("./resources/ko.json")
  },
  "pt": {
    "translation": require("./resources/pt.json")
  },
  "ru": {
    "translation": require("./resources/ru.json")
  },
  "de": {
    "translation": require("./resources/de.json")
  },
  "zh-CN": {
    "translation": require("./resources/zh-CN.json")
  },
  "zh-TW": {
    "translation": require("./resources/zh-TW.json")
  },
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "ja",
    debug: false,
    interpolation: {
      escapeValue: false,
    }
});

export default i18n;