import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

export type ScrollYState = {
  scrollY: number;
};

export const INITIAL_SCROLL_Y_STATE: ScrollYState = {
  scrollY: 0,
};

export const scrollYStore = atom<ScrollYState>({
  key: 'scrollYState',
  default: INITIAL_SCROLL_Y_STATE,
});

export const useScrollYStoreValue = () => {
  const { scrollY } = useRecoilValue(scrollYStore);
  return scrollY;
};

export const useSetScrollYStore = () => {
  return useSetRecoilState(scrollYStore);
};
