import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { fetchUserData } from '../../../lib/redux/actions/user';
import { useDispatch, useSelector } from 'react-redux';
import useVoiceContext from './useVoiceContext';
import useRoomState from './useRoomState';
import useRoom from './useRoom';

const useAudioToggle = () => {
  const currentUser = useSelector((state) => state.setUser.currentUser);
  const dispatch = useDispatch();
  const [microphone, setMicrophone] = useState(
    currentUser && currentUser.microphone
  ); //microphone state
  const [speaker, setSpeaker] = useState(currentUser && currentUser.speaker); //speaker state

  const { room } = useVoiceContext();
  const { state } = useRoomState();

  useEffect(() => {
    updateAudio();
    if (room) {
      room.localParticipant.audioTracks.forEach((audioTrack) =>
        microphone ? audioTrack.track.enable() : audioTrack.track.disable()
      );
    }
  }, [microphone, speaker]);

  useEffect(() => {
    if (room) {
      console.log("microphone2: " + microphone);
      console.log("speaker2: " + speaker);
      console.log("room2: " + room);
      room.localParticipant.audioTracks.forEach((audioTrack) =>
        microphone ? audioTrack.track.enable() : audioTrack.track.disable()
      );
    }
  }, [room]);

  const params = {
    microphone: microphone,
    speaker: speaker,
  };

  const updateAudio = () => {
    const url = '/api/v1/twilio/voices/status_update';
    axios.post(url, params).then((res) => {
      dispatch(fetchUserData());
    });
  };

  const toggleMicrophone = useCallback(() => {
    setMicrophone(!microphone);
  }, [microphone]);

  const toggleSpeaker = useCallback(() => {
    setSpeaker(!speaker);
  }, [speaker]);

  return { microphone, speaker, toggleMicrophone, toggleSpeaker };
};

export default useAudioToggle;
