import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import AvatarIcon from './icon/AvatarIcon';
import useVideoCallContext from './hooks/useVideoCallContext';
import useScreenShareParticipant from './hooks/useScreenShareParticipant';
import usePublications from './hooks/usePublications';
import useTrack from './hooks/useTrack';
import useIsTrackSwitchedOff from './hooks/useIsTrackSwitchedOff';
import useParticipantIsReconnecting from './hooks/useParticipantIsReconnecting';
import useIsTrackEnabled from './hooks/useIsTrackEnabled';
import useMediaStreamTrack from './hooks/useMediaStreamTrack';
import useParticipants from './hooks/useParticipants';
import useWindowDimensions from './hooks/useWindowDimentions';
import { useMediaQuery } from 'react-responsive';

const AudioContext = window.AudioContext || window.webkitAudioContext;
let audioContext;


export function initializeAnalyser(stream) {
  audioContext = audioContext || new AudioContext();
  const audioSource = audioContext.createMediaStreamSource(stream);

  const analyser = audioContext.createAnalyser();
  analyser.smoothingTimeConstant = 0.2;
  analyser.fftSize = 2048;

  audioSource.connect(analyser);
  return analyser;
}

const ParticipantInfo = ( {
  participant,
  onClick,
  isSelected,
  children,
  isLocalParticipant,
  hideParticipant,
  enableScreenShare,
  screenHeight,
  screenWidth
}) => {

  const { room , isSharingScreen } = useVideoCallContext();

  const publications = usePublications(participant);

  const audioPublication = publications.find(p => p.kind === 'audio');
  const videoPublication = publications.find(p => !p.trackName.includes('screen') && p.kind === 'video');

  const isVideoEnabled = Boolean(videoPublication);

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack);

  const audioTrack = useTrack(audioPublication);
  const isAudioEnabled = useIsTrackEnabled(audioTrack);
  const isAudioSwitchedOff = useIsTrackSwitchedOff(audioTrack);
  const [analyser, setAnalyser] = useState();
  const mediaStreamTrack = useMediaStreamTrack(audioTrack);

  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const participants = useParticipants();
  const participantsCount = participants.length + 1;
  const windowDimensions = useWindowDimensions();

  const [volume , setVolume] = useState(0);
  const [timer, setTimer] = useState(null);

  const isMobile = useMediaQuery({ maxWidth: 430 });

  // ボリュームに応じて枠線をつける
  useEffect(() => {
    if (audioTrack && mediaStreamTrack && isAudioEnabled) {
      let newMediaStream = new MediaStream([mediaStreamTrack.clone()]);
      // let newMediaStream = new MediaStream([mediaStreamTrack]);
      const stopAllMediaStreamTracks = () => newMediaStream.getTracks().forEach(track => track.stop());
      audioTrack.on('stopped', stopAllMediaStreamTracks);
      const reinitializeAnalyser = () => {
        stopAllMediaStreamTracks();
        newMediaStream = new MediaStream([mediaStreamTrack.clone()]);
        setAnalyser(initializeAnalyser(newMediaStream));
      };

      setAnalyser(initializeAnalyser(newMediaStream));
      window.addEventListener('focus', reinitializeAnalyser);

      return () => {
        stopAllMediaStreamTracks();
        window.removeEventListener('focus', reinitializeAnalyser);
        audioTrack.off('stopped', stopAllMediaStreamTracks);
      };
    }
  }, [isAudioEnabled, mediaStreamTrack, audioTrack, isAudioSwitchedOff]);

  useEffect(() => {
    if (!isAudioEnabled) {
      setVolume(0);
      clearInterval(timer);
    }
    if (isAudioEnabled && analyser) {
      const sampleArray = new Uint8Array(analyser.frequencyBinCount);
      const callInterval = () => {
        const interval = setInterval(() => {
          analyser.getByteFrequencyData(sampleArray);
          if (sampleArray.filter(Boolean).length == 0) {
            clearInterval(this);
            return;
          }
          let values = 0;
          const length = sampleArray.length;
          for (let i = 0; i < length; i++) {
            values += sampleArray[i];
          }
          const volume = Math.min(14, Math.max(0, Math.log10(values / length / 3) * 7));
          setVolume(volume);
        }, 300);
        setTimer(interval);
        return () => clearInterval(interval);
      };
      callInterval();
    }
  }, [isAudioEnabled, analyser]);

  return (
    <Container screenWidth={screenWidth} screenHeight={screenHeight} volume={volume} isAudioEnabled={isAudioEnabled}
      data-cy-participant={participant.identity}
    >
      {(!isVideoEnabled) && (
        <div className="avatar-container">
          <img src={RailsAssetPath("video_call/icon/avatar.png")}  />
        </div>
      )}
      <InfoContainer>
        {(!isAudioEnabled || isAudioSwitchedOff) && (
          <img src={RailsAssetPath("video_call/icon/mic_mute.png")} className="button_icon" style={{'padding': '2px'}}/>
        )}
        <Name>{participant.identity.substr(participant.identity.indexOf("_", participant.identity.indexOf("_")+1) + 1)}</Name>
      </InfoContainer>
      {children}
    </Container>
  );
}
const Container = styled.div`
  height: ${props => props.screenHeight}px;
  width: ${props => props.screenWidth}px;
  margin: 2px;
  position: relative;
  outline:  solid ${props => props.volume >= 3 && props.isAudioEnabled? "#00DD00 1px" : "#565656 1px"};
  border-top:  solid ${props => props.volume >= 3 && props.isAudioEnabled? "#00DD00 1px" : "#565656 1px"};
  border-bottom:  solid ${props => props.volume >= 3 && props.isAudioEnabled? "#00DD00 1px" : "#565656 1px"};
  overflow: hidden;
  `
const InfoContainer = styled.div`
  display: flex;
  position: absolute;
  z-index: 10;
  bottom: 0;
  color: #fff;
`
const Name = styled.div`
  background-color: rgba(60,60,60,0.5);
  padding: 3px 10px;
  border: 1px solid #565656;
  font-size: 11px;
  white-space: nowrap;
`


export default ParticipantInfo;
