import { useCallback } from 'react';
import { useGlobal } from 'reactn';

export const useShowCreateVirtualWorldModal = () => {
  const [_, setIsShowCreateVirtualWorldModal] = useGlobal("isShowCreateVirtualWorldModal");

  const showCreateVirtualWorldModal = useCallback(() => {
    setIsShowCreateVirtualWorldModal(true);
  }, []);

  return { showCreateVirtualWorldModal }
}