import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

export interface Language {
  ja: string;
  zhCN: string;
  zhTW: string;
  en: string;
  fr: string;
  de: string;
  it: string;
  ko: string;
  ru: string;
  es: string;
  pt: string;
}

export const LANGUAGE: Language = {
  ja: 'ja',
  zhCN: 'zh-CN',
  zhTW: 'zh-TW',
  en: 'en',
  fr: 'fr',
  de: 'de',
  it: 'it',
  ko: 'ko',
  ru: 'ru',
  es: 'es',
  pt: 'pt',
};

export type LanguageState = {
  language: string;
};

const subDirectoryLang = location.pathname.substring(1);

export const INITIAL_LANGUAGE_STATE: LanguageState = {
  language:
    subDirectoryLang === ''
      ? LANGUAGE.ja
      : subDirectoryLang === 'en'
      ? LANGUAGE.en
      : subDirectoryLang === 'fr'
      ? LANGUAGE.fr
      : subDirectoryLang === 'pt'
      ? LANGUAGE.pt
      : subDirectoryLang === 'ru'
      ? LANGUAGE.ru
      : subDirectoryLang === 'es'
      ? LANGUAGE.es
      : subDirectoryLang === 'it'
      ? LANGUAGE.it
      : subDirectoryLang === 'ko'
      ? LANGUAGE.ko
      : subDirectoryLang === 'zh'
      ? LANGUAGE.zhTW
      : subDirectoryLang === 'zh-Hans'
      ? LANGUAGE.zhCN
      : LANGUAGE.de,
};

export const languageStore = atom<LanguageState>({
  key: 'languageState',
  default: INITIAL_LANGUAGE_STATE,
});

export const useLanguageStoreValue = () => {
  const { language } = useRecoilValue(languageStore);
  return language;
};

export const useSetLanguageStore = () => {
  return useSetRecoilState(languageStore);
};
