import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useSWRConfig } from 'swr';
import { OwnedSkillProductInMybox } from 'types';

export const useSkillProductApplyUse = (
  product: OwnedSkillProductInMybox,
  toggleModal: () => void,
  avatarOwnedSkillProductSort: string | null
) => {
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();
  const params = {
    avatar_owned_product_id: product.avatar_owned_product_id,
    avatar_original_product_id: null,
  };

  const handleApplyUseSkillProduct = useCallback(async () => {
    if (product.used) return;
    const isConfirmed = confirm(t('ModalUseSkillConfirm', {}));
    if (isConfirmed) {
      const { data } = await axios.post<{ message: string; status: number }>(
        '/api/v1/avatar/product/apply_use_skill',
        params
      );
      const queryParams = `?avatar_owned_skill_products_sort=${avatarOwnedSkillProductSort}`;
      mutate(`/api/v1/avatar/owned_products${queryParams}`);
      alert(data.message);
      toggleModal();
    }
  }, [product, product.avatar_owned_product_id]);

  return { handleApplyUseSkillProduct };
};
