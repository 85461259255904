import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import propTypes from 'prop-types';
import axios from 'axios';
import dayjs from 'dayjs';
import store from '../../lib/redux/store';
import { fetchAttendanceData } from '../../lib/redux/actions/attendance';
import DateUtils from '@date-io/dayjs';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import CommonAddButton from '../common/CommonAddButton';
import { useTranslation } from 'react-i18next';

const USER_ROLE = {
  MASTER: 'master',
  MEMBER: 'member',
};

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersDay: {
      current: {
        color: '#00a0e9',
      },
      daySelected: {
        backgroundColor: '#00a0e9',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#00a0e9',
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        borderColor: ' #00a0e9',
        '&:focus': {
          borderColor: '#00a0e9',
        },
      },
      notchedOutline: {
        borderColor: '#00a0e9 !important',
      },
    },
  },
});

const DEFAULT_PAID_HOLIDAY_COUNT = 10;

const FormAddPaidHoliday = () => {
  // access redux store
  const currentUser = useSelector((state) => state.setUser.currentUser);

  const [isShowAddPaidHolidayFormShow, setIsShowAddPaidHolidayFormShow] =
    useGlobal('isShowAddPaidHolidayFormShow');
  const [inputList, setInputList] = useState([
    { userId: '', addedDays: DEFAULT_PAID_HOLIDAY_COUNT },
  ]);
  const [members, setMembers] = useState([]);
  const [selectedDate, handleDateChange] = useState(new Date());
  const { t } = useTranslation();

  useEffect(() => {
    getMembers();
    store.dispatch({ type: 'SET_UPDATED_USER_ATTENDANCE', refresh: false });
  }, []);

  const getMembers = () => {
    axios.get('/api/v1/add_paid_holidays').then((res) => {
      setMembers(res.data.all_users);
    });
  };

  const handleAddInput = () => {
    setInputList([
      ...inputList,
      { userId: '', addedDays: DEFAULT_PAID_HOLIDAY_COUNT },
    ]);
  };

  const decrementValue = (e, key) => {
    const list = [...inputList];
    const previousCount = list[key].addedDays;
    list[key].addedDays = previousCount - 1;
    setInputList(list);
  };

  const incrementValue = (e, key) => {
    const list = [...inputList];
    const previousCount = list[key].addedDays;
    list[key].addedDays = previousCount + 1;
    setInputList(list);
  };

  const handleChangeMemberSelect = (e, key) => {
    const list = [...inputList];
    list[key].userId = e.target.value;
    setInputList(list);
  };

  const handleSubmit = () => {
    const dateInput = dayjs(selectedDate).format('YYYYMMDD');
    const addingUsersList = inputList.map((m) => ({
      user_id: m.userId,
      added_days: m.addedDays,
    }));
    const params = {
      // date: dateInput,
      master_id: currentUser.id,
      add_paid_holidays: addingUsersList,
    };
    axios
      .post('/api/v1/add_paid_holidays', params)
      .then((res) => {
        //updateAttendanceData();
        store.dispatch({ type: 'SET_UPDATED_USER_ATTENDANCE', refresh: true });
        setIsShowAddPaidHolidayFormShow(false);
        alert(t('AttendanceMessageGranted', {}));
      })
      .catch((error) => {
        const message = error.response.data.message;
        alert(message);
      });
  };

  return (
    <PaidHolidayFormWrapper>
      <PaidHolidayFormClose
        onClick={() => setIsShowAddPaidHolidayFormShow(false)}
      >
        <img src={RailsAssetPath('close-img-gray.png')} />
      </PaidHolidayFormClose>
      <PaidHolidayFormTitle>
        {t('AttendanceTextGrantHoliday', {})}
      </PaidHolidayFormTitle>
      <PaidHolidayFormContent>
        {/* <div>
          <div className='mb-2'>{t('AttendanceTextDateOfGrant', {})}</div>
          <MuiPickersUtilsProvider utils={DateUtils} locale='ja'>
            <ThemeProvider theme={materialTheme}>
              <DatePicker
                autoOk
                value={selectedDate}
                placeholder={`${new Date()}`}
                onChange={(date) => handleDateChange(date)}
                //minDate={new Date()}
                format='YYYY/MM/DD'
                inputVariant='outlined'
                variant='inline'
                disableToolbar
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </div> */}
        {inputList.map((i, key) => (
          <AddHolidayRow
            addedDays={i.addedDays}
            incrementValue={(e) => incrementValue(e, key)}
            decrementValue={(e) => decrementValue(e, key)}
            members={members}
            handleChangeMemberSelect={(e) => handleChangeMemberSelect(e, key)}
            key={key}
          />
        ))}
        <PaidHolidayFormAdd onClick={handleAddInput}>
          <CommonAddButton />
          {t('GenelalButtonAdd', {})}
        </PaidHolidayFormAdd>
        <PaidHolidayFormSubmit onClick={handleSubmit}>
          {t('AttendanceButtonGrant', {})}
        </PaidHolidayFormSubmit>
      </PaidHolidayFormContent>
    </PaidHolidayFormWrapper>
  );
};

const PaidHolidayFormWrapper = styled.div`
  padding: 15px;
  position: relative;
`;

const PaidHolidayFormContent = styled.div`
  text-align: center;
  font-size: 14px;
  padding: 20px 20px 35px;
  input {
    padding: 10px;
    cursor: pointer;
    text-align: center;
    color: #565656;
    letter-spacing: 1px;
  }
`;

const PaidHolidayFormClose = styled.div`
  font-size: 14px;
  position: absolute;
  left: 15px;
  top: 15px;
  width: 15px;
  cursor: pointer;
`;

const PaidHolidayFormTitle = styled.div`
  font-size: 17px;
  text-align: center;
  margin: 15px 0;
  color: #777777;
`;

const PaidHolidayFormAdd = styled.div`
  text-align: left;
  margin: 10px 0;
  cursor: pointer;
  letter-spacing: 1px;
`;

const PaidHolidayFormSubmit = styled.button`
  width: 200px;
  height: 35px;
  margin: 0 auto;
  background: #03a9f4;
  color: #fff;
  border-radius: 8px;
  font-size: 12px;
`;

const AddHolidayRow = ({
  addedDays,
  incrementValue,
  decrementValue,
  members,
  handleChangeMemberSelect,
}) => {
  AddHolidayRow.propTypes = {
    addedDays: propTypes.number,
    incrementValue: propTypes.func,
    decrementValue: propTypes.func,
    members: propTypes.array,
    handleChangeMemberSelect: propTypes.func,
  };
  const { t } = useTranslation();

  const memberOptionNode = members.map((m, key) => (
    <option value={m.user_id} key={key}>
      {m.name}
    </option>
  ));

  return (
    <SingleRowContainer>
      <SingleRowMemberBox>
        <select onChange={handleChangeMemberSelect}>
          <option>{t('AttendanceTextMemberSelect', {})}</option>
          {memberOptionNode}
        </select>
      </SingleRowMemberBox>
      <SingleRowCounterBox>
        <CounterButton onClick={decrementValue}>−</CounterButton>
        <CounterValue>
          {addedDays}
          <span>{t('AttendanceTextDates', {})}</span>
        </CounterValue>
        <CounterButton onClick={incrementValue}>＋</CounterButton>
      </SingleRowCounterBox>
    </SingleRowContainer>
  );
};

const SingleRowContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;

const SingleRowMemberBox = styled.div`
  width: 200px;
  height: 35px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  select {
    text-align-last: center;
    width: 100%;
    height: 100%;
    background: #f6f6f6;
    font-size: 16px;
    appearance: none;
  }
  &::before {
    content: '';
    width: 12px;
    height: 12px;
    border: 6px solid transparent;
    border-top: 8px solid #6f6f6f;
    position: absolute;
    top: 15px;
    right: 15px;
  }
`;

const SingleRowCounterBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 160px;
`;

const CounterButton = styled.button`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #6f6f6f;
  line-height: 28px;
  font-size: 17px;
  color: #6f6f6f;
`;

const CounterValue = styled.div`
  font-size: 18px;
  line-height: 35px;
  letter-spacing: 1px;
  span {
    font-size: 10px;
  }
`;

export default FormAddPaidHoliday;
