import consumer from 'javascript/channels/consumer';

interface AddMemberParams {
  room_name: string | null;
  avatar_id: number | null;
  // avatar_ids: number[];
}

interface JoinMemberParams {
  room_name: string | null;
  avatar_id: number | null;
  twilio_room_id: number | null;
}

interface LeaveMemberParams {
  room_name: string | null;
}

export const directCallChannel = (
) => {

  const subscribeDirectCallChannel = () => {
    return consumer.subscriptions.create(
      { channel: 'DirectCallChannel' },
      {
        connected() {
        },

        received() {
        },

        add_member_for_direct_call(params: AddMemberParams){
          return this.perform('add_member_for_direct_call', params);
        },
        join_for_direct_call(params:JoinMemberParams) {
          return this.perform('join_for_direct_call', params);
        },
        leave_for_direct_call(params:LeaveMemberParams) {
          return this.perform('leave_for_direct_call',params);
        },
      }
    );
  };
  return { subscribeDirectCallChannel };
};
