import React, { useGlobal } from 'reactn';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useDeleteVirtualWorld } from 'javascript/features/virtualWorld/hooks/useDeleteVirtualWorld';
import { useShowVirtualWorldSettingModal } from 'javascript/features/virtualWorld/hooks/useShowVirtualWorldSettingModal';
import { useToggleFavorite } from 'javascript/features/virtualWorld/hooks/useToggleFavorite';
import { useToggleSortMode } from 'javascript/features/virtualWorld/hooks/useToggleSortMode';

interface Props {
  isOpen: boolean;
  isStarred: boolean;
  toggleSetting: (e: React.MouseEvent) => void;
  id: number;
  hasUser: boolean;
  creatorId: number | null;
}

export const VirtualWorldSettingMenu: React.FC<Props> = ({
  isOpen,
  isStarred,
  toggleSetting,
  id,
  hasUser,
  creatorId,
}) => {
  const { startSortMode } = useToggleSortMode();
  // TODO 型付け
  // @ts-ignore
  const currentUser = useSelector((state) => state.setUser.currentUser);
  const currenUserId = currentUser?.id;

  const isMaster = currentUser?.role === 'master';

  const isAbleToDelete = isMaster || currenUserId === creatorId;
  const [roomFilterId] = useGlobal<{ roomFilterId: number }>('roomFilterId');
  const { showVirtualWorldSettingModal } = useShowVirtualWorldSettingModal(
    id,
    hasUser
  );

  const { deleteVirtualWorld } = useDeleteVirtualWorld(
    id,
    hasUser,
    isAbleToDelete
  );

  const { addFavorite, deleteFavorite, isStarredLocal } = useToggleFavorite(
    id,
    isStarred
  );

  const { t } = useTranslation();

  const handleOnClick = (cb: () => void, e: React.MouseEvent) => {
    toggleSetting(e);
    cb();
  };

  return (
    <VirtualWorldMenuBox isOpen={isOpen}>
      <CloseIconWrapper onClick={(e) => toggleSetting(e)}>
        <img
          // @ts-ignore
          src={RailsAssetPath('close-img-gray.png')}
          width={12}
          height={12}
        />
      </CloseIconWrapper>
      <VirtualWorldMenuItem
        onClick={(e) => handleOnClick(showVirtualWorldSettingModal, e)}
      >
        {/* @ts-ignore */}
        <VirtualWorldMenuItemIcon icon={RailsAssetPath('icon_edit.png')} />
        {t('GenelalButtonEdit', {})}
      </VirtualWorldMenuItem>
      <VirtualWorldMenuItem
        onClick={(e) => handleOnClick(deleteVirtualWorld, e)}
        isAbleToDelete={isAbleToDelete}
        isToDelete
      >
        <VirtualWorldMenuItemIcon
          icon={
            isAbleToDelete
              ? // @ts-ignore
                RailsAssetPath('delete_red.png')
              : // @ts-ignore
                RailsAssetPath('delete_gray.png')
          }
        />
        {t('GenelalButtonDelete', {})}
      </VirtualWorldMenuItem>
      {(!roomFilterId || roomFilterId == 1) && (
        <VirtualWorldMenuItem onClick={startSortMode}>
          <VirtualWorldMenuItemIcon
            // @ts-ignore
            icon={RailsAssetPath('icon_sort.png')}
          />
          {t('GenelalButtonSort', {})}
        </VirtualWorldMenuItem>
      )}

      {(!roomFilterId || roomFilterId == 1) && (
        <VirtualWorldMenuItem
          onClick={isStarredLocal ? deleteFavorite : addFavorite}
        >
          <VirtualWorldMenuItemIcon
            // @ts-ignore
            icon={RailsAssetPath(
              isStarredLocal ? 'icon_star_remove.png' : 'icon_star.png'
            )}
          />
          {isStarredLocal
            ? t('GenelalButtonUnstar', {})
            : t('GenelalButtonStar', {})}
        </VirtualWorldMenuItem>
      )}
    </VirtualWorldMenuBox>
  );
};

const VirtualWorldMenuBox = styled.div<{
  isOpen: boolean;
}>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  min-width: 104px;
  padding: 8px 0;
  color: #303030;
  font-size: 10px;
  text-align: left;
  border-radius: 5px;
  backdrop-filter: blur(12px);
  background-color: rgba(0, 160, 233, 0.02);
  box-shadow: 0px 0px 3px 0px grey;
  position: absolute;
  top: 10px;
  right: 10px;
  white-space: nowrap;
  z-index: 1;
`;

const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 24px;
  height: 24px;
  background: #f2f2f2;
  border: 2px solid #fff;
  border-radius: 50%;
`;

const VirtualWorldMenuItem = styled.div<{
  isAbleToDelete?: boolean;
  isToDelete?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  color: ${({ isAbleToDelete, isToDelete }) =>
    isToDelete && !isAbleToDelete && '#999'};
  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`;

const VirtualWorldMenuItemIcon = styled.i<{ icon: string }>`
  display: inline-block;
  padding: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
  vertical-align: middle;
  background-image: ${({ icon }) => `url(${icon})`};
`;
