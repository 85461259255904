import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AvatarDisplayOnMypage } from 'javascript/features/userProfile/components/AvatarDisplayOnMypage';
import { UserProfile } from 'javascript/features/userProfile/components/UserProfile';
import { useDeleteUser } from 'javascript/features/userProfile/hooks/useDeleteUser';
import { device } from 'javascript/utils/mediaQuery';
import { UserProfile as TypeUserProfile } from 'types';
import { useLocation } from 'react-router-dom';

interface Props {
  userData: TypeUserProfile;
  isEditable: boolean;
  isMaster: boolean;
  isCurrentUser: boolean;
  handleEditUser: (userData: TypeUserProfile) => void;
  isCast: boolean;
}

export const UserProfileDisplay: React.FC<Props> = ({
  userData,
  isEditable,
  isMaster,
  isCurrentUser,
  handleEditUser,
  isCast,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 430 });
  const { handleDeleteUser } = useDeleteUser(userData.user.user_id);

  const userIdsSortByRespected = userData.user_ids;
  const currentPageUserId = userData.user.user_id;
  const currentPageUserIndex = userData.user_ids.findIndex(
    (userId) => userId === currentPageUserId
  );

  // 戻る・進むための矢印アイコンを押したとき、どのユーザーIDのページを表示させるかを判定する
  const previousUserId =
    currentPageUserIndex !== 0
      ? userIdsSortByRespected[currentPageUserIndex - 1]
      : userIdsSortByRespected[userIdsSortByRespected.length - 1];

  const forwardUserId =
    currentPageUserIndex !== userIdsSortByRespected.length - 1
      ? userIdsSortByRespected[currentPageUserIndex + 1]
      : userIdsSortByRespected[0];

  const search = useLocation().search;
  const query2 = new URLSearchParams(search);

  return (
    <StyledUserProfileDisplay>
      {!isCast && (
        !isMobile ? (
          <StyledButtonToPreviousUser>
            <Link to={`/mypage/${previousUserId}?sort_id=${query2.get('sort_id')}&select_id=${query2.get('select_id')}`}>
              {/** @ts-ignore */}
              <img src={RailsAssetPath('mypage/back_icon.png')} />
            </Link>
          </StyledButtonToPreviousUser>
        ) : (
          <StyledMobileArrowIconWrapper>
            <StyledButtonToPreviousUser>
              <Link to={`/mypage/${previousUserId}?sort_id=${query2.get('sort_id')}&select_id=${query2.get('select_id')}`}>
                {/** @ts-ignore */}
                <img src={RailsAssetPath('mypage/back_icon.png')} />
              </Link>
            </StyledButtonToPreviousUser>
            {isMaster && (
              <StyledDeleteButton onClick={handleDeleteUser}>
                {t('EmployeeAlertDeleteMember', {})}
              </StyledDeleteButton>
            )}
            <StyledButtonToForwardUser>
              <Link to={`/mypage/${forwardUserId}?sort_id=${query2.get('sort_id')}&select_id=${query2.get('select_id')}`}>
                {/* @ts-ignore */}
                <img src={RailsAssetPath('mypage/forward_icon.png')} />
              </Link>
            </StyledButtonToForwardUser>
          </StyledMobileArrowIconWrapper>
        )
      )}
      <AvatarDisplayOnMypage
        avatarParts={userData.avatar}
        isCurrentUser={isCurrentUser}
        userId={userData.user.user_id}
      />
      <UserProfile
        userData={userData}
        isEditable={isEditable}
        handleEditUser={handleEditUser}
      />
    </StyledUserProfileDisplay>
  );
};

const StyledUserProfileDisplay = styled.div`
  position: relative;
  padding: 0 48px;
  width: 100%;
  text-align: center;
  @media ${device.sp} {
    padding: 0;
  }
`;

const StyledButtonToPreviousUser = styled.div`
  position: fixed;
  top: 96px;
  left: 48px;
  cursor: pointer;
  img {
    width: 12px;
  }
  @media ${device.sp} {
    position: static;
    img {
      width: 14px;
    }
  }
`;

const StyledButtonToForwardUser = styled.div`
  cursor: pointer;
  img {
    width: 14px;
  }
`;

const StyledMobileArrowIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledDeleteButton = styled.div`
  color: #f00;
  font-size: 12px;
`;
