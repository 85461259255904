import React from 'react';
import styled from 'styled-components';
import { SecretChatAvatar } from 'javascript/features/secretChat/components/SecretChatAvatar';
import { SecretChatMember } from 'types';

interface Props {
  avatars: SecretChatMember[];
  currentAvatarId: number;
  ownSecretChatId: number;
  isHost: boolean;
  handleDeleteChatMember: (avatarId: number, secretChatId: number) => void;
}

export const SecretChatModalHeader: React.FC<Props> = ({
  avatars,
  currentAvatarId,
  ownSecretChatId,
  isHost,
  handleDeleteChatMember,
}) => (
  <StyledSecretChatModalHeader>
    <StyledSecretChatAvatars>
      {avatars &&
        avatars.length !== 0 &&
        avatars.map((avatar) => (
          <SecretChatAvatar
            key={avatar.avatar_id}
            currentAvatarId={currentAvatarId}
            secretChatId={ownSecretChatId}
            avatar={avatar}
            isHost={isHost}
            handleDeleteChatMember={handleDeleteChatMember}
          />
        ))}
    </StyledSecretChatAvatars>
    {avatars && avatars.length > 6 && <StyledArrowRight />}
  </StyledSecretChatModalHeader>
);

const StyledSecretChatModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 4px 8px 0;
  border-bottom: 1px solid #ddd;
`;

const StyledSecretChatAvatars = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 3px;
  width: 270px;
  height: 60px;
  overflow: auto;
`;

const StyledArrowRight = styled.div`
  width: 6px;
  height: 6px;
  margin-right: 12px;
  border-top: solid 1px #707070;
  border-right: solid 1px #707070;
  transform: rotate(45deg);
`;
