import { device } from 'javascript/utils/mediaQuery';
import React from 'react';
import styled, { css } from 'styled-components';
import { UserAvatar } from 'types';

interface Props {
  userImage: string;
  avatar?: Omit<UserAvatar, 'id'>;
}

export const AvatarImageWrapper: React.FC<Props> = ({ avatar, userImage }) => (
  <StyledAvatarImageWrapper>
    <StyledAvatarImage>
      {/* @ts-ignore */}
      <img src={userImage || RailsAssetPath('noimage_icon.png')} />
    </StyledAvatarImage>
    <StyledAvatarStatusTypeImage position='top' hasStatus={avatar?.item}>
      <img
        // @ts-ignore
        src={RailsAssetPath(
          `freeSpace/status/item/${avatar?.item ? avatar.item : 'icon'}.png`
        )}
      />
    </StyledAvatarStatusTypeImage>
    <StyledAvatarStatusTypeImage position='left' hasStatus={avatar?.summon}>
      <img
        // @ts-ignore
        src={RailsAssetPath(
          `freeSpace/status/summon/${
            avatar?.summon ? avatar.summon : 'icon'
          }.png`
        )}
      />
    </StyledAvatarStatusTypeImage>
    <StyledAvatarStatusTypeImage position='right' hasStatus={avatar?.defense}>
      <img
        // @ts-ignore
        src={RailsAssetPath(
          `freeSpace/status/defense/${
            avatar?.defense ? avatar.defense : 'icon'
          }.png`
        )}
      />
    </StyledAvatarStatusTypeImage>
  </StyledAvatarImageWrapper>
);

const StyledAvatarImageWrapper = styled.div`
  position: relative;
  width: 48px;
  height: 100%;
`;

const StyledAvatarImage = styled.div`
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 34px;
  height: 34px;
  img {
    border: 1px solid #fff;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    object-fit: cover;
    @media ${device.sp} {
      width: 28px;
      height: 28px;
    }
  }
`;

const Top = css`
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const Left = css`
  bottom: 0;
  left: 0;
  @media ${device.sp} {
    bottom: 4px;
  }
`;

const Right = css`
  bottom: 0;
  right: 0;
  @media ${device.sp} {
    bottom: 4px;
  }
`;

const StyledAvatarStatusTypeImage = styled.div<{
  position: 'top' | 'right' | 'left';
  hasStatus?: number | null;
}>`
  position: absolute;
  ${({ position }) =>
    position === 'top' ? Top : position === 'left' ? Left : Right}
  img {
    width: ${({ hasStatus }) => (hasStatus ? '14px' : '11px')};
    height: ${({ hasStatus }) => (hasStatus ? '14px' : '11px')};
  }
`;
