import React, { useCallback, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { useGlobal } from 'reactn';
import { ReservedPostForumModal } from 'javascript/features/reservedPostForum/components';

import FormRegisterForum from '../form/FormRegisterForum';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: '0px',
    background: 'white',
  },
  overlay: {
    background: 'rgba(62, 62, 62, 0.75)',
    zIndex: '100',
    height: '100vh',
    overflow: 'auto'
  },
};

Modal.setAppElement('body'); //react-modal初期設定

const ModalEnterReport = () => {
  const [isShowEnterReportModal, setIsShowEnterReportModal] = useGlobal(
    'isShowEnterReportModal'
  );
  const [showReservationDateModal, setShowReservationDateModal] =
    useState(false);
  const [selectedDate, setSelected] = useState();
  const setSelectedDate = useCallback((date) => setSelected(date), []);

  const [time, setTime] = useState({ hour: '00', minutes: '00' });

  const formattedDate = useMemo(
    () =>
      selectedDate &&
      `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${selectedDate.getDate()}`,
    [selectedDate]
  );

  const closeModal = () => {
    setIsShowEnterReportModal(false);
    setSelectedDate(undefined);
  };

  return (
    <>
      {isShowEnterReportModal && showReservationDateModal && (
        <ReservedPostForumModal
          setShowReservationDateModal={setShowReservationDateModal}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          time={time}
          setTime={setTime}
        />
      )}
      <Modal
        isOpen={isShowEnterReportModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <FormRegisterForum
          setShowReservationDateModal={setShowReservationDateModal}
          selectedDate={formattedDate}
          setSelectedDate={setSelectedDate}
          time={time}
        />
      </Modal>
    </>
  );
};

export default ModalEnterReport;
