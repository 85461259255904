import { useCallback } from 'react';
import axios from 'axios';
import { useSWRConfig } from 'swr';

export const useToggleOpenStatus = (
  officeName: string,
  pageNumber: number,
  isOpen?: boolean
) => {
  const { mutate } = useSWRConfig();

  const toggleOpenStatus = useCallback(async () => {
    axios
      .post('/api/v1/thanks/update_thanks_open', {
        boolean: !isOpen,
      })
      .then(() => mutate(`/api/v1/thanks/${officeName}?page=${pageNumber}`));
  }, [isOpen]);

  return { toggleOpenStatus };
};
