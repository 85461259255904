import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import VoiceChatConfigButton from "./VoiceChatConfigButton";
import VoiceChatPointBalance from "./VoiceChatPointBalance";

const VoiceChatSettingButton = ({ isMaster, plan }) => {
  const isMobile = useMediaQuery({ maxWidth: 430 });

  return (
    <VoiceChatButtonWrapper>
      {(isMaster && plan === 0 && !isMobile) && <VoiceChatPointBalance /> }
      <VoiceChatConfigButton />
    </VoiceChatButtonWrapper>
  )
}

const VoiceChatButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export default VoiceChatSettingButton;