import React from 'react';
import { EnterButton } from 'javascript/shared/components/Button/EnterButton';

interface Props {
  handleSubmit: () => void;
}

export const EnterMainModalFooter: React.FC<Props> = ({ handleSubmit }) => (
  <div className='flex justify-center'>
    <EnterButton onClick={handleSubmit} />
  </div>
);
