import React from 'react';
import styled from 'styled-components';
import MainParticipant from './MainParticipant';
import ParticipantList from './ParticipantList';
const VideoCallRoom = () => {
  return (
    <Container>
      <ParticipantList />
      <MainParticipant />
    </Container>
  ); 
}

const Container = styled.div`
  height: 100vh;  
  background-color:#000;
  overflow:hidden;
`


export default VideoCallRoom;