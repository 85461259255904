import { useCallback } from 'react';
import { useGlobal } from "reactn";
import useVoiceContext from "../../../components/voice/hooks/useVoiceContext";
import { useTranslation } from 'react-i18next';


export const useEnterRealWorld = (realWorld, videoConnectStatus) => {
  const [_, setSelectedRoom] = useGlobal("selectedRoom");
  const [isShowEnterOfficeModal, setIsShowEnterOfficeModal] = useGlobal("isShowEnterOfficeModal");

  const { room } = useVoiceContext();
  const { t } = useTranslation();

  const enterRealWorld = useCallback(() => {
    if(realWorld.enter_flg) return;
    if (room || videoConnectStatus() ) {
      alert(t("GenelalAlertCallNow",{}));
      return;
    } else {
      setSelectedRoom(realWorld.id);
      setIsShowEnterOfficeModal(true);
    }
  }, [realWorld.enter_flg, realWorld.id, room]);
  
  return { enterRealWorld }
}