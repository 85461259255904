import { useState } from 'react';
import { Avatar } from 'types';

const useInvitedUser = () => {
  const [invitedUsers, setInvitedUsers] = useState<Avatar[]>([]);

  return {
    invitedUsers,
    setInvitedUsers,
  };
};

export default useInvitedUser;
