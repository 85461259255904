import React from 'react';
import styled from 'styled-components';
import { useOpenModal } from 'javascript/features/avatarShop/hooks/useOpenModal';
import { MyboxProductModal } from 'javascript/features/mybox/components/MyboxProductModal';
import { AvatarPaidProductType, OwnedProductInMybox } from 'types';
import { AvatarOwnedSkillProductSort } from '../hooks/useMybox';

const StyledMyboxProduct = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 187px;
  position: relative;
`;

const StyledMyboxProductImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  height: 100%;
  max-height: 120px;
  padding: 12px 0;
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  img.thumbnail {
    max-width: 64px;
    max-height: 64px;
  }
`;

const StyledPresentIcon = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  img {
    width: 16px;
  }
`;

const StyledUserIcon = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

const StyledItemCount = styled.p`
  position: absolute;
  bottom: 4px;
  left: 4px;
  font-size: 12px;
  span {
    font-size: 14px;
    font-weight: bold;
  }
`;

const StyledCheckInput = styled.input`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  z-index: 1;
  &:checked + label {
    background-color: #f5f5f5;
  }
`;

interface Props {
  product: OwnedProductInMybox;
  selectedShopProductType: AvatarPaidProductType;
  currentAvatarId: number | undefined;
  avatarOwnedSkillProductSort: AvatarOwnedSkillProductSort,
  setCheckedProduct: (product: OwnedProductInMybox) => void;
}

export const MyboxProduct: React.FC<Props> = ({
  product,
  selectedShopProductType,
  currentAvatarId,
  avatarOwnedSkillProductSort,
  setCheckedProduct,
}) => {
  const { isOpen, toggleModal } = useOpenModal();

  return (
    <StyledMyboxProduct>
      <StyledCheckInput type="checkbox" id={`check-${product.product_number}`} onClick={() => setCheckedProduct(product)} />
      <StyledMyboxProductImageWrapper onClick={toggleModal}>
        <img
          className={'thumbnail'}
          src={
            selectedShopProductType !== 'hair'
              ? // @ts-ignore
              RailsAssetPath(
                `avatar_parts/${selectedShopProductType}/${product.product_number}.png`
              )
              : // @ts-ignore
              RailsAssetPath(
                `avatar_parts/${selectedShopProductType}/black/${product.product_number}.png`
              )
          }
        />
        {product.present_flg && (
          <>
            <StyledPresentIcon>
              {/* @ts-ignore */}
              <img src={RailsAssetPath('mybox/present_icon.png')} />
            </StyledPresentIcon>
            <StyledUserIcon>
              <img
                src={
                  product.presented_user_image ||
                  // @ts-ignore
                  RailsAssetPath('noimage_icon.png')
                }
              />
            </StyledUserIcon>
          </>
        )}
        {product.item_count > 1 && (
          <StyledItemCount>
            ×<span>{product.item_count}</span>
          </StyledItemCount>
        )}
      </StyledMyboxProductImageWrapper>
      {isOpen && (
        <MyboxProductModal
          product={product}
          selectedShopProductType={selectedShopProductType}
          toggleModal={toggleModal}
          currentAvatarId={currentAvatarId}
          avatarOwnedSkillProductSort={avatarOwnedSkillProductSort}
        />
      )}
    </StyledMyboxProduct>
  );
};
