import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import axios from 'axios';
import store from '../../lib/redux/store';
import { fetchUserData } from '../../lib/redux/actions/user';
import { useSelector, useDispatch } from 'react-redux';
import ProfileThumbnail from '../items/ProfileThumbnail';
import { setVoiceChatRoom } from '../../lib/redux/actions/voiceChat';
import { PREMIUM } from '../../shared/constants/plan';
import { EnterMainModalFooter } from '../../features/realWorld/components/EnterMainModal/EnterMainModalFooter';
import { EnterRoomModalFooter } from '../../features/virtualWorld/components/EnterRoomModal/EnterRoomModalFooter';
import Clipboard from '../items/Clipboard';
import styled from 'styled-components';
import { useGetOfficeData } from '../../features/realWorld/hooks/useGetOfficeData';
import { useTranslation } from 'react-i18next';

const FormEnterRoom = () => {
  // access redux store
  const currentUser = useSelector((state) => state.setUser.currentUser);
  const [selectedRoom, setSelectedRoom] = useGlobal('selectedRoom');
  const [isShowEnterOfficeModal, setIsShowEnterOfficeModal] = useGlobal(
    'isShowEnterOfficeModal'
  );

  const dispatch = useDispatch();

  const [roomDetail, setRoomDetail] = useState('');
  /**
   * 追加分
   */
  const [limitHour, setLimitHour] = useState(0);
  const [limitMinute, setLimitMinute] = useState(0);
  const [selected, setSelected] = useState(false);
  /**
   * 追加分終了
   */

  const plan = useSelector((state) => state.officePlan.plan);

  const { officeData, getOfficeData } = useGetOfficeData();

  const convertImage = (imageUrl) => {
    if (imageUrl.includes('under_water')) {
      return 'world_under_water';
    } else if (imageUrl.includes('sky')) {
      return 'world_sky';
    } else if (imageUrl.includes('universe')) {
      return 'world_universe';
    } else if (imageUrl.includes('under_ground')) {
      return 'world_under_ground';
    } else if (imageUrl.includes('tree')) {
      return 'world_tree';
    } else if (imageUrl.includes('sweet')) {
      return 'world_sweet';
    } else if (imageUrl.includes('neon')) {
      return 'world_neon';
    } else if (imageUrl.includes('flower')) {
      return 'world_flower';
    } else if (imageUrl.includes('chinese')) {
      return 'world_chinese';
    } else if (imageUrl.includes('desert')) {
      return 'world_desert';
    } else if (imageUrl.includes('resort')) {
      return 'world_resort';
    } else if (imageUrl.includes('festival')) {
      return 'world_festival';
    } else if (imageUrl.includes('hawaii')) {
      return 'world_hawaii';
    } else if (imageUrl.includes('ghost')) {
      return 'world_ghost';
    } else if (imageUrl.includes('european')) {
      return 'world_european';
    } else if (imageUrl.includes('halloween')) {
      return 'world_halloween';
    } else if (imageUrl.includes('japanese-garden')) {
      return 'world_japanese-garden';
    } else if (imageUrl.includes('christmas')) {
      return 'world_christmas';
    } else if (imageUrl.includes('newyear')) {
      return 'world_newyear';
    } else if (imageUrl.includes('choco')) {
      return 'world_choco';
    } else {
      return;
    }
  };

  const convertToLocalImage = (worldTheme, fetchedImage) =>
    `worldTheme/${worldTheme}-room-${fetchedImage.charAt(
      fetchedImage.length - 5
    )}.png`;
  const { t } = useTranslation();

  useEffect(() => {
    axios.get(`/api/v1/office/rooms/${selectedRoom}`).then((res) => {
      const theme = convertImage(res.data.user_room_image || '');
      const localImage = convertToLocalImage(
        theme,
        res.data.user_room_image || ''
      );
      setRoomDetail({ ...res.data, userRoomImage: localImage });
    });
  }, [selectedRoom]);

  /**
   * 追加分
   */
  useEffect(() => {
    if (
      (limitHour != 0 && limitMinute != 0) ||
      (limitHour != 0 && limitMinute == 0) ||
      (limitHour == 0 && limitMinute != 0)
    ) {
      setSelected(true);
    } else if (limitHour == 0 && limitMinute == 0) {
      setSelected(false);
    }
  }, [limitHour, limitMinute]);

  //TODOリファクタする
  const handleChangeHour = (e) => {
    const s = e.target;
    const hour = s.options[s.selectedIndex].value;
    setLimitHour(hour);
    if (hour == '8') {
      $('option[value="15"]').css('display', 'none');
      $('option[value="30"]').css('display', 'none');
      $('option[value="45"]').css('display', 'none');
    } else {
      $('option[value="15"]').css('display', 'block');
      $('option[value="30"]').css('display', 'block');
      $('option[value="45"]').css('display', 'block');
    }
  };

  const handleChangeMinute = (e) => {
    const s = e.target;
    const minute = s.options[s.selectedIndex].value;
    setLimitMinute(minute);
    if (minute == '0') {
      $('option[value="8"]').css('display', 'block');
    } else {
      $('option[value="8"]').css('display', 'none');
    }
  };

  const params = {
    finish_hours: limitHour,
    finish_minutes: limitMinute,
  };
  /**
   * 追加分終了
   */

  if (!roomDetail.users) {
    return null;
  }

  const updateUserInfo = () => {
    store.dispatch(fetchUserData());
  };

  const handleSubmit = (zoom = true) => {
    if (
      currentUser.work_status !== 'working' &&
      (roomDetail.type === 'MAIN' || roomDetail.room_type === 'nonfunctional')
    ) {
      alert(t('GenelalAlertNoWork', {}));
      return;
    }
    /**
     * 追加分
     */
    if (roomDetail.room_type === 'nonfunctional' && !selected) {
      alert(t('RoomAlertSetstaytime', {}));
      return;
    }
    /**
     * 追加分終了
     */
    // プレミアムプランのみVideoRoomへ接続させ、enterは実行しない（ビデオチャット参加時にenterを実行)
    if (roomDetail.unique_room_name != (null | undefined) && zoom) {
      window.open(
        window.location.origin + '/video_call/' + roomDetail.unique_room_name,
        '_blank'
      );
      setIsShowEnterOfficeModal(false);
      return;
    }
    const url = `/api/v1/office/rooms/${selectedRoom}/enter`;
    axios
      .post(url, params)
      .catch((error) => {
        const message = error.response.data.errors[0].message;
        alert(message);
      })
      .then((res) => {
        getOfficeData();
        currentUser.speaker && handleSound();
        if (zoom) {
          if (roomDetail.url && roomDetail.url != 'null') {
            window.open(roomDetail.url, '_blank');
          }
        }
        setIsShowEnterOfficeModal(false);
      });
  };

  const updateVoiceChat = () => {
    const args = {
      created: true,
      roomId: roomDetail.id,
      twilioRoomId: roomDetail.twilio_room_id,
    };
    dispatch(setVoiceChatRoom(args));
  };

  const handleVoiceChat = () => {
    if (currentUser.twilio_remain_point <= 0 && plan !== PREMIUM) {
      dispatch({ type: 'SET_TYPE_CALL_LIMIT', isShow: true });
      return;
    }

    const url = `/api/v1/office/rooms/${selectedRoom}/enter`;
    axios
      .post(url)
      .catch((error) => {
        const message = error.response.data.errors[0].message;
        alert(message);
      })
      .then((res) => {
        //handleSound();
        // if (zoom) {
        //   if (roomDetail.url && roomDetail.url != "null") {
        //     window.open(roomDetail.url, "_blank");
        //   }
        // }
        getOfficeData();
        setIsShowEnterOfficeModal(false);
        updateUserInfo();
        updateVoiceChat();
      });
  };

  var music = new Audio();
  function init() {
    music.preload = 'auto';
    music.src = '/assets/audio_room_enter.m4a';
    music.load();

    music.addEventListener(
      'ended',
      function () {
        music.currentTime = 0;
        // music.play();
      },
      false
    );
  }

  function play() {
    music.play();
  }

  function stop() {
    music.pause();
    music.currentTime = 0;
  }
  // let stop = setTimeout(music.pause(), 800);
  const handleSound = () => {
    init();
    play();
    clearTimeout(stop);
  };

  /**
   * 追加分
   */
  const limitHours = [];
  for (let i = 0; i <= 8; i++) {
    limitHours.push(i);
  }
  const limitMinutes = [0, 15, 30, 45];

  const limitHourOptions = limitHours.map((i, key) => (
    <option value={i} key={key}>
      {i}
    </option>
  ));

  const limitMinutesOptions = limitMinutes.map((i, key) => (
    <option value={i} key={key}>
      {i}
    </option>
  ));
  /**
   * 追加分終了
   */

  const roomName = roomDetail.name;

  let roomDescription;
  if (roomDetail.note == 'null' || !roomDetail.note) {
    roomDescription = t('RoomAlertNoDescription', {});
  } else {
    roomDescription = roomDetail.note;
  }

  const modalRoomImage =
    roomDetail.type === 'MAIN' ? 'square.png' : roomDetail.userRoomImage;

  return (
    <>
      <div className='enteroffice__wrapper'>
        <CloseButtonWrapper>
          <CloseButton>
            <img
              src={RailsAssetPath('close-img-gray.png')}
              onClick={() => {
                setIsShowEnterOfficeModal(false);
              }}
              alt=''
            />
          </CloseButton>
        </CloseButtonWrapper>
        <div>
          <OfficeName>{roomName}</OfficeName>
          <ConfirmEnter>
            {t('RoomConfirmGoIntoRoom', { room: '' })}
          </ConfirmEnter>
          <div className='image-container image-container--enter'>
            <img src={RailsAssetPath(modalRoomImage)} />
          </div>
          <OfficeMembers>
            {roomDetail.users.length ? (
              roomDetail.users.map((u, key) => (
                <OfficeMemberBox key={key}>
                  <ProfileThumbnail image={u.image} />
                  <MemberName>{u.name}</MemberName>
                  {roomDetail.room_type === 'nonfunctional' && (
                    <StyledOutTimeWrapper>
                      <p>{t('RoomTextOuttime', {})}</p>
                      <p>{u.scheduled_leave_at}</p>
                    </StyledOutTimeWrapper>
                  )}
                </OfficeMemberBox>
              ))
            ) : (
              <div>{t('RoomAlertNoOneIsHere', {})} </div>
            )}
          </OfficeMembers>
          <OfficeExplainBox>
            <p>{roomDescription}</p>
          </OfficeExplainBox>
          {roomDetail.create_user_name && (
            <OfficeExplainBox>
              <p>
                {t('RoomTextCreatedUserName', {})}:{roomDetail.create_user_name}
              </p>
            </OfficeExplainBox>
          )}
          <OfficeExplainBox>
            <p>{'部屋作成日：'}{roomDetail.create_date}</p>
          </OfficeExplainBox>
          {roomDetail.room_type === 'nonfunctional' && (
            <div>
              <p className='inputbox__meetingtime'>
                <img
                  className='inputbox__text'
                  src={RailsAssetPath('clock.png')}
                  alt='時計'
                />
                {t('RoomTextStaytime', {})}
              </p>
              <div className='inputbox__time'>
                <div className='inputbox__wrapper'>
                  <select
                    className='inputbox inputbox__hour'
                    name='finish-hour'
                    onChange={handleChangeHour}
                  >
                    {limitHourOptions}
                  </select>
                  <p className='placeholder'>h</p>
                </div>
                <div className='inputbox__wrapper'>
                  <select
                    className='inputbox inputbox__minute'
                    name='finish-minute'
                    onChange={handleChangeMinute}
                  >
                    {limitMinutesOptions}
                  </select>
                  <p className='placeholder'>m</p>
                </div>
                <div></div>
              </div>
            </div>
          )}
          {/* TODO FormEnterRoom component でなぜかstyledコンポーネントが効かないのなぜか調べる */}
          {roomDetail.type === 'MAIN' ? (
            <EnterMainModalFooter handleSubmit={handleSubmit} />
          ) : (
            <div className='enter-room__footer'>
              <EnterRoomModalFooter
                plan={plan}
                roomDetail={roomDetail}
                handleSubmit={handleSubmit}
                handleVoiceChat={handleVoiceChat}
              />
            </div>
          )}
        </div>
      </div>
      {roomDetail.unique_room_name != (null | undefined) &&
        roomDetail.type !== 'MAIN' &&
        roomDetail.room_type == 'video' && (
          <div className='office__url-link'>
            <SpacerTop />
            <SpacerMain />
            <SpacerBottom />
            <div className='office__url-link__wrapper'>
              <div className='office__url-link__text'></div>
              <Clipboard
                value={
                  roomDetail.unique_room_name == (null | undefined)
                    ? null
                    : window.location.origin +
                      '/video_call/' +
                      roomDetail.unique_room_name
                }
              />
            </div>
          </div>
        )}
    </>
  );
};
// 無理やりですがモーダルを上下に分割
const SpacerTop = styled.div`
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  width: 100%;
  bottom: 4px;
  height: 10px;
`;

const SpacerMain = styled.div`
  height: 32px;
  background-color: rgba(0, 0, 0, 0);
`;
const SpacerBottom = styled.div`
  background-color: #fff;
  position: relative;
  border-radius: 50px;
  bottom: -2px;
  height: 6px;
`;

const OfficeMembers = styled.div`
  margin-top: 20px;
  max-height: 130px;
  overflow: auto;
`;

const OfficeMemberBox = styled.div`
  width: 80px;
  display: inline-block;
  margin: 0 5px;
`;

const MemberName = styled.p`
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CloseButtonWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 85%;
  height: 38px;
  background: #fff;
`;

const CloseButton = styled.div`
  cursor: pointer;
  width: 19px;
`;

const OfficeExplainBox = styled.div`
  font-size: 12px;
  margin: 12px 0 22px;
  line-height: 18px;
  p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }
`;

const OfficeName = styled.p`
  font-size: 14px;
  margin: 40px 0 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
`;

const ConfirmEnter = styled.p`
  font-size: 12px;
  color: #707070;
`;

const StyledOutTimeWrapper = styled.div`
  margin-top: 4px;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:nth-child(2) {
      color: #f00;
    }
  }
`;

export default FormEnterRoom;
