import React, { useRef } from 'react';
import styled, { keyframes } from 'styled-components';

interface StyledProps {
  isHovered: boolean;
}

const showTooltip = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StyledTooltip = styled.div<{
  isHovered: boolean;
  elmWidth: number | undefined;
  isNeedToFlipSide: boolean;
}>`
  display: ${({ isHovered }) => (isHovered ? 'block' : 'none')};
  position: absolute;
  top: -8px;
  right: ${({ elmWidth }) => elmWidth && `${1.5 - (elmWidth - 13) / 2}px`};
  max-width: 210px;
  padding: 0 4px;
  background-color: #666;
  border-radius: 8px;
  color: #fff;
  font-size: 10px;
  text-align: center;
  z-index: 2;
  transform: ${({ isNeedToFlipSide }) => isNeedToFlipSide && 'scale(-1, 1)'};
  animation: ${showTooltip} 0.5s ease;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform: scale(0.8);
  }
`;

interface Props extends StyledProps {
  text: string;
  // FreeSpaceでアバターが右向きの場合、ツールチップの名前が反転して表示されてしまうので、
  // それを正常に表示させるためにさらに反転して表示するためのbool値
  isNeedToFlipSide?: boolean;
}

export const Tooltip: React.FC<Props> = ({
  isHovered,
  text,
  isNeedToFlipSide = false,
}) => {
  const elm = useRef<HTMLDivElement>(null);

  return (
    <StyledTooltip
      ref={elm}
      isHovered={isHovered}
      elmWidth={elm.current?.clientWidth}
      isNeedToFlipSide={isNeedToFlipSide}
    >
      <p>{text}</p>
    </StyledTooltip>
  );
};

