import { useEffect, useRef } from 'react';

export const useMoveElementRef = () => {
  const moveRef = useRef<HTMLDivElement>(null);

  const onMouseUp = () => {
    document.body.removeEventListener('mousemove', onMouseMove);
  };

  const onMouseMove = (e: MouseEvent) => {
    const x = e.clientX;
    const y = e.clientY;
    const width = moveRef.current?.offsetWidth;
    const height = moveRef.current?.offsetHeight;
    // メッセージリストの親要素であるモーダル自体の位置を変更
    if (moveRef.current?.parentElement && width && height) {
      moveRef.current.parentElement.style.top = y - height / 2 + 'px';
      moveRef.current.parentElement.style.left = x - width / 2 + 'px';
    }

    moveRef.current?.addEventListener('mouseup', onMouseUp);
  };

  const onMouseDown = () => {
    document.body.addEventListener('mousemove', onMouseMove);
  };

  useEffect(() => {
    if (moveRef) {
      moveRef.current?.addEventListener('mousedown', onMouseDown);
    }
  });

  return { moveRef };
};
