import { useCallback } from 'react';
import axios from 'axios';
import { useSWRConfig } from 'swr';
import { useTranslation } from 'react-i18next';

export const useChangeSkillStatusToHandle = (
	productId: number | undefined,
	avatarOwnedSkillProductSort: string | null
) => {
	const { mutate } = useSWRConfig();
	const { t } = useTranslation();

	const changeSKillStatusToHandle = useCallback(async () => {
		// TODO: i18n
		const isConfirmed = confirm("このアイテムのステータスを対応済みに変更しますか？");
		if (!isConfirmed) return;

		const params = {
			avatar_owned_product_id: productId,
			avatar_original_product_id: null,
		};

		await axios.post('/api/v1/avatar/product/handle', params);
		const queryParams = `?avatar_owned_skill_products_sort=${avatarOwnedSkillProductSort}`;
		mutate(`/api/v1/avatar/owned_products${queryParams}`);
		mutate(`/api/v1/avatar/product/order${queryParams}`);
		alert(t('MyboxConfirmStatusiscompleted'));
	}, [productId]);

	return { changeSKillStatusToHandle };
};
