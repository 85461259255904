import { useCallback, useState } from 'react';
import { AvatarToPresent } from 'types';

export const useSelectAvatar = (avatars: AvatarToPresent[] | undefined) => {
  const [avatarId, setAvatarId] = useState('');

  const handleSelectAvatar = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      setAvatarId(value);
    },
    [avatarId]
  );

  const targetAvatar = avatars?.find(
    (avatar) => avatar.avatar_id.toString() === avatarId
  );

  return { targetAvatar, handleSelectAvatar, setAvatarId };
};
