import React from 'react';
import styled from 'styled-components';
import { SubRoomMember } from 'javascript/features/subRooms/components/SubRoomMember';
import { device } from 'javascript/utils/mediaQuery';
import { SubRoomUser } from 'types';

interface Props {
  users: SubRoomUser[];
  isCast: boolean;
}

export const SubRoomMemberBox: React.FC<Props> = ({ users, isCast }) => (
  <SubRoomMemberBoxWrapper>
    {users.map((user) => (
      <SubRoomMember key={user.user_id} user={user} isCast={isCast} />
    ))}
  </SubRoomMemberBoxWrapper>
);

const SubRoomMemberBoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  gap: 1px;
  max-height: 58px;
  margin-top: 8px;
  padding: 0 12px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media ${device.sp} {
    max-height: 20px;
    padding-right: 46px;
    gap: 0;
  }
`;
