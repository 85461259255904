import axios from "axios";

export function fetchRooms() {
  return (dispatch) => {
    axios.get('/api/v1/rooms').then(res => {
      dispatch({ type: 'SET_ROOM_CREATE_OR_EDIT', rooms: res.data.rooms });
    });
  }
}

export function setEditRoomId(id) {
  return {
    type: 'SET_EDIT_ROOM_ID',
    id: id
  }
}