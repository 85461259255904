import React from 'react';
import useMainParticipant from './hooks/useMainParticipant';
import useVideoCallContext from './hooks/useVideoCallContext';
import {useSelectedParticipant} from './hooks/useSelectedParticipant';
import useScreenShareParticipant from './hooks/useScreenShareParticipant';
import ParticipantTracks from './ParticipantTracks';
import MainParticipantInfo from './MainParticipantInfo';
import useWindowDimensions from './hooks/useWindowDimentions';

const MainParticipant = () => {

  const { room , localTracks, isSharingScreen} = useVideoCallContext();
  const mainParticipant = useMainParticipant();
  const localParticipant = room.localParticipant;
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();

  const videoPriority = null
  if (!mainParticipant) return null; // screenShareのみ

  return(
    <MainParticipantInfo participant={mainParticipant}>
      <ParticipantTracks
        participant={mainParticipant}
        videoOnly
        enableScreenShare={true}
        videoPriority={videoPriority}
        isLocalParticipant={mainParticipant === localParticipant}
      />
    </MainParticipantInfo>

  )

}

export default MainParticipant;