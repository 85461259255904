import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from 'axios';
import useVoiceContext from 'javascript/components/voice/hooks/useVoiceContext';
import { useGetOfficeData } from 'javascript/features/realWorld/hooks/useGetOfficeData';

export const useMoveToSleep = (videoConnectStatus: () => void) => {
  // TODO 型付け
  // @ts-ignore
  const currentUser = useSelector((state) => state.setUser.currentUser);
  const { getOfficeData } = useGetOfficeData();

  const { room } = useVoiceContext();
  const { t } = useTranslation();

  const moveToSleep = useCallback(() => {
    if (room || videoConnectStatus()) {
      alert(t('GenelalAlertCallNow', {}));
      return;
    }
    if (currentUser.work_status != 'working') {
      alert(t('GenelalAlertNoWork', {}));
      return;
    }

    const result = window.confirm(t('OfficeConfirmTakeBreak', {}));
    if (result) {
      axios
        .post('/api/v1/office/rooms/normal_room_out')
        .then(() =>
          axios
            .post('/api/v1/office/rooms/lounge/enter')
            .then(() => getOfficeData())
        );
    }
  }, [room, videoConnectStatus, currentUser.work_status, getOfficeData]);

  return { moveToSleep };
};
