import axios from 'axios';
import useSWR from 'swr';
import { OwnedProductListInDressRoom } from 'types';

export const useOwnedProductList = () => {
  const fetcher = (url: string) =>
    axios.get<OwnedProductListInDressRoom>(url).then(({ data }) => data);

  const { data: ownedProductList } = useSWR(
    '/api/v1/avatar/dress_owned_products',
    fetcher
  );

  return { ownedProductList };
};
