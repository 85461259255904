export const languages = [
  'ja',
  'zh-CN',
  'zh-TW',
  'en',
  'fr',
  'de',
  'it',
  'ko',
  'ru',
  'es',
  'pt',
];
