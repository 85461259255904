/* eslint-disable @typescript-eslint/no-explicit-any */

const youtubeEmbed = (isEmbed: boolean) => {
  return () => {
    return new Promise<void>((resolve) => {
      if (isEmbed) {
        resolve();
        return;
      }

      const doc = (<any>window).document;
      const playerApiScript = doc.createElement('script');
      playerApiScript.type = 'text/javascript';
      playerApiScript.src = 'https://www.youtube.com/iframe_api';
      doc.body.appendChild(playerApiScript);

      (<any>window).onYouTubeIframeAPIReady = () => {
        resolve();
      };
    });
  };
};
export default youtubeEmbed;
