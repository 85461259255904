/**
髪の毛のパーツを表示する時に、どの髪色を表示するかがサーバーからnumberとして返却されるため、
そのnumberを元に、実際に使われる髪色の画像を決定するための定数
 */
export const hairColorArray = [
  'black',
  'brown',
  'gold',
  'silver',
  'orange',
  'blue',
  'green',
] as const;
