import React from 'react';
import {IconButton, Button, Badge } from '@material-ui/core';
import useParticipants from '../hooks/useParticipants';

const NumberParticipantButton = ({ onClick }) => {
  const participants = useParticipants();
  const participantsCount = participants.length + 1;
  return (
    <Button>
    <Badge
      badgeContent={participantsCount} showZero style={{'color': '#fff'}}
    >
      <img src={RailsAssetPath("video_call/icon/participant.png")} className="button_icon" />

    </Badge>

    </Button>
  );
}
export default NumberParticipantButton;