import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, keyframes } from 'styled-components';
import {
  MQ_DESKTOP,
  MQ_MOBILE,
} from 'javascript/components/landingPage/lib/MQ';
import { pcPx, px, spp } from 'javascript/components/landingPage/lib/cssUtil';
import {
  onScrollPromise,
  removeOnscrollEvent,
} from 'javascript/components/landingPage/local/scrollPromise';
import {
  BLACK,
  fadeInStyle,
  GRAY,
  jpStyle,
  onlySP,
} from 'javascript/components/landingPage/local/commonCss';
import { useScrollYStoreValue } from 'javascript/components/landingPage/store/scrollYStore';
import {
  LANGUAGE,
  useLanguageStoreValue,
} from 'javascript/components/landingPage/store/language';

// @ts-ignore
const ASSETS_ISLAND01 = RailsAssetPath('lp/ourmission/island01.png');
// @ts-ignore
const ASSETS_ISLAND02 = RailsAssetPath('lp/ourmission/island02.png');
// @ts-ignore
const ASSETS_PLANET = RailsAssetPath('lp/ourmission/planet.png');
// @ts-ignore
const ASSETS_ROOM = RailsAssetPath('lp/ourmission/room.png');
// @ts-ignore
const ASSETS_NEON = RailsAssetPath('lp/ourmission/neon.png');

const path = keyframes({
  from: { strokeDashoffset: spp(-1500) },
  to: { strokeDashoffset: spp(1500) },
});

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const PcWrapper = styled.div`
  position: relative;
  ${MQ_MOBILE} {
    padding: ${spp(48)};
    margin-top: ${spp(190)};
    margin-bottom: ${spp(0)};
  }
  ${MQ_DESKTOP} {
    width: 960px;
    height: 100%;
    margin: auto;
    padding: ${pcPx(360)} ${pcPx(268)} ${pcPx(300)};
    margin-bottom: ${pcPx(10)};
  }
  &:before {
    content: '';
    background-color: rgba(255, 255, 255, 0.77);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    ${MQ_MOBILE} {
      width: ${spp(520)};
      height: ${spp(488)};
      top: ${spp(-70)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(1148)};
      height: ${pcPx(990)};
      top: ${pcPx(100)};
    }
  }
`;

const Title = styled.h3`
  ${jpStyle}
  ${fadeInStyle}
  color: #535353;
  font-weight: bold;
  position: relative;
  overflow: visible;
  ${MQ_MOBILE} {
    font-size: ${spp(45)};
    line-height: ${spp(77)};
    margin-top: ${spp(80)};
    margin-bottom: ${spp(0)};
    padding-bottom: ${spp(35)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(70)};
    line-height: ${pcPx(119)};
    margin-top: ${pcPx(10)};
    margin-bottom: ${pcPx(10)};
    padding-bottom: ${pcPx(5)};
  }
`;

const SubTitle = styled.h4`
  ${jpStyle}
  ${fadeInStyle}
  color: #535353;
  font-weight: bold;
  position: relative;
  overflow: visible;
  ${MQ_MOBILE} {
    font-size: ${spp(22)};
    line-height: ${spp(51)};
    margin-top: ${spp(0)};
    margin-bottom: ${spp(25)};
    padding-bottom: ${spp(35)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(1200)};
    font-size: ${pcPx(36)};
    line-height: ${pcPx(70)};
    margin-top: ${pcPx(10)};
    margin-bottom: ${pcPx(20)};
    padding-bottom: ${pcPx(30)};
    margin-left: ${pcPx(34)};
  }
  span {
    ${MQ_MOBILE} {
      padding-bottom: ${spp(8)};
      border-bottom: solid ${spp(3)} #535353;
    }
    ${MQ_DESKTOP} {
      padding-bottom: ${pcPx(8)};
      border-bottom: solid ${pcPx(3)} #535353;
    }
  }
`;

const Text = styled.p`
  ${jpStyle}
  ${fadeInStyle}
  color: ${BLACK};
  position: relative;
  overflow: visible;
  ${MQ_MOBILE} {
    width: ${spp(600)};
    font-size: ${spp(20)};
    line-height: ${spp(34)};
    margin-top: ${spp(10)};
    margin-bottom: ${spp(200)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(1200)};
    font-size: ${pcPx(24)};
    line-height: ${pcPx(41)};
    margin-top: ${pcPx(5)};
    padding-bottom: ${pcPx(0)};
    margin-bottom: ${pcPx(0)};
    margin-left: ${pcPx(34)};
  }
`;

const PlanetImg = styled.div`
  ${fadeInStyle}
  position: absolute;
  background: url(${ASSETS_PLANET}) no-repeat;
  background-size: contain;
  ${MQ_MOBILE} {
    width: ${spp(178)};
    height: ${spp(178)};
    margin-bottom: ${spp(30)};
    top: ${spp(-60)};
    left: ${spp(10)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(233)};
    height: ${pcPx(233)};
    margin-bottom: ${pcPx(10)};
    top: ${pcPx(140)};
    left: ${pcPx(20)};
  }
`;

const Island01Img = styled.div`
  ${fadeInStyle}
  transition-delay: 0.15s;
  position: absolute;
  background: url(${ASSETS_ISLAND01}) no-repeat;
  background-size: contain;
  ${MQ_MOBILE} {
    width: ${spp(157)};
    height: ${spp(157)};
    margin-bottom: ${spp(30)};
    bottom: ${spp(40)};
    left: ${spp(0)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(273)};
    height: ${pcPx(273)};
    margin-bottom: ${pcPx(10)};
    bottom: ${pcPx(0)};
    left: ${pcPx(60)};
  }
`;

const Island02Img = styled.div`
  ${fadeInStyle}
  transition-delay: 0.05s;
  position: absolute;
  background: url(${ASSETS_ISLAND02}) no-repeat;
  background-size: contain;
  ${MQ_MOBILE} {
    width: ${spp(205)};
    height: ${spp(205)};
    margin-bottom: ${spp(30)};
    bottom: ${spp(220)};
    right: ${spp(-90)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(302)};
    height: ${pcPx(302)};
    margin-bottom: ${pcPx(10)};
    bottom: ${pcPx(600)};
    right: ${pcPx(165)};
  }
`;

const NeonImg = styled.div`
  ${fadeInStyle}
  transition-delay: 0.1s;
  position: absolute;
  background: url(${ASSETS_NEON}) no-repeat;
  background-size: contain;
  ${MQ_MOBILE} {
    width: ${spp(197)};
    height: ${spp(197)};
    margin-bottom: ${spp(30)};
    top: ${spp(-30)};
    right: ${spp(0)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(286)};
    height: ${pcPx(286)};
    margin-bottom: ${pcPx(10)};
    top: ${pcPx(130)};
    right: ${pcPx(160)};
  }
`;

const RoomImg = styled.div`
  ${fadeInStyle}
  transition-delay: 0.25s;
  position: absolute;
  background: url(${ASSETS_ROOM}) no-repeat;
  background-size: contain;
  ${MQ_MOBILE} {
    width: ${spp(209)};
    height: ${spp(209)};
    margin-bottom: ${spp(30)};
    bottom: ${spp(-10)};
    right: ${spp(25)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(347)};
    height: ${pcPx(347)};
    margin-bottom: ${pcPx(10)};
    bottom: ${pcPx(90)};
    right: ${pcPx(0)};
  }
`;

const svgStyle = css`
  position: absolute;
  margin: auto;
  svg {
    width: 100%;
    height: 100%;
    g {
      stroke: ${GRAY};
    }
  }
`;

const BoxStyle = css`
  ${svgStyle}
  ${onlySP}
  ${MQ_MOBILE} {
    width: ${spp(416)};
    height: ${spp(578)};
    top: ${spp(-10)};
    left: ${spp(-270)};
  }
`;

const Box = styled.div`
  ${BoxStyle}
`;

const BoxAnim = styled.div`
  ${BoxStyle}
  svg {
    g {
      stroke: ${BLACK};
      stroke-width: 2;
      stroke-dashoffset: ${spp(1500)};
      stroke-dasharray: ${spp(1500)};
      animation: ${path} 3s linear forwards infinite;
    }
  }
`;

const Box02Style = css`
  ${svgStyle}
  ${onlySP}
  ${MQ_MOBILE} {
    width: ${spp(417)};
    height: ${spp(580)};
    top: ${spp(-40)};
    right: ${spp(-330)};
  }
`;

const Box02 = styled.div`
  ${Box02Style}
`;

const Box02Anim = styled.div`
  ${Box02Style}
  svg {
    g {
      stroke: ${BLACK};
      stroke-width: 2;
      stroke-dashoffset: ${spp(1500)};
      stroke-dasharray: ${spp(1500)};
      animation: ${path} 3s linear forwards infinite;
    }
  }
`;

const OurMission = () => {
  const fvRef = useRef<HTMLDivElement | null>(null);
  const subTitleRef = useRef<HTMLDivElement | null>(null);
  const textRef = useRef<HTMLDivElement | null>(null);
  const img01Ref = useRef<HTMLDivElement | null>(null);
  const img02Ref = useRef<HTMLDivElement | null>(null);
  const img03Ref = useRef<HTMLDivElement | null>(null);
  const img04Ref = useRef<HTMLDivElement | null>(null);
  const img05Ref = useRef<HTMLDivElement | null>(null);
  const [isFvRefScrolled, setIsFvRefScrolled] = useState(false);
  const [isSubTitleRefScrolled, setIsSubTitleRefScrolled] = useState(false);
  const [isTextRefScrolled, setIsTextRefScrolled] = useState(false);
  const boxRef = useRef<HTMLDivElement | null>(null);
  const box02Ref = useRef<HTMLDivElement | null>(null);
  const scrollYStoreValue = useScrollYStoreValue();
  const languageStoreValue = useLanguageStoreValue();
  const { t } = useTranslation();

  useEffect(() => {
    onScrollPromise(fvRef.current)().then(() => setIsFvRefScrolled(true));
    onScrollPromise(subTitleRef.current)().then(() =>
      setIsSubTitleRefScrolled(true)
    );
    onScrollPromise(textRef.current)().then(() => setIsTextRefScrolled(true));
    return () => {
      removeOnscrollEvent(fvRef.current);
      removeOnscrollEvent(subTitleRef.current);
      removeOnscrollEvent(textRef.current);
    };
  }, []);

  const getY = (
    ref: MutableRefObject<HTMLDivElement | null>,
    scrollY: number
  ) => {
    if (!ref || !ref.current) return 0;
    const y =
      (scrollY -
        (ref.current.getBoundingClientRect().top + window.pageYOffset)) *
      0.1;
    return y;
  };

  return (
    <Wrapper>
      <PcWrapper>
        <Title
          ref={fvRef}
          data-visible={isFvRefScrolled}
          data-lang-ru={languageStoreValue === LANGUAGE.ru}
        >
          {t('ourmission.title')}
        </Title>
        <SubTitle
          ref={subTitleRef}
          data-visible={isSubTitleRefScrolled}
          data-lang-ru={languageStoreValue === LANGUAGE.ru}
        >
          <span>{t('ourmission.copy')}</span>
        </SubTitle>
        <Text
          ref={textRef}
          data-visible={isTextRefScrolled}
          data-lang-ru={languageStoreValue === LANGUAGE.ru}
        >
          <b>{t('ourmission.subtitle01')}</b>
          <br />
          {t('ourmission.text01')}
          <br />
          <br />
          <b>{t('ourmission.subtitle02')}</b>
          <br />
          {t('ourmission.text02')}
          <br />
          <br />
          <b>{t('ourmission.subtitle03')}</b>
          <br />
          {t('ourmission.text03')}
          <br />
          <br />
          {t('ourmission.text04')}
        </Text>
        <PlanetImg ref={img01Ref} data-visible={isFvRefScrolled} />
        <Island01Img ref={img02Ref} data-visible={isTextRefScrolled} />
        <Island02Img ref={img03Ref} data-visible={isTextRefScrolled} />
        <NeonImg ref={img04Ref} data-visible={isFvRefScrolled} />
        <RoomImg ref={img05Ref} data-visible={isTextRefScrolled} />
      </PcWrapper>
      <Box
        ref={boxRef}
        style={{
          transform: `translate3d(0, ${px(
            getY(boxRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='650.277'
          height='709.703'
          viewBox='0 0 650.277 709.703'
        >
          <g
            transform='translate(0 208.305) rotate(-30)'
            fill='none'
            stroke='#707070'
            strokeWidth='1'
          >
            <rect width='416.609' height='578.965' stroke='none' />
            <rect
              x='0.5'
              y='0.5'
              width='415.609'
              height='577.965'
              fill='none'
            />
          </g>
        </svg>
      </Box>
      <BoxAnim
        style={{
          transform: `translate3d(0, ${px(
            getY(boxRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='650.277'
          height='709.703'
          viewBox='0 0 650.277 709.703'
        >
          <g
            transform='translate(0 208.305) rotate(-30)'
            fill='none'
            stroke='#707070'
            strokeWidth='1'
          >
            <rect width='416.609' height='578.965' stroke='none' />
            <rect
              x='0.5'
              y='0.5'
              width='415.609'
              height='577.965'
              fill='none'
            />
          </g>
        </svg>
      </BoxAnim>
      <Box02
        ref={box02Ref}
        style={{
          transform: `translate3d(0, ${px(
            getY(box02Ref, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='652.153'
          height='711.752'
          viewBox='0 0 652.153 711.752'
        >
          <g
            transform='translate(0 208.906) rotate(-30)'
            fill='none'
            stroke='#707070'
            strokeWidth='1'
          >
            <rect width='417.812' height='580.635' stroke='none' />
            <rect
              x='0.5'
              y='0.5'
              width='416.812'
              height='579.635'
              fill='none'
            />
          </g>
        </svg>
      </Box02>
      <Box02Anim
        style={{
          transform: `translate3d(0, ${px(
            getY(box02Ref, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='652.153'
          height='711.752'
          viewBox='0 0 652.153 711.752'
        >
          <g
            transform='translate(0 208.906) rotate(-30)'
            fill='none'
            stroke='#707070'
            strokeWidth='1'
          >
            <rect width='417.812' height='580.635' stroke='none' />
            <rect
              x='0.5'
              y='0.5'
              width='416.812'
              height='579.635'
              fill='none'
            />
          </g>
        </svg>
      </Box02Anim>
    </Wrapper>
  );
};

export default OurMission;
