import { useState, useEffect, useCallback} from 'react';
import { getDeviceInfo } from '../utils';
import useMediaStreamTrack from './useMediaStreamTrack';
import useVideoCallContext from '../hooks/useVideoCallContext';
const useDevices = () => {

  const [deviceInfo, setDeviceInfo] = useState(getDeviceInfo>>>({
    audioInputDevices: [],
    videoInputDevices: [],
    audioOutputDevices: [],
    hasAudioInputDevices: false,
    hasVideoInputDevices: false,
  }));
  const { localTracks } = useVideoCallContext();
  const localAudioTrack = localTracks.find(track => track.kind === 'audio');
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;
  
  useEffect(() => {
    if (localAudioInputDeviceId && !deviceInfo?.audioInputDevices?.find(device => device.deviceId == localAudioInputDeviceId)){
      let newDevice = deviceInfo?.audioInputDevices?.find((device => device.kind === 'audioinput'))
      if (newDevice && newDevice.deviceId != ''){
        localAudioTrack?.restart({ deviceId: { exact: newDevice.deviceId } });
      }
    } else if (localAudioInputDeviceId == 'default') {
      // 既定のデバイスが取り外された場合にdefaultに対して再接続（Chromeのみ）
      localAudioTrack?.restart({ deviceId: { exact: localAudioInputDeviceId } });
    }
  },[localAudioTrack,localAudioInputDeviceId,deviceInfo])

  useEffect(() => {
    const getDevices = () => getDeviceInfo().then(devices => setDeviceInfo(devices));
    navigator.mediaDevices.addEventListener('devicechange', getDevices);
    getDevices();

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', getDevices);
    };
  }, []);

  
  return deviceInfo;
}
export default useDevices;


