import { useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export const useSubmitExchange = (
  exchangedViket: number,
  exchangedPoint: number,
  targetAvatarId: number | undefined,
  onClose: () => void
) => {
  const { t } = useTranslation();

  const submitExchange = useCallback(async () => {
    if (!targetAvatarId) {
      alert(t('MyboxPresentmodalSelecttarget'));
      return;
    }

    const isConfirmed = confirm(t('PaymentTextExchangeconfirmation'));
    if (!isConfirmed) return;

    const params = {
      viket: exchangedViket,
      point: exchangedPoint,
      to_avatar_id: targetAvatarId,
    };

    await axios.post('/api/v1/billing/payments/exchange_viket', params);
    alert(t('PaymentTextExchangecompleted'));
    onClose();
  }, [exchangedPoint, exchangedViket, targetAvatarId, onClose]);

  return { submitExchange };
};
