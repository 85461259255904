import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';
import styled, { css, keyframes } from 'styled-components';
import { FreeSpaceAvatarChatMessage } from 'javascript/features/freeSpace/components/FreeSpaceAvatarChatMessage';
import { FreeSpaceAvatarDirectCall } from 'javascript/features/freeSpace/components/FreeSpaceAvatarDirectCall';
import { PresentImage } from 'javascript/features/freeSpace/components/PresentImage';
import { usePresents } from 'javascript/features/freeSpace/hooks/usePresents';
import {
  eyeAppearanceInFreeSpace,
  mouthAppearanceInFreeSpace,
  clothAppearanceInFreeSpace,
  hairAppearanceInFreeSpace,
  hatAppearanceInFreeSpace,
} from 'javascript/features/avatarParts/constants/displayAvatarAreaInFreeSpace';
import { hairColorArray } from 'javascript/features/avatarParts/constants/hairColor';
import {
  Avatar,
  FixedWidthAndPosition,
  FixedHeightAndPosition,
  UserAvatar,
} from 'types';

interface Props {
  avatar: Avatar;
  currentAvatar?: UserAvatar;
  inviteSecretChat: (
    avatar: Avatar,
    e: React.MouseEvent<Element, MouseEvent>
  ) => void;
  inviteDirectCall: (
    avatar: Avatar,
    e: React.MouseEvent<Element, MouseEvent>
  ) => void;
  openMenuModal: (
    avatar: Avatar | null,
    e: React.MouseEvent<HTMLDivElement>
  ) => void;
}

export const FreeSpaceAvatar: React.FC<Props> = ({
  avatar,
  currentAvatar,
  openMenuModal,
  inviteSecretChat,
}) => {
  const [prevX, setPrevX] = useState<number>();
  const [prevY, setPrevY] = useState<number>();
  const [isInitialRender, setIsInitialRender] = useState(true);
  const isMobile = useMediaQuery({ maxWidth: 430 });

  const history = useHistory();

  const pushToMypage = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    history.push(`/mypage/${user_id}`);
  }, []);
  const {
    products_given_today,
    previous_x_coordinate,
    x_coordinate,
    previous_y_coordinate,
    y_coordinate,
    available_chat_secret_flg,
    user_id,
    eye,
    mouth,
    clothes,
    hair,
    hair_color,
    hat,
    user_image,
    summon_url,
    defense_url,
    item_url,
    level,
    open_chat_content,
    avatar_id,
    interact_world_enter_flag,
    interact_world_leave_flag,
    vike_ranking,
    owned_viket,
    is_happy
  } = avatar;

  const isSelfAvatar = currentAvatar?.id === avatar_id;

  const { formattedPresents, fixPresentPosition, moreCount } =
    usePresents(products_given_today);

  const horizontalDirection = useMemo(
    () =>
      previous_x_coordinate && x_coordinate - previous_x_coordinate > 0
        ? 'right'
        : 'left',
    [previous_x_coordinate, x_coordinate]
  );

  useEffect(() => {
    if (!isInitialRender) {
      setPrevX(previous_x_coordinate);
      setPrevY(previous_y_coordinate);
    }
    setIsInitialRender(false);
  }, [previous_x_coordinate, previous_y_coordinate]);

  // @ts-ignore
  const vike_ranking_img = RailsAssetPath(`freeSpace/vike_ranking.png`)
  // @ts-ignore
  const owned_viket_img = RailsAssetPath(`freeSpace/owned_viket.png`)

  return (
    <StyledAvatarWrapper
      x={x_coordinate}
      y={isMobile && y_coordinate > 80 ? 80 : y_coordinate}
      isAbleToInviteChat={available_chat_secret_flg}
      prevX={prevX}
      prevY={prevY && isMobile && prevY > 80 ? 80 : prevY}
      isJoin={interact_world_enter_flag}
      isLeave={interact_world_leave_flag}
    >
      <StyledAvatarImageWrapper>
        {user_id ? (
          <StyledAvatarAppearanceWrapper
            horizontalDirection={horizontalDirection}
            onClick={
              isSelfAvatar
                ? (e) => pushToMypage(e)
                : (e) => openMenuModal(avatar, e)
            }
          >
            <StyledBody
              // @ts-ignore
              src={RailsAssetPath(
                `avatar_parts/body/${clothes ? '1' : '2'}.png`
              )}
            />
            <StyledEyes
              fixedPosition={eyeAppearanceInFreeSpace[eye - 1]}
              // @ts-ignore
              src={RailsAssetPath(`avatar_parts/eye/${eye}.png`)}
            />
            <StyledMouth
              fixedPosition={mouthAppearanceInFreeSpace[mouth - 1]}
              // @ts-ignore
              src={RailsAssetPath(`avatar_parts/mouth/${mouth}.png`)}
            />
            {clothes && (
              <StyledCloth
                fixedPosition={clothAppearanceInFreeSpace[clothes - 1]}
                // @ts-ignore
                src={RailsAssetPath(`avatar_parts/clothes/${clothes}.png`)}
              />
            )}
            <StyledHair
              fixedPosition={hairAppearanceInFreeSpace[hair - 1]}
              // @ts-ignore
              src={RailsAssetPath(
                `avatar_parts/hair/${
                  hairColorArray[hair_color - 1]
                }/${hair}.png`
              )}
            />
            {hat && (
              <StyledHat
                fixedPosition={hatAppearanceInFreeSpace[hat - 1]}
                // @ts-ignore
                src={RailsAssetPath(`avatar_parts/hat/${hat}.png`)}
              />
            )}
            {is_happy && (
              // @ts-ignore
              <StyledHappyAura src={RailsAssetPath('freeSpace/happy_aura.png')} /> 
            )}
            {level && (
              <StyledLevel
                horizontalDirection={horizontalDirection}
              >{`Lv.${avatar.level}`}</StyledLevel>
            )}
            {formattedPresents && (
              <>
                {formattedPresents.map((present, i) => (
                  <PresentImage
                    present={present}
                    position={fixPresentPosition(i, present.product_type)}
                    horizontalDirection={horizontalDirection}
                  />
                ))}
                <StyledPresentIcon
                  // @ts-ignore
                  src={RailsAssetPath('mybox/present_icon.png')}
                />
                {moreCount && (
                  <StyledMoreCount horizontalDirection={horizontalDirection}>
                    +<span>{moreCount}</span>
                  </StyledMoreCount>
                )}
              </>
            )}
          </StyledAvatarAppearanceWrapper>
        ) : (
          <StyledAvatarImage
            // @ts-ignore
            src={user_image || RailsAssetPath('noimage_icon.png')}
            onClick={(e) => inviteSecretChat(avatar, e)}
          />
        )}

        {/* アバター画像を起点に、ステータスや吹き出しをabsoluteで配置する */}
        {summon_url && <StyledStatusImage src={summon_url} position='left' />}
        {item_url && <StyledStatusImage src={item_url} position='top' />}
        {defense_url && (
          <StyledStatusImage src={defense_url} position='right' />
        )}
        <div>
        {(vike_ranking != 0) && ( 
          <>
            <StyledVikeRanking horizontalDirection={horizontalDirection} isItem={item_url != ""}>
              <img src={vike_ranking_img} />
           </StyledVikeRanking>
           <div>
           <StyledVikeRankingSpan horizontalDirection={horizontalDirection} isItem={item_url != ""}>{`${avatar.vike_ranking}位`}</StyledVikeRankingSpan>
           </div>
           </>
        )}
        
        </div>
        <div>
        {(owned_viket != 0) && ( 
          <>
            <StyledOwnedViket horizontalDirection={horizontalDirection} isItem={item_url != ""}>
              <img src={owned_viket_img} />
            </StyledOwnedViket>
            <div>
              <StyledOwnedViketSpan horizontalDirection={horizontalDirection} isItem={item_url != ""} char_color={ owned_viket < 0 ? 'red' : 'black' }>{`${avatar.owned_viket.toLocaleString()}`}</StyledOwnedViketSpan>
            </div>
          </>
        )}
        </div>
        {open_chat_content && <FreeSpaceAvatarChatMessage avatar={avatar} />}
        <FreeSpaceAvatarDirectCall
          avatar={avatar}
          currentAvatar={currentAvatar}
          horizontalDirection={horizontalDirection}
          isItem={item_url != ""}
        />
      </StyledAvatarImageWrapper>
      {/* 移動時にアバター同士を重ならないようにするため、メニューモーダルを開くためのクリックイベントが
      発火するエリアを広く取るようにする */}
      <StyledWrapperToClickEvent
        hasItem={item_url}
        onClick={
          user_id
            ? isSelfAvatar
              ? (e) => pushToMypage(e)
              : (e) => openMenuModal(avatar, e)
            : (e) => inviteSecretChat(avatar, e)
        }
      />
    </StyledAvatarWrapper>
  );
};

const moving = (
  x: number,
  y: number,
  prevX: number,
  prevY: number
) => keyframes`
  0%{
    left: ${prevX}%;
    top: ${prevY}%;
  }
  100% {
    left: ${x}%;
    top: ${y}%;
  }
`;

const floating = keyframes`
  0%, 30%, 100% {
    transform: translateY(0);
  }
  15% {
    transform: translateY(-30%)
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 0;
    transform: scale(1);
  }
  25% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(0);
  }
`;

const StyledAvatarWrapper = styled.div<{
  x: number;
  y: number;
  isAbleToInviteChat: boolean;
  prevX?: number;
  prevY?: number;
  isJoin?: boolean | null;
  isLeave?: boolean | null;
}>`
  position: absolute;
  left: ${({ x }) => `${x}%`};
  top: ${({ y }) => `${y}%`};
  opacity: ${({ isAbleToInviteChat }) => !isAbleToInviteChat && `0.3`};
  animation: ${floating} 10s ease-in-out ${({ x, y }) => `${(x + y) / 20}s`}
      infinite,
    ${({ x, y, prevX, prevY }) => prevX && prevY && moving(x, y, prevX, prevY)}
      forwards 0.5s ease-in-out,
    ${({ isJoin, isLeave }) =>
      isJoin || isLeave
        ? isJoin
          ? css`
              ${fadeIn} 0.5s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards
            `
          : isLeave
          ? css`
              ${fadeOut} 0.5s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards
            `
          : `none`
        : `none`};
  &:hover {
    z-index: 2;
  }
`;

const StyledAvatarImageWrapper = styled.div`
  position: relative;
  width: 40px;
  z-index: 2;
  cursor: pointer;
`;

const StyledAvatarAppearanceWrapper = styled.div<{
  horizontalDirection: 'right' | 'left';
}>`
  transform: ${({ horizontalDirection }) =>
    horizontalDirection === 'right' && 'scale(-1, 1)'};
`;

const StyledBody = styled.img`
  height: 46px;
`;

const StyledEyes = styled.img<{ fixedPosition: FixedWidthAndPosition }>`
  position: absolute;
  top: ${({ fixedPosition }) => `${fixedPosition.top}px`};
  left: ${({ fixedPosition }) => `${fixedPosition.left}px`};
  width: ${({ fixedPosition }) => `${fixedPosition.width}px`};
  z-index: 1;
`;

const StyledMouth = styled.img<{ fixedPosition: FixedWidthAndPosition }>`
  position: absolute;
  top: ${({ fixedPosition }) => `${fixedPosition.top}px`};
  left: ${({ fixedPosition }) => `${fixedPosition.left}px`};
  width: ${({ fixedPosition }) => `${fixedPosition.width}px`};
  z-index: 4;
`;

const StyledCloth = styled.img<{ fixedPosition: FixedHeightAndPosition }>`
  position: absolute;
  top: ${({ fixedPosition }) => `${fixedPosition.top}px`};
  left: ${({ fixedPosition }) => `${fixedPosition.left}px`};
  height: ${({ fixedPosition }) => `${fixedPosition.height}px`};
  z-index: 3;
`;

const StyledHair = styled.img<{ fixedPosition: FixedWidthAndPosition }>`
  position: absolute;
  top: ${({ fixedPosition }) => `${fixedPosition.top}px`};
  left: ${({ fixedPosition }) => `${fixedPosition.left}px`};
  width: ${({ fixedPosition }) => `${fixedPosition.width}px`};
  z-index: 2;
`;

const StyledHat = styled.img<{ fixedPosition: FixedWidthAndPosition }>`
  position: absolute;
  top: ${({ fixedPosition }) => `${fixedPosition.top}px`};
  left: ${({ fixedPosition }) => `${fixedPosition.left}px`};
  width: ${({ fixedPosition }) => `${fixedPosition.width}px`};
  z-index: 5;
`;

const StyledAvatarImage = styled.img`
  height: 36px;
  object-fit: cover;
  cursor: pointer;
`;

const Left = css`
  left: -10px;
  bottom: 0px;
`;

const Top = css`
  top: -27px;
  left: 10px;
`;

const Right = css`
  right: -10px;
  bottom: 0px;
`;

const StyledStatusImage = styled.img<{ position: 'left' | 'top' | 'right' }>`
  position: absolute;
  width: 24px;
  height: 24px;
  ${({ position }) =>
    position === 'left' ? Left : position === 'top' ? Top : Right}
`;

const StyledVikeRanking = styled.div<{ isItem: true | false, horizontalDirection: 'right' | 'left' }>`
  position: absolute;
  width: 18px;
  height: 18px;
  top: ${({ isItem }) =>
  isItem === true ? '-20px;' : '-6px;'};
  left: ${({ horizontalDirection }) =>
    horizontalDirection === 'left' ? '-55px' : '36px'};
`;


const StyledVikeRankingSpan = styled.span<{ isItem: true | false, horizontalDirection: 'right' | 'left' }>`
  position: absolute;
  top: ${({ isItem }) =>
  isItem === true ? '-20px;' : '-6px;'};
  left: ${({ horizontalDirection }) =>
    horizontalDirection === 'left' ? '-30px' : '61px'};
  font-size: 10px;
  font-weight: bold;
  width: max-content;
`;



const StyledOwnedViket = styled.div<{ isItem: true | false , horizontalDirection: 'right' | 'left'}>`
  position: absolute;
  width: 16px;
  height: 16px;
  top: ${({ isItem }) =>
  isItem === true ? '-3px;' : '11px;'};
  left: ${({ horizontalDirection }) =>
    horizontalDirection === 'left' ? '-55px' : '36px'};
`;

const StyledOwnedViketSpan = styled.span<{ char_color: 'red' | 'black', isItem: true | false, horizontalDirection: 'right' | 'left'}>`
  position: absolute;
  top: ${({ isItem }) =>
  isItem === true ? '0px;' : '11px;'};
  left: -34px;
  left: ${({ horizontalDirection }) =>
    horizontalDirection === 'left' ? '-34px' : '57px'};
  font-size: 10px;
  color: ${({ char_color }) => `${char_color}`};
`;


const StyledPresentIcon = styled.img`
  position: absolute;
  top: -10px;
  right: -12px;
  width: 12px;
`;

const StyledMoreCount = styled.p<{ horizontalDirection: 'right' | 'left' }>`
  position: absolute;
  top: 66px;
  right: ${({ horizontalDirection }) =>
    horizontalDirection === 'right' ? '-8px' : '-82px'};
  font-size: 10px;
  transform: ${({ horizontalDirection }) =>
    horizontalDirection === 'right' && 'scale(-1, 1)'};
  span {
    font-size: 11px;
  }
`;

const StyledLevel = styled.p<{ horizontalDirection: 'right' | 'left' }>`
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: ${({ horizontalDirection }) =>
    horizontalDirection === 'right'
      ? 'translateX(-50%) scale(-1, 1)'
      : 'translateX(-50%)'};
  font-size: 10px;
`;

const StyledWrapperToClickEvent = styled.div<{ hasItem: string | null }>`
  position: absolute;
  top: ${({ hasItem }) => (hasItem ? '-56px' : '-40px')};
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: ${({ hasItem }) => (hasItem ? '120px' : '100px')};
  cursor: pointer;
`;

const StyledHappyAura = styled.img`
  position: absolute;
  min-width: 100px;
  height: 80px;
  bottom: -16px;
  right: -31px;
`;