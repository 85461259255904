import axios from 'axios';
import Push from 'push.js';

const NOTIFICATION_TIMEOUT = 1000 * 60 * 2;

const notificationJob = (fetchInterval, endpoint) => {
  requestNotification();
  let interval;
  clearInterval(interval);
  interval = setInterval(() => {
    fetchInfo(endpoint).then((res) => {
      const list = res.data.list;
      const userIdForDisconnectTwilio =
        res.data.disconnect_twilio_user_ids &&
        res.data.disconnect_twilio_user_ids[0];
      if (userIdForDisconnectTwilio) {
        if (userIdForDisconnectTwilio.type === 'voice') {
          axios
            .post('/api/v1/twilio/voices/room_leave')
            .then(() => console.log('voice call is forced to disconnect'));
          return;
        } else if (userIdForDisconnectTwilio.type === 'video') {
          const params = {
            is_guest: false,
          };
          axios
            .post('/api/v1/twilio/videocalls/room_leave', params)
            .then(() => console.log('video call is forced to disconnect'));
          return;
        }
      }
      if (list.length) {
        createNotification(list);
      }
    });
  }, fetchInterval);
};

const requestNotification = () => {
  if (!Push.Permission.has()) {
    Push.Permission.request();
  }
};

const createNotification = (arr) => {
  arr.forEach((item) => {
    if (item.key === 'direct_call') {
      axios.get('/api/v1/users/me').then(({ data }) => {
        if (data.speaker) {
          const sound = new Audio('/assets/audio_incoming_call.m4a');
          sound.play();
        }
      });
    }
    Push.create(item.message, {
      timeout: NOTIFICATION_TIMEOUT,
      icon: '/favicon.ico',
      onClick: function () {
        window.focus();
        if (item.url) {
          location.href = item.url;
        }
      },
    });
  });
};

const fetchInfo = (endpoint) => {
  return axios.get(endpoint);
};

const browserNotificationInfos = [
  // Feed内のReportにてもらうコメント
  {
    targetInfo: 'comment',
    fetchInterval: 5 * 1000,
    endpoint: '/api/v1/users/me/notification',
  },
  // TODO: taskとは何か？要確認
  {
    targetInfo: 'task',
    fetchInterval: 20 * 1000,
    endpoint: '/api/v1/users/me/info',
  },
];

export const batch = () => {
  browserNotificationInfos.map((info) => {
    notificationJob(info.fetchInterval, info.endpoint);
  });
};
