import { useState, useEffect, useCallback } from 'react';
import { SELECTED_BACKGROUND_SETTINGS_KEY } from '../constants';
import {
  GaussianBlurBackgroundProcessor,
  VirtualBackgroundProcessor,
  ImageFit,
  isSupported,
} from '@twilio/video-processors';


const images = [
  'underwater',
  'sky',
  'universe',
  'underground'
]


let imageElements = new Map();


export const backgroundConfig = {
  images,
};

const getImage = (index, url) => {
  return new Promise((resolve, reject) => {
    if (imageElements.has(index)) {
      return resolve(imageElements.get(index));
    }
    const img = new Image();
    img.onload = () => {
      imageElements.set(index, img);
      resolve(img);
    };
    img.onerror = reject;
    img.crossOrigin = "Anonymous";
    if (url) {
      img.src = url;
    }else {
      img.src = RailsAssetPath('video_call/virtual_background/' + images[index] + '.jpg');
    }
  });
};

const virtualBackgroundAssets = '/virtualbackground';
let blurProcessor;
let virtualBackgroundProcessor;

const  useBackgroundSettings = (videoTrack, room) => {
  const [backgroundSettings, setBackgroundSettings] = useState(() => {
    const localStorageSettings = window.localStorage.getItem(SELECTED_BACKGROUND_SETTINGS_KEY);
    return localStorageSettings ? JSON.parse(localStorageSettings) : { type: 'none', index: 0 };
  });


  const removeProcessor = useCallback(() => {
    if (videoTrack && videoTrack.processor) {
      videoTrack.removeProcessor(videoTrack.processor);
    }
  }, [videoTrack]);

  const addProcessor = useCallback((processor) => {
      if (!videoTrack || videoTrack.processor === processor) {
        return;
      }
      removeProcessor();
      videoTrack.addProcessor(processor);
    },
    [videoTrack, removeProcessor]
  );

  useEffect(() => {
    const handleProcessorChange = async () => {
      if (!blurProcessor) {
        blurProcessor = new GaussianBlurBackgroundProcessor({
          assetsPath: virtualBackgroundAssets,
        });
        await blurProcessor.loadModel();
      }
      if (!virtualBackgroundProcessor) {
        virtualBackgroundProcessor = new VirtualBackgroundProcessor({
          assetsPath: virtualBackgroundAssets,
          backgroundImage: await getImage(0),
          fitType: ImageFit.Cover,
        });
        await virtualBackgroundProcessor.loadModel();
      }
      if (!room?.localParticipant) {
        return;
      }

      if (backgroundSettings.type === 'blur') {
        addProcessor(blurProcessor);
      } else if (backgroundSettings.type === 'image' && typeof backgroundSettings.index === 'number') {
        virtualBackgroundProcessor.backgroundImage = await getImage(backgroundSettings.index);
        addProcessor(virtualBackgroundProcessor);
      } else if(backgroundSettings.type === 'custom') {
        virtualBackgroundProcessor.backgroundImage = await getImage(backgroundSettings.index, backgroundSettings.url);
        addProcessor(virtualBackgroundProcessor); 
      } else {
        removeProcessor();
      }
    };
    handleProcessorChange();
    window.localStorage.setItem(SELECTED_BACKGROUND_SETTINGS_KEY, JSON.stringify(backgroundSettings));

  }, [backgroundSettings, videoTrack, room, addProcessor, removeProcessor]);

  return [backgroundSettings, setBackgroundSettings];

}

export default useBackgroundSettings;