import React, { useState,useEffect } from "react";
import { useGlobal } from "reactn";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";
import Attendance from "../../lib/model/Attendance";
import axios from "axios";
import store from "../../lib/redux/store";
import { fetchAttendanceData } from "../../lib/redux/actions/attendance";
import dayjs from "dayjs";
import ProfileThumbnail from "../items/ProfileThumbnail";
import { useTranslation } from 'react-i18next';

const USER_ROLE = {
  MASTER: 'master',
  MEMBER: 'member'
}

const FormEditAttendanceType = ({ user, applyDate, applyType, isFuture, applyFlg, fixFlg, planFlg, otherFlg, fixholidayFlg, ApprovedAttendanceFlg }) => {

  // access redux store
  const currentUser = useSelector(state => state.setUser.currentUser);

  const [isShowEditAttendanceType, setIsShowEditAttendanceType] = useGlobal("isShowEditAttendanceType");
  const [attendanceType, setAttendanceType] = useState(null);
  const { t } = useTranslation();
  // const [formInitialData, setformInitialData] = useState({
  //   began_at_hour: 0,
  //   began_at_minute: 0,
  //   finished_hour: 0,
  //   finished_minute: 0,
  //   break_began_at_hour: 0,
  //   break_began_at_minute: 0,
  //   break_time_hour: 0,
  //   break_time_minute: 0,
  // });

  const [workingHours, setWorkingHoursData] = useState({hours:0, minites:0}); // formDataをstateで管理する
  const [leavingworkHours, setleavingworkHoursData] = useState({hours:0, minites:0}); // formDataをstateで管理する
  const [breakstartHours, setbreakstartHoursData] = useState({hours:0, minites:0}); // formDataをstateで管理する
  const [breakHours, setbreakHoursData] = useState({hours:0, minites:0}); // formDataをstateで管理する
  const [isButtonDisabled, setButtonDisabled] = useState(false);


  const config = Attendance.config.attendanceType;

  // whether current user has master role
  let isMaster;
  if (currentUser.role == USER_ROLE.MASTER) {
    isMaster = true;
  }

  const apiParams = useSelector(state => state.setDataAttendanceForm.params);
  const updateAttendanceData = data => {
    //store.dispatch(fetchAttendanceData(apiParams))
    store.dispatch({ type: 'SET_UPDATED_USER_ATTENDANCE', submittedUserData: data[0], submitted: true });
  }

  const handleChangeAttendanceOption = e => {
    const value = e.target.value;
    setAttendanceType(value);
  }

  const attendanceTypeOptionNode = () => {
    const optionNode = [];
    for (let i = 0; i < Object.keys(config).length; i++) {
      const option = {
        name: t(config[i].name),
        key: i
      }
      if (i === applyType && !ApprovedAttendanceFlg) {
        continue;
      }
      optionNode.push(option);
    }
    if (isFuture) {
      // return (
        // <>
          {/* <option value={Attendance.HOLIDAY}>{t(config[Attendance.HOLIDAY].name)}</option> */}
          {/* <option value={Attendance.ABSENCE}>{t(config[Attendance.ABSENCE].name)}</option> */}
          {/* <option value={Attendance.PAID_HOLIDAY}>{t(config[Attendance.PAID_HOLIDAY].name)}</option> */}
          {/* <option value={Attendance.COMPENSATORY_HOLIDAY}>{t(config[Attendance.COMPENSATORY_HOLIDAY].name)}</option> */}
          {/* <option value={Attendance.WORKSCHEDULE}>{t(config[Attendance.WORKSCHEDULE].name)}</option> */}
        {/* </> */}
      // );
      // if (!isMaster) {
        return optionNode.map((o, key) => {
          if (![0,2,5,6].includes(o.key)) {
            return <option value={o.key} key={key}>{o.name}</option>;
          }
        });
      // } 
      // else {
      //   return optionNode.map((o, key) => {
      //     if (![0,6].includes(o.key)) {
      //       // return <option value={o.key} key={key}>{o.name}</option>;
      //       console.log("12345")
      //     }
      //   });
      // } 
    } 
    // else if (!isFuture && !isMaster) { 
    else { 
      return optionNode.map((o, key) => {
        if (![0,6,7].includes(o.key)) {
          return <option value={o.key} key={key}>{o.name}</option>;
        }
      });
    }
  }

  const optionlist = attendanceTypeOptionNode().filter(v => v);
  const submitAttendanceChange = () => {
    setButtonDisabled(true);

    let url, date, params, status;
    // if (attendanceType === null && !isFuture) {
    if (attendanceType === null) {
      status = optionlist[0].props.value;
    } else {
      status = Number(attendanceType);
    }
    url = "/api/v1/attendances";
    date = dayjs(applyDate).format("YYYY-MM-DD");
    params = {
      user_id: user.id,
      date: date,
      attendance_type: status,
    };

    if (!isMaster || isFuture) {
      url = '/api/v1/paid_holidays/create';
      params = {
        user_id: user.id,
        date: applyDate,
        order: 'asc',
        page: apiParams.page
      }
      // 勤怠修正の場合
      if([0, 1, 2, 3, 4].includes(status)) {
        params = {
          attendance_type: status,
          date: applyDate,
          began_at: null ,
          finished_at: null,
          break_time_at: null,
          break_time_hour: null,
          break_time_minute: null
        }
      }
      if(status == 6 || status == 7) {
        params = {
          attendance_type: status,
          date: applyDate,
          began_at: workingHours.hours + ":" + workingHours.minites ,
          finished_at: leavingworkHours.hours + ":" + leavingworkHours.minites,
          break_time_at: breakstartHours.hours + ":" + breakstartHours.minites,
          break_time_hour: Number(breakHours.hours),
          break_time_minute: Number(breakHours.minites)
        }
      }
    }
    if (!isMaster || !isFuture) {
      url = '/api/v1/paid_holidays/create';
      if([0, 1, 2, 3, 4].includes(status)) {
        params = {
          attendance_type: status,
          date: applyDate,
          began_at: null ,
          finished_at: null,
          break_time_at: null,
          break_time_hour: null,
          break_time_minute: null
        }
      }
      if(status == 5) {
        params = {
          attendance_type: status,
          date: applyDate,
          began_at: workingHours.hours + ":" + workingHours.minites ,
          finished_at: leavingworkHours.hours + ":" + leavingworkHours.minites,
          break_time_at: breakstartHours.hours + ":" + breakstartHours.minites,
          break_time_hour: Number(breakHours.hours),
          break_time_minute: Number(breakHours.minites)
        }
      }
    }

    let message = t("AttendanceMessageChange",{});
    if (isFuture) {
      message = t("AttendanceMessageApply",{});
    }

    axios.post(url, params)
      .then(res => {
        const data = res.data;
        if (data.status == 'error') {
          alert(data.message);
          return false;
        }
        updateAttendanceData(res.data);
        setIsShowEditAttendanceType(false);
        alert(message);
      })
      .catch(error => {
        alert(t("AttendanceAlertServerError",{}));
      });
  }

  const handleAttendanceApply = confirm => {
    // TODO モデル作成してマジックナンバー回避
    let status;
    if (confirm) {
      status = 3; //承認
    } else {
      status = 2; //承認しない
    }
    const params = {
      user_id: user.id,
      master_id: currentUser.id,
      date: applyDate,
      status: status
    }
    axios.post('/api/v1/paid_holidays/update', params)
      .then(res => {
        updateAttendanceData(res.data);
        setIsShowEditAttendanceType(false);
        alert(t("AttendanceMessageUpdate",{}));
      })
      .catch(error => {
        alert(t("AttendanceMessageUpdate",{}));
      });
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/v1/attendances//detail?user_id=${user.id}&date=${applyDate}`);
        const { began_at_hour, began_at_minute, finished_hour, finished_minute, break_began_at_hour, break_began_at_minute, break_time_hour, break_time_minute } = response.data;
        setWorkingHoursData({ hours: began_at_hour, minites: began_at_minute });
        setleavingworkHoursData({ hours: finished_hour, minites: finished_minute });
        setbreakstartHoursData({ hours: break_began_at_hour, minites: break_began_at_minute });
        setbreakHoursData({ hours: break_time_hour, minites: break_time_minute });
      } catch (error) {
      }
    };
    fetchData();
  }, [user.id, applyDate]);

  function handleChangeWorkingHours(event) {
    const { name, value } = event.target;
    setWorkingHoursData(prevData => ({ ...prevData, [name]: value })); // 入力された値をstateにセットする
  }

  function handleChangeLeavingWorkHours(event) {
    const { name, value } = event.target;
    setleavingworkHoursData(prevData => ({ ...prevData, [name]: value })); // 入力された値をstateにセットする
  }

  function handleChangebreakstartHours(event) {
    const { name, value } = event.target;
    setbreakstartHoursData(prevData => ({ ...prevData, [name]: value })); // 入力された値をstateにセットする
  }

  function handleChangebreakHours(event) {
    const { name, value } = event.target;
    setbreakHoursData(prevData => ({ ...prevData, [name]: value })); // 入力された値をstateにセットする
  }

  return (
    <EditAttendanceTypeWrapper>
      <EditAttenadanceTypeClose
        onClick={() => { setIsShowEditAttendanceType(false) }}
      >
        <img src={RailsAssetPath('close-img-gray.png')} />
      </EditAttenadanceTypeClose>
      <EditAttendanceTypeContent>
        <ProfileThumbnail
          image={user.image}
          size='65px'
        />
        <EditAttenadanceTypeName>{user.name}</EditAttenadanceTypeName>
        {applyFlg ?
          <EditAttenadanceTypeText>{t("AttendanceStatusPaidHoliday",{})}</EditAttenadanceTypeText> : null
        }
        {fixFlg ?
          <EditAttenadanceTypeText>{t("AttendanceStatusCorrection",{})}</EditAttenadanceTypeText> : null
        }
        {otherFlg && (
          applyType == 1 ? <EditAttenadanceTypeText>{t("AttendanceStatusHolidays")}</EditAttenadanceTypeText> :
          applyType == 2 ? <EditAttenadanceTypeText>{t("AttendanceStatusAbsence")}</EditAttenadanceTypeText> :
          applyType == 4 ? <EditAttenadanceTypeText>{t("AttendanceStatusSustituteHoliday")}</EditAttenadanceTypeText> :
          applyType == 7 ? <EditAttenadanceTypeText>{t("AttendanceStatusWorkSchedule")}</EditAttenadanceTypeText> :
          null
        )}
        <EditAttenadanceTypeDate>{dayjs(applyDate).format('YYYY/MM/DD')}</EditAttenadanceTypeDate>
        {applyFlg || fixFlg || otherFlg || planFlg ?
          null :
          <SelectWrapper isFuture={isFuture}>
            <select onChange={handleChangeAttendanceOption}>
              {optionlist}
            </select>
          </SelectWrapper>
        }
        {(['5','6','7'].includes(attendanceType)) ?
          <>
            {/* {fetchData()} */}
            <SelectCorrectionWrapper>
              <SelectCorrectionHead>{t("AttendanceTextWorkingHours",{})}</SelectCorrectionHead>
              <div className='inputbox__time'>
                <div className='select-box-time'>
                  <input className="inputbox" type="number" name="hours"  min="0" max="23" value={workingHours.hours} onChange={handleChangeWorkingHours} /><br />
                </div>
                <span className='text-wave'>：</span>
                <div className='select-box-time'>
                  <input className="inputbox" type="number" name="minites" min="0" max="59" value={workingHours.minites} onChange={handleChangeWorkingHours} /><br />
                </div>
              </div>
              <SelectCorrectionHead>{t("AttendanceTextLeavingWorkHours",{})}</SelectCorrectionHead>
              <div className='inputbox__time'>
                <div className='select-box-time'>
                  <input className="inputbox" type="number" name="hours"  min="0" max="23" value={leavingworkHours.hours} onChange={handleChangeLeavingWorkHours} /><br />
                </div>
                <span className='text-wave'>：</span>
                <div className='select-box-time'>
                  <input className="inputbox" type="number" name="minites" min="0" max="59" value={leavingworkHours.minites} onChange={handleChangeLeavingWorkHours} /><br />
                </div>
              </div>
              {attendanceType != 7 && (<>
                <SelectCorrectionHead>{t("AttendanceTextBreakStartTime",{})}</SelectCorrectionHead>
                <div className='inputbox__time'>
                  <div className='select-box-time'>
                    <input className="inputbox" type="number" name="hours"  min="0" max="23" value={breakstartHours.hours} onChange={handleChangebreakstartHours} /><br />
                  </div>
                  <span className='text-wave'>：</span>
                  <div className='select-box-time'>
                    <input className="inputbox" type="number" name="minites" min="0" max="59" value={breakstartHours.minites} onChange={handleChangebreakstartHours} /><br />
                  </div>
                </div>
              </>)}
              <SelectCorrectionHead>{t("AttendanceTextBreakHours",{})}</SelectCorrectionHead>
              <div className='inputbox__time'>
                <div className='select-box-time'>
                  {/* <select className='inputbox' name='break_time' value={breakHours.hours} onChange={handleChangebreakHours}>
                    {breakHours.hours.map(option => (
                        <option key={option.value} value={option.value}>
                        </option>
                      ))}
                  </select> */}
                  <input className="inputbox" type="number" name="hours"  min="0" max="23" value={breakHours.hours} onChange={handleChangebreakHours} /><br />
                  <span className='text-gray'>h</span>
                </div>
                <span className='text-wave'>：</span>
                <div className='select-box-time'>
                  {/* <select className='inputbox' name='break_time' value={breakHours.minites} onChange={handleChangebreakHours}>
                    {breakHours.minites.map(option => (
                      <option key={option.value} value={option.value}>
                      </option>
                    ))}
                  </select> */}
                  <input className="inputbox" type="number" name="minites" min="0" max="59" value={breakHours.minites} onChange={handleChangebreakHours} /><br />
                  <span className='text-gray'>m</span>
                </div>
              </div>
            </SelectCorrectionWrapper>
          </> : null
        }
        {(applyFlg || fixFlg || otherFlg || planFlg)  ?
          <>
            <EditAttendanceTypeSubmit background='blue' onClick={() => handleAttendanceApply(confirm = true)} disabled={isButtonDisabled}>{t("AttendanceButtonApprove",{})}</EditAttendanceTypeSubmit>
            <EditAttendanceTypeSubmit background='white' onClick={() => handleAttendanceApply(confirm = false)} disabled={isButtonDisabled}>{t("AttendanceButtonReject",{})}</EditAttendanceTypeSubmit>
          </> :
          <EditAttendanceTypeSubmit background='blue' onClick={submitAttendanceChange} disabled={isButtonDisabled}>
            {isFuture ? t("AttendanceButtonApply",{}) : t("AttendanceButtonChange",{})}
          </EditAttendanceTypeSubmit>
        }
        {(['5'].includes(attendanceType)) ?
          <>
            {/* {fetchData()} */}
              <div className='inputbox__time'>
              <SpanWarningFixWorkText>-300vike消失します</SpanWarningFixWorkText>
              </div>
          </> : null
        }

      </EditAttendanceTypeContent>
    </EditAttendanceTypeWrapper>
  );
}

const EditAttendanceTypeWrapper = styled.div`
  padding: 15px;
  position: relative;
`

const EditAttenadanceTypeClose = styled.div`
  font-size: 14px;
  position: absolute;
  left: 15px;
  top: 15px;
  width: 15px;
  cursor: pointer;
`

const EditAttendanceTypeContent = styled.div`
  margin: 0 20px 25px;
  text-align: center;
`

const EditAttenadanceTypeName = styled.div`
  font-size: 13px;
  line-height: 13px;
`

const EditAttenadanceTypeText = styled.div`
  width: 100%;
  height: 40px;
  text-align: center;
  background: #f6f6f6;
  padding: 10px;
  text-align-last: center;
  border-radius: 8px;
  margin-top: 20px;
`

const EditAttenadanceTypeDate = styled.div`
  font-size: 20px;
  margin: 15px 0;
  letter-spacing: 1px;
`

const EditAttendanceTypeSubmit = styled.button`
  border-radius: 8px;
  width: 100%;
  height: 40px;
  ${props => (props.background == 'white') ? `
    border: 1px solid #00a0e9;
    color: #00a0e9;
    background-color: #fff; 
  ` : `
    border: none;
    color: #fff;
    background-color: #00a0e9;  
  `}
  font-size: 14px;
  margin: 10px 0;

  &:disabled {
    background-color: #b6b6b6;
  }
`

const SelectWrapper = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  select {
    appearance: none;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #dcdcdc;
    padding: 10px;
    text-align-last: center;
  }
  /* &::before {
    content: "";
    width: 12px; 
    height: 12px;
    border: 6px solid transparent;
    border-top: 8px solid #6f6f6f;
    position: absolute;
    top: 18px; 
    right: 15px;
  } */
  &::before {
    content: "";
    width: 12px; 
    height: 12px;
    border: 6px solid transparent;
    border-top: 8px solid #6f6f6f;
    position: absolute;
    top: 18px; 
    right: 15px;
  }
`

const SelectCorrectionWrapper = styled.div`
`

const SelectCorrectionHead = styled.p`
    font-size: 12px;
    padding: 10px 0px 10px;
`

const SpanWarningFixWorkText = styled.span`
  color: red;
  font-weight: bold;
`

const mapStateToProps = state => {
  return {
    user: state.setDataAttendanceForm.user,
    applyDate: state.setDataAttendanceForm.applyDate,
    applyType: state.setDataAttendanceForm.applyType,
    isFuture: state.setDataAttendanceForm.isFuture,
    applyFlg: state.setDataAttendanceForm.applyFlg,
    fixFlg: state.setDataAttendanceForm.fixFlg,
    planFlg: state.setDataAttendanceForm.planFlg,
    otherFlg: state.setDataAttendanceForm.otherFlg,
    fixholidayFlg: state.setDataAttendanceForm.fixholidayFlg,
    ApprovedAttendanceFlg: state.setDataAttendanceForm.ApprovedAttendanceFlg,
  }
}

export default connect(mapStateToProps)(FormEditAttendanceType);