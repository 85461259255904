import React, { useState } from 'react';
import axios from 'axios';
import { useGlobal } from 'reactn';
import styled from 'styled-components';
import { sp } from '../../utils/mediaQuery';
import { useTranslation } from 'react-i18next';
import { SHOW_FEED_TYPE } from '../../utils/util';

const FormRegisterForum = ({
  setShowReservationDateModal,
  selectedDate,
  setSelectedDate,
  time,
}) => {
  const [_, setIsShowEnterReportModal] = useGlobal('isShowEnterReportModal');

  const [todoImage, setTodoImage] = useState([]);
  const [previewUrlList, setPreviewUrlList] = useState([]);

  const [h, setH] = useState('');
  const [loading, setLoading] = useState(true);

  const [feedShowType, setFeedShowType] = useGlobal('feedShowType');

  const [textareas, setTextareas] = useState([
    { id: 1, value: "" },
    { id: 2, value: "" }
  ]);

  const defaultDetail = '';

  const { t } = useTranslation();

  const onChangeUpload = async (e) => {
    if (e.target.files[0]) {
      setTodoImage([...todoImage, ...e.target.files]);
      const files = [...e.target.files].map((file) => {
        const reader = new FileReader();
        return new Promise((resolve) => {
          reader.onload = () => resolve(reader.result);
          reader.readAsDataURL(file);
        });
      });
      const res = await Promise.all(files);
      setPreviewUrlList([
        ...previewUrlList,
        ...res.map((image) => ({ image })),
      ]);
    }
  };

  const handleChangeH = (e) => {
    setH(e.target.value);
  };

  const deleteImage = (index) => {
    setPreviewUrlList(previewUrlList.filter((_, i) => i !== index));
    setTodoImage(todoImage.filter((_, i) => i !== index));
  };

  //promiseで返すコンポーネント化する
  const handleSubmit = (e) => {
    setLoading(false);
    e.preventDefault();

    let url;
    let params;
    if(feedShowType == SHOW_FEED_TYPE.FORUM) {
      url = '/api/v1/notice_boards';
      params = {
        content: h || defaultDetail,
        reserved_at: selectedDate
          ? `${selectedDate} ${time.hour}:${time.minutes}:00`
          : null,
        images: todoImage,
      };
    }

    else if(feedShowType == SHOW_FEED_TYPE.SURVEY) {
      url = '/api/v1/question/create';
      params = {
        content: h || defaultDetail,
        limit_answered_at: selectedDate
        ? `${selectedDate} ${time.hour}:${time.minutes}:00` : null,
        // send_reserved_at: selectedDate
        // ? `${selectedDate} ${time.hour}:${time.minutes}:00`
        // : null,
        send_reserved_at: null,
        answer_1: textareas[0].value,
        answer_2: textareas[1].value,
        answer_3: textareas[2] ? (textareas[2].value.trim() == '' ? null : textareas[2].value) : null, 
        answer_4: textareas[3] ? (textareas[3].value.trim() == '' ? null : textareas[3].value) : null, 
        answer_5: textareas[4] ? (textareas[4].value.trim() == '' ? null : textareas[4].value) : null, 
        answer_6: textareas[5] ? (textareas[5].value.trim() == '' ? null : textareas[5].value) : null, 
        answer_7: textareas[6] ? (textareas[6].value.trim() == '' ? null : textareas[6].value) : null, 
        answer_8: textareas[7] ? (textareas[7].value.trim() == '' ? null : textareas[7].value) : null, 
        answer_9: textareas[8] ? (textareas[8].value.trim() == '' ? null : textareas[8].value) : null, 
        answer_10: textareas[9] ? (textareas[9].value.trim() == '' ? null : textareas[9].value) : null, 
        images:  todoImage
      };
    }


    const formData = new FormData();
    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          formData.append(key + '[]', v); // arrayデータを分割して入れ直す
        });
      } else {
        formData.append(key, value);
      }
    });
    console.log(url);
    console.log(params);
    axios
      .post(url, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then(() => {
        setSelectedDate(undefined);
        setIsShowEnterReportModal(false);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateTextAreaValue = (event, id) => {
    const updatedTextareas = textareas.map(textarea => {
      if (textarea.id === id) {
        return { ...textarea, value: event.target.value };
      }
      return textarea;
    });
    setTextareas(updatedTextareas);
  };

  const addTextarea = () => {
    if (textareas.length >= 10) return;
    const newId = textareas[textareas.length - 1].id + 1;
    setTextareas([...textareas, { id: newId, value: "" }]);
  };

  const isSubmitDisabled = () => {
    if (
      textareas[0].value.trim().length === 0 ||
      textareas[1].value.trim().length === 0 ||
      !selectedDate ||
      !time.hour ||
      !time.minutes
    ) {
    return true;
    }
    return false;
    };

  return (
    <FormRegirsterForum className='modal__background'>
      <div className='modal__wrapper'>
        {feedShowType == SHOW_FEED_TYPE.FORUM && (
          <form onSubmit={handleSubmit}>
            <FormRegisterSubmitTitle>
              {t('ForumTextNewPost', {})}
            </FormRegisterSubmitTitle>
            <FormRegisterWrapper>
              <textarea
                className='textarea padding'
                style={{ width: '100%', color: 'black' }}
                name='detail'
                onChange={handleChangeH}
                rows='15'
                value={h}
                placeholder={t('ForumTextNewPost', {})}
              ></textarea>
              <div className='' id='preview'>
                {previewUrlList.map((i, key) => (
                  <div key={key} className='preview-wrapper'>
                    <div className='relative'>
                      <div
                        className='preview-button'
                        onClick={() => deleteImage(key)}
                      >
                        ×
                      </div>
                      <img className='preview-img' src={i.image} alt='preview' />
                    </div>
                  </div>
                ))}
              </div>
              <div style={{ width: '50px', marginTop: '20px' }}>
                <label className='input-file-label' htmlFor='todofileUpload'>
                  <input
                    id='todofileUpload'
                    accept='image/*'
                    className='inputImage'
                    name='images'
                    type='file'
                    multiple
                    onChange={onChangeUpload}
                  />
                </label>
              </div>
              <StyledReservedDateWrapper>
                <button
                  onClick={() => setShowReservationDateModal(true)}
                  type='button'
                >
                  {t('ForumTextSetreserveddate')}
                </button>
                {selectedDate && (
                  <p>
                    {`${t('ForumTextReserveddate')}：${selectedDate} ${
                      time.hour
                    }:${time.minutes}`}
                    <span onClick={() => setSelectedDate(undefined)} />
                  </p>
                )}
              </StyledReservedDateWrapper>
            </FormRegisterWrapper>
            {loading ? (
              <div className='js-button-show'>
                <FormRegisterSubmitButton type='submit' onClick={handleSubmit}>
                  {t('ForumButtonSend', {})}
                </FormRegisterSubmitButton>
              </div>
            ) : (
              <FormRegisterSubmitLoad>
                {t('ForumMessageSending', {})}
              </FormRegisterSubmitLoad>
            )}
          </form>
        )}
        {feedShowType == SHOW_FEED_TYPE.SURVEY && (
          <form onSubmit={handleSubmit}>
            <FormRegisterSubmitTitle>
              {t('ForumTextNewSurvey', {})}
            </FormRegisterSubmitTitle>
            <FormRegisterWrapper>
              <textarea
                className='textarea padding'
                style={{ width: '100%', color: 'black' }}
                name='detail'
                onChange={handleChangeH}
                rows='15'
                value={h}
                placeholder={t('ForumTextNewPost', {})}
              ></textarea>
              <SurveyOptionsWrapper>
                {textareas.map(textarea => (
                  <SurveyOptionsFlex key={textarea.id}>
                    <SurveyOptionsTextarea
                      type="text"
                      maxLength={20}
                      value={textarea.value}
                      placeholder={`回答${textarea.id}`}
                      onChange={event => updateTextAreaValue(event, textarea.id)}
                    />
                    {textarea.id === textareas[textareas.length - 1].id &&
                      textareas.length < 10 && (
                      <SurveyOptionsTextareaAddButton type='button' onClick={addTextarea}>+</SurveyOptionsTextareaAddButton>
                    )}
                  </SurveyOptionsFlex>
                ))}
              </SurveyOptionsWrapper>
              <StyledReservedSurveyDateWrapper>
                <button
                  onClick={() => setShowReservationDateModal(true)}
                  type='button'
                >
                  {t('ForumTextReplyDeadline')}
                </button>
                {selectedDate && (
                  <p>
                    {`${selectedDate} ${
                      time.hour
                    }:${time.minutes}`}
                    <span onClick={() => setSelectedDate(undefined)} />
                  </p>
                )}
              </StyledReservedSurveyDateWrapper>
              <div className='' id='preview'>
                {previewUrlList.map((i, key) => (
                  <div key={key} className='preview-wrapper'>
                    <div className='relative'>
                      <div
                        className='preview-button'
                        onClick={() => deleteImage(key)}
                      >
                        ×
                      </div>
                      <img className='preview-img' src={i.image} alt='preview' />
                    </div>
                  </div>
                ))}
              </div>
              <div style={{ width: '50px', marginTop: '20px' }}>
                <label className='input-file-label' htmlFor='todofileUpload'>
                  <input
                    id='todofileUpload'
                    accept='image/*'
                    className='inputImage'
                    name='images'
                    type='file'
                    multiple
                    onChange={onChangeUpload}
                  />
                </label>
              </div>
              {/* <StyledReservedDateWrapper>
                <button
                  onClick={() => setShowReservationDateModal(true)}
                  type='button'
                >
                  {t('ForumTextSetreserveddate')}
                </button>
                {selectedDate && (
                  <p>
                    {`${t('ForumTextReserveddate')}：${selectedDate} ${
                      time.hour
                    }:${time.minutes}`}
                    <span onClick={() => setSelectedDate(undefined)} />
                  </p>
                )}
              </StyledReservedDateWrapper> */}
            </FormRegisterWrapper>
            {loading ? (
              <div className='js-button-show'>
                <FormRegisterSubmitButton type='submit' onClick={handleSubmit} disabled={isSubmitDisabled()} style={{ background: isSubmitDisabled() ? "#bdbdbd" : "#29a0e9" }}>
                  {t('ForumButtonSend', {})}
                </FormRegisterSubmitButton>
              </div>
            ) : (
              <FormRegisterSubmitLoad>
                {t('ForumMessageSending', {})}
              </FormRegisterSubmitLoad>
            )}
          </form>
        )}
      </div>
    </FormRegirsterForum>
  );
};

const FormRegirsterForum = styled.div`
  ${sp`
    padding: 30px;
    height: auto;
  `}
`;

const FormRegisterSubmitTitle = styled.div`
  font-size: 16px;
  color: #868686;
  margin: 30px auto 50px;
`;

const FormRegisterSubmitButton = styled.button`
  appearance: none;
  width: 200px;
  height: 40px;
  background: #29a0e9;
  border-radius: 10px;
  box-shadow: 0 0 12px 1px #c1c1c1;
  color: white;
  line-height: 40px;
  margin: auto;
`;

const FormRegisterSubmitLoad = styled.div`
  appearance: none;
  width: 200px;
  height: 40px;
  background: #bdbdbd;
  border-radius: 10px;
  color: white;
  line-height: 40px;
  margin: auto;
`;

const FormRegisterWrapper = styled.div`
  margin: auto;
  width: 90%;
  min-height: 400px;
`;

const StyledReservedDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0;
  padding-bottom: 16px;
  button {
    position: relative;
    border-bottom: 1px solid #00a0e9;
    color: #00a0e9;
  }
  p {
    position: relative;
    margin-top: 8px;
    span {
      position: absolute;
      top: -8px;
      right: -12px;
      width: 16px;
      height: 16px;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      cursor: pointer;
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2px;
        height: 8px;
        background-color: #878787;
      }
      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
`;

const StyledReservedSurveyDateWrapper = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  margin: 24px 0;
  padding-bottom: 16px;
  button {
    position: relative;
    border-bottom: 1px solid #00a0e9;
    color: #00a0e9;
    margin-right: 30px;
    font-weight: bold;
  }
  p {
    position: relative;
    /* margin-top: 8px; */
    color: #00a0e9;
    span {
      position: absolute;
      top: -8px;
      right: -12px;
      width: 16px;
      height: 16px;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      cursor: pointer;
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2px;
        height: 8px;
        background-color: #878787;
      }
      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
`;

const SurveyOptionsWrapper = styled.div`

`

const SurveyOptionsFlex =  styled.div`
  display:flex;
  align-items: center;
  margin-bottom: 10px;

  &:first-child {
    margin-top: 10px
  }
`

const SurveyOptionsTextarea = styled.input`
    border: 2px solid #03a9f4;
    border-radius: 7px;
    color: black;
    background: white;
    width: 85%;
    padding: 10px;
    resize: none;
    margin-right: 20px;
`

const SurveyOptionsTextareaAddButton = styled.button`
  font-size: 30px;
  color: #676767;
`

export default FormRegisterForum;
