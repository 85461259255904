import React from 'react';
import useTrack from './hooks/useTrack';
import AudioTrack from './AudioTrack';
import VideoTrack from './VideoTrack';

const Publication = ({ publication, isLocalParticipant, videoOnly, videoPriority }) => {
  const track = useTrack(publication);

  if (!track) return null;
  switch (track.kind) {
    case 'video':
      return (
        <VideoTrack
          track={track}
          priority={videoPriority}
          isLocal={!track.name.includes('screen') && isLocalParticipant}
        />
      );
    case 'audio':
      return videoOnly ? null : <AudioTrack track={track} />;
    default:
      return null;
  }
};

export default Publication;