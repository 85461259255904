import { useCallback, useState } from 'react';
import { AvatarPartsType } from 'types';

export const useSelectedAvatarPartsType = () => {
  const [selectedAvatarPartsType, setSelectedAvatarPartsType] =
    useState<AvatarPartsType>('hat');

  const selectAvatarPartsType = useCallback(
    (partsType: AvatarPartsType) => {
      setSelectedAvatarPartsType(partsType);
    },
    [setSelectedAvatarPartsType]
  );

  return {
    selectedAvatarPartsType,
    selectAvatarPartsType,
  };
};
