import React, { useState, useEffect, useCallback, useGlobal } from 'reactn';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { FORM_TYPES, BUY_TYPES } from '../constants';
import { USER_ROLE } from '../../../utils/util';

const usePaymentConfig = () => {
  const history = useHistory();

  const [paymentConfig, setPaymentConfig] = useGlobal('paymentConfig');
  useEffect(() => {
    if (!paymentConfig) {
      fetchConfig();
    }
  }, []);

  const fetchConfig = async () => {
    let conifg = await axios.get('/api/v1/billing/payments').then((res) => {
      if (!res.data.payment_possible) {
        history.push('/office');
      } else {
        setPaymentConfig(res.data);
      }
    });
  };

  return [paymentConfig, fetchConfig];
};

export default usePaymentConfig;
