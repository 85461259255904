import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useScrum } from 'javascript/features/scrum/hooks/useScrum';
import { WeeklyScrumResultTeamInfo } from 'javascript/features/scrum/components/WeeklyScrumResultTeamInfo';
import { device } from 'javascript/utils/mediaQuery';

const StyledWeeklyScrumResult = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const StyledTitle = styled.p`
  font-size: 14px;
`;

const StyledInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 259px;
  width: 100%;
  margin-top: 8px;
  padding: 8px 24px 28px;
  border-radius: 8px;
  background-color: #fff;
  @media ${device.tab} {
    order: 2;
    padding: 8px 12px;
  }
`;

const StyledUpdateTime = styled.p`
  font-size: 12px;
  text-align: right;
`;

const StyledResultInfoWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  height: calc(100% - 34px);
`;

const StyledNoData = styled.p`
  margin-top: 16px;
  font-size: 14px;
`;

const StyledScrumDescription = styled.div`
  margin-top: 8px;
  color: #999;
  font-size: 12px;
  p {
    display: flex;
    align-items: center;
    img {
      width: 16px;
      margin: 0;
      margin-right: 4px;
    }
  }
`;

type Props = {
  userId: number | undefined;
};

export const WeeklyScrumResult: React.FC<Props> = ({ userId }) => {
  const { t } = useTranslation();
  const { scrum } = useScrum(userId);

  return (
    <StyledWeeklyScrumResult>
      <StyledTitle>{t('EmployeeScrumResulttitle')}</StyledTitle>
      <StyledInfoWrapper>
        {scrum && (
          <>
            {scrum.weekly.gain.id ? (
              <>
                <StyledUpdateTime>
                  {t('EmployeeScrumUpdatedate')}
                  {scrum?.weekly.summary_at.replace(/-/g, '/')}
                </StyledUpdateTime>
                <StyledResultInfoWrapper>
                  <WeeklyScrumResultTeamInfo
                    teamInfo={scrum?.weekly.gain}
                    isWinner
                  />
                  <WeeklyScrumResultTeamInfo teamInfo={scrum?.weekly.loss} />
                </StyledResultInfoWrapper>
              </>
            ) : (
              <StyledNoData>{t('EmployeeScrumNodatafound')}</StyledNoData>
            )}
          </>
        )}
      </StyledInfoWrapper>
      <StyledScrumDescription>
        <p>
          {/* @ts-ignore */}
          <img src={RailsAssetPath('question.png')} alt='' />
          {t('EmployeeScrumAboutscrum')}
        </p>
        <p>{t('EmployeeScrumScrumdescription')}</p>
      </StyledScrumDescription>
    </StyledWeeklyScrumResult>
  );
};
