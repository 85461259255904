import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { fetchRooms } from '../../../lib/redux/actions/room';
import store from '../../../lib/redux/store';

export const useEditRealWorld = (roomId) => {
  const initialValues = {
    type: 'MAIN',
    name: '',
    url: '',
    note: '',
    lounge_auto_move: true,
  };

  const [roomDetail, setRoomDetail] = useState(initialValues);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [_, setIsShowEditRealWorldModal] = useGlobal("isShowEditRealWorldModal");

  const getRoomDetail = async () => {
    if (roomId) {
      const url = `/api/v1/rooms/${roomId}`;
      await axios.get(url).then(res => {
        setRoomDetail(res.data);
      });
    }
  }

  useEffect(() => {
    getRoomDetail();
  }, [])

  const submit = async (headers, data) => {
    const url = '/api/v1/rooms';
    const config = { headers };
    if (roomId) {
      return await axios.patch(`${url}/${roomId}`, data, config);
    } else {
      return await axios.post(url, data, config);
    }
  }

  const updateRoomInfo = () => {
    store.dispatch(fetchRooms());
    setIsShowEditRealWorldModal(false);
  }

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const headers = {
      'content-type': 'application/json'
    }

    const params = {
      type: 'MAIN',
      name: roomDetail.name,
      url: '',
      note: roomDetail.note,
      delete_flg: false,
      lounge_auto_move: true
    }
    
    submit(headers, params)
      .then(res => {
        updateRoomInfo();
      })
      .catch(err => {
        alert(err);
      });
  }, [submit, updateRoomInfo])

  const handleChangeValue = useCallback((e) => {
    const { name, value } = e.target;
    roomDetail[name] = value;
    setRoomDetail({ ...roomDetail, roomDetail });
  }, [roomDetail, setRoomDetail])

  return { roomDetail, handleChangeValue, handleSubmit, isSubmitting }
}