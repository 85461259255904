import React from "react";
import styled from "styled-components";
import { sp } from "../../utils/mediaQuery";

const CommonAddButton = () => {
  return (
    <React.Fragment>
      <AddButton src={RailsAssetPath('icon_plus_circle.png')} /> 
    </React.Fragment>
  )
}

const AddButton = styled.img`
  display: inline-block;
  width: 20px;
  margin-right: 10px; 
`
export default CommonAddButton;