import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { saveAs } from 'file-saver';

const StyledCsvButton = styled.button`
  display: flex;
  gap: 8px;
  padding: 6px 18px;
  background: #00a0e9;
  border-radius: 8px;
  color: #fff;
  font-size: 12px;
  img {
    width: 14px;
  }
`;

interface Props {
  url: string;
}

// CsvButton
export const CsvButton: React.FC<Props> = ({ url }) => {
  const handleDownloadCsv = () => {
    const config = {
      headers: {
        'content-type': 'text/csv',
        'Content-Disposition': 'attachment; filename="2020-02.csv";',
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const blob = new Blob([res.data], {
          type: res.data.type,
        });
        const contentDisposition = res.headers['content-disposition'];
        const fileName = getFileName(contentDisposition);
        saveAs(blob, fileName);
      })
      .catch((error) => {
        alert(error.response);
      });
  };

  const getFileName = (contentDisposition: string) => {
    let fileName = contentDisposition.substring(
      contentDisposition.indexOf("''") + 2,
      contentDisposition.length
    );
    fileName = decodeURI(fileName).replace(/\+/g, ' ');
    return fileName;
  };

  return (
    <StyledCsvButton onClick={handleDownloadCsv}>
      {/* @ts-ignore */}
      <img src={RailsAssetPath('icon_download.png')} />
      CSV
    </StyledCsvButton>
  );
};
