import React, { useState, useEffect, useCallback, useGlobal } from 'reactn';
import axios from 'axios';
import { PAYMENT_METHOD_STEPS } from './constants';
import { Typography, Button, Grid, Link } from '@material-ui/core';
import {
  CardNumberElement,
  useStripe,
  useElements,
  Elements,
} from '@stripe/react-stripe-js';
import { useStyles } from './hooks/useStyles';
import { useMediaQuery } from 'react-responsive';
import usePaymentContext from './hooks/usePaymentContext';
import InputCardNumber from './input/InputCardNumber';
import ProgressBackdrop from './ProgressBackdrop';
import { useTranslation } from 'react-i18next';

const AddPaymentMethodForm = () => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const isMobile = useMediaQuery({ maxWidth: 430 });
  const {
    paymentConfig,
    fetchConfig,
    cardInputComplete,
    setCardInputComplete,
  } = usePaymentContext();
  const [paymentPaymentMethodStep, setPaymentPaymentMethodStep] = useGlobal(
    'paymentPaymentMethodStep'
  );
  const [inProgres, setInProgress] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setInProgress(true);

    const { clientSecret } = await axios
      .get('/api/v1/billing/payments/create_setup_intent')
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        const message = error.response.data.message;
        alert(message);
      });
    if (clientSecret) {
      let paymentMethod = {
        payment_method: {
          type: 'card',
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: cardInputComplete.name,
            address: {
              country: cardInputComplete.country,
            },
          },
        },
      };
      let { error, setupIntent } = await stripe.confirmCardSetup(
        clientSecret,
        paymentMethod
      );
      if (error) {
        alert(error.message);
        setInProgress(false);
        return;
      }
      if (setupIntent.status == 'succeeded') {
        let params = {
          payment_method: setupIntent.payment_method,
        };
        const complete = await axios
          .post('/api/v1/billing/payments/add_payment_method', params)
          .then((res) => {
            fetchConfig();
            setPaymentPaymentMethodStep(PAYMENT_METHOD_STEPS.CARD_SELECT);
          });
      }
      setInProgress(false);
    }
  };

  const isDisabled = () => {
    if (cardInputComplete) {
      let val = Object.values(cardInputComplete).filter(
        (element) => element == false
      );
      return val.length != 0 || !stripe;
    }
    return false;
  };

  return (
    <>
      <ProgressBackdrop open={inProgres}></ProgressBackdrop>

      <form onSubmit={handleSubmit}>
        <Grid
          container
          direction='column'
          justify='center'
          spacing={isMobile ? 2 : 6}
        >
          <Grid item>
            <InputCardNumber />
          </Grid>
          <Grid container item justify='center' alignItems='center'>
            <Button
              className={`${classes.baseButton} ${classes.submitButton}`}
              type='submit'
              disabled={isDisabled()}
            >
              {t('PaymentTextRegister', {})}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AddPaymentMethodForm;
