import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import Observer from 'javascript/components/common/Observer';
import { RecentComments } from 'javascript/features/userProfile/components/RecentComments';
import { device } from 'javascript/utils/mediaQuery';
import { Comment } from 'types';
import { useLocation } from 'react-router-dom';

interface Props {
  currentPageUserId: number;
  userIdsSortByRespected: number[];
  isCast: boolean;
}

export const UserProfileRecentComments: React.FC<Props> = ({
  currentPageUserId,
  userIdsSortByRespected,
  isCast,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 430 });

  const currentPageUserIndex = userIdsSortByRespected.findIndex(
    (userId) => userId === currentPageUserId
  );

  // 戻るための矢印アイコンを押したとき、どのユーザーIDのページを表示させるかを判定する
  const forwardUserId =
    currentPageUserIndex !== userIdsSortByRespected.length - 1
      ? userIdsSortByRespected[currentPageUserIndex + 1]
      : userIdsSortByRespected[0];

  const [commentEmotion, setCommentEmotion] = useState(''); //nice amazing congrats cheerup
  const [commentsData, setCommentsData] = useState<Comment[]>([]);

  const [page, setPage] = useState(0);
  const [commentsUrl, setCommentsUrl] = useState('');

  const target = useRef(null);
  const emotionData = ['heart', 'star', 'cracker', 'megaphone'];
  const { t } = useTranslation();

  const onIntersection = () => {
    setPage((prev) => prev + 1);
  };

  const handleChangeComments = (emotionId: number) => {
    let index = emotionId - 1;
    let feelingStatus;
    if (commentEmotion == emotionData[index]) {
      setCommentEmotion('');
      feelingStatus = '';
    } else {
      setCommentEmotion(emotionData[index]);
      feelingStatus = emotionId;
    }
    handleGetCommentsData(currentPageUserId, feelingStatus);
  };

  const handleGetCommentsData = (
    id: number,
    feelingStatus: string | number
  ) => {
    let url = `/api/v1/users`;
    let params = `comment?feeling_status=`;
    if (feelingStatus === '') {
      axios.get(`/api/v1/users/${id}/comment`).then((res) => {
        setCommentsData(res.data.comments);
        setCommentsUrl(`/api/v1/users/${id}/comment?`);
      });
    } else {
      axios.get(`${url}/${id}/${params}${feelingStatus}`).then((res) => {
        setCommentsData(res.data.comments);
        setCommentsUrl(`${url}/${id}/${params}${feelingStatus}`);
        setPage(1);
      });
    }
  };

  const getNextPageData = () => {
    let url = `${commentsUrl}&page=${page}`;
    axios.get(url).then((res) => {
      const reports = res.data.comments;
      setCommentsData((prev) =>
        prev != reports ? [...prev, ...reports] : prev
      );
    });
  };

  const search = useLocation().search;
  const query2 = new URLSearchParams(search);

  useEffect(() => {
    handleGetCommentsData(currentPageUserId, '');
  }, [currentPageUserId]);

  useEffect(() => {
    if (page > 1) {
      getNextPageData();
    }
  }, [page]);

  return (
    <StyledUserProfileRecentComments>
      {!isCast && (
        !isMobile && (
          <StyledButtonToForwardUser>
            <Link to={`/mypage/${forwardUserId}?sort_id=${query2.get('sort_id')}&select_id=${query2.get('select_id')}`}>
              {/* @ts-ignore */}
              <img src={RailsAssetPath('mypage/forward_icon.png')} />
            </Link>
          </StyledButtonToForwardUser>
        )
      )}
      <StyledCommentTitleWrapper>
        <StyledUserProfileWrapperItemTitle>
          {t('CommentTextComment', {})}
        </StyledUserProfileWrapperItemTitle>
        <StyledLinkToOwnDiary to={`/diary?owndata=true&user_id=${currentPageUserId}`}>
          {t('CommentTextOwndiary')}
        </StyledLinkToOwnDiary>
      </StyledCommentTitleWrapper>
      <StyledUserProfileWrapperEmotionButton>
        <StyledUserProfileWrapperEmotionButtonItem
          inputColor={commentEmotion == 'heart' ? '#00a1e9' : null}
          onClick={() => {
            handleChangeComments(1);
          }}
        >
          <img
            src={
              commentEmotion == 'heart'
                ? // @ts-ignore
                  RailsAssetPath('icon-heart-white.png')
                : // @ts-ignore
                  RailsAssetPath('icon-heart-blue.png')
            }
          />
          <span>{t('ReportStatusThankyou', {})}</span>
        </StyledUserProfileWrapperEmotionButtonItem>
        <StyledUserProfileWrapperEmotionButtonItem
          inputColor={commentEmotion == 'star' ? '#00a1e9' : null}
          onClick={() => {
            handleChangeComments(2);
          }}
        >
          <img
            src={
              commentEmotion == 'star'
                ? // @ts-ignore
                  RailsAssetPath('icon-star-white.png')
                : // @ts-ignore
                  RailsAssetPath('icon-star-blue.png')
            }
          />
          <span>{t('ReportStatusAmazing', {})}</span>
        </StyledUserProfileWrapperEmotionButtonItem>
        <StyledUserProfileWrapperEmotionButtonItem
          inputColor={commentEmotion == 'cracker' ? '#00a1e9' : null}
          onClick={() => {
            handleChangeComments(3);
          }}
        >
          <img
            src={
              commentEmotion == 'cracker'
                ? // @ts-ignore
                  RailsAssetPath('icon-cracker-white.png')
                : // @ts-ignore
                  RailsAssetPath('icon-cracker-blue.png')
            }
          />
          <span>{t('ReportStatusCongrat', {})}</span>
        </StyledUserProfileWrapperEmotionButtonItem>
        <StyledUserProfileWrapperEmotionButtonItem
          inputColor={commentEmotion == 'megaphone' ? '#00a1e9' : null}
          onClick={() => {
            handleChangeComments(4);
          }}
        >
          <img
            src={
              commentEmotion == 'megaphone'
                ? // @ts-ignore
                  RailsAssetPath('icon-megaphone-white.png')
                : // @ts-ignore
                  RailsAssetPath('icon-megaphone-blue.png')
            }
          />
          <span>{t('ReportStatusFight', {})}</span>
        </StyledUserProfileWrapperEmotionButtonItem>
      </StyledUserProfileWrapperEmotionButton>
      <StyledComments className='target'>
        <RecentComments comments={commentsData} />
        <div ref={target} />
      </StyledComments>
      <Observer
        root={document.getElementById('target')}
        el={target.current}
        onIntersection={onIntersection}
      />
    </StyledUserProfileRecentComments>
  );
};

const StyledUserProfileRecentComments = styled.div`
  position: relative;
  min-height: 43px;
  padding-left: 24px;
  @media ${device.sp} {
    margin-top: 16px;
    padding-left: 0;
  }
`;

const StyledButtonToForwardUser = styled.div`
  position: fixed;
  top: 96px;
  right: 48px;
  cursor: pointer;
  img {
    width: 12px;
  }
`;

const StyledUserProfileWrapperItemTitle = styled.div`
  padding-left: 16px;
  font-size: 12px;
  font-weight: bold;
`;

const StyledComments = styled.div`
  height: 500px;
  margin-top: 20px;
  padding: 32px 24px 16px;
  background-color: #fafafa;
  border-radius: 8px;
  overflow-y: scroll;
  @media ${device.sp} {
    width: auto;
    height: 400px;
  }
`;

const StyledUserProfileWrapperEmotionButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const StyledUserProfileWrapperEmotionButtonItem = styled.div<{
  inputColor: string | null;
}>`
  border-radius: 10px;
  width: 88px;
  cursor: pointer;
  text-align: center;
  font-size: 10px;
  img {
    width: 12px;
    margin: 4px auto 0 auto
  }
  border: ${({ inputColor }) =>
    inputColor ? '1px solid ' + inputColor : '1px solid #e5e5e5'};
  background: ${({ inputColor }) => inputColor || 'white'};
  color: ${({ inputColor }) => 
    inputColor ? 'white' : '#00a0e9'};
  @media ${device.sp} {
    width: 24%;
  }
`;

const StyledCommentTitleWrapper = styled.div`
  @media ${device.sp} {
    display: flex;
    align-items: center;
  }
`;

const StyledLinkToOwnDiary = styled(Link)`
  display: block;
  margin-top: 8px;
  color: #00a0e9;
  font-size: 12px;
  @media ${device.sp} {
    margin-top: 0;
    margin-left: 16px;
  }
`;
