import React, { useState, useEffect, useCallback, useGlobal } from 'reactn';
import styled from 'styled-components';
import { Link } from '@material-ui/core';
import SubscriptionCancelDialog from '../SubscriptionCancelDialog';
import { Typography, Button } from '@material-ui/core';
import { useStyles } from '../hooks/useStyles';
import usePaymentContext from '../hooks/usePaymentContext';
import { useTranslation } from 'react-i18next';

const SubscriptionCancelButton = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { paymentConfig, fetchConfig } = usePaymentContext();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    fetchConfig();
    setDialogOpen(false);
  };

  return (
    <>
      <Button className={classes.baseButton} onClick={handleDialogOpen}>
        {t('PaymentTextCancelPlan', {})}
      </Button>
      <SubscriptionCancelDialog open={dialogOpen} onClose={handleClose} />
    </>
  );
};

export default SubscriptionCancelButton;
