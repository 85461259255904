import { useContext } from 'react';
import { PaymentContext } from '../PaymentProvider';

const usePaymentContext = () => {
  const context = useContext(PaymentContext);
  if (!context) {
    throw new Error('PaymentContext must be used within a PaymentProvider');
  }
  return context;
}

export default usePaymentContext;