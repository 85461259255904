import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

const Clipboard = ({ value }) => {

  const [copied, setCopied] = useState(false);

  const target = useRef();
  const button = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (target.current && button.current) {
      const clipboard = new ClipboardJS(button.current, {
        target: () => target.current
      });
      clipboard.on("success", function (e) {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 800);
      }); 
      return () => clipboard.destroy();
    }
  }, [copied]);

  return (
    <React.Fragment>
      <ClipboardInput
        ref={target}
        value={value ? value : ""}
        readOnly
      />
      {value && (
        <ClipboardButton
          ref={button}
        >
          <img src={RailsAssetPath("icon_clipboard.png")} />
          {copied && <ClipboardTooltip>{t("GenelalTextCopied",{})}</ClipboardTooltip>}
        </ClipboardButton>
      )}
    </React.Fragment>
  );
}

const ClipboardInput = styled.input`
  outline: none;
  background: transparent;
  text-overflow: ellipsis; 
`

const ClipboardButton = styled.button`
  width: 30px;
  padding: 5px;
  position: relative;
  opacity: 0.6;
  margin-bottom: 2px;
  img {
    pointer-events: none;
  }
`

const ClipboardTooltip = styled.span`
  z-index: 100;
  position: absolute;
  bottom: 26px;
  left: 50%;
  transform: translateX(-50%);
  padding: 3px 10px;
  background: #000;
  color: #fff;
  border-radius: 6px;
  font-size: 12px;
  &::before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #000;
    position: absolute;
    top: 21px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
`

export default Clipboard;