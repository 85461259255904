import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useGlobal,
} from 'reactn';
import styled from 'styled-components';
import {
  Typography,
  Grid,
  Container,
  IconButton,
  Divider,
  TextField,
} from '@material-ui/core';
import { useStyles } from '../hooks/useStyles';
import usePaymentContext from '../hooks/usePaymentContext';
import { FORM_TYPES } from '../constants';
import { useTranslation } from 'react-i18next';

const InputNumber = ({ formType }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { paymentConfig, fetchConfig } = usePaymentContext();
  const [paymentChargeQuantity, setPaymentChargeQuantity] = useGlobal(
    'paymentChargeQuantity'
  );
  const [basePrice, setBasePrice] = useState(0);
  const [basePoint, setBasePoint] = useState(0);
  const [planPt, setPlanPt] = useState(0);
  const [planAmount, setPlanAmount] = useState(0);

  const taxIncludePrice = useMemo(() => {
    return Math.floor(
      basePrice * paymentChargeQuantity -
        (basePrice * paymentChargeQuantity) / 1.1
    );
  }, [basePrice, paymentChargeQuantity]);

  useEffect(() => {
    setPaymentChargeQuantity(1);
  }, []);
  useEffect(() => {
    if (paymentConfig) {
      setBasePrice(paymentConfig.charge_amount);
      setBasePoint(paymentConfig.charge_base_point);
      setPlanPt(paymentConfig.plan_pt);
      setPlanAmount(paymentConfig.plan_amount);
      if (
        paymentConfig.available_plan &&
        (formType == FORM_TYPES.SUBSCRIPTION_UPDATE ||
          formType == FORM_TYPES.SUBSCRIPTION_BUY)
      ) {
        setPaymentChargeQuantity(paymentConfig.available_plan.quantity);
      }
    }
  }, [paymentConfig]);

  const setCount = (num) => {
    if (num <= 0) {
      setPaymentChargeQuantity(1);
    } else if (num > 999) {
      setPaymentChargeQuantity(999);
    } else {
      setPaymentChargeQuantity(num);
    }
  };

  const incrementNum = () => {
    let preNum = Number(paymentChargeQuantity);
    if (preNum + 1 > 999) return;
    setPaymentChargeQuantity(preNum + 1);
  };

  const decrementNum = () => {
    let preNum = Number(paymentChargeQuantity);
    if (preNum - 1 <= 0) return;
    setPaymentChargeQuantity(preNum - 1);
  };

  return (
    <Grid container spacing={2}>
      {formType !== FORM_TYPES.CHARGE && (
        <Grid container item justify='space-between'>
          <Grid container item xs={12} alignItems='center'>
            <Grid container item xs={8} alignItems='center'>
              <Grid item xs={7}>
                <Typography variant='subtitle2'>
                  {t('PaymentTextCurrentPlan', {})}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant='subtitle2' display='inline'>
                  {planPt.toLocaleString()}
                </Typography>
                pt
              </Grid>
              <Grid item xs={1}>
                <NowPlanDivider
                  orientation='vertical'
                  variant='middle'
                  flexItem
                  style={{ height: '40px', margin: '10px 13px' }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Grid container item xs={12}>
                <Typography variant='body1'>
                  ¥{planAmount.toLocaleString()}/{t('PaymentTextMonth', {})}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid container item xs={12}>
        <Container maxWidth='sm'>
          <Grid container item xs={12}>
            <Grid container item xs={8}>
              <Grid
                container
                item
                xs={4}
                direction='row'
                alignItems='center'
                justify='center'
              >
                <Grid container item xs={12} justify='center'>
                  <IconButton onClick={incrementNum}>▲</IconButton>
                </Grid>
                <Grid container item xs={10} justify='center'>
                  {/* <input type="text" name="quantity" value={subscriptionQuantity || 1} onChange={(e) => {setCount(e.target.value)}} onFocus={(e) => e.target.select()} required></input> */}
                  <TextField
                    className={classes.inputNumber}
                    id='outlined-basic'
                    size='small'
                    inputProps={{ min: 0, style: { textAlign: 'center' } }}
                    name='quantity'
                    value={paymentChargeQuantity || 1}
                    onChange={(e) => {
                      setCount(e.target.value);
                    }}
                    onFocus={(e) => e.target.select()}
                    color='primary'
                    variant='outlined'
                    type='number'
                    required
                  />
                </Grid>
                <Grid container item xs={12} justify='center'>
                  <IconButton onClick={decrementNum}>▼</IconButton>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={6}
                direction='row'
                alignItems='flex-end'
                style={{ height: '104px' }}
              >
                <Grid container item direction='column' alignItems='flex-start'>
                  <Grid item xs={12}>
                    000pt
                  </Grid>
                  <Grid item xs={12}>
                    <PointPrice>
                      {t('PaymentTextTaxIncludePrice', {
                        point: basePoint.toLocaleString(),
                        price: basePrice.toLocaleString(),
                      })}
                    </PointPrice>
                  </Grid>
                </Grid>
              </Grid>
              <Divider orientation='vertical' variant='middle' flexItem />
            </Grid>
            <Grid
              container
              item
              xs={4}
              direction='row'
              alignItems='center'
              justify='flex-start'
              spacing={0}
            >
              <Grid container item direction='column' alignItems='flex-start'>
                <Grid item xs={12}>
                  <Typography style={{ display: 'inline' }} variant='h6'>
                    ¥ {(basePrice * paymentChargeQuantity).toLocaleString()}
                  </Typography>
                  {formType !== FORM_TYPES.CHARGE
                    ? `/${t('PaymentTextMonth', {})}`
                    : ''}
                </Grid>
                <Grid item xs={12}>
                  <PointPrice>
                    {t('PaymentTextTaxText', { price: taxIncludePrice })}
                  </PointPrice>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid
        container
        item
        justify='center'
        direction='column'
        alignItems='center'
      >
        <Typography variant='caption'>
          {t('PaymentAnnotationFirst', {})}
        </Typography>
        <Typography variant='caption'>
          {t('PaymentAnnotationSecond', {})}
        </Typography>
        {formType !== FORM_TYPES.CHARGE && (
          <Typography variant='caption'>
            {t('PaymentAnnotationThird', {})}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

const PointPrice = styled.span`
  font-size: 8px;
`;

const NowPlanDivider = styled(Divider)`
  height: 40px;
  margin: 10px 13px;
`;
export default InputNumber;
