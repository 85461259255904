import React, { useState, useCallback, useEffect } from 'react';
import { useGlobal } from 'reactn';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { device } from 'javascript/utils/mediaQuery';
// import { ShopProduct, SkillProduct } from 'types';
import { useAddSkillProduct } from 'javascript/features/avatarShop/hooks/useAddSkillProduct';
import { Button, TextField } from '@material-ui/core';
import { InputQuantity } from './InputQuantity';

const StyledOverlay = styled.div`
  position: fixed;
  top: -65px;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  overflow: scroll;
`;

const StyledProductModal = styled.div`
  position: absolute;
  top: 152px;
  left: 50%;
  transform: translateX(-50%);
  width: 360px;
  max-height: 70vh;
  padding: 20px 20px 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 4px #ccc;
  overflow: scroll;
  @media ${device.sp} {
    top: 160px;
    width: 280px;
    padding: 16px 16px 32px;
  }
`;

const StyledCloseButton = styled.div`
  position: absolute;
  cursor: pointer;
  img {
    width: 16px;
  }
`;

const StyledModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledModalTitle = styled.p`
  margin-top: 48px;
  margin-bottom: 16px;
  font-size: 18px;
  color: #000;
  @media ${device.sp} {
    margin-top: 24px;
    font-size: 14px;
  }
`;

const StyledModalDescription = styled.p`
  font-size: 14px;
  margin-bottom: 16px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-flow: column;
  width: 100%;
  gap: 6px;
  align-items: center;
`;

const StyledForWorldWrapper = styled.div`
  display: flex;
  font-size: 13px;
  align-items: center;
  gap: 4px;
  @media ${device.sp} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const StyledForWorldTitle = styled.div`
  margin: 0 12px;
  @media ${device.sp} {
    margin: 0 0;
  }
`;
const StyledToggleBtnArea = styled.div`
  margin: 10px 22px;
  width: 60px;
  transform: rotate(180deg);
  border-radius: 50px;
  input[type='checkbox'] {
    display: none;
  }
  .for_world-text {
    transform: rotate(180deg);
    position: absolute;
    top: 6px;
    font-size: 12px;
    color: #bfbfbf;
  }

  .for_world-text-on {
    left: 70px;
  }

  .for_world-text-off {
    right: 70px;
  }

  label {
    display: block;
    box-sizing: border-box;
    text-align: left;
    border: 4px solid #bfbfbf;
    border-radius: 3px;
    height: 28px;
    background: #bfbfbf;
    border-radius: 50px;
  }

  #for_world-box {
    display: inline-block;
    height: 20px;
    width: 20px;
    background: white;
    transition: 0.3s;
    border-radius: 50px;
  }

  #for_world:checked + label #for_world-box {
    transform: translateX(160%);
    background: white;
  }

  #for_world:checked + label {
    transition: 0.5s all;
    border: 4px solid #03a9f4;
    background: #03a9f4;
  }

  #for_world:checked + label .for_world-text-on {
    color: #03a9f4;
  }

  #for_world:checked + label .for_world-text-off {
    color: #bfbfbf;
  }
`;

const StyledFileUpload = styled.div`
  margin: auto;
  width: 100px;
`;

const SkillThumbnailWrapper = styled.div`
  padding: 0 5px 1px;
  position: relative;
  background-color: #b6b6b6;
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
  z-index: 0;
  cursor: pointer;
  height: 90px;
  width: 90px;
  top: 4px;
  left: 4px;
`;

const SkillThumbnailItem = styled.div<{ backgroundImage: string }>`
  background-image: url('${({ backgroundImage }) => backgroundImage}');
  background-size: cover;
  background-position: center;
  position: absolute;
  border-radius: 50px;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: 90px;
  height: 90px;
`;

const SkillThumnailEditButton = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 50px;
  margin: 5px;
  background-color: #0000004f;
`;

const SkillThumnailEditButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  cursor: pointer;
`;

const StyledTextField = styled(TextField)`
  background: rgba(128, 128, 128, 0.06);
`;

const StyledProductNameField = styled(StyledTextField)`
  width: 88%;
`;
const StyledDescriptionField = styled(StyledTextField)`
  width: 88%;
`;
const StyledViketCoin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

const StyledViketIcon = styled.div`
  width: 16px;
  margin-right: 10px;
`;

const StyledViketCoinTextField = styled(StyledTextField)`
  width: 100px;
  input {
    text-align: center;
    padding: 10px 4px;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
      -moz-appearance: textfield;
    }
  }
`;

const StyledSubmitButtonArea = styled.div`
  margin: 20px 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
`;
const StyledSubmitButton = styled(Button) <{ published: number }>`
  border-radius: 4;
  height: 40;
  max-width: 225px;
  min-width: 158px;
  width: 60%;
  padding: 6px 14px;
  border: 1px solid ${({ published }) => (published ? '#00A0E9' : '#00A0E9')};
  background: linear-gradient(
    ${({ published }) =>
    published
      ? '45deg, #00A0E9 30%, #00A0E9 90%'
      : '45deg, #fff 30%, #fff 90%'}
  );

  .MuiButton-label {
    color: ${({ published }) => (published ? '#fff' : '#00A0E9')};
  }
  &:disabled {
    border: 1px solid #cfcfcf;
    background: linear-gradient(
      ${({ published }) =>
    published
      ? '45deg, #cfcfcf 30%, #cfcfcf 90%'
      : '45deg, #fff 30%, #fff 90%'}
    );
    .MuiButton-label {
      color: ${({ published }) => (published ? '#fff' : '#cfcfcf')};
    }
  }
`;

const StyledInputQuantityWrapper = styled.div`
  display: flex;
  align-items: center;
  /* column-gap: 0; */
  justify-content: center;
  margin: 0 auto;
  width: 57%;
  @media ${device.sp} {
    width: 77%;
  }
  > p {
    width: 100%;
    font-size: 9px;
  }
`;

const StyledErrorMessage = styled.p`
  color: #f00;
  font-size: 12px;
  margin: 8px 0 -12px;
`;

const StyledExpiredAtWrapper = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: space-around;
  width: 58%;
  margin-bottom: 10px;
`;

const StyledExpiredAtInput = styled.input`
  border: 1px solid #bfbfbf;
  padding: 10px 4px;
`;

interface Props {
  // product?: ShopProduct | SkillProduct | null;
}

export const ShopAddSkillProductModal: React.FC<Props> = (
  {
    // product,
  }
) => {
  const [uploadImage, setUploadImage] = useState(
    // @ts-ignore
    RailsAssetPath(`avatar_shop/skill_default.png`)
  );
  const [quantity, setQuantity] = useState<number>(1);
  const [isEdit, setIsEdit] = useState<boolean>(true);
  const [published, setPublished] = useState<boolean>(false);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      product_name: '',
      description: '',
      viket: 100,
      for_world: 0,
      quantity: 99,
      avatar_skill_product_id: "",
      published: false,
      file: undefined,
      expired_at: new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().split('T')[0]
    }
  });
  const { handleAddSkillProduct } = useAddSkillProduct();
  const updateQuantity = useCallback(
    (quantity: number) => {
      setQuantity(quantity);
      setValue('quantity', quantity);
    },
    [quantity]
  );
  const [_, setIsShowEditVirtualWorldModal] = useGlobal<{
    isShowSkillEditModal: boolean;
  }>('isShowSkillEditModal');
  const [product] = useGlobal<{ editSkillProduct: any }>('editSkillProduct');

  useEffect(() => {
    if (product) {
      setQuantity(product.stock);
      setValue('product_name', product.product_name);
      setValue('quantity', product.stock);
      setValue('description', product.description);
      setValue('viket', product.viket);
      setValue('for_world', product.for_world);
      setValue('avatar_skill_product_id', product.avatar_skill_product_id);
      setIsEdit(product.is_edit);
      setPublished(product.published);
      if (product.image_url) {
        setUploadImage(product.image_url);
      }
    } else {
      setQuantity(99);
      setValue('quantity', 99);
      setValue('viket', 100);
    }
    return () => {
      // setproduct(undefined);
    };
  }, [product]);

  const onChangeUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      const file = e.target.files[0];
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      reader.onloadend = () => {
        setUploadImage(typeof reader.result === 'string' ? reader.result : '');
      };
    }
  };

  return (
    <StyledOverlay>
      <StyledProductModal>
        <StyledCloseButton
          onClick={() => {
            setIsShowEditVirtualWorldModal(false);
          }}
        >
          {/* @ts-ignore */}
          <img src={RailsAssetPath('close-img-gray.png')} />
        </StyledCloseButton>
        <StyledModalBodyWrapper>
          <StyledModalTitle>{t('ModalAddSkillTitle', {})}</StyledModalTitle>
          <StyledModalDescription>
            {t('ModalAddSkillDescription', {})}
          </StyledModalDescription>

          <StyledForm onSubmit={handleSubmit(handleAddSkillProduct)}>
            <input type='hidden' {...register('avatar_skill_product_id')} />
            <input type='hidden' {...register('published')} />
            <StyledFileUpload>
              <label htmlFor='fileUpload'>
                <div className='relative'>
                  <SkillThumbnailWrapper>
                    <SkillThumbnailItem
                      //@ts-ignore
                      backgroundImage={uploadImage}
                    />
                  </SkillThumbnailWrapper>
                  <SkillThumnailEditButtonWrapper>
                    <SkillThumnailEditButton>
                      <img
                        className='profile-edit-icon'
                        // @ts-ignore
                        src={RailsAssetPath('camera-white.png')}
                      />
                    </SkillThumnailEditButton>
                  </SkillThumnailEditButtonWrapper>
                </div>
              </label>
              <input
                type='file'
                id='fileUpload'
                accept='image/*'
                style={{ display: 'none' }}
                {...register('file', {
                  onChange: (e) => onChangeUpload(e),
                })}
              />
            </StyledFileUpload>
            <StyledProductNameField
              required
              rows={1}
              inputProps={{
                maxLength: 20,
              }}
              placeholder={t('ModalAddSkillProductNamePlaceholder', {})}
              variant='outlined'
              disabled={!isEdit}
              {...register('product_name')}
            />
            <StyledDescriptionField
              required
              // error={reasonError}
              multiline
              rows={4}
              placeholder={t('ModalAddSkillDescriptionPlaceholder', {})}
              disabled={!isEdit}
              variant='outlined'
              {...register('description')}
            />
            {errors.viket && (
              <StyledErrorMessage>
                {t('ModalAddSkillMinpricealert')}
              </StyledErrorMessage>
            )}
            <StyledViketCoin>
              <StyledViketIcon>
                {/* @ts-ignore */}
                <img src={RailsAssetPath('mypage/coin.png')} />
              </StyledViketIcon>
              <StyledViketCoinTextField
                required
                disabled={!isEdit}
                type='number'
                variant='outlined'
                {...register('viket', { min: 10 })}
              />
            </StyledViketCoin>

            <StyledInputQuantityWrapper>
              <p>{t('ModalAddSkillExhibit', {})}</p>
              <InputQuantity
                quantity={quantity}
                updateQuantity={updateQuantity}
                register={register}
                disabled={!!false}
              />
            </StyledInputQuantityWrapper>

            <StyledExpiredAtWrapper >
              {/* TODO: i18n */}
              <div>
                使用期限
              </div>
              <StyledExpiredAtInput
                type="date"
                min={new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0]}
                // default value is 1 month later
                {...register('expired_at')}
              />
            </StyledExpiredAtWrapper>

            <StyledForWorldWrapper>
              <StyledForWorldTitle>
                {t('ModalAddSkillForWorld', {})}
              </StyledForWorldTitle>
              <StyledToggleBtnArea>
                <input
                  disabled={!isEdit}
                  type='checkbox'
                  id='for_world'
                  {...register('for_world')}
                />
                <label htmlFor='for_world'>
                  <p className='for_world-text for_world-text-on'>
                    {t('GenelalButtonOn')}
                  </p>
                  <p className='for_world-text for_world-text-off'>
                    {t('GenelalButtonOff')}
                  </p>
                  <div id='for_world-box'></div>
                </label>
              </StyledToggleBtnArea>
            </StyledForWorldWrapper>
            <StyledSubmitButtonArea>
              <StyledSubmitButton
                type='submit'
                published={+false}
                disabled={published && (product ? product.stock > 0 : false)}
                onClick={() => {
                  setValue('published', false);
                  handleSubmit(handleAddSkillProduct);
                }}
              >
                {t('GenelalButtonDraft', {})}
              </StyledSubmitButton>
              <StyledSubmitButton
                type='submit'
                published={+true}
                // disabled={published && (product ? product.stock > 0 : false)}
                onClick={() => {
                  setValue('published', true);
                  handleSubmit(handleAddSkillProduct);
                }}
              >
                {t('GenelalButtonPublished', {})}
              </StyledSubmitButton>
            </StyledSubmitButtonArea>
          </StyledForm>
          {/* <StyledForm 
            onSubmit={handleSubmit(handleDeleteSkillProduct)}
          >
            <StyledSubmitButton 
              type="submit"
              isDelete={true}
            >
              {t('GenelalButtonDelete', {})}
            </StyledSubmitButton>
          </StyledForm> */}
        </StyledModalBodyWrapper>
      </StyledProductModal>
    </StyledOverlay>
  );
};
