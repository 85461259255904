import { AvatarPartsType } from 'types';

/**
着せ替えページの
アバターパーツのタイプ選択エリアに表示するパーツタイプ画像を管理するための定数
 */
export const avatarPartsArray: AvatarPartsType[] = [
  'hat',
  'hair',
  'eye',
  'mouth',
  'clothes',
];
