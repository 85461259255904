import React from 'react';
import styled from 'styled-components';
import VideoTrack from './VideoTrack';
import useVideoCallContext from './hooks/useVideoCallContext';
import AvatarIcon from './icon/AvatarIcon';
import ToggleAudioButton from './Buttons/ToggleAudioButton';
import ToggleVideoButton from './Buttons/ToggleVideoButton';

const LocalVideoPreview = ({identity, isGuest, isFetching}) => {
  const { localTracks } = useVideoCallContext();
  const videoTrack = localTracks.find(
    track => !track.name.includes('screen') && track.kind === 'video'
  )

  return (
    <Container>
      <InnerContainer>
        {videoTrack ? (
            <VideoTrack track={videoTrack} isLocal />
        ) : (
          <AvatarContainer>
             <img src={RailsAssetPath("video_call/icon/avatar.png")} />
             {/* <AvatarIcon /> */}
          </AvatarContainer>
        )}
      </InnerContainer>
      {/* {isGuest && ( */}
        <ToggleButtons>
          <ToggleButtonWrapper>
            <ToggleAudioButton disabled={isFetching} />
            <ToggleVideoButton disabled={isFetching} />
          </ToggleButtonWrapper>
        </ToggleButtons>
      {/* )} */}
    </Container>
  ) 
}

const Container = styled.div`
  position:relative;
  overflow:hidden;
  padding-top: ${(9 / 16) * 100}%;
  text-align:center;
  border-radius: 6px;
`

const ToggleButtons = styled.div `
  position:relative;
  top: -10px;
  opacity: 0.8;
  text-align:center;
`
const ToggleButtonWrapper = styled.span`
  width: 100%;
  margin: 1em 0;
  background-color: #000;
  border-radius: 4px;
  padding: 12px 4px;
`

const InnerContainer = styled.div`
  position:absolute;
  top: 0;
  left: 0;
  width:100%;
  height:100%;
`

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  width: 100%;
  height: 100%;
`
export default LocalVideoPreview;