import React, { useCallback, memo } from 'react';
import styled from 'styled-components';

const DEFAULT_THUMBNAIL_SIZE = '35px';

const ProfileThumbnail = ({
  image,
  size,
  isRoom = false,
  user = {},
  showReport = {},
  inCall = false,
  speakerEnabled = false,
  isClickable = false,
}) => {
  const hasShowReportFunction = typeof showReport === 'function';

  const handleClick = useCallback(() => {
    hasShowReportFunction && showReport(user);
  }, [hasShowReportFunction, showReport, user]);

  let bordercolor;
  let thumbnail = image ? image : RailsAssetPath('noimage_icon.png');
  let thumbSize = size ? size : DEFAULT_THUMBNAIL_SIZE;

  let thumbnailSize = thumbSize;
  if (inCall || speakerEnabled) {
    const sizeFigure = thumbSize.split('px')[0];
    thumbnailSize = sizeFigure - 4;
  }

  const thumbnailStyle = {
    backgroundImage: `url(${thumbnail})`,
    width: thumbnailSize,
    height: thumbnailSize,
  };

  if (speakerEnabled) {
    bordercolor = 'linear-gradient(to bottom, #78f4ff, #31ace4)';
  } else {
    bordercolor = 'none';
  }

  return (
    <__ProfileThumbnail
      style={{ width: thumbSize, height: thumbSize }}
      isRoom={isRoom}
      // onClick={handleClick}
      isClickable={isClickable || hasShowReportFunction}
    >
      <__ProfileThumbnailItem
        style={thumbnailStyle}
        hasBorder={inCall || speakerEnabled ? true : false}
      />
      <__ProfileBackground color={bordercolor} />
    </__ProfileThumbnail>
  );
};

const __ProfileThumbnail = styled.div`
  padding: 0 5px 1px;
  position: relative;
  background-color: #b6b6b6;
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
  z-index: 0;
  cursor: ${(props) => (props.isClickable ? `pointer` : `auto`)};
  margin: ${(props) => (props.isRoom ? `2px` : `5px`)};
`;

const __ProfileThumbnailItem = styled.div`
  position: absolute;
  background-size: cover;
  background-position: center;
  top: ${(props) => (props.hasBorder ? `2px` : `0`)};
  left: ${(props) => (props.hasBorder ? `2px` : `0`)};
  border-radius: 50%;
  z-index: 1;
`;

const __ProfileBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  background: ${(props) => props.color};
`;

export default memo(ProfileThumbnail);
