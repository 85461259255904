import React from 'react';
import { EnterTimeButton } from 'javascript/shared/components/Button/EnterTimeButton';
import { StartVideoCallButton } from 'javascript/shared/components/Button/StartVideoCallButton';
import { StartVoiceChatButton } from 'javascript/shared/components/Button/StartVoiceChatButton';
import { BASIC } from 'javascript/shared/constants/plan';

export const EnterRoomModalFooter = ({
  plan,
  roomDetail,
  handleSubmit,
  handleVoiceChat,
}) => (
  <div className='flex justify-center'>
    {(roomDetail.room_type === 'nonfunctional' || !roomDetail.room_type) && (
      <EnterTimeButton onClick={handleSubmit} />
    )}
    {plan !== BASIC && roomDetail.room_type === 'voice' && (
      <StartVoiceChatButton roomDetail={roomDetail} onClick={handleVoiceChat} />
    )}
    {roomDetail.room_type === 'video' && (
      <StartVideoCallButton roomDetail={roomDetail} onClick={handleSubmit} />
    )}
  </div>
);
