import React, { KeyboardEvent, useCallback, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { secretChatChannel } from 'javascript/channels/avatar_chat_secret_channel';
import { interactWorldsecretChatChannel } from 'javascript/channels/interact_world_chat_secret_channel';
import { ResData, SecretChat } from 'types';

export const useSecretChat = (
  isWorld?: boolean,
  interactWorldId?: number,
) => {
  const mounted = useRef(false);
  const [val, setVal] = useState('');
  const [secretChats, setSecretChats] = useState<SecretChat[]>([]);
  const updateSecretChats = useCallback((data: SecretChat[]) =>  {
    if (mounted.current) setSecretChats(data) 
  }, []);
  const { subscribeSecretChatChannel } = secretChatChannel(updateSecretChats) 
  const cable = subscribeSecretChatChannel();
  const { subscribeInteractWorldSecretChatChannel } = interactWorldsecretChatChannel(updateSecretChats, interactWorldId) 
  const worldChatCable = subscribeInteractWorldSecretChatChannel();

  const handleCreateSecretChatRoom = useCallback(
    (
      avatarId: number,
      joiningSecretChat: SecretChat | undefined,
      currentAvatarId: number | undefined
    ) => {
      const chatCable = isWorld ? worldChatCable : cable; 
      if (!currentAvatarId) return;
      if (!joiningSecretChat) {
        chatCable.add_member_for_chat_secret_room({
          avatar_chat_secret_room_id: null,
          avatar_ids: [currentAvatarId, avatarId],
          interact_world_id: interactWorldId
        });
        return;
      } else {
        chatCable.add_member_for_chat_secret_room({
          avatar_chat_secret_room_id:
            joiningSecretChat.avatar_chat_secret_room_id,
          avatar_ids: [avatarId],
          interact_world_id: isWorld ? interactWorldId : null,
        });
        return;
      }
    },
    [secretChats, isWorld, interactWorldId]
  );

  const submit = (avatarId: number, secretChatId: number, interact_world_id: number | null) => {
    if (val.length === 0) return;
    const chatCable = interact_world_id ? worldChatCable : cable; 
    chatCable.create_chat_secret_item({
      avatar_chat_secret_room_id: secretChatId,
      avatar_id: avatarId,
      content: val,
      interact_world_id: interact_world_id,
    });
    setVal('');
  };

  const handleOnChangeVal = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = e.target;
      setVal(value);
    },
    [val]
  );

  const handleOnSubmit = useCallback(
    (avatarId: number, secretChatId: number, interact_world_id: number | null) => {
      submit(avatarId, secretChatId, interact_world_id);
    },
    [val, submit, isWorld]
  );

  const handleOnKeyDown = useCallback(
    (
      avatarId: number,
      secretChatId: number,
      e: KeyboardEvent<HTMLTextAreaElement>,
      interact_world_id: number
    ) => {
      if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
        submit(avatarId, secretChatId, interact_world_id);
      }
    },
    [val, submit]
  );

  const handleDeleteChatMember = useCallback(
    (avatarId: number, secretChatId: number ) => {
      const chatCable = interactWorldId ? worldChatCable : cable;
      chatCable.destroy_member_for_chat_secret_room({
        avatar_chat_secret_room_id: secretChatId,
        avatar_id: avatarId,
        interact_world_id: interactWorldId
      });
    },
    [secretChats, interactWorldId]
  );

  const getData = useCallback(() => {
    axios
      .get<ResData<SecretChat[]>>('/api/v1/avatar/chat_secrets', {params: {interact_world_id: interactWorldId}})
      .then(({ data }) => {
        setSecretChats(data.success.chat_secrets);
      });
  }, [interactWorldId]);

  useEffect(() => {
    if (!mounted.current) return;
    getData();
  },[interactWorldId])

  useEffect(() => {
    if (!mounted.current) return;
    getData();
    subscribeSecretChatChannel();
  }, []);

  useEffect(() => {
    mounted.current = true;
    return () => { mounted.current = false; };
  }, []);

  return {
    handleCreateSecretChatRoom,
    handleDeleteChatMember,
    handleOnChangeVal,
    handleOnKeyDown,
    handleOnSubmit,
    secretChats,
    val,
  };
};
