/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, {
  Pagination,
  Autoplay,
  Controller,
  EffectCoverflow,
} from 'swiper';
import styled from 'styled-components';
import {
  MQ_DESKTOP,
  MQ_MOBILE,
} from 'javascript/components/landingPage/lib/MQ';
import {
  layerZIndex,
  pcPx,
  spp,
} from 'javascript/components/landingPage/lib/cssUtil';
import {
  BLACK,
  buttonReset,
  jpStyle,
  onlyPC,
  onlySP,
  fadeInStyle,
} from 'javascript/components/landingPage/local/commonCss';
import {
  onScrollPromise,
  removeOnscrollEvent,
} from 'javascript/components/landingPage/local/scrollPromise';
import { useIsSpStoreValue } from 'javascript/components/landingPage/store/isSpStore';
import {
  LANGUAGE,
  useLanguageStoreValue,
} from 'javascript/components/landingPage/store/language';
import { ROOM } from 'javascript/components/landingPage/components/RoomWrapper';
import { t } from 'i18next';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  ${MQ_MOBILE} {
    padding-top: ${spp(120)};
    ul {
      display: none;
    }
  }
  ${MQ_DESKTOP} {
    display: flex;
    margin: auto;
    padding-top: ${pcPx(100)};
    &:before {
      content: '';
      background-color: rgba(255, 255, 255, 0.39);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: ${pcPx(1800)};
      height: 100%;
      top: 0;
    }
    ul {
      list-style: none;
      padding: 0;
      margin-left: ${pcPx(65)};
    }
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .swiper {
    overflow: visible;
    position: relative;
    ${MQ_MOBILE} {
      width: ${spp(455)};
      height: ${spp(878)};
      perspective-origin: -100% 40%;
    }
    ${MQ_DESKTOP} {
      width: 688px;
      height: 320px;
      margin: auto;
      margin-left: ${pcPx(-80)};
      padding-top: ${pcPx(50)};
      padding-bottom: ${pcPx(30)};
      padding-right: ${pcPx(400)};
      perspective-origin: 10% 50%;
    }
  }
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    display: flex;
  }
  .swiper-slide {
    width: 100%;
    height: 100%;
    opacity: 0;
    transform-origin: left top;
    transition: 0.3s opacity ease, 1.3s transform ease;
    ${MQ_MOBILE} {
      transform: translate3d(0, 0, 0);
    }
    ${MQ_DESKTOP} {
      transform: translate3d(${pcPx(0)}, ${pcPx(0)}, ${pcPx(0)});
    }
    &[data-prev='true'] {
      opacity: 0;
      ${MQ_MOBILE} {
        transform: translate3d(${spp(0)}, ${spp(0)}, ${spp(400)}) !important;
      }
      ${MQ_DESKTOP} {
        transform: translate3d(
          ${pcPx(50)},
          ${pcPx(100)},
          ${pcPx(200)}
        ) !important;
      }
    }
  }
  .swiper-slide-next {
    ${MQ_MOBILE} {
      transform: translate3d(0, 0, 0);
    }
    ${MQ_DESKTOP} {
      transform: translate3d(${pcPx(0)}, ${pcPx(0)}, ${pcPx(0)});
    }
  }
  .swiper-slide-prev {
    opacity: 0;
    ${MQ_MOBILE} {
      transform: translate3d(${spp(0)}, ${spp(0)}, ${spp(200)}) !important;
    }
    ${MQ_DESKTOP} {
      transform: translate3d(${pcPx(0)}, ${pcPx(50)}, ${pcPx(100)}) !important;
    }
  }
  .swiper-slide-active {
    opacity: 1;
    ${MQ_MOBILE} {
      transform: translate3d(0, 0, 0);
    }
    ${MQ_DESKTOP} {
      transform: translate3d(0, 0, 0);
    }
  }
  .swiper-pagination {
    display: flex;
    margin: auto;
    position: absolute;
    align-items: center;
    ${MQ_MOBILE} {
      justify-content: space-around;
      width: ${spp(300)};
      right: 0;
      left: 0;
    }
    ${MQ_DESKTOP} {
      display: none;
    }
  }
  .swiper-pagination-bullet {
    opacity: 1;
    background-color: rgba(49, 49, 49, 0.45);
    ${MQ_MOBILE} {
      width: ${spp(13)};
      height: ${spp(13)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(14)};
      height: ${pcPx(14)};
      margin-top: ${pcPx(20)} !important;
      margin-bottom: ${pcPx(20)} !important;
    }
  }
  .swiper-pagination-bullet-active {
    background-color: ${BLACK};
    ${MQ_MOBILE} {
      width: ${spp(19)};
      height: ${spp(19)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(20)};
      height: ${pcPx(20)};
      margin-top: ${pcPx(40)} !important;
      margin-bottom: ${pcPx(40)} !important;
    }
  }
`;

const PcWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  ${MQ_DESKTOP} {
    overflow: visible;
    display: flex;
    width: ${pcPx(1800 - 80 * 2)};
    margin: auto;
  }
`;

const TextWrapper = styled.div`
  ${MQ_DESKTOP} {
    overflow: visible;
    display: flex;
    flex-direction: column;
    margin: auto;
    position: relative;
    z-index: ${layerZIndex('button')};
    &:before {
      content: '';
      width: 1px;
      height: ${pcPx(0)};
      border-left: solid 1px #707070;
      position: absolute;
      top: 0;
      left: 0;
      transition: height 0.5s cubic-bezier(0.4, 0.59, 0.41, 0.97) 0s;
    }
    &[data-visible='true'] {
      &:before {
        height: ${pcPx(435)};
      }
    }
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  ${MQ_MOBILE} {
    margin: auto;
    margin-bottom: ${spp(50)};
    justify-content: center;
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(600)};
    margin-top: ${pcPx(10)};
    margin-bottom: ${pcPx(50)};
    margin-left: ${pcPx(65)};
  }
`;

const Title = styled.h3`
  ${fadeInStyle}
  ${jpStyle}
  color: ${BLACK};
  font-weight: bold;
  position: relative;
  text-align: center;
  overflow: visible;
  ${MQ_MOBILE} {
    font-size: ${spp(40)};
    line-height: ${spp(68)};
    margin-top: ${spp(0)};
    margin-bottom: ${spp(0)};
    margin-left: ${spp(10)};
    margin-right: ${spp(10)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(40)};
    line-height: ${pcPx(68)};
    margin-top: ${pcPx(0)};
    margin-bottom: ${pcPx(0)};
    margin-right: ${pcPx(20)};
  }
`;

const SubTitle = styled.span`
  ${jpStyle}
  ${fadeInStyle}
  transition-delay: 0.1s;
  font-weight: 300;
  ${MQ_MOBILE} {
    font-size: ${spp(24)};
    line-height: ${spp(68)};
    margin-top: ${spp(0)};
    margin-bottom: ${spp(0)};
    margin-left: ${spp(10)};
    margin-right: ${spp(10)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(24)};
    line-height: ${pcPx(41)};
    margin-top: ${pcPx(0)};
    margin-bottom: ${pcPx(0)};
    margin-right: ${pcPx(20)};
  }
`;

const Icon = styled.span`
  ${fadeInStyle}
  transition-delay: 0.2s;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: block;
  ${MQ_MOBILE} {
    width: ${spp(50)};
    height: ${spp(50)};
    margin-bottom: ${spp(0)};
    margin-left: ${spp(10)};
    margin-right: ${spp(10)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(50)};
    height: ${pcPx(50)};
    margin-bottom: ${pcPx(0)};
  }
`;

const SwiperWrapper = styled.div`
  ${fadeInStyle}
  &[data-type="VOICE"] {
    .swiper-pagination {
      ${MQ_MOBILE} {
        width: ${spp(195)};
      }
    }
  }
  &[data-type='NORMAL'] {
    .swiper-pagination {
      ${MQ_MOBILE} {
        width: ${spp(195)};
      }
    }
  }
`;

const SlideTitle = styled.p`
  ${jpStyle}
  ${fadeInStyle}
  ${onlySP}
  transition-delay: 0.1s;
  color: ${BLACK};
  font-weight: bold;
  position: relative;
  text-align: center;
  overflow: visible;
  white-space: pre-wrap;
  ${MQ_MOBILE} {
    font-size: ${spp(24)};
    line-height: ${spp(41)};
    margin-top: ${spp(30)};
    margin-bottom: ${spp(15)};
    padding: 0 ${spp(32.5)};
    padding-bottom: ${spp(5)};
  }
`;

const SlideButtonPC = styled.button`
  ${fadeInStyle}
  ${buttonReset}
  ${onlyPC}
  width: ${pcPx(14)};
  height: ${pcPx(14)};
  background-color: rgba(49, 49, 49, 0.45);
  border-radius: ${pcPx(50)};
  display: block;
  margin-left: ${pcPx(2)};
  &[data-active='true'] {
    background-color: ${BLACK};
    width: ${pcPx(20)};
    height: ${pcPx(20)};
    margin-left: ${pcPx(0)};
  }
`;

const SlideTitlePC = styled.p`
  ${fadeInStyle}
  ${jpStyle}
  ${onlyPC}
  color: ${BLACK};
  position: relative;
  overflow: visible;
  white-space: pre-wrap;
  ${MQ_DESKTOP} {
    width: ${pcPx(690)};
    font-size: ${pcPx(24)};
    line-height: ${pcPx(41)};
    margin-top: ${pcPx(10)};
    margin-bottom: ${pcPx(10)};
  }
  &[data-active='true'] {
    font-weight: bold;
    margin-top: ${pcPx(30)};
    margin-bottom: ${pcPx(30)};
  }
`;

const Text = styled.p`
  ${jpStyle}
  ${fadeInStyle}
  transition-delay: 0.3s;
  white-space: pre-wrap;
  color: ${BLACK};
  position: relative;
  overflow: visible;
  ${MQ_MOBILE} {
    text-align: center;
    font-size: ${spp(18)};
    line-height: ${spp(31)};
    margin-top: ${spp(10)};
    margin-bottom: ${spp(0)};
    padding: 0 ${spp(32.5)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(760)};
    font-size: ${pcPx(24)};
    line-height: ${pcPx(41)};
    letter-spacing: ${pcPx(-1)};
    margin-top: ${pcPx(5)};
    padding-bottom: ${pcPx(15)};
    margin-bottom: ${pcPx(0)};
    margin-left: ${pcPx(65)};
  }
`;

const Alert = styled.p`
  ${jpStyle}
  ${fadeInStyle}
  transition-delay: 0.3s;
  ${MQ_MOBILE} {
    font-size: ${spp(18)};
    text-align: center;
    margin-top: ${spp(20)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(20)};
    margin-left: ${pcPx(65)};
  }
`;

const Img = styled.pre({
  display: 'block',
  margin: 'auto',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  [MQ_MOBILE]: {
    width: spp(350),
    height: spp(700),
    marginTop: spp(50),
    marginBottom: spp(40),
    filter: `drop-shadow(0 ${spp(3)} ${spp(30)} rgba(0, 0, 0, 0.15))`,
  },
  [MQ_DESKTOP]: {
    width: pcPx(981),
    height: pcPx(606),
    marginBottom: pcPx(30),
    filter: `drop-shadow(0 ${pcPx(3)} ${pcPx(6)} rgba(0, 0, 0, 0.15))`,
  },
});

const Black = styled.div`
  position: relative;
  margin: auto;
  ${MQ_MOBILE} {
    background-color: rgba(0, 0, 0, 0.4);
    width: ${spp(290)};
    height: ${spp(597)};
    top: ${spp(60)};
    border-radius: ${spp(3)} ${spp(3)} ${spp(30)} ${spp(30)};
  }
  ${MQ_DESKTOP} {
    background-color: rgba(0, 0, 0, 0.6);
    width: ${pcPx(719)};
    height: ${pcPx(442)};
    top: ${pcPx(82)};
  }
`;

const SpotLight = styled.div`
  margin: auto;
  color: white;
  position: absolute;
  transition: transform 1s cubic-bezier(0.24, 1, 0.25, 1) 0s;
  background-color: transparent;
  ${MQ_MOBILE} {
    backdrop-filter: brightness(170%);
    width: ${spp(130)};
    height: ${spp(130)};
    border-radius: ${spp(100)};
    top: ${spp(50)};
    left: ${spp(100)};
  }
  ${MQ_DESKTOP} {
    backdrop-filter: brightness(250%);
    width: ${pcPx(170)};
    height: ${pcPx(170)};
    border-radius: ${pcPx(100)};
    top: ${pcPx(50)};
    left: ${pcPx(200)};
    &[data-active='true'] {
      transform: scale(1) translate3d(0, 0, 0);
    }
    &[data-active='false'] {
      transform: scale(0.01) translate3d(0, 0, 0);
    }
  }
`;

const SpotLightVoice = styled.div`
  margin: auto;
  color: white;
  position: absolute;
  transition: transform 1s cubic-bezier(0.24, 1, 0.25, 1) 0s;
  background-color: transparent;
  ${MQ_DESKTOP} {
    backdrop-filter: brightness(250%);
    border-radius: ${pcPx(100)};
    top: ${pcPx(72)};
    right: ${pcPx(124)};
    width: ${pcPx(120)};
    height: ${pcPx(120)};
  }
  &[data-active='true'] {
    transform: scale(1) translate3d(0, 0, 0);
  }
  &[data-active='false'] {
    transform: scale(0.01) translate3d(0, 0, 0);
  }
`;

export interface RoomItem {
  name: string;
  icon: string;
  title: string;
  subtitle: string;
  text: string;
  images: string[];
  imagesPc: string[];
  slide: string[];
}

const RoomContent = (props: { items: RoomItem }) => {
  const { items } = props;
  SwiperCore.use([Pagination, Autoplay, Controller, EffectCoverflow]);
  const [windowWidth, setWindowWidth] = useState(0);
  const [swiper, setSwiper] = useState<any>(null);
  const [id, setId] = useState(0);
  const [realId, setRealId] = useState(0);
  const isSlideMove = useRef(false);
  const slideX = useRef(0);
  const isSpStoreValue = useIsSpStoreValue();
  const LENGTH = items.images.length;
  const fvRef = useRef<HTMLDivElement | null>(null);
  const imgRef = useRef<HTMLDivElement | null>(null);
  const [isFvRefScrolled, setIsFvRefScrolled] = useState(false);
  const [isImgRefScrolled, setIsImgRefScrolled] = useState(false);
  const languageStoreValue = useLanguageStoreValue();

  const onClickButton = (swiper: any, i: number) => {
    swiper.slideToLoop(i);
  };

  const onTouchStart = (swiper: any) => {
    isSlideMove.current = true;
    slideX.current = swiper.touches.startX;
  };

  const onTouchEnd = (swiper: any) => {
    if (!isSlideMove.current) return;
    isSlideMove.current = false;
    if (slideX.current < swiper.touches.startX) {
      swiper.slidePrev();
    } else {
      swiper.slideNext();
    }
  };

  useEffect(() => {
    const onResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', onResize);

    onResize();
    return () => {
      window.addEventListener('resize', onResize);
    };
  }, []);

  const getSpotLightPc = (room: string, i: number) => {
    let top = 67;
    let left = 255;
    let width = 140;
    let height = 140;
    let radius = 100;
    switch (room) {
      case ROOM.video: {
        if (i === 1) {
          top = 320;
          left = 200;
          width = 320;
          height = 60;
          radius = 0;
        }
        if (i === 2) {
          top = 140;
          left = 500;
          width = 150;
          height = 150;
          radius = 100;
        }
        if (i === 3) {
          top = 52;
          left = 57;
          width = 580;
          height = 370;
          radius = 0;
        }
        if (i === 4) {
          top = 120;
          left = 158;
          width = 400;
          height = 245;
          radius = 0;
        }
        break;
      }
      case ROOM.voice: {
        if (i === 0) {
          top = 142;
          left = 192;
          width = 120;
          height = 120;
          radius = 100;
        }
        if (i === 1) {
          top = 152;
          left = 204;
          width = 100;
          height = 100;
          radius = 100;
        }
        if (i === 2) {
          top = 86;
          left = 274;
          width = 92;
          height = 164;
          radius = 0;
        }
        break;
      }
      case ROOM.normal: {
        if (i === 0) {
          top = 90;
          left = 344;
          width = 160;
          height = 160;
          radius = 100;
        }
        if (i === 1) {
          top = 200;
          left = 318;
          width = 80;
          height = 80;
          radius = 100;
        }
        if (i === 2) {
          top = 264;
          left = 308;
          width = 104;
          height = 104;
          radius = 100;
        }
        break;
      }
      default: {
        if (i === 0) {
          top = 75;
          left = 10;
          width = 140;
          height = 370;
          radius = 0;
        }
        if (i === 1 || i === 2 || i === 3) {
          top = 75;
          left = 0;
          width = 150;
          height = 130;
          radius = 0;
        }
        if (i === 4) {
          top = 70;
          left = 10;
          width = 140;
          height = 190;
          radius = 0;
        }
        break;
      }
    }

    return {
      top: `${pcPx(top)}`,
      left: `${pcPx(left)}`,
      width: `${pcPx(width)}`,
      height: `${pcPx(height)}`,
      borderRadius: `${pcPx(radius)}`,
    };
  };

  const getSpotLightSp = (room: string, i: number) => {
    let top = 70;
    let left = 183;
    let width = 95;
    let height = 428;
    let radius = 0;
    switch (room) {
      case ROOM.video: {
        if (i === 1) {
          top = 480;
          left = 5;
          width = 280;
          height = 60;
          radius = 0;
        }
        if (i === 2) {
          top = 60;
          left = 187;
          width = 100;
          height = 100;
          radius = 100;
        }
        if (i === 3) {
          top = 65;
          left = 4;
          width = 280;
          height = 488;
          radius = 0;
        }
        break;
      }
      case ROOM.voice: {
        if (i === 0) {
          top = 50;
          left = 90;
          width = 110;
          height = 110;
          radius = 100;
        }
        if (i === 1) {
          top = 230;
          left = 92;
          width = 105;
          height = 105;
          radius = 100;
        }
        if (i === 2) {
          top = 57;
          left = 100;
          width = 90;
          height = 450;
          radius = 0;
        }
        break;
      }
      case ROOM.normal: {
        if (i === 0) {
          top = 65;
          left = 10;
          width = 95;
          height = 443;
          radius = 0;
        }
        if (i === 1) {
          top = 247;
          left = 90;
          width = 110;
          height = 110;
          radius = 100;
        }
        if (i === 2) {
          top = 352;
          left = 83;
          width = 128;
          height = 128;
          radius = 100;
        }
        break;
      }
      default: {
        if (i === 0) {
          top = 38;
          left = 10;
          width = 260;
          height = 248;
          radius = 0;
        }
        if (i === 1) {
          top = 30;
          left = 5;
          width = 265;
          height = 172;
          radius = 0;
        }
        if (i === 2 || i === 3) {
          top = 37;
          left = 5;
          width = 265;
          height = 172;
          radius = 0;
        }
        if (i === 4) {
          top = 37;
          left = 8;
          width = 260;
          height = 248;
          radius = 0;
        }
        break;
      }
    }

    return {
      top: `${spp(top)}`,
      left: `${spp(left)}`,
      width: `${spp(width)}`,
      height: `${spp(height)}`,
      borderRadius: `${spp(radius)}`,
    };
  };

  useEffect(() => {
    const LOOP_LENGTH = LENGTH * 3;
    if (!swiper) return;
    const children = swiper.$wrapperEl[0].children;

    for (let i = 0; i < LOOP_LENGTH; i++) {
      children[i].style.opacity = 0;
      children[i].setAttribute('data-prev', false);
    }
    // swiper-prev 以前のスライド
    for (let i = 0; i < id - 1; i++) {
      children[i].setAttribute('data-prev', true);
    }
    children[id + 1].style.opacity = 0.8;
    children[id + 2].style.opacity = 0.6;
    children[id].style.opacity = 1;
  }, [id]);

  useEffect(() => {
    onScrollPromise(fvRef.current)().then(() => setIsFvRefScrolled(true));
    onScrollPromise(imgRef.current)().then(() => setIsImgRefScrolled(true));
    return () => {
      removeOnscrollEvent(fvRef.current);
      removeOnscrollEvent(imgRef.current);
    };
  }, []);

  return (
    <Wrapper>
      <PcWrapper>
        <TextWrapper data-visible={isFvRefScrolled}>
          <TitleWrapper>
            <Title
              ref={fvRef}
              data-visible={isFvRefScrolled}
              data-lang-ru={languageStoreValue === LANGUAGE.ru}
            >
              {items.title}
            </Title>
            <SubTitle
              data-visible={isFvRefScrolled}
              data-lang-ru={languageStoreValue === LANGUAGE.ru}
            >
              {items.subtitle}
            </SubTitle>
            <Icon
              style={{ backgroundImage: `url(${items.icon})` }}
              data-visible={isFvRefScrolled}
            />
          </TitleWrapper>
          <Text
            data-visible={isFvRefScrolled}
            data-lang-ru={languageStoreValue === LANGUAGE.ru}
          >
            {items.text}
          </Text>
          {items.name === ROOM.video && (
            <Alert data-visible={isFvRefScrolled}>{t('video.chrome')}</Alert>
          )}
          <ul>
            {items.slide.map((title, i) => (
              <li key={`slide_${i}`}>
                <SlideButtonPC
                  data-active={i === realId}
                  onClick={() => onClickButton(swiper, i)}
                  data-visible={isFvRefScrolled}
                  style={{ transitionDelay: `${0.1 * i + 0.4}s` }}
                />
                <SlideTitlePC
                  key={i}
                  data-active={i === realId}
                  data-visible={isFvRefScrolled}
                  data-lang-ru={languageStoreValue === LANGUAGE.ru}
                  style={{ transitionDelay: `${0.1 * i + 0.4}s` }}
                >
                  {title}
                </SlideTitlePC>
              </li>
            ))}
          </ul>
        </TextWrapper>
        <SwiperWrapper
          ref={imgRef}
          data-visible={isImgRefScrolled}
          data-type={items.name}
        >
          <Swiper
            spaceBetween={isSpStoreValue ? windowWidth * -0.1 : -200}
            slidesPerView='auto'
            centeredSlides
            speed={800}
            loop
            effect='coverflow'
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 500,
              modifier: isSpStoreValue ? 0.3 : 1.2,
              slideShadows: false,
            }}
            allowTouchMove={false}
            pagination={{ clickable: true }}
            onSwiper={setSwiper}
            onLoopFix={(swiper) => {
              setId(swiper.activeIndex);
            }}
            onSlideChange={(swiper) => {
              setId(swiper.activeIndex);
              setRealId(swiper.realIndex);
            }}
            onTouchStart={(swiper) => onTouchStart(swiper)}
            onTouchEnd={(swiper) => onTouchEnd(swiper)}
          >
            {isSpStoreValue
              ? items.images.map((img, i) => (
                  <SwiperSlide key={i}>
                    <Img style={{ backgroundImage: `url(${img})` }}>
                      <Black>
                        <SpotLight
                          style={getSpotLightSp(items.name, i)}
                          data-active={i === realId}
                        />
                      </Black>
                    </Img>
                  </SwiperSlide>
                ))
              : items.imagesPc.map((img, i) => (
                  <SwiperSlide key={i}>
                    <Img style={{ backgroundImage: `url(${img})` }}>
                      <Black>
                        <SpotLight
                          style={getSpotLightPc(items.name, i)}
                          data-active={i === realId}
                        />
                        {items.name === ROOM.voice && i === 0 && (
                          <SpotLightVoice data-active={i === realId} />
                        )}
                      </Black>
                    </Img>
                  </SwiperSlide>
                ))}
          </Swiper>
        </SwiperWrapper>
        <SlideTitle
          data-visible={isImgRefScrolled}
          data-lang-ru={languageStoreValue === LANGUAGE.ru}
        >
          {items.slide[realId]}
        </SlideTitle>
      </PcWrapper>
    </Wrapper>
  );
};

export default RoomContent;
