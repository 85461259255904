import React, { useState } from "react";
import { useGlobal } from "reactn";
import styled from "styled-components";
import "react-image-lightbox/style.css";
import { sp } from "../../utils/mediaQuery";

const ImageParts = ({ imageDataSet }) => {
  const [teamList, setTeamList] = useGlobal("teamList");
  const [photoIndex, setIndex] = useGlobal("photoIndex");
  const [isOpen, setisOpen] = useState(false);
  const [isOpenLightBox, setisOpenLightBox] = useGlobal("isOpenLightBox");
  const [imageArray, setImageArray] = useGlobal("imageArray");

  const changeImageArray = (index) => {
    setIndex(index)
    setImageArray(imageDataSet)
  }

  const ImageParts = styled.div`
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    cursor: pointer;
  `;

  const Image = styled.div`
  // overflow: hidden;
  // width: 152px;/* トリミングしたい枠の幅 */
  // height: 142px;/* トリミングしたい枠の高さ */
  // position: relative;
    width:100%;
    height: 140px;
    position: relative;
    ${sp`
      height: 80px;
    `}
  `;

  return (
    <>
      <ImageParts imageLength={imageDataSet.length} >
        {imageDataSet.map((img, i) => (
          <div key={i}>
            {i < 3 &&
              <Image key={i}>
                {i < 3 &&
                  <img
                    className="diary-image"
                    src={img || RailsAssetPath('noimage_icon.png')}
                    alt="report"
                    onClick={() => {
                      setisOpenLightBox(true), changeImageArray(i);
                    }}
                  />
                }
                {imageDataSet.length > 3 && i == 2 &&
                  <div className="wrapper-more-five-images" onClick={() => { setisOpenLightBox(true), changeImageArray(2); }}>
                    <p>+{imageDataSet.length - 3}</p>
                  </div>
                }
              </Image>
            }
          </div>
        ))}
      </ImageParts>
    </>
  );
};

export default ImageParts;
