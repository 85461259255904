import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PresentModalAvatar } from 'javascript/features/mybox/components/PresentModalAvatar';
import { usePresentProduct } from 'javascript/features/mybox/hooks/usePresentProduct';
import { useSelectAvatar } from 'javascript/features/mybox/hooks/useSelectAvatar';
import { device } from 'javascript/utils/mediaQuery';
import {
  AvatarPaidProductType,
  AvatarToPresent,
  OwnedProductInMybox,
  OwnedSkillProductInMybox,
} from 'types';
import { useIsTypeProductItem } from 'javascript/features/mybox/util/useIsTypeProductItem';

const StyledPresentModalTitle = styled.p`
  margin-top: 32px;
  font-weight: bold;
  text-align: center;
`;

const StyledPresentModalImageWrapper = styled.div<{ isClothes: boolean }>`
  margin-top: 16px;
  img {
    max-width: 104px;
    max-height: 104px;
  }
`;

const StyledPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  img {
    width: 20px;
  }
  p {
    margin-left: 4px;
    font-size: 24px;
  }
`;

const StyledSelectAvatar = styled.div`
  position: relative;
  margin-top: 8px;
  select {
    width: 176px;
    padding: 8px 20px;
    border: 2px solid #f4f4f4;
    border-radius: 8px;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    appearance: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  @media ${device.sp} {
    select {
      width: 152px;
    }
  }
`;

const StyledTriangle = styled.div`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  width: 10px;
  height: 8px;
  background-color: #433c35;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
`;

const StyledAvatarImage = styled.div`
  position: absolute;
  top: -1px;
  left: -48px;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

const StyledPresentButton = styled.button`
  width: 152px;
  margin-top: 24px;
  padding: 8px 16px;
  color: #03a9f4;
  border: 2px solid #03a9f4;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translate(-1px, -1px);
    box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 5%), 4px 4px 4px 0px rgb(0 0 0 / 5%);
  }
  @media ${device.sp} {
    margin-top: 32px;
  }
`;

interface Props {
  product: OwnedProductInMybox | OwnedSkillProductInMybox;
  selectedShopProductType: AvatarPaidProductType;
  toggleModal: () => void;
  avatars: AvatarToPresent[] | undefined;
  currentAvatarId: number | undefined;
}

export const PresentModalBody: React.FC<Props> = ({
  avatars,
  product,
  selectedShopProductType,
  currentAvatarId,
  toggleModal,
}) => {
  const { t } = useTranslation();
  const { targetAvatar, handleSelectAvatar } = useSelectAvatar(avatars);
  const { handlePresentProduct } = usePresentProduct({
    product,
    targetAvatarId: targetAvatar?.avatar_id,
    currentAvatarId,
    toggleModal,
  });

  const { isTypeProductItem } = useIsTypeProductItem();

  return (
    <>
      <StyledPresentModalTitle>
        {t('MyboxPresentmodalThisitem', {})}
        <br />
        {t('MyboxPresentmodalAskifpresent', {})}
      </StyledPresentModalTitle>
      <StyledPresentModalImageWrapper
        isClothes={selectedShopProductType === 'clothes'}
      >
        <img
          src={
            isTypeProductItem(product)
              ? selectedShopProductType !== 'hair'
                ? // @ts-ignore
                  RailsAssetPath(
                    `avatar_parts/${selectedShopProductType}/${product.product_number}.png`
                  )
                : // @ts-ignore
                  RailsAssetPath(
                    `avatar_parts/${selectedShopProductType}/black/${product.product_number}.png`
                  )
              : product.image_url !== ''
              ? product.image_url
              : // @ts-ignore
                RailsAssetPath(`avatar_shop/skill_default.png`)
          }
        />
      </StyledPresentModalImageWrapper>
      <StyledPriceWrapper>
        {/* @ts-ignore */}
        <img src={RailsAssetPath('mypage/coin.png')} />
        <p>{product.viket.toLocaleString()}</p>
      </StyledPriceWrapper>
      {targetAvatar && <PresentModalAvatar targetAvatar={targetAvatar} />}
      {avatars && (
        <StyledSelectAvatar>
          <select name='avatar' onChange={handleSelectAvatar}>
            <option hidden>{t('MyboxPresentmodalSelectavatar', {})}</option>
            {avatars.map((avatar) => (
              <option value={avatar.avatar_id} key={avatar.avatar_id}>
                {avatar.name}
              </option>
            ))}
          </select>
          <StyledTriangle />
          {targetAvatar && (
            <StyledAvatarImage>
              <img
                src={
                  // @ts-ignore
                  targetAvatar.user_image || RailsAssetPath('noimage_icon.png')
                }
              />
            </StyledAvatarImage>
          )}
        </StyledSelectAvatar>
      )}
      <StyledPresentButton onClick={handlePresentProduct}>
        {t('MyboxDefaultmodalPresent', {})}
      </StyledPresentButton>
    </>
  );
};
