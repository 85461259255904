/**
無料のアバターパーツアイテム（デフォルトパーツ）の個数を管理する定数
 */
export const DefaultClothItemsCount = 10;

export const DefaultHatItemsCount = 2;

export const DefaultEyeItemsCount = 32;

export const DefaultMouthItemsCount = 10;

export const DefaultHairCount = 20;
