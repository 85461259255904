import React from 'react';
import { Trans } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { device } from 'javascript/utils/mediaQuery';

const HelpMenuWrapper = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 1;
  position: absolute;
  width: 100%;
  top: 0;
  height: 100vh;
  right: 0;
`;

const HelpMenuBox = styled.div`
  position: absolute;
  min-width: 140px;
  padding: 8px 0;
  color: #303030;
  font-size: 10px;
  text-align: left;
  border-radius: 5px;
  backdrop-filter: blur(12px);
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 3px 0px grey;
  top: 70px;
  right: 270px;
  z-index: 1;
  @media ${device.sp} {
    width: auto;
    right: 5px;
    top: 80px;
  }
`;

const HelpMenuItemLink = styled.a`
  padding: 8px 16px 8px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`;

const HelpMenuItemIcon = styled.i<{ icon: string }>`
  display: inline-block;
  padding: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
  vertical-align: middle;
  background-image: ${({ icon }) => `url(${icon})`};
`;

interface Props {
  isOpen: boolean;
  isMaster: boolean;
  toggleHelpMenu: () => void;
}

export const HelpMenu: React.FC<Props> = ({
  isOpen,
  isMaster,
  toggleHelpMenu,
}) => {
  const isMobile =
    useMediaQuery({ maxWidth: 1024 }) ||
    (/android|ipod|ipad|iphone|macintosh/.test(
      navigator.userAgent.toLowerCase()
    ) &&
      'ontouchend' in document);

  return (
    <HelpMenuWrapper
      isOpen={isOpen}
      onClick={() => {
        toggleHelpMenu();
      }}
    >
      <HelpMenuBox>
        <HelpMenuItemLink href='/' target='_blank'>
          {/* @ts-ignore */}
          <HelpMenuItemIcon icon={RailsAssetPath('lp.png')} />
          <Trans i18nKey='MenuHelpProduct' />
        </HelpMenuItemLink>
        <HelpMenuItemLink href='https://bit.ly/3hwF1D9' target='_blank'>
          {/* @ts-ignore */}
          <HelpMenuItemIcon icon={RailsAssetPath('support.png')} />
          <Trans i18nKey='MenuHelpFeedback' />
        </HelpMenuItemLink>
        <HelpMenuItemLink href='https://bit.ly/3GKLBSw' target='_blank'>
          {/* @ts-ignore */}
          <HelpMenuItemIcon icon={RailsAssetPath('pdf_download.png')} />
          <Trans i18nKey='MenuHelpPdfdownload' />
        </HelpMenuItemLink>
        <HelpMenuItemLink href='https://bit.ly/3hVRFwY' target='_blank'>
          {/* @ts-ignore */}
          <HelpMenuItemIcon icon={RailsAssetPath('manual.png')} />
          <Trans i18nKey='MenuHelpManual' />
        </HelpMenuItemLink>
        {isMaster && !isMobile && (
          <HelpMenuItemLink href='/payment'>
            {/* @ts-ignore */}
            <HelpMenuItemIcon icon={RailsAssetPath('payment.png')} />
            <Trans i18nKey='MenuHelpPayment' />
          </HelpMenuItemLink>
        )}
        <HelpMenuItemLink href='/privacy' target='_blank'>
          {/* @ts-ignore */}
          <HelpMenuItemIcon icon={RailsAssetPath('privacy.png')} />
          <Trans i18nKey='MenuHelpPolicy' />
        </HelpMenuItemLink>
        <HelpMenuItemLink href='https://pla-cole.co/' target='_blank'>
          {/* @ts-ignore */}
          <HelpMenuItemIcon icon={RailsAssetPath('company.png')} />
          <Trans i18nKey='MenuHelpCompany' />
        </HelpMenuItemLink>
      </HelpMenuBox>
    </HelpMenuWrapper>
  );
};
