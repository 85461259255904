import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { device } from 'javascript/utils/mediaQuery';
import { WeeklyTeamResult } from 'types';

const StyledResultInfo = styled.div`
  width: 50%;
  &:first-child {
    padding-right: 16px;
    border-right: 1px solid #dbdbdb;
  }
  &:last-child {
    padding-left: 16px;
  }
`;

const StyledResultInfoHeader = styled.div`
  display: flex;
  align-items: center;
  @media ${device.tab} {
    flex-direction: column;
    row-gap: 8px;
    align-items: flex-start;
  }
`;

const StyledTeamTitle = styled.div<{ isWinner: boolean }>`
  display: flex;
  align-items: center;
  padding: 4px 16px;
  border-radius: 8px;
  background-color: ${({ isWinner }) => (isWinner ? '#eff5ff' : '#ffefef')};
  img {
    width: 16px;
  }
  p {
    margin-left: 4px;
    font-size: 12px;
  }
`;

const StyledVikeCount = styled.div<{ isWinner: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 24px;
  color: ${({ isWinner }) => !isWinner && '#f00'};
  img {
    width: 16px;
  }
  p {
    margin-left: 4px;
    font-size: 12px;
  }
  @media ${device.tab} {
    margin-left: 16px;
  }
`;

const StyledTeamName = styled.p`
  width: 100%;
  margin: 16px 0;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledUserList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  height: calc(100% - 76px);
  overflow: scroll;
  li {
    min-height: 15px;
    width: 100%;
    font-size: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  @media ${device.tab} {
    height: calc(100% - 102px);
  }
`;

type Props = {
  teamInfo: WeeklyTeamResult | undefined;
  isWinner?: boolean;
};

export const WeeklyScrumResultTeamInfo: React.FC<Props> = ({
  teamInfo,
  isWinner = false,
}) => {
  const { t } = useTranslation();

  return (
    <StyledResultInfo>
      <StyledResultInfoHeader>
        <StyledTeamTitle isWinner={isWinner}>
          <img
            src={
              isWinner
                ? // @ts-ignore
                  RailsAssetPath('mypage/coin.png')
                : // @ts-ignore
                  RailsAssetPath('freeSpace/status/item/24.png')
            }
          />
          <p>
            {isWinner
              ? t('EmployeeScrumRewardedteam')
              : t('EmployeeScrumLossteam')}
          </p>
        </StyledTeamTitle>
        <StyledVikeCount isWinner={isWinner}>
          {/* @ts-ignore */}
          <img src={RailsAssetPath('mypage/coin.png')} />
          <p>
            {isWinner ? `+${teamInfo?.vike ?? ''}` : `-${teamInfo?.vike ?? ''}`}
          </p>
        </StyledVikeCount>
      </StyledResultInfoHeader>
      <StyledTeamName>{teamInfo?.name}</StyledTeamName>
      <StyledUserList>
        {teamInfo?.user?.map((user) => (
          <li key={user.id}>{user.name}</li>
        ))}
      </StyledUserList>
    </StyledResultInfo>
  );
};
