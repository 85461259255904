import React, { createContext, useContext, useReducer, useState } from 'react';
import useActiveSinkId from './hooks/useActiveSinkId';

export const StateContext = createContext(null);


export default function AppStateProvider({ children }) {
  const [error, setError] = useState(null);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();

  let contextValue = {
    error,
    setError,
    activeSinkId,
    setActiveSinkId,
  }

  return (
    <StateContext.Provider value={{ ...contextValue }}>
      {children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}