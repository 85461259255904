import React from 'react';
import styled from 'styled-components';
import { useOpenModal } from 'javascript/features/avatarShop/hooks/useOpenModal';
import { MyboxProductModal } from 'javascript/features/mybox/components/MyboxProductModal';
import { AvatarPaidProductType, OwnedSkillProductInMybox } from 'types';
import MyBoxSkillActionBtns from './MyBoxSkillActionBtns';
import { useChangeSkillStatusToComplete } from '../hooks/useChangeSkillStatusToComplete';
import { AvatarOwnedSkillProductSort } from '../hooks/useMybox';

const StyledMyboxSkillProduct = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 187px;
`;

const StyledMyboxProductSkillImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  height: 100%;
  max-height: 120px;
  padding: 8px 0;
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  img.thumbnail {
    max-width: 64px;
    max-height: 64px;
    clip-path: inset(10px 0px 12% 0px);
    height: 82px;
    max-height: none;
    object-fit: cover;
  }
`;

const StyledPresentIcon = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  img {
    width: 16px;
  }
`;

const StyledUserIcon = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

const StyledMyboxSkilltName = styled.div`
  overflow-wrap: anywhere;
  min-height: 24px;
  font-size: 11px;
  height: 150px;
  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
`;

const StyledMyboxSkillExpiredAt = styled.div<{ expiredSoon: boolean; }>`
  overflow-wrap: anywhere;
  min-height: 24px;
  font-size: 11px;
  margin-top: 4px;
  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    ${({ expiredSoon }) => (expiredSoon ? 'color: #EC6A40;' : '')};
  }
`;

interface Props {
  product: OwnedSkillProductInMybox;
  selectedShopProductType: AvatarPaidProductType;
  currentAvatarId: number | undefined;
  avatarOwnedSkillProductSort: AvatarOwnedSkillProductSort,
}

export const MyboxSkillProduct: React.FC<Props> = ({
  product,
  selectedShopProductType,
  currentAvatarId,
  avatarOwnedSkillProductSort
}) => {
  const { isOpen, toggleModal } = useOpenModal();

  const { changeSKillStatusToComplete } = useChangeSkillStatusToComplete(
    product.avatar_owned_product_id,
    avatarOwnedSkillProductSort
  );

  function expired(expiredAt: string) {
    return new Date(expiredAt) < new Date(Date.now());
  }

  return (
    <StyledMyboxSkillProduct>
      {/* TODO */}
      <StyledMyboxProductSkillImageWrapper onClick={() => expired(product.expired_at) ? alert("利用期限切れのため利用申請できません") : toggleModal()}>
        <img
          className={'thumbnail'}
          src={
            product.image_url !== ''
              ? product.image_url
              : // @ts-ignore
              RailsAssetPath(`avatar_shop/skill_default.png`)
          }
        />
        {product.present_flg && (
          <>
            <StyledPresentIcon>
              {/* @ts-ignore */}
              <img src={RailsAssetPath('mybox/present_icon.png')} />
            </StyledPresentIcon>
            <StyledUserIcon>
              <img
                src={
                  product.presented_user_image ||
                  // @ts-ignore
                  RailsAssetPath('noimage_icon.png')
                }
              />
            </StyledUserIcon>
          </>
        )}
      </StyledMyboxProductSkillImageWrapper>
      {isOpen && (
        <MyboxProductModal
          product={product}
          selectedShopProductType={selectedShopProductType}
          toggleModal={toggleModal}
          currentAvatarId={currentAvatarId}
          avatarOwnedSkillProductSort={avatarOwnedSkillProductSort}
        />
      )}

      <StyledMyboxSkilltName>
        <p>{product.product_name}</p>
      </StyledMyboxSkilltName>

      {
        product.usage_status !== 1 && (
          <MyBoxSkillActionBtns used={product.used} handled={product.handled} usedHandler={changeSKillStatusToComplete} />
        )
      }

      {
        expired(product.expired_at) && (
          <StyledMyboxSkillExpiredAt expiredSoon={true}>
            <p>
              {/* TODO */}
              利用期限切れです
            </p>
          </StyledMyboxSkillExpiredAt>
        )
      }

      {
        new Date(product.expired_at) >= new Date(Date.now()) && (
          <StyledMyboxSkillExpiredAt expiredSoon={(new Date(product.expired_at) < new Date(Date.now() + 3 * 24 * 60 * 60 * 1000))}>
            <p>
              {/* TODO */}
              {"期限: "}
              {product.expired_at}
            </p>
          </StyledMyboxSkillExpiredAt>
        )
      }

    </StyledMyboxSkillProduct>
  );
};
