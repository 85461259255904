import { FixedWidthAndPosition, FixedHeightAndPosition } from 'types';

/*
オフィスページの
フリースペースエリアに表示されるアバターパーツの画像の大きさと位置を管理する定数
*/
// 目
export const eyeAppearanceInFreeSpace: FixedWidthAndPosition[] = [
  { width: 11, top: 13, left: 13 },
  { width: 11, top: 13, left: 13 },
  { width: 11, top: 13, left: 13 },
  { width: 11, top: 13, left: 13 },
  { width: 11, top: 13, left: 13 },
  { width: 12, top: 13, left: 12 },
  { width: 10, top: 13, left: 13 },
  { width: 12, top: 13, left: 12 },
  { width: 11, top: 13, left: 13 },
  { width: 12, top: 13, left: 12 },

  { width: 11, top: 14, left: 13 },
  { width: 11, top: 14, left: 13 },
  { width: 11, top: 13, left: 13 },
  { width: 11, top: 13, left: 13 },
  { width: 11, top: 13, left: 13 },
  { width: 11, top: 13, left: 13 },
  { width: 10, top: 13, left: 13 },
  { width: 10, top: 13, left: 13 },
  { width: 10, top: 13, left: 13 },
  { width: 9,  top: 13, left: 15 },

  { width: 11, top: 13, left: 13 },
  { width: 11, top: 13, left: 13 },
  { width: 9, top: 13, left: 13 },
  { width: 10, top: 13, left: 13 },
  { width: 11, top: 14, left: 12 },
  { width: 11, top: 13, left: 12 },
  { width: 11, top: 13, left: 12 },
  { width: 11, top: 13, left: 12 },
  { width: 10, top: 14, left: 13 },
  { width: 10, top: 14, left: 13 },

  { width: 10, top: 13, left: 13 },
  { width: 10, top: 14, left: 13 },
  // 以下有料プロダクト
  { width: 10, top: 13, left: 13 },
  { width: 18, top: 11, left: 11 },
  { width: 18, top: 12, left: 11 },
  { width: 9, top: 14, left: 13 },
  { width: 10, top: 14, left: 13 },
  { width: 7, top: 14, left: 14 },
  { width: 12, top: 13, left: 12 },
  { width: 14, top: 11, left: 11 },

  { width: 16, top: 11, left: 12 },
  { width: 16, top: 12, left: 12 },
  { width: 11, top: 13, left: 13 },
  { width: 11, top: 13, left: 13 },
  { width: 11, top: 14, left: 13 },
  { width: 10, top: 14, left: 13 },
  { width: 9, top: 12, left: 13 },
  { width: 10, top: 13, left: 13 },
  { width: 10, top: 14, left: 13 },
  { width: 10, top: 14, left: 13 },

  { width: 8, top: 13, left: 13 },
  { width: 10, top: 14, left: 13 },
  { width: 10, top: 14, left: 13 },
  { width: 10, top: 14, left: 13 },
  { width: 16, top: 14, left: 12 },
  { width: 10, top: 11, left: 12 },
  { width: 8, top: 11, left: 13 },
  { width: 8, top: 14, left: 13 },
  { width: 9, top: 13, left: 13 },
  { width: 10, top: 15, left: 13 },

  { width: 11, top: 14, left: 12 },
  { width: 11, top: 14, left: 12 },
  { width: 12, top: 14, left: 12 },
  { width: 11, top: 13, left: 13 },
  { width: 12, top: 13, left: 11 },
  { width: 11, top: 14, left: 13 },
  { width: 7, top: 14, left: 14 },
  { width: 10, top: 14, left: 13 },
  { width: 10, top: 13, left: 13 },
  { width: 11, top: 14, left: 13 },

  { width: 10, top: 15, left: 13 },
  { width: 11, top: 13, left: 12 },
  { width: 11, top: 14, left: 13 },
  { width: 10, top: 14, left: 13 },
  { width: 11, top: 13, left: 13 },
  { width: 10, top: 13, left: 13 },
  { width: 10, top: 14, left: 13 },
  { width: 10, top: 14, left: 13 },
  { width: 10, top: 14, left: 13 },
  { width: 9, top: 14, left: 13 },

  { width: 9, top: 13, left: 13 },
  { width: 9, top: 14, left: 13 },
  { width: 9, top: 14, left: 13 },
  { width: 9, top: 12, left: 14 },
  { width: 8, top: 12, left: 14 },
  { width: 12, top: 12, left: 13 },
  { width: 10, top: 12, left: 14 },
  { width: 10, top: 12, left: 14 },
  { width: 9, top: 13, left: 14 },
  { width: 11, top: 12, left: 13 },

  { width: 11, top: 12, left: 13 },
  { width: 11, top: 12, left: 13 },
  { width: 11, top: 12, left: 13 },
  { width: 11, top: 11, left: 13 },
  { width: 13, top: 11, left: 12 },

  { width: 11, top: 12, left: 13 },
  { width: 11, top: 12, left: 13 },
  { width: 11, top: 12, left: 13 },
  { width: 10, top: 12, left: 14 },
  { width: 13, top: 11, left: 12 },

  { width: 11, top: 13, left: 13 }, // 101
  { width: 11, top: 13, left: 13 },
  { width: 9, top: 12, left: 14 },
  { width: 17, top: 11, left: 12 },
  { width: 12, top: 12, left: 13 },

  { width: 20, top: 12, left: 11 }, // 目 106
  { width: 13, top: 13, left: 12 },
  { width: 13, top: 13, left: 12 },
  { width: 14, top: 11, left: 12 },
  { width: 14, top: 13, left: 12 },

  { width: 14, top: 11, left: 13 }, // 目 111
  { width: 14, top: 10, left: 13 },
  { width: 14, top: 12, left: 13 },
  { width: 14, top: 12, left: 13 },
  { width: 16, top: 12, left: 13 },

  { width: 14, top: 11, left: 13 }, // 目 116
  { width: 18, top: 5, left: 13 },
  { width: 13, top: 11, left: 13 },
  { width: 13, top: 11, left: 13 },
  { width: 13, top: 11, left: 13 },

  { width: 13, top: 11, left: 13 }, // 目 121
  { width: 13, top: 11, left: 13 },
  { width: 13, top: 11, left: 13 },
  { width: 13, top: 11, left: 13 },
  { width: 13, top: 14, left: 13 },

  { width: 13, top: 11, left: 13 }, // 目 126
  { width: 13, top: 13, left: 13 },
  { width: 13, top: 12, left: 13 },
  { width: 15, top: 12, left: 13 },
  { width: 13, top: 12, left: 13 },

  { width: 19, top: 7.5, left: 10 }, // 目 131
  { width: 12, top: 12.5, left: 13 },
  { width: 12, top: 13, left: 13 },
  { width: 11, top: 14, left: 14 },
  { width: 13, top: 10, left: 13 },

  { width: 15, top: 10.5, left: 11.5 }, // 目 136
  { width: 14, top: 12.5, left: 12.5 },
  { width: 16, top: 12.5, left: 11 },
  { width: 19, top: 12, left: 10.5 },
  { width: 18, top: 12, left: 11 },

  { width: 14, top: 11, left: 11.5 }, // 目 141
  { width: 14, top: 13, left: 12.5 },
  { width: 18, top: 13, left: 11 },
  { width: 14, top: 13.5, left: 12.5 },
  { width: 14, top: 12, left: 12.5 },

  { width: 13, top: 13.5, left: 13 }, // 目 146
  { width: 12, top: 14, left: 12.5 },
  { width: 12, top: 14, left: 13 },
  { width: 18, top: 13, left: 10.5 },
  { width: 12, top: 14.5, left: 13 },

  { width: 18, top: 13, left: 11 }, // 目 151
  { width: 14, top: 14, left: 12.5 },
  { width: 18, top: 11, left: 12 },
  { width: 13, top: 13, left: 13 },
  { width: 16, top: 12.5, left: 12 },

  { width: 20, top: 11, left: 12 }, // 目 156
  { width: 13.5, top: 14, left: 12.5 },
  { width: 14.5, top: 12.3, left: 13 },
  { width: 15, top: 13.5, left: 12.5 },
  { width: 15.5, top: 13.5, left: 12 },

  { width: 14, top: 13, left: 12.5 }, // 目 161
  { width: 13, top: 12, left: 13.5 },
  { width: 13, top: 12.3, left: 13.5 },
  { width: 14, top: 9.5, left: 12.5 },
  { width: 17.5, top: 12.5, left: 11.5 },

  { width: 15, top: 13.5, left: 12.5 }, // 目 166
  { width: 14, top: 14, left: 11 },
  { width: 13, top: 14, left: 12 },
  { width: 18.5, top: 12.5, left: 10.5 },
  { width: 16.2, top: 14, left: 12 },

  { width: 14, top: 12.5, left: 12.5 }, // 目 171
  { width: 15, top: 14, left: 12 },
  { width: 16.5, top: 14, left: 11 },
  { width: 16.5, top: 13, left: 11 },
  { width: 16.2, top: 13, left: 11 },

  { width: 15, top: 13, left: 12.5 }, // 目 176
  { width: 19, top: 13, left: 11 },
  { width: 15.5, top: 11, left: 11 },
  { width: 15.5, top: 13, left: 12 },
  { width: 16, top: 10.5, left: 11.5},

  { width: 15, top: 11, left: 12.5 }, // 目 181
  { width: 13.5, top: 14, left: 12.5 },
  { width: 13.5, top: 14.5, left: 12.5 },
  { width: 16.5, top: 12.5, left: 11 },
  { width: 14, top: 13.5, left: 12.5},

  { width: 16.5, top: 13, left: 11.5 }, // 目 186
  { width: 19, top: 12, left: 11 },
  { width: 15, top: 14.5, left: 12.5 },
  { width: 15.5, top: 12.5, left: 12 },
  { width: 14, top: 10.5, left: 12.5},

  { width: 9.5, top: 14, left: 14.5 }, // 目 191
  { width: 19, top: 13, left: 12 },
  { width: 14, top: 13.5, left: 12.5 },
  { width: 14.5, top: 10.5, left: 12.5 },
  { width: 16, top: 12, left: 11.5},
];
// 口
export const mouthAppearanceInFreeSpace: FixedWidthAndPosition[] = [
  { width: 5, top: 19, left: 15 },
  { width: 4, top: 19, left: 16 },
  { width: 4, top: 18, left: 15 },
  { width: 5, top: 18, left: 15 },
  { width: 5, top: 18, left: 15 },
  { width: 2, top: 18, left: 16 },
  { width: 4, top: 18, left: 16 },
  { width: 3, top: 19, left: 16 },
  { width: 2, top: 18, left: 16 },
  { width: 5, top: 19, left: 15 },

  // 以下有料プロダクト
  { width: 5, top: 18, left: 15 },
  { width: 6, top: 16, left: 15 },
  { width: 17, top: 15, left: 11 },
  { width: 3, top: 18, left: 16 },
  { width: 10, top: 19, left: 10 },
  { width: 16, top: 15, left: 12 },
  { width: 16, top: 15, left: 12 },
  { width: 5, top: 19, left: 15 },
  { width: 8, top: 16, left: 14 },
  { width: 18, top: 14, left: 12 },

  { width: 10, top: 15, left: 13 },
  { width: 7, top: 18, left: 14 },
  { width: 7, top: 18, left: 15 },
  { width: 5, top: 18, left: 15 },
  { width: 5, top: 18, left: 15 },
  { width: 5, top: 18, left: 15 },
  { width: 4, top: 18, left: 15 },
  { width: 3, top: 18, left: 16 },
  { width: 3, top: 18, left: 16 },
  { width: 5, top: 18, left: 15 },

  { width: 5, top: 18, left: 15 },
  { width: 6, top: 18, left: 15 },
  { width: 6, top: 18, left: 15 },
  { width: 7, top: 18, left: 15 },
  { width: 6, top: 18, left: 15 },
  { width: 6, top: 18, left: 15 },
  { width: 2, top: 18, left: 16 },
  { width: 5, top: 17, left: 15 },
  { width: 4, top: 18, left: 15 },
  { width: 5, top: 18, left: 15 },

  { width: 6, top: 18, left: 15 },
  { width: 4, top: 17, left: 16 },
  { width: 5, top: 18, left: 15 },
  { width: 7, top: 19, left: 15 },
  { width: 4, top: 18, left: 16 },
  { width: 6, top: 18, left: 15 },
  { width: 6, top: 18, left: 15 },
  { width: 6, top: 17, left: 15 },
  { width: 6, top: 19, left: 15 },
  { width: 9, top: 17, left: 15 },

  { width: 8, top: 17, left: 12 },
  { width: 6, top: 19, left: 15 },
  { width: 3, top: 19, left: 16 },
  { width: 3, top: 19, left: 16 },
  { width: 4, top: 16, left: 16 },
  { width: 18, top: 15, left: 11 },
  { width: 6, top: 17, left: 13 },
  { width: 4, top: 19, left: 16 },
  { width: 5, top: 19, left: 15 },
  { width: 6, top: 18, left: 15 },
  { width: 5, top: 19, left: 15 },
  { width: 3, top: 18, left: 16 },
  { width: 5, top: 19, left: 15 },
  { width: 5, top: 19, left: 15 },
  { width: 7, top: 14, left: 17 },
  { width: 6, top: 18, left: 16 },
  { width: 5, top: 17, left: 15 },
  { width: 5, top: 19, left: 16 },
  { width: 5, top: 16, left: 16 },

  { width: 5, top: 17, left: 16 },
  { width: 5, top: 17, left: 16 },
  { width: 5, top: 17, left: 16 },
  { width: 5, top: 16, left: 16 },
  { width: 3, top: 16, left: 16 },
  
  { width: 5, top: 17, left: 16 },
  { width: 5, top: 17, left: 16 },
  { width: 5, top: 17, left: 16 },
  { width: 9, top: 16, left: 16 },
  { width: 3, top: 16, left: 16 },

  { width: 4, top: 17, left: 16 }, //80
  { width: 4, top: 18, left: 16 },
  { width: 4, top: 18, left: 16 },
  { width: 3, top: 17, left: 17 },
  { width: 5, top: 16, left: 17 },

  { width: 8, top: 17, left: 15 }, // 口 85
  { width: 8, top: 18, left: 15 },
  { width: 6, top: 16, left: 16 },
  { width: 4, top: 16, left: 16 },
  { width: 7, top: 17, left: 15 },

  { width: 16, top: 14, left: 6 }, // 口 90
  { width: 18, top: 14, left: 11 },
  { width: 18, top: 14, left: 11 },
  { width: 4, top: 17, left: 18 },
  { width: 5, top: 17, left: 18 },
  { width: 5, top: 17, left: 18 },

  { width: 7, top: 17, left: 16 }, // 口 96
  { width: 11, top: 17, left: 14 },
  { width: 6, top: 17, left: 16 },
  { width: 6, top: 17, left: 16 },
  { width: 6, top: 17, left: 16 },

  { width: 17, top: 16, left: 11 }, // 口 101
  { width: 12, top: 17, left: 9 },
  { width: 8, top: 17, left: 15 },
  { width: 8, top: 17, left: 15 },
  { width: 8, top: 17, left: 15 },

  { width: 9, top: 17, left: 15 }, // 口 106
  { width: 10, top: 16, left: 15 },
  { width: 8, top: 18, left: 15 },
  { width: 7, top: 17, left: 19 },

  { width: 18, top: 18, left: 2 }, // 口 110
  { width: 12, top: 17, left: 9 },
  { width: 4, top: 18, left: 17 },
  { width: 4.5, top: 17.5, left: 16 },
  { width: 17, top: 14, left: 12 },

  { width: 4, top: 16.5, left: 16.5 }, // 口 115
  { width: 14, top: 17.5, left: 12 },
  { width: 13.5, top: 13, left: 6 },
  { width: 5.5, top: 18, left: 16 },
  { width: 3, top: 18, left: 17 },

  { width: 4, top: 18.5, left: 16.5 }, // 口 120
  { width: 5, top: 19, left: 16.5 },
  { width: 6.5, top: 19, left: 13 },
  { width: 6.5, top: 17.5, left: 16 },
  { width: 3, top: 18, left: 17 },

  { width: 2.5, top: 18.5, left: 17 }, // 口 125
  { width: 9, top: 18, left: 13.5 },
  { width: 9.5, top: 18, left: 14 },
  { width: 2.5, top: 18, left: 17.5 },
  { width: 4.5, top: 17.5, left: 16 },

  { width: 12, top: 17.5, left: 12.5 }, // 口 130
  { width: 7, top: 16, left: 14.5 },
  { width: 9.5, top: 17, left: 11 },
  { width: 3.5, top: 15.5, left: 17.5 },
  { width: 5.5, top: 14.5, left: 16.5 },

  { width: 20, top: 15, left: 9.5 }, // 口 135
  { width: 3, top: 17.7, left: 16.5 },
  { width: 5, top: 17.5, left: 16 },
  { width: 3.5, top: 17.5, left: 17.5 },
  { width: 3.5, top: 17, left: 18 },

  { width: 7, top: 18, left: 15.5 }, // 口 140
  { width: 3.5, top: 17.7, left: 17.5 },
  { width: 16, top: 14.5, left: 12 },
  { width: 13.5, top: 15.5, left: 16.5 },
  { width: 17.5, top: 14, left: 11 },

  { width: 9, top: 16, left: 15.5 }, // 口 145
  { width: 10.5, top: 18.7, left: 12.5 },
  { width: 5, top: 14.5, left: 15 },
  { width: 18.5, top: 12.7, left: 2.5 },
  { width: 12, top: 16.7, left: 13 },

  { width: 7, top: 18, left: 15.5 }, // 口 150
  { width: 11.5, top: 19, left: 14.5 },
  { width: 12, top: 18.5, left: 13 },
  { width: 8.5, top: 16.7, left: 16.5 },
  { width: 4, top: 16.5, left: 17 },

  { width: 7.5, top: 18, left: 14.5 }, // 口 155
  { width: 8.5, top: 18.5, left: 13.5 },
  { width: 18.5, top: 13.5, left: 11.5 },
  { width: 10.5, top: 16, left: 10.5 },
  { width: 13, top: 17, left: 13 },

  { width: 11.5, top: 17, left: 13.5 }, // 口 160
  { width: 8, top: 18.5, left: 13.5 },
  { width: 8.5, top: 16.5, left: 14.5 },
  { width: 2.5, top: 18.5, left: 17.5 },
  { width: 2, top: 17, left: 18 },

  { width: 3.5, top: 18.5, left: 17.5 }, // 口 165
  { width: 18, top: 13.5, left: 11.5 },
  { width: 6.5, top: 19, left: 15.5 },
  { width: 12.5, top: 18, left: 13.5 },
  { width: 2, top: 18.5, left: 18 },

  { width: 17.5, top: 16.5, left: 10.5 }, // 口 170
  { width: 11, top: 18, left: 12.5 },
  { width: 9.5, top: 18, left: 14.5 },
  { width: 7, top: 19, left: 16 },
  { width: 6.5, top: 14, left: 16 },
];

// 服
export const clothAppearanceInFreeSpace: FixedHeightAndPosition[] = [
  { height: 25, top: 22, left: 12 },
  { height: 25, top: 22, left: 12 },
  { height: 25, top: 22, left: 12 },
  { height: 25, top: 22, left: 10 },
  { height: 25, top: 22, left: 10 },
  { height: 25, top: 22, left: 10 },
  { height: 25, top: 22, left: 10 },
  { height: 25, top: 22, left: 12 },
  { height: 25, top: 22, left: 12 },
  { height: 25, top: 22, left: 12 },

  // 以下有料プロダクト
  { height: 25, top: 22, left: 10 },
  { height: 25, top: 22, left: 10 },
  { height: 25, top: 22, left: 10 },
  { height: 25, top: 22, left: 10 },
  { height: 25, top: 21, left: 11 },
  { height: 25, top: 22, left: 10 },
  { height: 25, top: 22, left: 10 },
  { height: 25, top: 22, left: 10 },
  { height: 24, top: 22, left: 10 },
  { height: 24, top: 22, left: 10 },

  { height: 24, top: 22, left: 10 },
  { height: 24, top: 22, left: 10 },
  { height: 24, top: 22, left: 10 },
  { height: 24, top: 22, left: 10 },
  { height: 27, top: 21, left: 8 },
  { height: 25, top: 22, left: 12 },
  { height: 25, top: 22, left: 10 },
  { height: 25, top: 22, left: 9 },
  { height: 25, top: 22, left: 9 },
  { height: 25, top: 22, left: 10 },

  { height: 25, top: 22, left: 10 },
  { height: 25, top: 21, left: 8 },
  { height: 25, top: 21, left: 8 },
  { height: 26, top: 22, left: 9 },
  { height: 24, top: 22, left: 9 },
  { height: 26, top: 22, left: 9 },
  { height: 25, top: 22, left: 8 },
  { height: 25, top: 22, left: 8 },
  { height: 26, top: 21, left: 10 },
  { height: 26, top: 22, left: 7 },

  { height: 21, top: 20, left: 9 },
  { height: 25, top: 21, left: 4 },
  { height: 25, top: 21, left: 14 },
  { height: 28, top: 18, left: 9 },
  { height: 24, top: 22, left: 11 },
  { height: 24, top: 22, left: 11 },
  { height: 25, top: 22, left: 11 },
  { height: 25, top: 22, left: 12 },
  { height: 24, top: 22, left: 11 },
  { height: 24, top: 22, left: 11 },

  { height: 29, top: 19, left: 8 },
  { height: 24, top: 22, left: 10 },
  { height: 24, top: 22, left: 10 },
  { height: 25, top: 21, left: 10 },
  { height: 25, top: 22, left: 10 },
  { height: 24, top: 22, left: 12 },
  { height: 24, top: 22, left: 12 },
  { height: 24, top: 22, left: 12 },
  { height: 25, top: 22, left: 7 },
  { height: 24, top: 22, left: 10 },

  { height: 24, top: 22, left: 8 },
  { height: 26, top: 22, left: 8 },
  { height: 28, top: 22, left: 8 },
  { height: 25, top: 21, left: 6 },
  { height: 25, top: 22, left: 8 },
  { height: 25, top: 22, left: 7 },
  { height: 26, top: 22, left: 7 },
  { height: 26, top: 22, left: 10 },
  { height: 26, top: 22, left: 10 },
  { height: 25, top: 22, left: 10 },

  { height: 26, top: 22, left: 9 },
  { height: 25, top: 22, left: 6 },
  { height: 24, top: 22, left: 9 },
  { height: 24, top: 22, left: 9 },
  { height: 26, top: 21, left: 10 },
  { height: 25, top: 22, left: 10 },
  { height: 26, top: 22, left: 6 },
  { height: 24, top: 22, left: 8 },
  { height: 24, top: 22, left: 10 },
  { height: 24, top: 22, left: 10 },

  { height: 24, top: 22, left: 11 },
  { height: 26, top: 22, left: 10 },
  { height: 27, top: 19, left: 6 },
  { height: 26, top: 21, left: 8 },
  { height: 26, top: 21, left: 8 },
  { height: 26, top: 22, left: 8 },
  { height: 27, top: 21, left: 9 },
  { height: 26, top: 21, left: 9 },
  { height: 26, top: 21, left: 8 },
  { height: 25, top: 21, left: 2 },
  { height: 24, top: 21, left: 7 },
  { height: 25, top: 21, left: 10 },
  { height: 25, top: 21, left: 10 },
  { height: 34, top: 9, left: 1 },
  { height: 24, top: 22, left: 12 },
  { height: 25, top: 22, left: 10 },
  { height: 24, top: 22, left: 12 },
  { height: 26, top: 22, left: 9 },
  { height: 27, top: 21, left: 9 },
  { height: 31, top: 16, left: 5 },

  { height: 26, top: 21, left: 9 },
  { height: 25, top: 22, left: 10 },
  { height: 26, top: 21, left: 10 },
  { height: 24, top: 22, left: 11 },
  { height: 26, top: 20, left: 9 },

  { height: 26, top: 21, left: 9 },
  { height: 26, top: 21, left: 9 },
  { height: 26, top: 21, left: 9 },
  { height: 26, top: 23, left: 10 },
  { height: 24, top: 23, left: 8 },
  { height: 26, top: 21, left: 9 },

  { height: 26, top: 21, left: 10 }, // 112
  { height: 26, top: 21, left: 10 },
  { height: 25, top: 21, left: 12 },
  { height: 25, top: 21, left: 12 },
  { height: 26, top: 21, left: 13 },
  { height: 26, top: 21, left: 13 },
  { height: 25, top: 23, left: 13 },
  { height: 26, top: 20, left: 6 },

  { height: 24, top: 22, left: 10 }, // 服 120
  { height: 24, top: 22, left: 10 },
  { height: 24, top: 22, left: 7 },
  { height: 24, top: 22, left: 7 },
  { height: 24, top: 22, left: 7 },
  { height: 24, top: 22, left: 7 },
  { height: 24, top: 22, left: 0 },
  { height: 24, top: 22, left: 11 },

  { height: 24, top: 22, left: 4 }, // 服 128
  { height: 24, top: 22, left: 4 },
  { height: 24, top: 22, left: 1 },
  { height: 24, top: 22, left: 1 },
  { height: 24, top: 22, left: 12 },
  { height: 24, top: 22, left: 12 },
  { height: 24, top: 22, left: 12 },
  { height: 24, top: 22, left: 12 },

  { height: 21, top: 21, left: 7 }, // 136
  { height: 26, top: 21, left: 10 },
  { height: 29, top: 18, left: 2 },
  { height: 26, top: 21, left: 1 },
  { height: 56, top: -6, left: -1 },

  { height: 25, top: 21, left: 6 }, // 服 141
  { height: 25, top: 21, left:10 },
  { height: 24, top: 23, left: 4 },
  { height: 33, top: 12, left: 2 },
  { height: 33, top: 12, left: 5 },

  { height: 27, top: 19, left: 9 }, // 服 146
  { height: 25, top: 21, left: 6 },
  { height: 28, top: 18, left: 5 },
  { height: 26, top: 20, left: 5 },
  { height: 37, top:  9, left: 3 },

  { height: 24, top: 22, left: 8 }, // 服 151
  { height: 27.5, top: 19.5, left: 5 },
  { height: 24, top: 21, left: 9 },
  { height: 34, top: 11.5, left: 0 },
  { height: 25, top:  21, left: 10 },

  { height: 29, top: 17.5, left: 6 }, // 服 156
  { height: 24.5, top: 21.5, left: 10 },
  { height: 26, top: 20, left: 8.5 },
  { height: 30, top: 17.5, left: 0 },
  { height: 25, top:  21, left: 10 },

  { height: 30, top: 16.5, left: 8 }, // 服 161
  { height: 24.5, top: 21.5, left: 10 },
  { height: 24, top: 22, left: 9.5 },
  { height: 29, top: 17.5, left: 7 },
  { height: 25, top:  22, left: 2 },

  { height: 27, top: 18.5, left: 3.5 }, // 服 166
  { height: 38.5, top: 7.5, left: -1 },
  { height: 27, top: 19, left: 3.5 },
  { height: 34, top: 11.5, left: 3 },
  { height: 49, top:  -3, left: 5 },

  { height: 38.5, top: 8, left: 0 }, // 服 171
  { height: 25, top: 22, left: 2.9 },
  { height: 28, top: 18, left: 3.5 },
  { height: 27, top: 19, left: 0.5 },
  { height: 40, top:  7, left: 2 },

  { height: 26, top: 20, left: 5 }, // 服 176
  { height: 34, top: 12, left: 1.9 },
  { height: 26, top: 21, left: 2.5 },
  { height: 26, top: 21, left: 4.4 },
  { height: 26.4, top:  20, left: 2.2 },

  { height: 25, top: 21.5, left: 12 }, // 服 181
  { height: 23, top: 22.5, left: 2.9 },
  { height: 24, top: 22, left: 4.5 },
  { height: 24, top: 22, left: 10 },
  { height: 37.4, top:  10.5, left: 3 },

  { height: 27, top: 18.5, left: 1 }, // 服 186
  { height: 37, top: 9.5, left: 8.9 },
  { height: 23, top: 24, left: 7.5 },
  { height: 28, top: 18, left: 6 },
  { height: 25.4, top:  22, left: 6 },

  { height: 24, top: 21, left: 5 }, // 服 191
  { height: 27, top: 18.5, left: 0 },
  { height: 24.5, top: 22, left: 8.5 },
  { height: 21, top: 21, left: 6.6 },
  { height: 29.4, top:  16, left: 1 },

  { height: 26, top: 20, left: 2 }, // 服 196
  { height: 19.5, top: 21.5, left: 6 },
  { height: 25, top: 22, left: 5 },
  { height: 35, top: 11, left: 1.6 },
  { height: 25.4, top: 21, left: 5 },

  { height: 32, top: 14, left: 0 }, // 服 201
  { height: 27, top: 19, left: 2 },
  { height: 34.5, top: 11.5, left: -1 },
  { height: 25, top: 21.5, left: 12.1 },
  { height: 30, top: 16, left: 1.9 },

  { height: 33, top: 12.5, left: 2.5 }, // 服 206
  { height: 24, top: 22, left: 10 },
  { height: 29.5, top: 18.5, left: 6 },
  { height: 25, top: 21.5, left: 7.1 },
  { height: 27, top: 18, left: 0.9 },

  { height: 26, top: 20.5, left: 3.5 }, // 服 211
  { height: 25, top: 21.5, left: 3 },
  { height: 27.5, top: 21, left: 6 },
  { height: 25, top: 21.5, left: 6.1 },
  { height: 41, top: 7, left: 4 },
];

// 髪
export const hairAppearanceInFreeSpace: FixedWidthAndPosition[] = [
  { width: 22, top: -1, left: 10 },
  { width: 22, top: -1, left: 10 },
  { width: 21, top: 0, left: 11 },
  { width: 32, top: 0, left: 6 },
  { width: 21, top: 0, left: 10 },
  { width: 23, top: -1, left: 10 },
  { width: 22, top: 0, left: 10 },
  { width: 21, top: 0, left: 11 },
  { width: 21, top: 0, left: 11 },
  { width: 23, top: -1, left: 10 },

  { width: 21, top: 0, left: 10 },
  { width: 25, top: 0, left: 9 },
  { width: 24, top: 0, left: 10 },
  { width: 26, top: 0, left: 10 },
  { width: 22, top: -1, left: 10 },
  { width: 22, top: 0, left: 10 },
  { width: 23, top: 0, left: 9 },
  { width: 23, top: -2, left: 9 },
  { width: 21, top: -1, left: 11 },
  { width: 26, top: -1, left: 7 },

  // 以下有料プロダクト
  { width: 24, top: 0, left: 9 },
  { width: 21, top: 0, left: 11 },
  { width: 20, top: 0, left: 11 },
  { width: 24, top: -1, left: 9 },
  { width: 20, top: 0, left: 11 },
  { width: 21, top: -1, left: 11 },
  { width: 23, top: -1, left: 9 },
  { width: 21, top: -4, left: 11 },
  { width: 23, top: -1, left: 11 },
  { width: 22, top: 0, left: 11 },

  { width: 24, top: 0, left: 10 },
  { width: 24, top: -4, left: 9 },
  { width: 21, top: -1, left: 11 },
  { width: 23, top: -1, left: 9 },
  { width: 19, top: -4, left: 11 },
  { width: 25, top: 0, left: 8 },
  { width: 25, top: 0, left: 9 },
  { width: 22, top: -1, left: 10 },
  { width: 22, top: -1, left: 10 },
  { width: 24, top: -1, left: 9 },

  { width: 22, top: -1, left: 10 },
  { width: 26, top: 0, left: 9 },
  { width: 23, top: -3, left: 12 },
  { width: 23, top: 0, left: 11 },
  { width: 24, top: -1, left: 9 },
  { width: 21, top: -1, left: 10 },
  { width: 21, top: 0, left: 11 },
  { width: 20, top: -1, left: 11 },
  { width: 22, top: -1, left: 9 },
  { width: 16, top: -1, left: 18 },

  { width: 29, top: 0, left: 8 },
  { width: 20, top: -1, left: 12 },
  { width: 20, top: -1, left: 12 },
  { width: 24, top: -1, left: 10 },
  { width: 22, top: -1, left: 10 },
  { width: 24, top: -4, left: 9 },
  { width: 24, top: -4, left: 10 },
  { width: 23, top: -2, left: 9 },
  { width: 24, top: -1, left: 9 },
  { width: 20, top: 0, left: 11 },

  { width: 21, top: -1, left: 10 },
  { width: 26, top: -1, left: 7 },
  { width: 23, top: 0, left: 9 },
  { width: 22, top: -2, left: 10 },
  { width: 31, top: -1, left: 6 },
  { width: 22, top: -3, left: 9 },
  { width: 23, top: -1, left: 10 },
  { width: 21, top: 0, left: 10 },
  { width: 25, top: -1, left: 8 },
  { width: 24, top: -1, left: 10 },
  { width: 23, top: -2, left: 9 },
  { width: 24, top: -1, left: 10 },
  { width: 24, top: -1, left: 8 },
  { width: 24, top: -2, left: 9 },
  { width: 24, top: -2, left: 8 },

  { width: 25, top: -2, left: 8 },
  { width: 27, top: -3, left: 8 },
  { width: 29, top: -4, left: 7 },
  { width: 25, top: -1, left: 8 },
  { width: 26, top: -3, left: 8 },

  { width: 24, top: -5, left: 9 },
  { width: 30, top: -3, left: 7 },
  { width: 27, top: -3, left: 8 },
  { width: 24, top: -3, left: 8 },
  { width: 25, top: -3, left: 7 },

  { width: 24, top: -3, left: 9 }, // 86 
  { width: 26, top: -5, left: 10 },
  { width: 26, top: -1, left: 8 },
  { width: 26, top: -1, left: 8 },
  { width: 23, top: -1, left: 11 },

  { width: 22, top: -1, left: 11 }, // 髪91 
  { width: 25, top: 0, left: 9 },
  { width: 26, top: 0, left: 8 },
  { width: 23, top: 0, left: 10 },
  { width: 23, top: 0, left: 10 },

  { width: 27, top: -3, left: 8 }, // 髪96
  { width: 27, top: -3, left: 8 },
  { width: 36, top: -3, left: 4 },
  { width: 26, top: -9, left: 9 },
  { width: 26, top: -4, left: 8 },

  { width: 25, top: 0, left: 8 }, // 髪101
  { width: 31, top: -8, left: 4 },
  { width: 28, top: -2, left: 8 },
  { width: 23, top: -2, left: 10 },
  { width: 24, top: -2, left: 9 },

  { width: 45, top: 0, left: 0 }, // 髪106
  { width: 28, top: -3, left: 7 },
  { width: 40, top: -2, left: 3 },
  { width: 25, top: -1, left: 8 },
  { width: 33, top: -5, left: 5 },

  { width: 45, top: 0, left: 0 }, // 髪111
  { width: 27, top: -1, left: 8 },
  { width: 23, top: -2, left: 10 },
  { width: 25, top: -1, left: 8 },
  { width: 35, top: -3, left: 3 },

  { width: 26, top: -1, left: 10.5 }, // 髪116
  { width: 29, top: -5, left: 7 },
  { width: 28, top: -2, left: 7 },
  { width: 23, top: -4, left: 10 },
  { width: 41, top: -3.5, left: 1 },

  { width: 33.5, top: -0.5, left: 5.5 }, // 髪121
  { width: 24.5, top: -1, left: 8 },
  { width: 29.5, top: -0.5, left: 7.5 },
  { width: 27.5, top: -1, left: 8 },
  { width: 33, top: -3, left: 8.5 },

  { width: 27.5, top: 0, left: 9 }, // 髪126
  { width: 38, top: -4, left: 5 },
  { width: 27.5, top: -0.5, left: 8 },
  { width: 27, top: 0, left: 9 },
  { width: 32, top: -2, left: 6.5 },

  { width: 22.5, top: -10, left: 10 }, // 髪131
  { width: 36, top: -7.5, left: 3},
  { width: 27.5, top: -0.5, left: 8 },
  { width: 23, top: -2, left: 9 },
  { width: 42, top: -1, left: 0.5 },

  { width: 26.5, top: -3.5, left: 7 }, // 髪136
  { width: 33, top: -5.5, left: 5 },
  { width: 29.5, top: 0, left: 4 },
  { width: 37, top: -4, left: 3 },
  { width: 25, top: 0, left: 9.5 },

  { width: 34.5, top: -3.5, left: 4.2 }, // 髪141
  { width: 27, top: -2.4, left: 7.9 },
  { width: 27.5, top: -4, left: 8 },
  { width: 27, top: -1, left: 7 },
  { width: 24.5, top: 0.1, left: 8.3 },

  { width: 41.5, top: -0.5, left: 1.2 }, // 髪146
  { width: 31.5, top: -5.4, left: 5.9 },
  { width: 32.5, top: -2.7, left: 4.5 },
  { width: 22, top: -4, left: 10 },
  { width: 25, top: 0.5, left: 8 },

  { width: 38, top: -0.4, left: 3 }, // 髪151
  { width: 28.5, top: -0.4, left: 6.9 },
  { width: 37.5, top: -1, left: 6.5 },
  { width: 26, top: -3.5, left: 8 },
  { width: 29, top: -1.2, left: 7 },

  { width: 22.5, top: -3.4, left: 10 }, // 髪156
  { width: 29.5, top: -4.4, left: 6.9 },
  { width: 35.5, top: -1.5, left: 3 },
  { width: 23, top: -1.5, left: 10 },
  { width: 31, top: -12.2, left: 5 },

  { width: 27.5, top: -3.4, left: 7.3 }, // 髪161
  { width: 28, top: -2.4, left: 7.9 },
  { width: 25.5, top: -2.5, left: 8 },
  { width: 30, top: -0.5, left: 9 },
  { width: 37.5, top: -1.2, left: 4 },

  { width: 28.5, top: 4.6, left: 7 }, // 髪166
  { width: 34.3, top: -4.2, left: 4.9 },
  { width: 29, top: -2.5, left: 7 },
  { width: 30, top: -1.5, left: 6 },
  { width: 12.5, top: -6.2, left: 14 },

  { width: 23.5, top: -1, left: 10 }, // 髪171
  { width: 24.3, top: -0.2, left: 8.9 },
  { width: 35, top: 0, left: 5 },
  { width: 36, top: -1.5, left: 4 },
  { width: 25.5, top: -0.2, left: 8 },

  { width: 28.5, top: -1, left: 8 }, // 髪176
  { width: 33.3, top: -1.2, left: 4.9 },
  { width: 32, top: -8, left: 5 },
  { width: 25.5, top: -0.5, left: 8.5 },
  { width: 24.5, top: -5.2, left: 9.5 },
];

// 帽子
export const hatAppearanceInFreeSpace: FixedWidthAndPosition[] = [
  { width: 21, top: -3, left: 10 },
  { width: 21, top: -3, left: 10 },
  { width: 23, top: -3, left: 10 },
  { width: 23, top: -3, left: 10 },
  { width: 25, top: -2, left: 7 },
  { width: 25, top: -2, left: 7 },
  // 以下有料プロダクト
  { width: 25, top: -4, left: 9 },
  { width: 23, top: -3, left: 10 },
  { width: 28, top: -5, left: 8 },
  { width: 19, top: 2, left: 11 },

  { width: 28, top: -3, left: 7 },
  { width: 21, top: 2, left: 11 },
  { width: 23, top: 2, left: 9 },
  { width: 23, top: 0, left: 9 },
  { width: 23, top: 0, left: 9 },
  { width: 19, top: 2, left: 10 },
  { width: 19, top: 2, left: 10 },
  { width: 23, top: -6, left: 11 },
  { width: 23, top: -6, left: 11 },
  { width: 19, top: 1, left: 10 },

  { width: 19, top: 1, left: 10 },
  { width: 15, top: 2, left: 12 },
  { width: 30, top: -5, left: 9 },
  { width: 20, top: -2, left: 11 },
  { width: 37, top: -4, left: 2 },
  { width: 25, top: -2, left: 9 },
  { width: 24, top: -6, left: 10 },
  { width: 24, top: -2, left: 10 },
  { width: 23, top: -7, left: 9 },
  { width: 21, top: -5, left: 10 },

  { width: 25, top: 3, left: 8 },
  { width: 31, top: -7, left: 7 },
  { width: 30, top: 4, left: 5 },
  { width: 26, top: -6, left: 10 },
  { width: 24, top: -3, left: 8 },
  { width: 13, top: -6, left: 9 },
  { width: 23, top: -3, left: 9 },
  { width: 10, top: 0, left: 22 },
  { width: 12, top: 0, left: 21 },
  { width: 23, top: 6, left: 11 },

  { width: 23, top: 5, left: 9 },
  { width: 19, top: 4, left: 11 },
  { width: 9, top: 4, left: 20 },
  { width: 25, top: -4, left: 9 },
  { width: 28, top: -4, left: 7 },
  { width: 24, top: -6, left: 9 },
  { width: 23, top: -5, left: 8 },
  { width: 27, top: -7, left: 8 },
  { width: 15, top: -3, left: 14 },
  { width: 20, top: 2, left: 11 },

  { width: 27, top: -6, left: 8 },
  { width: 21, top: -2, left: 10 },
  { width: 21, top: -7, left: 10 },
  { width: 22, top: -3, left: 9 },
  { width: 24, top: -2, left: 10 },
  { width: 22, top: -2, left: 10 },
  { width: 26, top: -4, left: 11 },
  { width: 35, top: -2, left: 4 },
  { width: 16, top: -4, left: 17 },
  { width: 26, top: -6, left: 9 },

  { width: 24, top: -4, left: 9 },
  { width: 26, top: -4, left: 9 },
  { width: 27, top: -4, left: 8 },
  { width: 24, top: -4, left: 9 },
  { width: 33, top: -4, left: 6 },
  { width: 26, top: -3, left: 6 },
  { width: 32, top: -8, left: 4 },
  { width: 28, top: -8, left: 8 },
  { width: 35, top: -3, left: 4 },
  { width: 21, top: -3, left: 12 },

  { width: 26, top: -5, left: 7 },
  { width: 20, top: -4, left: 11 },
  { width: 21, top: -6, left: 10 },
  { width: 23, top: -1, left: 10 },

  { width: 30, top: -2, left: 6 },
  { width: 20, top: -7, left: 10 },
  { width: 14, top: 0, left: 12 },
  { width: 17, top: -7, left: 14 },
  { width: 18, top: -12, left: 12 },
  { width: 30, top: -10, left: 8 },
  { width: 29, top: -7, left: 6 },
  { width: 23, top: -8, left: 9 },
  { width: 22, top: -4, left: 9 },
  { width: 31, top: -7, left: 6 },

  { width: 34, top: -6, left: 4 },
  { width: 19, top:  2, left: 10 },
  { width: 21, top: -2, left: 10 },
  { width: 23, top: -2, left: 8 },
  { width: 25, top: -1, left: 10 },

  { width: 29, top: -6, left: 4 },
  { width: 25, top: -6, left: 7 },
  { width: 25, top: -8, left: 8 },
  { width: 25, top: -10, left: 8 },
  { width: 30, top: -10, left: 6 },

  { width: 25, top: -6, left: 8 }, // 95
  { width: 25, top: 5, left: 10 },
  { width: 25, top: -6, left: 7 },
  { width: 31, top: -14, left: 4 },
  { width: 33, top: -7, left: 4 },

  { width: 28, top: -3, left: 7 }, // 帽子 100
  { width: 28, top: -3, left: 7 },
  { width: 18, top: -1, left: 11 },
  { width: 23, top: -3, left: 10 },
  { width: 20, top: -3, left: 11 },

  { width: 13, top:  1, left: 22 }, // 帽子 105
  { width: 13, top:  1, left: 22 },
  { width: 19, top:  1, left: 12 },
  { width: 19, top:  1, left: 12 },
  { width: 14, top: -1, left: 18 },
  { width: 14, top: -1, left: 18 },
  { width: 19, top: 5, left: 12 },

  { width: 21, top: -11, left: 13 }, // 帽子 112
  { width: 21, top: -3, left: 12 },
  { width: 23, top: -3, left: 11 },
  { width: 25, top: -5, left: 9 },
  { width: 20, top: -3, left: 11 },

  { width: 27, top: -1, left: 6 }, // 帽子 117
  { width: 23, top: -1, left: 7 },
  { width: 34, top: -1, left: 4 },
  { width: 18, top: -7, left: 15 },
  { width: 32, top: -7, left: 5 },

  { width: 31, top:  1, left: 6 }, // 帽子 122
  { width: 31, top: -5, left: 6 },
  { width: 34, top: -7, left: 4 },
  { width: 18, top: 0, left: 12 },
  { width: 23, top: -10, left: 8 },

  { width: 36, top:  -9, left: 4 }, // 帽子 127
  { width: 24, top: -12, left: 10 },
  { width: 23, top: -5, left: 11 },
  { width: 22, top: -1, left: 10 },
  { width: 30, top: -6, left: 6 },

  { width: 7, top:  -8, left: 17 }, // 帽子 132
  { width: 31, top: -5, left: 10 },
  { width: 25, top: -5, left: 8 },
  { width: 19, top: -5, left: 10 },
  { width: 30.5, top: -3, left: 8 },

  { width: 27, top:  -10, left: 6 }, // 帽子 137
  { width: 29, top: -2, left: 10 },
  { width: 25, top: 4, left: 12 },
  { width: 24, top: -6, left: 7 },
  { width: 8.5, top: -4, left: 16 },

  { width: 22, top:  -4, left: 9 }, // 帽子 142
  { width: 14, top: -3, left: 20 },
  { width: 17, top: -8, left: 22 },
  { width: 17, top: -6, left: 4 },
  { width: 32.5, top: -16, left: 3 },

  { width: 26, top:  -7, left: 8 }, // 帽子 147
  { width: 30, top: -4, left: 9 },
  { width: 34, top: -5, left: 6 },
  { width: 32, top: -5, left: 4 },
  { width: 40.5, top: -9, left: 1 },

  { width: 28, top:  -2, left: 9 }, // 帽子 152
  { width: 25, top: -17, left: 9 },
  { width: 32, top: -4, left: 6 },
  { width: 29, top: -5, left: 7 },
  { width: 31.5, top: -5, left: 8 },

  { width: 28, top:  -7, left: 7 }, // 帽子 157
  { width: 26, top: -6.5, left: 7 },
  { width: 31, top: -3, left: 5 },
  { width: 26, top: -9, left: 7 },
  { width: 24.5, top: -7, left: 7.5 },

  { width: 7, top:  4, left: 27 }, // 帽子 162
  { width: 26, top: -6.5, left: 7 },
  { width: 26, top: -2, left: 8 },
  { width: 40, top: -10, left: 1 },
  { width: 34.5, top: -6, left: 3 },

  { width: 27, top: -2, left: 6 }, // 帽子 167
  { width: 42, top: -4.5, left: 1 },
  { width: 22, top: 1, left: 10 },
  { width: 20, top: -10, left: 11 },
  { width: 20.5, top: -5, left: 10 },

  { width: 26, top: -10, left: 8 }, // 帽子 172
  { width: 30, top: -9.5, left: 7 },
  { width: 25, top: -4, left: 8 },
  { width: 16, top: -2, left: 22 },
  { width: 27.5, top: -7, left: 6 },

  { width: 9, top: -2, left: 20 }, // 帽子 177
  { width: 14, top: -3.5, left: 17 },
  { width: 21, top: -4, left: 13 },
  { width: 21, top: -4, left: 15 },
  { width: 33, top: -5.8, left: 5 },

  { width: 24.5, top: -2, left: 9 }, // 帽子 182
  { width: 34, top: -3.5, left: 6 },
  { width: 32, top: -4, left: 5 },
  { width: 24, top: -4, left: 10 },
  { width: 33, top: -6.8, left: 4 },

  { width: 24.5, top: -7, left: 9 }, // 帽子 187
  { width: 29, top: -3.5, left: 6 },
  { width: 20, top: 1, left: 9 },
  { width: 6, top: -1, left: 27 },
  { width: 26, top: -9.8, left: 11 },
];
