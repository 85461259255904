import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import {
  HeaderNavItem,
  NavItem as NavHelp,
  NavIcon as NavHelpIcon,
} from 'javascript/components/common/Header/components/HeaderNavItem';
import { HelpMenu } from 'javascript/components/common/Header/components/HelpMenu';
import { device } from 'javascript/utils/mediaQuery';

const StyledNonActiveNavMenu = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 424px;
  padding-right: 8px;
  letter-spacing: 1px;
  font-size: 10px;
  color: #a4a4a4;
  @media ${device.tab} {
    width: 328px;
  }
  @media ${device.sp} {
    width: 144px;
    padding-right: 8px;
    letter-spacing: 0;
    p {
      display: none;
    }
  }
  .isActive {
    img {
      filter: none;
      opacity: 1;
    }
    p {
      font-weight: bold;
      color: #000;
    }
  }
`;

export const HeaderNonActiveNavMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleHelpMenu = () => setIsOpen(!isOpen);
  const { t } = useTranslation();

  return (
    <StyledNonActiveNavMenu>
      <NavLink to='/employees' activeClassName='isActive'>
        <HeaderNavItem img='member_blue.png' text={t('MenuHeaderMember')} />
      </NavLink>
      <NavHelp
        onClick={() => {
          toggleHelpMenu();
        }}
      >
        {/* @ts-ignore */}
        <NavHelpIcon src={RailsAssetPath('help.png')} alt='icon-help' />
        <p>{t('MenuHeaderHelp', {})}</p>
      </NavHelp>
      <HelpMenu
        isMaster={false}
        isOpen={isOpen}
        toggleHelpMenu={toggleHelpMenu}
      />
    </StyledNonActiveNavMenu>
  );
};
