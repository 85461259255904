import { useEffect, useState } from 'react';
import useVideoCallContext from './useVideoCallContext';

const useScreenShareParticipant = () => {
  const { room } = useVideoCallContext();
  const [screenShareParticipant, setScreenShareParticipant] = useState();

  useEffect(() => {
    if (room) {
      const updateScreenShareParticipant = () => {
        setScreenShareParticipant(
          Array.from(room.participants.values())
            .concat(room.localParticipant)
            .find((participant) =>
              Array.from(participant.tracks.values()).find(track =>
                track.trackName.includes('screen')
              )
            )
        );
      };
      updateScreenShareParticipant();

      room.on('trackPublished', updateScreenShareParticipant);
      room.on('trackUnpublished', updateScreenShareParticipant);
      room.on('participantDisconnected', updateScreenShareParticipant);

      room.localParticipant.on('trackPublished', updateScreenShareParticipant);
      room.localParticipant.on('trackUnpublished', updateScreenShareParticipant);
      return () => {
        room.off('trackPublished', updateScreenShareParticipant);
        room.off('trackUnpublished', updateScreenShareParticipant);
        room.off('participantDisconnected', updateScreenShareParticipant);

        room.localParticipant.off('trackPublished', updateScreenShareParticipant);
        room.localParticipant.off('trackUnpublished', updateScreenShareParticipant);
      };
    }
  }, [room]);

  return screenShareParticipant;
}
export default useScreenShareParticipant;