import { useCallback } from 'react';
import useSWR from 'swr';

export const useToggleSortMode = () => {
  const { data: isSortMode, mutate: setIsSortMode } = useSWR(
    'isSortMode',
    null,
    {
      fallbackData: false,
    }
  );

  const startSortMode = useCallback(() => {
    setIsSortMode(true);
  }, []);

  const finishSortMode = useCallback(() => {
    setIsSortMode(false);
  }, []);

  return { isSortMode, startSortMode, finishSortMode };
};
