import React, { useState, useEffect, useCallback, useGlobal } from 'reactn';
import styled from 'styled-components';
import axios from 'axios';
import { Typography, Button, Grid, Link, Box, List } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import { PAYMENT_METHOD_STEPS } from './constants';
import usePaymentConfig from './hooks/usePaymentConfig';
import { useStyles } from './hooks/useStyles';
import InputCardNumber from './input/InputCardNumber';
import ProgressBackdrop from './ProgressBackdrop';
import { useTranslation } from 'react-i18next';

const PaymentMethodSelect = () => {
  const { t } = useTranslation();
  const [paymentConfig, fetchConfig] = usePaymentConfig();
  const [inProgres, setInProgress] = useState(false);
  const classes = useStyles();

  const handleClickCard = useCallback(async (type, item) => {
    let message, url;
    if (item.default) return;
    switch (type) {
      case 'change':
        message = t('PaymentMessageDefaultCardConfirm', { last4: item.last4 });
        url = '/api/v1/billing/payments/change_default_payment_method';
        break;
      case 'delete':
        message = t('PaymentMessageDeleteCardConfirm', { last4: item.last4 });
        url = '/api/v1/billing/payments/delete_payment_method';
        break;
    }
    const confirm = window.confirm(message);
    if (confirm) {
      setInProgress(true);
      await axios
        .post(url, { id: item.id })
        .then((res) => {
          fetchConfig();
        })
        .catch((err) => {
          alert(t('PaymentAlertErrorMessage', {}));
        });
    }
    setInProgress(false);
  });
  return (
    <>
      <ProgressBackdrop open={inProgres}></ProgressBackdrop>

      <Box m={1}>
        <List className={classes.paymentList}>
          {paymentConfig.payment_methods.map((item, index) => {
            return (
              <Grid key={index} container spacing={3}>
                <Grid container item justify='space-between'>
                  <Grid item xs={4}>
                    {item.card_brand}・・・{item.last4}
                  </Grid>
                  <Grid item xs={5}>
                    {item.default ? (
                      t('PaymentTextDefault', {})
                    ) : (
                      <Link onClick={() => handleClickCard('change', item)}>
                        {t('PaymentTextSaveDefault', {})}
                      </Link>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    {item.default ? (
                      ''
                    ) : (
                      <Link
                        color='secondary'
                        onClick={() => handleClickCard('delete', item)}
                      >
                        {t('PaymentTextDeletea', {})}
                      </Link>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </List>

        <Grid item>
          {paymentConfig.payment_methods.length == 1 ? (
            <Typography variant='caption'>
              {t('PaymentAlertSetDefaultPaymentMethodDelete', {})}
            </Typography>
          ) : (
            ''
          )}
        </Grid>
      </Box>
    </>
  );
};

export default PaymentMethodSelect;
