import React from 'reactn';
import styled from 'styled-components';
import { Typography, Dialog, DialogContent, Grid } from '@material-ui/core';
import DialogCloseButton from './buttons/DialogCloseButton';
import { useTranslation } from 'react-i18next';
import { device } from 'javascript/utils/mediaQuery';
import { PresentModalAvatar } from 'javascript/features/mybox/components/PresentModalAvatar';
import { useExchangeVike } from 'javascript/components/payment/hooks/useExchangeVike';
import { useSubmitExchange } from 'javascript/components/payment/hooks/useSubmitExchange';
import { AvatarToPresent } from 'types';

type Props = {
  open: boolean;
  onClose: () => void;
  avatars: AvatarToPresent[] | undefined;
  targetAvatar: AvatarToPresent | undefined;
  remainingPoint: number;
  handleSelectAvatar: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  rate: {
    point: number;
    viket: number;
  };
};

export const ExchangeToVikeDialog: React.FC<Props> = ({
  open,
  onClose,
  avatars,
  targetAvatar,
  remainingPoint,
  rate,
  handleSelectAvatar,
}) => {
  const { t } = useTranslation();

  const { exchangedVike, changeExchangedVike, exchangedPoint } =
    useExchangeVike(remainingPoint, rate.viket, rate.point);

  const { submitExchange } = useSubmitExchange(
    exchangedVike,
    exchangedPoint,
    targetAvatar?.avatar_id,
    onClose
  );

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogCloseButton onClose={onClose} />
        <DialogContent>
          <ContentWrapper>
            <Grid container direction='column' spacing={4} alignItems='center'>
              <Grid item>
                <Typography variant='subtitle1' style={{ color: '#707070' }}>
                  {t('PaymentTextPointtoviketitle', {})}
                </Typography>
              </Grid>
              <StyledExchangedPointWrapper>
                {/* @ts-ignore */}
                <img src={RailsAssetPath('icon_point.png')} />
                <p>{exchangedPoint}</p>
                <p>pt</p>
              </StyledExchangedPointWrapper>
              <StyledArrowToBottom>
                <div />
                <div />
              </StyledArrowToBottom>
              <StyledExchangedVikeWrapper>
                {/* @ts-ignore */}
                <img src={RailsAssetPath('mypage/coin.png')} />
                <input
                  value={exchangedVike}
                  onChange={(e) => changeExchangedVike(e)}
                  type='number'
                  name='quantity'
                  min='1'
                />
                <p>vike</p>
              </StyledExchangedVikeWrapper>
              {targetAvatar && (
                <PresentModalAvatar targetAvatar={targetAvatar} />
              )}
              <Grid
                container
                item
                direction='row'
                alignItems='center'
                justify='center'
              >
                {avatars && (
                  <StyledSelectAvatar>
                    <select name='avatar' onChange={handleSelectAvatar}>
                      <option hidden>
                        {t('MyboxPresentmodalSelectavatar', {})}
                      </option>
                      {avatars.map((avatar) => (
                        <option value={avatar.avatar_id} key={avatar.avatar_id}>
                          {avatar.name}
                        </option>
                      ))}
                    </select>
                    <StyledTriangle />
                    {targetAvatar && (
                      <>
                        <StyledAvatarImage>
                          <img
                            src={
                              targetAvatar.user_image ||
                              // @ts-ignore
                              RailsAssetPath('noimage_icon.png')
                            }
                          />
                        </StyledAvatarImage>
                        <StyledSendToText>
                          {t('PaymentTextSendto')}
                        </StyledSendToText>
                      </>
                    )}
                  </StyledSelectAvatar>
                )}
              </Grid>

              <Grid
                container
                direction='column'
                justify='center'
                alignItems='center'
                spacing={4}
              >
                <Grid item>
                  <StyledCompleteButton onClick={submitExchange}>
                    {t('PaymentTextComplete', {})}
                  </StyledCompleteButton>
                </Grid>
              </Grid>
            </Grid>
          </ContentWrapper>
        </DialogContent>
      </Dialog>
    </>
  );
};

const ContentWrapper = styled.div`
  width: 360px;
  padding: 20px 20px 40px;
  @media ${device.sp} {
    width: 280px;
  }
`;
const StyledCompleteButton = styled.button`
  width: 152px;
  margin-top: 24px;
  padding: 8px 16px;
  color: rgb(3, 169, 244);
  border: 2px solid rgb(3, 169, 244);
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.2s ease-in-out 0s;
  &:hover {
    transform: translate(-1px, -1px);
    box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 5%), 4px 4px 4px 0px rgb(0 0 0 / 5%);
  }
`;

const StyledSelectAvatar = styled.div`
  position: relative;
  margin-top: 8px;
  select {
    width: 176px;
    padding: 8px 20px;
    border: 2px solid #f4f4f4;
    border-radius: 8px;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    appearance: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  @media ${device.sp} {
    select {
      width: 152px;
    }
  }
`;

const StyledTriangle = styled.div`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  width: 10px;
  height: 8px;
  background-color: #433c35;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
`;

const StyledAvatarImage = styled.div`
  position: absolute;
  top: -1px;
  left: -48px;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

const StyledSendToText = styled.p`
  position: absolute;
  bottom: 0;
  right: -48px;
  font-size: 12px;
`;

const StyledExchangedVikeWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin: 16px 0 24px;
  img {
    width: 20px;
  }
  input {
    width: 120px;
    padding: 4px;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    background-color: #f5f5f5;
    text-align: center;
    color: #707070;
    font-size: 20px;
    font-weight: bold;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }
  }
  p {
    margin-top: auto;
    color: #707070;
  }
`;

const StyledArrowToBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    &:first-child {
      width: 4px;
      height: 8px;
      background-color: #707070;
    }
    &:last-child {
      width: 10px;
      height: 8px;
      clip-path: polygon(0 0, 100% 0, 50% 100%);
      background-color: #707070;
    }
  }
`;

const StyledExchangedPointWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
  margin-bottom: 16px;
  img {
    width: 20px;
  }
  p {
    color: #707070;
    &:first-of-type {
      font-size: 20px;
      font-weight: bold;
    }
  }
`;
