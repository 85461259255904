import makeStyles from '@material-ui/core/styles/makeStyles';
import { useMediaQuery } from 'react-responsive';

export const useStyles = makeStyles(theme => ({
  baseButton: {
    border: '1px solid #00A0E9',
    borderRadius: 2,
    color: '#00A0E9',
    height: 40,
    maxWidth: '225px',
    minWidth: '158px',
    padding: '6px 14px',
    '&:disabled': {
      background: '#cfcfcf!important',
      border: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem'
    }
  },
  submitButton: {
    background: 'linear-gradient(45deg, #00A0E9 30%, #00A0E9 90%)',
    color: 'white',
  },
  roundedButton: {
    borderRadius: 8,
  },

  backdrop: {
    zIndex: 1000,
    color: '#fff',
  },

  inputNumber: {
    border: `1px solid ${theme.palette.primary.main}`,
    overflow: 'hidden',
    borderRadius: 4,
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
  },
  baseList: {
    maxHeight: '300px',
    minHeight: '150px',
    overflow: 'auto',
  },
  historyBox:{
    paddingTop: '30px'
  },
  historyDateSelectButton: {
    borderRadius: 8,
    color: '#707070',
  },

}))


