import axios from "axios";

export function fetchMemberData(params) {
  return (dispatch) => {
    axios.get('/api/v1/users', { params }).then(res => {
      dispatch({
        type: 'SET_MEMBER_DATA', 
        userList: res.data,
        params: params
      });
    });
  }
}

export function setMemberProfile(args) {
  return (dispatch) => {
    dispatch({ 
      type: 'SET_MEMBER_PROFILE_DETAIL', 
      user: args.user, 
      roles: args.roles,
    })
  }
}

export function fetchMemberApi(params) {
  return axios.get('/api/v1/users', { params });
}