import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { fetchRooms } from '../../../lib/redux/actions/room';
import store from '../../../lib/redux/store';
import { useGetOfficeData } from '../../realWorld/hooks/useGetOfficeData';

export const useEditVirtualWorld = (roomId) => {
  const initialValues = {
    type: 'ROOM',
    name: '',
    url: '',
    note: '',
    room_type: '',
    lounge_auto_move: true,
    is_open_guest: false,
    image_id: 0,
  };

  const [roomDetail, setRoomDetail] = useState(initialValues);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [type, setType] = useState(initialValues.room_type);
  const [imageNum, setImageNum] = useState();
  const [_, setIsShowEditVirtualWorldModal] = useGlobal(
    'isShowEditVirtualWorldModal'
  );
  const { virtualWorldImageList } = useGetOfficeData();

  const getRoomDetail = async (roomId) => {
    const url = `/api/v1/rooms/${roomId}`;
    await axios.get(url).then((res) => {
      const image = res.data.user_room_image;
      const imageId = image.charAt(image.length - 5);
      setRoomDetail(res.data);
      setType(res.data.room_type);
      setImageNum(+imageId);
    });
  };

  useEffect(() => {
    getRoomDetail(roomId);
  }, [roomId]);

  const submit = async (headers, data) => {
    const url = '/api/v1/rooms';
    const config = { headers };

    return await axios.patch(`${url}/${roomId}`, data, config);
  };

  const updateRoomInfo = () => {
    store.dispatch(fetchRooms());
    setIsShowEditVirtualWorldModal(false);
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      const headers = {
        'content-type': 'application/json',
      };

      const params = {
        type: 'ROOM',
        name: roomDetail.name.trim() + (roomDetail.is_open_guest? " " : ""),
        url: roomDetail.url,
        note: roomDetail.note,
        is_open_guest: roomDetail.is_open_guest,
        room_type: type === 'voice' ? 2 : type === 'video' ? 1 : 3,
        delete_flg: false,
        lounge_auto_move: type !== 'nonfunctional',
        image_id: imageNum,
      };

      submit(headers, params)
        .then((res) => {
          updateRoomInfo();
        })
        .catch((err) => {
          alert(err);
        });
    },
    [submit, updateRoomInfo]
  );

  const handleChangeValue = useCallback(
    (e) => {
      const { name, value } = e.target;
      if (name == "is_open_guest") {
        roomDetail[name] =  e.target.checked;  
      } else {
        roomDetail[name] = value;
      }
      
      setRoomDetail({ ...roomDetail, roomDetail });
    },
    [roomDetail, setRoomDetail]
  );

  const handleSelectType = (roomType) => {
    setType(roomType);
  };

  const handleSelectImage = (imageId) => {
    setImageNum(imageId);
  };

  return {
    roomDetail,
    handleChangeValue,
    handleSubmit,
    type,
    handleSelectType,
    isSubmitting,
    virtualWorldImageList,
    imageNum,
    handleSelectImage,
  };
};
