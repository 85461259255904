export default class Attendance {
  static WEEKDAY_WORK = 0;
  static HOLIDAY = 1;
  static ABSENCE = 2;
  static PAID_HOLIDAY = 3;
  static COMPENSATORY_HOLIDAY = 4;
  static CORRECTION = 5;
  static WORKSCHEDULE = 6;
  static APPLYING_WORK = 7;

  static config = {
    attendanceType: {
      [Attendance.WEEKDAY_WORK]: {
        name: 'AttendanceStatusAtWork',
        style: {
          backgroundColor: '#e5e5e5',
          fontSize: '10px',
          flexWrap: 'wrap'
        }
      },
      [Attendance.HOLIDAY]: {
        name: 'AttendanceStatusHolidays',
        style: {
          backgroundColor: '#b5b5b5',
        }
      },
      [Attendance.ABSENCE]: {
        name: 'AttendanceStatusAbsence',
        style: {
          backgroundColor: '#b5b5b5',
        }
      },
      [Attendance.PAID_HOLIDAY]: {
        name: 'AttendanceStatusPaidHoliday',
        style: {
          backgroundColor: '#b5b5b5',
        }
      },
      [Attendance.COMPENSATORY_HOLIDAY]: {
        name: 'AttendanceStatusSustituteHoliday',
        style: {
          backgroundColor: '#b5b5b5',
        }
      },
      [Attendance.CORRECTION]: {
        name: 'AttendanceStatusCorrection',
        style: {
          backgroundColor: '#ff0000',
        }
      },
      [Attendance.WORKSCHEDULE]: {
        name: 'AttendanceStatusWorkSchedule',
        style: {
          backgroundColor: '#ff0000',
        }
      },
      [Attendance.APPLYING_WORK]: {
        name: 'AttendanceStatusWorkSchedule',
        style: {
          backgroundColor: '#b5b5b5',
        }
      },
    }
  }
}