import React, { useCallback, useMemo, useState } from 'react';

export const useExchangeVike = (
  remainingPoint: number,
  rateViket: number,
  ratePoint: number
) => {
  const MIN_VALUE = 3;
  const [exchangedVike, setExchangedVike] = useState(MIN_VALUE);

  const rateToVike = useMemo(
    () => rateViket / ratePoint,
    [ratePoint, rateViket]
  );
  const rateToPoint = useMemo(
    () => ratePoint / rateViket,
    [rateViket, ratePoint]
  );
  const exchangedPoint = useMemo(
    () => Math.floor(exchangedVike * rateToPoint),
    [rateToPoint, exchangedVike]
  );

  const changeExchangedVike = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(e.target.value);

      const maxLimitVike = Math.floor(remainingPoint * rateToVike);

      setExchangedVike(
        value <= MIN_VALUE
          ? MIN_VALUE
          : value >= maxLimitVike
          ? maxLimitVike
          : value
      );
    },
    [remainingPoint, rateToVike, MIN_VALUE]
  );

  return { changeExchangedVike, exchangedVike, exchangedPoint };
};
