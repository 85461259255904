import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import {
  MQ_DESKTOP,
  MQ_MOBILE,
  BREAKPOINT_MIN_PC,
} from 'javascript/components/landingPage/lib/MQ';
import {
  layerZIndex,
  pcp,
  pcPx,
  spp,
} from 'javascript/components/landingPage/lib/cssUtil';
import {
  BLACK,
  buttonReset,
  jpStyle,
  buttonAnim,
  buttonWhAnim,
  BLUE,
} from 'javascript/components/landingPage/local/commonCss';
import {
  LANGUAGE,
  useLanguageStoreValue,
  useSetLanguageStore,
} from 'javascript/components/landingPage/store/language';
import { useSetScrollYStore } from 'javascript/components/landingPage/store/scrollYStore';
import { useSetIsSpStore } from 'javascript/components/landingPage/store/isSpStore';

//@ts-ignore
const logoWh = RailsAssetPath('lp/top/ViKet Town_logo_w.png');
//@ts-ignore
const logo = RailsAssetPath('lp/top/ViKet Town_logo_1@144x.png');
//@ts-ignore
const check = RailsAssetPath('lp/common/langage_ch.svg');

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  position: fixed;
  overflow: visible;
  top: 0;
  left: 0;
  z-index: ${layerZIndex('header')};
  ${MQ_MOBILE} {
    height: ${spp(120)};
  }
  ${MQ_DESKTOP} {
    height: ${pcp(120)};
  }
`;

const TopWrapper = styled.div`
  transition: opacity 0.3s ease-out 0s, visibility 0.3s ease-out 0s;
  &[data-top='false'] {
    opacity: 0;
    visibility: hidden;
  }
`;

const Logo = styled.img`
  position: absolute;
  ${MQ_MOBILE} {
    top: ${spp(60)};
    left: ${spp(22)};
    height: ${spp(26)};
  }
  ${MQ_DESKTOP} {
    top: ${pcPx(71)};
    left: ${pcPx(65)};
    height: ${pcPx(35)};
  }
`;

const LinkStyle = css`
  ${jpStyle}
  ${buttonWhAnim}
  color: white;
  border: solid 1px white;
  font-weight: 600;
  text-align: center;
  background-color: transparent;
  ${MQ_MOBILE} {
    font-size: ${spp(17)};
    line-height: ${spp(29)};
    border-radius: ${spp(10)};
    padding: ${spp(6)} ${spp(35)};
    margin-left: ${spp(10)};
    max-width: ${spp(165)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(20)};
    line-height: ${pcPx(34)};
    border-radius: ${pcPx(10)};
    padding: ${pcPx(8)} ${pcPx(47)};
  }
`;

const Link01 = styled.a`
  ${LinkStyle}
  ${MQ_MOBILE} {
    max-width: ${spp(195)};
  }
`;

const Link02 = styled.a`
  ${LinkStyle}
  ${MQ_DESKTOP} {
    margin-left: ${pcPx(15)};
  }
`;

const Link03 = styled.a`
  ${LinkStyle}
  ${MQ_MOBILE} {
    display: none;
  }
  ${MQ_DESKTOP} {
    margin-left: ${pcPx(15)};
  }
`;

const Button = styled.button`
  ${jpStyle}
  ${buttonReset}
  ${buttonWhAnim}
  color: white;
  border: solid 1px white;
  font-weight: 600;
  text-align: center;
  ${MQ_MOBILE} {
    font-size: ${spp(21)};
    line-height: ${spp(36)};
    padding: ${spp(2)} ${spp(60)} ${spp(3)} ${spp(30)};
    margin-left: ${spp(10)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(24)};
    line-height: ${pcPx(41)};
    padding: ${pcPx(3)} ${pcPx(70)} ${pcPx(5)} ${pcPx(40)};
    margin-left: ${pcPx(13)};
  }
  &:before {
    border-radius: ${pcPx(0)};
  }
  &:after {
    content: '▼';
    position: absolute;
    ${MQ_MOBILE} {
      font-size: ${spp(17)};
      top: ${spp(6)};
      right: ${spp(20)};
    }
    ${MQ_DESKTOP} {
      font-size: ${pcPx(17)};
      top: ${pcPx(6)};
      right: ${pcPx(20)};
    }
  }
`;

const DefaultWrapper = styled.div`
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-out 0s, visibility 0.3s ease-out 0s;
  &[data-top='false'] {
    opacity: 1;
    visibility: visible;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  position: absolute;
  justify-content: space-between;
  ${MQ_MOBILE} {
    top: ${spp(53)};
    right: ${spp(30)};
  }
  ${MQ_DESKTOP} {
    top: ${pcPx(62)};
    right: ${pcPx(55)};
  }
`;

const LinkBkStyle = css`
  ${jpStyle}
  ${buttonAnim}
  color: ${BLACK};
  border: solid 1px ${BLACK};
  font-weight: 600;
  text-align: center;
  background-color: transparent;
  ${MQ_MOBILE} {
    font-size: ${spp(17)};
    line-height: ${spp(29)};
    border-radius: ${spp(10)};
    padding: ${spp(6)} ${spp(35)};
    margin-left: ${spp(10)};
    max-width: ${spp(165)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(20)};
    line-height: ${pcPx(34)};
    border-radius: ${pcPx(10)};
    padding: ${pcPx(8)} ${pcPx(47)};
  }
`;

const Link01Bk = styled.a`
  ${LinkBkStyle}
  ${MQ_MOBILE} {
    max-width: ${spp(195)};
  }
`;

const Link02Bk = styled.a`
  ${LinkBkStyle}
  ${MQ_DESKTOP} {
    margin-left: ${pcPx(15)};
  }
`;

const Link03Bk = styled.a`
  ${LinkBkStyle}
  ${MQ_DESKTOP} {
    margin-left: ${pcPx(15)};
  }
  ${MQ_MOBILE} {
    display: none;
  }
`;

const ButtonBk = styled.button`
  ${jpStyle}
  ${buttonReset}
  ${buttonAnim}
  color: ${BLACK};
  border: solid 1px ${BLACK};
  font-weight: 600;
  text-align: center;
  ${MQ_MOBILE} {
    font-size: ${spp(21)};
    line-height: ${spp(36)};
    padding: ${spp(2)} ${spp(60)} ${spp(3)} ${spp(30)};
    margin-left: ${spp(10)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(24)};
    line-height: ${pcPx(41)};
    padding: ${pcPx(3)} ${pcPx(70)} ${pcPx(5)} ${pcPx(40)};
    margin-left: ${pcPx(13)};
  }
  &:before {
    border-radius: ${pcPx(0)};
  }
  &:after {
    content: '▼';
    position: absolute;
    ${MQ_MOBILE} {
      font-size: ${spp(17)};
      top: ${spp(6)};
      right: ${spp(20)};
    }
    ${MQ_DESKTOP} {
      font-size: ${pcPx(17)};
      top: ${pcPx(6)};
      right: ${pcPx(20)};
    }
  }
`;

const SetLanguage = styled.ul`
  position: absolute;
  list-style-type: none;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(40px) brightness(200%);
  border: solid 1px ${BLUE};
  button {
    ${buttonReset}
  }
  li {
    ${jpStyle}
    text-align: left;
    font-weight: 600;
    display: flex;
    align-items: center;
    ${MQ_MOBILE} {
      font-size: ${spp(18)};
      line-height: ${spp(40)};
    }
    ${MQ_DESKTOP} {
      font-size: ${pcPx(18)};
      line-height: ${pcPx(40)};
    }
    &:before {
      content: '';
      ${MQ_MOBILE} {
        width: ${spp(22)};
        height: ${spp(18)};
        margin-right: ${spp(15)};
      }
      ${MQ_DESKTOP} {
        width: ${pcPx(22)};
        height: ${pcPx(18)};
        margin-right: ${pcPx(15)};
      }
    }
    &[data-select='true'] {
      &:before {
        background: url(${check}) no-repeat;
        background-size: contain;
      }
    }
  }
  ${MQ_MOBILE} {
    width: ${spp(118)};
    padding: ${spp(20)} ${spp(20)};
    border-radius: ${spp(10)};
    top: ${spp(85)};
    right: ${spp(25)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(118)};
    padding: ${pcPx(20)} ${pcPx(20)};
    border-radius: ${pcPx(10)};
    top: ${pcPx(100)};
    right: ${pcPx(25)};
  }
`;

const Header = () => {
  const [isTop, setIsTop] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [scrollY, setScrollY] = useState(0);
  const [isSp, setIsSp] = useState(false);
  const [isOpenLanguage, setIsOpenLanguage] = useState(false);
  const setScrollYStore = useSetScrollYStore();
  const setIsSpStore = useSetIsSpStore();
  const setLanguageStore = useSetLanguageStore();
  const languageStoreValue = useLanguageStoreValue();
  const { t } = useTranslation();

  const onClickLang = (language: string, index: number) => {
    setLanguageStore({ language });
    i18n.changeLanguage(Object.keys(LANGUAGE)[index]);
    setIsOpenLanguage(false);
  };

  useEffect(() => {
    const onScroll = () => {
      const scrollTop = window.scrollY;
      setScrollY(scrollTop);
      setScrollYStore({ scrollY: scrollTop });
    };

    const onResize = () => {
      const isSPSize = window.innerWidth < BREAKPOINT_MIN_PC;
      setIsSp(isSPSize);
      setIsSpStore({ isSp: isSPSize });
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onResize);

    onResize();
    return () => {
      window.removeEventListener('scroll', onScroll);
      window.addEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    const height = () => {
      const heightVwNum = isSp
        ? parseFloat(spp(1330 - 53))
        : parseFloat(pcp(1085));
      const height = (Number(heightVwNum) / 100) * windowWidth;

      return isSp ? height : height - parseFloat(pcPx(62));
    };
    setIsTop(height() > scrollY);
  }, [scrollY, isSp, windowWidth]);

  return (
    <Wrapper>
      <TopWrapper data-top={isTop}>
        <Logo src={logoWh} alt='ViKet Town' />
        <LinkWrapper>
          <Link01
            href={`/sign_up?lang=${languageStoreValue}`}
            target='_blank'
            rel='noopener noreferrer'
            data-lang-ru={languageStoreValue === LANGUAGE.ru}
          >
            <span>{t('header.signup')}</span>
          </Link01>
          <Link02
            href={`/sign_in?lang=${languageStoreValue}`}
            target='_blank'
            rel='noopener noreferrer'
            data-lang-ru={languageStoreValue === LANGUAGE.ru}
          >
            <span>{t('header.login')}</span>
          </Link02>
          <Link03
            href={
              languageStoreValue === LANGUAGE.ja
                ? 'https://docs.google.com/forms/d/1FfyHPuo1VzzgqIS-DvX_17DXf5W_e4JvtJSI1ZOFdGg/edit?usp=sharing'
                : 'https://bit.ly/3hwF1D9'
            }
            target='_blank'
            rel='noopener noreferrer'
            data-lang-ru={languageStoreValue === LANGUAGE.ru}
          >
            <span>{t('footer.contact')}</span>
          </Link03>
          <Link03
            href='https://bit.ly/3GKLBSw'
            target='_blank'
            rel='noopener noreferrer'
            data-lang-ru={languageStoreValue === LANGUAGE.ru}
          >
            <span>{t('header.download')}</span>
          </Link03>
          <Button
            type='button'
            onClick={() => setIsOpenLanguage(!isOpenLanguage)}
          >
            <span>{languageStoreValue}</span>
          </Button>
        </LinkWrapper>
      </TopWrapper>
      <DefaultWrapper data-top={isTop}>
        <Logo src={logo} alt='ViKet Town' />
        <LinkWrapper>
          <Link01Bk
            href={`/sign_up?lang=${languageStoreValue}`}
            target='_blank'
            rel='noopener noreferrer'
            data-lang-ru={languageStoreValue === LANGUAGE.ru}
          >
            <span>{t('header.signup')}</span>
          </Link01Bk>
          <Link02Bk
            href={`/sign_in?lang=${languageStoreValue}`}
            target='_blank'
            rel='noopener noreferrer'
            data-lang-ru={languageStoreValue === LANGUAGE.ru}
          >
            <span>{t('header.login')}</span>
          </Link02Bk>
          <Link03Bk
            href={
              languageStoreValue === LANGUAGE.ja
                ? 'https://docs.google.com/forms/d/1FfyHPuo1VzzgqIS-DvX_17DXf5W_e4JvtJSI1ZOFdGg/edit?usp=sharing'
                : 'https://bit.ly/3hwF1D9'
            }
            target='_blank'
            rel='noopener noreferrer'
            data-lang-ru={languageStoreValue === LANGUAGE.ru}
          >
            <span>{t('footer.contact')}</span>
          </Link03Bk>
          <Link03Bk
            href='https://bit.ly/3GKLBSw'
            target='_blank'
            rel='noopener noreferrer'
            data-lang-ru={languageStoreValue === LANGUAGE.ru}
          >
            <span>{t('header.download')}</span>
          </Link03Bk>
          <ButtonBk
            type='button'
            onClick={() => setIsOpenLanguage(!isOpenLanguage)}
          >
            <span>{languageStoreValue}</span>
          </ButtonBk>
        </LinkWrapper>
      </DefaultWrapper>
      {isOpenLanguage && (
        <SetLanguage>
          {Object.values(LANGUAGE).map((lang, i) => (
            <li key={i} data-select={languageStoreValue === lang}>
              <button type='button' onClick={() => onClickLang(lang, i)}>
                {lang}
              </button>
            </li>
          ))}
        </SetLanguage>
      )}
    </Wrapper>
  );
};

export default Header;
