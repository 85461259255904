import { AvatarPaidProductType } from 'types';

/**
ショップ・マイボックスページの
アバターパーツのタイプ選択エリアに表示するパーツタイプ画像を管理するための定数
 */
export const avatarPaidProductArray: AvatarPaidProductType[] = [
  'flower',
  'skill',
  'hat',
  'hair',
  'eye',
  'mouth',
  'clothes',
];

export const skillShopProductArray: AvatarPaidProductType[] = ['skill'];
