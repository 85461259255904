import { css } from 'styled-components';
import {
  MQ_DESKTOP,
  MQ_MOBILE,
} from 'javascript/components/landingPage/lib/MQ';
import { pcPx, spp } from 'javascript/components/landingPage/lib/cssUtil';

export const BLACK = '#00010D';
export const GRAY = '#707070';
export const BLUE = '#00A0E9';

export const linkReset = css`
  display: inline-block;
  text-decoration: none;
  color: inherit;
`;

export const buttonReset = css`
  padding: 0;
  margin: 0;
  appearance: none;
  border: none;
  background: none;
  font: inherit;
  text-align: inherit;
  color: inherit;
`;

export const enThinStyle = css`
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  white-space: pre-wrap;
`;

export const enStyle = css`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  white-space: pre-wrap;
`;

export const enBoldStyle = css`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  white-space: pre-wrap;
`;

export const jpStyle = css`
  white-space: pre-wrap;
  &[data-lang-ru='true'] {
    font-family: 'Roboto', sans-serif;
  }
  &[data-lang-ru='false'] {
    font-family: 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', sans-serif;
  }
`;

export const notojpStyle = css`
  white-space: pre-wrap;
  &[data-lang-ru='true'] {
    font-family: 'Roboto', sans-serif;
  }
  &[data-lang-ru='false'] {
    font-family: 'Noto Sans JP', sans-serif;
  }
`;

export const onlySP = css`
  ${MQ_DESKTOP} {
    display: none;
  }
`;

export const onlyPC = css`
  ${MQ_MOBILE} {
    display: none;
  }
`;

export const buttonAnim = css`
  span {
    position: relative;
  }
  ${MQ_DESKTOP} {
    transition: color ease-out 0.3s;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      transition: transform 0.6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
      transform: scale(0, 1);
      transform-origin: right top;
      background: ${BLACK};
      width: 100%;
      height: 100%;
      border-radius: ${pcPx(10)};
    }
    &:hover {
      color: white;
      opacity: 1;
      &:before {
        transform-origin: left top;
        transform: scale(1, 1);
      }
    }
  }
`;

export const buttonWhAnim = css`
  ${buttonAnim}
  ${MQ_DESKTOP} {
    &:before {
      background: white;
    }
    &:hover {
      color: ${BLACK};
    }
  }
`;

export const fadeInStyle = css`
  transition: opacity 1s cubic-bezier(0.4, 0.59, 0.41, 0.97) 0s,
    transform 1s cubic-bezier(0.4, 0.59, 0.41, 0.97) 0s;
  &[data-visible='true'] {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  &[data-visible='false'] {
    transition: opacity 0s ease 0s, transform 0s ease 0s;
    opacity: 0;
    visibility: hidden;
    ${MQ_DESKTOP} {
      transform: translateY(${pcPx(50)});
    }
    ${MQ_MOBILE} {
      transform: translateY(${spp(50)});
    }
  }
`;
