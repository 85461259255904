import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { SortableVirtualWorldTitle } from 'javascript/features/virtualWorld/components/SortableVirtualWorldTitle';
import { SortableVirtualWorldMemberBox } from 'javascript/features/virtualWorld/components/SortableVirtualWorldMemberBox';
import { VirtualWorld } from 'types';

interface Props {
  virtualWorld: VirtualWorld;
  localImage: string;
  isDraggingItem?: boolean;
  isOverlayed?: boolean;
}

export const SortableVirtualWorldItem: React.FC<Props> = React.memo(
  ({ virtualWorld, localImage, isDraggingItem, isOverlayed }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: virtualWorld.id.toString() });

    return (
      <VirtualWorldItemWrapper
        ref={setNodeRef}
        style={{
          transform: CSS.Transform.toString(transform),
          transition,
        }}
        isDraggingItem={isDraggingItem}
        isOverlayed={isOverlayed}
        {...attributes}
        {...listeners}
      >
        <StyledShakerWrapper numForDelay={virtualWorld.id % 5}>
          <SortableVirtualWorldTitle
            roomType={virtualWorld.room_type}
            roomName={virtualWorld.name}
            userNum={virtualWorld.users.length + virtualWorld.guest_num}
          />
          <VirtualWorldMemberWrapper
            // @ts-ignore
            image={RailsAssetPath(localImage)}
          >
            <SortableVirtualWorldMemberBox
              users={virtualWorld.users}
              guestNum={virtualWorld.guest_num}
            />
          </VirtualWorldMemberWrapper>
        </StyledShakerWrapper>
      </VirtualWorldItemWrapper>
    );
  }
);

const shaking = keyframes`
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-1deg);
  }
  75% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0);
  }
`;

const animation = (delay: number) => css`
  animation: ${shaking} 0.3s ease ${`0.${delay}s`} infinite;
`;

const StyledShakerWrapper = styled.div<{
  numForDelay: number;
}>`
  ${({ numForDelay }) => animation(numForDelay)}
`;

const VirtualWorldItemWrapper = styled.div<{
  isDraggingItem: boolean | undefined;
  isOverlayed: boolean | undefined;
}>`
  width: 120px;
  height: 121px;
  padding: 4px 4px 0 0;
  border-radius: 8px;
  opacity: ${({ isDraggingItem }) => isDraggingItem && '0'};
  cursor: ${({ isOverlayed }) => (isOverlayed ? 'grabbing' : 'pointer')};
  &:focus-visible {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`;

const VirtualWorldMemberWrapper = styled.div<{
  image: string;
}>`
  width: 80%;
  height: 90px;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  padding: 8px 3px;
  margin-left: 12px;
`;
