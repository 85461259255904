import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useSWRConfig } from 'swr';
import { OwnedProductInMybox } from 'types';
import { AvatarOwnedSkillProductSort } from './useMybox';

export const useSellProducts = (
  products: OwnedProductInMybox[],
  avatarOwnedSkillProductSort: AvatarOwnedSkillProductSort
) => {
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();

  const queryParams = `?avatar_owned_skill_products_sort=${avatarOwnedSkillProductSort}`;

  const handleSellProducts = useCallback(async () => {
    for (const product of products) {
      const params = {
        avatar_owned_product_id: product.avatar_owned_product_id.slice(
          0,
          product.item_count
        ),
        avatar_original_product_id: null,
      };
      await axios.post<{ message: string; status: number }>(
        '/api/v1/avatar/product/sell',
        params
      );
      mutate(`/api/v1/avatar/owned_products${queryParams}`);
    };
    // TODO: i18n
    alert("商品を売却しました");
  }, [products, mutate, t]);

  return { handleSellProducts };
};
