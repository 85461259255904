import React from 'react';
import styled from 'styled-components';
import { DisplayAvatarArea } from 'javascript/features/freeSpace/components/DisplayAvatarArea';
import { OpenChatInputArea } from 'javascript/features/freeSpace/components/OpenChatInputArea';
import { useAvatars } from 'javascript/features/freeSpace/hooks/useAvatars';
import { UserAvatar } from 'types';
import { useMediaQuery } from 'react-responsive';
import { device } from 'javascript/utils/mediaQuery';

interface Props {
  userImage: string;
  avatar?: UserAvatar;
  isCast: boolean;
}

export const FreeSpaceTemplate: React.FC<Props> = React.memo(
  ({ avatar, userImage, isCast }) => {
    const isMobile = useMediaQuery({ maxWidth: 430 });

    const { avatars, handleSetLocation } = useAvatars(avatar?.id);

    const isCurrentAvatarInFreeSpace = avatars.some(
      (a) => a.avatar_id === avatar?.id
    );

    return (
      <>
        <StyledFreeSpace>
          <DisplayAvatarArea
            avatars={avatars}
            currentAvatar={avatar}
            handleSetLocation={handleSetLocation}
            isCurrentAvatarInFreeSpace={isCurrentAvatarInFreeSpace}
            isWorld={false}
            isCast={isCast}
          />
        </StyledFreeSpace>
        {!isMobile && (
          <OpenChatInputArea
            avatar={avatar}
            userImage={userImage}
            isCurrentAvatarInFreeSpace={isCurrentAvatarInFreeSpace}
          />
        )}
      </>
    );
  }
);

const StyledFreeSpace = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  @media ${device.sp} {
    position: relative;
  }
`;
