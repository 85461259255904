import React from 'react';
import { useGlobal } from 'reactn';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { sp } from '../../../utils/mediaQuery';
import { useEditRealWorld } from '../hooks/useEditRealWorld';
import { useTranslation } from 'react-i18next';

const FormCreateRealWorld = ({ roomId }) => {
  const [_, setIsShowEditRealWorldModal] = useGlobal(
    'isShowEditRealWorldModal'
  );
  const { roomDetail, handleChangeValue, handleSubmit, isSubmitting } =
    useEditRealWorld(roomId);
  const isDisabled = !roomDetail.name || isSubmitting;
  const { t } = useTranslation();

  return (
    <FormCreateRealWorldWrapper>
      <ModalCloseIcon
        onClick={() => {
          setIsShowEditRealWorldModal(false);
        }}
        src={RailsAssetPath('close-img-gray.png')}
      />
      <RealWorldSettingTitleArea>
        <p>{t('RoomTextRealSetting', {})}</p>
      </RealWorldSettingTitleArea>
      <StyledIconWrapper>
        <img src={RailsAssetPath('enter_modal/icon_status.png')} />
      </StyledIconWrapper>
      <form onSubmit={(e) => handleSubmit(e)}>
        <RealWorldNameInput
          defaultValue={roomDetail.name !== 'null' ? roomDetail.name : ''}
          type='text'
          name='name'
          placeholder={t('RoomTextRealName', {})}
          onChange={handleChangeValue}
        />
        <RealWorldDescriptionInput
          rows={3}
          defaultValue={roomDetail.note !== 'null' ? roomDetail.note : ''}
          type='text'
          placeholder={t('GenelalTextDescription', {})}
          name='note'
          onChange={handleChangeValue}
        />
        <FormSubmitButton type='submit' disabled={isDisabled}>
          {roomId ? t('GenelalButtonSave', {}) : t('GenelalButtonAdd', {})}
        </FormSubmitButton>
      </form>
    </FormCreateRealWorldWrapper>
  );
};

const FormCreateRealWorldWrapper = styled.div`
  margin: auto;
  text-align: center;
  padding: 30px;
  font-size: 11px;
  min-height: 400px;
  ${sp`
    overflow: auto;
    margin-top: 40px;
    padding: 0 30px 30px;
  `}
`;

const ModalCloseIcon = styled.img`
  position: absolute;
  top: 27px;
  font-size: 25px;
  cursor: pointer;
  width: 19px;
  ${sp`
    top: 15px;
    left: 19px;
    width: 15px;
  `}
`;

const RealWorldSettingTitleArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  p {
    color: #303030;
    font-size: 16px;
  }
`;

const StyledIconWrapper = styled.div`
  margin-top: 16px;
  img {
    width: 56px;
  }
`;

const RealWorldNameInput = styled.input`
  height: 48px;
  width: 100%;
  background: #f5f5f5;
  font-size: 16px;
  border-radius: 8px;
  margin-top: 24px;
  line-height: 37px;
  padding: 0 20px;
`;

const RealWorldDescriptionInput = styled.textarea`
  font-size: 16px;
  height: 120px;
  margin-top: 32px;
  padding: 16px 20px;
  width: 100%;
  background: #f5f5f5;
  border-radius: 8px;
  resize: none;
  ${sp`
    font-size: 14px;
    height: 110px;
  `}
`;

const FormSubmitButton = styled.button`
  background: ${({ disabled }) => (disabled ? '#b6b6b6' : '#03A9F4')};
  color: white;
  font-size: 12px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  margin-top: 48px;
  padding: 8px 24px;
  width: 160px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  ${({ disabled }) =>
    !disabled &&
    `
    &:hover {
      transform: translate(-1px, -1px);
      box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 5%), 4px 4px 4px 0px rgb(0 0 0 / 5%);
    }
  `}
`;

const mapStateToProps = (state) => {
  return {
    roomId: state.setRooms.id,
  };
};

export default connect(mapStateToProps)(FormCreateRealWorld);
