import React, { useEffect, useRef, useState } from 'react';

// refを渡した場合、そのrefの範囲内をクリックした場合のみポップアップが閉じなくなる。
// refを渡さなかった場合、どの場所を押してもポップアップが閉じる
export const useIsShowPopup = (ref?: React.RefObject<HTMLElement>) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickScreen = useRef<HTMLElement>(null);

  useEffect(() => {
    // @ts-ignore
    handleClickScreen.current = (e) => {
      if (!ref?.current?.contains(e.target)) {
        setIsOpen(false);
        // @ts-ignore
        document.removeEventListener('click', handleClickScreen.current);
      }
    };
  }, [ref]);

  useEffect(() => {
    // @ts-ignore
    isOpen && document.addEventListener('click', handleClickScreen.current);
  }, [isOpen]);

  return { isOpen, setIsOpen };
};
