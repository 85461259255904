import { ThemeProvider, makeStyles, createMuiTheme } from '@material-ui/core/styles';

export const FORM_TYPES = {
  CHARGE: 1,
  SUBSCRIPTION_BUY: 2,
  SUBSCRIPTION_UPDATE: 3,
}

export const BUY_TYPES = {
  SUBSCRIPTION: "subscription",
  CHARGE: "charge", 
}

export const PAYMENT_METHOD_STEPS = {
  FIRST_STEP: 0,
  CARD_SELECT: 1,
  CARD_NUMBER_INPUT: 2,
}

export const SUBSCRIPTION_CANCEL_OTHER_REASON_ID = 6;

export const MUI_THEME = createMuiTheme({
  palette: {
    primary: {
      main: '#00A0E9',
      contrastText: '#fff',
    },
    secondary: {
      main: '#EC6941',
      contrastText: '#fff',
    }
  },
  typography: {
  },
  overrides: {
    MuiDialog: {
      paper: {
        '@media (max-width: 480px)': {
          minWidth: '90%'
        }
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '16px 24px 0px 24px',
      }
    },
    MuiDialogContent:{ 
      root: {
        paddingBottom: '40px',

      },

    },
    MuiIconButton: {
      root: {
        fontSize: '1rem',
        padding: 6,
        // transform: 'scale(1.1, 1)',
      }
    },
    MuiGrid: {
      root: {
        fontSize:15,
      }
    },
    MuiTab:{
      root: {
        '@media (min-width: 375px)': {
          minWidth: '50%',
        }
      }
    },
    MuiLink: {
      underlineHover: {
        cursor: 'pointer'
      } 
    },
    MuiTypography: {
      root: {
       },
      h5:{
        fontSize:18,
        fontWeight:"bold",
      },
      h6: {
        fontSize:16,
        color: '#707070',
      },
      subtitle1: {
        margin: '10px 0',
        fontSize:20,
        fontWeight: 'bold',
      },
      subtitle2: {
        margin: '10px 0',
        fontSize:16,
        fontWeight: 'bold',
      },
      caption: {
        fontSize: '0.55rem',
        color: 'gray',
      },
      body1: {
        fontSize: '0.9rem',
      }
    },
  }
});

