import React from 'react';
import styled from 'styled-components';
import { device } from 'javascript/utils/mediaQuery';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

interface Props {
  avatarId: number;
}

interface ViketMonthlyRanking {
  monthly_get_viket: number;
  month: string;
  viket_ranking: number;
}


export const ViketMonthlyRanking: React.FC<Props> = React.memo(({ avatarId }) => {
  const { t } = useTranslation();
  const data = axios.get(`/api/v1/avatars/viket_monthly_ranking/${avatarId}`)
  const [viketMonthlyRanking, setViketMonthlyRanking] = React.useState<ViketMonthlyRanking[]>([])

  React.useEffect(() => {
    data.then((res) => {
      setViketMonthlyRanking(res.data.rankings)
    })
  }, [])

  return (
    <>
      <StyledViketHistory id='historyTarget'>
        <StyledLabel>{t("MonthlyRanking")}・{t("MonthlyGotViketNumber")}</StyledLabel>
        <StyledViketHistoryList>
          {viketMonthlyRanking.length !== 0 ? (
            viketMonthlyRanking.map((item) => (
              <StyledViketHistoryItem key={item.month}>
                <StyledDate>{item.month}</StyledDate>
                <StyledViketRankingBox>
                  {/* @ts-ignore */}
                  <StyledViketIcon src={RailsAssetPath('freeSpace/vike_ranking.png')} width={22}/>
                  <StyledViketCount>
                    {item.viket_ranking}位
                  </StyledViketCount>
                </StyledViketRankingBox>
                <StyledViketRankingBox>
                  {/* @ts-ignore */}
                  <StyledViketIcon src={RailsAssetPath('mypage/coin.png')} width={16}/>
                  <StyledViketCount>
                    {item.monthly_get_viket > 0 ? `+${item.monthly_get_viket}` : `-${item.monthly_get_viket}`}
                  </StyledViketCount>
                </StyledViketRankingBox>
              </StyledViketHistoryItem>
            ))
          ) : (
            <p>{t("ViketTextNoHistory", {})}</p>
          )}
        </StyledViketHistoryList>
      </StyledViketHistory>
    </>
  );
});

const StyledViketHistory = styled.div`
  margin-top: 32px;
`;

const StyledLabel = styled.div`
  padding: 0 0 16px 24px;
  border-bottom: 2px solid #ddd;
  font-weight: bold;
  @media ${device.sp} {
    padding-left: 16px;
  }
`;

const StyledViketHistoryList = styled.div`
  max-height: 100px;
  margin-top: 32px;
  padding: 0 24px;
  overflow: scroll;
`;

const StyledViketHistoryItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const StyledDate = styled.p`
  font-weight: 300;
`;

const StyledViketRankingBox = styled.div`
  display: flex;
  align-items: center;
`

const StyledViketIcon = styled.img`
  width: ${(props) => props.width}px;
`;

const StyledViketCount = styled.p`
  margin-left: 8px;
  font-size: 14px;
`;
