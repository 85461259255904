import { useState, useCallback } from 'react';
import axios from 'axios';

const useLeaveCall = () => {
  const [state, setState] = useState(false);

  const setIsDisconnecting = useCallback(() => {
    setState(true);
  }, []);

  const leave = useCallback(
    async (room: any, isCallOverByUser: boolean = false) => {
      setIsDisconnecting();
      await room.disconnect();
      if (isCallOverByUser) {
        await axios.post('/api/v1/twilio/voices/room_leave', {
          end_call: true,
        });
      } else {
        await axios.post('/api/v1/twilio/voices/room_leave');
      }
    },
    []
  );

  const isDisconnecting = state;

  return { leave, isDisconnecting, setIsDisconnecting };
};

export default useLeaveCall;
