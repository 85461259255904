import { useCallback } from 'react';
import axios from 'axios';
import useSWR, { useSWRConfig } from 'swr';
import { UserProfile } from 'types';
import { useLocation } from 'react-router-dom';

export const useUserProfile = (userId: number | undefined) => {
  const { mutate } = useSWRConfig();

  const search = useLocation().search;
  const query2 = new URLSearchParams(search);

  const fetcher = (url: string) =>
    axios.get<UserProfile>(url).then(({ data }) => data);
  const { data: userData, mutate: localMutate } = useSWR(
    userId ? `/api/v1/users/${userId}?sort_id=${query2.get('sort_id')}&select_id=${query2.get('select_id')}` : null,
    fetcher
  );

  const handleGetUserData = useCallback((id: number) => {
    mutate(`/api/v1/users/${id}`);
  }, []);

  const setUserProfileData = (userData: UserProfile) => {
    localMutate(userData, false);
  };

  return { userData, handleGetUserData, setUserProfileData };
};
