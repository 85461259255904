import { useCallback , useState } from 'react';
import { useGlobal } from 'reactn';
import axios from 'axios';
import useVoiceContext from 'javascript/components/voice/hooks/useVoiceContext';

interface RoomEnterParams {
  twilio_room_id?: number | null;
  remorks_room_id?: number | null;
  is_direct_call?: boolean 
}


export const useVoice = () => {
  const { room, connect } = useVoiceContext();
  const [ token , setToken ] = useState<string>('');
  const [ roomName, setRoomName] = useGlobal<{roomName: string }, 'roomName'>('roomName');

  const handleVoiceConnect = useCallback(async (
    params: RoomEnterParams) => {
    if (room) return {room_name: room.name};
    let data = {token: "", room_name: ""};
    let result = await getToken(params).then(res => {
      data = res.data
      setToken(data.token);
      setRoomName(data.room_name);
      connect(data.token, data.room_name);
      return data;
    })
    return result;
  }, [room, roomName, connect])

  const handleVoiceDisconnect = useCallback(async(
  ) => {
    if (room) {
      await room.disconnect();
      await roomLeave();
     }
  },[room, setRoomName])

  const getToken = useCallback(async(
   params: RoomEnterParams 
  ) => {
    return await axios.post('/api/v1/twilio/voices/room_enter', params)
  }, [])

  const roomLeave = useCallback(async(
  ) => {
    return await axios.post('/api/v1/twilio/voices/room_leave', {
      end_call: true,
    }).then(() => {
      setRoomName('');
    }); 
  }, [roomName, setRoomName])
  return {
    handleVoiceConnect,
    handleVoiceDisconnect,
    getToken,
    token,
    roomName,
    setRoomName,
  }
}
