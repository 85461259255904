import { useState, useCallback } from "react";
import Video, { createLocalTracks } from "twilio-video";
import { useTranslation } from 'react-i18next';

const useRoom = () => {

  const [room, setRoom] = useState(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const { t } = useTranslation();

  const connect = useCallback((token, roomName) => {
    setIsConnecting(true);
    return Video.connect(token, { name: roomName, audio: true, video: false }).then(
      newRoom => {
        console.log('connected');
        setRoom(newRoom);
        const disconnect = () => newRoom.disconnect();

        newRoom.once('disconnected', () => {
          console.log('disconnected');
          setTimeout(() => setRoom(null));
          window.removeEventListener('beforeunload', disconnect);
        });
      }
    ),
    error => {
      alert(t("RoomAlertCantConnect",{}));
      setIsConnecting(false);
    }
    // createLocalTracks(constraints).then(localTracks => {

    // });
  });

  return { room, connect, isConnecting };
}

export default useRoom;