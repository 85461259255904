import React, {useEffect, useState, useRef} from 'react';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import AvatarIcon from './icon/AvatarIcon';
import useVideoCallContext from './hooks/useVideoCallContext';
import useScreenShareParticipant from './hooks/useScreenShareParticipant';
import usePublications from './hooks/usePublications';
import useTrack from './hooks/useTrack';
import useIsTrackSwitchedOff from './hooks/useIsTrackSwitchedOff';
import useParticipantIsReconnecting from './hooks/useParticipantIsReconnecting';
import useWindowDimensions from './hooks/useWindowDimentions';
import { useMediaQuery } from 'react-responsive';

const MainParticipantInfo = ({ participant , children}) => {

  const { room, localTracks, isSharingScreen } = useVideoCallContext();
  const localParticipant = room.localParticipant;
  const isLocal = localParticipant === participant;

  const screenShareParticipant = useScreenShareParticipant();
  const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;
  const publications = usePublications(participant);

  const videoPublication = publications.find(p => !p.trackName.includes('screen') && p.kind === 'video');
  const screenSharePublication = publications.find(p => p.trackName.includes('screen'));

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoEnabled = Boolean(videoTrack);

  const audioPublication = publications.find(p => p.kind === 'audio');
  // const audioTrack = useTrack(audioPublication);

  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack);
  // const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const ref = useRef();
  const windowDimensions = useWindowDimensions();
  const [screenWidth, setScreenWidth] = useState();
  const [screenHeight, setScreenHeight] = useState();
  const isMobile = useMediaQuery({ maxWidth: 430 });

  useEffect(() => {
    setScreenHeight(windowDimensions.height * (isMobile ? 0.7: 0.8))
    setScreenWidth(screenHeight * (16/9))
   },[windowDimensions, isSharingScreen, screenHeight])

  return (
    <Container ref={ref} screenHeight={screenHeight} screenWidth={screenWidth}
      data-cy-main-participant
      data-cy-participant={participant.identity}
      >
      {/* {(!isVideoEnabled || isVideoSwitchedOff) && (
        <div>
          アバター
          <AvatarIcon />
        </div>
      )} */}
      {children}
    </Container>
  )
}

const Container = styled.div`
  height: ${props => props.screenHeight}px;
  width: ${props => props.screenWidth}px;
  margin: 0 auto;
  max-width:100%;
`

export default MainParticipantInfo;