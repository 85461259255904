import axios from 'axios';
import useSWR from 'swr';
import { AvatarPaidProductType, ShopList } from 'types';

type Props = {
  selectedShopProductType: AvatarPaidProductType;
  avatarId?: number;
  isSkillShop?: boolean;
  isWorld?: boolean;
  sortType?: number;
};

export const useShopProductList = ({
  selectedShopProductType,
  avatarId,
  isSkillShop,
  isWorld,
  sortType,
}: Props) => {
  const fetcher = (url: string) =>
    axios
      .get<ShopList>(url, {
        params: {
          is_skill_shop: isSkillShop,
          avatar_id: avatarId,
          isWorld: isWorld,
        },
      })
      .then(({ data }) => data);

  const queryParams = `?sort=${sortType}`
  const { data } = useSWR(`/api/v1/avatar/products${queryParams}`, fetcher);

  const fixShownProductList = (
    productType: AvatarPaidProductType,
    list: ShopList | undefined
  ) => {
    if (!list) return;
    switch (productType) {
      case 'hat':
        return list.paid_products[1];
      case 'hair':
        return list.paid_products[2];
      case 'eye':
        return list.paid_products[3];
      case 'mouth':
        return list.paid_products[5];
      case 'clothes':
        return list.paid_products[7];
      case 'flower':
        return list.paid_products[9];
      case 'skill':
        return list.paid_skill_products[1];
      default:
        return null;
    }
  };

  const fixedShownProductList = fixShownProductList(
    selectedShopProductType,
    data
  );

  return { fixedShownProductList };
};
