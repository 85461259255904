import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  MQ_DESKTOP,
  MQ_MOBILE,
} from 'javascript/components/landingPage/lib/MQ';
import { pcPx, spp } from 'javascript/components/landingPage/lib/cssUtil';
import {
  BLACK,
  fadeInStyle,
  jpStyle,
} from 'javascript/components/landingPage/local/commonCss';
import {
  onScrollPromise,
  removeOnscrollEvent,
} from 'javascript/components/landingPage/local/scrollPromise';
import {
  LANGUAGE,
  useLanguageStoreValue,
} from 'javascript/components/landingPage/store/language';

// @ts-ignore
const ASSETS_IMG = RailsAssetPath('lp/magic/img.png');
// @ts-ignore
const ASSETS_IMG_PC = RailsAssetPath('lp/magic/img_pc.png');

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  ${MQ_DESKTOP} {
    &:before {
      content: '';
      background-color: rgba(255, 255, 255, 0.39);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: ${pcPx(1800)};
      height: 100%;
      top: 0;
    }
  }
`;

const Title = styled.h3`
  ${jpStyle}
  ${fadeInStyle}
  color: ${BLACK};
  font-weight: bold;
  position: relative;
  text-align: center;
  overflow: visible;
  ${MQ_MOBILE} {
    font-size: ${spp(26)};
    line-height: ${spp(45)};
    margin-top: ${spp(70)};
    margin-bottom: ${spp(35)};
    padding: 0 ${spp(20)};
    padding-bottom: ${spp(35)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(36)};
    line-height: ${pcPx(80)};
    margin-top: ${pcPx(100)};
    margin-bottom: ${pcPx(35)};
    padding-bottom: ${pcPx(30)};
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    background-color: ${BLACK};
    ${MQ_MOBILE} {
      width: ${spp(103)};
      height: ${spp(4)};
      bottom: ${spp(4)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(176)};
      height: ${pcPx(4)};
      bottom: ${pcPx(4)};
    }
  }
`;

const Text = styled.p`
  ${fadeInStyle}
  ${jpStyle}
  color: ${BLACK};
  position: relative;
  text-align: center;
  overflow: visible;
  transition-delay: 0.1s;
  white-space: pre-wrap;
  ${MQ_MOBILE} {
    font-size: ${spp(18)};
    line-height: ${spp(31)};
    margin-top: ${spp(10)};
    margin-bottom: ${spp(40)};
    padding: 0 ${spp(32.5)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(24)};
    line-height: ${pcPx(41)};
    margin-top: ${pcPx(5)};
    padding-bottom: ${pcPx(40)};
    margin-bottom: ${pcPx(0)};
  }
`;

const Img = styled.div`
  ${fadeInStyle}
  display: block;
  margin: auto;
  position: relative;
  ${MQ_MOBILE} {
    width: ${spp(714)};
    height: ${spp(242)};
    background: url(${ASSETS_IMG}) no-repeat;
    background-size: contain;
    margin-top: ${spp(60)};
    margin-bottom: ${spp(0)};
  }
  ${MQ_DESKTOP} {
    width: ${pcPx(1290)};
    height: ${pcPx(446)};
    background: url(${ASSETS_IMG_PC}) no-repeat;
    background-size: contain;
    margin-bottom: ${pcPx(190)};
  }
`;

const Magic = () => {
  const fvRef = useRef<HTMLDivElement | null>(null);
  const imgRef = useRef<HTMLDivElement | null>(null);
  const [isFvRefScrolled, setIsFvRefScrolled] = useState(false);
  const [isImgRefScrolled, setIsImgRefScrolled] = useState(false);
  const languageStoreValue = useLanguageStoreValue();
  const { t } = useTranslation();

  useEffect(() => {
    onScrollPromise(fvRef.current)().then(() => setIsFvRefScrolled(true));
    onScrollPromise(imgRef.current)().then(() => setIsImgRefScrolled(true));
    return () => {
      removeOnscrollEvent(fvRef.current);
      removeOnscrollEvent(imgRef.current);
    };
  }, []);

  return (
    <Wrapper>
      <Title
        ref={fvRef}
        data-visible={isFvRefScrolled}
        data-lang-ru={languageStoreValue === LANGUAGE.ru}
      >
        {t('magic.title')}
      </Title>
      <Text
        data-visible={isFvRefScrolled}
        data-lang-ru={languageStoreValue === LANGUAGE.ru}
      >
        {t('magic.text')}
      </Text>
      <Img ref={imgRef} data-visible={isImgRefScrolled} />
    </Wrapper>
  );
};

export default Magic;
