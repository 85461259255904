import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useScrum } from 'javascript/features/scrum/hooks/useScrum';
import { device } from 'javascript/utils/mediaQuery';

const StyledMonthlyViKeRanking = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const StyledTitle = styled.p`
  font-size: 14px;
`;

const StyledInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 259px;
  width: 100%;
  margin-top: 8px;
  padding: 0 0 24px 24px;
  border-radius: 8px;
  background-color: #fff;
  @media ${device.tab} {
    order: 2;
    padding: 8px 0 8px 12px;
  }
`;

const StyledInnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 24px;
  padding-right: 32px;
  overflow: scroll;
  @media ${device.tab} {
    padding-top: 16px;
  }
`;

const StyledBarWrapper = styled.ul`
  position: relative;
  li {
    display: flex;
    align-items: center;
    height: 30px;
  }
`;

const StyledTeamNameWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 20%;
  height: 100%;
  padding-right: 16px;
  border-right: 1px solid #dedede;
  p {
    display: -webkit-box;
    width: 100%;
    font-size: 10px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
  }
  @media ${device.tab} {
    padding-right: 8px;
  }
`;

const StyledBarItemWrapper = styled.div`
  width: 80%;
  height: 20px;
`;

const StyledBarItem = styled.div<{ percent: number; modulo: number }>`
  position: relative;
  width: ${({ percent }) => `${percent}%`};
  height: 100%;
  background-color: ${({ modulo }) =>
    modulo === 0 ? '#d0f25f' : modulo === 1 ? '#8ce3f8' : '#ff8080'};
  z-index: 2;
`;

const StyledScaleValues = styled.div<{ percent: number }>`
  position: absolute;
  top: 0;
  left: ${({ percent }) =>
    percent !== 100 ? `${percent}%` : 'calc(100% - 1px)'};
  height: 100%;
  width: 1px;
  border-right: 1px solid #dedede;
`;

const StyledScaleNumber = styled.p<{ percent: number }>`
  position: absolute;
  top: -16px;
  left: ${({ percent }) =>
    percent !== 100 ? `${percent}%` : 'calc(100% - 1px)'};
  transform: translateX(-50%);
  color: #aaa;
  font-size: 10px;
`;

const StyledNoData = styled.p`
  font-size: 14px;
`;

const StyledRankingDescription = styled.div`
  margin-top: 8px;
  color: #999;
  font-size: 12px;
  p {
    display: flex;
    align-items: center;
    img {
      width: 16px;
      margin: 0;
      margin-right: 4px;
    }
  }
`;

type Props = {
  userId: number | undefined;
};

export const MonthlyViKeRanking: React.FC<Props> = ({ userId }) => {
  const { t } = useTranslation();
  const { scrum } = useScrum(userId);

  // ランキング1位のビケ数から最も近い500の倍数(MAX値は500単位になるようにする)を算出
  const maxScale = useMemo(
    () => scrum?.ranking[0] && Math.ceil(scrum.ranking[0].vike / 500) * 500,
    [scrum?.ranking[0]]
  );

  return (
    <StyledMonthlyViKeRanking>
      <StyledTitle>{t('EmployeeScrumRankingtitle')}</StyledTitle>
      <StyledInfoWrapper>
        <StyledInnerWrapper>
          {scrum?.ranking && (
            <StyledBarWrapper>
              {scrum.ranking.length !== 0 ? (
                scrum.ranking.map((team, i) => (
                  <li key={team.id}>
                    <StyledTeamNameWrapper>
                      <p>{team.name}</p>
                    </StyledTeamNameWrapper>
                    <StyledBarItemWrapper>
                      {maxScale && (
                        <StyledBarItem
                          modulo={i % 3}
                          percent={(team.vike / maxScale) * 100}
                        >
                          {''}
                        </StyledBarItem>
                      )}
                    </StyledBarItemWrapper>
                    {/* 親liのwidth100%のうち、20％まではチーム名が専有しているため、残り80%を5分割し、16%ずつを一つの目盛りの値とする */}
                    <StyledScaleValues percent={36} />
                    <StyledScaleValues percent={52} />
                    <StyledScaleValues percent={68} />
                    <StyledScaleValues percent={84} />
                    <StyledScaleValues percent={100} />
                    {i === 0 && maxScale ? (
                      <>
                        <StyledScaleNumber percent={36}>
                          {maxScale / 5}
                        </StyledScaleNumber>
                        <StyledScaleNumber percent={52}>
                          {(maxScale / 5) * 2}
                        </StyledScaleNumber>
                        <StyledScaleNumber percent={68}>
                          {(maxScale / 5) * 3}
                        </StyledScaleNumber>
                        <StyledScaleNumber percent={84}>
                          {(maxScale / 5) * 4}
                        </StyledScaleNumber>
                        <StyledScaleNumber percent={100}>
                          {maxScale}
                        </StyledScaleNumber>
                      </>
                    ) : <></>}
                  </li>
                ))
              ) : (
                <StyledNoData>{t('EmployeeScrumNodatafound')}</StyledNoData>
              )}
            </StyledBarWrapper>
          )}
        </StyledInnerWrapper>
      </StyledInfoWrapper>
      <StyledRankingDescription>
        <p>
          {/* @ts-ignore */}
          <img src={RailsAssetPath('question.png')} alt='' />
          {t('EmployeeScrumAboutgraph')}
        </p>
        <p>{t('EmployeeScrumGraphdescription')}</p>
      </StyledRankingDescription>
    </StyledMonthlyViKeRanking>
  );
};
