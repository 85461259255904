import { useCallback, useState } from 'react';
import { hairColorArray } from 'javascript/features/avatarParts/constants/hairColor';
import { hairColor } from 'types';

export const useLocalHairColor = (fetchedhairColor: number) => {
  const [localHairColor, setLocalHairColor] = useState<hairColor>(
    hairColorArray[fetchedhairColor - 1]
  );

  const selectLocalHairColor = useCallback(
    (hairColor: hairColor) => {
      setLocalHairColor(hairColor);
    },
    [setLocalHairColor]
  );

  return { localHairColor, selectLocalHairColor };
};
