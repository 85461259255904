import React, { useState } from 'react';
import { useGlobal } from 'reactn';
import Clock from '../items/Clock';
import axios from 'axios';
import store from '../../lib/redux/store';
import styled, { css } from 'styled-components';
import { fetchUserData } from '../../lib/redux/actions/user';
import { sp } from '../../utils/mediaQuery';
import { useTranslation } from 'react-i18next';

const FormWorkFinish = ({ user, todayDetailList }) => {
  const scheduledTimes = user.scheduled_times;

  if (!scheduledTimes || scheduledTimes == undefined) {
    return false;
  }

  const [schedule, setSchedule] = useState(user.scheduled_times);
  const [a, setA] = useState('');
  const [b, setB] = useState('');
  const [todoImage, setTodoImage] = useState([]);
  const [previewUrlList, setPreviewUrlList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ interactWorldId ] = useGlobal('interactWorldId');

  const [remainingTime, setRemainingTime] = useGlobal('remainingTime');
  const [isShowModal, setIsShowModal] = useGlobal('isShowModal');

  const finishTime = schedule.finish_at;
  const paddingBraktimeBegin = ('00' + finishTime.minutes).slice(-2);
  const scheduledFinishTime = `${finishTime.hours}:${paddingBraktimeBegin}`;

  const breaktimeBegin = schedule.break_begins_at;
  const paddingBraktimeBeginMinutes = ('00' + breaktimeBegin.minutes).slice(-2);
  const scheduledBreakTime = `${breaktimeBegin.hours}:${paddingBraktimeBeginMinutes}`;

  const defaultTitle = 'title';
  const defaultContent = '';
  const defaultImage = [];
  const { t } = useTranslation();

  //TODO 12時以降の関数がちょっと微妙だったのでなおす
  const checkExtend = (text = null) => {

    console.log('checkExtend');

    if (checkToday(text)) {
      let finishTime = new Date();
      finishTime.setMinutes(schedule.finish_at.minutes);
      finishTime.setHours(schedule.finish_at.hours);
      let nowTime = new Date();
      let ms = finishTime - nowTime;
      let minutes = Math.floor(ms / 60000);
      if (minutes < 30 && minutes > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const checkToday = (text = null) => {

    console.log('checkToday');

    let TodaylimitTime = new Date();
    TodaylimitTime.setHours(23);
    TodaylimitTime.setMinutes(59);
    TodaylimitTime.setSeconds(59);

    // 今の時間じゃなくて、今の退勤時間 + 30分
    let nextExtendedTime = new Date();
    nextExtendedTime.setHours(schedule.finish_at.hours);
    nextExtendedTime.setMinutes(schedule.finish_at.minutes + 30);

    let lest = TodaylimitTime - nextExtendedTime;
    let minutesLeft = Math.floor(lest / 60000);
    if (minutesLeft >= 0) {
      return true;
    } else {
      text && alert(text);
      return false;
    }
  };

  const handleExtend = () => {

    console.log('handleExtend');

    if (checkExtend(t('WorkFinishAlertNoExtentionTime', {}))) {
      // if (checkToday()) {
      const value = 30;
      const url = '/api/v1/users/me/attendances/finish/extend';
      axios
        .patch(url, { minutes: value })
        .then((res) => {
          setSchedule(res.data.scheduled_times);
          setRemainingTime(res.data.remaining_times);
          updateUserInfo(); //こいつ追加
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      if (checkToday()) {
        handleExceptExtend();
      }
    }
  };

  const ok = () => {
    return true;
  };

  const updateUserInfo = () => {
    store.dispatch(fetchUserData());
  };

  const handleFinish = () => {
    alert(t('WorkFinishMessageLeaveWork', {}));
    updateUserInfo();
    setIsShowModal(false);
    window.location.reload();
  };

  const handleChangeB = (e) => {
    setB(e.target.value);
  };

  const onChangeUpload = (e) => {
    if (e.target.files[0]) {
      defaultImage.push([e.target.files[0]]);
      todoImage.push(e.target.files[0]); //これを送信している

      var reader = new FileReader();

      reader.onload = function (e) {
        setPreviewUrlList([...previewUrlList, { image: e.target.result }]);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const deleteImage = (index) => {
    let newUrl = [];
    let num = index;
    let arr = previewUrlList;
    arr.splice(num, 1);
    todoImage.splice(num, 1);
    for (let i = 0; i < arr.length; i++) {
      newUrl.push({ image: arr[i].image });
    }
    setPreviewUrlList(newUrl);
  };

  const handleSubmit = () => {
    setLoading(false);
    const url = '/api/v1/users/me/attendances/finish';
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const data = {
      title: defaultTitle || a,
      content: defaultContent || b,
      images: todoImage,
    };
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v, i) => {
          formData.append(key + '[]', v); // arrayデータを分割して入れ直す
        });
      } else {
        formData.append(key, value);
      }
    });
    formData.append('interact_world_id', interactWorldId);

    const isConfirmed = window.confirm(
      t('WorkFinishMessageLeaveWorkConfirm', {})
    );
    if (isConfirmed) {
      axios
        .patch(url, formData, config)
        .then((res) => {
          setLoading(true);
          handleFinish();
        })
        .catch((error) => {
          setLoading(true);
          const msg = error.response.data.message;
          if (msg) {
            alert(msg);
          }
        });
    } else {
      setLoading(true);
      return;
    }
  };

  const handleExceptExtend = () => {
    alert(t('WorkFinishAlertExtentionTime30Min', {}));
  };

  const handleExceptExtendOnlyToday = () => {
    alert(t('WorkFinishAlertNoExtentionTime', {}));
  };

  const FinishExtendTimeBtn = styled.div`
    background: white;
    border: 1px solid #535353;
    border-radius: 7px;
    color: #535353;
    padding: 2px 10px;
    ${({ props }) =>
      props &&
      `
      border: 1px solid #00a0e9;
      color: #00a0e9;
    `}
  `;

  return (
    <div className='modal__background'>
      <ModalWrapper>
        <p id='DateTimeDisp' className='modal__hour'>
          <Clock />
        </p>
        <div className='finishtime__box'>
          <div className='finish-rest-time'>
            <p className='text-xl'>▼本日の作業状況</p>
            <OfficeMembers>
              {(todayDetailList.length || todayDetailList.content != undefined )? ( 
                todayDetailList.content.map((u, key) => (
                  <p>{u.time} {u.name}</p>
              ))
              ) : (
                <div></div>
              )}
          </OfficeMembers>
            
          </div>
        </div>
        <div>
          <textarea
            className='textarea padding'
            style={{ width: '100%', color: 'black', height: '140px' }}
            name='detail'
            rows='9'
            onChange={handleChangeB}
            value={b}
            placeholder='Comment'
          ></textarea>
        </div>
        <div className='' id='preview'>
          {previewUrlList.map((i, key) => (
            <div key={key} className='preview-wrapper'>
              <div className='preview-button' onClick={() => deleteImage(key)}>
                ×
              </div>
              <img className='preview-img' src={i.image} alt='preview' />
            </div>
          ))}
        </div>
        <div style={{ width: '50px' }}>
          <label className='input-file-label' htmlFor='todofileUpload'>
            <input
              id='todofileUpload'
              accept='image/*'
              className='inputImage'
              name='images'
              type='file'
              onChange={onChangeUpload}
            />
          </label>
        </div>
        {loading && (
          <div>
            <button
              className='finishwork btn'
              type='submit'
              onClick={handleSubmit}
            ></button>
          </div>
        )}
      </ModalWrapper>
    </div>
  );
};

const ModalWrapper = styled.div`
  overflow-y: scroll;
  height: 500px;
  margin: auto;
  text-align: center;
  color: #535353;
  letter-spacing: 0.2em;
  font-size: 12px;
  ${sp`
    height: auto;
    overflow: visible;
  `}
`;

const OfficeMembers = styled.div`
  margin-top: 20px;
  text-align: left;
  overflow: auto;
`;

export default FormWorkFinish;
