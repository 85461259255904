import React from 'react';
import styled from 'styled-components';
import { SCALE_VALUE_ARRAY } from 'javascript/features/stayHistory/constants';

const StyledScaleValues = styled.div<{ percent: number }>`
  position: absolute;
  top: 0;
  left: ${({ percent }) =>
    percent !== 100 ? `${percent}%` : 'calc(100% - 1px)'};
  height: 100%;
  width: 1px;
  border-right: 1px solid #dedede;
`;

export const ScaleValues = () => (
  <>
    {SCALE_VALUE_ARRAY.map((val) => (
      <StyledScaleValues key={val} percent={val} />
    ))}
  </>
);
