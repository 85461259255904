import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import 'react-image-lightbox/style.css';
import { sp } from '../../utils/mediaQuery';
import { useTranslation } from 'react-i18next';
import Linkify from 'react-linkify';

const DiaryContent = ({ dataSet }) => {
  const [closeText, setCloseText] = useState(false);
  const elm = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    setLineClamp();
  }, [dataSet]);

  const setLineClamp = () => {
    const targetRef = elm.current;
    const elmH = targetRef.getBoundingClientRect().height;
    const maxHeight = 128;
    if (elmH > maxHeight) {
      setCloseText(true);
    }
  };

  const handleChangeText = () => {
    setCloseText(false);
  };

  return (
    <>
      <Linkify
        componentDecorator={(href, text, key) => (
          <a target='_blank' href={href} key={key}>
            {text}
          </a>
        )}
      >
        <DiaryContentBody isHidden={closeText} ref={elm}>
          {dataSet}
        </DiaryContentBody>
      </Linkify>
      {closeText && (
        <DairyContentReadMore onClick={handleChangeText}>
          <DairyContentReadMoreImg>
            {t('DiaryButtonMore', {})}
          </DairyContentReadMoreImg>
        </DairyContentReadMore>
      )}
    </>
  );
};

const DiaryContentBody = styled.div`
  word-break: break-all;
  white-space: pre-wrap;
  margin-bottom: 10px;

  a {
    border-bottom: 1px solid #00a0e9;
    color: #00a0e9;
  }

  ${(props) =>
    props.isHidden &&
    `
    text-overflow: clip;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical; 
  `}

  ${sp`
    font-size: 13px;  
  `}
`;

const DairyContentReadMoreImg = styled.a`
  pointer-events: none;
  // position: absolute;
  // top: 0px;
  // right: 0px;
  border-bottom: 1px solid #565656;
  cursor: pointer;
`;

const DairyContentReadMore = styled.div`
  // position: relative;
  // margin: 0px auto;
  // padding-bottom: 15px;
  // cursor: pointer;
  margin-bottom: 15px;
  cursor: pointer;
  text-align: right;
`;

export default DiaryContent;
