import React from "react";
import ParticipantInfo from "./ParticipantInfo";
import ParticipantTracks from "./ParticipantTracks";

const Participant = ( {
  participant,
  videoOnly,
  enableScreenShare,
  onClick,
  isLocalParticipant,
  hideParticipant,
  screenHeight,
  screenWidth
} ) => {
  return (
    <ParticipantInfo
      participant={participant}
      onClick={onClick}
      isLocalParticipant={isLocalParticipant}
      hideParticipant={hideParticipant}
      enableScreenShare={enableScreenShare}
      screenWidth={screenWidth}
      screenHeight={screenHeight}
    >
      <ParticipantTracks
        participant={participant}
        videoOnly={videoOnly}
        // isLocalParticipant={isLocalParticipant}
      />
    </ParticipantInfo>
  );
}

export default Participant;