import React, { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ProfileThumbnail from 'javascript/components/items/ProfileThumbnail';
import { Tooltip } from 'javascript/features/realWorld/components/Tooltip';
import { useTooltip } from 'javascript/features/realWorld/hooks/useTooltip';
import { SubRoomUser } from 'types';

const StyledSubRoomMember = styled.div`
  cursor: pointer;
  position: relative;
`;

interface Props {
  user: SubRoomUser;
  isCast: boolean;
}

export const SubRoomMember: React.FC<Props> = ({ user, isCast }) => {
  const isMobile = useMediaQuery({ maxWidth: 430 });
  const history = useHistory();

  const {
    targetRef,
    isHovered,
    position,
    handleOnMouseEnter,
    handleOnMouseLeave,
    handleOnMouseMove,
  } = useTooltip();

  const handleOnClick = useCallback((memberId, e, isCast) => {
    if (!isCast) {
      e.stopPropagation();
      history.push(`mypage/${memberId}`);
    }
  }, []);

  return (
    <StyledSubRoomMember
      ref={targetRef}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onMouseMove={handleOnMouseMove}
      onClick={(e) => handleOnClick(user.user_id, e, isCast)}
    >
      <ProfileThumbnail
        image={user.image}
        isRoom
        size={isMobile ? '16px' : '23px'}
        isClickable
      />
      <Tooltip isHovered={isHovered} position={position} text={user.name} />
    </StyledSubRoomMember>
  );
};
