import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import VideoCallRoom from "./VideoCallRoom";
import PreIntroduction from "./PreIntroduction";
import Disconnected from './Disconnected';
import useVideoCallContext from "./hooks/useVideoCallContext";
import useRoomState from "./hooks/useRoomState";
import { useIsVideoConnect } from "./hooks/useIsVideoConnect";

import MenuBar from "./MenuBar";
import MenuBarMobileTop from "./MenuBarMobileTop";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00A0E9',
      contrastText: '#fff',
    },
    secondary: {
      main: '#EC6941',
      contrastText: '#fff',
    }
  },
  overrides: {
    MuiPaper: {
      root: {

      },
    },
    MuiMenu: {
      paper: {
        padding: '10px',
        marginBottom: '10px',
        backgroundColor: 'rgba(0,0,0, 0.7)',
        color: '#fff',
      }
    },
    MuiDialog: {
      paper: {
        backgroundColor: 'white',
      },
      paperWidthMd:{
        maxWidth: 'calc(258px * 3)',
        paddingBottom: '30px',
      },
      paperWidthSm:{
        maxWidth: 'calc(258px * 2)',
        paddingBottom: '30px',
      }
    },
    MuiDialogContent:{
      root: {
        padding:"0",
      },
    },
    MuiDialogTitle: {
     root: {
       fontSize: '14px',
       color: '#707070',
     } 
    },
    MuiDialogContent: {
      root: {
        maxHeight: '300px',
        padding: '0'
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '11px',
      }
    },
    MuiButtonGroup: {
      root: {
        width: '64px',
        marginRight: '0px',
      },
      groupedContainedHorizontal: {
        '&:not(:last-child)': {
          borderRight: `0px solid `
        }
      }
  
    }
  }

});


const VideoCall = ({roomId, isGuest}) => {
  const { room } = useVideoCallContext();
  const roomState = useRoomState();
  const { isVideoConnect} = useIsVideoConnect();
  useEffect(() => {
    isVideoConnect();
  },[])

  if (roomState == "disconnected") {
    // ユーザーが切断した場合、切断ページを表示する
    return (
      <ThemeProvider theme={theme}>
        <Main>
          <Disconnected />
        </Main>
      </ThemeProvider>
    )
  }
  return (
    <ThemeProvider theme={theme}>

      <div className="video_call">
        {roomState == 'notconnected' ? (
          <Main>
            <PreIntroduction roomId={roomId} isGuest={isGuest} />
          </Main>
        )
          : (
          <Main>
            <MenuBarMobileTop />
            <VideoCallRoom />
            <MenuBar />
          </Main>
        ) 
        }
      </div>
    </ThemeProvider>
  );
};

const Main = styled.div`
  z-index: 1;
  height: 100vh;
`
export default VideoCall;