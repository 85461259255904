import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import ProfileThumbnail from 'javascript/components/items/ProfileThumbnail';

const GivenProductBox = ({ givenProducts }) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const productTypeMap = {
    1: 'hat',
    2: 'hair',
    3: 'eye',
    4: 'glasses',
    5: 'mouth',
    6: 'beard',
    7: 'clothes',
    8: 'body',
    9: 'flower',
  }

  const GivenProductBoxContainer = styled.div`
    overflow-x: scroll;
  `

  const GivenProductBox = styled.div`
    display: flex;
    margin-top: 24px;
    margin-bottom: 24px;
    gap: 20px;
    height: fit-content;
    padding: 14px;
    width: fit-content;

  `

  const GivenProduct = styled.div`
    position: relative;
    padding: 8px;
    background-color: #F5F5F5;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ width }) => width || '40px'};
    height: ${({ height }) => height || '40px'};
  `

  const FromAvatarIconContainer = styled.div`
    position: absolute;
    top: -14px;
    right: -14px;
  `

  if (!givenProducts || givenProducts.length == 0) return (<></>)

  return (
    <GivenProductBoxContainer>
      <GivenProductBox>
        {givenProducts.map((product) => (
          product.avatar_product && (
            <GivenProduct key={product.id} width={isMobile ? '40px' : '62px'} height={isMobile ? '40px' : '62px'}>
              <FromAvatarIconContainer>
                <ProfileThumbnail
                  image={product.from_avatar_image}
                  size="24px"
                />
              </FromAvatarIconContainer>
              {product.avatar_product.product_type === 2 && (
                <img
                  className={'thumbnail'}
                  src={RailsAssetPath(`avatar_parts/${productTypeMap[product.avatar_product.product_type]}/black/${product.avatar_product.product_number}.png`)}
                  style={isMobile ? { maxHeight: '30px' } : { maxHeight: '45px' }}
                />
              )}
              {product.avatar_product.product_type !== 2 && (
                <img
                  className={'thumbnail'}
                  src={RailsAssetPath(`avatar_parts/${productTypeMap[product.avatar_product.product_type]}/${product.avatar_product.product_number}.png`)}
                  style={isMobile ? { maxHeight: '30px' } : { maxHeight: '45px' }}
                />
              )}
            </GivenProduct>
          )
        ))}
      </GivenProductBox>
    </GivenProductBoxContainer>
  )
}

export default GivenProductBox
