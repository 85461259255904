import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import ProfileThumbnail from 'javascript/components/items/ProfileThumbnail';
import { device } from 'javascript/utils/mediaQuery';
import { VirtualWorldMember } from 'javascript/features/virtualWorld/components/VirtualWorldMember';
import { VirtualWorldUser } from 'types';

interface Props {
  users: VirtualWorldUser[];
  guestNum: number;
  isCast: boolean;
}

export const VirtualWorldMemberBox: React.FC<Props> = React.memo(
  ({ users, guestNum, isCast }) => {
    const isMobile = useMediaQuery({ maxWidth: 430 });

    return (
      <VirtualWorldMemberBoxWrapper>
        {users.map((user) => (
          <VirtualWorldMember
            key={user.avatar_id}
            userId={user.user_id}
            userName={user.name}
            userImage={user.image}
            isCast={isCast}
          />
        ))}
        {/* ゲストを表示する */}
        {(() => {
          let guests = [];
          for (let key = 0; key < guestNum; key++) {
            guests.push(
              // @ts-ignore
              <ProfileThumbnail
                // @ts-ignore
                image={RailsAssetPath('guest_icon.png')}
                key={key}
                isRoom
                size={isMobile ? '16px' : '23px'}
              />
            );
          }
          return guests;
        })()}
      </VirtualWorldMemberBoxWrapper>
    );
  }
);

const VirtualWorldMemberBoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 2px;
  justify-content: flex-start;
  align-content: flex-start;
  width: 137%;
  height: 121%;
  margin: -8px 0 0 -12px;
  overflow: auto;
  @media ${device.sp} {
    padding: 0 4px;
    align-items: center;
    width: 112%;
    height: 114%;
    margin: 0 0 0 -6px;
  }
`;
