export default class DailyReport {
  static THANKS = 1;
  static AWESOME = 2;
  static CONGRATULATIONS = 3;
  static ENCOURAGE = 4;

  static config = {
    feelingStatus: {
      [DailyReport.THANKS]: {
        icon: 'diary/heart.png',
        text: 'ReportStatusThankyou'
      },
      [DailyReport.AWESOME]: {
        icon: 'diary/star.png',
        text: 'ReportStatusAmazing'
      },
      [DailyReport.CONGRATULATIONS]: {
        icon: 'diary/party_cracker.png',
        text: 'ReportStatusCongrat'
      },
      [DailyReport.ENCOURAGE]: {
        icon: 'diary/cheer_horn.png',
        text: 'ReportStatusFight'
      }
    }
  }
}