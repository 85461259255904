import React, { useCallback, useState } from 'react';
import { Avatar, UserAvatar } from 'types';

export const useInviteDirectCall = (
  handleCreateDirectCall: (
    avatarId: number,
    currentAvatarId: number | undefined,
    inviting: boolean,
    setInviting: any
  ) => void,
  currentAvatar?: UserAvatar
) => {
  const [inviting, setInviting] = useState(false);

  const inviteDirectCall = useCallback(
    async (avatar: Avatar | null, e: React.MouseEvent) => {
      e.stopPropagation();
      if (!avatar) return;
      await handleCreateDirectCall(
        avatar.avatar_id,
        currentAvatar?.id,
        inviting,
        setInviting
      );
    },
    [currentAvatar, handleCreateDirectCall, inviting, setInviting]
  );

  return { inviteDirectCall, inviting };
};
