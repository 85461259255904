import React, { createContext , useState, useCallback, useEffect} from 'reactn';

import usePaymentConfig from './hooks/usePaymentConfig';
import useCardInput from './hooks/useCardInput';

export const PaymentContext = createContext();
const PaymentProvider = ({ options, children, onError}) => {

  const [paymentConfig, fetchConfig]  = usePaymentConfig();
  const [cardInputComplete, setCardInputComplete] = useCardInput();
  // const [paymentConfig, fetchConfig] = useState({});
  return (
    <PaymentContext.Provider value={{
      paymentConfig,
      fetchConfig,
      cardInputComplete,
      setCardInputComplete
    }}>
      {children}
    </PaymentContext.Provider>
  )

}
export default PaymentProvider;