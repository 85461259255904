import { useEffect, useState } from 'react';
import useVideoCallContext from './useVideoCallContext';

const useParticipants = () => {
  const [participants, setParticipants] = useState([]);
  const { room } = useVideoCallContext();

  useEffect(() => {
    if (room) {
      const participantConnected = (participant) =>
        setParticipants(prevParticipants => [...prevParticipants, participant]);
      const participantDisconnected = (participant) =>
        setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      room.participants.forEach(participantConnected);
      return () => {
        room.off('participantConnected', participantConnected);
        room.off('participantDisconnected', participantDisconnected);
      };
    }
  }, [room]);

  return participants;
}

export default useParticipants;