import { useCallback } from 'react'

export const useIsDuplicatedWindow = () => {
  const tabKey = 'remorks';

  const ItemType = {
    Session: 1,
    Local: 2
  };

  const isDuplicatedWindow = useCallback(() => {
    const setCookie = (name, value, days) => {
      let expires = "";

      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
  
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    const getCookie = (name) => {
      const nameEQ = name + "=";
      const ca = document.cookie.split(";");
  
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
  
        while (c.charAt(0) == " ") {
          c = c.substring(1, c.length);
        }
  
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
  
      return null;
    }

    const GetItem = (itemtype) => {
      let val = "";
  
      switch (itemtype) {
        case ItemType.Session:
          val = window.name;
          break;
  
        case ItemType.Local:
          val = decodeURIComponent(getCookie(tabKey));
          if (val == undefined) val = "";
          break;
      }
  
      return val;
    }
  
    const SetItem = (itemtype, val) => {
      switch (itemtype) {
        case ItemType.Session:
          window.name = val;
          break;
  
        case ItemType.Local:
          setCookie(tabKey, val);
          break;
      }
    }
  
    const createGUID = () => {
      const s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
      };
  
      return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
    }
  
    const isTabDuplicated = () => {
      const sessionGuid = GetItem(ItemType.Session) || createGUID();
      SetItem(ItemType.Session, sessionGuid);
      const val = GetItem(ItemType.Local);
      const tabObj = (val == "" ? null : JSON.parse(val)) || null;
  
      if (tabObj === null || tabObj.timestamp < new Date().getTime() - 15000 || tabObj.guid === sessionGuid) {
        const setTabObj = () => {
          const newTabObj = {
            guid: sessionGuid,
            timestamp: new Date().getTime()
          };
          SetItem(ItemType.Local, JSON.stringify(newTabObj));
        };
  
        setTabObj();
        setInterval(setTabObj, 10000);
  
        window.onunload = function () {
          SetItem(ItemType.Local, "");
          localStorage.removeItem(tabKey);
        };
  
        return false;
      } else {
        return true;
      }
    }

    return isTabDuplicated();
  }, [])

  return { isDuplicatedWindow }
};