import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useGlobal } from 'reactn';

import FormEditAttendanceType from '../form/FormEditAttendanceType';
Modal.setAppElement('body');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: '0px',
    width: '310px',
    background: '#fff',
  },
  overlay: {
    backgroundColor: 'transparent',
    zIndex: '100',
  },
};

const ModalAttendanceType = () => {
  const [isShowEditAttendanceType, setIsShowEditAttendanceType] = useGlobal(
    'isShowEditAttendanceType'
  );
  function closeModal() {
    setIsShowEditAttendanceType(false);
  }

  return (
    <Modal
      isOpen={isShowEditAttendanceType}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='attendance type modal'
    >
      <FormEditAttendanceType />
    </Modal>
  );
};

export default ModalAttendanceType;
