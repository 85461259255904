import React, { useState, useEffect, useCallback, useGlobal } from 'reactn';
import styled from 'styled-components';
import axios from 'axios';
import {
  Typography,
  Divider,
  Dialog,
  DialogContent,
  Button,
  IconButton,
  Grid,
  Link,
} from '@material-ui/core';
import { useStyles } from './hooks/useStyles';
import { sp } from '../..//utils/mediaQuery';
import usePaymentContext from './hooks/usePaymentContext';
import { PAYMENT_METHOD_STEPS } from './constants';
import InputCardNumber from './input/InputCardNumber';
import PaymentMethodSelect from './PaymentMethodSelect';
import AddPaymentMethodForm from './AddPaymentMethodForm';
import DialogCloseButton from './buttons/DialogCloseButton';
import { useTranslation } from 'react-i18next';

const PaymentMethodDialog = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [paymentPaymentMethodStep, setPaymentPaymentMethodStep] = useGlobal(
    'paymentPaymentMethodStep'
  );

  const classes = useStyles();

  useEffect(() => {
    if (open) {
      setPaymentPaymentMethodStep(PAYMENT_METHOD_STEPS.CARD_SELECT);
    }
  }, [open]);

  const handleClose = () => {
    setPaymentPaymentMethodStep(PAYMENT_METHOD_STEPS.CARD_SELECT);
    onClose();
  };
  const SelectPaymentDialogContent = () => {
    switch (paymentPaymentMethodStep) {
      case PAYMENT_METHOD_STEPS.CARD_NUMBER_INPUT:
        return (
          <>
            <AddPaymentMethodForm />
          </>
        );
      case PAYMENT_METHOD_STEPS.CARD_SELECT:
        return (
          <>
            <Typography variant='subtitle1'>
              {t('PaymentTextCurrentPaymentMethod', {})}
            </Typography>
            <Grid container direction='column' spacing={2}>
              <Grid item xs={12}>
                <PaymentMethodSelect />
              </Grid>
              <Grid
                container
                direction='column'
                justify='center'
                alignItems='center'
                spacing={4}
              >
                <Grid item>
                  <Link
                    onClick={() => {
                      setPaymentPaymentMethodStep(
                        PAYMENT_METHOD_STEPS.CARD_NUMBER_INPUT
                      );
                    }}
                  >
                    +{t('PaymentTextAddPaymentMethod', {})}
                  </Link>
                </Grid>
                <Grid item>
                  <Button
                    className={`${classes.baseButton} ${classes.submitButton}`}
                    onClick={handleClose}
                  >
                    {t('PaymentButtonSave', {})}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      maxWidth={sp ? 'sm' : 'sm'}
      fullWidth={true}
      open={open}
      onClose={handleClose}
    >
      <DialogCloseButton onClose={handleClose} />
      <DialogContent>
        <ContentWrapper>
          <SelectPaymentDialogContent />
        </ContentWrapper>
      </DialogContent>
    </Dialog>
  );
};

const ContentWrapper = styled.div`
  margin: 20px;
  ${sp`
    margin: 0px;
  `}
`;
export default PaymentMethodDialog;
