import React from "react";
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import useVideoCallContext from '../hooks/useVideoCallContext';
import useScreenShareParticipant from '../hooks/useScreenShareParticipant';


const ToggleScreenShareButton = ({disabled}) => {
  const screenShareParticipant = useScreenShareParticipant();
  const { toggleScreenShare } = useVideoCallContext();
  const disableScreenShareButton = Boolean(screenShareParticipant);
  const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  const isDisabled = disabled || disableScreenShareButton || !isScreenShareSupported;
  
  let tooltipMessage = '';

  return (
    <Button
      onClick={toggleScreenShare}
      disabled={isDisabled}
      data-cy-share-screen
    >
      {disableScreenShareButton 
        ? <img src={RailsAssetPath("video_call/icon/screen_share_glay.png")} className="button_icon" /> 
        :<img src={RailsAssetPath("video_call/icon/screen_share.png")} className="button_icon" />
      }
    </Button>
  );
}

export default ToggleScreenShareButton;