import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import DiaryContent from './DiaryContent';
import GivenProductBox from './GivenProductBox';
import ImageParts from './ImageParts';
import ReportEvaluation from './ReportEvaluation';
import { sp } from '../../utils/mediaQuery';
import { Trans } from 'react-i18next';
import { getQueryString,SHOW_FEED_TYPE } from '../../utils/util';
import { useGlobal } from 'reactn';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

/** TODO: リファクタ */
const ContentBody = ({ data, currentUser, showMyReport, feedType }) => {
  const [localDeletedNewsIds, setLocalDeletedNewsIds] = useState([]);

  const [selectedAnswerValue, setSelectedAnswerValue] = useState(null);

  const [newData, setNewData] = useState(data);
  const saveLocalDeletedNewsIds = useCallback(
    (newsId) => {
      setLocalDeletedNewsIds([...localDeletedNewsIds, newsId]);
    },
    [localDeletedNewsIds]
  );

  const { t } = useTranslation();

  const [feedShowType, setFeedShowType] = useGlobal('feedShowType');

  const surveyFutureCheck = (targetDatetime) => {
    const today = new Date();
    if (today <= new Date(targetDatetime)) { // 今日が締切より未来の場合
      return true;
    } else {
      return false;
    }
  };

  const surveyAnswerButtonClick = (value,item_id) => {
    const url = "api/v1/question/answer";
    setSelectedAnswerValue(value);
    axios.post(url, {
      id: item_id,
      answer_no: value,
    }).then(
      res => {
        setNewData(res.data.questions);
    });
  };

  return (
    <>
      {feedShowType !== SHOW_FEED_TYPE.SURVEY &&
        data
        .filter((item) => !localDeletedNewsIds.includes(item.id))
        .map((item, key) => (
          <Report key={key}>
            <div className=''>
              <ReportHeader>
                <a href={`/mypage/${item.user.id}`}>
                  <ReportIcon>
                    <img className='' src={item.user.image || RailsAssetPath('noimage_icon.png')} alt='' />
                  </ReportIcon>
                </a>
                <ReportTitleWrapper>
                  <ReportTitle>{item.title}</ReportTitle>
                  <UserName>{item.user.name}</UserName>
                </ReportTitleWrapper>
                <ReportFilter>
                  <ReportFilterTeam>
                    {item.team_name ? (
                      item.team_name
                    ) : (
                      <Trans i18nKey='GenelalTextNoTeam' />
                    )}
                  </ReportFilterTeam>
                  <ReportCreateTime>{item.position_name}</ReportCreateTime>
                  <ReportCreateTime>{item.created_at}</ReportCreateTime>
                </ReportFilter>
              </ReportHeader>
              <ReportBody>
                <DiaryContent dataSet={item.content} />
                <ImageParts imageDataSet={item.images} />
              </ReportBody>
              {feedType && (
                <ReportEvaluation
                  report={item}
                  currentUser={currentUser}
                  feedType={feedType}
                  showReport={showMyReport}
                  localDeletedNewsIds={localDeletedNewsIds}
                  saveLocalDeletedNewsIds={saveLocalDeletedNewsIds}
                />
              )}
              <GivenProductBox
                givenProducts={item.given_products}
              />
            </div>
          </Report>
        ))}
        {/* 開催中のアンケート */}
        {feedShowType == SHOW_FEED_TYPE.SURVEY &&
          newData
          .filter((item) => !localDeletedNewsIds.includes(item.id))
          .map((item, key) => (
            surveyFutureCheck(item.limit_answered_at) && (
            <Report key={key}>
              <div className=''>
                <ReportHeader>
                  <ReportIcon
                    data-user-id={item.user.id}
                    onClick={() => showMyReport(item.user)}
                  >
                    <img className='' src={item.user.image || RailsAssetPath('noimage_icon.png')} alt='' />
                  </ReportIcon>
                  <ReportTitleWrapper>
                    <ReportTitle>{item.title}</ReportTitle>
                    <UserName>{item.user.name}</UserName>
                  </ReportTitleWrapper>
                  <ReportFilter>
                    <ReportFilterTeam>
                      {item.team_name ? (
                        item.team_name
                      ) : (
                        <Trans i18nKey='GenelalTextNoTeam' />
                      )}
                    </ReportFilterTeam>
                    <ReportCreateTime>{item.created_at}</ReportCreateTime>
                  </ReportFilter>
                </ReportHeader>
                <ReportBody>
                  <DiaryContent dataSet={item.content} />
                  <SurveyOptionButtonFlexWrapper>
                    {item.owned_answer == 0 ? (
                      <>
                        {item.answer_1 &&
                          <SurveyOptionButton type="button" value={1} onClick={() => surveyAnswerButtonClick(1,item.id)}>
                            {item.answer_1}
                          </SurveyOptionButton>
                        }
                        {item.answer_2 &&
                          <SurveyOptionButton type="button" value={2} onClick={() => surveyAnswerButtonClick(2,item.id)}>
                            {item.answer_2}
                          </SurveyOptionButton>
                        }
                        {item.answer_3 &&
                          <SurveyOptionButton type="button" value={3} onClick={() => surveyAnswerButtonClick(3,item.id)}>
                            {item.answer_3}
                          </SurveyOptionButton>
                        }
                        {item.answer_4 &&
                          <SurveyOptionButton type="button" value={4} onClick={() => surveyAnswerButtonClick(4,item.id)}>
                            {item.answer_4}
                          </SurveyOptionButton>
                        }
                        {item.answer_5 &&
                          <SurveyOptionButton type="button" value={5} onClick={() => surveyAnswerButtonClick(5,item.id)}>
                            {item.answer_5}
                          </SurveyOptionButton>
                        }
                        {item.answer_6 &&
                          <SurveyOptionButton type="button" value={6} onClick={() => surveyAnswerButtonClick(6,item.id)}>
                            {item.answer_6}
                          </SurveyOptionButton>
                        }
                        {item.answer_7 &&
                          <SurveyOptionButton type="button" value={7} onClick={() => surveyAnswerButtonClick(7,item.id)}>
                            {item.answer_7}
                          </SurveyOptionButton>
                        }
                        {item.answer_8 &&
                          <SurveyOptionButton type="button" value={8} onClick={() => surveyAnswerButtonClick(8,item.id)}>
                            {item.answer_8}
                          </SurveyOptionButton>
                        }
                        {item.answer_9 &&
                          <SurveyOptionButton type="button" value={9} onClick={() => surveyAnswerButtonClick(9,item.id)}>
                            {item.answer_9}
                          </SurveyOptionButton>
                        }
                        {item.answer_10 &&
                          <SurveyOptionButton type="button" value={10} onClick={() => surveyAnswerButtonClick(10,item.id)}>
                            {item.answer_10}
                          </SurveyOptionButton>
                        }
                      </>
                      ) : (
                        <>
                          {item.answer_1 &&
                            <>
                              <SurveyAnswerFlexWrapper percent={item.answer_percent_1}>
                                <SurveyAnswerPercentWrapper >
                                  <SurveyFlex>
                                    <SurveyAnswerPercentTitle>{item.answer_1}</SurveyAnswerPercentTitle>
                                    {item.owned_answer == 1 &&
                                      <SurveyCheckIcon src={RailsAssetPath('icon_official_check.png')}/>
                                    }
                                  </SurveyFlex>
                                  <div>{item.answer_percent_1+"%"}</div>
                                </SurveyAnswerPercentWrapper>
                                <SurveyAnswerPercentBarWrapper className="survey-bar-color" percent={item.answer_percent_1}/>
                              </SurveyAnswerFlexWrapper>
                            </>
                          }
                          {item.answer_2 &&
                            <>
                              <SurveyAnswerFlexWrapper percent={item.answer_percent_2}>
                                <SurveyAnswerPercentWrapper>
                                  <SurveyFlex>
                                    <SurveyAnswerPercentTitle>{item.answer_2}</SurveyAnswerPercentTitle>
                                    {item.owned_answer == 2 &&
                                      <SurveyCheckIcon src={RailsAssetPath('icon_official_check.png')}/>
                                    }
                                  </SurveyFlex>
                                  <div>{item.answer_percent_2+"%"}</div>
                                </SurveyAnswerPercentWrapper>
                                <SurveyAnswerPercentBarWrapper className="survey-bar-color" percent={item.answer_percent_2}/>
                              </SurveyAnswerFlexWrapper>
                            </>
                          }
                          {item.answer_3 &&
                            <>
                              <SurveyAnswerFlexWrapper percent={item.answer_percent_3}>
                                <SurveyAnswerPercentWrapper>
                                  <SurveyFlex>
                                    <SurveyAnswerPercentTitle>{item.answer_3}</SurveyAnswerPercentTitle>
                                    {item.owned_answer == 3 &&
                                      <SurveyCheckIcon src={RailsAssetPath('icon_official_check.png')}/>
                                    }
                                  </SurveyFlex>
                                  <div>{item.answer_percent_3+"%"}</div>
                                </SurveyAnswerPercentWrapper>
                                <SurveyAnswerPercentBarWrapper className="survey-bar-color" percent={item.answer_percent_3}/>
                              </SurveyAnswerFlexWrapper>
                            </>
                          }
                          {item.answer_4 &&
                            <>
                              <SurveyAnswerFlexWrapper percent={item.answer_percent_4}>
                                <SurveyAnswerPercentWrapper>
                                  <SurveyFlex>
                                    <SurveyAnswerPercentTitle>{item.answer_4}</SurveyAnswerPercentTitle>
                                    {item.owned_answer == 4 &&
                                      <SurveyCheckIcon src={RailsAssetPath('icon_official_check.png')}/>
                                    }
                                  </SurveyFlex>
                                  <div>{item.answer_percent_4+"%"}</div>
                                </SurveyAnswerPercentWrapper>
                                <SurveyAnswerPercentBarWrapper className="survey-bar-color" percent={item.answer_percent_4}/>
                              </SurveyAnswerFlexWrapper>
                            </>
                          }
                          {item.answer_5 &&
                            <>
                              <SurveyAnswerFlexWrapper percent={item.answer_percent_5}>
                                <SurveyAnswerPercentWrapper>
                                  <SurveyFlex>
                                    <SurveyAnswerPercentTitle>{item.answer_5}</SurveyAnswerPercentTitle>
                                    {item.owned_answer == 5 &&
                                      <SurveyCheckIcon src={RailsAssetPath('icon_official_check.png')}/>
                                    }
                                  </SurveyFlex>
                                  <div>{item.answer_percent_5+"%"}</div>
                                </SurveyAnswerPercentWrapper>
                                <SurveyAnswerPercentBarWrapper className="survey-bar-color" percent={item.answer_percent_5}/>
                              </SurveyAnswerFlexWrapper>
                            </>
                          }
                          {item.answer_6 &&
                            <>
                              <SurveyAnswerFlexWrapper percent={item.answer_percent_6}>
                                <SurveyAnswerPercentWrapper>
                                  <SurveyFlex>
                                    <SurveyAnswerPercentTitle>{item.answer_6}</SurveyAnswerPercentTitle>
                                    {item.owned_answer == 6 &&
                                      <SurveyCheckIcon src={RailsAssetPath('icon_official_check.png')}/>
                                    }
                                  </SurveyFlex>
                                  <div>{item.answer_percent_6+"%"}</div>
                                </SurveyAnswerPercentWrapper>
                                <SurveyAnswerPercentBarWrapper className="survey-bar-color" percent={item.answer_percent_6}/>
                              </SurveyAnswerFlexWrapper>
                            </>
                          }
                          {item.answer_7 &&
                            <>
                              <SurveyAnswerFlexWrapper percent={item.answer_percent_7}>
                                <SurveyAnswerPercentWrapper>
                                  <SurveyFlex>
                                    <SurveyAnswerPercentTitle>{item.answer_7}</SurveyAnswerPercentTitle>
                                    {item.owned_answer == 7 &&
                                      <SurveyCheckIcon src={RailsAssetPath('icon_official_check.png')}/>
                                    }
                                  </SurveyFlex>
                                  <div>{item.answer_percent_7+"%"}</div>
                                </SurveyAnswerPercentWrapper>
                                <SurveyAnswerPercentBarWrapper className="survey-bar-color" percent={item.answer_percent_7}/>
                              </SurveyAnswerFlexWrapper>
                            </>
                          }
                          {item.answer_8 &&
                            <>
                              <SurveyAnswerFlexWrapper percent={item.answer_percent_8}>
                                <SurveyAnswerPercentWrapper>
                                  <SurveyFlex>
                                    <SurveyAnswerPercentTitle>{item.answer_8}</SurveyAnswerPercentTitle>
                                    {item.owned_answer == 8 &&
                                      <SurveyCheckIcon src={RailsAssetPath('icon_official_check.png')}/>
                                    }
                                  </SurveyFlex>
                                  <div>{item.answer_percent_8+"%"}</div>
                                </SurveyAnswerPercentWrapper>
                                <SurveyAnswerPercentBarWrapper className="survey-bar-color" percent={item.answer_percent_8}/>
                              </SurveyAnswerFlexWrapper>
                            </>
                          }
                          {item.answer_9 &&
                            <>
                              <SurveyAnswerFlexWrapper percent={item.answer_percent_9}>
                                <SurveyAnswerPercentWrapper>
                                  <SurveyFlex>
                                    <SurveyAnswerPercentTitle>{item.answer_9}</SurveyAnswerPercentTitle>
                                    {item.owned_answer == 9 &&
                                      <SurveyCheckIcon src={RailsAssetPath('icon_official_check.png')}/>
                                    }
                                  </SurveyFlex>
                                  <div>{item.answer_percent_9+"%"}</div>
                                </SurveyAnswerPercentWrapper>
                                <SurveyAnswerPercentBarWrapper className="survey-bar-color" percent={item.answer_percent_9}/>
                              </SurveyAnswerFlexWrapper>
                            </>
                          }
                          {item.answer_10 &&
                            <>
                              <SurveyAnswerFlexWrapper percent={item.answer_percent_10}>
                                <SurveyAnswerPercentWrapper>
                                  <SurveyFlex>
                                    <SurveyAnswerPercentTitle>{item.answer_10}</SurveyAnswerPercentTitle>
                                    {item.owned_answer == 10 &&
                                      <SurveyCheckIcon src={RailsAssetPath('icon_official_check.png')}/>
                                    }
                                  </SurveyFlex>
                                  <div>{item.answer_percent_10+"%"}</div>
                                </SurveyAnswerPercentWrapper>
                                <SurveyAnswerPercentBarWrapper className="survey-bar-color" percent={item.answer_percent_10}/>
                              </SurveyAnswerFlexWrapper>
                            </>
                          }
                        </>
                      )
                    }
                  </SurveyOptionButtonFlexWrapper>
                  <SurveyVoteFlexWrapper>
                    <SurveyVotes>{item.sum_answer+"票"}</SurveyVotes>
                    <div>{item.limit_answered_at}</div>
                  </SurveyVoteFlexWrapper>
                  <ImageParts imageDataSet={item.images} />
                </ReportBody>
                {feedType && (
                  <ReportEvaluation
                    report={item}
                    currentUser={currentUser}
                    feedType={feedType}
                    showReport={showMyReport}
                    localDeletedNewsIds={localDeletedNewsIds}
                    saveLocalDeletedNewsIds={saveLocalDeletedNewsIds}
                  />
                )}
              </div>
            </Report>
            )
          )
        )}
        {/* 期間の終わったアンケート */}
        {feedShowType == SHOW_FEED_TYPE.SURVEY &&
          data
          .filter((item) => !localDeletedNewsIds.includes(item.id))
          .map((item, key) => (
            !surveyFutureCheck(item.limit_answered_at) && (
            <Report key={key}>
              <div className=''>
                <ReportHeader>
                  <ReportIcon
                    data-user-id={item.user.id}
                    onClick={() => showMyReport(item.user)}
                  >
                    <img className='' src={item.user.image || RailsAssetPath('noimage_icon.png')} alt='' />
                  </ReportIcon>
                  <ReportTitleWrapper>
                    <ReportTitle>{item.title}</ReportTitle>
                    <UserName>{item.user.name}</UserName>
                  </ReportTitleWrapper>
                  <ReportFilter>
                    <ReportFilterTeam>
                      {item.team_name ? (
                        item.team_name
                      ) : (
                        <Trans i18nKey='GenelalTextNoTeam' />
                      )}
                    </ReportFilterTeam>
                    <ReportCreateTime>{item.created_at}</ReportCreateTime>
                  </ReportFilter>
                </ReportHeader>
                <ReportBody>
                  <DiaryContent dataSet={item.content} />
                  <SurveyOptionButtonFlexWrapper>
                    {item.answer_1 &&
                      <SurveyAnswerFlexWrapper percent={item.answer_percent_1}>
                        <SurveyAnswerPercentWrapper>
                          <SurveyFlex>
                            <SurveyAnswerPercentTitle>{item.answer_1}</SurveyAnswerPercentTitle>
                            {item.owned_answer == 1 &&
                              <SurveyCheckIcon src={RailsAssetPath('icon_official_check.png')}/>
                            }
                          </SurveyFlex>
                          <div>{item.answer_percent_1+"%"}</div>
                        </SurveyAnswerPercentWrapper>
                        <SurveyAnswerPercentBarWrapper className="survey-bar-color" percent={item.answer_percent_1}/>
                      </SurveyAnswerFlexWrapper>
                    }
                    {item.answer_2 &&
                      <SurveyAnswerFlexWrapper percent={item.answer_percent_2}>
                        <SurveyAnswerPercentWrapper>
                          <SurveyFlex>
                            <SurveyAnswerPercentTitle>{item.answer_2}</SurveyAnswerPercentTitle>
                            {item.owned_answer == 2 &&
                              <SurveyCheckIcon src={RailsAssetPath('icon_official_check.png')}/>
                            }
                          </SurveyFlex>
                          <div>{item.answer_percent_2+"%"}</div>
                        </SurveyAnswerPercentWrapper>
                        <SurveyAnswerPercentBarWrapper className="survey-bar-color" percent={item.answer_percent_2}/>
                      </SurveyAnswerFlexWrapper>
                    }
                    {item.answer_3 &&
                      <SurveyAnswerFlexWrapper percent={item.answer_percent_3}>
                        <SurveyAnswerPercentWrapper>
                          <SurveyFlex>
                            <SurveyAnswerPercentTitle>{item.answer_3}</SurveyAnswerPercentTitle>
                            {item.owned_answer == 3 &&
                              <SurveyCheckIcon src={RailsAssetPath('icon_official_check.png')}/>
                            }
                          </SurveyFlex>
                          <div>{item.answer_percent_3+"%"}</div>
                        </SurveyAnswerPercentWrapper>
                        <SurveyAnswerPercentBarWrapper className="survey-bar-color" percent={item.answer_percent_3}/>
                      </SurveyAnswerFlexWrapper>
                    }
                    {item.answer_4 &&
                      <SurveyAnswerFlexWrapper percent={item.answer_percent_4}>
                        <SurveyAnswerPercentWrapper>
                          <SurveyFlex>
                            <SurveyAnswerPercentTitle>{item.answer_4}</SurveyAnswerPercentTitle>
                            {item.owned_answer == 4 &&
                              <SurveyCheckIcon src={RailsAssetPath('icon_official_check.png')}/>
                            }
                          </SurveyFlex>
                          <div>{item.answer_percent_4+"%"}</div>
                        </SurveyAnswerPercentWrapper>
                        <SurveyAnswerPercentBarWrapper className="survey-bar-color" percent={item.answer_percent_4}/>
                      </SurveyAnswerFlexWrapper>
                    }
                    {item.answer_5 &&
                      <SurveyAnswerFlexWrapper percent={item.answer_percent_5}>
                        <SurveyAnswerPercentWrapper>
                          <SurveyFlex>
                            <SurveyAnswerPercentTitle>{item.answer_5}</SurveyAnswerPercentTitle>
                            {item.owned_answer == 5 &&
                              <SurveyCheckIcon src={RailsAssetPath('icon_official_check.png')}/>
                            }
                          </SurveyFlex>
                          <div>{item.answer_percent_5+"%"}</div>
                        </SurveyAnswerPercentWrapper>
                        <SurveyAnswerPercentBarWrapper className="survey-bar-color" percent={item.answer_percent_5}/>
                      </SurveyAnswerFlexWrapper>
                    }
                    {item.answer_6 &&
                      <SurveyAnswerFlexWrapper percent={item.answer_percent_6}>
                        <SurveyAnswerPercentWrapper>
                          <SurveyFlex>
                            <SurveyAnswerPercentTitle>{item.answer_6}</SurveyAnswerPercentTitle>
                            {item.owned_answer == 6 &&
                              <SurveyCheckIcon src={RailsAssetPath('icon_official_check.png')}/>
                            }
                          </SurveyFlex>
                          <div>{item.answer_percent_6+"%"}</div>
                        </SurveyAnswerPercentWrapper>
                        <SurveyAnswerPercentBarWrapper className="survey-bar-color" percent={item.answer_percent_6}/>
                      </SurveyAnswerFlexWrapper>
                    }
                    {item.answer_7 &&
                      <SurveyAnswerFlexWrapper percent={item.answer_percent_7}>
                        <SurveyAnswerPercentWrapper>
                          <SurveyFlex>
                            <SurveyAnswerPercentTitle>{item.answer_7}</SurveyAnswerPercentTitle>
                            {item.owned_answer == 7 &&
                              <SurveyCheckIcon src={RailsAssetPath('icon_official_check.png')}/>
                            }
                          </SurveyFlex>
                          <div>{item.answer_percent_7+"%"}</div>
                        </SurveyAnswerPercentWrapper>
                        <SurveyAnswerPercentBarWrapper className="survey-bar-color" percent={item.answer_percent_7}/>
                      </SurveyAnswerFlexWrapper>
                    }
                    {item.answer_8 &&
                      <SurveyAnswerFlexWrapper percent={item.answer_percent_8}>
                        <SurveyAnswerPercentWrapper>
                          <SurveyFlex>
                            <SurveyAnswerPercentTitle>{item.answer_8}</SurveyAnswerPercentTitle>
                            {item.owned_answer == 8 &&
                              <SurveyCheckIcon src={RailsAssetPath('icon_official_check.png')}/>
                            }
                          </SurveyFlex>
                          <div>{item.answer_percent_8+"%"}</div>
                        </SurveyAnswerPercentWrapper>
                        <SurveyAnswerPercentBarWrapper className="survey-bar-color" percent={item.answer_percent_8}/>
                      </SurveyAnswerFlexWrapper>
                    }
                    {item.answer_9 &&
                      <SurveyAnswerFlexWrapper percent={item.answer_percent_9}>
                        <SurveyAnswerPercentWrapper>
                          <SurveyFlex>
                            <SurveyAnswerPercentTitle>{item.answer_9}</SurveyAnswerPercentTitle>
                            {item.owned_answer == 9 &&
                              <SurveyCheckIcon src={RailsAssetPath('icon_official_check.png')}/>
                            }
                          </SurveyFlex>
                          <div>{item.answer_percent_9+"%"}</div>
                        </SurveyAnswerPercentWrapper>
                        <SurveyAnswerPercentBarWrapper className="survey-bar-color" percent={item.answer_percent_9}/>
                      </SurveyAnswerFlexWrapper>
                    }
                    {item.answer_10 &&
                      <SurveyAnswerFlexWrapper percent={item.answer_percent_10}>
                        <SurveyAnswerPercentWrapper>
                          <SurveyFlex>
                            <SurveyAnswerPercentTitle>{item.answer_10}</SurveyAnswerPercentTitle>
                            {item.owned_answer == 10 &&
                              <SurveyCheckIcon src={RailsAssetPath('icon_official_check.png')}/>
                            }
                          </SurveyFlex>
                          <div>{item.answer_percent_10+"%"}</div>
                        </SurveyAnswerPercentWrapper>
                        <SurveyAnswerPercentBarWrapper className="survey-bar-color" percent={item.answer_percent_10}/>
                      </SurveyAnswerFlexWrapper>
                    }
                  </SurveyOptionButtonFlexWrapper>
                  <SurveyVoteFlexWrapper>
                    <SurveyVotes>{item.sum_answer}{t('DiarySurveyVotes', {})}</SurveyVotes>
                    <div>{t('DiarySurveyPeriodEnds',{})}</div>
                  </SurveyVoteFlexWrapper>
                  <ImageParts imageDataSet={item.images} />
                </ReportBody>
                {feedType && (
                  <ReportEvaluation
                    report={item}
                    currentUser={currentUser}
                    feedType={feedType}
                    showReport={showMyReport}
                    localDeletedNewsIds={localDeletedNewsIds}
                    saveLocalDeletedNewsIds={saveLocalDeletedNewsIds}
                  />
                )}
              </div>
            </Report>
            )
          )
        )}
      </>
    );
};

const Report = styled.div`
  border-radius: 0.3rem;
  background: white;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  margin: 10px auto 25px;
  width: 700px;
  white-space: pre-wrap;
  padding: 20px 20px 5px;
  font-size: 14px;
  ${sp`
    width: 100%;
    padding: 20px 10px 5px;
  `}
`;

const ReportIcon = styled.div`
  border-radius: 50%;
  border: 1px solid #cfcfcf;
  overflow: hidden;
  height: 50px;
  width: 50px;
  cursor: pointer;
  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
  ${sp`
    width: 40px;
    height: 40px;
    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  `}
`;

const ReportFilter = styled.div`
  width: 20%;
  padding: 10px 0px 0px;
  ${sp`
    width: 35%;
  `}
`;

const ReportBody = styled.div`
  margin: 15px 15%;
  width: 70%;
  ${sp`
    margin: 15px 10px;
    width: auto;
  `}
`;

const ReportHeader = styled.div`
  display: flex;
  justify-content: space-between;
  ${sp`
    font-size: 13px;
  `}
`;

const ReportTitleWrapper = styled.div`
  width: 65%;
  padding: 2px 0px 0px;
  ${sp`
    max-width: 40%;
  `}
`;

const UserName = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
`;

const ReportTitle = styled.div`
  font-size: 17px;
`;

const ReportFilterTeam = styled.div`
  background: #eeeeee;
  border-radius: 5px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ReportCreateTime = styled.div`
  padding: 5px 0 0;
  font-size: 12px;
`;

const SurveyVoteFlexWrapper = styled.div`
  display: flex ;
`;

const SurveyVotes= styled.div`
  margin-right: 10px;
`;

const SurveyOptionButtonFlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const SurveyOptionButton = styled.button`
  border: 2px solid #03a9f4;
  border-radius: 7px;
  background: white;
  width: 100%;
  max-width: 100%;
  padding: 10px;
  text-align: left;
  margin-bottom: 10px;
  &:first-child {
    margin-top: 35px;
  }
  /* margin-right: 20px; */
`;

const SurveyAnswerFlexWrapper = styled.div`
  /* border: 2px solid #e4ecec; */
  border-radius: 7px;
  /* background: #e4ecec; */
  /* ${props => props.percent ? `width:${props => props.percent}%` : `width:10px`}; */
  width: ${props => props.percent+'%' || '100%'};
  max-width: 100%;
  text-align: left;
  margin-bottom: 10px;
  /* height: 73px; */
  position: relative;
  width: 100%;
  &:first-child {
    margin-top: 35px;
  }
  &:first-child .survey-bar-color{
    /* margin-top: 35px; */
    border: 2px solid #F6B9B7;
    background: #F6B9B7;
  }
  &:nth-child(2) .survey-bar-color{
    border: 2px solid #F9D2B0;
    background: #F9D2B0;
  }
  &:nth-child(3) .survey-bar-color{
    border: 2px solid #FCECAD;
    background: #FCECAD;
  }
  &:nth-child(4) .survey-bar-color{
    border: 2px solid #DAE8AC;
    background: #DAE8AC;
  }
  &:nth-child(5) .survey-bar-color{
    border: 2px solid #B4E5BA;
    background: #B4E5BA;
  }
  &:nth-child(6) .survey-bar-color{
    border: 2px solid #ADD7DD;
    background: #ADD7DD;
  }
  &:nth-child(7) .survey-bar-color{
    border: 2px solid #A5CAFF;
    background: #A5CAFF;
  }
  &:nth-child(8) .survey-bar-color{
    border: 2px solid #C3C6F9;
    background: #C3C6F9;
  }
  &:nth-child(9) .survey-bar-color{
    border: 2px solid #E0C3F3;
    background: #E0C3F3;
  }
  &:nth-child(10) .survey-bar-color{
    border: 2px solid #F7D4F6;
    background: #F7D4F6;
  }
`;

const SurveyAnswerPercentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  /* position: absolute; */
  width: 100%;
  padding: 10px;
  position: relative;
  z-index: 10;
  align-items: center;
`;

const  SurveyAnswerPercentTitle = styled.div`
  /* min-width: max-content; */
  padding-right: 15px;
  min-width: max-content;
  ${sp`
    max-width: 225px;
    min-width: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  `}
`;

const SurveyFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SurveyCheckIcon = styled.img`
  width: 16px;
`;

const SurveyAnswerPercentBarWrapper = styled.div`
  position: absolute;
  top: 0;
  width: ${props => props.percent+'%' || '100%'};
  background: red;
  height: 100%;
  z-index: 0;
  border-radius: 7px;
  min-width: 24px;
  /* &:first-child {
    margin-top: 35px;
    border: 2px solid #FFB5B5;
    background: #FFB5B5;
  }
  &:nth-child(2) {
    border: 2px solid #FFEC9E;
    background: #FFEC9E;
  }
  &:nth-child(3) {
    border: 2px solid #B5E5BB;
    background: #B5E5BB;
  }
  &:nth-child(4) {
    border: 2px solid #A6CAFF;
    background: #A6CAFF;
  } */
`;

export default ContentBody;
