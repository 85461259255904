import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  eyeAppearanceInMypage,
  mouthAppearanceInMypage,
  clothAppearanceInMypage,
  hairAppearanceInMypage,
  hatAppearanceInMypage,
} from 'javascript/features/avatarParts/constants/displayAvatarAreaInMypage';
import { hairColorArray } from 'javascript/features/avatarParts/constants/hairColor';
import { device } from 'javascript/utils/mediaQuery';
import {
  FixedHeightAndPosition,
  FixedWidthAndPosition,
  UserProfileAvatar,
} from 'types';

interface Props {
  avatarParts: UserProfileAvatar;
  isCurrentUser: boolean;
  userId: number;
}

export const AvatarDisplayOnMypage: React.FC<Props> = ({
  avatarParts,
  isCurrentUser,
  userId,
}) => (
  <StyledAvatarDisplayWrapper>
    {isCurrentUser && (
      <StyledDressUpIcon>
        <Link to={`/mypage/${userId}?target=avatar`}>
          {/* @ts-ignore */}
          <img src={RailsAssetPath('mypage/dressup_icon.png')} />
        </Link>
      </StyledDressUpIcon>
    )}
    <StyledBody
      // @ts-ignore
      src={RailsAssetPath(
        `avatar_parts/body/${avatarParts.clothes ? '1' : '2'}.png`
      )}
    />
    <StyledEyes
      fixedPosition={eyeAppearanceInMypage[avatarParts.eye - 1]}
      // @ts-ignore
      src={RailsAssetPath(`avatar_parts/eye/${avatarParts.eye}.png`)}
    />
    <StyledMouth
      fixedPosition={mouthAppearanceInMypage[avatarParts.mouth - 1]}
      // @ts-ignore
      src={RailsAssetPath(`avatar_parts/mouth/${avatarParts.mouth}.png`)}
    />
    {avatarParts.clothes && (
      <StyledCloth
        fixedPosition={clothAppearanceInMypage[avatarParts.clothes - 1]}
        // @ts-ignore
        src={RailsAssetPath(`avatar_parts/clothes/${avatarParts.clothes}.png`)}
      />
    )}
    <StyledHair
      fixedPosition={hairAppearanceInMypage[avatarParts.hair - 1]}
      // @ts-ignore
      src={RailsAssetPath(
        `avatar_parts/hair/${hairColorArray[avatarParts.hair_color - 1]}/${
          avatarParts.hair
        }.png`
      )}
    />
    {avatarParts.hat && (
      <StyledHat
        fixedPosition={hatAppearanceInMypage[avatarParts.hat - 1]}
        // @ts-ignore
        src={RailsAssetPath(`avatar_parts/hat/${avatarParts.hat}.png`)}
      />
    )}
    {avatarParts.is_happy && (
      // @ts-ignore
      <StyledHappyAura src={RailsAssetPath('freeSpace/happy_aura.png')} /> 
    )}
  </StyledAvatarDisplayWrapper>
);

const StyledAvatarDisplayWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 320px;
  @media ${device.sp} {
    width: 327px;
    margin: 0 auto;
  }
`;

const StyledDressUpIcon = styled.button`
  position: absolute;
  top: 0;
  right: 8px;
  img {
    width: 32px;
  }
  @media ${device.sp} {
    top: 40px;
    img {
      width: 40px;
    }
  }
`;

const StyledBody = styled.img`
  height: 240px;
`;

const StyledEyes = styled.img<{ fixedPosition: FixedWidthAndPosition }>`
  position: absolute;
  top: ${({ fixedPosition }) => `${fixedPosition.top}px`};
  left: ${({ fixedPosition }) => `${fixedPosition.left}px`};
  width: ${({ fixedPosition }) => `${fixedPosition.width}px`};
  z-index: 1;

  @media ${device.sp} {
    left: ${({ fixedPosition }) => `${fixedPosition.left + 12}px`};
  }
`;

const StyledMouth = styled.img<{ fixedPosition: FixedWidthAndPosition }>`
  position: absolute;
  top: ${({ fixedPosition }) => `${fixedPosition.top}px`};
  left: ${({ fixedPosition }) => `${fixedPosition.left}px`};
  width: ${({ fixedPosition }) => `${fixedPosition.width}px`};
  z-index: 4;

  @media ${device.sp} {
    left: ${({ fixedPosition }) => `${fixedPosition.left + 12}px`};
  }
`;

const StyledCloth = styled.img<{ fixedPosition: FixedHeightAndPosition }>`
  position: absolute;
  top: ${({ fixedPosition }) => `${fixedPosition.top}px`};
  left: ${({ fixedPosition }) => `${fixedPosition.left}px`};
  height: ${({ fixedPosition }) => `${fixedPosition.height}px`};
  z-index: 3;

  @media ${device.sp} {
    left: ${({ fixedPosition }) => `${fixedPosition.left + 12}px`};
  }
`;

const StyledHair = styled.img<{ fixedPosition: FixedWidthAndPosition }>`
  position: absolute;
  top: ${({ fixedPosition }) => `${fixedPosition.top}px`};
  left: ${({ fixedPosition }) => `${fixedPosition.left}px`};
  width: ${({ fixedPosition }) => `${fixedPosition.width}px`};
  z-index: 2;

  @media ${device.sp} {
    left: ${({ fixedPosition }) => `${fixedPosition.left + 12}px`};
  }
`;

const StyledHat = styled.img<{ fixedPosition: FixedWidthAndPosition }>`
  position: absolute;
  top: ${({ fixedPosition }) => `${fixedPosition.top}px`};
  left: ${({ fixedPosition }) => `${fixedPosition.left}px`};
  width: ${({ fixedPosition }) => `${fixedPosition.width}px`};
  z-index: 5;

  @media ${device.sp} {
    left: ${({ fixedPosition }) => `${fixedPosition.left + 12}px`};
  }
`;

const StyledHappyAura = styled.img`
  position: absolute;
  width: 320px;
  bottom: -8px;
`;