import MyBoxSkillActionBtns from 'javascript/features/mybox/components/MyBoxSkillActionBtns';
import { useChangeSkillStatusToHandle } from 'javascript/features/mybox/hooks/useChangeSkillStatusToHandled';
import React from 'react';
import styled from 'styled-components';
import { OrderedHistoryItem } from 'types';

const StyledShopProduct = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
`;

const ShopProductImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100px;
  height: 120px;
  display: flex;
`;

const StyledOrderedSkillImage = styled.img`
  max-width: 64px;
  max-height: 64px;
  clip-path: inset(10px 0px 12% 0px);
  height: 82px;
  max-height: none;
  object-fit: cover;
`;

const StyledShopProductImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  height: 120px;
  padding: 8px 0;
  background-color: #fff;
  border-radius: 8px;
`;

const StyledShopProductProductName = styled.div`
  font-size: 12px;
  min-height: 54px;
  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
`;

const StyledShopProductPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  img {
    width: 16px;
  }
  p {
    margin-left: 4px;
  }
`;

const StyledUserIcon = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

const StyledExpiredAt = styled.div<{ expiredSoon: boolean; }>`
  overflow-wrap: anywhere;
  min-height: 24px;
  font-size: 11px;
  margin-top: 4px;
  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    ${({ expiredSoon }) => (expiredSoon ? 'color: #EC6A40;' : '')};
  }
`;

type Props = {
  historyItem: OrderedHistoryItem;
  avatarOwnedSkillProductSort: string | null;
};

export const OrderedHistoryItemComponent: React.FC<Props> = ({ historyItem, avatarOwnedSkillProductSort }) => {

  const { changeSKillStatusToHandle } = useChangeSkillStatusToHandle(historyItem.avatar_owned_product_id, avatarOwnedSkillProductSort)

  return (
    <StyledShopProduct>
      <StyledShopProductImageWrapper>
        <ShopProductImageWrapper>
          <StyledOrderedSkillImage
            src={
              historyItem.image_url ||
              // @ts-ignore
              RailsAssetPath(`avatar_shop/skill_default.png`)
            }
          />
          <StyledUserIcon>
            <img
              src={
                historyItem.presented_user_image ||
                // @ts-ignore
                RailsAssetPath('noimage_icon.png')
              }
            />
          </StyledUserIcon>
        </ShopProductImageWrapper>
      </StyledShopProductImageWrapper>
      <StyledShopProductProductName>
        <p>{historyItem.product_name}</p>
      </StyledShopProductProductName>

      {
        historyItem.avatar_owned_product_id && historyItem.usage_status !== 1 && (
          <MyBoxSkillActionBtns used={historyItem.used} handled={historyItem.handled} handledHandler={changeSKillStatusToHandle} />
        )
      }

      <StyledExpiredAt expiredSoon={(new Date(historyItem.expired_at) < new Date(Date.now() + 3 * 24 * 60 * 60 * 1000))}>
        <p>
          {/* TODO */}
          {"期限: "}
          {historyItem.expired_at}
        </p>
      </StyledExpiredAt>

      <StyledShopProductPrice>
        {/* @ts-ignore */}
        <img src={RailsAssetPath('mypage/coin.png')} />
        <p>{historyItem.viket.toLocaleString()}</p>
      </StyledShopProductPrice>
    </StyledShopProduct>
  );
};
