import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import propTypes from 'prop-types';
import axios from 'axios';
import store from '../../lib/redux/store';
import { useTranslation } from 'react-i18next';


const FormAddWorkingHour = () => {
  // access redux store
  const currentUser = useSelector((state) => state.setUser.currentUser);

  const [isShowWorkingHourFormShow, setIsShowWorkingHourFormShow] =
    useGlobal('isShowWorkingHourFormShow');
  const [workingHours, setWorkingHours] = useState([]);
  const [selectedDate, handleDateChange] = useState(new Date());
  const { t } = useTranslation();
  const [formFields, setFormFields] = useState([]);
  const [deletedIds, setDeletedIds] = useState([]);

  const time_options = [];
  for (let h = 0; h < 24; h++) {
    for (let m = 0; m < 60; m += 15) {
      const time = ('0' + h).slice(-2) + ':' + ('0' + m).slice(-2);
      time_options.push(<option key={time} value={time}>{time}</option>);
    }
  }
  const minutes_options = Array.from({ length: 13 }, (_, i) => i * 5);

  useEffect(() => {
    getWorkingTimes();
  }, []);

  const getWorkingTimes = () => {
    axios.get('/api/v1/office/working_times').then((res) => {
      setWorkingHours(res.data);
    });
  };

  const formatTime = (minutes) => {
    const hour = Math.floor(minutes / 60);
    const minute = minutes % 60;
    return `${hour}:${('0' + minute).slice(-2)}`;
  }

  const handleDeleteItem = (index) => {
    const newData = [...workingHours];
    if (!newData[index].default) {
      setDeletedIds([...deletedIds, newData[index].id]);
      newData.splice(index, 1);
      setWorkingHours(newData);
    }
  }

  const handleAddField = () => {
    setFormFields([...formFields, { workingTime: '00:15', minute: '0' }]);
  }

  const handleRemoveField = (index) => {
    const values = [...formFields];
    values.splice(index, 1);
    setFormFields(values);
  }

  const handleFieldChange = (e, index, fieldName) => {
    const values = [...formFields];
    values[index][fieldName] = e.target.value;
    setFormFields(values);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const timeToMinutes = (timeString) => {
      const [hours, minutes] = timeString.split(':').map(Number);
      return hours * 60 + minutes;
    };
    try {
      const formData = new FormData();
      formFields.forEach((value, index) => {
        formData.append(`working_times[${index}]`, timeToMinutes(value.workingTime));
        formData.append(`minutes[${index}]`, value.minute);
      });
      formData.append('deleted_ids', deletedIds);
      const response = await axios.post('api/v1/office/working_times', formData);
      setIsShowWorkingHourFormShow(false);
      alert(t('AttendanceMessageUpdate', {}));
    } catch (error) {
      console.error(error);
      setIsShowWorkingHourFormShow(false);
      alert(t('AttendanceAlertServerError', {}));
    }
  }

  return (
    <WorkingHourFormWrapper>
      <WorkingHourFormClose
        onClick={() => setIsShowWorkingHourFormShow(false)}
      >
        <img src={RailsAssetPath('close-img-gray.png')} />
      </WorkingHourFormClose>
      <WorkingHourFormTitle>
        {t('AttendanceTextWorkHourSetting', {})}
      </WorkingHourFormTitle>
      <WorkingHourFormContent>
        <WorkingHourList>
          {workingHours && workingHours.map((value, index) => (
            <WorkingHourListItem key={value.id}>
              <WorkingHourListItemLabel>
                勤務
              </WorkingHourListItemLabel>
              <WorkingHourListItemValue>
                {formatTime(value.working_hour)}
              </WorkingHourListItemValue>
              <WorkingHourListItemLabel>
                休憩
                </WorkingHourListItemLabel>
              <WorkingHourListItemValue>
                {value.break_time}
              </WorkingHourListItemValue>
              <WorknignHourListDeleteButton>
                {!value.default && <button onClick={() => handleDeleteItem(index)}>
                  <WorknignHourListDeleteButtonImage src={RailsAssetPath('close-img-gray.png')} />
                  </button>
                }
              </WorknignHourListDeleteButton>
            </WorkingHourListItem>
          ))}
        </WorkingHourList>
        <WorkingHourForm onSubmit={handleSubmit}>

            {formFields.map((value, index) => (
              <WorkingHourFormItem key={index}>
                <WorkingHourFormLabel>
                  勤務
                </WorkingHourFormLabel>
                <WorkingHourFormSelect value={value.workingTime} onChange={(e) => handleFieldChange(e, index, 'workingTime')}>
                  {time_options}
                </WorkingHourFormSelect>
                <WorkingHourFormLabel>
                  休憩
                </WorkingHourFormLabel>
                <WorkingHourFormSelect value={value.minute} onChange={(e) => handleFieldChange(e, index, 'minute')}>
                {minutes_options.map((minute) => (
                  <option key={minute} value={minute}>{('0' + minute).slice(-2)}</option>
                ))}
                </WorkingHourFormSelect>
                <WorknignHourListDeleteButton>
                  <button onClick={() => handleRemoveField(index)}>
                    <WorknignHourListDeleteButtonImage src={RailsAssetPath('close-img-gray.png')} />
                  </button>
                </WorknignHourListDeleteButton>
              </WorkingHourFormItem>
            ))}
              <AddButton type="button" onClick={handleAddField}>＋</AddButton>
          <WorkingHourFormSubmit type="submit">Done</WorkingHourFormSubmit>
        </WorkingHourForm>
      </WorkingHourFormContent>
    </WorkingHourFormWrapper>
  );
};

const WorkingHourFormWrapper = styled.div`
  padding: 15px;
  position: relative;
`;

const WorkingHourFormContent = styled.div`
  text-align: center;
  font-size: 14px;
  padding: 20px 20px 35px;
  input {
    padding: 10px;
    cursor: pointer;
    text-align: center;
    color: #565656;
    letter-spacing: 1px;
  }
`;

const WorkingHourFormClose = styled.div`
  font-size: 14px;
  position: absolute;
  left: 15px;
  top: 15px;
  width: 15px;
  cursor: pointer;
`;

const WorkingHourFormTitle = styled.div`
  font-size: 17px;
  text-align: center;
  margin: 15px 0;
  color: #777777;
`;

const WOrkingHourFormContent = styled.div`
`;

const WorkingHourList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
  flex-direction: column;
  font-size: 14px;
  gap: 5px;
`;

const WorkingHourListItem = styled.div`
  display: flex;
  align-items: inherit;
`;

const WorkingHourListItemLabel = styled.div`
  margin-right: 15px;
  margin-left: 15px;
  min-width: 40px;
`;

const WorkingHourListItemValue = styled.div`
  min-width: 46px;
`;

const WorknignHourListDeleteButton = styled.div`
  min-width: 50px;
`;
const WorknignHourListDeleteButtonImage = styled.img`
  width: 15px;
`;

const WorkingHourFormAdd = styled.div`
  text-align: left;
  margin: 10px 0;
  cursor: pointer;
  letter-spacing: 1px;
`;

const WorkingHourForm = WorkingHourList.withComponent('form');


const WorkingHourFormItem = styled(WorkingHourListItem)`
`;

const WorkingHourFormLabel = styled(WorkingHourListItemLabel)`
`;

const AddButton = styled.button`
  width: 200px;
  height: 35px;
  margin-bottom: 42px;
`;
const WorkingHourFormSelect = styled.select`
  min-width: 46px;
  border: 1px solid #bfbfbf;
  color: #7b7b7b;
  border-radius: 7px;
  height: 35px;
  padding: 8px 0px 8px 0px;
  position: relative;
  text-align: center;
  appearance: none;
`;

const WorkingHourFormSubmit = styled.button`
  width: 200px;
  height: 35px;
  margin: 0 auto;
  background: #03a9f4;
  color: #fff;
  border-radius: 8px;
  font-size: 12px;
`;


export default FormAddWorkingHour;
