import axios from "axios";

export function fetchAttendanceData(params) {
  return (dispatch) => {
    axios.get('/api/v1/attendances', { params }).then(res => {
      dispatch({ 
        type: 'SET_ATTENDANCE_DATA', 
        userList: res.data,
        params: params
      });
    });
  }
}

export function fetchAttendanceApi(params) {
  return axios.get('/api/v1/attendances', { params });
}

export function setAttendanceApplicationDetail(args) {
  return (dispatch) => {
    dispatch({ 
      type: 'SET_ATTENDANCE_APPLICATION_DETAIL', 
      user: args.user, 
      applyDate: args.applyDate,
      applyType: args.applyType,
      isFuture: args.isFuture,
      applyFlg: args.applyFlg,
      fixFlg: args.fixFlg,
      planFlg: args.planFlg,
      otherFlg: args.otherFlg,
      fixholidayFlg: args.fixholidayFlg,
      ApprovedAttendanceFlg: args.ApprovedAttendanceFlg,
    })
  }
}