import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { RealWorldSettingMenu } from 'javascript/features/realWorld/components/RealWorldSettingMenu';
import { device } from 'javascript/utils/mediaQuery';
import { RealWorld } from 'types';

interface Props {
  realWorld: RealWorld;
}

export const RealWorldTitle: React.FC<Props> = ({ realWorld }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 430 });

  const toggleSetting = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };
  const hasUser = realWorld.users.length !== 0;

  return (
    <RealWorldTitleWrapper>
      <RealWorldName>{realWorld.name}</RealWorldName>
      <RealWorldSettingArea>
        <RealWorldCount>
          {realWorld.users.length !== 0 ? realWorld.users.length : ''}
        </RealWorldCount>
        {!isMobile && (
          <SettingIconWrapper onClick={(e) => toggleSetting(e)}>
            {/* @ts-ignore */}
            <img src={RailsAssetPath('dot.png')} />
            <RealWorldSettingMenu
              isOpen={isOpen}
              toggleSetting={toggleSetting}
              id={realWorld.id}
              hasUser={hasUser}
              creatorId={realWorld.user_id}
            />
          </SettingIconWrapper>
        )}
      </RealWorldSettingArea>
    </RealWorldTitleWrapper>
  );
};

const RealWorldTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
  font-size: 11px;
  color: #000;
  text-shadow: #fff 2px 0px 2px, #fff -2px 0px 2px, #fff 0px -2px 2px,
    #fff -2px 0px 2px, #fff 2px 2px 2px, #fff -2px 2px 2px, #fff 2px -2px 2px,
    #fff -2px -2px 2px, #fff 1px 2px 2px, #fff -1px 2px 2px, #fff 1px -2px 2px,
    #fff -1px -2px 2px, #fff 2px 1px 2px, #fff -2px 1px 2px, #fff 2px -1px 2px,
    #fff -2px -1px 2px, #fff 1px 1px 2px, #fff -1px 1px 2px, #fff 1px -1px 2px,
    #fff -1px -1px 2px;
  @media ${device.sp} {
    height: 20px;
    font-size: 10px;
  }
`;

const RealWorldName = styled.p`
  max-width: 176px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${device.sp} {
    max-width: 240px;
  }
`;

const RealWorldCount = styled.p`
  margin-right: 10px;
`;

const RealWorldSettingArea = styled.div`
  display: flex;
  align-items: center;
`;

const SettingIconWrapper = styled.div`
  position: relative;
  width: 20px;
  cursor: pointer;
  img {
    height: 12px;
  }
`;
