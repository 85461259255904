import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

const StyledButtonWrapper = styled.div`
  margin: 0 10px;
  cursor: pointer;
  font-weight: bold;
`;

const StyledButtonImage = styled.img`
  width: 80px;
  margin-bottom: 11px;
`;

interface Props {
  onClick: (zoom?: boolean) => void;
}

export const EnterButton: React.FC<Props> = ({ onClick: handleSubmit }) => (
  <StyledButtonWrapper
    onClick={() => {
      handleSubmit(false);
    }}
  >
    <StyledButtonImage
      // @ts-ignore
      src={RailsAssetPath('enter-room.png')}
      alt='icon-enter-door'
    />
    <div style={{ color: '#7387C1' }}>
      <Trans i18nKey='GenelalButtonEnter' />
    </div>
  </StyledButtonWrapper>
);
