import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import useLocalVideoToggle from '../hooks/useLocalVideoToggle';
import useDevices from '../hooks/useDevices';
import { isPermissionDenied } from '../utils';

const ToggleVideoButton = ({ className, style, disabled }) => {
  const { t } = useTranslation();
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = useCallback(async () => {
    const isCameraPermissionDenied = await isPermissionDenied('camera');
    if (isCameraPermissionDenied) {
      alert(t('VideocallPermissiondeniedVideo', {}));
      return;
    }

    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <Button
      className={className}
      onClick={toggleVideo}
      disabled={!hasVideoInputDevices || disabled}
      style={style}
    >
      {isVideoEnabled ? (
        <img
          src={RailsAssetPath('video_call/icon/video_on.png')}
          className='button_icon'
        />
      ) : (
        <img
          src={RailsAssetPath('video_call/icon/video_off.png')}
          className='button_icon'
        />
      )}
      {/* {!hasVideoInputDevices ? 'No Video' : isVideoEnabled ? 'Stop Video' : 'Start Video'} */}
    </Button>
  );
};

export default ToggleVideoButton;
