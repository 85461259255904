import React, {useRef, useState} from "react";
import styled from 'styled-components';
import useVideoCallContext from '../hooks/useVideoCallContext';
import {
  Button,
  Menu as MenuContainer,
  MenuItem,
  Typography,
  Divider,
} from '@material-ui/core';
import useDevices from '../hooks/useDevices';
import useMediaStreamTrack from '../hooks/useMediaStreamTrack';
import {　SELECTED_AUDIO_OUTPUT_KEY} from '../constants';
import { useAppState } from "../state";
import { useTranslation } from 'react-i18next';

const AudioDeviceSelectButton = ({className, disabled}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef(null);
  const { localTracks } = useVideoCallContext();
  const { t } = useTranslation();


  const { audioInputDevices, audioOutputDevices, hasAudioTrack } = useDevices();
  const localAudioTrack = localTracks.find(track => track.kind === 'audio');
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;
  const { activeSinkId, setActiveSinkId } = useAppState();
  const activeOutputLabel = audioOutputDevices?.find(device => device.deviceId === activeSinkId)?.label;
  function handleReplaceTrack(newDeviceId, kind) {
    if (kind == "audiooutput") {
      setActiveSinkId(newDeviceId);
    } else {
      window.localStorage.setItem( SELECTED_AUDIO_OUTPUT_KEY, newDeviceId);
      localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
    }
  }

  return (
    <>
    <Button
    className={className}
    onClick={() => setMenuOpen(isOpen => !isOpen)}
    // disabled={!hasAudioTrack}
    ref={anchorRef}
    data-cy-audio-setting-toggle
    style={{ 'minWidth': '10px' , 'padding': '0'}}
    >
       <img src={RailsAssetPath("video_call/icon/expand_less.png")} className="button_icon_small" /> 
    </Button>

    <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen(isOpen => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: audioInputDevices ? (audioInputDevices.length + audioOutputDevices.length) * 30 + 80 : 80, 
          horizontal: 0,
        }}
      >
        <Typography variant="subtitle2">{t("VideocallTextMic",{})}</Typography>
        {audioInputDevices && audioInputDevices.length >= 1 ? audioInputDevices.map(device => (
          <MenuItem onClick={() => handleReplaceTrack(device.deviceId , device.kind)}
           value={device.deviceId} 
           key={device.deviceId}
          >
            {localAudioInputDeviceId == device.deviceId ? (
              <IconContainer>
                <img src={RailsAssetPath("video_call/icon/checkmark.png")} className="button_icon_small" /> 
              </IconContainer>
            ):<IconContainer />
            }
            {device.deviceId == "default" ? t("VideocallTextSameSystem",{}) : device.label}
          </MenuItem>
          )): ""
        }
        <Divider orientation="horizontal" variant="middle" style={{"background": "#707070", "margin": "10px"}} />
        <Typography variant="subtitle2">{t("VideocallTextSpeaker",{})}</Typography>
        {audioOutputDevices && audioOutputDevices.length >= 1 ? audioOutputDevices.map(device => (
          <MenuItem onClick={() => setActiveSinkId(device.deviceId)}
           value={device.deviceId} 
           key={device.deviceId}
          >
            {activeSinkId == device.deviceId ? (
              <IconContainer>
                <img src={RailsAssetPath("video_call/icon/checkmark.png")} className="button_icon_small" /> 
              </IconContainer>
            ):<IconContainer />
            }
            {device.deviceId == "default" ? t("VideocallTextSameSystem",{}) : device.label}
          </MenuItem>
          )): ""
        }
        
      </MenuContainer>
    </>
  )
}

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 1em;
  margin-right: 0.3em;
`

export default AudioDeviceSelectButton;