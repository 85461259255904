import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSellProduct } from 'javascript/features/mybox/hooks/useSellProduct';
import { AvatarPaidProductType, OwnedProductInMybox } from 'types';
import { useSellQuantity } from 'javascript/features/mybox/hooks/useSellQuantity';
import { AvatarOwnedSkillProductSort } from '../hooks/useMybox';

const StyledSellModalTitle = styled.p`
  margin-top: 32px;
  font-weight: bold;
`;

const StyledSellModalImageWrapper = styled.div<{ isClothes: boolean }>`
  margin-top: 16px;
  img {
    max-width: 104px;
    max-height: 104px;
  }
`;

const StyledPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  img {
    width: 20px;
  }
  p {
    margin-left: 4px;
    font-size: 24px;
  }
`;

const StyledAlertMessage = styled.p`
  margin-top: 4px;
  color: #e00000;
  font-size: 12px;
`;

const StyledItemCount = styled.p`
  margin-top: 16px;
  font-size: 14px;
`;

const StyledQuantityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  button {
    color: #000;
    font-size: 20px;
  }
  input {
    width: 40px;
    border-radius: 2px;
    border: 1px solid #e2e8f0;
    text-align: center;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }
  }
`;

const StyledSellButton = styled.button`
  width: 152px;
  margin-top: 16px;
  padding: 8px 16px;
  color: #ec6a40;
  border: 2px solid #ec6a40;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translate(-1px, -1px);
    box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 5%), 4px 4px 4px 0px rgb(0 0 0 / 5%);
  }
`;

interface Props {
  product: OwnedProductInMybox;
  selectedShopProductType: AvatarPaidProductType;
  avatarOwnedSkillProductSort: AvatarOwnedSkillProductSort,
  toggleModal: () => void;
}

export const SellModalBody: React.FC<Props> = ({
  product,
  selectedShopProductType,
  avatarOwnedSkillProductSort,
  toggleModal,
}) => {
  const { t } = useTranslation();

  const { incrementQuantity, decrementQuantity, changeQuantity, quantity } =
    useSellQuantity(product.item_count);

  const { handleSellProduct } = useSellProduct(product, quantity, avatarOwnedSkillProductSort, toggleModal);

  return (
    <>
      <StyledSellModalTitle>
        {t('MyboxSellmodalAskifsell', {})}
      </StyledSellModalTitle>
      <StyledSellModalImageWrapper
        isClothes={selectedShopProductType === 'clothes'}
      >
        <img
          src={
            selectedShopProductType !== 'hair'
              ? // @ts-ignore
              RailsAssetPath(
                `avatar_parts/${selectedShopProductType}/${product.product_number}.png`
              )
              : // @ts-ignore
              RailsAssetPath(
                `avatar_parts/${selectedShopProductType}/black/${product.product_number}.png`
              )
          }
        />
      </StyledSellModalImageWrapper>
      <StyledPriceWrapper>
        {/* @ts-ignore */}
        <img src={RailsAssetPath('mypage/coin.png')} />
        <p>{(Math.floor(product.viket * 0.8) * quantity).toLocaleString()}</p>
      </StyledPriceWrapper>
      <StyledAlertMessage>
        {t('MyboxSellmodalAlerttosellhalfprice', {})}
      </StyledAlertMessage>
      <StyledItemCount>
        {t('MyboxSellmodalItemcount')}： {product.item_count}
      </StyledItemCount>
      {product.item_count > 1 && (
        <StyledQuantityWrapper>
          <button onClick={incrementQuantity}>▲</button>
          <input
            value={quantity}
            onChange={(e) => changeQuantity(e)}
            type='number'
            name='quantity'
            min='1'
            max={product.item_count}
          />
          <button onClick={decrementQuantity}>▼</button>
        </StyledQuantityWrapper>
      )}

      <StyledSellButton onClick={handleSellProduct}>
        {t('MyboxDefaultmodalSell', {})}
      </StyledSellButton>
    </>
  );
};
