import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import {
  HeaderNavItem,
  NavItem as NavHelp,
  NavIcon as NavHelpIcon,
} from 'javascript/components/common/Header/components/HeaderNavItem';
import { HelpMenu } from 'javascript/components/common/Header/components/HelpMenu';
import { device } from 'javascript/utils/mediaQuery';

const StyledCastNavMenu = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 424px;
  padding-right: 8px;
  letter-spacing: 1px;
  font-size: 10px;
  color: #a4a4a4;
  @media ${device.tab} {
    width: 328px;
  }
  @media ${device.sp} {
    width: 144px;
    padding-right: 8px;
    letter-spacing: 0;
    p {
      display: none;
    }
  }
  .isActive {
    img {
      filter: none;
      opacity: 1;
    }
    p {
      font-weight: bold;
      color: #000;
    }
  }
`;

export const HeaderCastNavMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleHelpMenu = () => setIsOpen(!isOpen);
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 430 });

  return (
    <StyledCastNavMenu>
      <NavLink to='/office' activeClassName='isActive'>
        <HeaderNavItem img='town_blue.png' text={t('MenuHeaderTown', {})} />
      </NavLink>
      <NavLink to='/diary' activeClassName='isActive'>
        <HeaderNavItem img='feed_blue.png' text={t('MenuHeaderFeed')} />
      </NavLink>
      <NavLink to='/shop' activeClassName='isActive'>
        <HeaderNavItem img='shop_blue.png' text={t('MenuUserShop')} />
      </NavLink>
      {!isMobile && (
        <NavLink to='/attendance' activeClassName='isActive'>
          <HeaderNavItem
            img='attendance_blue.png'
            text={t('MenuHeaderAttendance')}
          />
        </NavLink>
      )}
      <NavHelp
        onClick={() => {
          toggleHelpMenu();
        }}
      >
        {/* @ts-ignore */}
        <NavHelpIcon src={RailsAssetPath('help.png')} alt='icon-help' />
        <p>{t('MenuHeaderHelp', {})}</p>
      </NavHelp>
      <HelpMenu
        isMaster={false}
        isOpen={isOpen}
        toggleHelpMenu={toggleHelpMenu}
      />
    </StyledCastNavMenu>
  );
};
