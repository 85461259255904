import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useFilterMenuModal } from 'javascript/features/virtualWorld/hooks/useFilterMenuModal'
const StyledModalBackground = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  cursor: default;;
`;

const StyledModalMenu = styled.div<{
  position: { x: number; y: number } | null;
}>`
  position: fixed;
  top: ${({ position }) => position && `${position.y + 60}px`};
  left: ${({ position }) => position && `${position.x - 100}px`};
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  color: #303030;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 3px 0px grey;
  border-radius: 8px;
  font-size: 10px;
  z-index: 11;
  backdrop-filter: blur(12px);
`;

const StyledModalItem = styled.div<{
  isUse: boolean
}>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
  div {
    display: flex;
  }
  img {
    width: 16px;
    margin: 0;
  }
  p {
    margin-left: 8px;
  }
  color: ${({isUse}) => (isUse) ? "#00A0E9" : "545454"}
`
interface Props {
  modalPosition: { x: number; y: number } | null;
  closeMenuModal: (e: React.MouseEvent<HTMLDivElement>) => void;
  closeModalAfterSetFilter: (filterId: number) => void;
}

export const FilterMenuModal: React.FC<Props> = ({
  modalPosition,
  closeMenuModal,
  closeModalAfterSetFilter,
}) => {
  const { t } = useTranslation();
  const { roomFilterId } = useFilterMenuModal();

  return (
    <StyledModalBackground onClick={closeMenuModal}>
      <StyledModalMenu position={modalPosition}>
        <StyledModalItem 
          isUse={1 == roomFilterId}
          onClick={()=>closeModalAfterSetFilter(1)}
        >
          {/* @ts-ignore */}
          <img src={RailsAssetPath('filter_modal/all_' + ((1 == roomFilterId) ? 'blue' : 'gray')  + '.png')} />
          <p>{t('ModalFilterMenuAll', {})}</p>
        </StyledModalItem> 
        <StyledModalItem
          isUse={2 == roomFilterId}
          onClick={()=>closeModalAfterSetFilter(2)}
        >
          {/* @ts-ignore */}
          <img src={RailsAssetPath('filter_modal/favorite_' + ((2 == roomFilterId) ? 'blue' : 'gray')  + '.png')} />
          <p>{t('ModalFilterMenuFavorite', {})}</p>
        </StyledModalItem> 
        <StyledModalItem 
          isUse={3 == roomFilterId}
          onClick={()=>closeModalAfterSetFilter(3)}
        >
          {/* @ts-ignore */}
          <img src={RailsAssetPath('filter_modal/someones_' + ((3 == roomFilterId) ? 'blue' : 'gray')  + '.png')} />
          <p>{t('ModalFilterMenuSomonesHere', {})}</p>
        </StyledModalItem> 
        <StyledModalItem 
          isUse={4 == roomFilterId}
          onClick={()=>closeModalAfterSetFilter(4)}
        >
          {/* @ts-ignore */}
          <img src={RailsAssetPath('filter_modal/delete_' + ((4 == roomFilterId) ? 'blue' : 'gray')  + '.png')} />
          <p>{t('ModalFilterMenuUnused', {})}</p>
        </StyledModalItem> 
       </StyledModalMenu>
    </StyledModalBackground>
  );
}
