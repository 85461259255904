/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, {
  Pagination,
  Autoplay,
  Controller,
  EffectCoverflow,
} from 'swiper';
import styled, { css, keyframes } from 'styled-components';
import {
  MQ_DESKTOP,
  MQ_MOBILE,
} from 'javascript/components/landingPage/lib/MQ';
import { pcPx, px, spp } from 'javascript/components/landingPage/lib/cssUtil';
import {
  BLACK,
  fadeInStyle,
  GRAY,
  jpStyle,
  onlyPC,
  onlySP,
} from 'javascript/components/landingPage/local/commonCss';
import {
  onScrollPromise,
  removeOnscrollEvent,
} from 'javascript/components/landingPage/local/scrollPromise';
import { useIsSpStoreValue } from 'javascript/components/landingPage/store/isSpStore';
import {
  LANGUAGE,
  useLanguageStoreValue,
} from 'javascript/components/landingPage/store/language';
import { useScrollYStoreValue } from 'javascript/components/landingPage/store/scrollYStore';

// @ts-ignore
const ASSETS_SHOP = RailsAssetPath('lp/avatarshop/shop.png');
// @ts-ignore
const ASSETS_SHOP_PC = RailsAssetPath('lp/avatarshop/shop_pc.png');
// @ts-ignore
const ASSETS_DETAIL = RailsAssetPath('lp/avatarshop/detail.png');
// @ts-ignore
const ASSETS_DETAIL_PC = RailsAssetPath('lp/avatarshop/detail_pc.png');
// @ts-ignore
const ASSETS_MYBOX = RailsAssetPath('lp/avatarshop/mybox.png');
// @ts-ignore
const ASSETS_MYBOX_PC = RailsAssetPath('lp/avatarshop/mybox_pc.png');

const path = keyframes({
  from: { strokeDashoffset: spp(-1500) },
  to: { strokeDashoffset: spp(1500) },
});

const pathPc = keyframes({
  from: { strokeDashoffset: pcPx(-1550) },
  to: { strokeDashoffset: pcPx(1550) },
});

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  ${MQ_DESKTOP} {
    padding-bottom: ${pcPx(200)};
    &:before {
      content: '';
      background-color: rgba(255, 255, 255, 0.39);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: ${pcPx(1800)};
      height: 100%;
      top: 0;
    }
  }
  .swiper {
    overflow: visible;
    position: relative;
    perspective-origin: -10% 0%;
    ${MQ_MOBILE} {
      padding-bottom: ${spp(60)};
    }
    ${MQ_DESKTOP} {
      width: 800px;
      margin: auto;
      padding-top: ${pcPx(30)};
      padding-bottom: ${pcPx(100)};
      padding-right: ${pcPx(400)};
    }
  }
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    display: flex;
  }
  .swiper-slide {
    width: 100%;
    height: 100%;
    opacity: 0.42;
    transform-origin: left top;
    transition: 0.3s opacity ease, 1.3s transform ease;
    ${MQ_MOBILE} {
      transform: translate3d(0, 0, 0);
    }
    ${MQ_DESKTOP} {
      transform: translate3d(${pcPx(0)}, ${pcPx(0)}, ${pcPx(0)});
    }
    &[data-prev='true'] {
      opacity: 0;
      ${MQ_MOBILE} {
        transform: translate3d(
          ${spp(100)},
          ${spp(500)},
          ${spp(200)}
        ) !important;
      }
      ${MQ_DESKTOP} {
        transform: translate3d(
          ${pcPx(100)},
          ${pcPx(500)},
          ${pcPx(200)}
        ) !important;
      }
    }
  }
  .swiper-slide-next {
    ${MQ_MOBILE} {
      transform: translate3d(0, 0, 0);
    }
    ${MQ_DESKTOP} {
      transform: translate3d(${pcPx(0)}, ${pcPx(0)}, ${pcPx(0)});
    }
  }
  .swiper-slide-prev {
    opacity: 0;
    ${MQ_MOBILE} {
      transform: translate3d(${spp(0)}, ${spp(300)}, ${spp(100)}) !important;
    }
    ${MQ_DESKTOP} {
      transform: translate3d(${pcPx(0)}, ${pcPx(300)}, ${pcPx(100)}) !important;
    }
  }
  .swiper-slide-active {
    opacity: 1;
    ${MQ_MOBILE} {
      transform: translate3d(0, 0, 0);
    }
    ${MQ_DESKTOP} {
      transform: translate3d(0, 0, 0);
    }
  }
  .swiper-pagination {
    display: flex;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    justify-content: space-around;
    align-items: center;
    ${MQ_MOBILE} {
      width: ${spp(160)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(200)};
    }
  }
  .swiper-pagination-bullet {
    opacity: 1;
    background-color: rgba(49, 49, 49, 0.45);
    ${MQ_MOBILE} {
      width: ${spp(13)};
      height: ${spp(13)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(14)};
      height: ${pcPx(14)};
    }
  }
  .swiper-pagination-bullet-active {
    background-color: ${BLACK};
    ${MQ_MOBILE} {
      width: ${spp(19)};
      height: ${spp(19)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(20)};
      height: ${pcPx(20)};
    }
  }
`;

const Title = styled.h3`
  ${jpStyle}
  ${fadeInStyle}
  color: ${BLACK};
  font-weight: bold;
  position: relative;
  text-align: center;
  overflow: visible;
  ${MQ_MOBILE} {
    font-size: ${spp(26)};
    line-height: ${spp(45)};
    margin-top: ${spp(70)};
    margin-bottom: ${spp(35)};
    padding: 0 ${spp(32.5)};
    padding-bottom: ${spp(35)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(36)};
    line-height: ${pcPx(80)};
    margin-top: ${pcPx(100)};
    margin-bottom: ${pcPx(35)};
    padding: 0 ${pcPx(50)};
    padding-bottom: ${pcPx(30)};
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    background-color: ${BLACK};
    ${MQ_MOBILE} {
      width: ${spp(103)};
      height: ${spp(4)};
      bottom: ${spp(4)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(176)};
      height: ${pcPx(4)};
      bottom: ${pcPx(4)};
    }
  }
`;

const SlideTitle = styled.h3`
  ${jpStyle}
  color: ${BLACK};
  font-weight: bold;
  position: relative;
  text-align: center;
  overflow: visible;
  ${MQ_MOBILE} {
    font-size: ${spp(19)};
    line-height: ${spp(42)};
    margin-top: ${spp(60)};
    margin-bottom: ${spp(15)};
    padding-bottom: ${spp(25)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(36)};
    line-height: ${pcPx(80)};
    margin-top: ${pcPx(100)};
    margin-bottom: ${pcPx(35)};
    padding-bottom: ${pcPx(30)};
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    background-color: ${BLACK};
    ${MQ_MOBILE} {
      width: ${spp(103)};
      height: ${spp(4)};
      bottom: ${spp(4)};
    }
    ${MQ_DESKTOP} {
      width: ${pcPx(176)};
      height: ${pcPx(4)};
      bottom: ${pcPx(4)};
    }
  }
`;

const Text = styled.p`
  ${jpStyle}
  ${fadeInStyle}
  transition-delay: 0.1s;
  color: ${BLACK};
  position: relative;
  text-align: center;
  overflow: visible;
  ${MQ_MOBILE} {
    font-size: ${spp(18)};
    line-height: ${spp(31)};
    margin-top: ${spp(10)};
    margin-bottom: ${spp(40)};
    padding: 0 ${spp(32.5)};
  }
  ${MQ_DESKTOP} {
    font-size: ${pcPx(24)};
    line-height: ${pcPx(41)};
    margin-top: ${pcPx(5)};
    padding: 0 ${pcPx(50)};
    padding-bottom: ${pcPx(40)};
    margin-bottom: ${pcPx(0)};
  }
`;

const SwiperWrapper = styled.div`
  ${fadeInStyle}
`;

const Img = styled.pre({
  display: 'block',
  margin: 'auto',
  [MQ_MOBILE]: {
    width: spp(349),
    height: spp(755),
    background: `url(${ASSETS_SHOP}) no-repeat`,
    backgroundSize: 'contain',
    marginTop: spp(60),
    marginBottom: spp(40),
  },
  [MQ_DESKTOP]: {
    width: pcPx(1078),
    height: pcPx(605),
    background: `url(${ASSETS_SHOP_PC}) no-repeat`,
    backgroundSize: 'contain',
    marginBottom: pcPx(30),
    filter: `drop-shadow(0 ${pcPx(3)} ${pcPx(6)} rgba(0, 0, 0, 0.15))`,
  },
});

const svgStyle = css`
  position: absolute;
  margin: auto;
  svg {
    width: 100%;
    height: 100%;
    g {
      stroke: ${GRAY};
    }
  }
`;

const BoxStyle = css`
  ${svgStyle}
  ${onlySP}
  ${MQ_MOBILE} {
    width: ${spp(371)};
    height: ${spp(532)};
    top: ${spp(80)};
    left: ${spp(-270)};
  }
`;

const Box = styled.div`
  ${BoxStyle}
`;

const BoxAnim = styled.div`
  ${BoxStyle}
  svg {
    path {
      stroke: ${BLACK};
      stroke-width: 2;
      stroke-dashoffset: ${spp(1500)};
      stroke-dasharray: ${spp(1500)};
      animation: ${path} 3s linear forwards infinite;
    }
  }
`;

const BoxPcStyle = css`
  ${svgStyle}
  ${onlyPC}
  ${MQ_DESKTOP} {
    width: ${pcPx(544)};
    height: ${pcPx(756)};
    top: ${pcPx(200)};
    right: ${pcPx(0)};
    left: ${pcPx(-2200)};
  }
`;

const BoxPc = styled.div`
  ${BoxPcStyle}
`;

const BoxPcAnim = styled.div`
  ${BoxPcStyle}
  svg {
    g {
      stroke: ${BLACK};
      stroke-width: 2;
      stroke-dashoffset: ${pcPx(1500)};
      stroke-dasharray: ${pcPx(1500)};
      animation: ${pathPc} 3s linear forwards infinite;
    }
  }
`;

const BoxPc02Style = css`
  ${svgStyle}
  ${onlyPC}
  ${MQ_DESKTOP} {
    width: ${pcPx(544)};
    height: ${pcPx(756)};
    top: ${pcPx(1000)};
    right: ${pcPx(0)};
    left: ${pcPx(-2200)};
  }
`;

const BoxPc02 = styled.div`
  ${BoxPc02Style}
`;

const AvatarShop = () => {
  SwiperCore.use([Pagination, Autoplay, Controller, EffectCoverflow]);
  const [windowWidth, setWindowWidth] = useState(0);
  const [swiper, setSwiper] = useState<any>(null);
  const [id, setId] = useState(0);
  const isSlideMove = useRef(false);
  const slideX = useRef(0);
  const isSpStoreValue = useIsSpStoreValue();
  const LENGTH = 3;
  const fvRef = useRef<HTMLDivElement | null>(null);
  const imgRef = useRef<HTMLDivElement | null>(null);
  const [isFvRefScrolled, setIsFvRefScrolled] = useState(false);
  const [isImgRefScrolled, setIsImgRefScrolled] = useState(false);
  const boxRef = useRef<HTMLDivElement | null>(null);
  const boxPcRef = useRef<HTMLDivElement | null>(null);
  const boxPc02Ref = useRef<HTMLDivElement | null>(null);
  const LIST = [ASSETS_SHOP, ASSETS_DETAIL, ASSETS_MYBOX];
  const LIST_PC = [ASSETS_SHOP_PC, ASSETS_DETAIL_PC, ASSETS_MYBOX_PC];
  const scrollYStoreValue = useScrollYStoreValue();
  const languageStoreValue = useLanguageStoreValue();
  const { t } = useTranslation();

  const getY = (
    ref: MutableRefObject<HTMLDivElement | null>,
    scrollY: number
  ) => {
    if (!ref || !ref.current) return 0;
    const y =
      (scrollY -
        (ref.current.getBoundingClientRect().top + window.pageYOffset)) *
      0.1;
    return y;
  };

  const onTouchStart = (swiper: any) => {
    isSlideMove.current = true;
    slideX.current = swiper.touches.startX;
  };

  const onTouchEnd = (swiper: any) => {
    if (!isSlideMove.current) return;
    isSlideMove.current = false;
    if (slideX.current < swiper.touches.startX) {
      swiper.slidePrev();
    } else {
      swiper.slideNext();
    }
  };

  useEffect(() => {
    const onResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', onResize);

    onResize();
    return () => {
      window.addEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    const LOOP_LENGTH = LENGTH * 3;
    if (!swiper) return;
    const children = swiper.$wrapperEl[0].children;

    for (let i = 0; i < LOOP_LENGTH; i++) {
      children[i].style.opacity = 0;
      children[i].setAttribute('data-prev', false);
    }
    // swiper-prev 以前のスライド
    for (let i = 0; i < id - 1; i++) {
      children[i].setAttribute('data-prev', true);
    }
    children[id + 1].style.opacity = 0.42;
    children[id + 2].style.opacity = 0.42;
    children[id].style.opacity = 1;
  }, [id]);

  useEffect(() => {
    onScrollPromise(fvRef.current)().then(() => setIsFvRefScrolled(true));
    onScrollPromise(imgRef.current)().then(() => setIsImgRefScrolled(true));
    return () => {
      removeOnscrollEvent(fvRef.current);
      removeOnscrollEvent(imgRef.current);
    };
  }, []);

  return (
    <Wrapper>
      <Title
        ref={fvRef}
        data-visible={isFvRefScrolled}
        data-lang-ru={languageStoreValue === LANGUAGE.ru}
      >
        {t('avatarshop.title')}
      </Title>
      <Text
        data-visible={isFvRefScrolled}
        data-lang-ru={languageStoreValue === LANGUAGE.ru}
      >
        {t('avatarshop.text')}
      </Text>
      <SwiperWrapper ref={imgRef} data-visible={isImgRefScrolled}>
        <Swiper
          spaceBetween={isSpStoreValue ? windowWidth * -0.1 : 0}
          slidesPerView='auto'
          centeredSlides
          speed={1200}
          loop
          effect='coverflow'
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 500,
            modifier: 0.7,
            slideShadows: false,
          }}
          allowTouchMove={false}
          pagination={{ clickable: true }}
          onSwiper={setSwiper}
          onLoopFix={(swiper) => {
            setId(swiper.activeIndex);
          }}
          onSlideChange={(swiper) => {
            setId(swiper.activeIndex);
          }}
          onTouchStart={(swiper) => onTouchStart(swiper)}
          onTouchEnd={(swiper) => onTouchEnd(swiper)}
        >
          {isSpStoreValue
            ? LIST.map((img, i) => (
                <SwiperSlide key={i}>
                  <SlideTitle data-lang-ru={languageStoreValue === LANGUAGE.ru}>
                    {t(`avatarshop.slide0${i + 1}Title`)}
                  </SlideTitle>
                  <Img style={{ backgroundImage: `url(${img})` }} />
                  <Text data-lang-ru={languageStoreValue === LANGUAGE.ru}>
                    {t(`avatarshop.slide0${i + 1}Text`)}
                  </Text>
                </SwiperSlide>
              ))
            : LIST_PC.map((img, i) => (
                <SwiperSlide key={i}>
                  <SlideTitle data-lang-ru={languageStoreValue === LANGUAGE.ru}>
                    {t(`avatarshop.slide0${i + 1}Title`)}
                  </SlideTitle>
                  <Img style={{ backgroundImage: `url(${img})` }} />
                  <Text data-lang-ru={languageStoreValue === LANGUAGE.ru}>
                    {t(`avatarshop.slide0${i + 1}Text`)}
                  </Text>
                </SwiperSlide>
              ))}
          {/* <SwiperSlide>
            <SlideTitle data-lang-ru={languageStoreValue === LANGUAGE.ru}>
              {t("avatarshop.slide01Title")}
            </SlideTitle>
            <Img />
            <Text data-lang-ru={languageStoreValue === LANGUAGE.ru}>
              {t("avatarshop.slide01Text")}
            </Text>
          </SwiperSlide>
          <SwiperSlide>
          <SlideTitle data-lang-ru={languageStoreValue === LANGUAGE.ru}>
              {t("avatarshop.slide02Title")}
            </SlideTitle>
            <Img02 />
            <Text data-lang-ru={languageStoreValue === LANGUAGE.ru}>
              {t("avatarshop.slide02Text")}
            </Text>
          </SwiperSlide>
          <SwiperSlide>
            <SlideTitle>MYBOX</SlideTitle>
            <Img03 />
            <Text data-lang-ru={languageStoreValue === LANGUAGE.ru}>
              {t("avatarshop.slide03Text")}
            </Text>
          </SwiperSlide> */}
        </Swiper>
      </SwiperWrapper>
      <Box
        ref={boxRef}
        style={{
          transform: `translate3d(0, ${px(
            getY(boxRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='588.073'
          height='646.924'
          viewBox='0 0 588.073 646.924'
        >
          <g transform='translate(0 185.824) rotate(-30)' fill='none'>
            <path
              d='M32.916,23.686,371.648,0,338.732,508.746,0,532.432Z'
              stroke='none'
            />
            <path
              d='M 370.5764465332031 1.07733154296875 L 33.85751342773438 24.62216186523438 L 1.071807861328125 531.3544921875 L 337.7907409667969 507.8096618652344 L 370.5764465332031 1.07733154296875 M 371.6482543945312 0 L 338.7322692871094 508.7462463378906 L 0 532.4318237304688 L 32.91598510742188 23.68557739257812 L 371.6482543945312 0 Z'
              stroke='none'
              fill='#707070'
            />
          </g>
        </svg>
      </Box>
      <BoxAnim
        style={{
          transform: `translate3d(0, ${px(
            getY(boxRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='588.073'
          height='646.924'
          viewBox='0 0 588.073 646.924'
        >
          <g transform='translate(0 185.824) rotate(-30)' fill='none'>
            <path
              d='M 370.5764465332031 1.07733154296875 L 33.85751342773438 24.62216186523438 L 1.071807861328125 531.3544921875 L 337.7907409667969 507.8096618652344 L 370.5764465332031 1.07733154296875 M 371.6482543945312 0 L 338.7322692871094 508.7462463378906 L 0 532.4318237304688 L 32.91598510742188 23.68557739257812 L 371.6482543945312 0 Z'
              stroke='none'
              fill='#707070'
            />
          </g>
        </svg>
      </BoxAnim>
      <BoxPc
        ref={boxPcRef}
        style={{
          transform: `translate3d(0, ${px(
            getY(boxPcRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='849.118'
          height='926.715'
          viewBox='0 0 849.118 926.715'
        >
          <g
            transform='translate(0 272) rotate(-30)'
            fill='none'
            stroke='#707070'
            strokeWidth='1'
          >
            <rect width='544' height='756' stroke='none' />
            <rect x='0.5' y='0.5' width='543' height='755' fill='none' />
          </g>
        </svg>
      </BoxPc>
      <BoxPcAnim
        style={{
          transform: `translate3d(0, ${px(
            getY(boxPcRef, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='849.118'
          height='926.715'
          viewBox='0 0 849.118 926.715'
        >
          <g
            transform='translate(0 272) rotate(-30)'
            fill='none'
            stroke='#707070'
            strokeWidth='1'
          >
            <rect width='544' height='756' stroke='none' />
            <rect x='0.5' y='0.5' width='543' height='755' fill='none' />
          </g>
        </svg>
      </BoxPcAnim>
      <BoxPc02
        ref={boxPc02Ref}
        style={{
          transform: `translate3d(0, ${px(
            getY(boxPc02Ref, scrollYStoreValue)
          )}, 0)`,
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='731.308'
          height='876.66'
          viewBox='0 0 731.308 876.66'
        >
          <g
            transform='translate(0 149.946) rotate(-16)'
            fill='none'
            stroke='#707070'
            strokeWidth='1'
          >
            <rect width='544' height='756' stroke='none' />
            <rect x='0.5' y='0.5' width='543' height='755' fill='none' />
          </g>
        </svg>
      </BoxPc02>
    </Wrapper>
  );
};

export default AvatarShop;
