import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import { useSelector, connect } from "react-redux";
import store from "../../lib/redux/store";
import { fetchUserData } from "../../lib/redux/actions/user";
import { fetchMemberData } from "../../lib/redux/actions/member";
import axios from "axios";
import dayjs from "dayjs";
import DayjsUtils from "@date-io/dayjs";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import ProfileThumbnail from "../items/ProfileThumbnail";
import { useTranslation } from 'react-i18next';

const USER_ROLE = {
  MASTER: "master",
  MEMBER: "member",
};

//datepicker style
const materialTheme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        width: "220px",
      },
      input: {
        background: "#eeeeee",
        textAlign: "center",
        padding: "5px",
        height: "30px",
        borderRadius: "8px",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "5px",
      },
      notchedOutline: {
        border: "none",
      },
    },
    MuiIconButton: {
      root: {
        padding: "10px",
        width: "40px",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#00a0e9',
        '&:hover': {
          backgroundColor: '#00a0e9',
        }
      }
    }
  },
});

const FormEditProfile = ({ user, roles }) => {
  // access redux store
  const currentUser = useSelector((state) => state.setUser.currentUser);

  const [mailRecieve, setMailRecieve] = useState(currentUser.work_send_mail);
  const [isShowProfileModal, setIsShowProfileModal] = useGlobal("isShowProfileModal");
  const [uploadImage, setUploadImage] = useState(user.user_image);
  const [editedName, setEditedName] = useState(user.user_name);
  const [image, setImage] = useState(user.user_image);
  const [uploaded, setUploaded] = useState(false);
  const [deleteImage, setDeleteImage] = useState(false);

  const [selectList, setSelectList] = useState({
    ...selectList,
    role: user.current_role_id,
    team: user.current_team_id,
    position: user.current_position_id,
  });

  const [joinDate, setJoinDate] = useState(
    user.join_date ? user.join_date : new Date()
  );
  const [birthDate, setBirthDate] = useState(
    user.birth_date ? user.birth_date : null
  );
  const [isOpen, setIsOpen] = useState({ join: false, birth: false });
  const [teamList, setTeamList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const { t } = useTranslation();

  // whether current user has master role
  let isMaster;
  if (currentUser.role == USER_ROLE.MASTER) {
    isMaster = true;
  }

  useEffect(() => {
    getTeamList();
    getPositionList();
  }, []);

  const getTeamList = () => {
    axios.get("/api/v1/teams").then((res) => {
      setTeamList(res.data);
    });
  };

  const getPositionList = () => {
    axios.get("/api/v1/positions").then((res) => {
      setPositionList(res.data);
    });
  };

  const onChangeName = (e) => {
    setEditedName(e.target.value);
  };

  const onChangeSelect = (e) => {
    const { name, value } = e.target;
    const obj = { ...selectList };
    obj[name] = value;
    setSelectList(obj);
  };

  const onChangeUpload = (e) => {
    const file = e.target.files[0];
    setUploadImage(e.target.files[0]);
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      setUploaded(true);
    }
    reader.onloadend = () => {
      setImage(reader.result);
    };
  };

  const params = {
    user_name: editedName,
    role_id: selectList.role,
    join_date: dayjs(joinDate).format("YYYYMMDD"),
    birth_date: dayjs(birthDate).format("YYYYMMDD"),
    team_id: selectList.team,
    position_id: selectList.position,
    user_image_delete_flg: false,
    work_send_mail: mailRecieve,
  };

  const submit = (data) => {
    const url = `/api/v1/users/${user.user_id}`;
    const config = {
      headers: { "content-type": "multipart/form-data" },
      params: params,
    };
    return axios.patch(url, data, config);
  };

  const apiParams = useSelector((state) => state.setMemberProfile.params);
  const updateMemberData = () => {
    store.dispatch(fetchMemberData(apiParams));
  };

  const updateUserInfo = () => {
    store.dispatch(fetchUserData());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (uploaded) {
      formData.append("user_image", uploadImage);
    } else if (deleteImage) {
      params.user_image_delete_flg = true;
    }
    submit(formData)
      .then((res) => {
        setIsShowProfileModal(false);
        updateUserInfo();
        updateMemberData();
        store.dispatch({
          type: 'SET_MEMBER_PROFILE_DETAIL',
          submitted: true,
          user: res.data,
        });
        alert(t("GenelalAlertSaveComplete",{}));
      })
      .catch((error) => {
        alert(t("GenelalAlertSaveFailed",{}));
      });
  };

  const handleDeleteImage = () => {
    setImage("");
    setUploadImage("");
    setDeleteImage(true);
  };

  const onChangeChecked = () => {
    setMailRecieve(!mailRecieve)
  }

  return (
    <React.Fragment>
      <div className="profile-edit">
        <div
          className="profile-edit-close"
          onClick={() => {
            setIsShowProfileModal(false);
          }}
        >
          ×
        </div>
        <div>
          <p className="profile-edit-header">{t("ProfileTextChangeProfile",{})}</p>
          <ProfileThumbnail image={image ? image : uploadImage} size="150px" />
          <form className="profile-edit__form" onSubmit={handleSubmit}>
            <input
              type="text"
              className="user__name"
              defaultValue={editedName}
              onChange={onChangeName}
            />
            <label htmlFor="fileUpload" className="user__img">
              {t("ProfileTextUploadImage",{})}
            </label>
            <input
              type="file"
              accept="image/*"
              id="fileUpload"
              className=""
              style={{ display: "none" }}
              onChange={onChangeUpload}
            />
            <div className="user__delete-img" onClick={handleDeleteImage}>
              {t("ProfileTextDeleteImage",{})}
            </div>
            {isMaster && (
              <DefaultSelect
                defaultValue={user.current_role_id}
                data={roles}
                onChange={onChangeSelect}
                name="role"
              />
            )}
            <div className="text-left">{t("ProfileTextDateOfEmployment",{})}</div>
            <div className="profile-edit__date flex">
              <MuiPickersUtilsProvider utils={DayjsUtils}>
                <ThemeProvider theme={materialTheme}>
                  <KeyboardDatePicker
                    autoOk
                    value={joinDate}
                    placeholder="2020/11/2"
                    onChange={(date) => setJoinDate(date)}
                    format="YYYY/MM/DD"
                    inputVariant="outlined"
                    variant="inline"
                    open={isOpen.join}
                    onOpen={() => setIsOpen({ ...isOpen, join: true })}
                    onClose={() => setIsOpen({ ...isOpen, join: false })}
                    keyboardIcon={<CustomIcon />}
                    disableToolbar
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </div>
            <div className="text-left">{t("ProfileTextDateOfBirth",{})}</div>
            <div className="profile-edit__date flex">
              <MuiPickersUtilsProvider utils={DayjsUtils}>
                <ThemeProvider theme={materialTheme}>
                  <KeyboardDatePicker
                    autoOk
                    value={birthDate}
                    placeholder="2020/11/2"
                    onChange={(date) => setBirthDate(date)}
                    format="YYYY/MM/DD"
                    inputVariant="outlined"
                    variant="inline"
                    open={isOpen.birth}
                    onOpen={() => setIsOpen({ ...isOpen, birth: true })}
                    onClose={() => setIsOpen({ ...isOpen, birth: false })}
                    keyboardIcon={<CustomIcon />}
                    disableToolbar
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </div>
            {teamList.teams && (
              <DefaultSelect
                defaultValue={user.current_team_id}
                data={teamList.teams}
                onChange={onChangeSelect}
                name="team"
                label="チーム名"
              />
            )}
            {positionList.positions && (
              <DefaultSelect
                defaultValue={user.current_position_id}
                data={positionList.positions}
                onChange={onChangeSelect}
                name="position"
                label="Position"
              />
            )}
            <p className="text-left">{t("ProfileTextNotifyMemberByEmail",{})}</p>
            <div className="sample2Area" id="makeImg">
              <input type="checkbox" id="sample2check" onChange={onChangeChecked} checked={mailRecieve} />
              <label htmlFor="sample2check">
                <p className="sample2check-text sample2check-text-on">{t("GenelalButtonOn",{})}</p>
                <p className="sample2check-text sample2check-text-off">{t("GenelalButtonOff",{})}</p>
                <div id="sample2box"></div>
              </label>
            </div>
            <button className="profile-submit" type="submit" >
            {t("GenelalButtonSave",{})}
            </button>
          </form>
        </div>
      </div>
      <style jsx>{`
        .profile-edit {
          margin: auto;
          text-align: center;
          padding: 30px 35px 10px;
          font-size: 11px;
          text-align: center;
          width: 270px;
          margin-bottom: 15px;
        }
        .profile-edit__form {
          padding: 5px 0;
        }
        .profile-edit-close {
          position: absolute;
          top: 10px;
          left: 20px;
          font-size: 25px;
          cursor: pointer;
          width: 19px;
        }
        .profile-edit-header {
          font-size: 15px;
        }
        .profile-edit__date {
          margin: 5px 0 15px;
        }
        .profile-edit__date input {
          background: #eeeeee;
          text-align: center;
          padding: 5px;
          height: 30px;
          border-radius: 8px;
        }


        .sample2Area {
          margin         : 10px 28px 20px;            
          width          : 60px;            
          transform: rotate(180deg);
          border-radius: 50px;
        }
         
        .sample2Area input[type="checkbox"] {
          display        : none;
        }
        
        .label[for="sample2check"]{
          position: relative;
        }

        .sample2check-text{
           transform: rotate(180deg);
           position: absolute;
           top: 6px;
           font-size: 12px;
           color: #bfbfbf;
        }

        .sample2check-text-on{
          left: 70px;
        }

        .sample2check-text-off{
          right: 70px;
        }
         
        .sample2Area label {
          display        : block;
          box-sizing     : border-box;
          text-align     : left;
          border         : 4px solid #bfbfbf;
          border-radius  : 3px;
          line-height    : 1;
          height         : 28px;
          background: #bfbfbf; 
          border-radius: 50px;
        }

        .sample2Area #sample2box {
          display        :inline-block;
          height         : 20px;
          width          : 20px;
          background     : white;
          transition     : .3s;
          border-radius: 50px;
        }
         
        .sample2Area #sample2check:checked + label #sample2box {
          transform      : translateX(160%);
          background     : white;
        }
        
        .sample2Area #sample2check:checked + label {
          transition: .5s all;
          border         : 4px solid #03A9F4;
          background: #03A9F4;
        }

        .sample2Area #sample2check:checked + label .sample2check-text-on {
          color: #03A9F4;
        }

        .sample2Area #sample2check:checked + label .sample2check-text-off {
          color: #bfbfbf;
        }
      `}</style>
    </React.Fragment>
  );
};

const DefaultSelect = ({ defaultValue, data, onChange, name, label }) => {
  if (!data) return null;
  const optionNode = data.map((o, key) => (
    <option value={o.id} key={key}>
      {o.name}
    </option>
  ));

  return (
    <React.Fragment>
      <div className="default-select">
        <select defaultValue={defaultValue} onChange={onChange} name={name}>
          {!defaultValue && <option value="">{label}</option>}
          {optionNode}
        </select>
      </div>
      <style jsx>{`
        .default-select {
          width: 100%;
          height: 35px;
          position: relative;
          border-radius: 8px;
          overflow: hidden;
          margin-bottom: 15px;
        }
        .default-select select {
          width: 100%;
          height: 100%;
          text-align-last: center;
          background: #eeeeee;
          appearance: none;
          font-size: 16px;
        }
        .default-select::before {
          content: "";
          width: 12px;
          height: 12px;
          border: 6px solid transparent;
          border-top: 8px solid #6f6f6f;
          position: absolute;
          top: 15px;
          right: 15px;
        }
      `}</style>
    </React.Fragment>
  );
};

const CustomIcon = () => {
  return <img src={RailsAssetPath("icon_calendar.png")} />;
};

const mapStateToProps = (state) => {
  return {
    user: state.setMemberProfile.user,
    roles: state.setMemberProfile.roles,
  };
};

export default connect(mapStateToProps)(FormEditProfile);
