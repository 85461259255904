import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useDeleteRealWorld } from 'javascript/features/realWorld/hooks/useDeleteRealWorld';
import { useShowRealWorldSettingModal } from 'javascript/features/realWorld/hooks/useShowRealWorldSettingModal';

interface Props {
  isOpen: boolean;
  toggleSetting: (e: React.MouseEvent) => void;
  id: number;
  hasUser: boolean;
  creatorId: number | null;
}

export const RealWorldSettingMenu: React.FC<Props> = ({
  isOpen,
  toggleSetting,
  id,
  hasUser,
  creatorId,
}) => {
  // TODO 型付け
  // @ts-ignore
  const currentUser = useSelector((state) => state.setUser.currentUser);
  const currenUserId = currentUser?.id;

  const isMaster = currentUser?.role === 'master';

  const isAbleToDelete = isMaster || currenUserId === creatorId;

  const { showRealWorldSettingModal } = useShowRealWorldSettingModal(id);
  const { deleteRealWorld } = useDeleteRealWorld(id, hasUser, isAbleToDelete);
  const { t } = useTranslation();

  const handleOnClick = (cb: () => void, e: React.MouseEvent) => {
    toggleSetting(e);
    cb();
  };

  return (
    <RealWorldMenuBox isOpen={isOpen}>
      <CloseIconWrapper onClick={(e) => toggleSetting(e)}>
        {/* @ts-ignore */}
        <img src={RailsAssetPath('close-img-gray.png')} />
      </CloseIconWrapper>
      <RealWorldMenuItem
        onClick={(e) => handleOnClick(showRealWorldSettingModal, e)}
      >
        {/* @ts-ignore */}
        <RealWorldMenuItemIcon icon={RailsAssetPath('icon_edit.png')} />
        {t('GenelalButtonEdit', {})}
      </RealWorldMenuItem>
      <RealWorldMenuItem
        onClick={(e) => handleOnClick(deleteRealWorld, e)}
        isAbleToDelete={isAbleToDelete}
        isToDelete
      >
        <RealWorldMenuItemIcon
          icon={
            isAbleToDelete
              ? // @ts-ignore
                RailsAssetPath('delete_red.png')
              : // @ts-ignore
                RailsAssetPath('delete_gray.png')
          }
        />
        {t('GenelalButtonDelete', {})}
      </RealWorldMenuItem>
    </RealWorldMenuBox>
  );
};

const RealWorldMenuBox = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  min-width: 80px;
  padding: 8px 0;
  color: #303030;
  font-size: 10px;
  text-align: left;
  border-radius: 5px;
  backdrop-filter: blur(12px);
  background-color: rgba(0, 160, 233, 0.02);
  box-shadow: 0px 0px 3px 0px grey;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  text-shadow: none;
  white-space: nowrap;
`;

const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 24px;
  height: 24px;
  background: #f2f2f2;
  border: 2px solid #fff;
  border-radius: 50%;
`;

const RealWorldMenuItem = styled.div<{
  isAbleToDelete?: boolean;
  isToDelete?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  color: ${({ isAbleToDelete, isToDelete }) =>
    isToDelete && !isAbleToDelete && '#999'};
  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`;

const RealWorldMenuItemIcon = styled.i<{ icon: string }>`
  display: inline-block;
  padding: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
  vertical-align: middle;
  background-image: ${({ icon }) => `url(${icon})`};
`;
