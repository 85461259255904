import React from 'react';
import styled from 'styled-components';
import { EachAvatarPartsTypeList } from 'javascript/features/avatarDressUp/components/EachAvatarPartsTypeList';
import {
  DefaultHatItemsCount,
  DefaultClothItemsCount,
  DefaultEyeItemsCount,
  DefaultMouthItemsCount,
  DefaultHairCount,
} from 'javascript/features/avatarParts/constants/defaultItemCount';
import { device } from 'javascript/utils/mediaQuery';
import {
  AvatarPartsType,
  OwnedProductsInDressRoom,
  UserProfileAvatar,
} from 'types';

const StyledAvatarPartsItemList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
  flex: 1 0 auto;
  position: relative;
  height: 180px;
  overflow: scroll;
  @media ${device.sp} {
    height: 150px;
    padding: 0 12px;
    gap: 4px;
  }
`;

type Props = {
  avatarParts: UserProfileAvatar;
  ownedProducts: OwnedProductsInDressRoom;
  selectedAvatarPartsType: AvatarPartsType;
  localHairColor: string;
  selectAvatarParts: (selectedAvatarParts: UserProfileAvatar) => void;
};

export const AvatarPartsItemList: React.FC<Props> = ({
  avatarParts,
  ownedProducts,
  selectedAvatarPartsType,
  localHairColor,
  selectAvatarParts,
}) => (
  <StyledAvatarPartsItemList>
    {selectedAvatarPartsType === 'hat' && (
      <EachAvatarPartsTypeList
        defaultItemsCount={DefaultHatItemsCount}
        ownedProducts={ownedProducts[1]}
        avatarParts={avatarParts}
        selectedAvatarPartsType={selectedAvatarPartsType}
        selectAvatarParts={selectAvatarParts}
        isAbleToTakeOff
      />
    )}
    {selectedAvatarPartsType === 'hair' && (
      <EachAvatarPartsTypeList
        defaultItemsCount={DefaultHairCount}
        ownedProducts={ownedProducts[2]}
        avatarParts={avatarParts}
        selectedAvatarPartsType={selectedAvatarPartsType}
        selectAvatarParts={selectAvatarParts}
        localHairColor={localHairColor}
      />
    )}
    {selectedAvatarPartsType === 'eye' && (
      <EachAvatarPartsTypeList
        defaultItemsCount={DefaultEyeItemsCount}
        ownedProducts={ownedProducts[3]}
        avatarParts={avatarParts}
        selectedAvatarPartsType={selectedAvatarPartsType}
        selectAvatarParts={selectAvatarParts}
      />
    )}
    {selectedAvatarPartsType === 'mouth' && (
      <EachAvatarPartsTypeList
        defaultItemsCount={DefaultMouthItemsCount}
        ownedProducts={ownedProducts[5]}
        avatarParts={avatarParts}
        selectedAvatarPartsType={selectedAvatarPartsType}
        selectAvatarParts={selectAvatarParts}
      />
    )}
    {selectedAvatarPartsType === 'clothes' && (
      <EachAvatarPartsTypeList
        defaultItemsCount={DefaultClothItemsCount}
        ownedProducts={ownedProducts[7]}
        avatarParts={avatarParts}
        selectedAvatarPartsType={selectedAvatarPartsType}
        selectAvatarParts={selectAvatarParts}
        isAbleToTakeOff
      />
    )}
  </StyledAvatarPartsItemList>
);
