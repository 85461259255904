import { useCallback } from 'react';
import { useGlobal } from 'reactn';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

export const useDeleteUser = (userId: number) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [_, setIsShowMypage] =
    useGlobal<{ isShowMypage: boolean }>('isShowMypage');

  const handleDeleteUser = useCallback(() => {
    const confirmDelete = window.confirm(t('EmployeeConfirmDeleteMember', {}));
    if (confirmDelete) {
      setIsShowMypage(false);
      const url = `/api/v1/users`;
      axios
        .delete(`${url}/${userId}`)
        .then(() => {
          history.push('/employees');
        })
        .catch((error) => {
          const err = error.response.data.message;
          alert(err);
        });
    }
  }, [userId]);

  return { handleDeleteUser };
};
