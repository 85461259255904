import React, { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { useMediaQuery } from 'react-responsive';
import { avatarChannel } from 'javascript/channels/avatar_channel';
import { interactWorldAvatarChannel } from 'javascript/channels/interact_world_avatar_channel';
import { StatusType, UserAvatar } from 'types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const sound = new Audio('/assets/coin.mp3');
sound.volume = 0.3;

export const useOpenChat = (avatar?: UserAvatar) => {
  // @ts-ignore
  const currentUser = useSelector((state) => state.setUser.currentUser);
  const { t } = useTranslation();
  const [val, setVal] = useState('');
  const [avatarInfo, setAvatarInfo] = useState<Omit<UserAvatar, 'id'>>({
    summon: avatar?.summon ?? 0,
    item: avatar?.item ?? 0,
    defense: avatar?.defense ?? 0,
    available_direct_call: avatar?.available_direct_call ?? false,
  });
  const [interactWorldId] =
    useGlobal<{ interactWorldId: number | undefined }>('interactWorldId');
  const { subscribeAvatarChannel } = avatarChannel();
  const cable = subscribeAvatarChannel(avatar?.id);
  const { subscribeInteractWorldAvatarChannel } = interactWorldAvatarChannel();
  const worldAvatarCable = subscribeInteractWorldAvatarChannel(
    avatar?.id,
    interactWorldId
  );
  const isMobile = useMediaQuery({ maxWidth: 430 });

  useEffect(() => {
    avatar &&
      setAvatarInfo({
        summon: avatar.summon ?? 0,
        item: avatar.item ?? 0,
        defense: avatar.defense ?? 0,
        available_direct_call: avatar?.available_direct_call ?? false,
      });
  }, [avatar]);

  const submit = () => {
    if (val.length === 0) return;
    const avatarCable = interactWorldId ? worldAvatarCable : cable;
    console.log("avatarCable")
    console.log(avatarCable)
    avatarCable.create_chat_open({
      content: val,
      interact_world_id: interactWorldId,
    });
    setVal('');
    const elem = document.activeElement as HTMLElement;
    elem.blur();
  };

  const electricsubmit = () => {
    if (val.length === 0) return;

    const isConfirmed = window.confirm(t('BulletinboardSendConfirm', {}));
    if (isConfirmed) {
      const avatarCable = interactWorldId ? worldAvatarCable : cable;
      avatarCable.create_chat_open({
        bulletin_board_content: val,
        interact_world_id: interactWorldId,
      });
      setVal('');
      const elem = document.activeElement as HTMLElement;
      elem.blur();
    }
  };

  const handleOnChangeVal = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setVal(value);
    },
    [val]
  );

  const handleOnSubmit = useCallback(() => {
    submit();
  }, [val]);

  const electricHandleOnSubmit = useCallback(() => {
    electricsubmit();
  },[val])

  const handleOnClick = useCallback(
    (type: StatusType, num: number) => {
      const avatarCable = interactWorldId ? worldAvatarCable : cable;
      avatar?.id &&
        avatarCable.update_status({
          avatar_id: avatar.id,
          [type]: num,
          interact_world_id: interactWorldId,
        });
      !isMobile && currentUser.speaker && sound.play();
      setAvatarInfo({ ...avatarInfo, [type]: num });
    },
    [avatarInfo, interactWorldId, currentUser.speaker]
  );

  const handleOnKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
        submit();
      }
    },
    [val]
  );

  return {
    handleOnChangeVal,
    handleOnSubmit,
    electricHandleOnSubmit,
    handleOnClick,
    handleOnKeyDown,
    val,
    avatarInfo,
  };
};
