import { useCallback, useState } from 'react';

export const useSelectModalType = (
  useModalType: 'default' | 'present' | 'sell' | 'skill_use' = 'default'
) => {
  const [modalType, setModalType] = useState<'default' | 'present' | 'sell' | 'skill_use'>(
    useModalType
  );

  const handleSelectPresent = useCallback(() => {
    setModalType('present');
  }, []);

  const handleSelectSell = useCallback(() => {
    setModalType('sell');
  }, []);

  const handleSelectDefault = useCallback(() => {
    setModalType('default');
  }, []);

  const handleSelectUseSkill = useCallback(() => {
    setModalType('skill_use')
  },[])

  return {
    modalType,
    handleSelectPresent,
    handleSelectSell,
    handleSelectDefault,
    handleSelectUseSkill,
  };
};
