import { device } from 'javascript/utils/mediaQuery';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface Props {
  value: string;
  isDisabled: boolean;
  handleOnChangeVal: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnSubmit: () => void;
  electricHandleOnSubmit: () => void;
  handleOnKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const OpenChatInputForm: React.FC<Props> = ({
  value,
  isDisabled,
  handleOnChangeVal,
  handleOnSubmit,
  electricHandleOnSubmit,
  handleOnKeyDown,
}) => {
  const { t } = useTranslation();

  return(
    <StyledOpenChatInputForm>
      <input
        disabled={isDisabled}
        value={value}
        type='text'
        maxLength={20}
        placeholder={t("OpenChatTextSentMessage",{})}
        onChange={handleOnChangeVal}
        onKeyDown={handleOnKeyDown}
      />
      <button className="send-submit-button" onClick={handleOnSubmit} disabled={value.length === 0}>
        {/* @ts-ignore */}
        <img src={RailsAssetPath('freeSpace/send.png')} />
      </button>
      <button onClick={electricHandleOnSubmit} disabled={value.length === 0}>
        {/* @ts-ignore */}
        <img src={RailsAssetPath('freeSpace/megaphone_glay.png')} />
      </button>
      <AlertMessage disabled={value.length > 20}>
      {t("OpenChatAlertUpTo20Char",{})}
      </AlertMessage>
    </StyledOpenChatInputForm>
  );
}
const StyledOpenChatInputForm = styled.div`
  position: relative;
  width: 224px;
  height: 29px;
  input {
    width: 100%;
    height: 100%;
    padding: 0 28px 0 16px;
    font-size: 10px;
    border-radius: 8px;
    @media ${device.sp} {
      height: 26px;
      margin-top: 2px;
      padding: 0 28px 0 20px;
    }
  }
  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    &:focus {
      border: 1px solid #00a0e9;
      box-shadow: 0 0 4px #00a0e9;
    }
    img {
      width: 11px;
      height: 11px;
    }
  }
  .send-submit-button {
      right: 30px;
  }
`;

const AlertMessage = styled.p<{ disabled: boolean }>`
  display: ${({ disabled }) => (disabled ? 'block' : 'none')};
  position: absolute;
  top: 30px;
  left: 16px;
  font-size: 10px;
  color: red;
`;
