import React from 'react';
import Button from '@material-ui/core/Button';

const DisconnectButton = ({ onClick }) => {

  
  return (
    <Button
    onClick={onClick}
    data-cy-disconnect
    >
      <img src={RailsAssetPath("video_call/icon/leave.png")} className="button_icon" />

    </Button>
  );
}
export default DisconnectButton;