// 参考にしているコード
// https://github.com/twilio/twilio-video-app-react

import React, { createContext , useCallback} from 'react';
import { useEffect } from 'reactn';
import { useSelector } from 'react-redux';
import useRoom from './hooks/useRoom';
import useHandleRoomDisconnection from './hooks/useHandleRoomDisconnection';
import useLeaveCall from './hooks/useLeaveCall';
import useLocalTracks from './hooks/useLocalTracks';
import useScreenShareToggle from './hooks/useScreenShareToggle';
import useBackgroundSettings from './hooks/useBackgroundSettings';
import { SelectedParticipantProvider } from './hooks/useSelectedParticipant';
import { useIsVideoConnect } from './hooks/useIsVideoConnect';
import useRemainingPoint from "./hooks/useRemainingPoint";

export const VideoCallContext = createContext();

const VideoCallProvider = ({ options, children, onError}) => {
  const onErrorCallback = useCallback(
    error => {
      console.log(`ERROR: ${error.message}`, error);
      onError(error);
    },
    [onError]
  );

  const { leave, isDisconnecting, setIsDisconnecting } = useLeaveCall();
  const { localTracks , getLocalVideoTrack, getLocalVideoAndAudioTracks, removeLocalVideoTrack, removeLocalAudioTrack, getAudioAndVideoTracks } = useLocalTracks();
  const { room, isConnecting, connect } = useRoom(localTracks, onErrorCallback, options);
  const [ isSharingScreen, toggleScreenShare ] = useScreenShareToggle(room, onError);
  const { isOtherConnected, videoConnectStatus, connected, disconnected, checkConnect, isVideoConnect} = useIsVideoConnect();
  const [ remainingPoint, setRemainingPoint ] = useRemainingPoint();

   useHandleRoomDisconnection(
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharingScreen,
    toggleScreenShare
  );
  const videoTrack = localTracks.find(track => !track.name.includes('screen') && track.kind === 'video')
  const [backgroundSettings, setBackgroundSettings] = useBackgroundSettings(videoTrack, room);

  useEffect(() => {
    if (room) {
      room.disconnect();
    }
  },[]);

  return (
    <VideoCallContext.Provider
      value={{
        room,
        isConnecting,
        onError,
        connect,
        leave,
        isDisconnecting,
        setIsDisconnecting,
        localTracks,
        getLocalVideoTrack,
        getLocalVideoAndAudioTracks,
        removeLocalVideoTrack,
        removeLocalAudioTrack, 
        getAudioAndVideoTracks,
        isSharingScreen,
        toggleScreenShare,
        isOtherConnected,
        isVideoConnect,
        videoConnectStatus,
        backgroundSettings,
        setBackgroundSettings,
        remainingPoint,
        setRemainingPoint
      }}
    >
      <SelectedParticipantProvider room={room}>
        {children}
      </SelectedParticipantProvider>
    </VideoCallContext.Provider>
  );
}
export default VideoCallProvider;