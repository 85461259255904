import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import Participant from './Participant';
import useVideoCallContext from './hooks/useVideoCallContext';
import useParticipants from './hooks/useParticipants';
import useMainParticipant from './hooks/useMainParticipant';
import { useSelectedParticipant } from './hooks/useSelectedParticipant';
import useScreenShareParticipant from './hooks/useScreenShareParticipant';
import useWindowDimensions from './hooks/useWindowDimentions';
import SwipeableViews from 'react-swipeable-views';
import { virtualize } from 'react-swipeable-views-utils';
import { useMediaQuery } from 'react-responsive';
import { sp } from '../../utils/mediaQuery';

// 画面比率
const SREEN_RATIO_HORIZONTAL = 16;
const SREEN_RATIO_VERTICAL = 9;

const BASE_PARTICIPANT_NUM = 2;
const INCREMENT_PARTICIPANT_NUM = 6;

const ParticipantsList = () => {
  const { room, isSharingScreen } = useVideoCallContext();
  const localParticipant = room.localParticipant;
  const participants = useParticipants();
  const allParticipants = Array.from(
    new Set([localParticipant].concat(participants))
  );
  const [selectedParticipant, setSelectedParticipant] =
    useSelectedParticipant();
  const participantsCount = allParticipants.length;

  const mainParticipant = useMainParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const isRemoteParticipantScreenSharing =
    screenShareParticipant && screenShareParticipant !== localParticipant;
  // if (participants.length === 0) returdata-swipeablen null;
  const [index, setIndex] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 430 });
  const windowDimensions = useWindowDimensions();
  const [maxScreenWidth, setMaxScreenWidth] = useState(windowDimensions.width);
  const [screenWidth, setScreenWidth] = useState(
    windowDimensions.width / participantsCount
  );
  const [screenHeight, setScreenHeight] = useState(screenWidth * (9 / 16));

  const styles = {
    root: {
      padding: '0 0px',
    },
    slideContainer: {
      padding: '0 5px',
    },
    slide: {
      display: 'flex',
      flexFlow: 'wrap',
      justifyContent: 'center',
    },
  };

  const eachSlice = (arr, n = 2, result = []) => {
    if (arr.length === 0) {
      return result;
    }
    return eachSlice(arr, n, [...result, arr.splice(0, n)]);
  };

  const SwipeableViewsParticipants = (key, index) => {
    let viewIndex = 0;
    let maxParticipant = 50;
    if (isMobile) {
      maxParticipant = 10;
    }
    let ParticipantsNode = allParticipants.map((participant, index) => {
      const isSelected = participant === selectedParticipant;
      const hideParticipant =
        participant === mainParticipant &&
        participant !== screenShareParticipant &&
        !isSelected;
      return (
        <Participant
          key={participant.sid}
          participant={participant}
          isSelected={participant === selectedParticipant}
          hideParticipant={hideParticipant}
          enableScreenShare={
            isSharingScreen || isRemoteParticipantScreenSharing
          }
          isLocalParticipant={index == 0}
          screenWidth={screenWidth}
          screenHeight={screenHeight}
        />
      );
    });

    let publishParticipantsList = eachSlice(
      ParticipantsNode,
      maxParticipant
    ).map((ParticipantNode) => {
      viewIndex++;
      return (
        <div key={viewIndex} style={styles.slide}>
          {ParticipantNode}
        </div>
      );
    });
    return publishParticipantsList;
  };

  useEffect(() => {
    if (allParticipants) {
      let participantsCount = allParticipants.length;
      let minWidth = windowDimensions.width / 10 - 10;
      if (isMobile) {
        minWidth = windowDimensions.width / 2 - 54;
      }
      let screenNum =
        BASE_PARTICIPANT_NUM +
        (participantsCount > INCREMENT_PARTICIPANT_NUM
          ? Math.floor(participantsCount / INCREMENT_PARTICIPANT_NUM)
          : 0);
      if (screenNum >= participantsCount) screenNum = participantsCount;
      let verticalSplitScreen =
        participantsCount / BASE_PARTICIPANT_NUM != 1
          ? Math.ceil(participantsCount / screenNum)
          : Math.ceil(participantsCount / screenNum) + 1;
      if (verticalSplitScreen >= 5) verticalSplitScreen = 5;
      let maxScreenWidth =
        (windowDimensions.width / screenNum / verticalSplitScreen) * screenNum;
      let width = maxScreenWidth * (isMobile ? 0.95 : 0.7);
      let height = width * (SREEN_RATIO_VERTICAL / SREEN_RATIO_HORIZONTAL);
      if (isMobile) {
        setMaxScreenWidth(windowDimensions.width);
      } else {
        setMaxScreenWidth(maxScreenWidth * screenNum * 0.9);
      }

      if (minWidth >= width) {
        width = minWidth;
        height = width * (SREEN_RATIO_VERTICAL / SREEN_RATIO_HORIZONTAL);
      }
      setScreenWidth(width);
      setScreenHeight(height);
      if (isMobile && participantsCount <= 2) {
        // スマートフォン(縦)で2人の場合
        let width = windowDimensions.width * 0.95;
        let height = width * (SREEN_RATIO_VERTICAL / SREEN_RATIO_HORIZONTAL);
        setScreenWidth(width);
        setScreenHeight(height);
      }

      // 画面共有がある場合は画面共有を大きくしたいので、通常の画面は10％程度にする
      if (isSharingScreen || isRemoteParticipantScreenSharing) {
        setScreenWidth(
          windowDimensions.height *
            0.1 *
            (SREEN_RATIO_HORIZONTAL / SREEN_RATIO_VERTICAL)
        );
        setScreenHeight(windowDimensions.height * 0.1);
      }
    }
  }, [
    allParticipants,
    windowDimensions,
    isSharingScreen,
    isRemoteParticipantScreenSharing,
  ]);

  return (
    <>
      {isSharingScreen || isRemoteParticipantScreenSharing ? (
        <ScrollContainerWrapper>
          <ScrollContainer>
            <InnerScrollContainer>
              {allParticipants.map((participant, index) => {
                const isSelected = participant === selectedParticipant;
                const hideParticipant =
                  participant === mainParticipant &&
                  participant !== screenShareParticipant &&
                  !isSelected;
                return (
                  <Participant
                    key={participant.sid}
                    participant={participant}
                    isSelected={participant === selectedParticipant}
                    hideParticipant={hideParticipant}
                    enableScreenShare={
                      isSharingScreen || isRemoteParticipantScreenSharing
                    }
                    isLocalParticipant={index == 0}
                    screenWidth={screenWidth}
                    screenHeight={screenHeight}
                  />
                );
              })}
            </InnerScrollContainer>
          </ScrollContainer>
        </ScrollContainerWrapper>
      ) : (
        <Container maxScreenWidth={maxScreenWidth}>
          <SwipeableViews
            index={index}
            // onChangeIndex={(idx) => setIndex(idx)}
            // onSwitching={(idx) => setIndex(idx)}
            onSwitching={(idx) => setIndex(idx)}
            style={styles.root}
            slideStyle={styles.slideContainer}
          >
            {SwipeableViewsParticipants()}
          </SwipeableViews>
        </Container>
      )}
    </>
  );
};
const ScrollContainerWrapper = styled.div`
  z-index: 5;
  grid-area: 2 / 1 / 3 / 3;
  overflow-y: initial;
  overflow-x: auto;
  display: box;

  &::-webkit-scrollbar {
    overflow: hidden;
    width: 2px;

    &:horizontal {
      height: 5px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
  }
  ${sp`
    margin-top:10%;
  `}
`;
const ScrollContainer = styled.div`
  display: -moz-box;
  display: -webkit-box;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  margin: auto;
`;

const InnerScrollContainer = styled.div`
  width: auto;
  display: flex;
  padding: 8px;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: flex-start;
  align-items: center;
  background-color: #000;
  position: relative;
  margin: auto;
  top: 40%;
  transform: translateY(-45%);
  width: ${(props) => props.maxScreenWidth}px;
  max-width: 100%;
`;
const InnerContainer = styled.div``;

export default ParticipantsList;
