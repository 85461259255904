export function setVideoCallRoom(params) {
  return (dispatch) => {
    dispatch({
      type: 'SET_VIDEOCALL_ROOM_CREATED',
      created: params.created,
      roomId: params.roomId,
      twilioRoomId: params.twilioRoomId,
      isGuest: params.isGuest,
      token: params.token
    });
  }
}