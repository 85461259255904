import React from 'react';
import styled from 'styled-components';
import { ThanksComment } from 'types';

const StyledModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(62, 62, 62, 0.75);
  z-index: 2;
`;

const StyledCommentModal = styled.div`
  position: absolute;
  top: 10vh;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  max-height: 70vh;
  padding: 40px;
  background-color: #fff;
  border-radius: 8px;
  transform: translateX(-50%);
  overflow: scroll;
`;

const StyledCloseButtonWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
  }
`;

const StyledStatusIconWrapper = styled.div`
  img {
    width: 40px;
  }
`;

const StyledStatusText = styled.p`
  margin-top: 8px;
  font-size: 14px;
`;

const StyledContent = styled.p`
  margin-top: 12px;
  font-size: 12px;
  word-break: break-word;
`;

const StyledPersonRelation = styled.div`
  width: 100%;
  height: 15px;
  margin-top: 8px;
  font-size: 10px;
  color: #aaa;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StyledDate = styled.div`
  width: 100%;
  font-size: 10px;
  color: #aaa;
`;

interface Props {
  thanksComment: ThanksComment;
  icon: 'heart' | 'star' | 'party_cracker';
  text: string;
  toggleModal: () => void;
}

export const CommentModal: React.FC<Props> = ({
  icon,
  text,
  thanksComment,
  toggleModal,
}) => {
  const { content, from_user_name, to_user_name, created_at } = thanksComment;

  return (
    <StyledModalBackground>
      <StyledCommentModal>
        <StyledCloseButtonWrapper onClick={toggleModal}>
          {/* @ts-ignore */}
          <img src={RailsAssetPath('close-img-gray.png')} />
        </StyledCloseButtonWrapper>
        <StyledStatusIconWrapper>
          <img
            // @ts-ignore
            src={RailsAssetPath(`diary/${icon}.png`)}
          />
        </StyledStatusIconWrapper>
        <StyledStatusText>{text}</StyledStatusText>
        <StyledContent dangerouslySetInnerHTML={{ __html: content }} />
        <StyledPersonRelation>
          <p>{`${from_user_name} ▶ ${to_user_name}`}</p>
        </StyledPersonRelation>
        <StyledDate>
          <p>{created_at}</p>
        </StyledDate>
      </StyledCommentModal>
    </StyledModalBackground>
  );
};
