import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';

interface InviteInfo {
  twilio_room_id: number | null;
  from_user_id: number | null,
  from_avatar_id: number | null,
  to_user_id: number | null,
  to_avatar_id: number | null,
}
const useInvitedDirectCallInfo = () => {
  const [inviteInfo, setInviteInfo] = useState<InviteInfo[]>([]);

  const getData = useCallback(() => {
    axios
      .get('/api/v1/twilio/voices/check_direct_call')
      .then(({ data }) => {
        if (data?.twilio_room_id && JSON.stringify(data) != JSON.stringify(inviteInfo)) {
          setInviteInfo(data);
        }
      });
  }, []);

  useEffect(() => {
    getData();
  },[]);

  return {
    inviteInfo,
    setInviteInfo,
  };
};

export default useInvitedDirectCallInfo;
