import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useGlobal } from 'reactn';

import FormAddWorkingHour from '../form/FormAddWorkingHour';
Modal.setAppElement('body');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: '0px',
    width: '350px',
    background: '#fff',
  },
  overlay: {
    backgroundColor: 'transparent',
    zIndex: '100',
  },
};

const ModalAddWorkingHour= () => {
  const [isShowWorkingHourFormShow, setIsShowWorkingHourFormShow] =
    useGlobal('isShowWorkingHourFormShow');
  function openModal() {
    setIsShowWorkingHourFormShow(true);
  }
  function closeModal() {
    setIsShowWorkingHourFormShow(false);
  }

  return (
    <Modal
      isOpen={isShowWorkingHourFormShow}
      //onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='add Working Hour modal'
    >
      <FormAddWorkingHour />
    </Modal>
  );
};

export default ModalAddWorkingHour;
