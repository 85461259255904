import { useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export const useDeleteRealWorld = (
  id: number,
  hasUser: boolean,
  isAbleToDelete: boolean
) => {
  const { t } = useTranslation();

  const deleteRealWorld = useCallback(() => {
    if (!isAbleToDelete) {
      alert(t('WorldAlertOnlyauthorizedusercandelete', {}));
      return;
    }
    if (hasUser) {
      alert(t('WorldAlertRealUserCantDelete', {}));
      return;
    }
    const confirmDelete = window.confirm(t('GenelalConfirmDelete', {}));
    if (confirmDelete) {
      const url = `/api/v1/rooms/${id}`;
      axios.delete(url).catch((error) => {
        alert(error.message);
      });
    }
  }, [id, hasUser, isAbleToDelete]);

  return { deleteRealWorld };
};
