import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

const useIsTrackEnabled = () => {
  
  const currentUser = useSelector((state) => state.setUser.currentUser);
  const [isEnabled, setIsEnabled] = useState(currentUser ? currentUser.microphone : false);

  useEffect(() => {
    setIsEnabled(currentUser ? currentUser.microphone : false);

    if (track) {
      const setEnabled = () => setIsEnabled(true);
      const setDisabled = () => setIsEnabled(false);
      track.on('enabled', setEnabled);
      track.on('disabled', setDisabled);
      return () => {
        track.off('enabled', setEnabled);
        track.off('disabled', setDisabled);
      };
    }
  }, [track]);

  return isEnabled;
}

export default useIsTrackEnabled;