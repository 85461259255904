import { Room, TwilioError } from 'twilio-video';
import { useEffect } from 'react';
import useLeaveCall from './useLeaveCall';
import { useIsVideoConnect } from "./useIsVideoConnect";
export default function useHandleRoomDisconnection(
  room,
  onError,
  removeLocalAudioTrack,
  removeLocalVideoTrack,
  isSharingScreen,
  toggleScreenShare
) {

  const { leaveApi } = useLeaveCall();
  const {disconnected} = useIsVideoConnect();


  useEffect(() => {
    if (room) {
      const onDisconnected = (_, error) => {
        if (error) {
          onError(error);
        }
        leaveApi();
        disconnected();
        removeLocalAudioTrack();
        removeLocalVideoTrack();
        if (isSharingScreen) {
          toggleScreenShare();
        }
      };

      room.on('disconnected', onDisconnected);
      return () => {
        room.off('disconnected', onDisconnected);
      };
    }
  }, [room, onError, removeLocalAudioTrack, removeLocalVideoTrack, isSharingScreen, toggleScreenShare]);
}
