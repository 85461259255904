import styled from 'styled-components';
import { sp } from '../../utils/mediaQuery';

export const GlobalContainer = styled.div`
  overflow: hidden;
  min-height: 100vh;
  background: rgb(246, 246, 246);
`;

export const GlobalComponentWrapper = styled.div`
  padding-top: 60px;
  ${sp`
    overflow: unset;
    padding-top: 45px;
  `}
`;
