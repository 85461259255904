import { useCallback } from 'react';
import axios from 'axios';
import useSWR from 'swr';
import { useGetOfficeData } from 'javascript/features/realWorld/hooks/useGetOfficeData';

export const useToggleFavorite = (roomId: number, isStarred: boolean) => {
  const { getOfficeData } = useGetOfficeData();
  const { data: isStarredLocal, mutate: setIsStarredLocal } = useSWR(
    `isStarred/${roomId}`,
    null,
    { fallbackData: isStarred }
  );

  const addFavorite = useCallback(() => {
    axios.post(`/api/v1/office/rooms/add_favorite/${roomId}`).then(() => {
      getOfficeData();
      setIsStarredLocal(true);
    });
  }, [roomId]);

  const deleteFavorite = useCallback(() => {
    axios.post(`/api/v1/office/rooms/delete_favorite/${roomId}`).then(() => {
      getOfficeData();
      setIsStarredLocal(false);
    });
  }, [roomId]);

  return {
    addFavorite,
    deleteFavorite,
    isStarredLocal,
  };
};
