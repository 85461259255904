import React from 'react';
import styled from 'styled-components';
import ProfileThumbnail from 'javascript/components/items/ProfileThumbnail';
import { SortableVirtualWorldMember } from 'javascript/features/virtualWorld/components/SortableVirtualWorldMember';
import { VirtualWorldUser } from 'types';

interface Props {
  users: VirtualWorldUser[];
  guestNum: number;
}

export const SortableVirtualWorldMemberBox: React.FC<Props> = React.memo(
  ({ users, guestNum }) => {
    return (
      <VirtualWorldMemberBoxWrapper>
        {users.map((user) => (
          <SortableVirtualWorldMember
            key={user.avatar_id}
            userImage={user.image}
          />
        ))}
        {/* ゲストを表示する */}
        {(() => {
          let guests = [];
          for (let key = 0; key < guestNum; key++) {
            guests.push(
              // @ts-ignore
              <ProfileThumbnail
                // @ts-ignore
                image={RailsAssetPath('guest_icon.png')}
                key={key}
                isRoom
                size='16px'
              />
            );
          }
          return guests;
        })()}
      </VirtualWorldMemberBoxWrapper>
    );
  }
);

const VirtualWorldMemberBoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 2px;
  justify-content: flex-start;
  align-content: flex-start;
  width: 137%;
  height: 121%;
  margin: -8px 0 0 -12px;
  overflow: auto;
`;
