import SimpleButton from 'javascript/components/common/button/SimpleButton';
import { device } from 'javascript/utils/mediaQuery';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { OwnedProductInMybox } from 'types';

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  overflow: scroll;
`;

const StyledProductModal = styled.div<{ isPresentModal: boolean }>`
  position: absolute;
  top: ${({ isPresentModal }) => (isPresentModal ? '96px' : '152px')};
  left: 50%;
  transform: translateX(-50%);
  width: 360px;
  max-height: 70vh;
  padding: 20px 20px 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 4px #ccc;
  overflow: scroll;
  @media ${device.sp} {
    top: ${({ isPresentModal }) => (isPresentModal ? '104px' : '160px')};
    width: 280px;
    max-height: ${({ isPresentModal }) =>
    isPresentModal && 'calc(100vh - 104px)'};
    overflow: ${({ isPresentModal }) => isPresentModal && 'scroll'};
  }
`;

const StyledCloseButton = styled.div`
  position: absolute;
  cursor: pointer;
  img {
    width: 16px;
  }
`;

const StyledModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const StyledSellModalTitle = styled.p`
  margin-top: 32px;
  font-weight: bold;
`;

const StyledActionButtons = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 32px;
`;

const StyledPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  img {
    width: 20px;
  }
  p {
    margin-left: 4px;
    font-size: 24px;
  }
`;

const StyledAlertMessage = styled.p`
  margin-top: 4px;
  color: #e00000;
  font-size: 12px;
`;


interface Props {
  toggleModal: () => void;
  sellProducts: () => void;
  products: OwnedProductInMybox[];
}

export const SellMultiConfirmModal: React.FC<Props> = ({
  toggleModal,
  sellProducts,
  products
}) => {
  const { t } = useTranslation();

  function sell() {
    sellProducts();
    toggleModal();
  }

  function calcTotalSellPrice() {
    return Math.floor(
      products.reduce((acc, p) => acc + p.viket * p.item_count, 0) * 0.8
    );
  }

  return (
    <StyledOverlay>
      <StyledProductModal isPresentModal={false}>
        <StyledCloseButton onClick={toggleModal}>
          {/* @ts-ignore */}
          <img src={RailsAssetPath('close-img-gray.png')} />
        </StyledCloseButton>
        <StyledModalBodyWrapper>
          <StyledSellModalTitle>
            {/* TODO: i18n */}
            選択したアイテムを売却
          </StyledSellModalTitle>
          <StyledPriceWrapper>
            {/* @ts-ignore */}
            <img src={RailsAssetPath('mypage/coin.png')} />
            <p>{calcTotalSellPrice()}</p>
          </StyledPriceWrapper>
          <StyledAlertMessage>
            {t('MyboxSellmodalAlerttosellhalfprice', {})}
          </StyledAlertMessage>

          <StyledActionButtons>
            <SimpleButton borderColor='#00A0E9' fontSize='16px' onClick={sell}>
              {/* TODO: i18n */}
              売却する
            </SimpleButton>
            <SimpleButton borderColor='#00A0E9' fontSize='16px' onClick={toggleModal}>
              {/* TODO: i18n */}
              キャンセル
            </SimpleButton>

            {
              /* Show total price of products */
            }

          </StyledActionButtons>
        </StyledModalBodyWrapper>
      </StyledProductModal>
    </StyledOverlay>
  );
};
