import React, { useEffect } from 'react';
import ModalWorkRegister from './modal/ModalWorkRegister';
import ModalRegistrationMember from './modal/ModalRegistrationMember';
import ModalProfile from './modal/ModalProfile';
import ModalEnterOffice from './modal/ModalEnterOffice';
import ModalEditReport from './modal/ModalEditReport';
import ModalAttendanceType from './modal/ModalAttendanceType';
import { batch } from '../lib/notificationBatch';
import ModalAddPaidHoliday from './modal/ModalAddPaidHoliday';
import ModalAddWorkingHour from './modal/ModalAddWorkingHour';
import InviteCallModal from './voice/direct/invite/InviteCallModal';
import DefaultDialogModal from './voice/DefaultDialogModal';
import ModalEditRealWorld from './modal/ModalEditRealWorld';
import ModalEditVirtualWorld from './modal/ModalEditVirtualWorld';
import ModalCreateVirtualWorld from './modal/ModalCreateVirtualWorld';

const Loader = ({i18n, isWorld}) => {
  useEffect(() => {
    batch();
  }, []);

  return (
    <>
      <ModalCreateVirtualWorld />
      <ModalEditRealWorld />
      <ModalEditVirtualWorld />
      <ModalWorkRegister />
      <ModalRegistrationMember />
      <ModalProfile />
      <ModalEnterOffice />
      <ModalEditReport />
      <ModalAttendanceType />
      <ModalAddPaidHoliday />
      <ModalAddWorkingHour />
      <InviteCallModal isWorld={isWorld}/>
      <DefaultDialogModal />
    </>
  );
};

export default Loader;
