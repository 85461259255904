import React from 'react';
import styled from 'styled-components';
import { avatarPartsArray } from 'javascript/features/avatarParts/constants/selectPartsTypeInDressRoom';
import { device } from 'javascript/utils/mediaQuery';
import { AvatarPartsType } from 'types';

interface Props {
  selectedAvatarPartsType: AvatarPartsType;
  selectAvatarPartsType: (partsType: AvatarPartsType) => void;
}

export const AvatarPartsTypeSelectWrapper: React.FC<Props> = ({
  selectedAvatarPartsType,
  selectAvatarPartsType,
}) => (
  <StyledPartsTypeSelectWrapper>
    <StyledPartsTypeSelectArea>
      {avatarPartsArray.map((avatarParts, i) => (
        <StyledPartsTypeSelectItem
          key={`${i}_${avatarParts}`}
          onClick={() => selectAvatarPartsType(avatarParts)}
          isSelected={selectedAvatarPartsType === avatarParts}
        >
          <img
            // @ts-ignore
            src={RailsAssetPath(`avatar_parts/${avatarParts}_icon.png`)}
          />
        </StyledPartsTypeSelectItem>
      ))}
    </StyledPartsTypeSelectArea>
  </StyledPartsTypeSelectWrapper>
);

const StyledPartsTypeSelectWrapper = styled.div`
  width: 112px;
  height: 90%;
  padding: 32px 12px 72px;
  background: #1a1a1a;
  border-radius: 8px;
  @media ${device.sp} {
    width: 72px;
    height: 400px;
  }
`;

const StyledPartsTypeSelectArea = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledPartsTypeSelectItem = styled.div<{ isSelected: boolean }>`
  display: flex;
  width: 100%;
  height: calc(100% / 5);
  cursor: pointer;
  opacity: ${({ isSelected }) => !isSelected && '0.3'};
`;
