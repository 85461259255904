import React from 'react';
import styled, { css } from 'styled-components';
import { SecretChatMember } from 'types';

interface Props {
  avatar: SecretChatMember;
  secretChatId: number;
  currentAvatarId: number;
  isHost: boolean;
  handleDeleteChatMember: (avatarId: number, secretChatId: number) => void;
}

export const SecretChatAvatar: React.FC<Props> = ({
  avatar,
  currentAvatarId,
  secretChatId,
  isHost,
  handleDeleteChatMember,
}) => {
  const isCurrenAvatar = avatar.avatar_id === currentAvatarId;

  return (
    <StyledAvatarWrapper>
      <StyledAvatarImageWrapper>
        <StyledAvatarImage
          // @ts-ignore
          src={avatar.avatar_image || RailsAssetPath('noimage_icon.png')}
          isBot={!avatar.user_id}
        />
        {/* アバター画像を起点に、ステータスや吹き出しをabsoluteで配置する */}
        {avatar.summon_url && (
          <StyledStatusImage src={avatar.summon_url} position='left' />
        )}
        {avatar.item_url && (
          <StyledStatusImage src={avatar.item_url} position='top' />
        )}
        {avatar.defense_url && (
          <StyledStatusImage src={avatar.defense_url} position='right' />
        )}
        {isHost && !isCurrenAvatar ? (
          <DirectCallDeleteUserButton
            onClick={() =>
              handleDeleteChatMember(avatar.avatar_id, secretChatId)
            }
          />
        ) : null}
      </StyledAvatarImageWrapper>
      <p>{avatar.avatar_name}</p>
    </StyledAvatarWrapper>
  );
};

const StyledAvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 42px;
  p {
    width: 70px;
    text-align: center;
    font-size: 10px;
    transform: scale(0.6);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const StyledAvatarImageWrapper = styled.div`
  position: relative;
  height: 28px;
`;

const StyledAvatarImage = styled.img<{ isBot: boolean }>`
  width: 20px;
  height: ${({ isBot }) => !isBot && '20px'};
  border-radius: ${({ isBot }) => !isBot && '50%'};
  object-fit: cover;
`;

const Left = css`
  left: -6px;
  bottom: 0;
`;

const Top = css`
  top: -12px;
  left: 4px;
`;

const Right = css`
  right: -8px;
  bottom: 0;
`;

const StyledStatusImage = styled.img<{ position: 'left' | 'top' | 'right' }>`
  position: absolute;
  width: 12px;
  height: 12px;
  ${({ position }) =>
    position === 'left' ? Left : position === 'top' ? Top : Right}
`;

const DirectCallDeleteUserButton = styled.button`
  position: absolute;
  top: -14px;
  right: -8px;
  background: #fff;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-shadow: 1px 2px 4px 2px rgb(0 0 0 / 10%);
  &::before,
  &::after {
    content: '';
    width: 6px;
    height: 1px;
    background: #000;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;
