import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AvatarDisplayArea } from 'javascript/features/avatarDressUp/components/AvatarDisplayArea';
import { AvatarPartsItemList } from 'javascript/features/avatarDressUp/components/AvatarPartsItemList';
import { AvatarPartsItemListFooter } from 'javascript/features/avatarDressUp/components/AvatarPartsItemListFooter';
import { AvatarPartsTypeSelectWrapper } from 'javascript/features/avatarDressUp/components/AvatarPartsTypeSelectWrapper';
import { useAvatarParts } from 'javascript/features/avatarDressUp/hooks/useAvatarParts';
import { useSelectedAvatarPartsType } from 'javascript/features/avatarDressUp/hooks/useSelectedAvatarPartsType';
import { useLocalHairColor } from 'javascript/features/avatarDressUp/hooks/useLocalHairColor';
import { device } from 'javascript/utils/mediaQuery';
import { OwnedProductsInDressRoom, UserProfileAvatar } from 'types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

interface Props {
  avatarPartsInfo: UserProfileAvatar;
  currentUserId: number;
  ownedProducts: OwnedProductsInDressRoom;
}

export const AvatarDressUpTemplate: React.FC<Props> = ({
  avatarPartsInfo,
  currentUserId,
  ownedProducts,
}) => {
  const { avatarParts, selectAvatarParts } = useAvatarParts(avatarPartsInfo);
  const { selectedAvatarPartsType, selectAvatarPartsType } =
    useSelectedAvatarPartsType();
  const { localHairColor, selectLocalHairColor } = useLocalHairColor(
    avatarPartsInfo.hair_color
  );

  const isMobile = useMediaQuery({ maxWidth: 430 });
  const { t } = useTranslation();

  const search = useLocation().search;
  const query2 = new URLSearchParams(search);

  return (
    <>
      <StyledAvatarDressUpArea>
        <StyledBackButton>
          <Link to={`/mypage/${currentUserId}?sort_id=${query2.get('sort_id')}&select_id=${query2.get('select_id')}`}>
            {/** @ts-ignore */}
            <img src={RailsAssetPath('mypage/back_icon.png')} />
          </Link>
        </StyledBackButton>
        <AvatarPartsTypeSelectWrapper
          selectedAvatarPartsType={selectedAvatarPartsType}
          selectAvatarPartsType={selectAvatarPartsType}
        />
        <StyledAvatarDisplayAreaWrapper>
          <p>{t('AvatarTextChangeAvatar', {})}</p>
          <AvatarDisplayArea avatarParts={avatarParts} />
          {!isMobile && (
            <>
              <AvatarPartsItemList
                avatarParts={avatarParts}
                ownedProducts={ownedProducts}
                selectedAvatarPartsType={selectedAvatarPartsType}
                localHairColor={localHairColor}
                selectAvatarParts={selectAvatarParts}
              />
              <AvatarPartsItemListFooter
                avatarParts={avatarParts}
                userId={currentUserId}
                selectedAvatarPartsType={selectedAvatarPartsType}
                selectLocalHairColor={selectLocalHairColor}
              />
            </>
          )}
        </StyledAvatarDisplayAreaWrapper>
      </StyledAvatarDressUpArea>
      {isMobile && (
        <StyledMobileBelowWrapper>
          <AvatarPartsItemList
            avatarParts={avatarParts}
            ownedProducts={ownedProducts}
            selectedAvatarPartsType={selectedAvatarPartsType}
            localHairColor={localHairColor}
            selectAvatarParts={selectAvatarParts}
          />
          <AvatarPartsItemListFooter
            avatarParts={avatarParts}
            userId={currentUserId}
            selectedAvatarPartsType={selectedAvatarPartsType}
            selectLocalHairColor={selectLocalHairColor}
          />
        </StyledMobileBelowWrapper>
      )}
    </>
  );
};

const StyledAvatarDressUpArea = styled.div`
  display: flex;
  width: 628px;
  height: 560px;
  @media ${device.sp} {
    width: 355px;
    height: auto;
    padding: 40px 12px 0;
    margin: 0 -14px;
  }
`;

const StyledBackButton = styled.button`
  position: fixed;
  top: 96px;
  left: 48px;
  cursor: pointer;
  img {
    width: 12px;
  }
  @media ${device.sp} {
    position: absolute;
    top: 61px;
    left: 16px;
    img {
      width: 14px;
    }
  }
`;

const StyledAvatarDisplayAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 112px;
  margin-left: 32px;
  p {
    text-align: center;
    font-size: 14px;
  }

  @media ${device.sp} {
    margin-left: 0;
    flex: 1 0 72px;
  }
`;

const StyledMobileBelowWrapper = styled.div`
  margin: 16px -14px 0;
`;
