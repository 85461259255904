import React from 'react';
import { useGlobal } from 'reactn';
import { SecretChatModal } from 'javascript/features/secretChat/components/SecretChatModal';
import { useSelector } from 'react-redux';
import { useSecretChat } from 'javascript/features/secretChat/hooks/useSecretChat';

interface Props {
  isWorld?: boolean
}
export const SecretChatTemplate = (props: Props) => {
  // @ts-ignore
  // TODO: stateに型付け
  const currentUser = useSelector((state) => state.setUser.currentUser);
  const currentAvatarId = currentUser.avatar?.id;
  const [ interactWorldId ] = useGlobal<{ interactWorldId: number | undefined }>('interactWorldId');
  const { secretChats } = useSecretChat(props.isWorld , interactWorldId);

  const joiningSecretChat =
    secretChats && secretChats.length !== 0
      ? secretChats.filter((chat) => {
        return chat.avatar_chat_secret_members.find(
          (member) => member.avatar_id === currentAvatarId
        ) 
        && ((interactWorldId != null) ?  interactWorldId === chat.interact_world_id : chat.interact_world_id == null )
      }

        )
      : undefined;

  const currentAvatar =
    joiningSecretChat && joiningSecretChat.length !== 0
      ? joiningSecretChat[0].avatar_chat_secret_members.find(
          (member) => member.avatar_id === currentAvatarId
        )
      : undefined;
   return (
    <>
      {joiningSecretChat &&
        currentAvatar &&
        joiningSecretChat.map((chat, i) => (
          <SecretChatModal
            key={chat.avatar_chat_secret_room_id}
            index={i}
            ownSecretChat={chat}
            users={chat.avatar_chat_secret_members}
            currentAvatar={currentAvatar}
            interactWorldId={interactWorldId}
            chatType={chat.chat_type}
          />
        ))}
    </>
  );
};
