import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'javascript/features/stayHistory/components/Tooltip';
import { useTooltip } from 'javascript/features/realWorld/hooks/useTooltip';

const StyledBarItem = styled.div<{ itemWidth: number; barColor: string }>`
  height: 100%;
  width: ${({ itemWidth }) => `${itemWidth}%`};
  background-color: ${({ barColor }) => barColor};
  border-right: 1px solid #fff;
  transition: 0.5s;
  z-index: 2;
  &:hover {
    opacity: 0.5;
  }
`;

interface Props {
  barColor: string;
  itemWidth: number;
  roomName: string;
  stayMinutes: number;
}

export const BarItem: React.FC<Props> = ({
  barColor,
  itemWidth,
  roomName,
  stayMinutes,
}) => {
  const {
    targetRef,
    position,
    isHovered,
    handleOnMouseEnter,
    handleOnMouseLeave,
    handleOnMouseMove,
  } = useTooltip();

  const formattedStayMinutes = useMemo(() => {
    const hours = Math.floor(stayMinutes / 60)
      .toString()
      .padStart(2, '0');
    const minutes = Math.floor(stayMinutes % 60)
      .toString()
      .padStart(2, '0');
    return `${hours}:${minutes}`;
  }, [stayMinutes]);

  return (
    <>
      <StyledBarItem
        barColor={barColor}
        itemWidth={itemWidth}
        ref={targetRef}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        onMouseMove={handleOnMouseMove}
      />
      <Tooltip
        isHovered={isHovered}
        position={position}
        roomName={roomName}
        stayMinutes={formattedStayMinutes}
      />
    </>
  );
};
