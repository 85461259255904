import React from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import styled from 'styled-components';
import { useSelectDate } from 'javascript/features/reservedPostForum/hooks/useSelectDate';
import {
  HOUR_ARRAY,
  MINUTE_ARRAY,
} from 'javascript/features/reservedPostForum/constants';
import { useTranslation } from 'react-i18next';

const StyledReservedPostForumModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
`;

const StyledReservedPostForumModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75vh;
  max-width: 350px;
  padding: 24px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  text-align: center;
`;

const StyledClose = styled.span`
  position: relative;
  display: block;
  width: 32px;
  height: 32px;
  cursor: pointer;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 24px;
    background-color: #878787;
  }
  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

const StyledCalendarWrapper = styled.div`
  .DayPicker-Day {
    padding: 2px;
  }
  .DayPicker-Day--today {
    color: #707070;
    font-weight: normal;
  }
`;

const StyledSelectRowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
`;

const StyledSelectBoxWrapper = styled.div`
  position: relative;
  width: 30%;
  select {
    width: 100%;
    background: #fff;
    border: 2px solid #03a9f4;
    border-radius: 8px;
    height: 32px;
    padding-left: 18px;
    font-size: 14px;
    appearance: none;
  }
`;

const StyledTriangle = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  width: 8px;
  height: 6px;
  background-color: #666;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
`;

const StyledTimeSettingWrapper = styled.div`
  margin-top: 8px;
  padding: 0 48px;
  p {
    font-size: 14px;
    text-align: left;
  }
`;

type Props = {
  setShowReservationDateModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDate: Date | undefined;
  setSelectedDate: (date: Date) => void;
  time: { hour: string; minutes: string };
  setTime: React.Dispatch<
    React.SetStateAction<{ hour: string; minutes: string }>
  >;
};

export const ReservedPostForumModal: React.FC<Props> = ({
  setShowReservationDateModal,
  selectedDate,
  setSelectedDate,
  time,
  setTime,
}) => {
  const { t } = useTranslation();
  const { selectDate } = useSelectDate(setSelectedDate);

  return (
    <StyledReservedPostForumModalBackground>
      <StyledReservedPostForumModal>
        <StyledClose onClick={() => setShowReservationDateModal(false)} />
        <StyledCalendarWrapper>
          <DayPicker
            onDayClick={selectDate}
            selectedDays={selectedDate}
            disabledDays={{ before: new Date() }}
          />
        </StyledCalendarWrapper>
        <StyledTimeSettingWrapper>
          <p>{t('ForumTextTime')}</p>
          <StyledSelectRowWrapper>
            <StyledSelectBoxWrapper>
              <select
                name='hour'
                onChange={(e) => setTime({ ...time, hour: e.target.value })}
              >
                {HOUR_ARRAY.map((hour) => (
                  <option key={hour} value={hour}>
                    {hour}
                  </option>
                ))}
              </select>
              <StyledTriangle />
            </StyledSelectBoxWrapper>
            ：
            <StyledSelectBoxWrapper>
              <select
                name='minutes'
                onChange={(e) => setTime({ ...time, minutes: e.target.value })}
              >
                {MINUTE_ARRAY.map((minute) => (
                  <option key={minute} value={minute}>
                    {minute}
                  </option>
                ))}
              </select>
              <StyledTriangle />
            </StyledSelectBoxWrapper>
          </StyledSelectRowWrapper>
        </StyledTimeSettingWrapper>
      </StyledReservedPostForumModal>
    </StyledReservedPostForumModalBackground>
  );
};
