import { useEffect, useState } from 'react';
import useVideoCallContext from './useVideoCallContext';

const usePublications = (participant) => {
  const [publications, setPublications] = useState([]);
  const { room, localTracks, connect , leave , getAudioAndVideoTracks} = useVideoCallContext();

  useEffect(() => {
    setPublications(Array.from(participant.tracks.values()));
    const publicationAdded = (publication) =>
      setPublications(prevPublications => [...prevPublications, publication]);
    const publicationRemoved = (publication) =>
      setPublications(prevPublications => prevPublications.filter(p => p !== publication));

    participant.on('trackPublished', publicationAdded);
    participant.on('trackUnpublished', publicationRemoved);
    return () => {
      participant.off('trackPublished', publicationAdded);
      participant.off('trackUnpublished', publicationRemoved);
    }
  },[participant]);

  return publications;

}
export default usePublications;