import { useCallback } from 'react';
import { useGlobal } from 'reactn';
import { setEditRoomId } from '../../../lib/redux/actions/room';
import store from '../../../lib/redux/store';

export const useShowRealWorldSettingModal = (id) => {
  const [_, setIsShowEditRealWorldModal] = useGlobal("isShowEditRealWorldModal");

  const showRealWorldSettingModal = useCallback(() => {
    store.dispatch(setEditRoomId(id));
    setIsShowEditRealWorldModal(true);
  }, []);

  return { showRealWorldSettingModal }
}