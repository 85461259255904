import React, { useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import axios from 'axios';
import { Office } from 'types';

export const useGetOfficeData = () => {
  const { mutate } = useSWRConfig();
  const fetcher = (url: string) => axios.get(url).then(({ data }) => data);
  const { data: officeData } = useSWR<Office>('/api/v1/office/rooms', fetcher, {
    refreshInterval: 5000,
    refreshWhenHidden: true,
  });

  const convertImage = (themeNum: number) => {
    switch (themeNum) {
      // シンプルを削除したので、2からスタートしている
      case 2:
        return 'world_under_water';
      case 3:
        return 'world_sky';
      case 4:
        return 'world_universe';
      case 5:
        return 'world_under_ground';
      case 6:
        return 'world_tree';
      case 7:
        return 'world_sweet';
      case 8:
        return 'world_neon';
      case 9:
        return 'world_flower';
      case 10:
        return 'world_chinese';
      case 11:
        return 'world_desert';
      case 12:
        return 'world_resort';
      case 13:
        return 'world_festival';
      case 14:
        return 'world_hawaii';
      case 15:
        return 'world_ghost';
      case 16:
        return 'world_european';
      case 17:
        return 'world_halloween';
      case 18:
        return 'world_japanese-garden';
      case 19:
        return 'world_christmas';
      case 20:
        return 'world_newyear';
      case 21:
        return 'world_choco';
      default:
        return '';
    }
  };

  const getOfficeData = useCallback(() => {
    mutate('/api/v1/office/rooms');
  }, []);

  const changeTheme = useCallback((themeNum: number, e: React.MouseEvent) => {
    e.preventDefault();
    const data = { world_image_type: themeNum };
    axios
      .post('/api/v1/users/world_image_update', data)
      .then(() => mutate('/api/v1/office/rooms'))
      .catch((err) => alert(err));
  }, []);

  const convertImageList = (localImage: string, imageList: string[]) => {
    return imageList.map(
      (_, i) => `worldTheme/${localImage}-room-${i + 1}.png`
    );
  };

  const virtualWorldImage =
    officeData && convertImage(officeData.user_world_image);

  const virtualWorldImageList =
    officeData &&
    virtualWorldImage !== undefined &&
    convertImageList(
      virtualWorldImage,
      Object.values(officeData.user_room_image_list)
    );

  return {
    virtualWorldImage,
    virtualWorldImageList,
    convertImage,
    changeTheme,
    officeData,
    getOfficeData,
  };
};
