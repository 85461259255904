import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import styled from 'styled-components';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { connect, useDispatch } from 'react-redux';
import { sp } from '../../utils/mediaQuery';
import { useTranslation } from 'react-i18next';

const FilterBoxWrapper = styled.div`
  margin-bottom: 10px;
  position: relative;
  &::before {
    content: '';
    width: 12px;
    height: 12px;
    border-width: 8px 6px 6px;
    border-style: solid;
    border-color: rgb(111, 111, 111) transparent transparent;
    border-image: initial;
    position: absolute;
    top: 15px;
    right: 15px;
    ${sp`
      top: 12px;
    `}
  }
`;

const FilterBox = styled.select`
  appearance: none;
  height: 35px;
  border-radius: 8px;
  border: 1px solid rgb(191, 191, 191);
  color: rgb(123, 123, 123);
  width: 185px;
  font-size: 14px;
  text-align-last: center;
  background: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${sp`
    width: 109px;
    height: 30px;
    padding: 0 30px;
  `}
`;

const DiaryFillterButtonsWrapper = styled.div`
  position: absolute;
  top: 85px;
  right: 60px;
  ${sp`
    position: static;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 5px 20px;
  `}
`;

const SubmitWrapper = styled.div`
  text-align: right;
`;

const SubmitButton = styled.button`
  width: 80px;
  padding: 3px;
  display: inline-block;
  font-size: 14px;
  background: #00a0e9;
  color: #fff;
  border-radius: 3px;
  ${sp`
    height: 30px;
    font-size: 13px;
    border-radius: 8px;
  `}
`;

const DiaryFillterButtons = ({ handleSubmitSearch, user, isUserSelected }) => {
  const isFromMypageToShowOwnData = location.search.includes('owndata');
  const [teamList, setTeamList] = useGlobal('teamList');
  const [team, setTeam] = useState(isFromMypageToShowOwnData ? { id: 0 } : {});
  const [isUserId, setIsUserId] = useState(false);
  const [date, setDate] = useState({ startDate: null, endDate: null });
  const [focusedInput, setFocusedInput] = useState(null);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    setSelectOption();
  }, [setSelectOption]);

  const setSelectOption = () => {
    if (isFromMypageToShowOwnData) {
      setTeam({ id: 0 });
      return;
    }
    if (teamList && teamList.length) {
      setTeam({ id: user.id, isUser: user.userSelected });
    }
  };

  const onDatesChange = ({ startDate, endDate }) => {
    setDate({ ...date, startDate: startDate, endDate: endDate });
  };

  const onChangeTeam = (e) => {
    const { options } = e.target;
    const t = options[e.target.selectedIndex];
    setTeam({ id: t.value, isUser: t.dataset.user });
    if (t.dataset.user == 'true') {
      setIsUserId(true);
      dispatch({ type: 'SET_IF_USER_SEARCHED', isUserSelected: true });
    } else {
      setIsUserId(false);
      dispatch({ type: 'SET_IF_USER_SEARCHED', isUserSelected: false });
    }
  };

  const onFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  const onSubmit = () => {
    const s = date.startDate;
    const e = date.endDate;
    const dates = {
      start: s ? momentFormatter(s) : s,
      end: e ? momentFormatter(e) : s,
    };
    handleSubmitSearch(dates, team.id);
  };

  const momentFormatter = (obj) => {
    if (obj instanceof moment) {
      return obj.format('YYYYMMDD');
    }
  };

  return (
    <>
      <DiaryFillterButtonsWrapper>
        <RangePicker
          date={date}
          focusedInput={focusedInput}
          onDatesChange={onDatesChange}
          onFocusChange={onFocusChange}
        />
        <FilterBoxWrapper>
          <FilterBox onChange={onChangeTeam} value={team ? team.id : ''}>
            {teamList &&
              teamList.map((team, key) => (
                <option
                  value={team.id}
                  key={key}
                  data-user={team.userSelected ? true : false}
                >
                  {team.name}
                </option>
              ))}
          </FilterBox>
        </FilterBoxWrapper>
        <SubmitWrapper>
          <SubmitButton onClick={onSubmit}>
            {t('DiaryButtonSearch', {})}
          </SubmitButton>
        </SubmitWrapper>
      </DiaryFillterButtonsWrapper>
    </>
  );
};

const RangePicker = ({ date, focusedInput, onDatesChange, onFocusChange }) => {
  return (
    <CustomStyleWrapper>
      <DateRangePicker
        onDatesChange={onDatesChange}
        startDate={date.startDate}
        startDateId='startDateId'
        onFocusChange={onFocusChange}
        focusedInput={focusedInput}
        endDate={date.endDate}
        endDateId='endDateId'
        numberOfMonths={1}
        isOutsideRange={() => false}
        monthFormat='YYYYMMMM'
        displayFormat='YYYY/MM/DD'
      />
    </CustomStyleWrapper>
  );
};

//ピッカーのカスタムスタイル
const CustomStyleWrapper = styled.div`
  .DateRangePickerInput__withBorder {
    border-radius: 8px !important;
    overflow: hidden !important;
    border: 1px solid rgb(191, 191, 191) !important;
  }
  .DateInput {
    width: 85px !important;
    ${sp`
      width: 46px !important;
    `}
  }
  .DateInput_input {
    font-size: 14px !important;
    line-height: 15px !important;
    text-align: center !important;
    padding: 11px 5px 9px !important;
    ${sp`
      height: 28px;
      font-size: 13px !important;
    `}
  }
  .DateInput_input__focused {
    border-bottom: 2px solid #00a0e9 !important;
  }
  .DateRangePickerInput_arrow_svg {
    width: 15px !important;
    height: 15px !important;
  }
  .CalendarDay__default {
    border: none !important;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: #00a0e9 !important;
    border: none !important;
  }
  .CalendarDay__selected_span {
    background: #88d2fb !important;
    border: none !important;
  }
  .DayPickerKeyboardShortcuts_show__bottomRight {
    display: none;
  }
`;

const mapStateToProps = (state) => {
  return {
    user: state.report.user,
    isUserSelected: state.report.isUserSelected,
  };
};

export default connect(mapStateToProps)(DiaryFillterButtons);
