import React from 'react';
import styled from 'styled-components';
import { ViketHistory } from 'javascript/features/userProfile/components/ViketHistory';
import { device } from 'javascript/utils/mediaQuery';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { ViketMonthlyRanking } from './ViketMonthlyRanking';

interface Props {
  avatarId: number;
  viketCount: number;
  level: number;
}

export const UserProfileViketInfo: React.FC<Props> = ({
  avatarId,
  viketCount,
  level,
}) => (
  <StyledUserProfileViketInfoWrapper>
    <StyledViketCountLevelWrapper>
      <StyledViketTitle>
        <Trans i18nKey='ViketTextOwnedViket' />
      </StyledViketTitle>
      <StyledViketCount>
        <StyledViketIconWrapper>
          {/* @ts-ignore */}
          <img src={RailsAssetPath('mypage/coin.png')} />
        </StyledViketIconWrapper>
        <StyledViketNum style={{ color: viketCount < 0 ? 'red' : '#fff' }}>
            {viketCount.toLocaleString()}
            <span className='color-white'>
                <Trans i18nKey='ViketTextViket' />
            </span>
            <style jsx>{`
              .color-white {
                color: #fff;
              }
            `}</style>
        </StyledViketNum>
      </StyledViketCount>
      <StyledLevel>
        <span>
          <Trans i18nKey='ViketTextLevel' />
        </span>
        {level}
      </StyledLevel>
    </StyledViketCountLevelWrapper>
    <StyledItemLinkWrapper>
      <StyledItemLink>
        <StyledLink to={'/shop'}>
          {/* @ts-ignore */}
          <img src={RailsAssetPath('avatar_shop/shop_icon.png')} />
          <Trans i18nKey='ViketTextShop' />
        </StyledLink>
      </StyledItemLink>
      <StyledItemLink>
        <StyledLink to={'/mybox'}>
          {/* @ts-ignore */}
          <img src={RailsAssetPath('mybox/mybox_icon.png')} />
          <Trans i18nKey='ViketTextMyBox' />
        </StyledLink>
      </StyledItemLink>
    </StyledItemLinkWrapper>
    <ViketHistory avatarId={avatarId} />
    <ViketMonthlyRanking avatarId={avatarId} />
  </StyledUserProfileViketInfoWrapper>
);

const StyledUserProfileViketInfoWrapper = styled.div`
  padding: 0 36px;
  @media ${device.sp} {
    margin-top: 16px;
    padding: 0;
  }
`;

const StyledViketCountLevelWrapper = styled.div`
  width: 100%;
  padding: 12px 32px 12px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 12px;
  color: #fff;
  font-size: 12px;
`;

const StyledViketTitle = styled.p`
  font-weight: bold;
`;

const StyledViketCount = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-size: 28px;
`;

const StyledViketIconWrapper = styled.div`
  img {
    width: 24px;
  }
`;

const StyledViketNum = styled.p`
  margin-left: 8px;
  font-size: 26px;
  span {
    margin-left: 12px;
    font-size: 12px;
  }
`;

const StyledLevel = styled.p`
  font-size: 22px;
  span {
    margin-right: 8px;
    font-size: 12px;
  }
`;

const StyledItemLinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const StyledItemLink = styled.div`
  width: 150px;
  height: 40px;
  border: 2px solid #00a0e9;
  border-radius: 12px;
  font-size: 12px;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: 500;
  img {
    height: 22px;
    // office.scssでimg要素にmargin: auto;があたっているので、
    // それを打ち消しつつmargin-rightを設定
    margin: 0 8px 0 0;
  }
`;
