import { useTranslation } from 'react-i18next';

export const useFormatMonth = (unformattedMonth: string) => {
  const { t } = useTranslation();

  const substringOfMonth = unformattedMonth.substring(
    unformattedMonth.length - 2
  );

  switch (substringOfMonth) {
    case '01':
      return t('ProfileTextJanuary', {});
    case '02':
      return t('ProfileTextFebruary', {});
    case '03':
      return t('ProfileTextMarch', {});
    case '04':
      return t('ProfileTextApril', {});
    case '05':
      return t('ProfileTextMay', {});
    case '06':
      return t('ProfileTextJune', {});
    case '07':
      return t('ProfileTextJuly', {});
    case '08':
      return t('ProfileTextAugust', {});
    case '09':
      return t('ProfileTextSeptember', {});
    case '10':
      return t('ProfileTextOctober', {});
    case '11':
      return t('ProfileTextNovember', {});
    case '12':
      return t('ProfileTextDecember', {});
    default:
      return 'no month matched';
  }
};
