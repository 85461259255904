import React from 'react';
import { ThanksTemplate } from 'javascript/features/thanks/components';

interface Props {
  isLoggedInUser?: boolean;
}

const Thanks: React.FC<Props> = ({ isLoggedInUser = false }) => {
  const officeName = location.pathname.substring(8);

  return (
    <ThanksTemplate officeName={officeName} isLoggedInUser={isLoggedInUser} />
  );
};

export default Thanks;
