import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { device } from 'javascript/utils/mediaQuery';
import { AvatarPaidProductType, OwnedProductInMybox } from 'types';

const StyledDefaultModalImageWrapper = styled.div<{ isClothes: boolean }>`
  margin-top: 64px;
  img {
    max-width: 104px;
    max-height: 104px;
  }
`;

const StyledButtonAreaWrapper = styled.div<{ isAbleToPresent: boolean }>`
  display: flex;
  justify-content: ${({ isAbleToPresent }) =>
    isAbleToPresent ? 'space-between' : 'center'};
  width: 100%;
  margin-top: 56px;
  @media ${device.sp} {
    flex-direction: column;
    align-items: center;
    height: 104px;
    margin-top: 40px;
  }
`;

const StyledButton = styled.button<{ danger?: boolean }>`
  width: 156px;
  padding: 8px 16px;
  color: ${({ danger }) => (danger ? '#ec6a40' : '#03a9f4')};
  border: 2px solid ${({ danger }) => (danger ? '#ec6a40' : '#03a9f4')};
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translate(-1px, -1px);
    box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 5%), 4px 4px 4px 0px rgb(0 0 0 / 5%);
  }
`;

interface Props {
  selectedShopProductType: AvatarPaidProductType;
  product: OwnedProductInMybox;
  handleSelectPresent: () => void;
  handleSelectSell: () => void;
}

export const DefaultModalBody: React.FC<Props> = ({
  selectedShopProductType,
  product,
  handleSelectPresent,
  handleSelectSell,
}) => {
  const { t } = useTranslation();
  const isAbleToPresent = !product.present_flg;

  return (
    <>
      <StyledDefaultModalImageWrapper
        isClothes={selectedShopProductType === 'clothes'}
      >
        <img
          src={
            selectedShopProductType !== 'hair'
              ? // @ts-ignore
                RailsAssetPath(
                  `avatar_parts/${selectedShopProductType}/${product.product_number}.png`
                )
              : // @ts-ignore
                RailsAssetPath(
                  `avatar_parts/${selectedShopProductType}/black/${product.product_number}.png`
                )
          }
        />
      </StyledDefaultModalImageWrapper>
      <StyledButtonAreaWrapper isAbleToPresent={isAbleToPresent}>
        {/* プレゼントされたものは招待できないようにする */}
        {isAbleToPresent && (
          <StyledButton onClick={handleSelectPresent}>
            {t('MyboxDefaultmodalPresent', {})}
          </StyledButton>
        )}
        <StyledButton danger onClick={handleSelectSell}>
          {t('MyboxDefaultmodalSell', {})}
        </StyledButton>
      </StyledButtonAreaWrapper>
    </>
  );
};
