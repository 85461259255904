import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as style from '../../style/VoiceButtons';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';

const VoiceChatPointBalance = () => {
  // @ts-ignore
  const currentUser = useSelector((state) => state.setUser.currentUser);

  const isMobile =
    useMediaQuery({ maxWidth: 430 }) ||
    (/android|ipod|ipad|iphone|macintosh/.test(
      navigator.userAgent.toLowerCase()
    ) &&
      'ontouchend' in document);

  const voiceImage = RailsAssetPath(`voice/icon_point.png`);
  const paymentImage = RailsAssetPath(`plus.png`);
  let type = 'point';

  return (
    <style.__VoiceChatButton type={type}>
      <style.__VoiceChatButtonPoint>
        <img src={voiceImage} />
        <style.__VoiceChatButtonPointNumber>
          {currentUser.twilio_remain_point}
          <style.__VoiceChatButtonPoint>pt</style.__VoiceChatButtonPoint>
        </style.__VoiceChatButtonPointNumber>
        {!isMobile && (
          <Link to='/payment'>
            <style.__PointBuyButton>
              <img src={paymentImage} />
            </style.__PointBuyButton>
          </Link>
        )}
      </style.__VoiceChatButtonPoint>
    </style.__VoiceChatButton>
  );
};

export default VoiceChatPointBalance;
