import React from 'react';
import styled from 'styled-components';
import { sp } from '../utils/mediaQuery';

export const DisplayAlertMessage = ({ children }) => (
  <Wrapper>
    <div>
      <img src={RailsAssetPath('viket-town-header-logo.png')} />
    </div>
    <p>{children}</p>
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 80px;
  width: 100vw;
  height: 100vh;
  background: #f2f2f2;
  text-align: center;
  div {
    margin-bottom: 40px;
    img {
      width: 400px;
    }
  }
  ${sp`
    padding: 32px;
    text-align: left;
  `}
`;
