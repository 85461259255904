import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

export type IsLoadedState = {
  isLoaded: boolean;
};

export const INITIAL_IS_LOADED_STATE: IsLoadedState = {
  isLoaded: false,
};

export const isLoadedStore = atom<IsLoadedState>({
  key: 'isLoadedState',
  default: INITIAL_IS_LOADED_STATE,
});

export const useIsLoadedStoreValue = () => {
  const { isLoaded } = useRecoilValue(isLoadedStore);
  return isLoaded;
};

export const useSetIsLoadedStore = () => {
  return useSetRecoilState(isLoadedStore);
};
