import React from "react";
import Participant from "./Participant";
import useParticipants from "./hooks/useParticipants";
import useVoiceContext from "./hooks/useVoiceContext";

const ParticipantList = () => {
  const { room } = useVoiceContext();
  const participants = useParticipants();

  if (!room || participants.length <= 0) {
    return null;
  }

  const remoteParticipants = participants.map(participant => (
    <Participant key={participant.sid} participant={participant} />
  ));

  return (
    <>
      {room ? (
        <Participant
          key={room.localParticipant.sid}
          participant={room.localParticipant}
        />
      ) : (
        ""
      )}
      {remoteParticipants}
    </>
  );
}

export default ParticipantList;