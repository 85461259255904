import consumer from 'javascript/channels/consumer';
import { Avatar } from 'types';

interface PublishedData {
  type: 'location' | 'create_chat_open' | 'create_bot_question';
  avatars: Avatar[];
  sender_ids: {
    user_id?: number;
    avatar_id?: number;
    speaker?: boolean;
  };
}

interface LocationParams {
  x_coordinate: number;
  y_coordinate: number;
  interact_world_id: number | undefined;
}

interface ChatOpenParams {
  content?: string;
  bulletin_board_content?: string;
  interact_world_id: number | undefined;
}

interface UpdateStatusParams {
  avatar_id: number;
  defense?: number | null;
  summon?: number | null;
  item?: number | null;
  interact_world_id: number | undefined;
}

type TypeUpdateAvatars = (data: Avatar[]) => void;

const sound = new Audio('/assets/freeSpace/receive_open_chat.mp3');

export const interactWorldAvatarChannel = (
  updateAvatars?: TypeUpdateAvatars
) => {
  const subscribeInteractWorldAvatarChannel = (
    currentAvatarId?: number,
    interactWorldId?: number
  ) =>
    consumer.subscriptions.create(
      {
        channel: 'InteractWorldAvatarChannel',
        interact_world_id: interactWorldId,
      },
      {
        received(data: PublishedData) {
          updateAvatars && updateAvatars(data.avatars);
          if (
            data.type === 'create_chat_open' &&
            currentAvatarId &&
            currentAvatarId !== data.sender_ids.avatar_id &&
            data.sender_ids.speaker
          ) {
            sound.play();
            return;
          }
        },
        location(params: LocationParams) {
          return this.perform('location', params);
        },
        create_chat_open(params: ChatOpenParams) {
          return this.perform('create_chat_open', params);
        },
        update_status(params: UpdateStatusParams) {
          return this.perform('update_status', params);
        },
        destroy() {
          let channel = JSON.parse(this.identifier).channel;
          this.consumer.subscriptions['subscriptions'].forEach(
            (subscription: any) => {
              let identifier = JSON.parse(subscription.identifier);
              if (channel == identifier.channel) {
                this.consumer.subscriptions.remove(subscription);
              }
            }
          );
        },
      }
    );

  return { subscribeInteractWorldAvatarChannel };
};
