import React, { useState, useEffect, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
  Typography,
  Grid,
  Button,
  Theme,
  Hidden,
  InputLabel,
  TextField,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

import useVideoCallContext from './hooks/useVideoCallContext';
import axios from 'axios';
import IntroContainer from './IntroContainer';
import LocalVideoPreview from './LocalVideoPreview';
import useLocalAudioToggle from './hooks/useLocalAudioToggle';
import useLocalVideoToggle from './hooks/useLocalVideoToggle';

import { sp } from '../../utils/mediaQuery';
import { useMediaQuery } from 'react-responsive';
import { setVideoCallRoom } from '../../lib/redux/actions/videoCall';
import { useIsVideoConnect } from './hooks/useIsVideoConnect';
import { DisplayAlertMessage } from '../DisplayAlertMessage';
import { isChrome } from 'javascript/utils/isChrome';
import TouchRipple from '@material-ui/core/ButtonBase/TouchRipple';
import { Trans, useTranslation } from 'react-i18next';

const PreIntroduction = ({ roomId, created, isGuest }) => {
  const {
    room,
    localTracks,
    connect: videoConnect,
    leave,
    getAudioAndVideoTracks,
    isOtherConnected,
    videoConnectStatus,
    remainingPoint,
    setRemainingPoint,
  } = useVideoCallContext();
  const [token, setToken] = useState(null);
  const [roomName, setRoomName] = useState(null);
  const [mediaError, setMediaError] = useState();
  const [isFetching, setFetching] = useState(false);
  const [isAudioEnabled, toggleAudioDisabled] = useLocalAudioToggle();
  const [isVideoEnabled, toggleVideoDisabled] = useLocalVideoToggle();

  const currentUser = useSelector((state) => state.setUser.currentUser);
  const [userName, setUserName] = useState('');
  const [notWorking, setNotWorking] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 430 });
  const [isConnect, setIsConnect] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    if (isOtherConnected) return;
    if (token && roomName) {
      videoConnect(token, roomName);
    }
    const args = {
      created: true,
      roomId: roomId,
      isGuest: isGuest,
      token: token,
    };
    dispatch(setVideoCallRoom(args));
  }, [token, roomName, isOtherConnected]);

  useEffect(() => {
    if (isOtherConnected) return;
    getAudioAndVideoTracks()
      .then((res) => {
        // toggleAudioDisabled();
        // toggleVideoDisabled();
        setIsConnect(true);
      })
      .catch((error) => {
        setMediaError(error);
        setIsConnect(true);
      });
  }, []);

  useEffect(() => {
    if (Object.keys(currentUser).length > 0) {
      setUserName(currentUser.real_name);
      // setFetching(true);
    }
    // if (Object.keys(currentUser).length > 0 && isConnect && !isOtherConnected) {
    //   // ログイン状態の場合はカメラをオフにして即時ルームへ接続
    //   setIsConnect(false);
    //   toggleAudioDisabled();
    //   toggleVideoDisabled();
    //   handleJoinRoom();
    // }
  }, [currentUser]);

  const startVideoCall = () => {
    if (videoConnectStatus()) return;
    const params = {
      remorks_room_id: roomId,
      twilio_room_id: null,
      invite_user_id: null,
      user_identity: userName,
      is_guest: isGuest,
    };
    setFetching(true);
    getToken(params);
  };

  const getToken = (params) => {
    if (isOtherConnected) return;
    axios
      .post('/api/v1/twilio/videocalls/room_enter', params)
      .then((res) => {
        if (res.data.status == 200) {
          setToken(res.data.token);
          setRoomName(res.data.room_name);
        } else {
          alert(res.data.message);
          setFetching(false);
        }
      })
      .catch((err) => {
        setFetching(false);
        console.log(err);
        alert(t('VideocallAlertUnexpectedError', {}));
      });
  };

  const handleJoinRoom = () => {
    setFetching(true);
    // officeのroomAPIではcurrent_userが取得できないケースがあるので、remain_pointのみ取るAPIを別途作成
    axios
      .get('/api/v1/twilio/videocalls/remain_point/' + roomId)
      .then((res) => {
        const data = res.data;
        setRemainingPoint(data.twilio_remain_point);
        if (data.twilio_remain_point <= 0 && !isGuest) {
          dispatch({ type: 'SET_TYPE_CALL_LIMIT', isShow: true });
          setFetching(false);
          return;
        } else {
          startVideoCall();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleNameChange = (event) => {
    setUserName(event.target.value);
  };

  if (isOtherConnected || videoConnectStatus()) {
    return (
      <DisplayAlertMessage>
        {t('VideocallAlertYouAlreadyJoinRoom', {})}
      </DisplayAlertMessage>
    );
  }
  return (
    <IntroContainer>
      <Grid
        container
        item
        md={12}
        sm={12}
        xs={12}
        direction={isMobile ? 'column-reverse' : 'row'}
      >
        <Grid item md={5} sm={6} xs={12}>
          <Grid container spacing={5} justify='center' alignItems='center'>
            <Grid container item xs={12} direction={'column'}>
              <JoinRoomTitle>
                <Trans i18nKey='VideocallTextEnterVideoRoom' />
              </JoinRoomTitle>
            </Grid>

            {isFetching ? (
              <Grid
                container
                justify='center'
                alignItems='center'
                direction={'column'}
                style={{ height: '200px' }}
              >
                <div>
                  <CircularProgress variant='indeterminate' />
                </div>
                <div>
                  <Typography
                    variant='body2'
                    style={{ fontWeight: 'bold', fontSize: '16px' }}
                  >
                    {t('VideocallTextConnectVideoRoom', {})}
                  </Typography>
                </div>
              </Grid>
            ) : (
              <Grid
                container
                item
                xs={12}
                direction={'row'}
                spacing={2}
                justify='center'
                alignItems='center'
              >
                <Grid container item xs={12} direction={'column'} spacing={2}>
                  <InputLabel shrink htmlFor='input-user-name'>
                    {t('VideocallTextName', {})}
                  </InputLabel>
                  <TextField
                    id='input-user-name'
                    variant='outlined'
                    fullWidth
                    size='small'
                    value={userName}
                    onChange={handleNameChange}
                    disabled={!isGuest}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Notice>
                    <Trans i18nKey='VideocallTextPrivacyLink'>
                      入室をクリックすると
                      <a href='/privacy'>利用規約とプライバシーポリシー</a>
                      に同意した事になります
                    </Trans>
                  </Notice>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    style={{ width: '100%' }}
                    variant='contained'
                    color='primary'
                    data-cy-join-now
                    onClick={handleJoinRoom}
                    disabled={!isConnect || !userName}
                  >
                    {isGuest
                      ? t('VideocallButtonGuestEnterVideoRoom', {})
                      : t('VideocallButtonEnterVideoRoom', {})}
                  </Button>
                </Grid>
                <Grid container item xs={12} justify='center'>
                  <StyledSignInAndAlertWrapper>
                    {isGuest ? (
                      <a href='/sign_in'>
                        <Trans i18nKey='VideocallStatusSignin' />
                      </a>
                    ) : (
                      ''
                    )}
                    {!isChrome && (
                      <StyledNotChromeAlert>
                        {t('GenelalAlertOnlyChrome', {})}
                      </StyledNotChromeAlert>
                    )}
                  </StyledSignInAndAlertWrapper>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {isConnect ? (
          <Grid container item md={7} sm={6} xs={12}>
            <LocalPreviewContainer isMobile={isMobile}>
              <LocalVideoPreview
                identity={name}
                isGuest={isGuest}
                isFetching={isFetching}
              />
            </LocalPreviewContainer>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </IntroContainer>
  );
};

const JoinRoomTitle = styled.div`
  font-size: 18px;
  text-align: center;
  font-weight: bold;
`;
const Notice = styled.div`
  display: inline-block;
  font-size: 11px;
  a {
    color: #0fa5e9;
  }
`;
const LocalPreviewContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
  padding-left: 40px;
  ${sp`
    padding-left:0;
  `}
`;

const StyledSignInAndAlertWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    font-size: 11px;
    color: #0fa5e9;
  }
`;

const StyledNotChromeAlert = styled.p`
  margin-top: 8px;
  font-size: 16px;
  font-weight: bold;
  color: #f00;
`;

// const mapStateToProps = state => {
// 	return {
//     roomId: state.videoCall.roomId,
// 		isGeust: state.videoCall.isGuest,
//     token: state.videoCall.token,
// 	}
// }

// export default connect(mapStateToProps)(PreIntroduction);
export default PreIntroduction;
