import React , {useRef, useEffect} from 'react';
import { Track } from 'twilio-video';
import useMediaStreamTrack from './hooks/useMediaStreamTrack';
import useVideoCallContext from './hooks/useVideoCallContext';
import useVideoTrackDimensions from './hooks/useVideoTrackDimensions';

const VideoTrack = ({ track, isLocal, priority }) => {
  const ref = useRef();
  const { room, getAudioAndVideoTracks, localTracks, connect , leave } = useVideoCallContext();
  const mediaStreamTrack = useMediaStreamTrack(track);
  const dimensions = useVideoTrackDimensions(track);
  const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0);

  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    if (track.setPriority && priority) {
      track.setPriority(priority);
    }
    track.attach(el);
    return () => {
      track.detach(el);
      el.srcObject = null;
      if (track.setPriority && priority) {
        track.setPriority(null);
      }
    };
  }, [track, priority]);

  const isFrontFacing = mediaStreamTrack?.getSettings().facingMode !== 'environment';
  const style = {
    transform: isLocal && isFrontFacing ? 'rotateY(180deg)' : '',
    objectFit:  track.name.includes('screen') ? 'contain' : 'cover',
    width: '100%',
    height: '100%',
  };

  return <video track={track.name} ref={ref} style={style} />
}

export default VideoTrack;