import { BREAKPOINT_MIN_PC } from 'javascript/components/landingPage/lib/MQ';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

export type IsSpState = {
  isSp: boolean;
};

export const INITIAL_IS_SP_STATE: IsSpState = {
  isSp: window.innerWidth < BREAKPOINT_MIN_PC,
};

export const isSpStore = atom<IsSpState>({
  key: 'isSpState',
  default: INITIAL_IS_SP_STATE,
});

export const useIsSpStoreValue = () => {
  const { isSp } = useRecoilValue(isSpStore);
  return isSp;
};

export const useSetIsSpStore = () => {
  return useSetRecoilState(isSpStore);
};
