import React from 'react';
import axios from 'axios';
import { saveAs } from "file-saver";

// CsvButton
const CsvButton = ({ year, month, order }) => {
  const handleDownloadCsv = () => {
    const url = "/api/v1/attendances/csv";
    const params = {
      year: year,
      month: month,
      order: order,
    };
    const config = {
      headers: {
        "content-type": "text/csv",
        "Content-Disposition": 'attachment; filename="2020-02.csv";',
      },
    };
    axios
      .get(url, { params }, config)
      .then((res) => {
        const blob = new Blob([res.data], {
          type: res.data.type,
        });
        const contentDisposition = res.headers["content-disposition"];
        const fileName = getFileName(contentDisposition);
        saveAs(blob, fileName);
      })
      .catch((error) => {
        alert(error.response);
      });
  };

  const getFileName = contentDisposition => {
    let fileName = contentDisposition.substring(
      contentDisposition.indexOf("''") + 2,
      contentDisposition.length
    );
    fileName = decodeURI(fileName).replace(/\+/g, " ");
    return fileName;
  }

  return (
    <div onClick={handleDownloadCsv} className="btn-csv-dl">
      <div className="btn-inner"></div>
    </div>
  );
};

export default CsvButton;