import moment from 'moment';
import { useMemo } from 'react';

export const useYearMonthOption = () => {
  const yearMonthOption: string[] = [];

  // 当月から過去12ヶ月分をCSV出力できるようにする
  for (let i = 0; i < 12; i++) {
    yearMonthOption.push(moment().subtract(i, 'month').format('YYYYMM'));
  }

  // 滞在履歴の数値が正常なのが2022/4〜なので、そのデータのみを出力できるようにする
  const filteredYearMonth = useMemo(
    () => yearMonthOption.filter((ym) => Number(ym) >= 202204),
    []
  );

  return { yearMonthOption: filteredYearMonth };
};
