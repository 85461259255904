import React from 'react';
import styled from 'styled-components';
import { RealWorldMember } from 'javascript/features/realWorld/components/RealWorldMember';
import { device } from 'javascript/utils/mediaQuery';
import { RealWorld } from 'types';

interface Props {
  realWorld: RealWorld;
  isCast: boolean;
}

export const RealWorldMemberBox: React.FC<Props> = ({ realWorld, isCast }) => (
  <RealWorldMemberBoxWrapper>
    {realWorld.users.map((user) => (
      <RealWorldMember key={user.user_id} user={user} isCast={isCast}/>
    ))}
  </RealWorldMemberBoxWrapper>
);

const RealWorldMemberBoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1px;
  align-items: baseline;
  max-height: 66px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media ${device.sp} {
    max-height: 52px;
    gap: 0;
  }
`;
