import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { ProductTypeSelect } from 'javascript/features/avatarShop/components/ProductTypeSelect';
import { useSelectedShopProductType } from 'javascript/features/avatarShop/hooks/useSelectedShopProductType';
import { MyboxProduct } from 'javascript/features/mybox/components/MyboxProduct';
import { AvatarOwnedSkillProductSort, useMybox } from 'javascript/features/mybox/hooks/useMybox';
import { device } from 'javascript/utils/mediaQuery';
import { useIsTypeProductList } from 'javascript/features/mybox/util/useIsTypeProductList';
import { MyboxSkillProduct } from 'javascript/features/mybox/components/MyboxSkillProduct';
import SimpleButton from 'javascript/components/common/button/SimpleButton';
import { OwnedProductInMybox } from 'types';
import { useSellProducts } from '../hooks/useSellProducts';
import {
  FILTER_TYPE,
} from 'javascript/features/avatarShop/constants/sortAndFilterType';
import { useFilterType } from 'javascript/features/avatarShop/hooks/useFilterType';
import { OrderedHistoryItemComponent } from 'javascript/features/avatarShop/components/OrderedHistoryItem';
import { AvatarOrderedHistorySort, useOrderedHistory } from 'javascript/features/avatarShop/hooks/useOrderedHistory';
import { useOpenModal } from 'javascript/features/avatarShop/hooks/useOpenModal';
import { SellMultiConfirmModal } from './SellMultiConfirmModal';

const CssFlexAlignCenter = css`
  display: flex;
  align-items: center;
`;

const StyledMybox = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  height: 100%;
  margin-left: -56px;
  @media ${device.sp} {
    width: 100%;
    margin-left: 0;
    padding-top: 8px;
  }
`;

const StyledMyboxTitle = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  @media ${device.sp} {
    font-size: 16px;
  }
`;

const StyledLinkToAnotherPage = css`
  display: flex;
  position: absolute;
  top: 4px;
  padding-bottom: 2px;
  border-bottom: 1px solid #707070;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  cursor: pointer;
  img {
    width: 20px;
    margin-right: 4px;
  }
  @media ${device.sp} {
    top: 0;
    font-size: 12px;
    img {
      width: 14px;
    }
  }
`;

const StyledLinkToShop = styled.div`
  ${StyledLinkToAnotherPage}
  right: 0;
`;

const StyledLinkToDressRoom = styled.div`
  ${StyledLinkToAnotherPage}
  left: 0;
  img {
    filter: grayscale(100%);
  }
`;

const StyledIconWrapper = styled.div`
  margin-right: 8px;
  margin-left: 112px;
  img {
    width: 32px;
  }
  @media ${device.sp} {
    margin-left: 0;
    img {
      width: 22px;
    }
  }
`;

const StyledMyboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow: scroll;
`;

const StyledMyboxList = styled.div<{ isSkill: boolean }>`
  display: grid;
  grid-template-columns: repeat(6, 100px);
  grid-auto-rows: auto;
  column-gap: 10px;
  row-gap: ${({ isSkill }) => (isSkill ? '16px' : '16px')};
  height: 100%;
  margin-left: 48px;
  padding-bottom: 8px;
  padding-right: 16px;
  overflow: scroll;
  @media ${device.sp} {
    margin-left: 0;
    grid-template-columns: repeat(2, 100px);
  }
`;

const StyledNoProductMessage = styled.p`
  width: 650px;
  text-align: center;
  @media ${device.sp} {
    width: auto;
    margin-right: 56px;
  }
`;

const StyledSubHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 16px;
  margin-left: 78.14px;
`;

const StyledFilterButton = styled.button<{ isSelected: boolean }>`
  padding: 2px 16px;
  border: ${({ isSelected }) => !isSelected && '1px solid #e5e5e5'};
  border-radius: 6px;
  background-color: ${({ isSelected }) => (isSelected ? '#00a0e9' : '#fff')};
  color: ${({ isSelected }) => (isSelected ? '#fff' : '#00a0e9')};
  font-size: 10px;
  &:first-child {
    margin-right: 8px;
  }
`;

const StyledSortWrapper = styled.div<{ isOrderedSkillShown?: boolean }>`
  ${CssFlexAlignCenter}
  flex-wrap: wrap;
  &:first-child {
    visibility: ${({ isOrderedSkillShown }) => isOrderedSkillShown && 'hidden'};
  }
  @media ${device.sp} {
    width: 230px;
    &:first-child {
      margin-bottom: 8px;
    }
  }
`;


const StyledSortItem = styled.div<{ active: boolean }>`
  ${CssFlexAlignCenter}
  padding: 2px 8px;
  border-left: 1px solid #00A0E9;
  color: #00A0E9;
  font-size: 12px;
  cursor: pointer;
  font-weight: ${({ active }) => active && 'bold'};
  &:first-child {
    border-left: none;
  }

  @media ${device.sp} {
    &:nth-child(4) {
      border-left: none;
    }
  }
`;


interface Props {
  userId: number | undefined;
}

export const MyboxTemplate: React.FC<Props> = ({ userId }) => {
  const { t } = useTranslation();
  const { selectShopProductType, selectedShopProductType } =
    useSelectedShopProductType();
  const [avatarOwnedSkillProductSort, setAvatarOwnedSkillProductSort] = React.useState<AvatarOwnedSkillProductSort>(null);
  const { fixedShownProductListInMybox } = useMybox(selectedShopProductType, avatarOwnedSkillProductSort);
  // TODO 型付け
  // @ts-ignore
  const currentUser = useSelector((state) => state.setUser.currentUser);
  const currentAvatarId = currentUser?.avatar?.id as number | undefined;
  const [multipleSellDisabled, setMultipleSellDisabled] = React.useState(true);
  const [checkedProducts, setCheckedProducts] = React.useState<OwnedProductInMybox[]>([]);
  const { filterType, setFilterType } = useFilterType();
  const { orderedHistory, avatarOrderedHistorySort, setAvatarOrderedHistorySort } = useOrderedHistory();
  const { isOpen, toggleModal } = useOpenModal();

  const avatarOwnedSkillProductSorts = [
    { label: "購入日", value: "created_at" }, // TODO
    { label: "使用期限", value: "expired_at" }, // TODO
    { label: "使用期限切れ", value: "expired" }, // TODO
    { label: "未利用", value: "used" }, // TODO
    { label: "販売者", value: "avatar" } // TODO
  ]

  const avatarBoughtSkillProductSorts = [
    { label: "販売日", value: "created_at" }, // TODO
    { label: "使用期限", value: "expired_at" }, // TODO
    { label: "使用期限切れ", value: "expired" }, // TODO
    { label: "未対応", value: "handling" }, // TODO
    { label: "対応済", value: "handled" }, // TODO
  ]

  function handleCheckProduct(product: OwnedProductInMybox) {
    if (checkedProducts.includes(product)) {
      setCheckedProducts(checkedProducts.filter((p) => p !== product));
    } else {
      setCheckedProducts([...checkedProducts, product]);
    }
  }

  useEffect(() => {
    if (checkedProducts.length > 0) {
      setMultipleSellDisabled(false);
    } else {
      setMultipleSellDisabled(true);
    }
  }, [checkedProducts]);


  const { handleSellProducts } = useSellProducts(checkedProducts, avatarOwnedSkillProductSort);

  function sellProducts() {
    handleSellProducts();
    setCheckedProducts([]);
  }

  function sortAavatarOwnedSkillProducts(sort: AvatarOwnedSkillProductSort) {
    setAvatarOwnedSkillProductSort(sort);
  }

  const { isTypeProductList } = useIsTypeProductList();

  return (
    <StyledMybox>
      <StyledMyboxTitle>
        <StyledIconWrapper>
          {/* @ts-ignore */}
          <img src={RailsAssetPath('mybox/mybox_icon.png')} />
        </StyledIconWrapper>
        {t('MyboxPageTitle', {})}
        <Link to={`mypage/${userId}?target=avatar`}>
          <StyledLinkToDressRoom>
            {/* @ts-ignore */}
            <img src={RailsAssetPath('mypage/dressup_icon.png')} />
            {t('MenuUserAvatar', {})}
          </StyledLinkToDressRoom>
        </Link>
        <Link to='/shop'>
          <StyledLinkToShop>
            {/* @ts-ignore */}
            <img src={RailsAssetPath('avatar_shop/shop_icon.png')} />
            {t('ShopPageTitle', {})}
          </StyledLinkToShop>
        </Link>
      </StyledMyboxTitle>
      <StyledSubHeaderContainer>
        <div>
          {
            selectedShopProductType == 'skill' && filterType === 1 && (
              <StyledSortWrapper>
                {
                  avatarOwnedSkillProductSorts.map((item) => (
                    <StyledSortItem
                      key={item.label}
                      active={item.value == avatarOwnedSkillProductSort}
                      onClick={() => sortAavatarOwnedSkillProducts(item.value as AvatarOwnedSkillProductSort)}>
                      {item.label}
                    </StyledSortItem>
                  ))
                }
              </StyledSortWrapper>
            )
          }

          {
            selectedShopProductType == 'skill' && filterType === 2 && (
              <StyledSortWrapper>
                {
                  avatarBoughtSkillProductSorts.map((item) => (
                    <StyledSortItem
                      key={item.label}
                      active={item.value == avatarOrderedHistorySort}
                      onClick={() => setAvatarOrderedHistorySort(item.value as AvatarOrderedHistorySort)}>
                      {item.label}
                    </StyledSortItem>
                  ))
                }
              </StyledSortWrapper>
            )
          }
        </div>
        <div>
          {
            selectedShopProductType != 'skill' && (
              // TODO: i18n
              <SimpleButton disabled={multipleSellDisabled} onClick={() => toggleModal()}>一括売却</SimpleButton>
            )
          }
          {
            selectedShopProductType == 'skill' && (
              <StyledSortWrapper>
                {/* TODO: i18n */}
                {FILTER_TYPE.map((item) => (
                  <StyledFilterButton
                    key={item.type}
                    isSelected={filterType === item.type}
                    onClick={() => setFilterType(item.type)}
                  >
                    {item.text}
                  </StyledFilterButton>
                ))}
              </StyledSortWrapper>
            )
          }
        </div>
      </StyledSubHeaderContainer >
      <StyledMyboxWrapper>
        <ProductTypeSelect
          selectedShopProductType={selectedShopProductType}
          selectShopProductType={selectShopProductType}
        />
        {fixedShownProductListInMybox &&
          (fixedShownProductListInMybox.length !== 0 ? (
            <StyledMyboxList isSkill={selectedShopProductType == 'skill'}>
              {isTypeProductList(fixedShownProductListInMybox)
                ? fixedShownProductListInMybox.map((product) => (
                  <MyboxProduct
                    key={`${product.avatar_owned_product_id[0]}`}
                    product={product}
                    selectedShopProductType={selectedShopProductType}
                    currentAvatarId={currentAvatarId}
                    setCheckedProduct={handleCheckProduct}
                    avatarOwnedSkillProductSort={avatarOwnedSkillProductSort}
                  />
                ))
                : filterType == 1 ? fixedShownProductListInMybox.map((product) => (
                  <MyboxSkillProduct
                    key={`${product.avatar_owned_product_id}`}
                    product={product}
                    selectedShopProductType={selectedShopProductType}
                    currentAvatarId={currentAvatarId}
                    avatarOwnedSkillProductSort={avatarOwnedSkillProductSort}
                  />
                )) : orderedHistory && orderedHistory.map((historyItem) => (
                  <OrderedHistoryItemComponent
                    key={historyItem.avatar_owned_product_id}
                    historyItem={historyItem}
                    avatarOwnedSkillProductSort={avatarOwnedSkillProductSort}
                  />
                ))
              }
            </StyledMyboxList>
          ) : (
            <StyledNoProductMessage>
              {t('MyboxPageNoitem', {})}
            </StyledNoProductMessage>
          ))}

        {
          isOpen && (
            <SellMultiConfirmModal toggleModal={toggleModal} sellProducts={sellProducts} products={checkedProducts} />
          )
        }

      </StyledMyboxWrapper>
    </StyledMybox >
  );
};
