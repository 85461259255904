import { useCallback } from 'react';
import { useGlobal } from 'reactn';
import { setEditRoomId } from '../../../lib/redux/actions/room';
import store from '../../../lib/redux/store';
import { useTranslation } from 'react-i18next';

export const useShowVirtualWorldSettingModal = (id, hasUser) => {
  const [_, setIsShowEditVirtualWorldModal] = useGlobal(
    'isShowEditVirtualWorldModal'
  );
  const { t } = useTranslation();

  const showVirtualWorldSettingModal = useCallback(() => {
    if (hasUser) {
      alert(t("WorldAlertVirtualUserCantEdti",{}));
      return;
    }
    store.dispatch(setEditRoomId(id));
    setIsShowEditVirtualWorldModal(true);
  }, [id, hasUser]);

  return { showVirtualWorldSettingModal };
};
