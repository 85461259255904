import React from 'react';
import styled from 'styled-components';
import ProfileThumbnail from 'javascript/components/items/ProfileThumbnail';
import { Comment } from 'types';
import { useTranslation } from 'react-i18next';

interface Props {
  comments: Comment[];
}

export const RecentComments: React.FC<Props> = ({
  comments,
}: {
  comments: Comment[];
}) => {
  const emotionData = ['heart', 'cracker', 'star', 'megaphone'];
  const { t } = useTranslation();

  return (
    <>
      {comments.length !== 0 ? (
        comments.map((item, key) => (
          <StyledCommentItem key={key}>
            <StyledCommentItemDate>{item.created_at}</StyledCommentItemDate>
            {item.feeling_status && (
              <StyledCommentItemIcon>
                <img
                  // @ts-ignore
                  src={RailsAssetPath(
                    'icon-' + emotionData[item.feeling_status - 1] + '-blue.png'
                  )}
                />
              </StyledCommentItemIcon>
            )}
            <StyledCommentItemThumbnail>
              {/** @ts-ignore */}
              <ProfileThumbnail image={item.user.image} size='50px' />
            </StyledCommentItemThumbnail>
            <StyledCommentItemContent>
              <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
            </StyledCommentItemContent>
          </StyledCommentItem>
        ))
      ) : (
        <p>{t('CommentTextNoComment', {})}</p>
      )}
    </>
  );
};

const StyledCommentItem = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 32px;
  padding: 8px 16px;
  background-color: #fff;
  border-radius: 10px;
`;
const StyledCommentItemIcon = styled.div`
  background: white;
  position: absolute;
  box-shadow: 0px 1px 4px 0px #00000085;
  border-radius: 20px;
  top: -13px;
  right: -4px;
  img {
    width: 12px;
    margin: 7px;
  }
`;
const StyledCommentItemThumbnail = styled.div`
  margin-right: 10px;
`;
const StyledCommentItemDate = styled.div`
  position: absolute;
  top: -20px;
  left: 0;
  font-size: 11px;
  letter-spacing: 1px;
`;
const StyledCommentItemContent = styled.div`
  color: #696969;
  font-size: 12px;
  width: 300px;
  padding-top: 12px;
  word-break: break-all;
  white-space: pre-wrap;
  overflow: hidden; /*float解除*/
  .space {
    float: left; /*画像の位置を右下にしたい場合はright*/
    height: 5.3em; /*画像上の余白の高さ*/
  }
`;
