import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { OfficeTitle } from 'javascript/features/realWorld/components/OfficeTitle';
import { RealWorldList } from 'javascript/features/realWorld/components/RealWorldList';
import { RealWorld } from 'types';

interface Props {
  realWorldList: RealWorld[];
  plan: number;
  role: 'member' | 'master' | 'cast' | undefined;
  videoConnectStatus: () => boolean;
  isHiddenSubRoom?: boolean;
}

export const RealWorldTemplate: React.FC<Props> = ({
  realWorldList,
  plan,
  isHiddenSubRoom,
  role,
  videoConnectStatus,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 430 });
  const { t } = useTranslation();

  return (
    <>
      {!isMobile && (
        <OfficeTitle img='location.png' plan={plan} role={role}>
          {t('GenelalRoomReal', {})}
        </OfficeTitle>
      )}
      <RealWorldList
        realWorldList={realWorldList}
        isHiddenSubRoom={isHiddenSubRoom}
        videoConnectStatus={videoConnectStatus}
      />
    </>
  );
};
