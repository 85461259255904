import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import axios from 'axios';
import CommonAddButton from '../common/CommonAddButton';
import { useTranslation } from 'react-i18next';

//チーム登録、役職登録

const FormEditTeam = ({ form }) => {
  const inputDefaultValue = { id: null, name: '' };
  const [isShowRegistrationMemberModal, setIsShowRegistrationMemberModal] =
    useGlobal('isShowRegistrationMemberModal');
  const [inputList, setInputList] = useState([inputDefaultValue]);
  const { t } = useTranslation();

  useEffect(() => {
    fetchInputData();
  }, []);

  const urlList = {
    addTeam: {
      key: 'teams',
      url: '/api/v1/teams',
    },
    addPosition: {
      key: 'positions',
      url: '/api/v1/positions',
    },
  };
  const url = urlList[form.type].url;
  const paramKey = urlList[form.type].key;
  const params = { [paramKey]: inputList };
  const name = 'name';

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleDeleteInput = (e, index) => {
    const willDelete = window.confirm(t('GenelalConfirmDelete', {}));
    if (willDelete) {
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
    } else {
      return false;
    }
  };

  const fetchInputData = () => {
    axios
      .get(url)
      .then((res) => {
        const data = res.data[paramKey];
        if (!data) {
          return false;
        }
        setInputList(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddInput = () => {
    setInputList([...inputList, { id: null, name: '' }]);
  };

  const handleSubmit = () => {
    axios.patch(url, params).then((res) => {
      alert(t('ProfileMessageAdd', {}));
      setIsShowRegistrationMemberModal(false);
    });
  };

  return (
    <>
      <div className='invite-member'>
        <div
          className='edit-close'
          onClick={() => setIsShowRegistrationMemberModal(false)}
        ></div>
        <div className='edit-title'>{form.name}</div>
        <div className='edit-wrap flex'>
          {inputList.map((i, key) => (
            <div className='edit-input-box mb-3' key={key}>
              <input
                className='edit-input'
                onChange={(e) => handleInputChange(e, key)}
                value={i.name}
                name='name'
                placeholder={form.label}
              />
              {i.name.length > 0 && (
                <button
                  onClick={(e) => handleDeleteInput(e, key)}
                  className='edit-input-reset'
                ></button>
              )}
            </div>
          ))}
        </div>
        <div onClick={handleAddInput} className='invite-add-row'>
          <CommonAddButton />
          {t('GenelalButtonAdd', {})}
        </div>
        <div className='edit-submit' onClick={handleSubmit}>
          {t('GenelalButtonSave', {})}
        </div>
      </div>
      <style jsx>{`
        @media screen and (max-width: 430px) {
          .invite-member .edit-wrap {
            width: 100%;
            max-height: 350px;
            overflow: auto;
          }
        }
        .edit-close {
          width: 20px;
          height: 20px;
          position: absolute;
          top: -30px;
          left: 0;
          cursor: pointer;
        }
        .edit-close::before {
          content: '';
          display: inline-block;
          width: 100%;
          height: 2px;
          background-color: #fff;
          transform: rotate(45deg);
          position: absolute;
          top: 50%;
        }
        .edit-close::after {
          content: '';
          display: inline-block;
          width: 100%;
          height: 2px;
          background-color: #fff;
          transform: rotate(-45deg);
          position: absolute;
          top: 50%;
        }
        .edit-title {
          font-size: 15px;
          margin: 0 0 20px;
          color: #000;
        }
        .edit-wrap {
          justify-content: space-between;
          width: 615px;
          flex-wrap: wrap;
          align-items: center;
        }
        .edit-input-box {
          position: relative;
          padding-right: 30px;
        }
        .edit-input-box::before {
          content: '';
          display: inline-block;
          width: 20px;
          height: 20px;
          background-image: url(${RailsAssetPath('icon_person.png')});
          background-size: cover;
          position: absolute;
          top: 12px;
          left: 15px;
        }
        .edit-input {
          width: 270px;
          height: 45px;
          padding: 5px 50px 5px;
          outline: none;
          border: 1px solid #000;
          border-radius: 5px;
          font-size: 14px;
        }
        .edit-input-reset {
          position: absolute;
          display: inline-block;
          /*
          background-image: url(${RailsAssetPath('close-img-gray.png')});
          background-size: cover;
          */
          width: 15px;
          height: 15px;
          top: 15px;
          right: 5px;
        }
        .edit-input-reset::before {
          content: '';
          display: inline-block;
          width: 100%;
          height: 1px;
          background: red;
          position: absolute;
          top: 50%;
          transform: rotate(45deg);
        }
        .edit-input-reset::after {
          content: '';
          display: inline-block;
          width: 100%;
          height: 1px;
          background: red;
          position: absolute;
          top: 50%;
          transform: rotate(-45deg);
        }
        .edit-submit {
          padding: 8px;
          border-radius: 5px;
          text-align: center;
          width: 200px;
          margin: 10px auto;
          background: #00a0e9;
          color: white;
          cursor: pointer;
          font-size: 13px;
        }
      `}</style>
    </>
  );
};

export default FormEditTeam;
