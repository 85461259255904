import React, { useState, useEffect, useMemo, lazy, Suspense } from 'react';
import { useGlobal } from 'reactn';
import styled from 'styled-components';
import DiaryButtons from '../diary/DiaryButtons';
import DiaryFillterButton from '../diary/DiaryFillterButtons';
import Reports from '../diary/Reports';
import Tasks from '../diary/Tasks';
import { getQueryString, SHOW_FEED_TYPE } from '../../utils/util';
import { sp } from '../../utils/mediaQuery';
// ページ表示時には本来必要の無いforum表示のためのAPIリクエストがとんでいるため、
// それを回避するために遅延ロードさせている
const Forums = lazy(() => import('../diary/Forums'));
const Surveys = lazy(() => import('../diary/Surveys'));

const DiaryRoom = ({ currentUser }) => {
  const [feedShowType, setFeedShowType] = useGlobal('feedShowType');
  const [selectedDate, setSelectedDate] = useState({ start: '', end: '' });
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [clearParams, setClearParams] = useState(false);

  const isFromMypageToShowOwnData = location.search.includes('owndata');

  useEffect(() => {
    const qs = getQueryString();
    if (isFromMypageToShowOwnData) {
      setFeedShowType(SHOW_FEED_TYPE.REPORT);
      return;
    }
    if (qs) {
      // TODO: マジックナンバーを修正
      setFeedShowType(Number(qs.type));
    } else {
      setFeedShowType(SHOW_FEED_TYPE.REPORT);
    }
  }, []);

  const handleSubmitSearch = (dates, team) => {
    const { start, end } = dates;
    setSelectedDate({ ...selectedDate, start: start, end: end });
    setSelectedTeam(team);
    setClearParams(true);
  };

  const searchParams = useMemo(
    () => ({ selectedDate, selectedTeam, clearParams }),
    [selectedDate, selectedTeam, clearParams]
  );

  return (
    <DiaryContainer>
      <DiaryButtons
        officeName={currentUser.office?.name}
        isUnauthorizedUser={
          currentUser.role === 'cast' || currentUser.role === 'non_active'
        }
      />
      <DiaryFillterButton handleSubmitSearch={handleSubmitSearch} />
      <DiaryWrapper id='target'>
        {feedShowType === SHOW_FEED_TYPE.TODO ? (
          <Tasks searchParams={searchParams} />
        ) : feedShowType === SHOW_FEED_TYPE.REPORT ? (
          <Reports
            searchParams={searchParams}
            currentUser={currentUser}
            isFromMypageToShowOwnData={isFromMypageToShowOwnData}
          />
        ) : feedShowType === SHOW_FEED_TYPE.FORUM ? (
          <Suspense fallback={<p>...Loading</p>}>
            <Forums searchParams={searchParams} currentUser={currentUser} feedshowtype={feedShowType} />
          </Suspense>
        ) : feedShowType === SHOW_FEED_TYPE.SURVEY ? (
          <Suspense fallback={<p>...Loading</p>}>
            <Surveys searchParams={searchParams} currentUser={currentUser} feedshowtype={feedShowType} />
          </Suspense>
        ) : (
          <Suspense fallback={<p>...Loading</p>}>
            <Forums searchParams={searchParams} currentUser={currentUser} feedshowtype={feedShowType} />
          </Suspense>
        )}
      </DiaryWrapper>
    </DiaryContainer>
  );
};

const DiaryContainer = styled.div`
  display: flex;
  background-color: #f6f6f6;
  justify-content: space-between;
  padding: 10px;
  height: 92vh;
  min-height: 700px;
  ${sp`
    display: flex;
    flex-wrap: wrap;
    height: auto;
    padding: 10px 0;
  `}
`;

const DiaryWrapper = styled.div`
  background: #eeeeee;
  max-width: 1000px;
  min-width: 780px;
  margin: 0 auto;
  padding: 20px 40px;
  border-radius: 5px;
  overflow-y: scroll;
  ${sp`
    min-width: auto;
    max-width: auto;
    width: 100%;
    padding: 10px 20px;
    max-height: 700px;
    min-height: 700px;
  `}
`;

export default DiaryRoom;
