import React, { useCallback, useState } from 'react';

export const useSellQuantity = (itemCount: number) => {
  const [quantity, setQuantity] = useState(1);

  const incrementQuantity = useCallback(() => {
    if (quantity >= itemCount) return;

    setQuantity((prev) => prev + 1);
  }, [quantity, itemCount]);

  const decrementQuantity = useCallback(() => {
    if (quantity <= 1) return;

    setQuantity((prev) => prev - 1);
  }, [quantity]);

  const changeQuantity = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(e.target.value);

      setQuantity(value <= 0 ? 1 : value >= itemCount ? itemCount : value);
    },
    [itemCount]
  );

  return { incrementQuantity, decrementQuantity, changeQuantity, quantity };
};
