import { useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const useScreenShareToggle = (room, onError) => {
  const [isSharing, setIsSharing] = useState(false);
  const stopScreenShareRef = useRef(null);
  const { t } = useTranslation();

  const shareScreen = useCallback(() => {
    navigator.mediaDevices
      .getDisplayMedia({
        audio: false,
        video: {
          frameRate: 10,
          height: 1080,
          width: 1920,
        },
      })
      .then((stream) => {
        const track = stream.getTracks()[0];

        room.localParticipant
          .publishTrack(track, {
            name: 'screen',
            priority: 'high',
          })
          .then((trackPublication) => {
            stopScreenShareRef.current = () => {
              room.localParticipant.unpublishTrack(track);
              room.localParticipant.emit('trackUnpublished', trackPublication);
              track.stop();
              setIsSharing(false);
            };

            track.onended = stopScreenShareRef.current;
            setIsSharing(true);
          })
          .catch(onError);
      })
      .catch((error) => {
        alert(t('VideocallScreenshareAlert', {}));
        if (error.name !== 'AbortError' && error.name !== 'NotAllowedError') {
          onError(error);
        }
      });
  }, [room, onError]);

  const toggleScreenShare = useCallback(() => {
    if (room) {
      !isSharing ? shareScreen() : stopScreenShareRef.current();
    }
  }, [isSharing, shareScreen, room]);

  return [isSharing, toggleScreenShare];
};

export default useScreenShareToggle;
