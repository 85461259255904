/* eslint-disable @typescript-eslint/no-explicit-any */

const preventDefault = (ev: any) => {
  ev.preventDefault();
};

function preventDefaultForScrollKeys(e: any) {
  preventDefault(e);
}

let scrollEvent: string = '';

function getScrollEvent() {
  if (scrollEvent === '') {
    if ('onwheel' in document) {
      scrollEvent = 'wheel';
    } else {
      scrollEvent = 'mousewheel';
    }
  }
  return scrollEvent;
}
export function addScrollStop() {
  window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  window.addEventListener(getScrollEvent(), preventDefault, { passive: false }); // modern desktop
  window.addEventListener('touchmove', preventDefault, { passive: false }); // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

export function removeScrollStop() {
  window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.removeEventListener(getScrollEvent(), preventDefault);
  window.removeEventListener('touchmove', preventDefault);
  window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}
