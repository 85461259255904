import { useCallback } from "react";
import useVoiceContext from "../../hooks/useVoiceContext";

const useParticipantStatus = () => {

  const { invitedUsers, setInvitedUsers } = useVoiceContext();

  const setParticipantState = useCallback((id, state) => {
    const participantIndex = invitedUsers.findIndex(user => user.id === id);
    const users = [...invitedUsers];
    if (state === 'connected') {
      users[participantIndex].chat_voice_status = true;
    } else if (state === 'disconnected') {
      users[participantIndex].chat_voice_status = false; 
    }
    setInvitedUsers(users);
  });

  return { setParticipantState };

}

export default useParticipantStatus;