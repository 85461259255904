import React, { useCallback, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { avatarChannel } from 'javascript/channels/avatar_channel';
import { Avatar, ResData } from 'types';

export const useAvatars = (
  currentAvatarId?: number,
) => {
  const mounted = useRef(false);
  const [avatars, setAvatars] = useState<Avatar[]>([]);
  const updateAvatars = useCallback((data: Avatar[]) => {
    if (mounted.current) setAvatars(data);
  }, []);
  const { subscribeAvatarChannel } = avatarChannel(updateAvatars);
  const cable = subscribeAvatarChannel(currentAvatarId);

  const handleSetLocation = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const targetRect = e.currentTarget.getBoundingClientRect();
      // clickされたdiv要素のwidthとheightを取得
      const { clientWidth, clientHeight } = e.currentTarget;

      // 要素内でclickされた座標を算出
      const x_coordinate = e.clientX - targetRect.left;
      const y_coordinate = e.clientY - targetRect.top;

      // div要素の中で、clickされた座標の相対値を算出し、小数第二位で四捨五入する
      const ratioX = (x_coordinate / clientWidth) * 100;
      const roundedRatioX = Math.floor(ratioX * 100) / 100;
      const ratioY = (y_coordinate / clientHeight) * 100;
      const roundedRatioY = Math.floor(ratioY * 100) / 100;
      cable.location({
        x_coordinate:
          roundedRatioX < 5 ? 5 : roundedRatioX > 95 ? 95 : roundedRatioX,
        y_coordinate:
          roundedRatioY < 15 ? 15 : roundedRatioY > 90 ? 90 : roundedRatioY,
      });
    },
    [cable]
  );

  const getData = useCallback(() => {
    axios.get<ResData<Avatar[]>>("/api/v1/avatar/location").then(({ data }) => {
      setAvatars(data.success.avatars);
    });
  }, [currentAvatarId]);

  useEffect(() => {
    getData();
    subscribeAvatarChannel(currentAvatarId);
  }, [currentAvatarId]);

  useEffect(() => {
    mounted.current = true;
    return () => { mounted.current = false; };
  }, []);

  return { avatars, handleSetLocation };
};
