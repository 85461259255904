import React from 'react';
import styled from 'styled-components';
import { DefaultModalBody } from 'javascript/features/mybox/components/DefaultModalBody';
import { PresentModalBody } from 'javascript/features/mybox/components/PresentModalBody';
import { SellModalBody } from 'javascript/features/mybox/components/SellModalBody';
import { SkillUseModalBody } from 'javascript/features/mybox/components/SkillUseModalBody';
import { useAvatarsToPresent } from 'javascript/features/mybox/hooks/useAvatarsToPresent';
import { useSelectModalType } from 'javascript/features/mybox/hooks/useSelectModalType';
import { device } from 'javascript/utils/mediaQuery';
import {
  AvatarPaidProductType,
  OwnedProductInMybox,
  OwnedSkillProductInMybox,
} from 'types';
import { useIsTypeProductItem } from 'javascript/features/mybox/util/useIsTypeProductItem';
import { AvatarOwnedSkillProductSort } from '../hooks/useMybox';

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  overflow: scroll;
`;

const StyledProductModal = styled.div<{ isPresentModal: boolean }>`
  position: absolute;
  top: ${({ isPresentModal }) => (isPresentModal ? '96px' : '152px')};
  left: 50%;
  transform: translateX(-50%);
  width: 360px;
  max-height: 70vh;
  padding: 20px 20px 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 4px #ccc;
  overflow: scroll;
  @media ${device.sp} {
    top: ${({ isPresentModal }) => (isPresentModal ? '104px' : '160px')};
    width: 280px;
    max-height: ${({ isPresentModal }) =>
    isPresentModal && 'calc(100vh - 104px)'};
    overflow: ${({ isPresentModal }) => isPresentModal && 'scroll'};
  }
`;

const StyledCloseButton = styled.div`
  position: absolute;
  cursor: pointer;
  img {
    width: 16px;
  }
`;

const StyledModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface Props {
  product: OwnedProductInMybox | OwnedSkillProductInMybox;
  selectedShopProductType: AvatarPaidProductType;
  avatarOwnedSkillProductSort: AvatarOwnedSkillProductSort,
  toggleModal: () => void;
  currentAvatarId: number | undefined;
}

export const MyboxProductModal: React.FC<Props> = ({
  product,
  selectedShopProductType,
  avatarOwnedSkillProductSort,
  toggleModal,
  currentAvatarId,
}) => {
  const { avatars } = useAvatarsToPresent();
  const { modalType, handleSelectPresent, handleSelectSell } =
    useSelectModalType(
      selectedShopProductType == 'skill' ? 'skill_use' : 'default'
    );

  const { isTypeProductItem } = useIsTypeProductItem();

  return (
    <StyledOverlay>
      <StyledProductModal isPresentModal={modalType === 'present'}>
        <StyledCloseButton onClick={toggleModal}>
          {/* @ts-ignore */}
          <img src={RailsAssetPath('close-img-gray.png')} />
        </StyledCloseButton>
        <StyledModalBodyWrapper>
          {modalType === 'default' && isTypeProductItem(product) && (
            <DefaultModalBody
              product={product}
              selectedShopProductType={selectedShopProductType}
              handleSelectPresent={handleSelectPresent}
              handleSelectSell={handleSelectSell}
            />
          )}
          {modalType === 'present' && (
            <PresentModalBody
              avatars={avatars}
              product={product}
              selectedShopProductType={selectedShopProductType}
              currentAvatarId={currentAvatarId}
              toggleModal={toggleModal}
            />
          )}
          {modalType === 'sell' && isTypeProductItem(product) && (
            <SellModalBody
              product={product}
              selectedShopProductType={selectedShopProductType}
              toggleModal={toggleModal}
              avatarOwnedSkillProductSort={avatarOwnedSkillProductSort}
            />
          )}
          {modalType === 'skill_use' && !isTypeProductItem(product) && (
            <SkillUseModalBody
              product={product}
              handleSelectPresent={handleSelectPresent}
              toggleModal={toggleModal}
              avatarOwnedSkillProductSort={avatarOwnedSkillProductSort}
            />
          )}
        </StyledModalBodyWrapper>
      </StyledProductModal>
    </StyledOverlay>
  );
};
