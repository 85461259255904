import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import {
  MQ_DESKTOP,
  MQ_MOBILE,
} from 'javascript/components/landingPage/lib/MQ';
import {
  pcp,
  // percent,
  spp,
} from 'javascript/components/landingPage/lib/cssUtil';
import { setText } from 'javascript/components/landingPage/lib/animationText';
import { removeScrollStop } from 'javascript/components/landingPage/lib/modalScrollStop';
import {
  notojpStyle,
  // onlyPC,
} from 'javascript/components/landingPage/local/commonCss';
import {
  useIsLoadedStoreValue,
  useSetIsLoadedStore,
} from 'javascript/components/landingPage/store/isLoaded';
// import { useScrollYStoreValue } from 'javascript/components/landingPage/store/scrollYStore';
import {
  LANGUAGE,
  useLanguageStoreValue,
} from 'javascript/components/landingPage/store/language';
import './Top.scss';

// @ts-ignore
// const ASSETS_4 = RailsAssetPath('lp/top/4.png');
// // @ts-ignore
// const ASSETS_BACK = RailsAssetPath('lp/top/back.png');
// // @ts-ignore
// const ASSETS_1 = RailsAssetPath('lp/top/1.png');
// // @ts-ignore
// const ASSETS_LITE2 = RailsAssetPath('lp/top/lite2.png');
// // @ts-ignore
// const ASSETS_LITE3 = RailsAssetPath('lp/top/lite3.png');
// // @ts-ignore
// const ASSETS_EFFECT1 = RailsAssetPath('lp/top/effect1.png');
// // @ts-ignore
// const ASSETS_EFFECT2 = RailsAssetPath('lp/top/effect2.png');
// @ts-ignore
// const ASSETS_UP_WHITE = RailsAssetPath('lp/top/upWhite.png');
// @ts-ignore
const ASSETS_CHINA = RailsAssetPath('lp/top/world/china.png');
// @ts-ignore
const ASSETS_FESTIVAL = RailsAssetPath('lp/top/world/festival.png');
// @ts-ignore
const ASSETS_FLOWER = RailsAssetPath('lp/top/world/flower.png');
// @ts-ignore
const ASSETS_NEON = RailsAssetPath('lp/top/world/neon.png');
// @ts-ignore
const ASSETS_RESORT = RailsAssetPath('lp/top/world/resort.png');
// @ts-ignore
const ASSETS_SWEETS = RailsAssetPath('lp/top/world/sweets.png');
// @ts-ignore
const ASSETS_TREE = RailsAssetPath('lp/top/world/tree.png');
// @ts-ignore
const ASSETS_SKY = RailsAssetPath('lp/top/world/sky.jpeg');
// @ts-ignore
const ASSETS_UNDER_THE_SEA = RailsAssetPath('lp/top/world/under_the_sea.jpeg');
// @ts-ignore
const ASSETS_UNDER_GROUND = RailsAssetPath('lp/top/world/under_ground.jpeg');
// @ts-ignore
const ASSETS_TITLE = RailsAssetPath('lp/top/title.png');

const circlemove = keyframes({
  '0%': { bottom: spp(81) },
  '100%': { bottom: spp(-5) },
});

const circlemovePc = keyframes({
  '0%': { bottom: pcp(81) },
  '100%': { bottom: pcp(-5) },
});

/*上から下にかけて丸が透過→不透明→透過する*/
const cirlemovehide = keyframes({
  '0%': { opacity: 0 },
  '50%': { opacity: 1 },
  '80%': { opacity: 0.9 },
  '100%': { opacity: 0 },
});

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  backface-visibility: hidden;
  overflow: hidden;
  background: linear-gradient(
    0deg,
    rgba(255, 254, 210, 1) 25%,
    rgba(1, 41, 84, 1) 100%
  );
  position: relative;
  overflow: hidden;
  ${MQ_MOBILE} {
    height: 100vh;
  }
  ${MQ_DESKTOP} {
    height: 100vh;
  }
  div {
    backface-visibility: hidden;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    will-change: animation, position, background-position;
  }
`;

const Black = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.51);
  position: absolute;
  top: 0;
  left: 0;
`;

const Text = styled.h1`
  ${notojpStyle}
  color: white;
  text-align: left;
  font-weight: bold;
  ${MQ_MOBILE} {
    font-size: 15px;
  }
  ${MQ_DESKTOP} {
    font-size: 26px;
  }
`;

const TitleWrapper = styled.div`
  position: absolute;
  z-index: 1;
  ${MQ_MOBILE} {
    top: 34%;
    padding: 0 8%;
  }
  ${MQ_DESKTOP} {
    top: 26%;
    padding: 0 10%;
  }
`

const Title = styled.img`
  width: 100%;

  &.fadeIn {
    animation-name:fadeInAnime;
    animation-duration:1s;
    animation-fill-mode:forwards;
    opacity:0;
    animation-delay: 1s;
  }
`

const TextJp = styled.h2`
  ${notojpStyle}
  color: white;
  text-align: left;
  ${MQ_MOBILE} {
    font-size: 10px;
  }
  ${MQ_DESKTOP} {
    font-size: 16px;
    line-height: 32px;
  }
  span {
    font-weight: bold;
  }
`;

const Scroll = styled.div`
  position: absolute;
  left: 50%;
  overflow: visible !important;
  z-index: 1;
  ${MQ_MOBILE} {
    width: ${spp(100)};
    height: ${spp(81)};
    bottom: ${spp(50)};
  }
  ${MQ_DESKTOP} {
    width: ${pcp(100)};
    height: ${pcp(81)};
    bottom: ${pcp(50)};
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 1px;
    height: 100%;
    background: #eee;
    ${MQ_MOBILE} {
      left: ${spp(5)};
    }
    ${MQ_DESKTOP} {
      left: ${pcp(5)};
    }
  }
  span {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    color: #eee;
    font-size: 0.7rem;
    letter-spacing: 0.05em;
    writing-mode: vertical-rl;
    ${MQ_MOBILE} {
      font-size: ${spp(16)};
      line-height: ${spp(27)};
      left: ${spp(15)};
    }
    ${MQ_DESKTOP} {
      font-size: ${pcp(16)};
      line-height: ${pcp(27)};
      left: ${pcp(15)};
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      ${MQ_MOBILE} {
        width: ${spp(12)};
        height: ${spp(12)};
        left: ${spp(-15)};
        animation: ${circlemove} 1.6s ease-in-out infinite,
          ${cirlemovehide} 1.6s ease-out infinite;
      }
      ${MQ_DESKTOP} {
        width: ${pcp(12)};
        height: ${pcp(12)};
        left: ${pcp(-15)};
        animation: ${circlemovePc} 2.6s ease-in-out infinite,
          ${cirlemovehide} 2.6s ease-out infinite;
      }
      border-radius: 50%;
      background: #eee;
    }
  }
`;

const Top = () => {
  const textRef = useRef<HTMLDivElement | null>(null);
  const text01Ref = useRef<HTMLDivElement | null>(null);
  const setIsLoadedStore = useSetIsLoadedStore();
  const isLoadedStoreValue = useIsLoadedStoreValue();
  // const scrollYStoreValue = useScrollYStoreValue();
  const languageStoreValue = useLanguageStoreValue();
  const { t } = useTranslation();
  const slideShowRef = useRef<HTMLImageElement | null>(null);
  const slideShow2Ref = useRef<HTMLImageElement | null>(null);
  const slideShow3Ref = useRef<HTMLImageElement | null>(null);
  const slideShow4Ref = useRef<HTMLImageElement | null>(null);
  const slideShow5Ref = useRef<HTMLImageElement | null>(null);
  const slideShow6Ref = useRef<HTMLImageElement | null>(null);
  const slideShow7Ref = useRef<HTMLImageElement | null>(null);
  const slideShow8Ref = useRef<HTMLImageElement | null>(null);
  const slideShow9Ref = useRef<HTMLImageElement | null>(null);
  const slideShow10Ref = useRef<HTMLImageElement | null>(null);
  const titleRef = useRef<HTMLImageElement | null>(null);
  const [isSlideShowLoaded, setIsSlideShowLoaded] = useState(false);
  const [isSlideShow2Loaded, setIsSlideShow2Loaded] = useState(false);
  const [isSlideShow3Loaded, setIsSlideShow3Loaded] = useState(false);
  const [isSlideShow4Loaded, setIsSlideShow4Loaded] = useState(false);
  const [isSlideShow5Loaded, setIsSlideShow5Loaded] = useState(false);
  const [isSlideShow6Loaded, setIsSlideShow6Loaded] = useState(false);
  const [isSlideShow7Loaded, setIsSlideShow7Loaded] = useState(false);
  const [isSlideShow8Loaded, setIsSlideShow8Loaded] = useState(false);
  const [isSlideShow9Loaded, setIsSlideShow9Loaded] = useState(false);
  const [isSlideShow10Loaded, setIsSlideShow10Loaded] = useState(false);
  const [isTitleLoaded, setIsTitleLoaded] = useState(false);

  // useEffect(() => {
    // let fixScrollY = scrollYStoreValue - window.innerHeight / 4;
    // setIsFocus(fixScrollY < 0);
  // }, [scrollYStoreValue]);

  useEffect(() => {
    if (
      !slideShowRef ||
      !slideShowRef.current ||
      !slideShow2Ref ||
      !slideShow2Ref.current ||
      !slideShow3Ref ||
      !slideShow3Ref.current ||
      !slideShow4Ref ||
      !slideShow4Ref.current ||
      !slideShow5Ref ||
      !slideShow5Ref.current ||
      !slideShow6Ref ||
      !slideShow6Ref.current ||
      !slideShow7Ref ||
      !slideShow7Ref.current ||
      !slideShow8Ref ||
      !slideShow8Ref.current ||
      !slideShow9Ref ||
      !slideShow9Ref.current ||
      !slideShow10Ref ||
      !slideShow10Ref.current ||
      !titleRef ||
      !titleRef.current
    )
      return;
    const onSlideShowLoad = () => setIsSlideShowLoaded(true);
    const onSlideShow2Load = () => setIsSlideShow2Loaded(true);
    const onSlideShow3Load = () => setIsSlideShow3Loaded(true);
    const onSlideShow4Load = () => setIsSlideShow4Loaded(true);
    const onSlideShow5Load = () => setIsSlideShow5Loaded(true);
    const onSlideShow6Load = () => setIsSlideShow6Loaded(true);
    const onSlideShow7Load = () => setIsSlideShow7Loaded(true);
    const onSlideShow8Load = () => setIsSlideShow8Loaded(true);
    const onSlideShow9Load = () => setIsSlideShow9Loaded(true);
    const onSlideShow10Load = () => setIsSlideShow10Loaded(true);
    const onTitleLoad = () => setIsTitleLoaded(true);
 
    slideShowRef.current.addEventListener('load', onSlideShowLoad);
    slideShow2Ref.current.addEventListener('load', onSlideShow2Load);
    slideShow3Ref.current.addEventListener('load', onSlideShow3Load);
    slideShow4Ref.current.addEventListener('load', onSlideShow4Load);
    slideShow5Ref.current.addEventListener('load', onSlideShow5Load);
    slideShow6Ref.current.addEventListener('load', onSlideShow6Load);
    slideShow7Ref.current.addEventListener('load', onSlideShow7Load);
    slideShow8Ref.current.addEventListener('load', onSlideShow8Load);
    slideShow9Ref.current.addEventListener('load', onSlideShow9Load);
    slideShow10Ref.current.addEventListener('load', onSlideShow10Load);
    titleRef.current.addEventListener('load', onTitleLoad);

    return () => {
      if (
        !slideShowRef ||
        !slideShowRef.current ||
        !slideShow2Ref ||
        !slideShow2Ref.current ||
        !slideShow3Ref ||
        !slideShow3Ref.current ||
        !slideShow4Ref ||
        !slideShow4Ref.current ||
        !slideShow5Ref ||
        !slideShow5Ref.current ||
        !slideShow6Ref ||
        !slideShow6Ref.current ||
        !slideShow7Ref ||
        !slideShow7Ref.current ||
        !slideShow8Ref ||
        !slideShow8Ref.current ||
        !slideShow9Ref ||
        !slideShow9Ref.current ||
        !slideShow10Ref ||
        !slideShow10Ref.current ||
        !titleRef ||
        !titleRef.current
      )
        return;
      slideShowRef.current.removeEventListener('load', onSlideShowLoad);
      slideShow2Ref.current.removeEventListener('load', onSlideShow2Load);
      slideShow3Ref.current.removeEventListener('load', onSlideShow3Load);
      slideShow4Ref.current.removeEventListener('load', onSlideShow4Load);
      slideShow5Ref.current.removeEventListener('load', onSlideShow5Load);
      slideShow6Ref.current.removeEventListener('load', onSlideShow6Load);
      slideShow7Ref.current.removeEventListener('load', onSlideShow7Load);
      slideShow8Ref.current.removeEventListener('load', onSlideShow8Load);
      slideShow9Ref.current.removeEventListener('load', onSlideShow9Load);
      slideShow10Ref.current.removeEventListener('load', onSlideShow10Load);
      titleRef.current.removeEventListener('load', onTitleLoad);
    };
  }, []);

  useEffect(() => {
    let timer: number;
    if (
      isSlideShowLoaded &&
      isSlideShow2Loaded &&
      isSlideShow3Loaded &&
      isSlideShow4Loaded &&
      isSlideShow5Loaded &&
      isSlideShow6Loaded &&
      isSlideShow7Loaded &&
      isSlideShow8Loaded &&
      isSlideShow9Loaded &&
      isSlideShow10Loaded &&
      isTitleLoaded
    ) {
      timer = window.setTimeout(() => {
        setIsLoadedStore({ isLoaded: true });
      }, 500);
    }

    return () => {
      window.clearTimeout(timer);
    };
  }, [
    isSlideShowLoaded,
    isSlideShow2Loaded,
    isSlideShow3Loaded,
    isSlideShow4Loaded,
    isSlideShow5Loaded,
    isSlideShow6Loaded,
    isSlideShow7Loaded,
    isSlideShow8Loaded,
    isSlideShow9Loaded,
    isSlideShow10Loaded,
    isTitleLoaded,
  ]);

  useEffect(() => {
    const time = isLoadedStoreValue ? 0 : 1500;
    const timer = window.setTimeout(() => {
      removeScrollStop();
      setText(textRef.current, t('top.copy'));
      setText(text01Ref.current, t('top.subCopy'));
    }, time);

    return () => {
      window.clearTimeout(timer);
    };
  }, [languageStoreValue, isLoadedStoreValue]);

  return (
    <Wrapper>
      <Black />
      <TitleWrapper>
        <div style={{ paddingBottom: "30px"}}>
          <Title src={ASSETS_TITLE} ref={titleRef} className={isTitleLoaded ? "fadeIn" : ""}/>
        </div>
        <div style={{ paddingBottom: "30px"}}>
          <Text 
            ref={textRef} 
            data-lang-ru={languageStoreValue === LANGUAGE.ru}
          />
        </div>
        <div>
          <TextJp
            ref={text01Ref}
            data-lang-ru={languageStoreValue === LANGUAGE.ru}
          />
        </div>
      </TitleWrapper>
      <Scroll>
        <span>Scroll</span>
      </Scroll>
      <div className="slideshow">
        <img 
          className="slideshow-image" 
          src={ASSETS_SKY}
          ref={slideShowRef}
        />
        <img
          className="slideshow-image" 
          src={ASSETS_UNDER_GROUND}
          ref={slideShow2Ref}
        />
        <img
          className="slideshow-image" 
          src={ASSETS_UNDER_THE_SEA}
          ref={slideShow3Ref}
        />
        <img
          className="slideshow-image" 
          src={ASSETS_TREE}
          ref={slideShow4Ref}
        />
        <img
          className="slideshow-image" 
          src={ASSETS_NEON}
          ref={slideShow5Ref}
        />
        <img
          className="slideshow-image" 
          src={ASSETS_CHINA}
          ref={slideShow6Ref}
        />
        <img
          className="slideshow-image" 
          src={ASSETS_FLOWER}
          ref={slideShow7Ref}
        />
        <img
          className="slideshow-image" 
          src={ASSETS_SWEETS}
          ref={slideShow8Ref}
        />
        <img
          className="slideshow-image" 
          src={ASSETS_RESORT}
          ref={slideShow9Ref}
        />
        <img
          className="slideshow-image" 
          src={ASSETS_FESTIVAL}
          ref={slideShow10Ref}
        />
      </div>
    </Wrapper>
  );
};

export default Top;