import axios from "axios";
import { USER_ROLE } from "../../../utils/util";

export function fetchUserData() {
  return (dispatch) => {
    axios.get('/api/v1/users/me').then(res => {
      const currentUser = res.data;
      const isMaster = (currentUser.role === USER_ROLE.MASTER) ? true : false
      dispatch({ 
        type: 'SET_USER_WORK_STATUS', 
        currentUser: currentUser,
        isMaster: isMaster
      });
    });
  }
}