import React from 'react';
import styled from 'styled-components';
import { useUpdateAvatarParts } from 'javascript/features/avatarDressUp/hooks/useUpdateAvatarParts';
import { device } from 'javascript/utils/mediaQuery';
import { AvatarPartsType, UserProfileAvatar, hairColor } from 'types';
import { useTranslation } from 'react-i18next';

interface Props {
  avatarParts: UserProfileAvatar;
  userId: number;
  selectedAvatarPartsType: AvatarPartsType;
  selectLocalHairColor: (hairColor: hairColor) => void;
}

export const AvatarPartsItemListFooter: React.FC<Props> = ({
  avatarParts,
  userId,
  selectedAvatarPartsType,
  selectLocalHairColor,
}) => {
  const { updateAvatarParts } = useUpdateAvatarParts(avatarParts, userId);
  const { t } = useTranslation();

  return (
    <StyledPartsItemListFooter avatarPartsType={selectedAvatarPartsType}>
      {selectedAvatarPartsType === 'hair' && (
        <StyledSelectHairColor>
          <StyledHairColor
            onClick={() => selectLocalHairColor('black')}
            hairColor='#463e3b'
          />
          <StyledHairColor
            onClick={() => selectLocalHairColor('brown')}
            hairColor='#a47e57'
          />
          <StyledHairColor
            onClick={() => selectLocalHairColor('gold')}
            hairColor='#e0b03d'
          />
          <StyledHairColor
            onClick={() => selectLocalHairColor('silver')}
            hairColor='#a9a6a2'
          />
          <StyledHairColor
            onClick={() => selectLocalHairColor('orange')}
            hairColor='#e46534'
          />
          <StyledHairColor
            onClick={() => selectLocalHairColor('blue')}
            hairColor='#56938e'
          />
          <StyledHairColor
            onClick={() => selectLocalHairColor('green')}
            hairColor='#7d973d'
          />
        </StyledSelectHairColor>
      )}
      <StyledSaveButton onClick={updateAvatarParts}>
        {t('GenelalButtonSave', {})}
      </StyledSaveButton>
    </StyledPartsItemListFooter>
  );
};

const StyledSelectHairColor = styled.div`
  display: flex;
  padding-left: 12px;
`;

const StyledHairColor = styled.div<{ hairColor: string }>`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  background-color: ${({ hairColor }) => hairColor};
  border-radius: 50%;
  @media ${device.sp} {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`;

const StyledPartsItemListFooter = styled.div<{ avatarPartsType: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ avatarPartsType }) =>
    avatarPartsType === 'hair' ? 'space-between' : 'flex-end'};
  height: 39px;
  width: 100%;
  @media ${device.sp} {
    padding-right: 0;
  }
`;

const StyledSaveButton = styled.button`
  background: #03a9f4;
  color: white;
  font-size: 12px;
  padding: 8px 24px;
  width: 112px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translate(-1px, -1px);
    box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 5%), 4px 4px 4px 0px rgb(0 0 0 / 5%);
  }
`;
