import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import {
  HeaderNavItem,
  NavItem as NavHelp,
  NavIcon as NavHelpIcon,
} from 'javascript/components/common/Header/components/HeaderNavItem';
import { HelpMenu } from 'javascript/components/common/Header/components/HelpMenu';
import { device } from 'javascript/utils/mediaQuery';

const StyledNormalNavMenu = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 8px;
  letter-spacing: 1px;
  font-size: 10px;
  color: #a4a4a4;
  min-width: 488px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  a {
    position: relative;
  }
  @media ${device.tab} {
    width: 328px;
  }
  @media ${device.sp} {
    width: 160px;
    padding-right: 8px;
    letter-spacing: 0;
    min-width: auto;
    overflow-x: auto;
    p {
      display: none;
    }
  }
  .isActive {
    img {
      filter: none;
      opacity: 1;
    }
    p {
      font-weight: bold;
      color: #000;
    }
  }
`;

const StyledNewLabel = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  color: #f00;
  font-size: 10px;
  transform: scale(0.7);
  @media ${device.sp} {
    top: -5px;
    right: -8px;
  }
`;

interface Props {
  isMaster: boolean;
}

export const HeaderNormalNavMenu: React.FC<Props> = ({ isMaster }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleHelpMenu = () => setIsOpen(!isOpen);
  const isMobile = useMediaQuery({ maxWidth: 430 });
  const { t } = useTranslation();
  const isThanksPage = location.pathname.includes('/thanks/');

  const shouldShowNewLabel = useMemo(
    () => new Date().getDate() >= 24 || new Date().getDate() <= 3,
    []
  );

  return (
    <StyledNormalNavMenu>
      {isThanksPage ? (
        <>
          <a href='/office'>
            <HeaderNavItem img='town_blue.png' text={t('MenuHeaderTown', {})} />
          </a>
          <a href='/employees'>
            <HeaderNavItem img='member_blue.png' text={t('MenuHeaderMember')} />
          </a>
          <a href='/diary'>
            <HeaderNavItem img='feed_blue.png' text={t('MenuHeaderFeed')} />
          </a>
          <a href='/shop'>
            <HeaderNavItem img='shop_blue.png' text={t('MenuUserShop')} />
            {shouldShowNewLabel && <StyledNewLabel>New</StyledNewLabel>}
          </a>
          {!isMobile && (
            <a href='/attendance'>
              <HeaderNavItem
                img='attendance_blue.png'
                text={t('MenuHeaderAttendance')}
              />
            </a>
          )}
        </>
      ) : (
        <>
          <NavLink to='/office' activeClassName='isActive'>
            <HeaderNavItem img='town_blue.png' text={t('MenuHeaderTown', {})} />
          </NavLink>
          <NavLink to='/employees' activeClassName='isActive'>
            <HeaderNavItem img='member_blue.png' text={t('MenuHeaderMember')} />
          </NavLink>
          <NavLink to='/diary' activeClassName='isActive'>
            <HeaderNavItem img='feed_blue.png' text={t('MenuHeaderFeed')} />
          </NavLink>
          <NavLink to='/shop' activeClassName='isActive'>
            <HeaderNavItem img='shop_blue.png' text={t('MenuUserShop')} />
            {shouldShowNewLabel && <StyledNewLabel>New</StyledNewLabel>}
          </NavLink>
          {!isMobile && (
            <NavLink to='/attendance' activeClassName='isActive'>
              <HeaderNavItem
                img='attendance_blue.png'
                text={t('MenuHeaderAttendance')}
              />
            </NavLink>
          )}
        </>
      )}
      <NavHelp
        onClick={() => {
          toggleHelpMenu();
        }}
      >
        {/* @ts-ignore */}
        <NavHelpIcon src={RailsAssetPath('help.png')} alt='icon-help' />
        <p>{t('MenuHeaderHelp', {})}</p>
      </NavHelp>
      <HelpMenu
        isMaster={isMaster}
        isOpen={isOpen}
        toggleHelpMenu={toggleHelpMenu}
      />
    </StyledNormalNavMenu>
  );
};
