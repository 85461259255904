import { useCallback, useState } from 'react';

export const useToggleModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return {
    isOpen,
    toggleModal,
  };
};
