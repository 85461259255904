import React from "react";
import DefaultModalPortal from "./DefaultModalPortal";
import DefaultModalContent from "./DefaultModalContent";

const DefaultDialogModal = () => {

  return (
    <DefaultModalPortal>
      <DefaultModalContent />
    </DefaultModalPortal>
  );
}

export default DefaultDialogModal;