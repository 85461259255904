import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useGlobal } from 'reactn';
import { Link } from 'react-router-dom';
import axios from 'axios';
import propTypes from 'prop-types';
import { useDispatch, useSelector, connect } from 'react-redux';
import styled from 'styled-components';
import ProfileThumbnail from '../items/ProfileThumbnail';
import { fetchMemberApi } from '../../lib/redux/actions/member';
import Observer from '../common/Observer';
//import ReactPaginate from "react-paginate";
import { device } from '../../utils/mediaQuery';
import LoadingAnimation from '../common/LoadingAnimation';
import { useTranslation } from 'react-i18next';
import { ScrumTemplate } from 'javascript/features/scrum/components';

const EmployeesList = () => {
  //access redux store
  const currentUser = useSelector((state) => state.setUser.currentUser);
  const isMaster = useSelector((state) => state.setUser.isMaster);

  const [_, setIsShowRegistrationMemberModal] = useGlobal(
    'isShowRegistrationMemberModal'
  );
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [teamId, setTeamId] = useState(null);
  const [sortId, setSortId] = useState(null);
  const [isObserved, setIsObserved] = useState(false);
  const [data, setData] = useState({});
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const target = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    getUserList();
  }, [sortId, teamId]);

  useEffect(() => {
    if (page > 1) {
      getNextPageData();
    }
  }, [page]);

  const params = {
    page: page,
    select_id: teamId,
    sort_id: sortId,
  };

  const onChangeTeamSelect = (e) => {
    //params.page = 1;
    const t = e.target.value;
    setTeamId(t);
    setPage(1);
    setList([]);
  };

  const onChangeSortSelect = (e) => {
    //params.page = 1;
    const s = e.target.value;
    setSortId(s);
    setPage(1);
    setList([]);
  };

  const getUserList = () => {
    setLoading(true);
    fetchMemberApi(params).then((res) => {
      setData(res.data);
      const users = res.data.users;
      setIsObserved(users.length >= 30 ? true : false);
      setList(users);
      setLoading(false);
    });
  };

  const getNextPageData = () => {
    fetchMemberApi(params).then((res) => {
      //setData(res.data);
      const users = res.data.users;
      setIsObserved(users.length >= 30 ? true : false);
      setList((prev) => (prev != users ? [...prev, ...users] : prev));
    });
  };

  const handleMemberListSetting = (config) => {
    dispatch({ type: 'SET_MEMBER_SETTING_MODAL', config: config });
    setIsShowRegistrationMemberModal(true);
  };

  const onIntersection = () => {
    setPage((prev) => prev + 1);
  };

  const memberConfig = [
    {
      type: 'addMember',
      label: '',
      name: t('EmployeeCategoryMember', {}),
    },
    {
      type: 'addTeam',
      label: 'チーム名',
      name: t('EmployeeCategoryTeam', {}),
    },
    {
      type: 'addPosition',
      label: '役職名',
      name: t('EmployeeCategoryPosition', {}),
    },
  ];

  const cancelInvitation = useCallback(
    (userId, e) => {
      e.stopPropagation();
      e.preventDefault();
      const isConfirmed = confirm(t('EmployeeCategoryConfirmcancellation', {}));
      if (isConfirmed) {
        axios.post(`/api/v1/users/delete_invite_user/${userId}`).then(() => {
          getUserList();
          alert(t('EmployeeCategoryCompletedcancellation', {}));
        });
      }
    },
    [getUserList]
  );

  const memberList =
    list &&
    list.map((user, key) => (
      <SingleMemberCard
        user={user}
        key={key}
        currentUser={currentUser}
        cancelInvitation={cancelInvitation}
      />
    ));

  const teamOptions = data.select_list;
  const teamOptionNode =
    teamOptions &&
    teamOptions.map((team, key) => (
      <option value={team.id} key={key}>
        {team.name}
      </option>
    ));

  const sortOptions = data.sort_list;
  const sortOptionNode =
    sortOptions &&
    sortOptions.map((sort, key) => (
      <option value={sort.id} key={key}>
        {sort.name}
      </option>
    ));

  if (!list) {
    return null;
  }

  return (
    <>
      <MemberContainer>
        <MemberHeader>
          <StyledButtonWrapper>
            {isMaster &&
              memberConfig.map((config, key) => (
                <MemberCommonAddButton
                  onClick={() => {
                    handleMemberListSetting(config);
                  }}
                  key={key}
                >
                  <span>＋</span> {config.name}
                </MemberCommonAddButton>
              ))}
          </StyledButtonWrapper>
          <div>
            <MemberSelectWrapper>
              <select onChange={onChangeSortSelect}>{sortOptionNode}</select>
            </MemberSelectWrapper>
            <MemberSelectWrapper>
              <select onChange={onChangeTeamSelect}>{teamOptionNode}</select>
            </MemberSelectWrapper>
          </div>
        </MemberHeader>
        <ScrumTemplate userId={currentUser.id} />
        {loading && <LoadingAnimation />}
        {list.length ? (
          <MemberAllList>
            {memberList}
            {isMaster && (
              <StyledAddMemberIcon>
                <div
                  onClick={() => {
                    handleMemberListSetting({
                      type: 'addMember',
                      label: '',
                      name: t('EmployeeCategoryMember', {}),
                    });
                  }}
                />
              </StyledAddMemberIcon>
            )}
          </MemberAllList>
        ) : null}
        <div
          ref={target}
          style={isObserved ? undefined : { display: 'none' }}
        />
      </MemberContainer>
      {isObserved && (
        <Observer
          root={null}
          el={target.current}
          onIntersection={onIntersection}
        />
      )}
    </>
  );
};

const MemberContainer = styled.div`
  background-color: #f6f6f6;
  padding: 40px 80px;
  min-height: 100vh;
  @media ${device.sp} {
    padding: 40px 10px;
  }
`;

const MemberHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  @media ${device.sp} {
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const MemberSelectWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 20px;
  &::before {
    content: '';
    width: 12px;
    height: 12px;
    border: 6px solid transparent;
    border-top: 8px solid #6f6f6f;
    position: absolute;
    top: 15px;
    right: 10px;
  }
  select {
    appearance: none;
    height: 35px;
    border-radius: 8px;
    border: 1px solid #bfbfbf;
    color: #7b7b7b;
    width: 150px;
    font-size: 14px;
    background: #fff;
    padding: 0 12px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media ${device.sp} {
    margin: 15px 20px 0 0;
  }
`;

const MemberAllList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

const MemberCommonAddButton = styled.button`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 16px;
  font-size: 12px;
  transition: 0.3s background-color;
  span {
    display: grid;
    place-items: center;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 50%;
    background-color: #00a0e9;
    color: #fff;
    font-size: 14px;
  }
  &:hover {
    background-color: #dedede;
  }
`;

const StyledAddMemberIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 80px 5px 0;
  width: 240px;
  height: 200px;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    padding: 24px;
    background-color: #fff;
    cursor: pointer;
    &::before,
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 3px;
      background: #00a0e9;
    }
    &::before {
      transform: translateY(50%);
    }
    &::after {
      transform: rotate(90deg);
    }
  }
  @media ${device.sp} {
    width: calc(100% / 2 - 10px);
    height: 185px;
    margin: 0 5px;
  }
`;

const SingleMemberCard = ({ user, currentUser, cancelInvitation }) => {
  SingleMemberCard.propTypes = {
    user: propTypes.object,
    isMaster: propTypes.bool,
    editMemberProfile: propTypes.func,
  };

  const isNonActiveUser = currentUser.role === 'non_active';

  const { t } = useTranslation();

  return (
    <MemberSingleBox isNonActiveUser={isNonActiveUser}>
      <Link to={!isNonActiveUser ? `/mypage/${user.user_id}?sort_id=${user.sort_id}&select_id=${user.select_id}` : null}>
        <StyledRoleName>{user.current_role_name}</StyledRoleName>
        <MemberSingleThumbnail>
          <ProfileThumbnail image={user.user_image} size='65px' />
        </MemberSingleThumbnail>
        <MemberSingleContent>
          <MemberSingleName>{user.user_name}</MemberSingleName>
          <MemberSingleInfo>
            {user.team_name ? user.team_name : t('GenelalTextNoSet', {})}
          </MemberSingleInfo>
          <MemberSingleInfo>
            {user.position_name
              ? user.position_name
              : t('GenelalTextNoSet', {})}
          </MemberSingleInfo>
          {user.inviting_flg ? (
            <>
              <MemberSingleInfo isInviting>
                {t('EmployeeCategoryInviting', {})}
              </MemberSingleInfo>
              <MemberSingleInfo
                isCancelInvitation
                onClick={(e) => cancelInvitation(user.user_id, e)}
              >
                {t('EmployeeCategoryCancelinvitation', {})}
              </MemberSingleInfo>
            </>
          ) : (
            <>
              <MemberSingleInfoGray>
                <MemberSingleIcon
                  icon={RailsAssetPath('member/icon_myviket.png')}
                  style={{'filter' : 'unset'}}
                />
                <span>
                  <span style={{ color: user.owned_viket < 0 ? 'red' : '#8c8c8c' }}>
                    {user.owned_viket.toLocaleString()}{' '}
                  </span>
                  {t('EmployeeCategoryViket', {})}
                </span>
              </MemberSingleInfoGray>
              <MemberSingleInfoGray>
                <MemberSingleIcon
                  icon={RailsAssetPath('member/icon_viket.png')}
                />
                <span>
                  {user.viket.toLocaleString()} {t('EmployeeCategoryViket', {})}
                  <StyledThisMonthViket>
                    {' '}
                    {t('EmployeeCategoryThismonthviket', {})}
                  </StyledThisMonthViket>
                </span>
              </MemberSingleInfoGray>
              <MemberSingleInfoGray>
                <MemberSingleIcon
                  icon={RailsAssetPath('member/icon_cake.png')}
                />
                <span>
                  {user.birth_date
                    ? user.birth_date
                    : t('GenelalTextNoSet', {})}
                </span>
              </MemberSingleInfoGray>
              <MemberSingleInfoGray>
                <MemberSingleIcon
                  icon={RailsAssetPath('mybox/present_icon.png')}
                />
                <span>{user.present_point}</span>
              </MemberSingleInfoGray>
              <MemberSingleInfoGray>
                <MemberSingleIcon
                  icon={RailsAssetPath('member/icon_skill.png')}
                />
                <span>
                  {user.skill_viket.toLocaleString()}{' '}
                  {t('EmployeeCategoryViket', {})}
                </span>
              </MemberSingleInfoGray>
            </>
          )}
        </MemberSingleContent>
      </Link>
    </MemberSingleBox>
  );
};

const MemberSingleBox = styled.div`
  margin: 80px 5px 0;
  width: 240px;
  height: 231px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background: #fff;
  position: relative;
  cursor: ${({ isNonActiveUser }) => (isNonActiveUser ? 'default' : 'pointer')};
  a {
    cursor: ${({ isNonActiveUser }) => isNonActiveUser && 'default'};
  }
  @media ${device.sp} {
    width: calc(100% / 2 - 10px);
  }
`;

const StyledThisMonthViket = styled.span`
  font-size: 10px;
  color: #aaa;
`;

const MemberSingleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 42px 8px 0;
  height: 100%;
  overflow: hidden;
`;

const MemberSingleName = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  font-size: 15px;
  font-weight: bold;
`;

const MemberSingleInfo = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 4px;
  font-size: 12px;
  color: ${({ isInviting, isCancelInvitation }) =>
    isInviting ? '#8c8c8c' : isCancelInvitation ? '#f00' : '#00a0e9'};
`;

const MemberSingleInfoGray = styled.div`
  font-size: 12px;
  color: #8c8c8c;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 88px 2px 65px;
  span {
    width: 60%;
    text-align: center;
    word-break: keep-all;
    white-space: nowrap;
  }
  @media ${device.sp} {
    width: 90%;
    margin: 0 0 2px 0;
  }
`;

const MemberSingleIcon = styled.i`
  width: 13px;
  height: 13px;
  margin-right: 8px;
  display: inline-block;
  background: url(${(props) => props.icon});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  filter: grayscale(100%);
`;

const MemberSingleThumbnail = styled.div`
  width: 75px;
  height: 75px;
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translate(-50%, 0);
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  }
`;

const StyledRoleName = styled.p`
  position: absolute;
  top: 4px;
  right: 8px;
  color: #00a0e9;
  font-size: 10px;
`;

const mapStateToProps = (state) => {
  return {
    submitted: state.setMemberProfile.submitted,
    userData: state.setMemberProfile.user,
  };
};

export default connect(mapStateToProps)(EmployeesList);
