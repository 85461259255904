import React, { useState, useEffect, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
  Typography,
  Grid,
  Button,
  Theme,
  Hidden,
  InputLabel,
  TextField,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

import useVideoCallContext from './hooks/useVideoCallContext';
import axios from 'axios';
import IntroContainer from './IntroContainer';
import LocalVideoPreview from './LocalVideoPreview';
import useLocalAudioToggle from './hooks/useLocalAudioToggle';
import useLocalVideoToggle from './hooks/useLocalVideoToggle';

import { sp } from '../../utils/mediaQuery';
import { Trans, useTranslation } from 'react-i18next';

const Disconnected = ({ roomId, created }) => {
  const { t } = useTranslation();

  return (
    <IntroContainer>
      <Grid container justify='center'>
        <Grid item xs={12}>
          <Grid container spacing={0} justify='center' alignItems='center'>
            <Grid
              container
              item
              xs={12}
              md={8}
              direction={'column'}
              spacing={0}
              justify='center'
              alignItems='center'
            >
              <Logo>
                <img
                  src={RailsAssetPath('viket-town-header-logo.png')}
                  alt=''
                  className='header-title__logo'
                />
              </Logo>
            </Grid>
            <Grid container item xs={12} direction={'column'} spacing={2}>
              <EndRoom>
                <Trans i18nKey='VideocallMessageThankyouUsingVideoroom'>
                  Video Roomをご利用いただき
                  <SpBr />
                  ありがとうございました
                </Trans>
              </EndRoom>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={10}>
          <GlobalMenu>
            <Grid
              container
              direction={'row'}
              spacing={2}
              justify='center'
              alignItems='center'
              style={{ fontSize: '12px' }}
            >
              <Grid container item xs={12} sm={4} justify='center'>
                <a href='/office' target='_blank' rel='noopener'>
                  {t('GenelalSettingsSiteName', {})} TOP
                </a>
              </Grid>
              <Grid container item xs={4} sm={4} justify='center'>
                <a href='https://bit.ly/3hwF1D9' target='_blank' rel='noopener'>
                  {t('VideocallTextContactUs', {})}
                </a>
              </Grid>
              <Grid container item xs={4} sm={4} justify='center'>
                <a href='https://pla-cole.co/' target='_blank' rel='noopener'>
                  {t('VideocallTextCompany', {})}
                </a>
              </Grid>
            </Grid>
          </GlobalMenu>
        </Grid>
      </Grid>
    </IntroContainer>
  );
};

const Logo = styled.div`
  margin-bottom: 30px;
`;
const EndRoom = styled.div`
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  color: #000;
`;
const Notice = styled.div`
  display: inline-block;
  font-size: 10px;
`;

const GlobalMenu = styled.div`
  position: relative;
  display: flex;
  padding: 0 1.43em;
  margin-top: 80px;
  ${sp`
    height: 20px;
    padding: 0;
    font-size:12px;
  `}
  a {
    color: #0fa5e9;
  }
`;
const SpBr = styled.span`
  ::after {
    ${sp`
      content: "\\A";
      white-space: pre;
     `}
  }
`;

export default Disconnected;
