import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import {
  Container,
  Tabs,
  Tab,
  Typography,
  Box,
  Grid,
  FormControl,
  Select,
  Button,
  MenuItem,
  List,
} from '@material-ui/core';
import { useStyles } from './hooks/useStyles';
import usePaymentContext from './hooks/usePaymentContext';
import { useTranslation } from 'react-i18next';

const History = ({}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [pointHistory, setPointHistory] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(
    ('0' + (new Date().getMonth() + 1)).slice(-2)
  );
  const { paymentConfig, fetchConfig } = usePaymentContext();

  // プランがなければ請求書ボタンを表示しないようにしていたが、後から解約で請求書が取得できなくなる可能性があったので不使用
  const [hasSubscription, setHasSubscription] = useState(false);

  const ymList = useMemo(() => {
    if (paymentConfig) {
      setHasSubscription(paymentConfig.has_plan);
      return paymentConfig.ym_lists;
    }
    return [`${year}/${month}`];
  }, [paymentConfig]);

  useEffect(() => {
    fetchData(year, month);
  }, []);
  const fetchData = async (year, month) => {
    let params = {
      year: year,
      month: month,
    };
    await axios
      .post('/api/v1/billing/payments/purchase_history', params)
      .then((res) => {
        setPaymentHistory(res.data);
      });
    await axios
      .post('/api/v1/billing/payments/point_history', params)
      .then((res) => {
        setPointHistory(res.data);
      });
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleYmChange = (event) => {
    let ym = event.target.value;
    let year = ym.split('/')[0];
    let month = ym.split('/')[1];
    setYear(year);
    setMonth(month);
    fetchData(year, month);
  };

  const handleCreateInvoice = () => {
    console.log(year);
    location.href = `/api/v1/billing/payments/create_invoice?year=${year}&month=${month}`;
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role='tabpanel'
        className={`${classes.historyBox} `}
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <List className={`${classes.baseList} ${classes.historyList}`}>
            <Grid container>{children}</Grid>
          </List>
        )}
      </Box>
    );
  };
  return (
    <Grid container spacing={4}>
      <Grid
        container
        item
        justify='flex-end'
        alignItems='flex-end'
        spacing={2}
      ></Grid>
      <Grid container item justify='flex-end' alignItems='flex-end' spacing={2}>
        <Grid item>
          <FormControl
            variant='outlined'
            size='small'
            className={classes.formControl}
          >
            <Select
              className={classes.historyDateSelectButton}
              defaultValue={`${new Date().getFullYear()}/${(
                '0' +
                (new Date().getMonth() + 1)
              ).slice(-2)}`}
              onChange={handleYmChange}
            >
              {ymList.map((ym, index) => {
                return (
                  <MenuItem key={index} value={ym}>
                    {ym}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Button
            onClick={handleCreateInvoice}
            className={`${classes.baseButton} ${classes.roundedButton}`}
          >
            {t('PaymentTextInvoice', {})}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          value={value}
          indicatorColor='primary'
          textColor='primary'
          onChange={handleTabChange}
        >
          <Tab label={t('PaymentTextPurchaseHistory', {})} />
          <Tab label={t('PaymentTextPointHistory', {})} />
        </Tabs>
        <TabPanel value={value} index={0}>
          {paymentHistory &&
            paymentHistory.map((item, index) => {
              return (
                <Grid key={index} container item justify='flex-start'>
                  <Grid item xs={4}>
                    {item.billing_date}
                  </Grid>
                  <Grid item xs={6}>
                    {item.payment_type}
                  </Grid>
                  <Grid item xs={2}>
                    ¥{item.amount.toLocaleString()}
                  </Grid>
                </Grid>
              );
            })}
        </TabPanel>

        <TabPanel value={value} index={1}>
          {pointHistory &&
            pointHistory.map((item, index) => {
              return (
                <Grid key={index} container item justify='flex-start'>
                  <Grid item xs={10}>
                    {item.used_date}
                  </Grid>
                  <Grid item xs={2}>
                    {item.amount.toLocaleString()}
                  </Grid>
                </Grid>
              );
            })}
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default History;
