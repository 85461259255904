import React from 'react';
import styled from 'styled-components';
import { sp } from '../../../utils/mediaQuery';
import { useState , useGlobal,  useEffect , useRef} from 'reactn';
import consumer from 'javascript/channels/consumer';
import axios from 'axios';

const MarqueeWrapper = styled.div<{ icon: string }>`
  width: 600px;
  padding: 0.5em 0;
  overflow: hidden;
  background: rgba(0,0,0,0.6);
  color: #F8E58C;
  border-radius: 5px;
  margin-right: 30px;
  ${sp`
    top: calc(44px + 34px);
    max-width: 100vw;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    width: 92%;
    margin-top: 16px;
  `}
  background-size: 14px;
  background-repeat: no-repeat;
  margin-right: 10px;
  vertical-align: middle;
  background-image: ${({ icon }) => `url(${icon})`};
  background-position: right;
  background-position: 95%;

`;

// const MarqueeText = styled.p<{ icon: string }>`
const MarqueeText = styled.p`
  margin: 0;
  padding-left: 600px;
  display: inline-block;
  white-space: nowrap;
  animation-name: marquee;
  animation-timing-function: linear;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  
  &:after {
    content: "";
    white-space: nowrap;
    padding-right: 50px;
  }
  
  @keyframes marquee {
    from   { transform: translate(0%);}
    to { transform: translate(-100%);}
  }
`;


export const FreeSpaceLedScrollingText = () => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const messagesRef = useRef([]);

  const [interactWorldId] =  useGlobal<{ interactWorldId: number | undefined }>('interactWorldId');
  const [messages, setMessages] = useState([]);
  const channelInfo = {
    channel: interactWorldId === undefined ? 'AvatarChannel' : 'InteractWorldAvatarChannel',
    ...(interactWorldId !== undefined && { interact_world_id: interactWorldId }),
  };

  const subscription = consumer.subscriptions.create(channelInfo, {
    received(data) {
      if (data.type === 'create_chat_open') {
        const array = data.bulletin_boards;
        setMessages(array);
      }
    },
  });
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const res = await axios.get('/api/v1/avatar/bulletin_boards', {
          params: {
            interact_world_id: interactWorldId,
          }
        });
        setMessages(res.data.contents);
      } catch (error) {
        console.error(error);
      }
    };
    fetchInitialData();

    return () => {
      subscription.unsubscribe();
    };
  }, [interactWorldId]);

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);
  
  const handleMarqueeEnd = () => {
    setCurrentMessageIndex((currentMessageIndex + 1) % messagesRef.current.length);
  };

  return (
    <>
    {/* @ts-ignore */}
      <MarqueeWrapper icon={RailsAssetPath('freeSpace/megaphone_yellow.png')}>
        <MarqueeText 
        onAnimationIteration={() => handleMarqueeEnd()} 
        >
          {messagesRef.current[currentMessageIndex]}
        </MarqueeText>
      </MarqueeWrapper>
    </>
  );
};
